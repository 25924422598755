<template>
	<ValidationObserver ref="observer" v-slot="{ handleSubmit, reset }">
		<b-form
			@submit.prevent="handleSubmit(createBusinessCard)"
			@reset.prevent="reset"
		>
			<div class="col-lg-4 offset-lg-4">
				<h2 class="title-bold text-left">
					{{ $t("createCard.createCard") }}
				</h2>
				<hr />
				<div class="background-outline">
					<div class="background-modifier text-left">
						<label class="small">
							{{ $t("createCard.chooseUser") }}
						</label>
						<ValidationProvider
							name="userId"
							rules="required"
							v-slot="{ valid, errors }"
						>
							<b-form-group id="userIdInputGroup" label-for="userIdInput">
								<b-form-select
									id="userIdInput"
									:state="errors[0] ? false : valid ? true : null"
									v-model="userId"
									><option v-for="user in users" :value="user.id" :key="user.id"
										>{{ user.id }} {{ user.firstname }}
										{{ user.lastname }}</option
									>
									<template #first>
										<b-form-select-option value="" disabled>{{
											$t("createCard.userId")
										}}</b-form-select-option>
									</template></b-form-select
								>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>

						<label class="small">
							{{ $t("createCard.chooseLinkedAccount") }}
						</label>
						<ValidationProvider
							rules="required"
							name="debitAccountId"
							v-slot="{ valid, errors }"
						>
							<b-form-group
								id="debitAccountIdInputGroup"
								label-for="debitAccountIdInput"
							>
								<b-form-select
									@change="onChange"
									id="debitAccountIdInput"
									:state="errors[0] ? false : valid ? true : null"
									v-model="debitAccountId"
									><option
										v-for="account in accounts"
										:value="account.id"
										:key="account.id"
										>{{ account.iban }}
										{{ $n(account.credits - account.debits, "decimal") }}
										{{ account.currency }}</option
									>
									<template #first>
										<b-form-select-option value="" disabled>{{
											$t("createCard.debitAccountId")
										}}</b-form-select-option>
									</template></b-form-select
								>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>

						<!-- <ValidationProvider
            rules="required"
            name="currency"
            v-slot="{ valid, errors }"
          >
            <b-form-group
              id="accountCurrencyInputGroup"
              label-for="accountCurrencyInput"
            >
              <b-form-select
                id="accountCurrencyInput"
                :state="errors[0] ? false : valid ? true : null"
                v-model="currency"
                ><option
                  v-for="currency in currencies"
                  :value="currency.code"
                  :key="currency.code"
                  >{{ currency.code }}</option
                >
                <template #first>
                  <b-form-select-option value="" disabled>{{
                    $t("createCard.currency")
                  }}</b-form-select-option>
                </template></b-form-select
              >
              <b-form-invalid-feedback id="inputLiveFeedback">{{
                errors[0]
              }}</b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider> -->

						<!-- <ValidationProvider
            rules="alpha_spaces|min:3|max:50"
            name="nickname"
            v-slot="{ valid, errors }"
          >
            <b-form-group
              class="text-left"
              label-for="lastnameInput"
              :description="$t('createCard.nicknameVoluntary')"
            >
              <b-form-input
                type="text"
                v-model="nickname"
                :state="errors[0] ? false : valid ? true : null"
                :placeholder="$t('createCard.nickname')"
              ></b-form-input>
              <b-form-invalid-feedback id="inputLiveFeedback">{{
                errors[0]
              }}</b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider> -->
					</div>
					<br />
					<div class="background-modifier text-left">
						<h4 class="title-bold">{{ $t("createCard.specifications") }}</h4>
						<hr />
						<label class="small">
							{{ $t("createCard.chooseCardType") }}
						</label>
						<b-form-group label="" v-slot="{ chooseCardType }">
							<b-form-radio
								v-model="cardType"
								:aria-describedby="chooseCardType"
								name="chooseCardType"
								value="0"
								>{{ $t("createCard.standard") }}</b-form-radio
							>
							<br />
							<b-form-radio
								v-model="cardType"
								:aria-describedby="chooseCardType"
								name="chooseCardType"
								value="1"
								>{{ $t("createCard.premium") }}</b-form-radio
							>
						</b-form-group>
						<hr />
						<label class="small">
							{{ $t("createCard.chooseCardColor") }}
						</label>
						<b-form-group label="" v-slot="{ chooseCardColor }">
							<b-form-radio
								v-model="cardColor"
								:aria-describedby="chooseCardColor"
								name="chooseCardColor"
								value="1"
								>{{ $t("createCard.silver") }}</b-form-radio
							>
							<br />
							<b-form-radio
								v-model="cardColor"
								:aria-describedby="chooseCardColor"
								name="chooseCardColor"
								value="2"
								>{{ $t("createCard.gold") }}</b-form-radio
							>
							<br />
							<div v-if="cardType == '1'">
								<b-form-radio
									v-model="cardColor"
									:aria-describedby="chooseCardColor"
									name="chooseCardColor"
									value="0"
									>{{ $t("createCard.black") }}</b-form-radio
								>
								<br />
								<b-form-radio
									v-model="cardColor"
									:aria-describedby="chooseCardColor"
									name="chooseCardColor"
									value="3"
									>{{ $t("createCard.rose") }}</b-form-radio
								>
							</div>
						</b-form-group>
					</div>
					<br />
					<div class="background-modifier text-left">
						<h4 class="title-bold">{{ $t("createCard.pin") }}</h4>
						<hr />
						<label class="small">{{ $t("createCard.choosePinCode") }}</label>

						<div class="">
							<ValidationProvider
								rules="alpha_dash|min:4|max:6"
								name="cardPin"
								v-slot="{ valid, errors }"
							>
								<b-form-group label-for="pinInput" description="">
									<b-form-input
										@input=""
										type="text"
										v-model="cardPin"
										:state="errors[0] ? false : valid ? true : null"
										:placeholder="$t('createCard.pin')"
									></b-form-input>
									<b-form-invalid-feedback id="inputLiveFeedback">{{
										errors[0]
									}}</b-form-invalid-feedback>
								</b-form-group>
							</ValidationProvider>
						</div>
						<br />
					</div>
					<br />
					<div class="background-modifier text-left">
						<h4 class="title-bold">{{ $t("createCard.authorizations") }}</h4>
						<hr />
						<label class="small">{{ $t("createCard.paymentLimit") }}</label>

						<ValidationProvider
							rules="required|numeric|min_value:100.00|max_value:10000.00"
							name="paymentLimit"
							v-slot="{ valid, errors }"
						>
							<b-form-group label-for="paymentLimitInput" description="">
								<b-form-input
									@input=""
									type="text"
									v-model="paymentLimit"
									:state="errors[0] ? false : valid ? true : null"
									:placeholder="$t('createCard.paymentLimit')"
								></b-form-input>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>
						<br />
						<b-form-checkbox
							class="text-left"
							id="checkbox-1"
							v-model="paymentsAbroad"
							name="checkbox-1"
							value="1"
							unchecked-value="0"
							switch
						>
							{{ $t("createCard.paymentsAbroad") }}
							<!-- <b>({{ paymentsAbroad }})</b> -->
						</b-form-checkbox>
						<br />
						<b-form-checkbox
							class="text-left"
							id="checkbox-2"
							v-model="onlinePayments"
							name="checkbox-2"
							value="1"
							unchecked-value="0"
							switch
						>
							{{ $t("createCard.onlinePayments") }}
							<!-- <b>({{ onlinePayments }})</b> -->
						</b-form-checkbox>
						<br />
						<b-form-checkbox
							class="text-left"
							id="checkbox-3"
							v-model="atmWithdrawals"
							name="checkbox-3"
							value="1"
							unchecked-value="0"
							switch
						>
							{{ $t("createCard.atmWithdrawals") }}
							<!-- <b>({{ atmWithdrawals }})</b> -->
						</b-form-checkbox>
						<br />

						<b-form-checkbox
							class="text-left"
							id="checkbox-4"
							v-model="locked"
							name="checkbox-4"
							value="1"
							unchecked-value="0"
							switch
						>
							{{ $t("createCard.blockCard") }}
							<!-- <b>({{ locked }})</b> -->
						</b-form-checkbox>
						<br />
					</div>
					<br />
					<b-form-checkbox
						class="text-left"
						id="checkbox-5"
						v-model="status"
						name="checkbox-5"
						value="accepted"
						unchecked-value="not_accepted"
					>
						{{ $t("signup.acceptTerms") }}
					</b-form-checkbox>

					<div v-if="status == 'accepted'">
						<hr />
						<b-button
							type="submit"
							@click="isloading"
							class="btn btn-info text-light btn-block"
							>{{ $t("createCard.submit")
							}}<b-spinner
								v-if="isloading"
								class="ml-auto"
								:variant="light"
							></b-spinner
						></b-button>
					</div>
					<hr />
					<b-button
						type="reset"
						class="btn btn-alert text-light btn-block"
						@click="resetForm()"
						>{{ $t("reset") }}</b-button
					>

					<div v-if="msg && $bvToast.show('notification-toast')"></div>
					<div class="action">
						<br />
						<b-toast
							id="notification-toast"
							variant="secondary"
							static
							no-auto-hide
						>
							<template #toast-title>
								<div class="d-flex flex-grow-1 align-items-baseline">
									<b-img
										class="logo mb-2"
										:src="require('/public/images/logo.png')"
										alt=""
										width="12"
										height="12"
									></b-img>
									<strong class="mr-auto">{{ $t("notification") }}</strong>
									<small class="text-muted mr-2"></small>
								</div>
							</template>
							{{ msg }}
						</b-toast>
					</div>
				</div>
			</div>
		</b-form>
	</ValidationObserver>
</template>

<script>
	import { ValidationObserver, ValidationProvider } from "vee-validate";
	import AuthService from "@/services/AuthService.js";

	export default {
		name: "CreateCardForm",
		components: {
			ValidationObserver,
			ValidationProvider,
		},
		data() {
			return {
				// currencies: [],
				users: [],
				accounts: [],
				//currency: "EUR",
				// nickname: this.$t("createCard.myCard"),
				userId: "",
				debitAccountId: "",
				cardType: 0,
				cardColor: 1,
				cardPin: "",
				cardStatus: 0,
				paymentsAbroad: 1,
				onlinePayments: 1,
				atmWithdrawals: 1,
				paymentLimit: 2000.0,
				locked: 0,
				msg: "",
				status: "not_accepted",
				isloading: false,
			};
		},
		async created() {
			if (!this.$store.getters.isLoggedIn) {
				this.$router.push("login");
			}
			if (
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 3 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 15 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 14 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 13 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 12
			) {
				this.$router.push("overview");
			}
			let lang = this.$i18n.locale;
			this.role = this.$store.getters.getUser.role;
			this.username = this.$store.getters.getUser.username;
			this.firstname = this.$store.getters.getUser.firstname;
		},
		methods: {
			// async getAllAccountCurrencies() {
			//   try {
			//     const response = await AuthService.getAllAccountCurrencies();
			//     this.currencies = response;
			//     console.log(response);
			//   } catch (error) {
			//     console.log(error);
			//   }
			// },
			async getClientsAccounts() {
				try {
					const id = this.$store.getters.getUser.id;
					this.isloading = true;
					const response = await AuthService.getClientsAccounts(id);
					this.accounts = response;
					this.isloading = false;
					console.log(response);
				} catch (error) {
					this.isloading = false;
					console.log(error);
				}
			},
			async getClientsUsers() {
				try {
					const id = this.$store.getters.getUser.id;
					const response = await AuthService.getClientsUsers(id);
					this.users = response;
					console.log(response);
				} catch (error) {
					console.log(error);
				}
			},
			async createBusinessCard() {
				try {
					const details = {
						lang: this.$i18n.locale,
						clientId: this.$route.params.id,
						userId: this.userId,
						accountId: this.debitAccountId,
						cardColor: this.cardColor,
						cardType: this.cardType,
						cardPin: this.cardPin,
						locked: this.locked,
						cardStatus: this.cardStatus,
						paymentsAbroad: this.paymentsAbroad,
						onlinePayments: this.onlinePayments,
						atmWithdrawals: this.atmWithdrawals,
						paymentLimit: this.paymentLimit,
						// nickname: this.nickname,
					};
					const response = await AuthService.createBusinessCard(details);
					this.resetForm();
					this.msg = response.msg;
				} catch (error) {
					this.msg = error.response.data.msg;
					/*this.$router.push('accounts');*/
				}
			},
			resetForm() {
				// this.nickname = "";
				this.msg = "";
				requestAnimationFrame(() => {
					this.$refs.observer.reset();
				});
			},
		},
		mounted() {
			// this.getAllAccountCurrencies();
			this.getClientsAccounts();
			this.getClientsUsers();
		},
	};
</script>
