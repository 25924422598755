<template>
	<div class="col-8 offset-md-2 text-left">
		<h2 class="title-bold">
			{{ $t("userDetail.user") }} {{ this.$route.params.id }}
		</h2>
		<hr />

		<div class="background-modifier">
			<div class="row">
				<div class="col-md-10">
					<h4 class="title-bold">{{ user.firstname }} {{ user.lastname }}</h4>
				</div>
				<div class="col-md-2 text-right">
					<b-avatar variant="secondary" size="3rem">
						{{ user.firstname.charAt() }}{{ user.lastname.charAt() }}
					</b-avatar>
				</div>
			</div>
			<hr />
			<div class="product-details">
				<label class="small">{{ $t("userDetail.id") }}</label>
				<p class="text-left">{{ user.id }}</p>
				<label class="small">{{ $t("userDetail.username") }}</label>
				<p class="text-left">
					{{ user.username }}
				</p>
				<label class="small">{{ $t("userDetail.firstname") }}</label>
				<p class="text-left">
					{{ user.firstname }}
				</p>
				<label class="small">{{ $t("userDetail.lastname") }}</label>
				<p class="text-left">
					{{ user.lastname }}
				</p>
				<label class="small">{{ $t("userDetail.email") }}</label>
				<p class="text-left">{{ user.email }}</p>
				<label class="small">{{ $t("userDetail.role") }}</label>
				<p class="text-left">
					{{ user.role == 3 ? $t("role.admin") : $t("role.user") }}
				</p>
				<label class="small">{{ $t("userDetail.verificationStatus") }}</label>
				<p class="text-left">
					{{
						user.verificationStatus == 3
							? $t("userDetail.verified")
							: $t("userDetail.pending")
					}}
				</p>
				<label class="small">{{ $t("userDetail.active") }}</label>
				<p class="text-left">
					{{ user.active == 1 ? $t("yes") : $t("no") }}
				</p>
				<label class="small">{{ $t("userDetail.registered") }}</label>
				<p class="text-left">
					{{ $d(Date.parse(user.registered), "long") }}
				</p>
				<label class="small">{{ $t("userDetail.lastLogin") }}</label>
				<p class="text-left">
					{{ $d(Date.parse(user.lastLogin), "long") }}
				</p>
			</div>
			<div class="text-right">
				<router-link
					:to="{ name: 'edit-user', params: { id: user.id } }"
					class="btn btn-outline mx-1 "
					>{{ $t("userDetail.edit") }}</router-link
				>
			</div>
		</div>
		<hr />

		<div class="">
			<h3 class="title-bold">{{ $t("fileUpload.listOfFiles") }}</h3>

			<div v-if="isloading2" class="text-center">
				<b-spinner
					style="width: 3rem; height: 3rem;"
					class="ml-auto text-center"
					:variant="secondary"
				></b-spinner>
			</div>
			<div v-else-if="idDocs.length > 0">
				<div class="background-modifier">
					<table class="table mt-2 ">
						<thead>
							<tr>
								<!--  <th class="text-left">{{ $t('filename') }}</th> -->
							</tr>
						</thead>
						<tbody>
							<tr v-for="idDoc in idDocs" :key="idDoc.id">
								<td class="text-left">
									<span>
										<a
											:href="
												'https://www.n3consortium.com/api/files/' +
													idDoc.filename
											"
											>{{ idDoc.filename }}</a
										></span
									>
								</td>

								<td class="text-center"></td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>

		<br />
		<div class="row justify-content-end">
			<div class="col-md-12">
				<router-link
					:to="{ name: 'document-upload' }"
					class="btn btn-secondary mx-1 "
					>{{ $t("dashboard.fileUpload") }}</router-link
				>
			</div>
		</div>
		<hr />

		<div class="">
			<h3 class="title-bold">{{ $t("accountList.accounts") }}</h3>
			<hr />

			<p class="text-right">
				<router-link class="btn btn-secondary" :to="{ name: 'new-account' }">{{
					$t("accountList.openAccount")
				}}</router-link>
			</p>

			<div class="background-modifier">
				<div class="input-group mb-3">
					<input
						type="text"
						class="form-control"
						:placeholder="$t('search')"
						v-model="searchTitle"
					/>
					<div class="input-group-append">
						<button
							class="btn btn-outline"
							type="button"
							@click="
								page = 1;
								getUserAccounts();
							"
						>
							{{ $t("search") }}
						</button>
					</div>
				</div>

				<div v-if="isloading" class="text-center">
					<b-spinner
						style="width: 3rem; height: 3rem;"
						class="ml-auto text-center"
						:variant="secondary"
					></b-spinner>
				</div>
				<div v-else-if="accounts.length > 0">
					<div class="table-wrap">
						<table class="table mt-2 " id="account-list">
							<thead>
								<tr>
									<th class="text-left small">IBAN</th>
									<th class="text-left small">
										{{ $t("accountList.accountHolder") }}
									</th>
									<th class="text-left small">
										{{ $t("accountList.nickname") }}
									</th>
									<th class="text-right small">
										{{ $t("accountList.opened") }}
									</th>
									<th class="text-right small">
										{{ $t("accountList.balance") }}
									</th>
									<th class="text-centered small">
										{{ $t("accountList.actions") }}
									</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="account in accounts" :key="account.id">
									<td class="text-left">
										{{ account.iban.toString().replace(/\w{4}(?=.)/g, "$& ") }}
									</td>
									<td class="text-left">
										{{ account.userId }} {{ account.firstname }}
										{{ account.lastname }}
									</td>
									<td class="text-left">{{ account.nickname }}</td>
									<td class="text-right">
										{{ $d(Date.parse(account.created), "short") }}
									</td>
									<td class="text-right">
										{{
											account.credits - account.debits != null
												? $n(account.credits - account.debits, "decimal")
												: 0.0
										}}
									</td>
									<td class="text-right">
										<router-link
											:to="{
												name: 'account-detail',
												params: { id: account.id },
											}"
											class="btn btn-outline mx-1 "
											>{{ $t("accountList.view") }}</router-link
										>
										<a
											class="btn btn-danger mx-1 "
											@click="deleteAccount(account.id)"
											>{{ $t("accountList.delete") }}</a
										>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div v-if="accounts.length >= 1">
						<div class="row">
							<div class="col-md-6">
								{{ $t("itemsPerPage") }}
								<select
									class="custom-select"
									style="width: 60px;"
									v-model="pageSize"
									@change="handlePageSizeChange($event)"
								>
									<option v-for="size in pageSizes" :key="size" :value="size">
										{{ size }}
									</option>
								</select>
							</div>
							<div class="col-md-6">
								<b-pagination
									v-model="page"
									:total-rows="count"
									:per-page="pageSize"
									:prev-text="$t('previous')"
									:next-text="$t('next')"
									align="right"
									@change="handlePageChange"
								></b-pagination>
							</div>
						</div>
					</div>
				</div>
				<div v-else>{{ $t("userDetail.noAccounts") }}</div>
			</div>
		</div>
	</div>
</template>

<script>
	import AuthService from "@/services/AuthService.js";

	export default {
		name: "UserDetail",
		data() {
			return {
				user: "",
				accounts: [],
				currentAccount: null,
				currentIndex: -1,
				searchTitle: "",
				isloading: false,
				isloading2: false,
				page: 1,
				count: 0,
				pageSize: 10,

				pageSizes: [10, 25, 50],

				idDocs: [],
			};
		},
		async created() {
			if (!this.$store.getters.isLoggedIn) {
				this.$router.push("login");
			}
			if (
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role !== 3
			) {
				this.$router.push("overview");
			}

			this.role = this.$store.getters.getUser.role;
			this.getUser();
		},
		methods: {
			async getUser(id) {
				try {
					const id = this.$route.params.id;
					const response = await AuthService.getUser(id);
					this.user = response;
					console.log(response);
				} catch (error) {
					console.log(error);
				}
			},

			getRequestParams(searchTitle, page, pageSize) {
				let params = {};

				if (searchTitle) {
					params["nickname"] = searchTitle;
				}

				if (page) {
					params["page"] = page - 1;
				}

				if (pageSize) {
					params["size"] = pageSize;
				}

				return params;
			},

			async getUserAccounts() {
				const params = this.getRequestParams(
					this.searchTitle,
					this.page,
					this.pageSize
				);

				try {
					const details = {
						id: this.$route.params.id,
					};
					this.isloading = true;
					const response = await AuthService.getUserAccounts(
						details.id,
						params.page,
						params.size,
						params.nickname
					);
					const { items, totalItems } = response.data;
					this.accounts = items;
					this.count = totalItems;
					this.isloading = false;
					console.log(response.data);
				} catch (error) {
					this.isloading = false;
					console.log(error);
				}
			},

			handlePageChange(value) {
				this.page = value;
				this.getUserAccounts();
			},

			handlePageSizeChange(event) {
				this.pageSize = event.target.value;
				this.page = 1;
				this.getUserAccounts();
			},

			async getIdDocs(id) {
				try {
					id = this.$route.params.id;
					//  console.log(id);
					this.isloading2 = true;
					const response = await AuthService.getIdDocs(id);
					this.idDocs = response;
					this.isloading2 = false;
					console.log(response);
				} catch (error) {
					this.isloading2 = false;
					console.log(error);
				}
			},
		},
		mounted() {
			this.getUser();
			this.getUserAccounts();
			this.getIdDocs();
		},
	};
</script>
