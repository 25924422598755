import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Axios from "axios";
import BootstrapVue from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import VueI18n from "vue-i18n";
import messages from "./lang";

import { IBAN, BIC } from "ibankit";

import "./vee-validate";
import {
	extend,
	ValidationProvider,
	configure,
	ValidationObserver,
} from "vee-validate";

require("./assets/style.css");

// import { library } from "@fortawesome/fontawesome-svg-core";
// import {
// 	faGlobe,
// 	faExclamationCircle,
// 	faArrowRight,
// 	faCreditCard,
// 	faChartBar,
// 	faMoneyBill,
// 	faEye,
// 	faClipboard,
// 	faBriefcase,
// 	faArrowsAltH,
// 	faPaperPlane,
// 	faUserCircle,
// 	faShoppingBasket,
// 	faFileInvoice,
// 	faMoneyCheck,
// 	faPeopleArrows,
// 	faFileDownload,
// 	faQrcode,
// 	faWind,
// 	faStar,
// 	faHandSparkles,
// 	faPercentage,
// 	faPiggyBank,
// 	faPersonBooth,
// 	faFileInvoiceDollar,
// 	faHandHoldingUsd,
// 	faEuroSign,
// 	faCalculator,
// } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
// library.add(
// 	faGlobe,
// 	faArrowRight,
// 	faExclamationCircle,
// 	faCreditCard,
// 	faChartBar,
// 	faMoneyBill,
// 	faEye,
// 	faClipboard,
// 	faBriefcase,
// 	faArrowsAltH,
// 	faPaperPlane,
// 	faUserCircle,
// 	faShoppingBasket,
// 	faFileInvoice,
// 	faMoneyCheck,
// 	faPeopleArrows,
// 	faFileDownload,
// 	faQrcode,
// 	faWind,
// 	faStar,
// 	faHandSparkles,
// 	faPercentage,
// 	faPiggyBank,
// 	faPersonBooth,
// 	faFileInvoiceDollar,
// 	faHandHoldingUsd,
// 	faCalculator,
// 	faEuroSign
// );
// Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.config.productionTip = false;

const dateTimeFormats = {
	en: {
		card: {
			year: "numeric",
			month: "numeric",
		},
		short: {
			year: "numeric",
			month: "short",
			day: "numeric",
		},
		long: {
			year: "numeric",
			month: "short",
			day: "numeric",
			weekday: "short",
			hour: "numeric",
			minute: "numeric",
			hour12: true,
		},
	},
	fr: {
		short: {
			year: "numeric",
			month: "short",
			day: "numeric",
		},
		long: {
			year: "numeric",
			month: "long",
			day: "numeric",
			weekday: "short",
			hour: "numeric",
			minute: "numeric",
		},
	},
	fi: {
		short: {
			year: "numeric",
			month: "short",
			day: "numeric",
		},
		long: {
			year: "numeric",
			month: "long",
			day: "numeric",
			weekday: "short",
			hour: "numeric",
			minute: "numeric",
		},
	},
};

const numberFormats = {
	en: {
		currency: {
			style: "currency",
			currency: "EUR",
		},
		decimal: {
			style: "decimal",
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
		},
	},
	fi: {
		currency: {
			style: "currency",
			currency: "EUR",
		},
		decimal: {
			style: "decimal",
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
		},
	},
	fi: {
		currency: {
			style: "currency",
			currency: "EUR",
		},
		decimal: {
			style: "decimal",
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
		},
	},
};

configure({
	// this will be used to generate messages.
	defaultMessage: (field, values) => {
		values._field_ = i18n.t(`fields.${field}`);
		return i18n.t(`validations.messages.${values._rule_}`, values);
	},
});

Vue.use(VueI18n);
export const i18n = new VueI18n({
	locale: "en",
	fallbackLocale: "en",
	messages,
	dateTimeFormats,
	numberFormats,
});
Vue.use(BootstrapVue);

const translationsIBAN = "! = IBAN"; //'$t("validations.iban")';
const translationsBIC = "! = BIC";
Vue.component("ValidationProvider", ValidationProvider);
extend("iban", {
	validate: (str) => {
		return IBAN.isValid(str);
	},
	message: translationsIBAN,
});
extend("bic", {
	validate: (str) => {
		return BIC.isValid(str);
	},
	message: translationsBIC,
});

// set auth header
Axios.defaults.headers.common["Authorization"] = `Bearer ${store.state.token}`;

new Vue({
	router,
	store,
	i18n,
	render: (h) => h(App),
}).$mount("#app");
