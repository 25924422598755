<template>
	<div class="col-4 offset-md-4 text-left">
		<h2 class="title-bold">
			{{ $t("transactionDetail.transaction") }} {{ this.$route.params.id }}
		</h2>
		<hr />
		<div class="background-modifier">
			<div class="product-details">
				<label class="small">{{ $t("transactionDetail.id") }}</label>
				<p class="text-left">
					{{ transaction.id }}
				</p>
				<label class="small">{{
					$t("transactionDetail.debitAccountId")
				}}</label>
				<p class="text-left">
					({{ transaction.debitAccountId }})
					{{
						this.debitAccountIBAN.iban.toString().replace(/\w{4}(?=.)/g, "$& ")
					}}
				</p>
				<label class="small">{{ $t("transactionDetail.remitter") }}</label>
				<p class="text-left">
					{{ transaction.debitAccountHolder }}
				</p>
				<label class="small">{{
					$t("transactionDetail.creditAccountId")
				}}</label>
				<p class="text-left">
					({{ transaction.creditAccountId }})
					{{
						this.creditAccountIBAN.iban.toString().replace(/\w{4}(?=.)/g, "$& ")
					}}
				</p>
				<label class="small">{{ $t("transactionDetail.beneficiary") }}</label>
				<p class="text-left">
					{{ transaction.creditAccountHolder }}
				</p>
				<label class="small">{{
					$t("transactionDetail.accountCurrency")
				}}</label>
				<p class="text-left">
					{{ transaction.baseCurrency }}
				</p>
				<label class="small">{{ $t("transactionDetail.amount") }}</label>
				<p class="text-left">
					{{ $n(transaction.amount, "decimal") }}
				</p>
				<label class="small">{{
					$t("transactionDetail.paymentCurrency")
				}}</label>
				<p class="text-left">
					{{ transaction.paymentCurrency }}
				</p>
				<label class="small">{{
					$t("transactionDetail.currencyAmount")
				}}</label>
				<p class="text-left">
					{{ $n(transaction.currencyAmount, "decimal") }}
				</p>
				<label class="small">{{ $t("transactionDetail.message") }}</label>
				<p class="text-left">
					{{ transaction.message }}
				</p>
				<label class="small">{{ $t("transactionDetail.date") }}</label>
				<p class="text-left">
					{{ $d(Date.parse(transaction.created), "long") }}
				</p>
			</div>
			<!-- <div class="text-right">
        <router-link
          :to="{ name: 'edit-transaction', params: { id: transaction.id } }"
          class="btn btn-outline mx-1 "
          >{{ $t("transactionDetail.edit") }}</router-link
        >
      </div> -->
			<hr />
			<div class="background-modifier">
				<label class="col-10 offset-md-1">
					<input class="" type="file" ref="file" @change="selectFile" />
				</label>
				<hr />
				<div v-if="currentFile" class="progress">
					<div
						class="progress-bar progress-bar-striped bg-info"
						role="progressbar"
						:aria-valuenow="progress"
						aria-valuemin="0"
						aria-valuemax="100"
						:style="{ width: progress + '%' }"
					>
						{{ progress }}%
					</div>
				</div>
				<br />
				<b-button
					class="btn btn-info text-light btn-block"
					:disabled="!selectedFiles"
					@click="upload"
				>
					{{ $t("transactionDetail.uploadReceipt") }}
				</b-button>

				<div class="alert alert-light" role="alert">{{ msg }}</div>

				<!--   <div class="card">
              <div class="card-header">{{ $t('fileUpload.listOfFiles') }}</div>
              <ul class="list-group list-group-flush">
                <li
                  class="list-group-item"
                  v-for="(file, index) in fileInfos"
                  :key="index"
                >
                  <a :href="file.url">{{ file.name }}</a>
                </li>
              </ul>
            </div>  -->

				<hr />
				<div class="">
					<h3 class="title-bold">{{ $t("transactionDetail.receiptFiles") }}</h3>
					<div class="text-right">
						<!-- <b-button
              class=""
              variant="outline"
              v-b-tooltip.hover
              :title="$t('businessSignup.ensureRequiredFilesAreUploaded')"
            >
              {{ $t("info") }}
            </b-button> -->
					</div>

					<div v-if="expenseReceipts.length">
						<table class="table mt-2 ">
							<!-- <thead>
                <tr>
                  <th class="text-left">
                    {{ $t("documentUpload.filename") }}
                  </th>
                </tr>
              </thead> -->
							<tbody>
								<tr
									v-for="expenseReceipt in expenseReceipts"
									:key="expenseReceipt.id"
								>
									<td class="text-left">
										<span>
											<a
												:href="
													'https://www.n3consortium.com/api/files/' +
														expenseReceipt.filename
												"
												>{{ expenseReceipt.filename }}</a
											></span
										>
									</td>

									<td class="text-center"></td>
								</tr>
							</tbody>
						</table>
					</div>
					<div v-else>{{ $t("fileUpload.noDocuments") }}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import AuthService from "@/services/AuthService.js";
	import UploadService from "../../services/ExpenseReceiptUploadService";

	export default {
		name: "TransactionDetail",
		data() {
			return {
				transaction: "",
				debitAccountIBAN: "",
				creditAccountIBAN: "",
				selectedFiles: undefined,
				currentFile: undefined,
				progress: 0,
				msg: "",

				fileInfos: [],
				expenseReceipts: [],
			};
		},
		async created() {
			if (!this.$store.getters.isLoggedIn) {
				this.$router.push("login");
			}

			if (
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 3 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 15 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 14 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 13 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 12 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 11
			) {
				this.$router.push("overview");
			}
			let lang = this.$i18n.locale;
			this.role = this.$store.getters.getUser.role;
			this.username = this.$store.getters.getUser.username;
			this.firstname = this.$store.getters.getUser.firstname;

			this.role = this.$store.getters.getUser.role;
			this.getTransactionDetails();
		},
		methods: {
			async getTransactionDetails(id) {
				try {
					const id = this.$route.params.id;
					const response = await AuthService.getTransactionDetails(id);
					this.transaction = response;
					console.log(response);
				} catch (error) {
					console.log(error);
				}
			},
			async getCreditAccountIBAN(id) {
				try {
					const id = this.$route.params.id;
					const response = await AuthService.getCreditAccountIBAN(id);
					this.creditAccountIBAN = response;
					console.log(response);
				} catch (error) {
					console.log(error);
				}
			},
			async getDebitAccountIBAN(id) {
				try {
					const id = this.$route.params.id;
					const response = await AuthService.getDebitAccountIBAN(id);
					this.debitAccountIBAN = response;
					console.log(response);
				} catch (error) {
					console.log(error);
				}
			},
			selectFile() {
				this.selectedFiles = this.$refs.file.files;
			},
			upload() {
				this.progress = 0;

				const lang = this.$i18n.locale;
				const txId = this.$route.params.id;

				this.currentFile = this.selectedFiles.item(0);
				UploadService.upload(this.currentFile, lang, txId, (event) => {
					this.progress = Math.round((100 * event.loaded) / event.total);
				})
					.then((response) => {
						this.msg = response.data.msg;
						this.getExpenseReceipts();
						return UploadService.getFiles();
					})
					.then((files) => {
						this.fileInfos = files.data;
					})
					.catch(() => {
						this.progress = 0;
						this.msg = this.$t("fileUpload.couldNotUploadTheFile");
						this.currentFile = undefined;
					});

				this.selectedFiles = undefined;
			},
			async getExpenseReceipts(id) {
				try {
					id = this.$route.params.id;
					//  console.log(id);
					const response = await AuthService.getExpenseReceipts(id);
					this.expenseReceipts = response;
					console.log(response);
				} catch (error) {
					console.log(error);
				}
			},
		},
		mounted() {
			UploadService.getFiles().then((response) => {
				this.fileInfos = response.data;
			});
			this.getTransactionDetails();
			this.getExpenseReceipts();
			this.getCreditAccountIBAN();
			this.getDebitAccountIBAN();
		},
	};
</script>
