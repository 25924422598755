var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.newNip)},"reset":function($event){$event.preventDefault();return reset($event)}}},[_c('div',{staticClass:"col-lg-4 offset-lg-4 text-left"},[_c('h2',{staticClass:"title-bold"},[_vm._v(_vm._s(_vm.$t("nip.newNip")))]),_c('p',{staticClass:"open-account text-right"},[_c('router-link',{staticClass:"btn btn-outline",attrs:{"to":{ name: 'claim-business-nip' }}},[_vm._v(_vm._s(_vm.$t("nip.claimNip")))])],1),_c('hr'),(_vm.isloading2)?_c('div',{staticClass:"text-center"},[_c('div',{staticClass:"background-outline"},[_c('b-spinner',{staticClass:"ml-auto text-center",staticStyle:{"width":"3rem","height":"3rem"},attrs:{"variant":_vm.secondary}})],1),_c('br')]):_c('div',{staticClass:"background-outline"},[_c('div',{staticClass:"background-modifier"},[_c('label',{staticClass:"small"},[_vm._v(_vm._s(_vm.$t("nip.debitAccountId")))]),_c('ValidationProvider',{attrs:{"rules":"required","name":"debitAccountId"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"id":"debitAccountIdInputGroup","label-for":"debitAccountIdInput"}},[_c('b-form-select',{attrs:{"id":"debitAccountIdInput","state":errors[0] ? false : valid ? true : null},on:{"change":_vm.onChange},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":"","disabled":""}},[_vm._v(_vm._s(_vm.$t("nip.debitAccountId")))])]},proxy:true}],null,true),model:{value:(_vm.debitAccountId),callback:function ($$v) {_vm.debitAccountId=$$v},expression:"debitAccountId"}},_vm._l((_vm.accounts),function(account){return _c('option',{key:account.id,domProps:{"value":account.id}},[_vm._v("("+_vm._s(account.id)+") "+_vm._s(account.iban.toString().replace(/\w{4}(?=.)/g, "$& "))+" "+_vm._s(_vm.$n(account.credits - account.debits, "decimal"))+" "+_vm._s(account.currency))])}),0),_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),(_vm.debitAccountId > 0)?_c('div',[_c('label',{staticClass:"small"},[_vm._v(_vm._s(_vm.$t("nip.beneficiaryName")))]),_c('ValidationProvider',{attrs:{"rules":"alpha_spaces|min:2|max:50","name":"creditAccountHolder"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"creditAccountHolder","description":""}},[_c('b-form-input',{attrs:{"type":"text","state":errors[0] ? false : valid ? true : null,"placeholder":_vm.$t('editStandingOrder.beneficiaryName')},model:{value:(_vm.creditAccountHolder),callback:function ($$v) {_vm.creditAccountHolder=$$v},expression:"creditAccountHolder"}}),_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('label',{staticClass:"small"},[_vm._v(_vm._s(_vm.$t("nip.email")))]),_c('ValidationProvider',{attrs:{"rules":"required|email","name":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"emailInput","description":""}},[_c('b-form-input',{attrs:{"type":"email","state":errors[0] ? false : valid ? true : null,"placeholder":_vm.$t('nip.email')},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1):_vm._e()],1),_c('br'),(_vm.isloading3)?_c('div',{staticClass:"text-center"},[_c('div',{staticClass:"background-modifier"},[_c('b-spinner',{staticClass:"ml-auto text-center",staticStyle:{"width":"3rem","height":"3rem"},attrs:{"variant":_vm.secondary}})],1),_c('br')]):_c('div',{staticClass:"background-modifier"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('label',{staticClass:"small"},[_vm._v(_vm._s(_vm.$t("account.currency")))]),_c('ValidationProvider',{attrs:{"rules":"required","name":"selected"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"id":"sourceCurrencyInputGroup","label-for":_vm.sourceCurrencyInput}},[_c('b-form-select',{attrs:{"id":"sourceCurrencyInput","state":errors[0] ? false : valid ? true : null},on:{"change":_vm.convert},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":"","disabled":""}},[_vm._v(_vm._s(_vm.$t("editStandingOrder.paymentCurrency")))])]},proxy:true}],null,true),model:{value:(_vm.selected[0]),callback:function ($$v) {_vm.$set(_vm.selected, 0, $$v)},expression:"selected[0]"}},_vm._l((_vm.currencies),function(currency){return _c('option',{key:currency.CharCode,domProps:{"value":currency.CharCode}},[_vm._v(_vm._s(currency.CharCode))])}),0),_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('div',{staticClass:"col-md-8"},[_c('label',{staticClass:"small"},[_vm._v(_vm._s(_vm.$t("nip.amount")))]),_c('ValidationProvider',{attrs:{"rules":"required|double|min_value:0.01","name":"amount"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"amountInput","description":""}},[_c('b-form-input',{staticClass:"custom-amount",attrs:{"type":"text","state":errors[0] ? false : valid ? true : null,"placeholder":_vm.$t('editStandingOrder.amount')},on:{"input":_vm.convert},model:{value:(_vm.amount),callback:function ($$v) {_vm.amount=$$v},expression:"amount"}}),_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)]),(_vm.selected[0] !== _vm.accountCurrency.currency)?_c('div',[_c('div',{staticClass:"col-md-12 text-right"},[_c('p',{staticClass:"small",attrs:{"type":"number","readonly":""},model:{value:(_vm.result),callback:function ($$v) {_vm.result=$$v},expression:"result"}},[_vm._v(" "+_vm._s(_vm.result ? _vm.accountCurrency.currency : "")+" "+_vm._s(_vm.result ? _vm.$n(_vm.result.toFixed(2), "decimal") : "")+" ")])])]):_vm._e()]),_c('br'),_c('div',{staticClass:"background-modifier"},[_c('label',{staticClass:"small"},[_vm._v(_vm._s(_vm.$t("nip.message")))]),_c('ValidationProvider',{attrs:{"rules":"min:1|max:50","name":"message"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"messageInput","description":""}},[_c('b-form-input',{attrs:{"type":"text","state":errors[0] ? false : valid ? true : null,"placeholder":_vm.$t('nip.message')},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}}),_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),(_vm.debitAccountId > 0)?_c('div',[_c('br'),(_vm.creditAmount.credits - _vm.debitAmount.debits - _vm.result >= 0)?_c('div',[_c('b-button',{staticClass:"btn btn-info text-light btn-block",attrs:{"type":"submit"},on:{"click":_vm.isloading}},[_vm._v(_vm._s(_vm.$t("editStandingOrder.submit"))),(_vm.isloading)?_c('b-spinner',{staticClass:"ml-auto",attrs:{"variant":_vm.light}}):_vm._e()],1)],1):(
								_vm.result - (_vm.creditAmount.credits - _vm.debitAmount.debits) > 0
							)?_c('div',{staticClass:"red"},[_vm._v(" "+_vm._s(_vm.$t("newPayment.insufficientBalance"))+" ")]):_c('div',{staticClass:"red"},[_vm._v(" "+_vm._s(_vm.$t("editStandingOrder.accountsMustBeDifferent"))+" ")]),_c('hr'),_c('b-button',{staticClass:"btn btn-alert text-light btn-block",attrs:{"type":"reset"},on:{"click":function($event){return _vm.resetForm()}}},[_vm._v(_vm._s(_vm.$t("reset")))])],1):_vm._e(),(_vm.msg && _vm.$bvToast.show('notification-toast'))?_c('div'):_vm._e(),_c('div',{staticClass:"action"},[_c('br'),_c('b-toast',{attrs:{"id":"notification-toast","variant":"secondary","static":"","no-auto-hide":""},scopedSlots:_vm._u([{key:"toast-title",fn:function(){return [_c('div',{staticClass:"d-flex flex-grow-1 align-items-baseline"},[_c('b-img',{staticClass:"logo mb-2",attrs:{"src":require('/public/images/logo.png'),"alt":"","width":"12","height":"12"}}),_c('strong',{staticClass:"mr-auto"},[_vm._v(_vm._s(_vm.$t("notification")))]),_c('small',{staticClass:"text-muted mr-2"})],1)]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(_vm.msg)+" ")])],1)])])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }