<template>
	<div class="col-lg-4 offset-lg-4">
		<div class="text-left">
			<div class="">
				<div class="">
					<h1 class="title-bold">{{ $t("profile.profile") }}</h1>
					<hr />
					<div class="background-modifier">
						<div class="row">
							<div class="col-md-9">
								<h4 class="title-bold">{{ firstname }} {{ lastname }}</h4>
							</div>
							<div class="col-md-3 text-right">
								<b-avatar variant="secondary" size="3rem">
									{{ firstname.charAt() }}{{ lastname.charAt() }}
								</b-avatar>
							</div>
						</div>
						<div v-if="role == '1'">
							<label class="small">{{ $t("profile.authorizations") }}</label>
							<p class="text-left">
								{{ $t("role.user") }}
							</p>
						</div>
						<div v-if="role == '3'">
							<label class="small">{{ $t("profile.authorizations") }}</label>
							<p class="text-left">
								{{ $t("role.admin") }}
							</p>
						</div>
						<div v-if="role == '11'">
							<label class="small">{{ $t("profile.authorizations") }}</label>
							<p class="text-left">
								{{ $t("profile.viewingRights") }} ({{ $t("role.accountant") }})
							</p>
						</div>
						<div v-if="role == '12'">
							<label class="small">{{ $t("profile.authorizations") }}</label>
							<p class="text-left">
								{{ $t("profile.viewingAndPreparationRights") }} ({{
									$t("role.employee")
								}})
							</p>
						</div>
						<div v-if="role == '13'">
							<label class="small">{{ $t("profile.authorizations") }}</label>
							<p class="text-left">
								{{ $t("profile.viewingPreparationAndAuthorizationRights") }} ({{
									$t("role.accountant")
								}})
							</p>
						</div>
						<div v-if="role == '14'">
							<label class="small">{{ $t("profile.authorizations") }}</label>
							<p class="text-left">
								{{
									$t(
										"profile.viewingPreparationAuthorizationAndContractSigningRights"
									)
								}}
								({{ $t("role.admin") }})
							</p>
						</div>
						<div v-if="role == '15'">
							<label class="small">{{ $t("profile.authorizations") }}</label>
							<p class="text-left">
								{{ $t("profile.masterUser") }} ({{ $t("role.masterUser") }})
							</p>
						</div>
					</div>
					<hr />
					<div class="text-left">
						<router-link
							:to="{ name: 'file-upload' }"
							class="btn btn-secondary mx-1 "
							>{{ $t("profile.fileUpload") }}</router-link
						>
					</div>
					<hr />
					<div class="text-left">
						<small class="product-details text text-grey">
							<!-- <font-awesome-icon icon="exclamation-circle" /> -->
							{{ $t("profile.downloadOnlyValidIf") }}
						</small>
						<hr />
						<a
							class="btn btn-secondary mx-1 "
							href="itms-services://?action=download-manifest&url=https://www.n3consortium.com/apps/manifest.plist"
							>{{ $t("profile.downloadApp") }}
						</a>
					</div>
					<hr />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import AuthService from "@/services/AuthService.js";
	export default {
		data() {
			return {
				firstname: "",
				lastname: "",
				username: "",
				role: "",
			};
		},
		async created() {
			if (!this.$store.getters.isLoggedIn) {
				this.$router.push("login");
			}
			this.firstname = this.$store.getters.getUser.firstname;
			this.lastname = this.$store.getters.getUser.lastname;
			this.username = this.$store.getters.getUser.username;
			this.role = this.$store.getters.getUser.role;
		},
	};
</script>
