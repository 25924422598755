import {
  required,
  confirmed,
  length,
  email,
  min,
  max,
  min_value,
  max_value,
  alpha,
  alpha_dash,
  alpha_spaces,
  regex,
  numeric,
  integer,
  double,
} from "vee-validate/dist/rules";
import { extend } from "vee-validate";

extend("required", {
  ...required,
  // message: "This field is required"
});

extend("email", {
  ...email,
});

extend("confirmed", {
  ...confirmed,
});

extend("length", {
  ...length,
});

extend("min", {
  ...min,
});

extend("max", {
  ...max,
});

extend("alpha", {
  ...alpha,
});

extend("alpha_dash", {
  ...alpha_dash,
});

extend("alpha_spaces", {
  ...alpha_spaces,
});

extend("regex", {
  ...regex,
});

extend("numeric", {
  ...numeric,
});

extend("integer", {
  ...integer,
});

extend("double", {
  ...double,
});

extend("min_value", {
  ...min_value,
});

extend("max_value", {
  ...max_value,
});
