<template>
	<ValidationObserver ref="observer" v-slot="{ handleSubmit, reset }">
		<b-form @submit.prevent="handleSubmit(signUp)" @reset.prevent="reset">
			<div class="col-lg-4 offset-lg-4 text-left">
				<h2 class="title-bold">{{ $t("signup.signup") }}</h2>
				<hr />
				<div class="background-outline">
					<div class="background-modifier">
						<label class="small">{{ $t("signup.username") }}</label>
						<ValidationProvider
							rules="required|alpha_dash|min:3|max:50"
							name="username"
							v-slot="{ valid, errors }"
						>
							<b-form-group label-for="usernameInput" description="">
								<b-form-input
									type="text"
									v-model="username"
									:state="errors[0] ? false : valid ? true : null"
									:placeholder="$t('signup.username')"
								></b-form-input>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>
						<label class="small">{{ $t("signup.email") }}</label>
						<ValidationProvider
							rules="required|email"
							name="email"
							v-slot="{ valid, errors }"
						>
							<b-form-group label-for="emailInput" description="">
								<b-form-input
									type="email"
									v-model="email"
									:state="errors[0] ? false : valid ? true : null"
									:placeholder="$t('signup.email')"
								></b-form-input>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>
					</div>
					<br />

					<div class="background-modifier">
						<label class="small">{{ $t("signup.firstname") }}</label>
						<ValidationProvider
							rules="required|alpha_spaces|min:2|max:50"
							name="firstname"
							v-slot="{ valid, errors }"
						>
							<b-form-group label-for="firstnameInput" description="">
								<b-form-input
									type="text"
									v-model="firstname"
									:state="errors[0] ? false : valid ? true : null"
									:placeholder="$t('signup.firstname')"
								></b-form-input>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>

						<label class="small">{{ $t("signup.lastname") }}</label>
						<ValidationProvider
							rules="required|alpha_spaces|min:2|max:50"
							name="lastname"
							v-slot="{ valid, errors }"
						>
							<b-form-group label-for="lastnameInput" description="">
								<b-form-input
									type="text"
									v-model="lastname"
									:state="errors[0] ? false : valid ? true : null"
									:placeholder="$t('signup.lastname')"
								></b-form-input>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>
					</div>
					<br />

					<div class="background-modifier">
						<label class="small">{{ $t("signup.password") }}</label>
						<ValidationProvider
							rules="required|min:6|max:30"
							name="password"
							vid="password"
							v-slot="{ valid, errors }"
						>
							<b-form-group description="">
								<b-form-input
									type="password"
									v-model="password"
									:state="errors[0] ? false : valid ? true : null"
									:placeholder="$t('signup.password')"
								></b-form-input>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>

						<label class="small">{{ $t("signup.repeatPassword") }}</label>
						<ValidationProvider
							rules="required|confirmed:password"
							name="Password confirmation"
							v-slot="{ valid, errors }"
						>
							<b-form-group label="" label-for="repeatPasswordInput1">
								<b-form-input
									type="password"
									v-model="password_repeat"
									:state="errors[0] ? false : valid ? true : null"
									:placeholder="$t('signup.repeatPassword')"
								></b-form-input>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>
					</div>
					<br />
					<!-- <div class="background-modifier">
          <ValidationProvider
            rules="required|min:5|max:50"
            name="addressLine1"
            v-slot="{ valid, errors }"
          >
            <b-form-group label-for="addressLine1Input" description="">
              <b-form-input
                type="text"
                v-model="addressLine1"
                :state="errors[0] ? false : valid ? true : null"
                :placeholder="$t('signup.address')"
              ></b-form-input>
              <b-form-invalid-feedback id="inputLiveFeedback">{{
                errors[0]
              }}</b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
          <ValidationProvider
            rules="required|min:5|max:50"
            name="city"
            v-slot="{ valid, errors }"
          >
            <b-form-group label-for="cityInput" description="">
              <b-form-input
                type="text"
                v-model="city"
                :state="errors[0] ? false : valid ? true : null"
                :placeholder="$t('signup.city')"
              ></b-form-input>
              <b-form-invalid-feedback id="inputLiveFeedback">{{
                errors[0]
              }}</b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
          <ValidationProvider
            rules="required|min:3|max:10"
            name="zip"
            v-slot="{ valid, errors }"
          >
            <b-form-group label-for="zipInput" description="">
              <b-form-input
                type="text"
                v-model="zip"
                :state="errors[0] ? false : valid ? true : null"
                :placeholder="$t('signup.postCode')"
              ></b-form-input>
              <b-form-invalid-feedback id="inputLiveFeedback">{{
                errors[0]
              }}</b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
          <ValidationProvider
            rules="required"
            name="country"
            v-slot="{ valid, errors }"
          >
            <b-form-group id="countryInputGroup" label-for="countryInput">
              <b-form-select
                @change="onChange"
                id="countryInput"
                :state="errors[0] ? false : valid ? true : null"
                v-model="country"
                ><option
                  v-for="country in countries"
                  :value="country.countryCode"
                  :key="country.countryCode"
                  >{{ countryNames.getName(country.countryCode, codeLang) }}
                </option>
                <template #first>
                  <b-form-select-option value="" disabled>{{
                    $t("signup.country")
                  }}</b-form-select-option>
                </template></b-form-select
              >
              <b-form-invalid-feedback id="inputLiveFeedback">{{
                errors[0]
              }}</b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </div>
        <br />
        <div class="background-modifier">
          <div class="row">
            <div class="col-md-6">
              <ValidationProvider
                rules="required"
                name="prefix"
                v-slot="{ valid, errors }"
              >
                <b-form-group id="prefixInputGroup" label-for="prefixInput">
                  <b-form-select
                    @change="onChange"
                    id="prefixInput"
                    :state="errors[0] ? false : valid ? true : null"
                    v-model="prefix"
                    ><option
                      v-for="country in countries"
                      :value="country.prefix"
                      :key="country.prefix"
                      >+ {{ country.prefix }}
                      {{ countryNames.getName(country.countryCode, codeLang) }}
                    </option>
                    <template #first>
                      <b-form-select-option value="" disabled>{{
                        $t("signup.country")
                      }}</b-form-select-option>
                    </template></b-form-select
                  >
                  <b-form-invalid-feedback id="inputLiveFeedback">{{
                    errors[0]
                  }}</b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
            </div>
            <div class="col-md-6">
              <ValidationProvider
                rules="required|numeric|min:5|max:12"
                name="mobile"
                v-slot="{ valid, errors }"
              >
                <b-form-group label-for="mobileInput" description="">
                  <b-form-input
                    type="text"
                    v-model="mobile"
                    :state="errors[0] ? false : valid ? true : null"
                    :placeholder="$t('signup.phoneNumber')"
                  ></b-form-input>
                  <b-form-invalid-feedback id="inputLiveFeedback">{{
                    errors[0]
                  }}</b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
            </div>
          </div>
        </div>
        <br />
        <div class="background-modifier text-left">
          <div>
            <label for="datepicker-placeholder">{{ $t("signup.dob") }}</label>
            <b-form-datepicker
              id="datepicker-placeholder"
              :placeholder="$t('signup.dob')"
              v-model="dob"
              :min="min"
              :max="max"
              :locale="$i18n.locale"
              :label-no-date-selected="$t('signup.noDateSelected')"
              :label-help="$t('signup.useCursorKeysToNavigateCalendarDates')"
            ></b-form-datepicker>
          </div>
        </div>
        <br />
        <div class="background-modifier">
          <ValidationProvider
            rules="required|numeric|min:6|max:6"
            name="pin"
            v-slot="{ valid, errors }"
          >
            <b-form-group label-for="pinInput" description="">
              <b-form-input
                type="text"
                v-model="pin"
                :state="errors[0] ? false : valid ? true : null"
                :placeholder="$t('signup.pin')"
              ></b-form-input>
              <b-form-invalid-feedback id="inputLiveFeedback">{{
                errors[0]
              }}</b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </div>
        <br />
        <b-form-checkbox
          class="text-left"
          id="checkbox-1"
          v-model="status"
          name="checkbox-1"
          value="accepted"
          unchecked-value="not_accepted"
        >
          {{ $t("signup.acceptTerms") }}
        </b-form-checkbox>
        <br /> -->
					<!-- disabled-->
					<!-- <div v-if="status == 'accepted'"> -->
					<b-button
						disabled
						type="submit"
						@click="isloading"
						class="btn btn-info text-light btn-block"
						>{{ $t("signup.submit")
						}}<b-spinner
							v-if="isloading"
							class="ml-auto"
							:variant="light"
						></b-spinner
					></b-button>
					<p class="small">{{ $t("signup.notAvailable") }}</p>
					<!-- </div> -->
					<hr />
					<b-button
						type="reset"
						class="btn btn-alert text-light btn-block"
						@click="resetForm()"
						>{{ $t("reset") }}</b-button
					>
					<p class="forgot-password text-right">
						{{ $t("signup.alreadyRegistered") }}
						<router-link :to="{ name: 'login' }">{{
							$t("signup.login")
						}}</router-link>
					</p>

					<div v-if="msg && $bvToast.show('notification-toast')"></div>
					<div class="action">
						<br />
						<b-toast
							id="notification-toast"
							variant="secondary"
							static
							no-auto-hide
						>
							<template #toast-title>
								<div class="d-flex flex-grow-1 align-items-baseline">
									<b-img
										class="logo mb-2"
										:src="require('/public/images/logo.png')"
										alt=""
										width="12"
										height="12"
									></b-img>
									<strong class="mr-auto">{{ $t("notification") }}</strong>
									<small class="text-muted mr-2"></small>
								</div>
							</template>
							{{ msg }}
						</b-toast>
					</div>
				</div>
			</div>
		</b-form>
	</ValidationObserver>
</template>

<script>
	import { ValidationObserver, ValidationProvider } from "vee-validate";
	import AuthService from "@/services/AuthService.js";
	var countryNames = require("i18n-iso-countries");
	export default {
		name: "SignUpForm",
		components: {
			ValidationObserver,
			ValidationProvider,
		},
		/*props: {
    codeLang: {
      type: String,
      default: "en",
    },
  },*/
		//this.codeLang
		created() {
			this.codeLang = this.$i18n.locale;
			this.countryNames.registerLocale(
				require(`i18n-iso-countries/langs/${this.codeLang}.json`)
			);
		},
		watch: {
			codeLang(newVal, oldVal) {
				this.countryNames.registerLocale(
					require(`i18n-iso-countries/langs/${newVal}.json`)
				);
			},
		},
		data() {
			const now = new Date();
			const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
			// 15th two months prior
			const minDate = new Date(today);
			minDate.setYear(minDate.getFullYear() - 130);
			const maxDate = new Date(today);
			maxDate.setYear(maxDate.getFullYear() - 18);
			// minDate.setMonth(minDate.getMonth() - 2);
			// minDate.setDate(15);
			// 15th in two months

			return {
				countries: [],
				countryNames: countryNames,
				username: "",
				email: "",
				firstname: "",
				lastname: "",
				password: "",
				password_repeat: "",
				msg: "",
				pin: "",
				dob: maxDate.toISOString().slice(0, 10),
				addressLine1: "",
				city: "",
				zip: "",
				country: "",
				prefix: "",
				mobile: "",
				value: "",
				min: minDate,
				max: maxDate,
				status: "not_accepted",
				isloading: false,
			};
		},

		methods: {
			async signUp() {
				try {
					const credentials = {
						lang: this.$i18n.locale,
						username: this.username,
						email: this.email,
						firstname: this.firstname,
						lastname: this.lastname,
						password: this.password,
						password_repeat: this.password_repeat,
						pin: this.pin,
						dob: this.dob,
						addressLine1: this.addressLine1,
						city: this.city,
						zip: this.zip,
						country: this.country,
						phoneNumber: "+" + this.prefix + this.mobile,
					};
					let reference = this.$route.params.reference;
					this.isloading = true;
					const response = await AuthService.signUp(reference, credentials);
					this.resetForm();
					this.msg = response.msg;
					this.isloading = false;
				} catch (error) {
					this.msg = error.response.data.msg;
					this.isloading = false;
					/*this.$router.push('home');*/
				}
			},
			resetForm() {
				this.username = "";
				this.firstname = "";
				(this.lastname = ""), (this.email = "");
				this.password = "";
				this.password_repeat = "";
				this.msg = "";
				this.pin = "";
				this.dob = "";
				this.addressLine1 = "";
				this.city = "";
				this.zip = "";
				this.country = "";
				this.prefix = "";
				this.mobile = "";
				requestAnimationFrame(() => {
					this.$refs.observer.reset();
				});
			},
			async getCountries() {
				try {
					const response = await AuthService.getCountries();
					this.countries = response;
					console.log(response);
				} catch (error) {
					console.log(error);
				}
			},
		},
		mounted() {
			this.getCountries();
		},
	};
</script>
