<template>
	<ValidationObserver ref="observer" v-slot="{ handleSubmit, reset }">
		<b-form
			@submit.prevent="handleSubmit(newTransaction)"
			@reset.prevent="reset"
		>
			<div class="col-4 offset-md-4 text-left">
				<h2 class="title-bold">{{ $t("newTransaction.newTransaction") }}</h2>
				<hr />

				<div v-if="isloading2" class="text-center">
					<div class="background-outline">
						<b-spinner
							style="width: 3rem; height: 3rem;"
							class="ml-auto text-center"
							:variant="secondary"
						></b-spinner>
					</div>
					<br />
				</div>
				<div v-else class="background-outline">
					<div class="background-modifier">
						<label class="small">{{
							$t("newTransaction.debitAccountId")
						}}</label>
						<ValidationProvider
							name="debitAccountId"
							rules="required"
							v-slot="{ valid, errors }"
						>
							<b-form-group
								id="debitAccountIdInputGroup"
								label-for="debitAccountIdInput"
							>
								<b-form-select
									id="debitAccountIdInput"
									:state="errors[0] ? false : valid ? true : null"
									v-model="debitAccountId"
									><option
										v-for="account in accounts"
										:value="account.id"
										:key="account.id"
										>{{ account.id }} {{ account.firstname }}
										{{ account.lastname }}
										{{ $n(account.credits - account.debits, "decimal") }}
										{{ account.currency }}</option
									>
									<template #first>
										<b-form-select-option value="" disabled>{{
											$t("newTransaction.debitAccountId")
										}}</b-form-select-option>
									</template></b-form-select
								>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>

						<label class="small">{{
							$t("newTransaction.creditAccountId")
						}}</label>
						<ValidationProvider
							name="creditAccountId"
							rules="required"
							v-slot="{ valid, errors }"
						>
							<b-form-group
								id="creditAccountIdInputGroup"
								label-for="creditAccountIdInput"
							>
								<b-form-select
									id="creditAccountIdInput"
									:state="errors[0] ? false : valid ? true : null"
									v-model="creditAccountId"
									><option
										v-for="account in accounts"
										:value="account.id"
										:key="account.id"
										>{{ account.id }} {{ account.firstname }}
										{{ account.lastname }}
										{{ $n(account.credits - account.debits, "decimal") }}
										{{ account.currency }}</option
									>
									<template #first>
										<b-form-select-option value="" disabled>{{
											$t("newTransaction.creditAccountId")
										}}</b-form-select-option>
									</template></b-form-select
								>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>
					</div>
					<br />

					<!--    <div class="input-group mb-3">
          <input type="text" class="form-control" :placeholder="$t('newTransaction.beneficiaryName')" v-model="creditAccountHolder" />
        </div> -->

					<div class="background-modifier">
						<label class="small">{{ $t("newTransaction.amount") }}</label>
						<ValidationProvider
							rules="required|double|max_value:1000000|min_value:0.01"
							name="amount"
							v-slot="{ valid, errors }"
						>
							<b-form-group label-for="amountInput" description="">
								<b-form-input
									type="text"
									v-model="amount"
									:state="errors[0] ? false : valid ? true : null"
									:placeholder="$t('newTransaction.amount')"
								></b-form-input>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>
					</div>

					<br />
					<div class="background-modifier">
						<label class="small">{{ $t("newTransaction.message") }}</label>
						<ValidationProvider
							rules="min:1|max:50"
							name="message"
							v-slot="{ valid, errors }"
						>
							<b-form-group label-for="messageInput" description="">
								<b-form-input
									type="text"
									v-model="message"
									:state="errors[0] ? false : valid ? true : null"
									:placeholder="$t('newTransaction.message')"
								></b-form-input>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>
					</div>
					<br />
					<b-button
						type="submit"
						@click="isloading"
						class="btn btn-info text-light btn-block"
						>{{ $t("newTransaction.submit")
						}}<b-spinner
							v-if="isloading"
							class="ml-auto"
							:variant="light"
						></b-spinner
					></b-button>
					<hr />
					<b-button
						type="reset"
						class="btn btn-alert text-light btn-block"
						@click="resetForm()"
						>{{ $t("reset") }}</b-button
					>

					<div v-if="msg && $bvToast.show('notification-toast')"></div>
					<div class="action">
						<br />
						<b-toast
							id="notification-toast"
							variant="secondary"
							static
							no-auto-hide
						>
							<template #toast-title>
								<div class="d-flex flex-grow-1 align-items-baseline">
									<b-img
										class="logo mb-2"
										:src="require('/public/images/logo.png')"
										alt=""
										width="12"
										height="12"
									></b-img>
									<strong class="mr-auto">{{ $t("notification") }}</strong>
									<small class="text-muted mr-2"></small>
								</div>
							</template>
							{{ msg }}
						</b-toast>
					</div>
				</div>
			</div>
		</b-form>
	</ValidationObserver>
</template>

<script>
	import { ValidationObserver, ValidationProvider } from "vee-validate";
	import AuthService from "@/services/AuthService.js";

	export default {
		name: "NewTransactionForm",
		components: {
			ValidationObserver,
			ValidationProvider,
		},
		data() {
			return {
				accounts: [],
				debitAccountId: "",
				creditAccountId: "",
				amount: "",
				message: "",
				msg: "",
				paymentStatus: 1,
				isloading: false,
				isloading2: false,
			};
		},
		async created() {
			if (!this.$store.getters.isLoggedIn) {
				this.$router.push("login");
			}
			if (
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role !== 3
			) {
				this.$router.push("overview");
			}

			this.role = this.$store.getters.getUser.role;
		},
		methods: {
			async getAccountList() {
				try {
					this.isloading2 = true;
					const response = await AuthService.getAccountList();
					this.accounts = response;
					this.isloading2 = false;
					console.log(response);
				} catch (error) {
					this.isloading2 = false;
					console.log(error);
				}
			},
			async newTransaction() {
				try {
					const details = {
						lang: this.$i18n.locale,
						debitAccountId: this.debitAccountId,
						creditAccountId: this.creditAccountId,
						//    creditAccountHolder: this.creditAccountHolder,
						amount: this.amount,
						message: this.message,
						paymentStatus: this.paymentStatus,
						// userId: this.userId = this.$store.getters.getUser.id,
					};
					this.isloading = true;
					const response = await AuthService.newTransaction(details);
					this.resetForm();
					this.msg = response.msg;
					this.isloading = false;
				} catch (error) {
					this.msg = error.response.data.msg;
					this.isloading = false;
					/*this.$router.push('accounts');*/
				}
			},
			resetForm() {
				this.debitAccountId = "";
				this.creditAccountId = "";
				this.amount = "";
				this.message = "";
				this.msg = "";
				requestAnimationFrame(() => {
					this.$refs.observer.reset();
				});
			},
		},
		mounted() {
			this.getAccountList();
		},
	};
</script>
