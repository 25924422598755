var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.newExtendedPayment)},"reset":function($event){$event.preventDefault();return reset($event)}}},[_c('div',{staticClass:"col-lg-4 offset-lg-4 text-left"},[_c('h2',{staticClass:"title-bold"},[_vm._v(_vm._s(_vm.$t("newPayment.newPayment")))]),_c('hr'),(_vm.isloading2)?_c('div',{staticClass:"text-center"},[_c('div',{staticClass:"background-outline"},[_c('b-spinner',{staticClass:"ml-auto text-center",staticStyle:{"width":"3rem","height":"3rem"},attrs:{"variant":_vm.secondary}})],1),_c('br')]):_c('div',{staticClass:"background-outline"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-10"},[_c('h5',{staticClass:"title-bold"},[_vm._v(" "+_vm._s(_vm.$t("newPayment.thisFormForSEPAandSWIFT"))+" ")])]),_c('div',{staticClass:"col-md-2"},[_c('div',{staticClass:"text-right"},[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"text-right",attrs:{"variant":"outline","title":_vm.$t('newPayment.executeImmediateOrLater')}},[_vm._v(" ⓘ ")])],1)])]),_c('hr'),_c('div',{staticClass:"background-modifier"},[_c('label',{staticClass:"small"},[_vm._v(" "+_vm._s(_vm.$t("newPayment.startByChoosingDebitAccount"))+" ")]),_c('ValidationProvider',{attrs:{"rules":"required","name":"debitAccountId"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"id":"debitAccountIdInputGroup","label-for":"debitAccountIdInput"}},[_c('b-form-select',{attrs:{"id":"debitAccountIdInput","state":errors[0] ? false : valid ? true : null},on:{"change":_vm.onChange},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":"","disabled":""}},[_vm._v(_vm._s(_vm.$t("newPayment.debitAccountId")))])]},proxy:true}],null,true),model:{value:(_vm.debitAccountId),callback:function ($$v) {_vm.debitAccountId=$$v},expression:"debitAccountId"}},_vm._l((_vm.accounts),function(account){return _c('option',{key:account.id,domProps:{"value":account.id}},[_vm._v("("+_vm._s(account.id)+") "+_vm._s(account.iban.toString().replace(/\w{4}(?=.)/g, "$& "))+" "+_vm._s(_vm.$n(account.credits - account.debits, "decimal"))+" "+_vm._s(account.currency))])}),0),_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('br'),_c('b-form-group',{attrs:{"label":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ibanSlot = ref.ibanSlot;
return [_c('b-form-radio',{attrs:{"aria-describedby":ibanSlot,"name":"radio_group_2","value":"1"},model:{value:(_vm.isIBAN),callback:function ($$v) {_vm.isIBAN=$$v},expression:"isIBAN"}},[_vm._v(_vm._s(_vm.$t("newPayment.beneficiaryAccountIsIBAN")))]),_c('b-form-radio',{attrs:{"aria-describedby":ibanSlot,"name":"radio_group_2","value":"0"},model:{value:(_vm.isIBAN),callback:function ($$v) {_vm.isIBAN=$$v},expression:"isIBAN"}},[_vm._v(_vm._s(_vm.$t("newPayment.beneficiaryAccountIsBBAN")))])]}}],null,true)}),(_vm.isIBAN == 1)?_c('div',[_c('div',{staticClass:"background-modifier"},[(_vm.debitAccountId > 0)?_c('div',[(_vm.creditAccountId == '' && _vm.creditAccountId2 == '')?_c('div',[_c('label',{staticClass:"small"},[_vm._v(" "+_vm._s(_vm.$t("newPayment.selectStoredBeneficiaryOrInsertANewOne"))+" ")])]):_vm._e(),(_vm.creditAccountId == '')?_c('div',[(_vm.beneficiaries.length > 0)?_c('div',[_c('label',{staticClass:"small"},[_vm._v(_vm._s(_vm.$t("newPayment.creditAccountId")))]),_c('ValidationProvider',{attrs:{"rules":"","name":"creditAccountId2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"id":"creditAccountIdInputGroup2","label-for":"creditAccountIdInput2"}},[_c('b-form-select',{attrs:{"id":"creditAccountIdInput2","state":errors[0] ? false : valid ? true : null},on:{"input":function($event){return _vm.getBeneficiaryName()}},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":"","disabled":""}},[_vm._v(_vm._s(_vm.$t("newPayment.creditAccountId")))])]},proxy:true}],null,true),model:{value:(_vm.creditAccountId2),callback:function ($$v) {_vm.creditAccountId2=$$v},expression:"creditAccountId2"}},_vm._l((_vm.beneficiaries),function(beneficiary){return _c('option',{key:beneficiary.beneficiaryAccount,domProps:{"value":beneficiary.beneficiaryAccount}},[_vm._v("("+_vm._s(beneficiary.beneficiaryAccount)+") "+_vm._s(beneficiary.alias)+" "+_vm._s(beneficiary.beneficiaryIBAN .toString() .replace(/\w{4}(?=.)/g, "$& "))+" ")])}),0),_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1):_vm._e(),_c('label',{staticClass:"small"},[_vm._v(_vm._s(_vm.beneficiaryDetails ? _vm.$t("newPayment.beneficiaryName") : ""))]),_c('p',[_vm._v(" "+_vm._s(_vm.beneficiaryDetails ? _vm.beneficiaryDetails.beneficiaryName : "")+" ")])]):_vm._e()]):_vm._e(),(_vm.creditAccountId2 == '')?_c('div',[(_vm.isIBAN == 1)?_c('div',[_c('label',{staticClass:"small"},[_vm._v(_vm._s(_vm.$t("newPayment.creditAccountId")))]),_c('ValidationProvider',_vm._b({staticClass:"w-full",attrs:{"rules":{ iban: true },"vid":_vm.name,"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"creditAccountIdInput","description":""}},[_c('b-form-input',{attrs:{"type":"text","state":errors[0] ? false : _vm.valid ? true : null,"placeholder":_vm.$t('newPayment.creditAccountId')},model:{value:(_vm.creditAccountId),callback:function ($$v) {_vm.creditAccountId=$$v},expression:"creditAccountId"}}),_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)},'ValidationProvider',_vm.$attrs,false))],1):_vm._e(),_c('label',{staticClass:"small"},[_vm._v(_vm._s(_vm.$t("newPayment.beneficiaryName")))]),_c('ValidationProvider',{attrs:{"rules":"alpha_spaces|min:2|max:50","name":"creditAccountHolder"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"creditAccountHolder","description":""}},[_c('b-form-input',{attrs:{"type":"text","state":errors[0] ? false : valid ? true : null,"placeholder":_vm.$t('newPayment.beneficiaryName')},model:{value:(_vm.creditAccountHolder),callback:function ($$v) {_vm.creditAccountHolder=$$v},expression:"creditAccountHolder"}}),_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1):_vm._e()]),_c('br')]):_vm._e(),(_vm.isIBAN == 0)?_c('div',[_c('div',{staticClass:"background-modifier"},[_c('label',{staticClass:"small"},[_vm._v(" "+_vm._s(_vm.$t("newPayment.creditAccountId")))]),_c('ValidationProvider',{attrs:{"rules":"alpha_dash|max:35","name":"creditAccountId"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"creditAccountIdInput","description":""}},[_c('b-form-input',{attrs:{"type":"text","state":errors[0] ? false : valid ? true : null,"placeholder":_vm.$t('newPayment.creditAccountId')},model:{value:(_vm.creditAccountId),callback:function ($$v) {_vm.creditAccountId=$$v},expression:"creditAccountId"}}),_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('b-form-group',{attrs:{"label":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var bicSlot = ref.bicSlot;
return [_c('b-form-radio',{attrs:{"aria-describedby":bicSlot,"name":"radio_group_3","value":"1"},model:{value:(_vm.isBIC),callback:function ($$v) {_vm.isBIC=$$v},expression:"isBIC"}},[_vm._v(_vm._s(_vm.$t("newPayment.beneficiaryBankUsesBIC")))]),_c('b-form-radio',{attrs:{"aria-describedby":bicSlot,"name":"radio_group_3","value":"0"},model:{value:(_vm.isBIC),callback:function ($$v) {_vm.isBIC=$$v},expression:"isBIC"}},[_vm._v(_vm._s(_vm.$t("newPayment.beneficiaryBankDoesNotUseBIC")))])]}}],null,true)}),(_vm.isBIC == 1)?_c('div',[_c('label',{staticClass:"small"},[_vm._v(_vm._s(_vm.$t("newPayment.bic")))]),_c('ValidationProvider',_vm._b({staticClass:"w-full",attrs:{"rules":{ bic: true },"vid":_vm.name1,"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text","state":errors[0] ? false : _vm.valid ? true : null,"placeholder":_vm.$t('newPayment.bic')},model:{value:(_vm.BICfield),callback:function ($$v) {_vm.BICfield=$$v},expression:"BICfield"}}),_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)},'ValidationProvider',_vm.$attrs,false)),_c('br')],1):_vm._e(),(_vm.isBIC == 0)?_c('div',[_c('label',{staticClass:"small"},[_vm._v(_vm._s(_vm.$t("newPayment.beneficiaryBank")))]),_c('ValidationProvider',{attrs:{"rules":"alpha_spaces|min:2|max:50","name":"beneficiaryBank"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"beneficiaryBank","description":""}},[_c('b-form-input',{attrs:{"type":"text","state":errors[0] ? false : valid ? true : null,"placeholder":_vm.$t('newPayment.beneficiaryBank')},model:{value:(_vm.beneficiaryBank),callback:function ($$v) {_vm.beneficiaryBank=$$v},expression:"beneficiaryBank"}}),_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('label',{staticClass:"small"},[_vm._v(_vm._s(_vm.$t("newPayment.beneficiaryBankAddressLine1")))]),_c('ValidationProvider',{attrs:{"rules":"required|min:5|max:50","name":"beneficiaryBankAddressLine1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"beneficiaryBankAddressLine1Input","description":""}},[_c('b-form-input',{attrs:{"type":"text","state":errors[0] ? false : valid ? true : null,"placeholder":_vm.$t('newPayment.beneficiaryBankAddressLine1')},model:{value:(_vm.beneficiaryBankAddressLine1),callback:function ($$v) {_vm.beneficiaryBankAddressLine1=$$v},expression:"beneficiaryBankAddressLine1"}}),_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('label',{staticClass:"small"},[_vm._v(_vm._s(_vm.$t("newPayment.beneficiaryBankAddressLine2")))]),_c('ValidationProvider',{attrs:{"rules":"min:5|max:50","name":"beneficiaryBankAddressLine2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"beneficiaryBankAddressLine2Input","description":""}},[_c('b-form-input',{attrs:{"type":"text","state":errors[0] ? false : valid ? true : null,"placeholder":_vm.$t('newPayment.beneficiaryBankAddressLine2')},model:{value:(_vm.beneficiaryBankAddressLine2),callback:function ($$v) {_vm.beneficiaryBankAddressLine2=$$v},expression:"beneficiaryBankAddressLine2"}}),_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('label',{staticClass:"small"},[_vm._v(_vm._s(_vm.$t("newPayment.beneficiaryBankCity")))]),_c('ValidationProvider',{attrs:{"rules":"required|min:5|max:50","name":"city"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"beneficiaryBankCityInput","description":""}},[_c('b-form-input',{attrs:{"type":"text","state":errors[0] ? false : valid ? true : null,"placeholder":_vm.$t('newPayment.beneficiaryBankCity')},model:{value:(_vm.beneficiaryBankCity),callback:function ($$v) {_vm.beneficiaryBankCity=$$v},expression:"beneficiaryBankCity"}}),_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('label',{staticClass:"small"},[_vm._v(_vm._s(_vm.$t("newPayment.beneficiaryBankZip")))]),_c('ValidationProvider',{attrs:{"rules":"required|min:3|max:10","name":"beneficiaryBankZip"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"beneficiaryBankZipInput","description":""}},[_c('b-form-input',{attrs:{"type":"text","state":errors[0] ? false : valid ? true : null,"placeholder":_vm.$t('newPayment.beneficiaryBankZip')},model:{value:(_vm.beneficiaryBankZip),callback:function ($$v) {_vm.beneficiaryBankZip=$$v},expression:"beneficiaryBankZip"}}),_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('label',{staticClass:"small"},[_vm._v(_vm._s(_vm.$t("newPayment.beneficiaryBankCountry")))]),_c('ValidationProvider',{attrs:{"rules":"required","name":"beneficiaryCountry"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"id":"beneficiaryBankCountryInputGroup","label-for":"beneficiaryBankCountryInput"}},[_c('b-form-select',{attrs:{"id":"countryInput","state":errors[0] ? false : valid ? true : null},on:{"change":_vm.onChange},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":"","disabled":""}},[_vm._v(_vm._s(_vm.$t("newPayment.beneficiaryBankCountry")))])]},proxy:true}],null,true),model:{value:(_vm.beneficiaryBankCountry),callback:function ($$v) {_vm.beneficiaryBankCountry=$$v},expression:"beneficiaryBankCountry"}},_vm._l((_vm.countries),function(country){return _c('option',{key:country.countryCode,domProps:{"value":country.countryCode}},[_vm._v(_vm._s(_vm.countryNames.getName(country.countryCode, _vm.codeLang))+" ")])}),0),_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('br')],1):_vm._e(),_c('label',{staticClass:"small"},[_vm._v(_vm._s(_vm.$t("newPayment.beneficiaryName")))]),_c('ValidationProvider',{attrs:{"rules":"alpha_spaces|min:2|max:50","name":"creditAccountHolder"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"creditAccountHolder","description":""}},[_c('b-form-input',{attrs:{"type":"text","state":errors[0] ? false : valid ? true : null,"placeholder":_vm.$t('newPayment.beneficiaryName')},model:{value:(_vm.creditAccountHolder),callback:function ($$v) {_vm.creditAccountHolder=$$v},expression:"creditAccountHolder"}}),_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('label',{staticClass:"small"},[_vm._v(_vm._s(_vm.$t("newPayment.beneficiaryAddressLine1")))]),_c('ValidationProvider',{attrs:{"rules":"required|min:5|max:50","name":"addressLine1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"addressLine1Input","description":""}},[_c('b-form-input',{attrs:{"type":"text","state":errors[0] ? false : valid ? true : null,"placeholder":_vm.$t('newPayment.beneficiaryAddressLine1')},model:{value:(_vm.beneficiaryAddressLine1),callback:function ($$v) {_vm.beneficiaryAddressLine1=$$v},expression:"beneficiaryAddressLine1"}}),_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('label',{staticClass:"small"},[_vm._v(_vm._s(_vm.$t("newPayment.beneficiaryAddressLine2")))]),_c('ValidationProvider',{attrs:{"rules":"min:5|max:50","name":"addressLine2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"addressLine2Input","description":""}},[_c('b-form-input',{attrs:{"type":"text","state":errors[0] ? false : valid ? true : null,"placeholder":_vm.$t('newPayment.beneficiaryAddressLine2')},model:{value:(_vm.beneficiaryAddressLine2),callback:function ($$v) {_vm.beneficiaryAddressLine2=$$v},expression:"beneficiaryAddressLine2"}}),_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('label',{staticClass:"small"},[_vm._v(_vm._s(_vm.$t("newPayment.beneficiaryCity")))]),_c('ValidationProvider',{attrs:{"rules":"required|min:5|max:50","name":"city"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"cityInput","description":""}},[_c('b-form-input',{attrs:{"type":"text","state":errors[0] ? false : valid ? true : null,"placeholder":_vm.$t('newPayment.beneficiaryCity')},model:{value:(_vm.beneficiaryCity),callback:function ($$v) {_vm.beneficiaryCity=$$v},expression:"beneficiaryCity"}}),_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('label',{staticClass:"small"},[_vm._v(_vm._s(_vm.$t("newPayment.beneficiaryZip")))]),_c('ValidationProvider',{attrs:{"rules":"required|min:3|max:10","name":"zip"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"zipInput","description":""}},[_c('b-form-input',{attrs:{"type":"text","state":errors[0] ? false : valid ? true : null,"placeholder":_vm.$t('newPayment.beneficiaryZip')},model:{value:(_vm.beneficiaryZip),callback:function ($$v) {_vm.beneficiaryZip=$$v},expression:"beneficiaryZip"}}),_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('label',{staticClass:"small"},[_vm._v(_vm._s(_vm.$t("newPayment.beneficiaryCountry")))]),_c('ValidationProvider',{attrs:{"rules":"required","name":"beneficiaryCountry"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"id":"countryInputGroup","label-for":"countryInput"}},[_c('b-form-select',{attrs:{"id":"countryInput","state":errors[0] ? false : valid ? true : null},on:{"change":_vm.onChange},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":"","disabled":""}},[_vm._v(_vm._s(_vm.$t("newPayment.beneficiaryCountry")))])]},proxy:true}],null,true),model:{value:(_vm.beneficiaryCountry),callback:function ($$v) {_vm.beneficiaryCountry=$$v},expression:"beneficiaryCountry"}},_vm._l((_vm.countries),function(country){return _c('option',{key:country.countryCode,domProps:{"value":country.countryCode}},[_vm._v(_vm._s(_vm.countryNames.getName(country.countryCode, _vm.codeLang))+" ")])}),0),_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('br')]):_vm._e(),(_vm.isloading3)?_c('div',{staticClass:"text-center"},[_c('div',{staticClass:"background-modifier"},[_c('b-spinner',{staticClass:"ml-auto text-center",staticStyle:{"width":"3rem","height":"3rem"},attrs:{"variant":_vm.secondary}})],1),_c('br')]):_c('div',{staticClass:"background-modifier"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('label',{staticClass:"small"},[_vm._v(_vm._s(_vm.$t("account.currency")))]),_c('ValidationProvider',{attrs:{"rules":"required","name":"selected"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"id":"sourceCurrencyInputGroup","label-for":_vm.sourceCurrencyInput}},[_c('b-form-select',{attrs:{"id":"sourceCurrencyInput","state":errors[0] ? false : valid ? true : null},on:{"change":_vm.convert},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":"","disabled":""}},[_vm._v(_vm._s(_vm.$t("newPayment.paymentCurrency")))])]},proxy:true}],null,true),model:{value:(_vm.selected[0]),callback:function ($$v) {_vm.$set(_vm.selected, 0, $$v)},expression:"selected[0]"}},_vm._l((_vm.currencies),function(currency){return _c('option',{key:currency.CharCode,domProps:{"value":currency.CharCode}},[_vm._v(_vm._s(currency.CharCode))])}),0),_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('div',{staticClass:"col-md-8"},[_c('label',{staticClass:"small"},[_vm._v(_vm._s(_vm.$t("newPayment.amount")))]),_c('ValidationProvider',{attrs:{"rules":"required|double|min_value:0.01","name":"amount"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"amountInput","description":""}},[_c('b-form-input',{staticClass:"custom-amount",attrs:{"type":"text","state":errors[0] ? false : valid ? true : null,"placeholder":_vm.$t('newPayment.amount')},on:{"input":_vm.convert},model:{value:(_vm.amount),callback:function ($$v) {_vm.amount=$$v},expression:"amount"}}),_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)]),(_vm.selected[0] !== _vm.accountCurrency.currency)?_c('div',[_c('div',{staticClass:"col-md-12 text-right"},[_c('p',{staticClass:"small",attrs:{"type":"number","readonly":""},model:{value:(_vm.result),callback:function ($$v) {_vm.result=$$v},expression:"result"}},[_vm._v(" "+_vm._s(_vm.result ? _vm.accountCurrency.currency : "")+" "+_vm._s(_vm.result ? _vm.$n(_vm.result.toFixed(2), "decimal") : "")+" ")])])]):_vm._e()]),_c('br'),_c('div',{staticClass:"background-modifier"},[_c('label',{staticClass:"small"},[_vm._v(_vm._s(_vm.$t("newPayment.message")))]),_c('ValidationProvider',{attrs:{"rules":"min:1|max:50","name":"message"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"messageInput","description":""}},[_c('b-form-input',{attrs:{"type":"text","state":errors[0] ? false : valid ? true : null,"placeholder":_vm.$t('newPayment.message')},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}}),_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('br'),(_vm.isIBAN == '1')?_c('div',[_c('div',{staticClass:"background-modifier"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 offset-md-0"},[_c('label',{staticClass:"small",attrs:{"for":""}},[_vm._v(_vm._s(_vm.$t("newPayment.dueDate")))]),_c('b-form-datepicker',{attrs:{"min":_vm.min,"locale":_vm.$i18n.locale,"label-no-date-selected":_vm.$t('newPayment.noDateSelected'),"label-help":_vm.$t('newPayment.useCursorKeysToNavigateCalendarDates')},model:{value:(_vm.dueDate),callback:function ($$v) {_vm.dueDate=$$v},expression:"dueDate"}})],1)]),_c('br'),_c('hr'),_c('b-form-group',{attrs:{"label":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var oneOrMany = ref.oneOrMany;
return [_c('b-form-radio',{attrs:{"aria-describedby":oneOrMany,"name":"radio_group_1","value":"once"},model:{value:(_vm.radio),callback:function ($$v) {_vm.radio=$$v},expression:"radio"}},[_vm._v(_vm._s(_vm.$t("newPayment.payOnce")))]),_c('b-form-radio',{attrs:{"aria-describedby":oneOrMany,"name":"radio_group_1","value":"monthly"},model:{value:(_vm.radio),callback:function ($$v) {_vm.radio=$$v},expression:"radio"}},[_vm._v(_vm._s(_vm.$t("newPayment.monthly")))])]}}],null,true)}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.radio === 'monthly'),expression:"radio === 'monthly'"}]},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 offset-md-0"},[_c('ValidationProvider',{attrs:{"rules":"","name":"endDate"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"endDateInput","description":""}},[_c('label',{staticClass:"small",attrs:{"for":""}},[_vm._v(_vm._s(_vm.$t("newPayment.endDate")))]),_c('b-form-datepicker',{attrs:{"min":_vm.minEnd,"state":errors[0] ? false : valid ? true : null,"locale":_vm.$i18n.locale,"label-no-date-selected":_vm.$t('newPayment.noDateSelected'),"label-help":_vm.$t(
															'newPayment.useCursorKeysToNavigateCalendarDates'
														)},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}}),_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)])])],1)]):_vm._e(),_c('br'),(_vm.isIBAN == '1')?_c('div',[(_vm.creditAccountId2 == '')?_c('div',[_c('div',{staticClass:"background-modifier"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 offset-md-0"},[_c('b-form-checkbox',{attrs:{"id":"checkbox-1","name":"checkbox-1","value":"accepted","unchecked-value":"not_accepted"},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}},[_vm._v(" "+_vm._s(_vm.$t("newPayment.storeBeneficiary"))+" ")])],1),_c('div',{staticClass:"col-md-6 offset-md-0"},[_c('label',{staticClass:"small"},[_vm._v(_vm._s(_vm.$t("newPayment.alias")))]),_c('ValidationProvider',{attrs:{"rules":"min:1|max:50","name":"alias"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
														var valid = ref.valid;
														var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"aliasInput","description":""}},[_c('b-form-input',{attrs:{"type":"text","state":errors[0] ? false : valid ? true : null,"placeholder":_vm.$t('newPayment.alias')},model:{value:(_vm.alias),callback:function ($$v) {_vm.alias=$$v},expression:"alias"}}),_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)])])]):_vm._e()]):_vm._e(),(_vm.debitAccountId > 0)?_c('div',[(
								_vm.debitAccountId != _vm.creditAccountId &&
									_vm.debitAccountId != _vm.creditAccountId2
							)?_c('div',[(_vm.creditAmount.credits - _vm.debitAmount.debits - _vm.result >= 0)?_c('div',[(_vm.role == 3)?_c('div',[_c('hr'),_c('b-button',{staticClass:"btn btn-info text-light btn-block",attrs:{"type":"submit"},on:{"click":_vm.isloading}},[_vm._v(_vm._s(_vm.$t("newPayment.submit"))),(_vm.isloading)?_c('b-spinner',{staticClass:"ml-auto",attrs:{"variant":_vm.light}}):_vm._e()],1)],1):_vm._e(),(_vm.role == 12)?_c('div',[_c('hr'),_c('b-button',{staticClass:"btn btn-info text-light btn-block",attrs:{"type":"submit"}},[_vm._v(_vm._s(_vm.$t("newPayment.submitForApproval")))])],1):_vm._e()]):(
									_vm.result - (_vm.creditAmount.credits - _vm.debitAmount.debits) > 0
								)?_c('div',{},[_c('hr'),_c('b-alert',{attrs:{"variant":"danger","show":""}},[_vm._v(_vm._s(_vm.$t("newPayment.insufficientBalance")))])],1):_vm._e()]):_c('div',{},[_c('b-alert',{attrs:{"variant":"danger","show":""}},[_vm._v(_vm._s(_vm.$t("newPayment.accountsMustBeDifferent")))])],1),_c('hr'),_c('b-button',{staticClass:"btn btn-alert text-light btn-block",attrs:{"type":"reset"},on:{"click":function($event){return _vm.resetForm()}}},[_vm._v(_vm._s(_vm.$t("reset")))])],1):_vm._e(),(_vm.msg && _vm.$bvToast.show('notification-toast'))?_c('div'):_vm._e(),_c('div',{staticClass:"action"},[_c('br'),_c('b-toast',{attrs:{"id":"notification-toast","variant":"secondary","static":"","no-auto-hide":""},scopedSlots:_vm._u([{key:"toast-title",fn:function(){return [_c('div',{staticClass:"d-flex flex-grow-1 align-items-baseline"},[_c('b-img',{staticClass:"logo mb-2",attrs:{"src":require('/public/images/logo.png'),"alt":"","width":"12","height":"12"}}),_c('strong',{staticClass:"mr-auto"},[_vm._v(_vm._s(_vm.$t("notification")))]),_c('small',{staticClass:"text-muted mr-2"})],1)]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(_vm.msg)+" ")])],1)],1)])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }