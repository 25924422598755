<template>
	<div class="col-12 offset-0">
		<div class="text-left">
			<div class="col-md-8 offset-md-2">
				<h1 class="title-bold">
					{{ $t("userManagement.userManagement") }}
				</h1>
				<hr />
				<div class="">
					<h3 class="text-bold">
						{{ $t("userManagement.users") }} ({{ users.length }})
					</h3>
					<hr />
				</div>
				<!-- <h3 class="title-bold">
          {{ $t("userManagement.users") }} ({{ users.length }})
        </h3> -->
				<!--   <form>
          <div class="input-group mb-3">
            <input type="text" class="form-control" :placeholder="$t('account.nickname')" v-model="account.nickname" />
            </div>
          <button type="submit" class="btn btn-info"
        @click="updateAccount"
      >{{ $t('newPayment.submit') }}
        </button>
        <p v-if="msg">{{ msg }}</p>
        </form> -->

				<div v-if="role == 3 || role == 15 || role == 14 || role == 13">
					<p class="open-account text-right">
						<router-link
							class="btn btn-outline"
							:to="{ name: 'create-user' }"
							>{{ $t("userManagement.newUser") }}</router-link
						>
					</p>
					<br />
				</div>

				<div v-if="msg && $bvToast.show('notification-toast')"></div>
				<div class="action">
					<b-toast
						id="notification-toast"
						variant="secondary"
						static
						no-auto-hide
					>
						<template #toast-title>
							<div class="d-flex flex-grow-1 align-items-baseline">
								<b-img
									class="logo mb-2"
									:src="require('/public/images/logo.png')"
									alt=""
									width="12"
									height="12"
								></b-img>
								<strong class="mr-auto">{{ $t("notification") }}</strong>
								<small class="text-muted mr-2"></small>
							</div>
						</template>
						{{ msg }}
					</b-toast>
				</div>

				<div class="">
					<!-- <div class="input-group mb-3">
            <input
              type="text"
              class="form-control"
              :placeholder="$t('userManagement.lastname')"
              v-model="searchTitle"
            />
            <div class="input-group-append">
              <button
                class="btn btn-outline"
                type="button"
                @click="
                  page = 1;
                  getAllUsers();
                "
              >
                {{ $t("search") }}
              </button>
            </div>
          </div> -->
					<!-- <div class="">
            <div class="mb-3">
              {{ $t("itemsPerPage") }}
              <select <select 
										class="custom-select" 
										style="width: 60px;" v-model="pageSize" @change="handlePageSizeChange($event)">
                <option v-for="size in pageSizes" :key="size" :value="size">
                  {{ size }}
                </option>
              </select>
            </div>

            <b-pagination
              v-model="page"
              :total-rows="count"
              :per-page="pageSize"
              :prev-text="$t('previous')"
              :next-text="$t('next')"
              @change="handlePageChange"
            ></b-pagination>
          </div> -->

					<div v-if="isloading" class="text-center">
						<b-spinner
							style="width: 3rem; height: 3rem;"
							class="ml-auto text-center"
							:variant="secondary"
						></b-spinner>
					</div>
					<div v-else-if="users.length > 0">
						<b-row>
							<div v-for="user in users" :key="user.id" class="col-6 card-grid">
								<router-link
									:to="{ name: 'user-details', params: { id: user.id } }"
									class=""
									tag="text-grey"
								>
									<div class="background-modifier ">
										<div class="row">
											<div class="col-md-9">
												<h4 class="title-bold">
													{{ user.firstname }} {{ user.lastname }}
												</h4>
											</div>
											<div class="col-md-3 text-right">
												<b-avatar variant="secondary" size="3rem">
													{{ user.firstname.charAt()
													}}{{ user.lastname.charAt() }}
												</b-avatar>
											</div>
										</div>
										<p class="">
											{{ user.role == 3 ? $t("role.admin") : $t("role.user") }}
										</p>

										<div class="">
											<div class="">
												<p class="">
													{{ $t("userManagement.active") }}:
													{{ user.active == 1 ? $t("yes") : $t("no") }}
												</p>
											</div>
											<div class="">
												<small></small>
											</div>
										</div>
									</div>
								</router-link>
							</div>
						</b-row>

						<!-- <div class="table-wrap">
              <table class="table mt-2 ">
                <thead>
                  <tr>
                    <th class="text-left">{{ $t("userManagement.id") }}</th>
                    <th class="text-left">
                      {{ $t("userManagement.username") }}
                    </th>
                    <th class="text-left">
                      {{ $t("userManagement.firstname") }}
                    </th>
                    <th class="text-left">
                      {{ $t("userManagement.lastname") }}
                    </th>
                    <th class="text-left">{{ $t("userManagement.email") }}</th>
                    <th class="text-right">{{ $t("userManagement.role") }}</th>
                    <th class="text-right">
                      {{ $t("userManagement.active") }}
                    </th>
                    <th class="text-right">
                      {{ $t("userManagement.registered") }}
                    </th>
                    <th class="text-right">
                      {{ $t("userManagement.lastLogin") }}
                    </th>
                    <th class="text-centered">
                      {{ $t("userManagement.actions") }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="user in users" :key="user.id">
                    <td class="text-left">{{ user.id }}</td>
                    <td class="text-left">{{ user.username }}</td>
                    <td class="text-left">{{ user.firstname }}</td>
                    <td class="text-left">{{ user.lastname }}</td>
                    <td class="text-left">{{ user.email }}</td>
                    <td class="text-right">
                      {{ user.role == 3 ? $t("role.admin") : $t("role.user") }}
                    </td>
                    <td class="text-right">
                      {{ user.active == 1 ? $t("yes") : $t("no") }}
                    </td>
                    <td class="text-right">
                      {{ $d(Date.parse(user.registered), "long") }}
                    </td>
                    <td class="text-right">
                      {{
                        user.lastLogin != null
                          ? $d(Date.parse(user.lastLogin), "long")
                          : $t("no")
                      }}
                    </td>
                    <td class="text-right">
                      <router-link
                        :to="{ name: 'user-details', params: { id: user.id } }"
                        class="btn btn-outline mx-1 "
                        >{{ $t("userManagement.view") }}</router-link
                      >
                      <a
                        class="btn btn-danger mx-1 "
                        @click="deleteUser(user.id)"
                        >{{ $t("userManagement.delete") }}</a
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div> -->
					</div>
					<div v-else>{{ $t("userManagement.noUsers") }}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import AuthService from "@/services/AuthService.js";

	export default {
		name: "UserList",
		data() {
			return {
				users: [],
				user: "",
				// count: [],
				msg: "",
				isloading: false,
				// currentUser: null,
				// currentIndex: -1,
				// searchTitle: "",

				// page: 1,
				// count: 0,
				// pageSize: 10,

				// pageSizes: [10, 25, 50],
			};
		},
		async created() {
			if (!this.$store.getters.isLoggedIn) {
				this.$router.push("login");
			}
			if (
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 3 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 15 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 14 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 13
			) {
				this.$router.push("overview");
			}
			let lang = this.$i18n.locale;
			this.role = this.$store.getters.getUser.role;
			this.username = this.$store.getters.getUser.username;
			this.firstname = this.$store.getters.getUser.firstname;
			// this.getClientsUserList();
		},
		methods: {
			// getRequestParams(searchTitle, page, pageSize) {
			//   let params = {};

			//   if (searchTitle) {
			//     params["lastname"] = searchTitle;
			//   }

			//   if (page) {
			//     params["page"] = page - 1;
			//   }

			//   if (pageSize) {
			//     params["size"] = pageSize;
			//   }

			//   return params;
			// },

			// async getClientsUserList() {
			//   const id = this.$store.getters.getUser.id;
			//   const params = this.getRequestParams(
			//     this.searchTitle,
			//     this.page,
			//     this.pageSize
			//   );

			//   try {
			//     const response = await AuthService.getClientsUserList(
			//       params.page,
			//       params.size,
			//       params.lastname,
			//       id
			//     );
			//     const { items, totalItems } = response.data;
			//     this.users = items;
			//     this.count = totalItems;
			//     console.log(response.data);
			//   } catch (error) {
			//     console.log(error);
			//   }
			// },

			// handlePageChange(value) {
			//   this.page = value;
			//   this.getClientsUserList();
			// },

			// handlePageSizeChange(event) {
			//   this.pageSize = event.target.value;
			//   this.page = 1;
			//   this.getClientsUserList();
			// },
			async getClientsUsers() {
				try {
					const id = this.$store.getters.getUser.id;
					this.isloading = true;
					const response = await AuthService.getClientsUsers(id);
					this.users = response;
					this.isloading = false;
					console.log(response);
				} catch (error) {
					this.isloading = false;
					console.log(error);
				}
			},
			async deleteUser(id) {
				try {
					const lang = this.$i18n.locale;
					const response = await AuthService.deleteUser(id, lang);
					console.log(response);
					// this.getClientsUserList();
					this.msg = response.msg;
					console.log(response.msg);
				} catch (error) {
					console.log(error);
					this.msg = error.response.data.msg;
				}
			},
		},
		mounted() {
			// this.getClientsUserList();
			this.getClientsUsers();
		},
	};
</script>
