var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.editUser)},"reset":function($event){$event.preventDefault();return reset($event)}}},[_c('div',{staticClass:"col-md-4 offset-md-4 text-left"},[_c('h2',{staticClass:"title-bold"},[_vm._v(_vm._s(_vm.$t("editUser.editUser"))+" "+_vm._s(_vm.user.id))]),_c('hr'),_c('div',{staticClass:"background-outline"},[_c('div',{staticClass:"background-modifier"},[_c('label',{staticClass:"text-left small"},[_vm._v(_vm._s(_vm.$t("editUser.username")))]),_c('ValidationProvider',{attrs:{"rules":"required|alpha_dash|min:3|max:50","name":"username"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"usernameInput","description":""}},[_c('b-form-input',{attrs:{"type":"text","state":errors[0] ? false : valid ? true : null,"placeholder":_vm.$t('editUser.username')},model:{value:(_vm.user.username),callback:function ($$v) {_vm.$set(_vm.user, "username", $$v)},expression:"user.username"}}),_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('label',{staticClass:"text-left small"},[_vm._v(_vm._s(_vm.$t("editUser.email")))]),_c('ValidationProvider',{attrs:{"rules":"required|email","name":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"emailInput","description":""}},[_c('b-form-input',{attrs:{"type":"email","state":errors[0] ? false : valid ? true : null,"placeholder":_vm.$t('editUser.email')},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}}),_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('br'),_c('div',{staticClass:"background-modifier"},[_c('label',{staticClass:"text-left small"},[_vm._v(_vm._s(_vm.$t("editUser.firstname")))]),_c('ValidationProvider',{attrs:{"rules":"required|alpha_spaces|min:2|max:50","name":"firstname"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"firstnameInput","description":""}},[_c('b-form-input',{attrs:{"type":"text","state":errors[0] ? false : valid ? true : null,"placeholder":_vm.$t('editUser.firstname')},model:{value:(_vm.user.firstname),callback:function ($$v) {_vm.$set(_vm.user, "firstname", $$v)},expression:"user.firstname"}}),_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('label',{staticClass:"text-left small"},[_vm._v(_vm._s(_vm.$t("editUser.lastname")))]),_c('ValidationProvider',{attrs:{"rules":"required|alpha_spaces|min:2|max:50","name":"lastname"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"lastnameInput","description":""}},[_c('b-form-input',{attrs:{"type":"text","state":errors[0] ? false : valid ? true : null,"placeholder":_vm.$t('editUser.lastname')},model:{value:(_vm.user.lastname),callback:function ($$v) {_vm.$set(_vm.user, "lastname", $$v)},expression:"user.lastname"}}),_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('br'),_c('div',{staticClass:"background-modifier"},[_c('label',{staticClass:"text-left small"},[_vm._v(_vm._s(_vm.$t("editUser.verificationStatus")))]),_c('b-form-group',{attrs:{"label":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var vStatus = ref.vStatus;
return [_c('b-form-radio',{attrs:{"aria-describedby":vStatus,"name":"radio_group_1","value":"1"},model:{value:(_vm.user.verificationStatus),callback:function ($$v) {_vm.$set(_vm.user, "verificationStatus", $$v)},expression:"user.verificationStatus"}},[_vm._v(_vm._s(_vm.$t("editUser.pending")))]),_c('b-form-radio',{attrs:{"aria-describedby":vStatus,"name":"radio_group_1","value":"3"},model:{value:(_vm.user.verificationStatus),callback:function ($$v) {_vm.$set(_vm.user, "verificationStatus", $$v)},expression:"user.verificationStatus"}},[_vm._v(_vm._s(_vm.$t("editUser.verified")))])]}}],null,true)})],1),_c('br'),_c('b-button',{staticClass:"btn btn-info text-light btn-block",attrs:{"type":"submit"},on:{"click":_vm.isloading}},[_vm._v(_vm._s(_vm.$t("editUser.submit"))),(_vm.isloading)?_c('b-spinner',{staticClass:"ml-auto",attrs:{"variant":_vm.light}}):_vm._e()],1),(_vm.msg && _vm.$bvToast.show('notification-toast'))?_c('div'):_vm._e(),_c('div',{staticClass:"action"},[_c('br'),_c('b-toast',{attrs:{"id":"notification-toast","variant":"secondary","static":"","no-auto-hide":""},scopedSlots:_vm._u([{key:"toast-title",fn:function(){return [_c('div',{staticClass:"d-flex flex-grow-1 align-items-baseline"},[_c('b-img',{staticClass:"logo mb-2",attrs:{"src":require('/public/images/logo.png'),"alt":"","width":"12","height":"12"}}),_c('strong',{staticClass:"mr-auto"},[_vm._v(_vm._s(_vm.$t("notification")))]),_c('small',{staticClass:"text-muted mr-2"})],1)]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(_vm.msg)+" ")])],1)],1)])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }