<template>
	<div class="col-12 offset-0">
		<div class="">
			<div class="text-left">
				<div class="col-md-8 offset-md-2">
					<h1 class="title-bold">{{ $t("overview.overview") }}</h1>
					<hr />
					<div class="background-outline">
						<div class="background-modifier">
							<p>{{ $t("hello") }} {{ username }}</p>
							<!--  <p>{{ $d(new Date(), "short") }}</p> -->
							<p>{{ userMessage }}</p>
						</div>
						<hr />
						<div v-if="!isloading" class="">	
								<div class="row">
									<div class="col-md-6 text-left">
										<!-- <div v-if="isloading2" class="">
											<b-spinner
												class="ml-auto text-center"
												:variant="secondary"
											></b-spinner>
											</div> -->
										<span>
											<h3 class="text-bold">
												{{
													totalBalance() != null
														? $n(totalBalance(), "decimal")
														: $n(0.0, "decimal")
												}}
											</h3>
											<p class="small">
												{{ $t("accounts.totalBalance") }} ({{
													this.baseCurrency
												}})
											</p>
										</span>
									</div>
									<div class="col-md-6 text-right">
										<h3 class="text-bold">
											{{ $t("accounts.accounts") }} ({{
												listedAccounts.length
											}})
										</h3>
									</div>
								</div>
								<hr />
							</div>

							<!--   <p class="open-account text-right">
            <router-link
              class="btn btn-outline"
              :to="{ name: 'open-account' }"
              >{{ $t("accounts.openAccount") }}</router-link
            >
          </p>
          <br /> -->

							<!--    <div class="input-group mb-3">
            <input
              type="text"
              class="form-control"
              :placeholder="$t('accounts.nickname')"
              v-model="searchTitle"
            />
            <div class="input-group-append">
              <button
                class="btn btn-outline-secondary"
                type="button"
                @click="
                  page = 1;
                  getUsersAccounts();
                "
              >
                {{ $t("search") }}
              </button>
            </div>
          </div> -->
							<div v-if="isloading" class="text-center">
								<b-spinner
									style="width: 3rem; height: 3rem;"
									class="ml-auto text-center"
									:variant="secondary"
								></b-spinner>
							</div>
							<div v-else-if="listedAccounts.length > 0">
								<b-row>
									<div
										v-for="account in listedAccounts"
										:key="account.id"
										class="col-6 card-grid"
									>
										<router-link
											:to="{ name: 'account', params: { id: account.id } }"
											class=""
											tag="text-grey"
										>
											<div class="background-modifier ">
												<h4 class="">
													{{
														account.iban
															.toString()
															.replace(/\w{4}(?=.)/g, "$& ")
													}}
												</h4>
												<p class="text-left">{{ account.nickname }}</p>
												<br />
												<hr />
												<div class="row">
													<div class="col-md-8 text-left">
														<h3 class="text-bold">
															{{ account.currency }}
															{{
																account.balance != null
																	? $n(account.balance, "decimal")
																	: $n(0.0, "decimal")
															}}
														</h3>
													</div>
													<div class="col-md-4 text-right">
														<div
															v-if="account.currency != baseCurrency"
															class=""
														>
															<small
																>({{ baseCurrency }}
																{{
																	account.baseBalance != null
																		? $n(account.baseBalance, "decimal")
																		: $n(0.0, "decimal")
																}})</small
															>
														</div>
													</div>
												</div>
											</div>
										</router-link>
									</div>
								</b-row>
							</div>
							<div v-else>{{ $t("accounts.noAccounts") }}</div>

							<!-- <div class="background-modifier">
            <div v-if="listedAccounts.length > 10">
              <div class="">
                <div class="mb-3">
                  {{ $t("itemsPerPage") }}
                  <select 
										class="custom-select" 
										style="width: 60px;"
                    v-model="pageSize"
                    @change="handlePageSizeChange($event)"
                  >
                    <option v-for="size in pageSizes" :key="size" :value="size">
                      {{ size }}
                    </option>
                  </select>
                </div>

                <b-pagination
                  v-model="page"
                  :total-rows="count"
                  :per-page="pageSize"
                  :prev-text="$t('previous')"
                  :next-text="$t('next')"
                  @change="handlePageChange"
                ></b-pagination>
              </div>
            </div>

            <span>
              <h3 class="title-bold">
                {{
                  totalBalance() != null
                    ? $n(totalBalance(), "decimal")
                    : $n(0.0, "decimal")
                }}
              </h3>
              <p class="small">
                {{ $t("accounts.totalBalance") }} ({{ this.baseCurrency }})
              </p>
            </span>

            <div v-if="accounts.length > 0">
              <div class="table-wrap">
                <table class="table mt-2" id="accounts">
                  <thead>
                    <tr>
                      <th class="text-left">IBAN</th>
                    
                      <th class="text-left">{{ $t("accounts.nickname") }}</th>
                     
                      <th class="text-right">{{ $t("accounts.currency") }}</th>
                      <th class="text-right">{{ $t("accounts.balance") }}</th>
                      <th class="text-right small">
                        {{ $t("accounts.balance") }} ({{ this.baseCurrency }})
                      </th>
                      <th class="text-centered">
                        {{ $t("accounts.actions") }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="account in accounts" :key="account.id">
                    
                      <td class="text-left">
                        {{
                          account.iban.toString().replace(/\w{4}(?=.)/g, "$& ")
                        }}
                      </td>
                
                      <td class="text-left">{{ account.nickname }}</td>
                    
                      <td class="text-left">{{ account.currency }}</td>
                      <td class="text-right">
                        {{
                          account.balance != null
                            ? $n(account.balance, "decimal")
                            : $n(0.0, "decimal")
                        }}
                      </td>
                      <td class="text-right small">
                        {{
                          account.baseBalance != null
                            ? $n(account.baseBalance, "decimal")
                            : $n(0.0, "decimal")
                        }}
                      </td>
                      <td class="text-right">
                        <router-link
                          :to="{ name: 'account', params: { id: account.id } }"
                          class="btn btn-outline mx-1 "
                          >{{ $t("accounts.view") }}</router-link
                        >
                       
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div v-else>{{ $t("accounts.noAccounts") }}</div> -->
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- </div> -->
	</div>
</template>

<script>
	import AuthService from "@/services/AuthService.js";

	export default {
		data() {
			return {
				account: "",
				userMessage: "",
				username: "",
				accounts: [],
				listedAccounts: [],
				currentAccount: null,
				currentIndex: -1,
				searchTitle: "",
				isloading: false,
				isloading2: false,
				page: 1,
				count: 0,
				pageSize: 10,

				pageSizes: [10, 25, 50],
			};
		},
		async created() {
			if (!this.$store.getters.isLoggedIn) {
				this.$router.push("login");
			}
			let lang = this.$i18n.locale;
			this.username = this.$store.getters.getUser.username;
			this.userMessage = await AuthService.getUserContent(lang);
			this.baseCurrency = this.$store.getters.getUser.currency;
		},
		methods: {
			getRequestParams(searchTitle, page, pageSize) {
				let params = {};

				if (searchTitle) {
					params["nickname"] = searchTitle;
				}

				if (page) {
					params["page"] = page - 1;
				}

				if (pageSize) {
					params["size"] = pageSize;
				}

				return params;
			},

			async getUsersAccounts() {
				const params = this.getRequestParams(
					this.searchTitle,
					this.page,
					this.pageSize
				);

				try {
					const details = {
						id: this.$store.getters.getUser.id,
					};
					//	this.isloading = true;
					const response = await AuthService.getUsersAccounts(
						details.id,
						params.page,
						params.size,
						params.nickname
					);
					const { items, totalItems } = response.data;
					this.accounts = items;
					this.count = totalItems;
					//	this.isloading = false;
					console.log(response.data);
				} catch (error) {
					//this.isloading = false;
					console.log(error);
				}
			},

			async getUsersAccountList() {
				try {
					const id = this.$store.getters.getUser.id;
					this.isloading = true;
					const response = await AuthService.getUsersAccountList(id);
					this.listedAccounts = response;
					this.isloading = false;
					this.totalBalance();
					console.log(response);
				} catch (error) {
					this.isloading = false;
					console.log(error);
				}
			},
			totalBalance() {
				//this.isloading2 = true;
				return this.listedAccounts.reduce(
					(acc, item) => acc + item.baseBalance,
					0
				);
				//this.isloading2 = false;
			},

			handlePageChange(value) {
				this.page = value;
				this.getUsersAccounts();
			},

			handlePageSizeChange(event) {
				this.pageSize = event.target.value;
				this.page = 1;
				this.getUsersAccounts();
			},

			/* async deleteAccount(id) {
	       try {
	         const response = await AuthService.deleteAccount(id);
	         console.log(response);
	         this.getUsersAccounts();
	       } catch (error) {
	         console.log(error);
	       }
	     },*/
		},

		mounted() {
			// document.title = $t("overview.overview");
			//this.getUsersAccounts();
			this.getUsersAccountList();
			this.totalBalance();
			// this.formatIBAN();
		},
	};
</script>
