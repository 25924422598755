<template>
	<div class="col-12 offset-0">
		<div class="">
			<div class="text-left">
				<div class="col-md-8 offset-md-2">
					<h2 class="title-bold">
						{{ $t("manageCardApplications.manageCardApplications") }}
					</h2>
					<hr />
				</div>

				<div class="col-md-8 offset-md-2 ">
					<div v-if="!isloading" class="">
						<h3 class="text-bold">
							{{ $t("manageCardApplications.applications") }} ({{
								listedCards.length
							}})
						</h3>
						<hr />
					</div>

					<br />
					<div v-if="isloading" class="text-center">
						<b-spinner
							style="width: 3rem; height: 3rem;"
							class="ml-auto text-center"
							:variant="secondary"
						></b-spinner>
					</div>
					<div v-else-if="listedCards.length > 0">
						<b-row>
							<div
								v-for="card in listedCards"
								:key="card.id"
								class="col-6 card-grid"
							>
								<router-link
									:to="{
										name: 'manage-card-application',
										params: { id: card.id },
									}"
									class=""
									tag="text-grey"
								>
									<div class="background-modifier ">
										<br />
										<div class="">
											<div class="">
												<h3 class="text-bold">
													{{ card.cardholder }}
												</h3>
												<br />
												<div class="row">
													<div class="col-md-6 text-left">
														<p class="text-left small">
															{{ $t("manageCardApplications.paymentLimit") }}
														</p>
													</div>
													<div class="col-md-6 text-right">
														<p class="text-right small">
															{{ $t("manageCardApplications.date") }}
														</p>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6 text-left">
														<h5 class="text-bold text-left">
															{{ $n(card.paymentLimit, "decimal") }}
														</h5>
													</div>
													<div class="col-md-6 text-right">
														<h5 class="text-right text-bold">
															{{ $d(Date.parse(card.created), "short") }}
														</h5>
													</div>
												</div>

												<!-- <p class="text-right small">
                          {{ $t("manageCardApplications.debitAccountId") }}
                        </p>
                        <h5 class="text-right text-bold">
                          {{
                            card.accountId
                              .toString()
                              .replace(/\w{4}(?=.)/g, "$& ")
                          }}
                        </h5> -->
											</div>
										</div>
									</div>
								</router-link>
							</div>
						</b-row>
					</div>
					<div v-else>{{ $t("manageCardApplications.noApplications") }}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import AuthService from "@/services/AuthService.js";

	export default {
		data() {
			return {
				card: "",
				username: "",
				listedCards: [],
				isloading: false,
			};
		},
		async created() {
			if (!this.$store.getters.isLoggedIn) {
				this.$router.push("login");
			}

			if (
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 3 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 15 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 14 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 13 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 12 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 11
			) {
				this.$router.push("overview");
			}
			let lang = this.$i18n.locale;
			this.role = this.$store.getters.getUser.role;
			this.username = this.$store.getters.getUser.username;
			this.firstname = this.$store.getters.getUser.firstname;
			this.clientId = this.$store.getters.getUser.clientId;
		},
		methods: {
			async getClientsCardApplications() {
				try {
					const id = this.$store.getters.getUser.id;
					this.isloading = true;
					const response = await AuthService.getClientsCardApplications(id);
					this.listedCards = response;
					this.isloading = false;
					console.log(response);
				} catch (error) {
					this.isloading = false;
					console.log(error);
				}
			},
		},

		mounted() {
			this.getClientsCardApplications();
		},
	};
</script>
