<template>
	<div class="col-12 offset-0">
		<div class="text-left">
			<div class="col-md-8 offset-md-2">
				<h1 class="title-bold">
					{{ $t("accountManagement.termAccounts") }}
				</h1>
				<hr />
				<h3 class="title-bold">
					{{ $t("accountManagement.termAccounts") }} ({{
						listedAccounts.length
					}})
				</h3>
				<hr />
				<div v-if="role == 3 || role == 15 || role == 14 || role == 13">
					<p class="open-account text-right">
						<router-link
							class="btn btn-outline"
							:to="{ name: 'business-create-term-account' }"
							>{{ $t("accountManagement.openAccount") }}</router-link
						>
					</p>
				</div>

				<div class="">
					<div v-if="listedAccounts.length > 10">
						<div class="">
							<div class="mb-3">
								{{ $t("itemsPerPage") }}
								<select
									class="custom-select"
									style="width: 60px;"
									v-model="pageSize"
									@change="handlePageSizeChange($event)"
								>
									<option v-for="size in pageSizes" :key="size" :value="size">
										{{ size }}
									</option>
								</select>
							</div>

							<b-pagination
								v-model="page"
								:total-rows="count"
								:per-page="pageSize"
								:prev-text="$t('previous')"
								:next-text="$t('next')"
								@change="handlePageChange"
							></b-pagination>
						</div>
					</div>

					<div class="row">
						<div class="col-md-6 text-left ">
							<div class="background-outline">
								<span>
									<h3 class="text-bold">
										{{
											totalBalance() != null
												? $n(totalBalance(), "decimal")
												: $n(0.0, "decimal")
										}}
									</h3>
									<p class="small">
										{{ $t("accounts.totalBalance") }} ({{ this.baseCurrency }})
									</p>
								</span>
							</div>
							<br />
						</div>

						<div class="col-md-6 text-right">
							<h3 class="text-bold">
								<!-- {{ $t("accounts.accounts") }} ({{ listedAccounts.length }}) -->
							</h3>
						</div>
					</div>

					<div v-if="isloading" class="text-center">
						<b-spinner
							style="width: 3rem; height: 3rem;"
							class="ml-auto text-center"
							:variant="secondary"
						></b-spinner>
					</div>
					<div v-else-if="listedAccounts.length > 0">
						<b-row>
							<div
								v-for="account in listedAccounts"
								:key="account.id"
								class="col-6 card-grid"
							>
								<router-link
									:to="{ name: 'account-details', params: { id: account.id } }"
									class=""
									tag="text-grey"
								>
									<div class="background-modifier ">
										<h4 class="">
											{{
												account.iban.toString().replace(/\w{4}(?=.)/g, "$& ")
											}}
										</h4>
										<p class="text-left">{{ account.nickname }}</p>
										<div class="">
											<div class="">
												<h3 class="text-bold">
													{{ account.currency }}
													{{
														account.balance != null
															? $n(account.balance, "decimal")
															: $n(0.0, "decimal")
													}}
												</h3>
											</div>
											<div v-if="account.currency != baseCurrency" class="">
												<small
													>({{ baseCurrency }}
													{{
														account.baseBalance != null
															? $n(account.baseBalance, "decimal")
															: $n(0.0, "decimal")
													}})</small
												>
											</div>
										</div>
									</div>
								</router-link>
							</div>
						</b-row>
					</div>
					<div v-else>{{ $t("accounts.noAccounts") }}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import AuthService from "@/services/AuthService.js";

	export default {
		name: "accounts",
		data() {
			return {
				accounts: [],
				listedAccounts: [],
				isloading: false,
				currentAccount: null,
				currentIndex: -1,
				searchTitle: "",

				page: 1,
				count: 0,
				pageSize: 10,

				pageSizes: [10, 25, 50],
			};
		},

		async created() {
			if (!this.$store.getters.isLoggedIn) {
				this.$router.push("login");
			}
			if (
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 3 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 15 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 14 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 13 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 12 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 11
			) {
				this.$router.push("overview");
			}
			let lang = this.$i18n.locale;
			this.role = this.$store.getters.getUser.role;
			this.username = this.$store.getters.getUser.username;
			this.firstname = this.$store.getters.getUser.firstname;
			this.baseCurrency = this.$store.getters.getUser.currency;
			// this.getClientsTermAccounts();
		},
		methods: {
			getRequestParams(searchTitle, page, pageSize) {
				let params = {};

				if (searchTitle) {
					params["nickname"] = searchTitle;
				}

				if (page) {
					params["page"] = page - 1;
				}

				if (pageSize) {
					params["size"] = pageSize;
				}

				return params;
			},

			async getClientsAccountList() {
				const params = this.getRequestParams(
					this.searchTitle,
					this.page,
					this.pageSize
				);

				try {
					const details = {
						id: this.$store.getters.getUser.id,
					};
					const response = await AuthService.getClientsAccountList(
						params.page,
						params.size,
						params.nickname,
						details.id
					);
					const { items, totalItems } = response.data;
					this.accounts = items;
					this.count = totalItems;
					console.log(response.data);
				} catch (error) {
					console.log(error);
				}
			},

			async getClientsTermAccounts() {
				try {
					const id = this.$store.getters.getUser.id;
					this.isloading = true;
					const response = await AuthService.getClientsTermAccounts(id);
					this.listedAccounts = response;
					this.isloading = false;
					console.log(response);
				} catch (error) {
					this.isloading = false;
					console.log(error);
				}
			},
			totalBalance() {
				return this.listedAccounts.reduce(
					(acc, item) => acc + item.baseBalance,
					0
				);
			},
			handlePageChange(value) {
				this.page = value;
				this.getClientsAccountList();
			},

			handlePageSizeChange(event) {
				this.pageSize = event.target.value;
				this.page = 1;
				this.getClientsAccountList();
			},

			/* async deleteAccount(id) {
        try {
          const response = await AuthService.deleteAccount(id);
          console.log(response);
          this.getClientsAccountList();
        } catch (error) {
          console.log(error);
        }
      },*/
		},
		mounted() {
			this.getClientsTermAccounts();
			this.getClientsAccountList();
			this.totalBalance();
			//  this.filteredAccounts();
		},
	};
</script>
