<template>
	<div class="col-12 offset-0">
		<div class="col-md-8 offset-md-2">
			<h2 class="title-bold text-left">
				{{ $t("documentUpload.fileUpload") }}
			</h2>
			<hr />
			<div class="background-modifier">
				<ValidationObserver ref="observer" v-slot="{ handleSubmit, reset }">
					<b-form @submit.prevent="handleSubmit(upload)" @reset.prevent="reset">
						<ValidationProvider
							name="userId"
							rules="required"
							v-slot="{ valid, errors }"
						>
							<b-form-group id="userIdInputGroup" label-for="userIdInput">
								<b-form-select
									id="userIdInput"
									:state="errors[0] ? false : valid ? true : null"
									v-model="userId"
									><option v-for="user in users" :value="user.id" :key="user.id"
										>{{ user.id }} {{ user.firstname }}
										{{ user.lastname }}</option
									>
									<template #first>
										<b-form-select-option value="" disabled>{{
											$t("newAccount.userId")
										}}</b-form-select-option>
									</template></b-form-select
								>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>

						<label class="col-10 offset-md-1">
							<input class="" type="file" ref="file" @change="selectFile" />
						</label>
						<hr />
						<div v-if="currentFile" class="progress">
							<div
								class="progress-bar progress-bar-striped bg-info"
								role="progressbar"
								:aria-valuenow="progress"
								aria-valuemin="0"
								aria-valuemax="100"
								:style="{ width: progress + '%' }"
							>
								{{ progress }}%
							</div>
						</div>
						<br />
						<div v-if="userId">
							<b-button
								type="submit"
								class="btn btn-info text-light btn-block"
								:disabled="!selectedFiles"
								@click="upload"
							>
								{{ $t("documentUpload.upload") }}
							</b-button>
						</div>
						<div v-else></div>
						<div class="alert alert-light" role="alert">{{ msg }}</div>
					</b-form>
				</ValidationObserver>
			</div>

			<!--   <div class="card">
            <div class="card-header">{{ $t('fileUpload.listOfFiles') }}</div>
            <ul class="list-group list-group-flush">
              <li
                class="list-group-item"
                v-for="(file, index) in fileInfos"
                :key="index"
              >
                <a :href="file.url">{{ file.name }}</a>
              </li>
            </ul>
          </div>  -->

			<hr />

			<h2 class="title-bold text-left">
				{{ $t("documentUpload.listOfFiles") }}
			</h2>
			<hr />
			<div class="background-modifier">
				<div class="input-group mb-3">
					<input
						type="text"
						class="form-control"
						:placeholder="$t('documentUpload.filename')"
						v-model="searchTitle"
					/>
					<div class="input-group-append">
						<button
							class="btn btn-outline"
							type="button"
							@click="
								page = 1;
								getAllIdDocs();
							"
						>
							{{ $t("search") }}
						</button>
					</div>
				</div>

				<div v-if="isloading" class="text-center">
					<b-spinner
						style="width: 3rem; height: 3rem;"
						class="ml-auto text-center"
						:variant="secondary"
					></b-spinner>
				</div>
				<div v-else-if="idDocs.length > 0">
					<table class="table mt-2 " id="document-upload">
						<thead>
							<tr>
								<th class="text-left small">{{ $t("documentUpload.user") }}</th>
								<th class="text-left small">
									{{ $t("documentUpload.filename") }}
								</th>
								<th class="text-left small">
									{{ $t("documentUpload.created") }}
								</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="idDoc in idDocs" :key="idDoc.id">
								<td class="text-left">
									<router-link
										:to="{ name: 'user-detail', params: { id: idDoc.userId } }"
										class=" "
										>{{ idDoc.firstname }} {{ idDoc.lastname }}</router-link
									>
								</td>
								<td class="text-left">
									<span>
										<a
											:href="
												'https://www.n3consortium.com/api/files/' +
													idDoc.filename
											"
											>{{ idDoc.filename }}</a
										></span
									>
								</td>
								<td class="text-right">
									{{ $d(Date.parse(idDoc.created), "short") }}
								</td>
							</tr>
						</tbody>
					</table>
					<div v-if="idDocs.length >= 1">
						<div class="row ">
							<div class="col-md-6 text-left">
								{{ $t("itemsPerPage") }}
								<select
									class="custom-select"
									style="width: 60px;"
									v-model="pageSize"
									@change="handlePageSizeChange($event)"
								>
									<option v-for="size in pageSizes" :key="size" :value="size">
										{{ size }}
									</option>
								</select>
							</div>
							<div class="col-md-6">
								<b-pagination
									v-model="page"
									:total-rows="count"
									:per-page="pageSize"
									:prev-text="$t('previous')"
									:next-text="$t('next')"
									align="right"
									@change="handlePageChange"
								></b-pagination>
							</div>
						</div>
					</div>
				</div>

				<div v-else>{{ $t("documentUpload.noDocuments") }}</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { ValidationObserver, ValidationProvider } from "vee-validate";
	import AuthService from "@/services/AuthService.js";
	import UploadService from "@/services/FileUploadService";

	export default {
		name: "document-upload",
		components: {
			ValidationObserver,
			ValidationProvider,
		},
		data() {
			return {
				userId: "",
				users: [],
				selectedFiles: undefined,
				currentFile: undefined,
				progress: 0,
				msg: "",
				isloading: false,
				fileInfos: [],
				idDocs: [],

				currentIdDoc: null,
				currentIndex: -1,
				searchTitle: "",

				page: 1,
				count: 0,
				pageSize: 10,

				pageSizes: [10, 25, 50],
			};
		},
		async created() {
			if (!this.$store.getters.isLoggedIn) {
				this.$router.push("login");
			}
			if (
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role !== 3
			) {
				this.$router.push("overview");
			}
			this.role = this.$store.getters.getUser.role;
			// this.getIdDocs();
		},
		methods: {
			getRequestParams(searchTitle, page, pageSize) {
				let params = {};

				if (searchTitle) {
					params["title"] = searchTitle;
				}

				if (page) {
					params["page"] = page - 1;
				}

				if (pageSize) {
					params["size"] = pageSize;
				}

				return params;
			},

			async getUserList() {
				try {
					const response = await AuthService.getUserList();
					this.users = response;
					console.log(response);
				} catch (error) {
					console.log(error);
				}
			},
			selectFile() {
				this.selectedFiles = this.$refs.file.files;
			},
			upload() {
				this.progress = 0;

				const lang = this.$i18n.locale;
				const userId = this.userId;

				this.currentFile = this.selectedFiles.item(0);
				UploadService.upload(this.currentFile, lang, userId, (event) => {
					this.progress = Math.round((100 * event.loaded) / event.total);
				})
					.then((response) => {
						this.msg = response.data.msg;
						this.getAllIdDocs();
						return UploadService.getFiles();
					})
					.then((files) => {
						this.fileInfos = files.data;
					})
					.catch(() => {
						this.progress = 0;
						this.msg = this.$t("couldNotUploadTheFile");
						this.currentFile = undefined;
					});

				this.selectedFiles = undefined;
			},
			async getAllIdDocs() {
				const params = this.getRequestParams(
					this.searchTitle,
					this.page,
					this.pageSize
				);

				try {
					//  console.log(id);
					this.isloading = true;
					const response = await AuthService.getAllIdDocs(
						params.page,
						params.size,
						params.title
					);
					const { items, totalItems } = response.data;
					this.idDocs = items;
					this.count = totalItems;
					this.isloading = false;
					console.log(response.data);
				} catch (error) {
					this.isloading = false;
					console.log(error);
				}
			},
			handlePageChange(value) {
				this.page = value;
				this.getAllIdDocs();
			},

			handlePageSizeChange(event) {
				this.pageSize = event.target.value;
				this.page = 1;
				this.getAllIdDocs();
			},
		},
		mounted() {
			UploadService.getFiles().then((response) => {
				this.fileInfos = response.data;
			});
			this.getUserList();
			this.getAllIdDocs();
		},
	};
</script>
