<template>
	<ValidationObserver ref="observer" v-slot="{ handleSubmit, reset }">
		<b-form
			@submit.prevent="handleSubmit(forgotPassword)"
			@reset.prevent="reset"
		>
			<div class="col-lg-4 offset-lg-4 text-left">
				<h2 class="title-bold">{{ $t("forgotPassword.forgotPassword") }}</h2>
				<hr />
				<div class="background-outline">
					<div class="background-modifier">
						<ValidationProvider
							rules="required|email"
							name="email"
							v-slot="{ valid, errors }"
						>
							<b-form-group label-for="emailInput" description="">
								<b-form-input
									type="email"
									v-model="email"
									:state="errors[0] ? false : valid ? true : null"
									:placeholder="$t('signup.email')"
								></b-form-input>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>
					</div>
					<hr />

					<b-button
						type="submit"
						@click="isloading"
						class="btn btn-info text-light btn-block"
						>{{ $t("forgotPassword.submit")
						}}<b-spinner
							v-if="isloading"
							class="ml-auto"
							:variant="light"
						></b-spinner
					></b-button>
					<hr />
					<b-button
						type="reset"
						class="btn btn-alert text-light btn-block"
						@click="resetForm()"
						>{{ $t("reset") }}</b-button
					>

					<div v-if="msg && $bvToast.show('notification-toast')"></div>
					<div class="action">
						<br />
						<b-toast
							id="notification-toast"
							variant="secondary"
							static
							no-auto-hide
						>
							<template #toast-title>
								<div class="d-flex flex-grow-1 align-items-baseline">
									<b-img
										class="logo mb-2"
										:src="require('/public/images/logo.png')"
										alt=""
										width="12"
										height="12"
									></b-img>
									<strong class="mr-auto">{{ $t("notification") }}</strong>
									<small class="text-muted mr-2"></small>
								</div>
							</template>
							{{ msg }}
						</b-toast>
					</div>
				</div>
			</div>
		</b-form>
	</ValidationObserver>
</template>

<script>
	import AuthService from "@/services/AuthService.js";
	import { ValidationObserver, ValidationProvider } from "vee-validate";

	export default {
		name: "ForgotPasswordForm",
		components: {
			ValidationObserver,
			ValidationProvider,
		},
		data() {
			return {
				email: "",
				msg: "",
				isloading: false,
			};
		},
		methods: {
			async forgotPassword() {
				try {
					const credentials = {
						lang: this.$i18n.locale,
						email: this.email,
					};
					this.isloading = true;
					const response = await AuthService.forgotPassword(credentials);
					this.msg = response.msg;
					this.isloading = false;
					this.resetForm();
				} catch (error) {
					this.msg = error.response.data.msg;
					this.isloading = false;
					/*this.$router.push('home');*/
				}
			},
			resetForm() {
				this.email = "";
				requestAnimationFrame(() => {
					this.$refs.observer.reset();
				});
			},
		},
	};
</script>
