<template>
	<ValidationObserver ref="observer" v-slot="{ handleSubmit, reset }">
		<b-form @submit.prevent="handleSubmit(newNip)" @reset.prevent="reset">
			<div class="col-lg-4 offset-lg-4 text-left">
				<h2 class="title-bold">{{ $t("generateQRCode.generateQRCode") }}</h2>
				<h5 class="title-bold">
					{{ $t("generateQRCode.generateQRCodeToReceiveNip") }}
				</h5>
				<hr />
				<div class="background-outline">
					<div class="background-modifier text-center">
						<qrcode-vue
							:value="
								creditAccountHolder +
									'\n' +
									email +
									'\n' +
									message +
									'\n' +
									selected[0] +
									'\n' +
									amount
							"
							:size="size"
							level="H"
						/>
					</div>
					<hr />
					<div class="background-modifier">
						<label class="small">{{
							$t("generateQRCode.beneficiaryName")
						}}</label>
						<ValidationProvider
							rules="alpha_spaces|min:2|max:50"
							name="creditAccountHolder"
							v-slot="{ valid, errors }"
						>
							<b-form-group label-for="creditAccountHolder" description="">
								<b-form-input
									type="text"
									v-model="creditAccountHolder"
									:state="errors[0] ? false : valid ? true : null"
									:placeholder="$t('generateQRCode.beneficiaryName')"
								></b-form-input>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>

						<label class="small">{{ $t("generateQRCode.email") }}</label>
						<ValidationProvider
							rules="required|email"
							name="email"
							v-slot="{ valid, errors }"
						>
							<b-form-group label-for="emailInput" description="">
								<b-form-input
									type="email"
									v-model="email"
									:state="errors[0] ? false : valid ? true : null"
									:placeholder="$t('generateQRCode.email')"
								></b-form-input>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>
					</div>
					<br />
					<!--   </div>   -->
					<div class="background-modifier">
						<div class="row">
							<div class="col-md-5">
								<label class="small">{{
									$t("generateQRCode.selectCurrency")
								}}</label>
								<ValidationProvider
									rules="required"
									name="selected"
									v-slot="{ valid, errors }"
								>
									<b-form-group
										id="sourceCurrencyInputGroup"
										:label-for="sourceCurrencyInput"
									>
										<b-form-select
											@change="convert"
											id="sourceCurrencyInput"
											:state="errors[0] ? false : valid ? true : null"
											v-model="selected[0]"
											><option
												v-for="currency in currencies"
												:value="currency.CharCode"
												:key="currency.CharCode"
												>{{ currency.CharCode }}</option
											>
											<template #first>
												<b-form-select-option value="" disabled>{{
													$t("generateQRCode.selectCurrency")
												}}</b-form-select-option>
											</template></b-form-select
										>
										<b-form-invalid-feedback id="inputLiveFeedback">{{
											errors[0]
										}}</b-form-invalid-feedback>
									</b-form-group>
								</ValidationProvider>
							</div>

							<div class="col-md-7">
								<label class="small">{{ $t("generateQRCode.amount") }}</label>
								<ValidationProvider
									rules="required|double|min_value:0.01"
									name="amount"
									v-slot="{ valid, errors }"
								>
									<b-form-group label-for="amountInput" description="">
										<b-form-input
											@input="convert"
											type="text"
											v-model="amount"
											:state="errors[0] ? false : valid ? true : null"
											:placeholder="$t('generateQRCode.amount')"
										></b-form-input>
										<b-form-invalid-feedback id="inputLiveFeedback">{{
											errors[0]
										}}</b-form-invalid-feedback>
									</b-form-group>
								</ValidationProvider>
							</div>
						</div>

						<!-- <div v-if="selected[0] !== accountCurrency.currency">
            <div class="col-md-12 text-right">
              <p v-model="result" class="small" type="number" readonly>
                <!-- (inside p tag)-->
						<!-- {{ result !== "undefined" ? accountCurrency.currency : "" }}
                {{ result ? $n(result.toFixed(2), "decimal") : "" }}
              </p>
            </div>
          </div> -->
					</div>
					<br />

					<div class="background-modifier">
						<label class="small">{{ $t("generateQRCode.message") }}</label>
						<ValidationProvider
							rules="min:1|max:50"
							name="message"
							v-slot="{ valid, errors }"
						>
							<b-form-group label-for="messageInput" description="">
								<b-form-input
									type="text"
									v-model="message"
									:state="errors[0] ? false : valid ? true : null"
									:placeholder="$t('generateQRCode.message')"
								></b-form-input>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>
					</div>

					<hr />
					<b-button
						type="reset"
						class="btn btn-alert text-light btn-block"
						@click="resetForm()"
						>{{ $t("reset") }}</b-button
					>
				</div>
			</div>
		</b-form>
	</ValidationObserver>
</template>

<script>
	import { ValidationObserver, ValidationProvider } from "vee-validate";
	import AuthService from "@/services/AuthService.js";
	import QrcodeVue from "qrcode.vue";
	const currencyAPI = require("axios").default;

	export default {
		name: "NewNipForm",
		components: {
			ValidationObserver,
			ValidationProvider,
			QrcodeVue,
		},
		data() {
			return {
				value: "https://example.com",
				size: 275,

				accounts: [],
				debitAccountId: "",

				creditAccountHolder: "",
				email: "",
				balance: "",
				accountCurrency: "", //this.accountCurrency.currency ? this.accountCurrency.currency : '',
				amount: "",
				message: "",

				msg: "",

				currencies: {},
				selected: ["EUR", "USD"],
				//  inputted: "",
				result: null,
				countries: [],
				rules: {
					onlyNumbers: (value) => {
						const pattern = /^\d+$/.test(value);
						if (!pattern) this.inputed = this.inputed.replace(/\D/g, "");
						return true;
					},
				},
			};
		},

		async created() {
			if (!this.$store.getters.isLoggedIn) {
				this.$router.push("login");
			}
			this.creditAccountHolder = this.username =
				this.$store.getters.getUser.firstname +
				" " +
				this.$store.getters.getUser.lastname;
			this.email = this.$store.getters.getUser.email;
		},
		methods: {
			async onChange(event) {
				try {
					const id = this.debitAccountId;
					const response1 = await AuthService.getCreditAmount(id);
					this.creditAmount = response1;

					//  console.log(response1);
					const response2 = await AuthService.getDebitAmount(id);
					this.debitAmount = response2;

					const response3 = await AuthService.getAccountCurrency(id);
					this.accountCurrency = response3;
					console.log(accountCurrency.currency);

					//   console.log(response2);
					const balance1 = this.creditAmount.credits - this.debitAmount.debits;
					this.balance = balance1;
					console.log(balance);
				} catch (error) {
					console.log(error);
				}
			},
			convert() {
				// Default currency values / EUR
				let defaultCurrency = {
					Value: 1,
					Nominal: 1,
				};
				// First selected currency details
				let firstCurrency =
						this.currencies[this.selected[0]] ?? defaultCurrency,
					firstCurrencyValue = firstCurrency.Value * Number(this.amount),
					firstCurrencyNominal = firstCurrency.Nominal;
				// Second selected currency details
				let secondCurrency =
						this.currencies[this.accountCurrency.currency /*selected[1]*/] ??
						defaultCurrency,
					secondCurrencyValue = secondCurrency.Value,
					secondCurrencyNominal = secondCurrency.Nominal;
				// Result calculating
				let result =
					firstCurrencyValue /
					firstCurrencyNominal /
					(secondCurrencyValue / secondCurrencyNominal);
				// Rounding to ten thousandths
				this.result = result ? Math.floor(result * 10000) / 10000 : null;
			},
			async getCurrencyData() {
				try {
					const response = await AuthService.getCurrencyData();
					this.currencies = response.data.Valute;
					console.log(response.data.Valute);
					// Adding all charCodes to array
					for (let code in response) {
						this.countries.push(code);
					}
				} catch (error) {
					console.log(error);
				}
			},

			resetForm() {
				this.debitAccountId = "";
				//    this.creditAccountId = '';
				//    this.creditAccountId2 = '';
				this.creditAccountHolder = "";
				// this.beneficiaryDetails.beneficiaryName = '';
				this.email = "";
				this.amount = "";
				this.selected[0] = "";
				this.message = "";
				//    this.alias = '';
				//    this.dueDate = '';
				//    this.endDate = '';
				//  this.msg = '';
				requestAnimationFrame(() => {
					this.$refs.observer.reset();
				});
			},
		},

		mounted() {
			//    this.getUsersBeneficiaryList();
			this.getCurrencyData();
			this.getUserId();

			/*if (this.debitAccountId > 0) {
          this.getAccountCurrency();
        }*/
		},
	};
</script>
