<template>
	<ValidationObserver ref="observer" v-slot="{ handleSubmit, reset }">
		<b-form
			@submit.prevent="handleSubmit(signUpBusiness)"
			@reset.prevent="reset"
		>
			<div class="col-lg-4 offset-lg-4 text-left">
				<h2 class="title-bold">
					{{ $t("businessSignup.openBusinessAccount") }}
				</h2>
				<hr />

				<h5 class="text-left text-bold">
					{{ $t("businessSignup.answerQuestions") }}
				</h5>

				<hr />
				<div class="background-outline">
					<div class="background-modifier text-left">
						<label class="small">{{ $t("businessSignup.fullname") }}</label>
						<ValidationProvider
							rules="required|alpha_spaces|min:2|max:50"
							name="fullname"
							v-slot="{ valid, errors }"
						>
							<b-form-group label-for="fullnameInput" description="">
								<b-form-input
									type="text"
									v-model="fullname"
									:state="errors[0] ? false : valid ? true : null"
									:placeholder="$t('businessSignup.fullname')"
								></b-form-input>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>

						<div v-if="getReference() !== 0">
							<label class="small">{{ $t("businessSignup.businessId") }}</label>
							<ValidationProvider
								rules="required|min:5|max:50"
								name="businessId"
								v-slot="{ valid, errors }"
							>
								<b-form-group label-for="businessIdInput" description="">
									<b-form-input
										type="text"
										v-model="businessId"
										:state="errors[0] ? false : valid ? true : null"
										:placeholder="$t('businessSignup.businessId')"
									></b-form-input>
									<b-form-invalid-feedback id="inputLiveFeedback">{{
										errors[0]
									}}</b-form-invalid-feedback>
								</b-form-group>
							</ValidationProvider>
						</div>
						<div v-if="getReference() === 0">
							<p>{{ $t("businessSignup.companyInIncorporation") }}</p>
						</div>
						{{ reference }}
					</div>

					<hr />

					<h5 class="text-left text-bold">
						{{ $t("businessSignup.createUserProfile") }}
					</h5>

					<hr />

					<div class="background-modifier">
						<label class="small">{{ $t("businessSignup.username") }}</label>
						<ValidationProvider
							rules="required|alpha_dash|min:3|max:50"
							name="username"
							v-slot="{ valid, errors }"
						>
							<b-form-group label-for="usernameInput" description="">
								<b-form-input
									type="text"
									v-model="username"
									:state="errors[0] ? false : valid ? true : null"
									:placeholder="$t('businessSignup.username')"
								></b-form-input>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>

						<label class="small">{{ $t("businessSignup.email") }}</label>
						<ValidationProvider
							rules="required|email"
							name="email"
							v-slot="{ valid, errors }"
						>
							<b-form-group label-for="emailInput" description="">
								<b-form-input
									type="email"
									v-model="email"
									:state="errors[0] ? false : valid ? true : null"
									:placeholder="$t('businessSignup.email')"
								></b-form-input>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>
					</div>
					<br />

					<div class="background-modifier">
						<label class="small">{{ $t("businessSignup.firstname") }}</label>
						<ValidationProvider
							rules="required|alpha_spaces|min:2|max:50"
							name="firstname"
							v-slot="{ valid, errors }"
						>
							<b-form-group label-for="firstnameInput" description="">
								<b-form-input
									type="text"
									v-model="firstname"
									:state="errors[0] ? false : valid ? true : null"
									:placeholder="$t('businessSignup.firstname')"
								></b-form-input>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>

						<label class="small">{{ $t("businessSignup.lastname") }}</label>
						<ValidationProvider
							rules="required|alpha_spaces|min:2|max:50"
							name="lastname"
							v-slot="{ valid, errors }"
						>
							<b-form-group label-for="lastnameInput" description="">
								<b-form-input
									type="text"
									v-model="lastname"
									:state="errors[0] ? false : valid ? true : null"
									:placeholder="$t('businessSignup.lastname')"
								></b-form-input>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>
					</div>
					<br />

					<div class="background-modifier">
						<label class="small">{{ $t("businessSignup.password") }}</label>
						<ValidationProvider
							rules="required|min:6|max:30"
							name="password"
							vid="password"
							v-slot="{ valid, errors }"
						>
							<b-form-group description="">
								<b-form-input
									type="password"
									v-model="password"
									:state="errors[0] ? false : valid ? true : null"
									:placeholder="$t('businessSignup.password')"
								></b-form-input>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>

						<label class="small">{{
							$t("businessSignup.repeatPassword")
						}}</label>
						<ValidationProvider
							rules="required|confirmed:password"
							name="Password confirmation"
							v-slot="{ valid, errors }"
						>
							<b-form-group label="" label-for="repeatPasswordInput1">
								<b-form-input
									type="password"
									v-model="password_repeat"
									:state="errors[0] ? false : valid ? true : null"
									:placeholder="$t('businessSignup.repeatPassword')"
								></b-form-input>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>
					</div>
					<br />

					<div class="background-modifier">
						<div class="row">
							<div class="col-md-6">
								<label class="small">{{ $t("businessSignup.country") }}</label>
								<ValidationProvider
									rules="required"
									name="prefix"
									v-slot="{ valid, errors }"
								>
									<b-form-group id="prefixInputGroup" label-for="prefixInput">
										<b-form-select
											@change="onChange"
											id="prefixInput"
											:state="errors[0] ? false : valid ? true : null"
											v-model="prefix"
											><option
												v-for="country in countries"
												:value="country.prefix"
												:key="country.prefix"
												>+ {{ country.prefix }}
												{{
													countryNames.getName(country.countryCode, codeLang)
												}}
											</option>
											<template #first>
												<b-form-select-option value="" disabled>{{
													$t("businessSignup.country")
												}}</b-form-select-option>
											</template></b-form-select
										>
										<b-form-invalid-feedback id="inputLiveFeedback">{{
											errors[0]
										}}</b-form-invalid-feedback>
									</b-form-group>
								</ValidationProvider>
							</div>
							<div class="col-md-6">
								<label class="small">{{
									$t("businessSignup.phoneNumber")
								}}</label>
								<ValidationProvider
									rules="required|numeric|min:5|max:12"
									name="mobile"
									v-slot="{ valid, errors }"
								>
									<b-form-group label-for="mobileInput" description="">
										<b-form-input
											type="text"
											v-model="mobile"
											:state="errors[0] ? false : valid ? true : null"
											:placeholder="$t('businessSignup.phoneNumber')"
										></b-form-input>
										<b-form-invalid-feedback id="inputLiveFeedback">{{
											errors[0]
										}}</b-form-invalid-feedback>
									</b-form-group>
								</ValidationProvider>
							</div>
						</div>
					</div>

					<br />

					<b-form-checkbox
						class="text-left"
						id="checkbox-1"
						v-model="status"
						name="checkbox-1"
						value="accepted"
						unchecked-value="not_accepted"
					>
						{{ $t("businessSignup.acceptTerms") }}
					</b-form-checkbox>
					<br />
					<div v-if="completed == 'not'">
						<div v-if="status == 'accepted'">
							<b-button
								type="submit"
								@click="isloading"
								class="btn btn-info text-light btn-block"
								>{{ $t("businessSignup.submit")
								}}<b-spinner
									v-if="isloading"
									class="ml-auto"
									:variant="light"
								></b-spinner
							></b-button>
							<p class="small">
								{{ $t("businessSignup.createTheEntityAndAdminUserProfile") }}
							</p>
						</div>
						<hr />
						<b-button
							type="reset"
							class="btn btn-alert text-light btn-block"
							@click="resetForm()"
							>{{ $t("reset") }}</b-button
						>
					</div>

					<!-- disabled-->
					<!-- <div v-if="status == 'accepted'"> -->
					<!--    <br />
        
        <hr />
        <div class="background-modifier">
          <h5 class="text-left text-bold">
            {{ $t("businessSignup.businessDetails") }}
          </h5>
          <hr />
          <p class="text-left">
            {{ $t("businessSignup.addRequiredBusinessDetails") }}
          </p>
          <div class="text-center">
            <router-link
              :to="{ name: 'business-add-details' }"
              class="btn btn-secondary mx-1 "
              >{{ $t("businessSignup.addBusinessDetails") }}</router-link
            >
          </div>
        </div>
        <hr />

        <div class="background-modifier">
          <h5 class="text-left text-bold">
            {{ $t("businessSignup.identityOfKeyPersons") }}
          </h5>
          <hr />
          <p class="text-left">
            {{ $t("businessSignup.addInfoAboutKeyPersons") }}
          </p>

          <p class="text-left text-bold"></p>

          <hr />
          <p class="text-left text-bold">
            {{ $t("businessSignup.directors") }}
          </p>

          <p class="text-left">
            {{ $t("businessSignup.directorInfo") }}
          </p>

          <div class="text-center">
            <router-link
              :to="{ name: 'business-add-role' }"
              class="btn btn-secondary mx-1 "
              >{{ $t("businessSignup.addDirectors") }}</router-link
            >
          </div>

          <hr />

          <p class="text-left text-bold">
            {{ $t("businessSignup.decisionMakers") }}
          </p>

          <p class="text-left">
            {{ $t("businessSignup.decisionMakerInfo") }}
          </p>

          <div class="text-center">
            <router-link
              :to="{ name: 'business-add-role' }"
              class="btn btn-secondary mx-1 "
            >
              {{ $t("businessSignup.addDecisionMakers") }}</router-link
            >
          </div>

          <hr />

          <p class="text-left text-bold">
            {{ $t("businessSignup.signatories") }}
          </p>

          <p class="text-left">
            {{ $t("businessSignup.signatoryInfo") }}
          </p>

          <div class="text-center">
            <router-link
              :to="{ name: 'business-add-role' }"
              class="btn btn-secondary mx-1 "
              >{{ $t("businessSignup.addSignatories") }}</router-link
            >
          </div>
          <br />

          <p class="text-left">
            {{ $t("businessSignup.signatoryUploadInfo") }}
          </p>

          <hr />

          <p class="text-left text-bold">
            {{ $t("businessSignup.shareholders") }}
          </p>

          <p class="text-left ">
            {{ $t("businessSignup.shareholderInfo1") }}
          </p>

          <p class="text-left">
            {{ $t("businessSignup.shareholderInfo2") }}
          </p>

          <div class="text-center">
            <router-link
              :to="{ name: 'business-add-role' }"
              class="btn btn-secondary mx-1 "
              >{{ $t("businessSignup.addShareholders") }}</router-link
            >
          </div>
          <br />
          <div class="text-center">
            <router-link
              :to="{ name: 'business-add-role' }"
              class="btn btn-secondary mx-1 "
              >{{ $t("businessSignup.addUBOs") }}</router-link
            >
          </div>
          <br />

          <p class="text-left">
            {{ $t("businessSignup.shareholderUploadInfo") }}
          </p>
        </div>

        <hr />

        <div class="background-modifier">
          <h5 class="text-left text-bold">
            {{ $t("businessSignup.documentUpload") }}
          </h5>
          <hr />
          <p class="text-left">
            {{ $t("businessSignup.uploadInfo") }}
          </p>
          <div class="text-center">
            <router-link
              :to="{ name: 'business-document-upload' }"
              class="btn btn-secondary mx-1 "
            >
              {{ $t("businessSignup.uploadDocuments") }}</router-link
            >
          </div>
        </div>
        <hr />

        <h5 class="text-left text-bold">
          {{ $t("businessSignup.declarations") }}
        </h5>

        <hr />

        <p class="text-left">
          {{ $t("businessSignup.declaration1") }}
        </p>

        <p class="text-left">
          {{ $t("businessSignup.declaration2") }}
        </p>

        <hr />

        <p class="text-left">
          {{ $t("businessSignup.dutyToUpdate") }}
        </p>

        <p class="text-left">
          {{ $t("businessSignup.reservationOfRights") }}
        </p>

        <p class="text-left"></p>

        <br />
        <b-form-checkbox
          class="text-left"
          id="checkbox-1"
          v-model="status"
          name="checkbox-1"
          value="accepted"
          unchecked-value="not_accepted"
        >
          {{ $t("businessSignup.acceptTerms") }}
        </b-form-checkbox>
        <br />
-->
					<!-- disabled-->
					<!-- <div v-if="status == 'accepted'"> -->

					<!--   <b-button
          disabled
          type="submit"
          class="btn btn-info text-light btn-block"
          >{{ $t("businessSignup.submit") }}</b-button
        > -->

					<!-- <p class="small">{{ $t("businessSignup.notAvailable") }}</p> -->
					<!-- </div> -->

					<p class="forgot-password text-right">
						{{ $t("businessSignup.alreadyRegistered") }}
						<router-link :to="{ name: 'login' }">{{
							$t("businessSignup.login")
						}}</router-link>
					</p>
					<div v-if="completed == 'yes'">
						<router-link
							class="btn btn-alert text-light btn-block"
							:to="{ path: 'business/application-login' }"
							>{{ $t("registrationType.loginToApplications") }}</router-link
						>
					</div>

					<div v-if="msg && $bvToast.show('notification-toast')"></div>
					<div class="action">
						<br />
						<b-toast
							id="notification-toast"
							variant="secondary"
							static
							no-auto-hide
						>
							<template #toast-title>
								<div class="d-flex flex-grow-1 align-items-baseline">
									<b-img
										class="logo mb-2"
										:src="require('/public/images/logo.png')"
										alt=""
										width="12"
										height="12"
									></b-img>
									<strong class="mr-auto">{{ $t("notification") }}</strong>
									<small class="text-muted mr-2"></small>
								</div>
							</template>
							{{ msg }}
						</b-toast>
					</div>
				</div>
			</div>
		</b-form>
	</ValidationObserver>
</template>

<script>
	import { ValidationObserver, ValidationProvider } from "vee-validate";
	import AuthService from "@/services/AuthService.js";
	var countryNames = require("i18n-iso-countries");
	export default {
		name: "SignUpForm",
		components: {
			ValidationObserver,
			ValidationProvider,
		},
		/*props: {
      codeLang: {
        type: String,
        default: "en",
      },
    },*/
		//this.codeLang
		created() {
			this.codeLang = this.$i18n.locale;
			let reference = this.$route.params.reference;
			this.countryNames.registerLocale(
				require(`i18n-iso-countries/langs/${this.codeLang}.json`)
			);
		},
		watch: {
			codeLang(newVal, oldVal) {
				this.countryNames.registerLocale(
					require(`i18n-iso-countries/langs/${newVal}.json`)
				);
			},
		},
		data() {
			const now = new Date();
			const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
			// 15th two months prior
			const minDate = new Date(today);
			minDate.setYear(minDate.getFullYear() - 130);
			const maxDate = new Date(today);
			maxDate.setYear(maxDate.getFullYear() - 18);
			// minDate.setMonth(minDate.getMonth() - 2);
			// minDate.setDate(15);
			// 15th in two months

			return {
				countries: [],
				countryNames: countryNames,
				fullname: "",
				businessId: "",
				username: "",
				email: "",
				firstname: "",
				lastname: "",
				password: "",
				password_repeat: "",
				msg: "",
				pin: "",
				dob: maxDate.toISOString().slice(0, 10),
				addressLine1: "",
				city: "",
				zip: "",
				country: "",
				prefix: "",
				mobile: "",
				value: "",
				min: minDate,
				max: maxDate,
				status: "not_accepted",
				completed: "not",
				isloading: false,
			};
		},

		methods: {
			getReference() {
				const reference = this.$route.params.reference;
				return reference;
			},
			async signUpBusiness() {
				try {
					const credentials = {
						lang: this.$i18n.locale,
						fullname: this.fullname,
						businessId: this.businessId,
						username: this.username,
						email: this.email,
						firstname: this.firstname,
						lastname: this.lastname,
						password: this.password,
						password_repeat: this.password_repeat,
						pin: this.pin,
						dob: this.dob,
						addressLine1: this.addressLine1,
						city: this.city,
						zip: this.zip,
						country: this.country,
						phoneNumber: "+" + this.prefix + this.mobile,
						subType: this.$route.params.reference,
					};
					let reference = this.$route.params.reference;
					this.isloading = true;
					const response = await AuthService.signUpBusiness(
						reference,
						credentials
					);
					this.resetForm();
					this.msg = response.msg;
					this.isloading = false;
					this.completed = "yes";
					//  this.$router.push({ name: "application-login" });
				} catch (error) {
					this.msg = error.response.data.msg;
					this.isloading = false;
				}
			},
			resetForm() {
				this.fullname = "";
				this.businessId = "";
				this.username = "";
				this.firstname = "";
				(this.lastname = ""), (this.email = "");
				this.password = "";
				this.password_repeat = "";
				this.msg = "";
				this.pin = "";
				this.dob = "";
				this.addressLine1 = "";
				this.city = "";
				this.zip = "";
				this.country = "";
				this.prefix = "";
				this.mobile = "";
				requestAnimationFrame(() => {
					this.$refs.observer.reset();
				});
			},
			async getCountries() {
				try {
					const response = await AuthService.getCountries();
					this.countries = response;
					console.log(response);
				} catch (error) {
					console.log(error);
				}
			},
		},
		mounted() {
			this.getCountries();
			this.getReference();
		},
	};
</script>
