<template>
	<ValidationObserver ref="observer" v-slot="{ handleSubmit, reset }">
		<b-form
			@submit.prevent="handleSubmit(addNewInvoiceItem)"
			@reset.prevent="reset"
		>
			<div class="col-lg-8 offset-lg-2 text-left">
				<h2 class="title-bold">
					{{ $t("customerInvoiceItem.addInvoiceItem") }} ({{ invoice.id }})
				</h2>
				<hr />
				<!-- {{ this.$route.params.id }} -->
				<!-- <h5 class="text-left text-bold">
          {{ $t("customerInvoiceItem.insertTheDetails") }}
        </h5>
        <hr /> -->
				<!-- <p class="open-account text-right">
          <router-link class="btn btn-outline" :to="{ name: 'add-customer' }">{{
            $t("customerInvoiceItem.customers")
          }}</router-link>
        </p> -->
				<div class="background-outline">
					<div class="background-modifier text-left">
						<label class="small">{{ $t("customerProduct.productName") }}</label>
						<ValidationProvider
							rules="required"
							name="productName"
							v-slot="{ valid, errors }"
						>
							<b-form-group
								id="productNameInputGroup"
								label-for="productNameInput"
							>
								<b-form-select
									@change=""
									id="productNameInput"
									:state="errors[0] ? false : valid ? true : null"
									v-model="productId"
									><option
										v-for="product in products"
										:value="product.id"
										:key="product.id"
										>({{ product.id }}) {{ product.productName }} ({{
											$n(product.productUnitPriceNet, "decimal")
										}})
									</option>
									<template #first>
										<b-form-select-option value="" disabled>{{
											$t("customerProduct.productName")
										}}</b-form-select-option>
									</template></b-form-select
								>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>

						<!-- <div class="background-modifier text-left">
          <label>{{ $t("customerInvoiceItem.productName") }}</label>
          <ValidationProvider
            rules="min:1|max:50"
            name="internalId"
            v-slot="{ valid, errors }"
          >
            <b-form-group label-for="productNameInput" description="">
              <b-form-input
                type="text"
                v-model="productName"
                :state="errors[0] ? false : valid ? true : null"
                :placeholder="$t('customerInvoiceItem.productName')"
              ></b-form-input>
              <b-form-invalid-feedback id="inputLiveFeedback">{{
                errors[0]
              }}</b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider> -->

						<!-- <label>{{ $t("customerInvoiceItem.productUnitPriceNet") }}</label>
          <ValidationProvider
            rules="required|double|min_value:0.00"
            name="productUnitPriceNet"
            v-slot="{ valid, errors }"
          >
            <b-form-group label-for="productUnitPriceNet" description="">
              <b-form-input
                @input="calculateVATAmount"
                type="text"
                v-model="productUnitPriceNet"
                :state="errors[0] ? false : valid ? true : null"
                :placeholder="$t('customerInvoiceItem.productUnitPriceNet')"
              ></b-form-input>
              <b-form-invalid-feedback id="inputLiveFeedback">{{
                errors[0]
              }}</b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>

          <label>{{ $t("customerInvoiceItem.vatRate") }} (%)</label>
          <ValidationProvider
            rules="required|double|min_value:0.00"
            name="vatRate"
            v-slot="{ valid, errors }"
          >
            <b-form-group label-for="discountRateInput" description="">
              <b-form-input
                @input=""
                type="text"
                v-model="vatRate"
                :state="errors[0] ? false : valid ? true : null"
                :placeholder="$t('customerInvoiceItem.vatRate')"
              ></b-form-input>
              <b-form-invalid-feedback id="inputLiveFeedback">{{
                errors[0]
              }}</b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>

          <p>{{ $t("customerInvoiceItem.vatAmount") }}</p>
          <p>{{ $n(vatAmountResult, "decimal") }}</p>

          <p>{{ $t("customerInvoiceItem.productUnitPriceGross") }}</p>
          <p>{{ $n(calculateProductUnitPriceGross(), "decimal") }}</p> -->

						<label class="small">{{
							$t("customerInvoiceItem.numberOfItems")
						}}</label>
						<ValidationProvider
							rules="required|numeric|min_value:1"
							name="numberOfItems"
							v-slot="{ valid, errors }"
						>
							<b-form-group label-for="numberOfItems" description="">
								<b-form-input
									@input=""
									type="number"
									v-model="numberOfItems"
									:state="errors[0] ? false : valid ? true : null"
									:placeholder="$t('customerInvoiceItem.numberOfItems')"
								></b-form-input>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>

						<!-- <p>{{ $t("customerInvoiceItem.subTotal") }}</p>
          <p>{{ $n(calculateSubtotal(), "decimal") }}</p> -->
					</div>

					<hr />

					<!-- disabled-->
					<!-- <div v-if="status == 'accepted'"> -->
					<b-button
						type="submit"
						@click="isloading"
						class="btn btn-info text-light btn-block"
						>{{ $t("customerInvoiceItem.submit")
						}}<b-spinner
							v-if="isloading"
							class="ml-auto"
							:variant="light"
						></b-spinner
					></b-button>
					<!--   <p class="small">{{ $t("businessSignup.notAvailable") }}</p> -->
					<!-- </div> -->
					<!-- <hr /> -->
					<!-- <b-button
          type="reset"
          class="btn btn-alert text-light btn-block"
          @click="resetForm()"
          >{{ $t("reset") }}</b-button
        > -->
					<hr />
					<div v-if="msg && $bvToast.show('notification-toast')"></div>
					<div class="action">
						<b-toast
							id="notification-toast"
							variant="secondary"
							static
							no-auto-hide
						>
							<template #toast-title>
								<div class="d-flex flex-grow-1 align-items-baseline">
									<b-img
										class="logo mb-2"
										:src="require('/public/images/logo.png')"
										alt=""
										width="12"
										height="12"
									></b-img>
									<strong class="mr-auto">{{ $t("notification") }}</strong>
									<small class="text-muted mr-2"></small>
								</div>
							</template>
							{{ msg }}
						</b-toast>
					</div>

					<h4 class="title-bold">
						{{ $t("customerInvoiceItem.listOfItems") }} ({{ items.length }})
					</h4>
					<hr />

					<b-button
						type="reset"
						class="btn btn-alert text-light btn-block"
						@click="getInvoiceItems()"
						>{{ $t("customerInvoiceItem.view") }}</b-button
					>
					<hr />

					<div v-if="items.length > 0">
						<div class="background-modifier">
							<div class="input-group mb-3">
								<input
									type="text"
									class="form-control"
									:placeholder="$t('customerInvoiceItem.productName')"
									v-model="searchTitle"
								/>
								<div class="input-group-append">
									<button
										class="btn btn-outline"
										type="button"
										@click="
											page = 1;
											getInvoiceItems();
										"
									>
										{{ $t("search") }}
									</button>
								</div>
							</div>

							<div v-if="items.length > 0">
								<div class="table-wrap">
									<table class="table mt-2" id="items">
										<thead>
											<tr>
												<th class="text-left small">
													{{ $t("customerInvoiceItem.id") }}
												</th>
												<th class="text-left small">
													{{ $t("customerInvoiceItem.productName") }}
												</th>
												<th class="text-right small">
													{{ $t("customerInvoiceItem.productUnitPriceGross") }}
												</th>
												<th class="text-right small">
													{{ $t("customerInvoiceItem.numberOfItems") }}
												</th>
												<th class="text-centered small">
													{{ $t("customerInvoiceItem.subTotal") }}
												</th>
												<th class="text-centered small">
													{{ $t("customerInvoiceItem.actions") }}
												</th>
											</tr>
										</thead>
										<tbody>
											<tr v-for="item in items" :key="item.id">
												<td class="text-left">
													{{ item.id }}
												</td>
												<td class="text-left">
													{{ item.productName }}
												</td>
												<td class="text-right">
													{{ $n(item.productUnitPriceGross, "decimal") }}
												</td>
												<td class="text-right">
													{{ item.numberOfItems }}
												</td>
												<td class="text-right">
													{{ $n(item.subTotal, "decimal") }}
												</td>

												<td class="text-right">
													<router-link
														:to="{
															name: 'invoice-item-details',
															params: { id: item.id },
														}"
														class="btn btn-outline mx-1 "
														>{{ $t("customerInvoiceItem.view") }}</router-link
													>
													<a
														class="btn btn-danger mx-1 "
														@click="deleteInvoiceItem(item.id)"
														>{{ $t("customerInvoiceItem.delete") }}</a
													>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
								<div v-if="items.length >= 1">
									<div class="row">
										<div class="col-md-6">
											{{ $t("itemsPerPage") }}
											<select
												class="custom-select"
												style="width: 60px;"
												v-model="pageSize"
												@change="handlePageSizeChange($event)"
											>
												<option
													v-for="size in pageSizes"
													:key="size"
													:value="size"
												>
													{{ size }}
												</option>
											</select>
										</div>
										<div class="col-md-6">
											<b-pagination
												v-model="page"
												:total-rows="count"
												:per-page="pageSize"
												:prev-text="$t('previous')"
												:next-text="$t('next')"
												align="right"
												@change="handlePageChange"
											></b-pagination>
										</div>
									</div>
								</div>
							</div>

							<div v-else>
								{{ $t("customerInvoiceItem.noItems") }}
							</div>
						</div>
					</div>
				</div>
			</div>
		</b-form>
	</ValidationObserver>
</template>

<script>
	import { ValidationObserver, ValidationProvider } from "vee-validate";
	import AuthService from "@/services/AuthService.js";
	// var countryNames = require("i18n-iso-countries");
	export default {
		name: "AddNewProduct",
		components: {
			ValidationObserver,
			ValidationProvider,
		},
		/*props: {
        codeLang: {
          type: String,
          default: "en",
        },
      },*/
		//this.codeLang
		created() {
			this.codeLang = this.$i18n.locale;
			// this.countryNames.registerLocale(
			//   require(`i18n-iso-countries/langs/${this.codeLang}.json`)
			// );
		},
		watch: {
			// codeLang(newVal, oldVal) {
			//   this.countryNames.registerLocale(
			//     require(`i18n-iso-countries/langs/${newVal}.json`)
			//   );
			// },
		},
		data() {
			// const now = new Date();
			// const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
			// // 15th two months prior
			// const minDate = new Date(today);
			// minDate.setYear(minDate.getFullYear() - 0);
			// const maxDate = new Date(today);
			// maxDate.setYear(maxDate.getFullYear() + 2);
			// minDate.setMonth(minDate.getMonth() - 2);
			// minDate.setDate(15);
			// 15th in two months

			return {
				products: [],
				items: [],
				invoice: "",
				msg: "",
				productName: "",
				productUnitPriceNet: 0.0,
				productUnitPriceGross: 0.0,
				vatRate: 21.0,
				numberOfItems: 1,
				subTotal: 0.0,
				invoiceId: 0,
				productId: 0,

				currentItem: null,

				currentIndex: -1,
				searchTitle: "",
				isloading: false,
				page: 1,
				count: 0,
				pageSize: 10,

				pageSizes: [10, 25, 50],
				// min: minDate,
				// max: maxDate,
				//status: "not_accepted",
			};
		},
		async created() {
			if (!this.$store.getters.isLoggedIn) {
				this.$router.push("login");
			}
			if (
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 3 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 15 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 14 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 13 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 12
			) {
				this.$router.push("overview");
			}
			let lang = this.$i18n.locale;
			this.role = this.$store.getters.getUser.role;
			// this.username = this.$store.getters.getUser.username;
			// this.firstname = this.$store.getters.getUser.firstname;
			// this.getLatestInvoice();
			// this.getInvoiceItems();
			//this.getOfferById();
		},
		methods: {
			// calculateVATAmount() {
			//   this.vatAmountResult = (this.productUnitPriceNet * this.vatRate) / 100;
			//   return this.vatAmountResult;
			// },
			// calculateProductUnitPriceGross() {
			//   this.productUnitPriceGrossResult =
			//     1 * this.productUnitPriceNet + this.vatAmountResult;
			//   return this.productUnitPriceGrossResult;
			// },
			// calculateSubtotal() {
			//   this.subtotalResult =
			//     this.productUnitPriceGrossResult * this.numberOfItems;
			//   return this.subtotalResult;
			// },
			// calculateVATAmount() {
			//   this.vatAmountResult = (this.totalNetResult * this.vatRate) / 100;
			//   return this.vatAmountResult;
			// },
			// calculateTotalInclVAT() {
			//   this.totalIncVATResult = this.totalNetResult - this.vatAmountResult;
			//   return this.totalIncVATResult;
			// },

			async addNewInvoiceItem() {
				try {
					const details = {
						userId: this.$store.getters.getUser.id,
						lang: this.$i18n.locale,
						clientId: this.clientId,
						invoiceId: this.invoice.id,
						productId: this.productId,
						//id: this.$route.params.id,
						productName: this.productName,
						// productDescription: this.productDescription,
						productUnitPriceNet: this.productUnitPriceNet,
						vatRate: this.vatRate,
						vatAmount: this.vatAmount, //this.vatAmountResult,
						productUnitPriceGross: this.productUnitPriceGross, //this.calculateProductUnitPriceGross(),
						numberOfItems: this.numberOfItems,
						subTotal: this.subTotal, //this.calculateSubtotal(),
					};
					this.isloading = true;
					const response = await AuthService.addNewInvoiceItem(details);
					// this.resetForm();
					this.msg = response.msg;
					this.isloading = false;
					console.log(response.msg);
				} catch (error) {
					this.msg = error.response.data.msg;
					this.isloading = false;
					//console.log(error);
				}
			},
			async getLatestInvoice() {
				try {
					const id = this.$store.getters.getUser.id;
					const response = await AuthService.getLatestInvoice(id);
					this.invoice = response;
					console.log(response);
				} catch (error) {
					console.log(error);
				}
			},

			resetForm() {
				// this.email = "";
				this.productName = "";
				// this.productDescription = "";
				this.productUnitPriceGross = 0.0;
				this.vatRate = 0.0;
				this.productUnitPriceNet = 0.0;
				this.numberOfItems = 1;
				this.subTotal = 0;
				this.msg = "";

				requestAnimationFrame(() => {
					this.$refs.observer.reset();
				});
			},

			async getClientsProductList() {
				try {
					const details = {
						id: this.$store.getters.getUser.id,
					};
					const response = await AuthService.getClientsProductList(details.id);
					this.products = response;
					console.log(response);
				} catch (error) {
					console.log(error);
				}
			},
			getRequestParams(searchTitle, page, pageSize) {
				let params = {};

				if (searchTitle) {
					params["title"] = searchTitle;
				}

				if (page) {
					params["page"] = page - 1;
				}

				if (pageSize) {
					params["size"] = pageSize;
				}

				return params;
			},
			async getInvoiceItems() {
				const params = this.getRequestParams(
					this.searchTitle,
					this.page,
					this.pageSize
				);

				try {
					const details = {
						id: this.invoice.id,
					};
					const response = await AuthService.getInvoiceItems(
						params.page,
						params.size,
						params.title,
						details.id
					);
					const { items, totalItems } = response.data;
					this.items = items;
					this.count = totalItems;
					console.log(response.data);
				} catch (error) {
					console.log(error);
				}
			},

			handlePageChange(value) {
				this.page = value;
				this.getInvoiceItems();
			},

			handlePageSizeChange(event) {
				this.pageSize = event.target.value;
				this.page = 1;
				this.getInvoiceItems();
			},

			async deleteInvoiceItem(id) {
				try {
					const lang = this.$i18n.locale;
					const response = await AuthService.deleteInvoiceItem(id, lang);
					console.log(response);
					this.getInvoiceItems();
					this.msg = response.msg;
					console.log(response.msg);
				} catch (error) {
					console.log(error);
					this.msg = error.response.data.msg;
				}
			},
		},
		mounted() {
			this.getClientsProductList();
			this.getLatestInvoice();
			this.getInvoiceItems();
			// this.getCountries();
			// this.getClientsCurrentAccounts();
			//  this.getUserId();
		},
	};
</script>
