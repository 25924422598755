<template>
	<div class="col-md-8 offset-md-2">
		<h1 class="title-bold text-left">{{ $t("dashboard.dashboard") }}</h1>
		<hr />
		<div class="background-outline text-center">
			<div v-if="isloading" class="text-center">
				<b-spinner
					style="width: 3rem; height: 3rem;"
					class="ml-auto text-center"
					:variant="secondary"
				></b-spinner>
			</div>
			<div v-else>
				<p class="text-left">{{ adminMessage }}</p>
				<hr />
				<p class="small">{{ $t("dashboard.newUsers") }}</p>
				<div class="row">
					<div class="col-md-3">
						<div class="background-modifier">
							<p class="small">{{ $t("dashboard.hourly") }}</p>
							<h3 class="title-bold">
								{{ $n(latestUsersWithin1h.count) }}
							</h3>
							<p>
								{{
									hourlyUserGrowth() != null
										? $n(hourlyUserGrowth(), "decimal")
										: $n(0.0, "decimal")
								}}%
							</p>
						</div>
					</div>
					<div class="col-md-3">
						<div class="background-modifier">
							<p class="small">{{ $t("dashboard.daily") }}</p>
							<h3 class="title-bold">
								{{ $n(latestUsersWithin1d.count) }}
							</h3>
							<p>
								{{
									dailyUserGrowth() != null
										? $n(dailyUserGrowth(), "decimal")
										: $n(0.0, "decimal")
								}}%
							</p>
						</div>
					</div>
					<div class="col-md-3">
						<div class="background-modifier">
							<p class="small">{{ $t("dashboard.weekly") }}</p>
							<h3 class="title-bold">
								{{ $n(latestUsersWithin1w.count) }}
							</h3>
							<p>
								{{
									weeklyUserGrowth() != null
										? $n(weeklyUserGrowth(), "decimal")
										: $n(0.0, "decimal")
								}}%
							</p>
						</div>
					</div>
					<div class="col-md-3">
						<div class="background-modifier">
							<p class="small">{{ $t("dashboard.monthly") }}</p>
							<h3 class="title-bold">
								{{ $n(latestUsersWithin1m.count) }}
							</h3>
							<p>
								{{
									monthlyUserGrowth() != null
										? $n(monthlyUserGrowth(), "decimal")
										: $n(0.0, "decimal")
								}}%
							</p>
						</div>
					</div>
				</div>

				<div class="row">
					<div class="col-md-12">
						<div class="background-modifier">
							<p class="small">{{ $t("dashboard.totalUsers") }}</p>
							<h3 class="title-bold">{{ $n(totalUsers.count) }}</h3>
							<div>
								<MapChart
									:countryData="countryData"
									highColor="#1fb9d5"
									lowColor="#aaaaaa"
									countryStrokeColor="#909090"
									defaultCountryFillColor="#dadada"
									:LangUser="this.$i18n.locale"
								/>
							</div>
						</div>
					</div>
				</div>

				<div class="row">
					<div class="col-md-12">
						<div class="background-modifier">
							<div class="row">
								<div class="col-md-4">
									<p class="small">
										{{ $t("dashboard.totalIndividualClients") }}
									</p>
									<h4 class="title-bold">
										{{ $n(totalIndividualClients.count) }}
									</h4>
								</div>
								<div class="col-md-4">
									<p class="small">{{ $t("dashboard.totalClients") }}</p>
									<h1 class="title-bold">{{ $n(totalClients.count) }}</h1>
								</div>
								<div class="col-md-4">
									<p class="small">
										{{ $t("dashboard.totalBusinessClients") }}
									</p>
									<h4 class="title-bold">
										{{ $n(totalBusinessClients.count) }}
									</h4>
								</div>
							</div>
							<router-link
								:to="{ name: 'client-list' }"
								class="btn btn-secondary mx-1 "
								>{{ $t("dashboard.clients") }}
								<!-- <font-awesome-icon icon="arrow-right"
          /> -->
							</router-link>
						</div>
					</div>
				</div>

				<div class="row">
					<div class="col-md-12">
						<div class="background-modifier">
							<div class="row">
								<div class="col-md-4">
									<p class="small">
										{{ $t("dashboard.totalIndividualClientApplications") }}
									</p>
									<h4 class="title-bold">
										{{ $n(totalIndividualClientApplications.count) }}
									</h4>
								</div>
								<div class="col-md-4">
									<p class="small">
										{{ $t("dashboard.totalClientApplications") }}
									</p>
									<h1 class="title-bold">
										{{ $n(totalClientApplications.count) }}
									</h1>
								</div>
								<div class="col-md-4">
									<p class="small">
										{{ $t("dashboard.totalBusinessClientApplications") }}
									</p>
									<h4 class="title-bold">
										{{ $n(totalBusinessClientApplications.count) }}
									</h4>
								</div>
							</div>
							<router-link
								:to="{ name: 'applications' }"
								class="btn btn-secondary mx-1 "
								>{{ $t("dashboard.applications") }}
								<!-- <font-awesome-icon icon="arrow-right"
          /> -->
							</router-link>
						</div>
					</div>
				</div>

				<div class="row">
					<div class="col-md-4">
						<div class="background-modifier">
							<p class="small">{{ $t("dashboard.totalUsers") }}</p>
							<h1 class="title-bold">{{ $n(totalUsers.count) }}</h1>
							<router-link
								:to="{ name: 'user-list' }"
								class="btn btn-secondary mx-1 "
								>{{ $t("dashboard.users") }}
								<!-- <font-awesome-icon icon="arrow-right"
          /> -->
							</router-link>
						</div>
					</div>
					<div class="col-md-4">
						<div class="background-modifier">
							<p class="small">{{ $t("dashboard.totalAccounts") }}</p>
							<h1 class="title-bold">{{ $n(totalAccounts.count) }}</h1>
							<router-link
								:to="{ name: 'account-list' }"
								class="btn btn-secondary mx-1 "
								>{{ $t("dashboard.accounts") }}
								<!-- <font-awesome-icon icon="arrow-right"
          /> -->
							</router-link>
						</div>
					</div>
					<div class="col-md-4">
						<div class="background-modifier">
							<p class="small">{{ $t("dashboard.totalTransactions") }}</p>
							<h1 class="title-bold">{{ $n(totalTransactions.count) }}</h1>
							<router-link
								:to="{ name: 'transaction-list' }"
								class="btn btn-secondary mx-1 "
								>{{ $t("dashboard.transactions") }}
								<!-- <font-awesome-icon icon="arrow-right"
          /> -->
							</router-link>
						</div>
					</div>
				</div>

				<div class="row">
					<div class="col-md-6">
						<div class="background-modifier">
							<p class="small">{{ $t("dashboard.totalUsers") }}</p>
							<div class="container">
								<trend
									v-if="loaded"
									:data="chartData"
									:gradientDirection="left"
									:gradient="['#808080', '#d6d6d6', '#2c3e50']"
									:padding="7.8"
									:radius="9.2"
									:stroke-width="1.4"
									:stroke-linecap="round"
									auto-draw
									smooth
								>
								</trend>
							</div>
						</div>
					</div>
					<div class="col-md-6">
						<div class="background-modifier">
							<p class="small">
								{{ $t("dashboard.logins") }}
							</p>
							<div class="container">
								<trend
									v-if="loaded2"
									:data="loginData"
									:gradient="['#6fa8dc', '#42b983', '#2c3e50']"
									auto-draw
									smooth
								>
								</trend>
							</div>
						</div>
					</div>
				</div>

				<div class="row">
					<div class="col-md-3">
						<div class="background-modifier">
							<p class="small">{{ $t("dashboard.totalClientDeposits") }}</p>
							<h3 class="title-bold">
								{{
									totalDepositBalance() != null
										? $n(totalDepositBalance(), "decimal")
										: $n(0.0, "decimal")
								}}
							</h3>
						</div>
					</div>
					<div class="col-md-3">
						<div class="background-modifier">
							<p class="small">{{ $t("dashboard.totalClientLoans") }}</p>
							<h3 class="title-bold">
								{{
									totalLoanBalance() != null
										? $n(totalLoanBalance(), "decimal")
										: $n(0.0, "decimal")
								}}
							</h3>
						</div>
					</div>
					<div class="col-md-3">
						<div class="background-modifier">
							<p class="small">
								{{ $t("dashboard.weighedAverageDepositInterest") }}
							</p>
							<h3 class="title-bold">
								{{
									averageDepositInterest() / totalDepositBalance() != null
										? $n(
												averageDepositInterest() / totalDepositBalance(),
												"decimal"
										  )
										: $n(0.0, "decimal")
								}}%
							</h3>
						</div>
					</div>
					<div class="col-md-3">
						<div class="background-modifier">
							<p class="small">
								{{ $t("dashboard.weighedAverageLoanInterest") }}
							</p>
							<h3 class="title-bold">
								{{
									averageLoanInterest() / totalLoanBalance() != null
										? $n(averageLoanInterest() / totalLoanBalance(), "decimal")
										: $n(0.0, "decimal")
								}}%
							</h3>
						</div>
					</div>
				</div>

				<div class="row">
					<div class="col-md-3">
						<div class="background-modifier">
							<p class="small">{{ $t("dashboard.totalInterestIncome") }}</p>
							<h3 class="title-bold">
								{{
									totalInterestIncome() != null
										? $n(totalInterestIncome(), "decimal")
										: $n(0.0, "decimal")
								}}
							</h3>
						</div>
					</div>
					<div class="col-md-3">
						<div class="background-modifier">
							<p class="small">{{ $t("dashboard.totalInterestExpenses") }}</p>
							<h3 class="title-bold">
								{{
									totalInterestExpenses() != null
										? $n(totalInterestExpenses(), "decimal")
										: $n(0.0, "decimal")
								}}
							</h3>
						</div>
					</div>
					<div class="col-md-3">
						<div class="background-modifier">
							<p class="small">{{ $t("dashboard.interestMargin") }}</p>
							<h3 class="title-bold">
								{{
									totalInterestIncome() - totalInterestExpenses() != null
										? $n(
												totalInterestIncome() - totalInterestExpenses(),
												"decimal"
										  )
										: $n(0.0, "decimal")
								}}
							</h3>
						</div>
					</div>
					<div class="col-md-3">
						<div class="background-modifier">
							<p class="small">{{ $t("dashboard.totalFeeIncome") }}</p>
							<h3 class="title-bold">
								{{
									totalFeeIncome() != null
										? $n(totalFeeIncome(), "decimal")
										: $n(0.0, "decimal")
								}}
							</h3>
						</div>
					</div>
				</div>
				<br />
				<p v-if="msg">{{ msg }}</p>
				<!-- <hr /> -->
				<!-- <div class="col-4 offset-md-4">
      <router-link
        :to="{ name: 'document-upload' }"
        class="btn btn-secondary mx-1 "
        >{{ $t("dashboard.fileUpload") }}</router-link
      >
    </div> -->
			</div>
		</div>
	</div>
</template>

<script>
	import AuthService from "@/services/AuthService.js";
	import Vue from "vue";
	import Trend from "vuetrend";
	import MapChart from "@/components/MapChart.vue";

	//Vue.use(MapChart);

	Vue.use(Trend);

	export default {
		components: {
			MapChart,
		},
		data() {
			return {
				username: "",
				adminMessage: "",
				totalUsers: "",
				totalClients: "",
				totalIndividualClients: "",
				totalBusinessClients: "",
				totalClientApplications: "",
				totalIndividualClientApplications: "",
				totalBusinessClientsApplications: "",
				totalAccounts: "",
				totalTransactions: "",
				latestUsersWithin1h: "",
				latestUsersBefore1h: "",
				latestUsersWithin1d: "",
				latestUsersBefore1d: "",
				latestUsersWithin1w: "",
				latestUsersBefore1w: "",
				latestUsersWithin1m: "",
				latestUsersBefore1m: "",
				totalDeposits: "",
				totalLoans: "",
				//  averageDepositInterest: "",
				//  averageLoanInterest: "",
				interestIncome: "",
				interestExpenses: "",
				feeIncome: "",
				isloading: false,
				msg: "",

				loaded: false,
				chartData: null,
				loaded2: false,
				loginData: null,
				countryData: null,
			};
		},
		async created() {
			if (!this.$store.getters.isLoggedIn) {
				this.$router.push("login");
			}
			if (
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role !== 3
			) {
				this.$router.push("overview");
			}
			let lang = this.$i18n.locale;
			this.username = this.$store.getters.getUser.username;
			this.role = this.$store.getters.getUser.role;
			this.adminMessage = await AuthService.getAdminContent(lang);
			this.msg = adminMessage.msg;
			// this.getTotalUsers();
		},

		methods: {
			async getUserList() {
				try {
					//this.isloading = true;
					const response = await AuthService.getUserList();
					this.userList = response;
					//this.isloading = false;
					console.log(response);
				} catch (error) {
					//this.isloading = false;
					console.log(error);
				}
			},
			async getTotalUsers() {
				try {
					this.isloading = true;
					const response = await AuthService.getTotalUsers();
					this.totalUsers = response;
					this.isloading = false;
					console.log(response);
				} catch (error) {
					this.isloading = false;
					console.log(error);
				}
			},
			async getTotalClients() {
				try {
					const response = await AuthService.getTotalClients();
					this.totalClients = response;
					console.log(response);
				} catch (error) {
					console.log(error);
				}
			},
			async getTotalIndividualClients() {
				try {
					const response = await AuthService.getTotalIndividualClients();
					this.totalIndividualClients = response;
					console.log(response);
				} catch (error) {
					console.log(error);
				}
			},
			async getTotalBusinessClients() {
				try {
					const response = await AuthService.getTotalBusinessClients();
					this.totalBusinessClients = response;
					console.log(response);
				} catch (error) {
					console.log(error);
				}
			},
			async getTotalClientApplications() {
				try {
					const response = await AuthService.getTotalClientApplications();
					this.totalClientApplications = response;
					console.log(response);
				} catch (error) {
					console.log(error);
				}
			},
			async getTotalIndividualClientApplications() {
				try {
					const response = await AuthService.getTotalIndividualClientApplications();
					this.totalIndividualClientApplications = response;
					console.log(response);
				} catch (error) {
					console.log(error);
				}
			},
			async getTotalBusinessClientApplications() {
				try {
					const response = await AuthService.getTotalBusinessClientApplications();
					this.totalBusinessClientApplications = response;
					console.log(response);
				} catch (error) {
					console.log(error);
				}
			},
			async getTotalAccounts() {
				try {
					const response = await AuthService.getTotalAccounts();
					this.totalAccounts = response;
					console.log(response);
				} catch (error) {
					console.log(error);
				}
			},
			async getTotalTransactions() {
				try {
					const response = await AuthService.getTotalTransactions();
					this.totalTransactions = response;
					console.log(response);
				} catch (error) {
					console.log(error);
				}
			},
			async getLatestUsersWithin1h() {
				try {
					const response = await AuthService.getLatestUsersWithin1h();
					this.latestUsersWithin1h = response;
					console.log(response);
				} catch (error) {
					console.log(error);
				}
			},
			async getLatestUsersBefore1h() {
				try {
					const response = await AuthService.getLatestUsersBefore1h();
					this.latestUsersBefore1h = response;
					console.log(response);
				} catch (error) {
					console.log(error);
				}
			},
			async getLatestUsersWithin1d() {
				try {
					const response = await AuthService.getLatestUsersWithin1d();
					this.latestUsersWithin1d = response;
					console.log(response);
				} catch (error) {
					console.log(error);
				}
			},
			async getLatestUsersBefore1d() {
				try {
					const response = await AuthService.getLatestUsersBefore1d();
					this.latestUsersBefore1d = response;
					console.log(response);
				} catch (error) {
					console.log(error);
				}
			},
			async getLatestUsersWithin1w() {
				try {
					const response = await AuthService.getLatestUsersWithin1w();
					this.latestUsersWithin1w = response;
					console.log(response);
				} catch (error) {
					console.log(error);
				}
			},
			async getLatestUsersBefore1w() {
				try {
					const response = await AuthService.getLatestUsersBefore1w();
					this.latestUsersBefore1w = response;
					console.log(response);
				} catch (error) {
					console.log(error);
				}
			},
			async getLatestUsersWithin1m() {
				try {
					const response = await AuthService.getLatestUsersWithin1m();
					this.latestUsersWithin1m = response;
					console.log(response);
				} catch (error) {
					console.log(error);
				}
			},
			async getLatestUsersBefore1m() {
				try {
					const response = await AuthService.getLatestUsersBefore1m();
					this.latestUsersBefore1m = response;
					console.log(response);
				} catch (error) {
					console.log(error);
				}
			},
			async getTotalDeposits() {
				try {
					const response = await AuthService.getTotalDeposits();
					this.totalDeposits = response;
					console.log(response);
				} catch (error) {
					console.log(error);
				}
			},
			async getTotalLoans() {
				try {
					const response = await AuthService.getTotalLoans();
					this.totalLoans = response;
					console.log(response);
				} catch (error) {
					console.log(error);
				}
			},
			/* async getAverageDepositInterest() {
      try {
        const response = await AuthService.getAverageDepositInterest();
        this.averageDepositInterest = response;
        console.log(response);
      } catch (error) {
        console.log(error);
      }
    }, */
			/* async getAverageLoanInterest() {
      try {
        const response = await AuthService.getAverageLoanInterest();
        this.averageLoanInterest = response;
        console.log(response);
      } catch (error) {
        console.log(error);
      }
    }, */
			async getTotalInterestIncome() {
				try {
					const response = await AuthService.getTotalInterestIncome();
					this.interestIncome = response;
					console.log(response);
				} catch (error) {
					console.log(error);
				}
			},
			async getTotalInterestExpenses() {
				try {
					const response = await AuthService.getTotalInterestExpenses();
					this.interestExpenses = response;
					console.log(response);
				} catch (error) {
					console.log(error);
				}
			},
			async getTotalFeeIncome() {
				try {
					const response = await AuthService.getTotalFeeIncome();
					this.feeIncome = response;
					console.log(response);
				} catch (error) {
					console.log(error);
				}
			},

			hourlyUserGrowth() {
				return (
					((this.latestUsersWithin1h.count + this.latestUsersBefore1h.count) /
						this.latestUsersBefore1h.count -
						1) *
					100
				);
			},
			dailyUserGrowth() {
				return (
					((this.latestUsersWithin1d.count + this.latestUsersBefore1d.count) /
						this.latestUsersBefore1d.count -
						1) *
					100
				);
			},
			weeklyUserGrowth() {
				return (
					((this.latestUsersWithin1w.count + this.latestUsersBefore1w.count) /
						this.latestUsersBefore1w.count -
						1) *
					100
				);
			},
			monthlyUserGrowth() {
				return (
					((this.latestUsersWithin1m.count + this.latestUsersBefore1m.count) /
						this.latestUsersBefore1m.count -
						1) *
					100
				);
			},
			totalDepositBalance() {
				return this.totalDeposits.reduce(
					(acc, item) => acc + item.baseBalance,
					0
				);
			},
			totalLoanBalance() {
				return this.totalLoans.reduce((acc, item) => acc + item.baseBalance, 0);
			},
			totalInterestIncome() {
				return this.interestIncome.reduce(
					(acc, item) => acc + item.baseBalance,
					0
				);
			},
			totalInterestExpenses() {
				return this.interestExpenses.reduce(
					(acc, item) => acc + item.baseBalance,
					0
				);
			},
			averageDepositInterest() {
				return this.totalDeposits.reduce(
					(acc, item) => acc + (item.baseBalance * item.interestRate) / 100,
					0
				);
			},
			averageLoanInterest() {
				return this.totalLoans.reduce(
					(acc, item) => acc + (item.baseBalance * item.interestRate) / 100,
					0
				);
			},
			totalFeeIncome() {
				return this.feeIncome.reduce((acc, item) => acc + item.baseBalance, 0);
			},
			async getNewUserData() {
				this.loaded = false;
				try {
					const response = await AuthService.getNewUserData();
					this.chartData = response;
					this.loaded = true;
					console.log(response);
				} catch (error) {
					console.log(error);
				}
			},
			async getLoginData() {
				this.loaded2 = false;
				try {
					const response = await AuthService.getLoginData();
					this.loginData = response;
					this.loaded2 = true;
					console.log(response);
				} catch (error) {
					console.log(error);
				}
			},
			async getCountryData() {
				try {
					const response = await AuthService.getCountryData();
					//this.countryData = response; // { US: 4, CA: 7, GB: 8, FI: 4, BE: 1 }; //
					// this.countryData = response;

					this.countryData = response;

					console.log(countryData);
				} catch (error) {
					console.log(error);
				}
			},
		},
		mounted() {
			//this.getUserList();
			this.getTotalUsers();
			this.getTotalClients();
			this.getTotalIndividualClients();
			this.getTotalBusinessClients();
			this.getTotalClientApplications();
			this.getTotalIndividualClientApplications();
			this.getTotalBusinessClientApplications();
			this.getTotalAccounts();
			this.getTotalTransactions();
			this.getLatestUsersWithin1h();
			this.getLatestUsersBefore1h();
			this.getLatestUsersWithin1d();
			this.getLatestUsersBefore1d();
			this.getLatestUsersWithin1w();
			this.getLatestUsersBefore1w();
			this.getLatestUsersWithin1m();
			this.getLatestUsersBefore1m();
			this.monthlyUserGrowth();
			this.getTotalDeposits();
			this.getTotalLoans();
			// this.getAverageDepositInterest();
			// this.getAverageLoanInterest();
			this.getTotalInterestIncome();
			this.getTotalInterestExpenses();
			this.getTotalFeeIncome();
			//  this.totalDepositBalance();
			//  this.totalLoanBalance();
			//  this.totalInterestIncome();
			//  this.totalInterestExpenses();
			//  this.totalFeeIncome();
			this.getNewUserData();
			this.getLoginData();
			this.getCountryData();
		},
	};
</script>
