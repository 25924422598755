<template>
	<div class="col-md-4 offset-md-4 text-left">
		<h2 class="title-bold">
			{{ $t("editAccount.editAccount") }} {{ this.$route.params.id }}
		</h2>
		<hr />
		<div class="background-outline">
			<div class="background-modifier">
				<label class="text-left small">{{ $t("editAccount.nickname") }}</label>
				<div class="input-group mb-3">
					<input
						type="text"
						class="form-control"
						id="nickname"
						v-model="account.nickname"
					/>
				</div>
			</div>
			<br />
			<div v-if="isloading" class="text-center">
				<b-spinner
					style="width: 3rem; height: 3rem;"
					class="ml-auto text-center"
					:variant="secondary"
				></b-spinner>
			</div>
			<div v-if="!isloading">
				<input
					type="button"
					class="btn btn-info btn-block"
					@click="editAccount"
					:value="$t('editAccount.submit')"
				/>
			</div>

			<div v-if="msg && $bvToast.show('notification-toast')"></div>
			<div class="action">
				<br />
				<b-toast
					id="notification-toast"
					variant="secondary"
					static
					no-auto-hide
				>
					<template #toast-title>
						<div class="d-flex flex-grow-1 align-items-baseline">
							<b-img
								class="logo mb-2"
								:src="require('/public/images/logo.png')"
								alt=""
								width="12"
								height="12"
							></b-img>
							<strong class="mr-auto">{{ $t("notification") }}</strong>
							<small class="text-muted mr-2"></small>
						</div>
					</template>
					{{ msg }}
				</b-toast>
			</div>
		</div>
	</div>
</template>

<script>
	import AuthService from "@/services/AuthService.js";

	export default {
		data() {
			return {
				account: "",
				msg: "",
				isloading: false,
			};
		},
		async created() {
			if (!this.$store.getters.isLoggedIn) {
				this.$router.push("login");
			}
			if (
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role !== 3
			) {
				this.$router.push("overview");
			}

			this.role = this.$store.getters.getUser.role;
		},
		methods: {
			async getAccount(id) {
				try {
					const id = this.$route.params.id;

					const response = await AuthService.getAccount(id);
					this.account = response;

					console.log(response);
				} catch (error) {
					console.log(error);
				}
			},
			async editAccount() {
				try {
					const id = this.$route.params.id;
					const details = {
						lang: this.$i18n.locale,
						nickname: this.account.nickname,
					};
					this.isloading = true;
					const response = await AuthService.editAccount(id, details);
					this.msg = response.msg;
					this.isloading = false;
					this.getAccount();
					console.log(response.msg);
				} catch (error) {
					console.log(error);
					this.msg = error.response.data.msg;
					this.isloading = false;
				}
			},
		},
		mounted() {
			this.getAccount();
		},
	};
</script>
