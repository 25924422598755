<template>
	<div v-if="getUserId() === card.userId">
		<div class="col-12 offset-0">
			<div class="text-left">
				<div class="col-md-8 offset-md-2 ">
					<h2 class="title-bold">
						{{ card.cardnumber.toString().replace(/(\d{4}(?!\s))/g, "$1 ") }}
					</h2>
					<hr />

					<div class="background-modifier">
						<div class="product-details">
							<label class="small">{{ $t("cardDetails.cardholder") }}</label>
							<p class="text-left">
								{{ card.cardholder }}
							</p>
							<label class="small">{{ $t("cardDetails.cardnumber") }}</label>
							<p class="text-left">
								{{ card.cardnumber.toString().replace(/\w{4}(?=.)/g, "$& ") }}
							</p>
							<label class="small">{{ $t("cardDetails.currency") }}</label>
							<p class="text-left">
								{{ card.currency }}
							</p>
							<label class="small">{{
								$t("cardDetails.debitAccountId")
							}}</label>
							<p class="text-left">
								{{ card.iban.toString().replace(/\w{4}(?=.)/g, "$& ") }}
							</p>
							<label class="small">{{ $t("cardDetails.paymentLimit") }}</label>
							<p class="text-left">
								{{ $n(card.paymentLimit, "decimal") }}
							</p>
							<label class="small">{{ $t("cardDetails.validity") }}</label>
							<p class="text-left">
								{{ $d(Date.parse(card.validity), "short") }}
							</p>
							<hr />
							<label class="small">{{
								$t("cardDetails.paymentsAbroad")
							}}</label>
							<p class="text-left">
								{{
									card.paymentsAbroad == "1"
										? $t("cardDetails.yes")
										: $t("cardDetails.no")
								}}
							</p>
							<label class="small">{{
								$t("cardDetails.onlinePayments")
							}}</label>
							<p class="text-left">
								{{
									card.onlinePayments == "1"
										? $t("cardDetails.yes")
										: $t("cardDetails.no")
								}}
							</p>
							<label class="small">{{
								$t("cardDetails.atmWithdrawals")
							}}</label>
							<p class="text-left">
								{{
									card.atmWithdrawals == "1"
										? $t("cardDetails.yes")
										: $t("cardDetails.no")
								}}
							</p>
							<label class="small">{{ $t("cardDetails.blockCard") }}</label>
							<p class="text-left">
								{{
									card.blockCard == "1"
										? $t("cardDetails.yes")
										: $t("cardDetails.no")
								}}
							</p>
						</div>
						<div class="text-right">
							<router-link
								:to="{ name: 'manage-card', params: { id: card.id } }"
								class="btn btn-outline mx-1 "
								>{{ $t("cardDetails.manage") }}</router-link
							>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div v-else>
		<h3>{{ $t("unauthorized") }}</h3>
	</div>
</template>

<script>
	import AuthService from "@/services/AuthService.js";
	import Vue from "vue";

	export default {
		name: "",

		data() {
			return {
				card: "",
				//  nickname: '',

				msg: "",
			};
		},
		async created() {
			if (!this.$store.getters.isLoggedIn) {
				this.$router.push("login");
			}

			if (
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 3 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 15 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 14 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 13 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 12 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 11
			) {
				this.$router.push("overview");
			}
			let lang = this.$i18n.locale;
			this.role = this.$store.getters.getUser.role;
			this.username = this.$store.getters.getUser.username;
			this.firstname = this.$store.getters.getUser.firstname;
			this.getCardById();

			//  this.getAcctId();
			//   this.updateAccount();
			//  this.getAccountPayments();
			//  this.getCreditAmount();
			//  this.getDebitAmount();
		},
		methods: {
			getCardId() {
				const cardId = this.$route.params.id;
				return cardId;
			},
			getUserId() {
				const userId = this.$store.getters.getUser.id;
				return userId;
			},

			async getCardById(id) {
				try {
					const id = this.$route.params.id;
					const response = await AuthService.getCardById(id);
					this.card = response;
					console.log(response);
				} catch (error) {
					console.log(error);
				}
			},

			/*  async updateAccount(id) {
        try {
          const id = this.$route.params.id;
         
          const details = {
            nickname: this.card.nickname
          };
          const response = await AuthService.updateAccount(id, details);
          this.msg = response.msg;
          console.log(response.msg);
        } catch (error) {
          console.log(error);
        }
   }, */
			/*   async deletePayment(id) {
          try {
            const response = await AuthService.deletePayment(id);
            console.log(response);
            this.getAccountTransactions();
          } catch (error) {
            console.log(error);
          }
        }, */
		},
		mounted() {
			this.getCardById();
			this.getUserId();
		},
	};
</script>
