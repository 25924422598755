import http from "../http-common";

class BusinessFileUploadService {
  upload(file, lang, clientId, onUploadProgress) {
    let formData = new FormData();
    // let details = details;
    formData.append("file", file);

    return http.post(lang + "/business-upload/" + clientId + "/{}", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
    });
  }

  getFiles() {
    return http.get("/files");
  }
}

export default new BusinessFileUploadService();
