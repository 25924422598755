<template>
	<ValidationObserver ref="observer" v-slot="{ handleSubmit, reset }">
		<b-form
			@submit.prevent="handleSubmit(createAccountUser)"
			@reset.prevent="reset"
		>
			<div class="col-lg-4 offset-lg-4">
				<h2 class="title-bold text-left">
					{{ $t("createAccountUser.createAccountUser") }}
				</h2>
				<hr />
				<div class="background-outline">
					<div class="background-modifier text-left">
						<label class="small">
							{{ $t("createAccountUser.chooseUser") }}
						</label>
						<ValidationProvider
							name="userId"
							rules="required"
							v-slot="{ valid, errors }"
						>
							<b-form-group id="userIdInputGroup" label-for="userIdInput">
								<b-form-select
									id="userIdInput"
									:state="errors[0] ? false : valid ? true : null"
									v-model="userId"
									><option v-for="user in users" :value="user.id" :key="user.id"
										>{{ user.id }} {{ user.firstname }}
										{{ user.lastname }}</option
									>
									<template #first>
										<b-form-select-option value="" disabled>{{
											$t("createAccountUser.user")
										}}</b-form-select-option>
									</template></b-form-select
								>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>

						<!-- <p class="">
            {{ $t("createAccountUser.chooseLinkedAccount") }}
          </p>
          <ValidationProvider
            rules="required"
            name="debitAccountId"
            v-slot="{ valid, errors }"
          >
            <b-form-group
              id="debitAccountIdInputGroup"
              label-for="debitAccountIdInput"
            >
              <b-form-select
                @change="onChange"
                id="debitAccountIdInput"
                :state="errors[0] ? false : valid ? true : null"
                v-model="debitAccountId"
                ><option
                  v-for="account in accounts"
                  :value="account.id"
                  :key="account.id"
                  >{{ account.iban }}
                  {{ $n(account.credits - account.debits, "decimal") }}
                  {{ account.currency }}</option
                >
                <template #first>
                  <b-form-select-option value="" disabled>{{
                    $t("createAccountUser.debitAccountId")
                  }}</b-form-select-option>
                </template></b-form-select
              >
              <b-form-invalid-feedback id="inputLiveFeedback">{{
                errors[0]
              }}</b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider> -->
					</div>
					<br />

					<div class="background-modifier text-left">
						<h4 class="title-bold">
							{{ $t("createAccountUser.authorizations") }}
						</h4>
						<hr />

						<b-form-checkbox
							class="text-left"
							id="checkbox-1"
							v-model="viewingRights"
							name="checkbox-1"
							value="1"
							unchecked-value="0"
							switch
						>
							{{ $t("createAccountUser.viewingRights") }}
							<!-- <b>({{ paymentsAbroad }})</b> -->
						</b-form-checkbox>
						<br />
						<b-form-checkbox
							class="text-left"
							id="checkbox-2"
							v-model="paymentPreparation"
							name="checkbox-2"
							value="1"
							unchecked-value="0"
							switch
						>
							{{ $t("createAccountUser.preparationOfPayments") }}
							<!-- <b>({{ onlinePayments }})</b> -->
						</b-form-checkbox>
						<br />
						<b-form-checkbox
							class="text-left"
							id="checkbox-3"
							v-model="paymentAuthorization"
							name="checkbox-3"
							value="1"
							unchecked-value="0"
							switch
						>
							{{ $t("createAccountUser.authorizationOfPayments") }}
							<!-- <b>({{ atmWithdrawals }})</b> -->
						</b-form-checkbox>
						<div v-if="paymentAuthorization == '1'">
							<br />
							<label class="small">{{
								$t("createAccountUser.paymentLimit")
							}}</label>

							<ValidationProvider
								rules="required|numeric|min_value:0.00|max_value:1000000.00"
								name="paymentLimit"
								v-slot="{ valid, errors }"
							>
								<b-form-group label-for="paymentLimitInput" description="">
									<b-form-input
										@input=""
										type="text"
										v-model="paymentLimit"
										:state="errors[0] ? false : valid ? true : null"
										:placeholder="$t('createAccountUser.paymentLimit')"
									></b-form-input>
									<b-form-invalid-feedback id="inputLiveFeedback">{{
										errors[0]
									}}</b-form-invalid-feedback>
								</b-form-group>
							</ValidationProvider>
						</div>
						<br />

						<b-form-checkbox
							class="text-left"
							id="checkbox-4"
							v-model="contractSigning"
							name="checkbox-4"
							value="1"
							unchecked-value="0"
							switch
						>
							{{ $t("createAccountUser.signingOfAgreements") }}
							<!-- <b>({{ locked }})</b> -->
						</b-form-checkbox>
						<br />
					</div>
					<br />
					<!-- <b-form-checkbox
          class="text-left"
          id="checkbox-5"
          v-model="status"
          name="checkbox-5"
          value="accepted"
          unchecked-value="not_accepted"
        >
          {{ $t("signup.acceptTerms") }}
        </b-form-checkbox>
        <br />

        <div v-if="status == 'accepted'">
          <hr /> -->
					<b-button
						type="submit"
						@click="isloading"
						class="btn btn-info text-light btn-block"
						>{{ $t("createAccountUser.submit")
						}}<b-spinner
							v-if="isloading"
							class="ml-auto"
							:variant="light"
						></b-spinner
					></b-button>
					<!-- </div> -->
					<hr />
					<b-button
						type="reset"
						class="btn btn-alert text-light btn-block"
						@click="resetForm()"
						>{{ $t("reset") }}</b-button
					>
					<hr />
					<div v-if="msg && $bvToast.show('notification-toast')"></div>
					<div class="action">
						<b-toast
							id="notification-toast"
							variant="secondary"
							static
							no-auto-hide
						>
							<template #toast-title>
								<div class="d-flex flex-grow-1 align-items-baseline">
									<b-img
										class="logo mb-2"
										:src="require('/public/images/logo.png')"
										alt=""
										width="12"
										height="12"
									></b-img>
									<strong class="mr-auto">{{ $t("notification") }}</strong>
									<small class="text-muted mr-2"></small>
								</div>
							</template>
							{{ msg }}
						</b-toast>
					</div>
				</div>
			</div>
		</b-form>
	</ValidationObserver>
</template>

<script>
	import { ValidationObserver, ValidationProvider } from "vee-validate";
	import AuthService from "@/services/AuthService.js";

	export default {
		name: "CreateCardForm",
		components: {
			ValidationObserver,
			ValidationProvider,
		},
		data() {
			return {
				// currencies: [],
				users: [],
				// accounts: [],
				//currency: "EUR",
				// nickname: this.$t("createAccountUser.myCard"),
				userId: "",
				//debitAccountId: "",
				userType: 2,
				viewingRights: 1,
				paymentPreparation: 1,
				paymentAuthorization: 1,
				paymentLimit: 2000.0,
				contractsigning: 0,
				msg: "",
				isloading: false,
				//status: "not_accepted",
			};
		},
		async created() {
			if (!this.$store.getters.isLoggedIn) {
				this.$router.push("login");
			}
			if (
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 3 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 15 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 14 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 13
			) {
				this.$router.push("overview");
			}
			let lang = this.$i18n.locale;
			this.role = this.$store.getters.getUser.role;
			this.username = this.$store.getters.getUser.username;
			this.firstname = this.$store.getters.getUser.firstname;
		},
		methods: {
			// async getAllAccountCurrencies() {
			//   try {
			//     const response = await AuthService.getAllAccountCurrencies();
			//     this.currencies = response;
			//     console.log(response);
			//   } catch (error) {
			//     console.log(error);
			//   }
			// },
			// async getClientsAccounts() {
			//   try {
			//     const id = this.$store.getters.getUser.id;
			//     const response = await AuthService.getClientsAccounts(id);
			//     this.accounts = response;
			//     console.log(response);
			//   } catch (error) {
			//     console.log(error);
			//   }
			// },
			async getClientsUsers() {
				try {
					const id = this.$store.getters.getUser.id;
					const response = await AuthService.getClientsUsers(id);
					this.users = response;
					console.log(response);
				} catch (error) {
					console.log(error);
				}
			},
			async createAccountUser() {
				try {
					const details = {
						lang: this.$i18n.locale,
						userId: this.userId,
						userType: 2, //this.userType,
						accountId: this.$route.params.id,
						viewingRights: this.viewingRights,
						paymentPreparation: this.paymentPreparation,
						paymentAuthorization: this.paymentAuthorization,
						authorizationType: 1, //this.viewingRights.authorizationType,
						paymentLimit: this.paymentLimit,
						contractSigning: this.contractSigning,

						// nickname: this.nickname,
					};
					this.isloading = true;
					const response = await AuthService.createAccountUser(details);
					this.resetForm();
					this.msg = response.msg;
					this.isloading = false;
				} catch (error) {
					this.msg = error.response.data.msg;
					this.isloading = false;
					/*this.$router.push('accounts');*/
				}
			},
			resetForm() {
				// this.nickname = "";
				this.msg = "";
				requestAnimationFrame(() => {
					this.$refs.observer.reset();
				});
			},
		},
		mounted() {
			// this.getAllAccountCurrencies();
			// this.getClientsAccounts();
			this.getClientsUsers();
		},
	};
</script>
