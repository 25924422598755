var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.editProductDetails)},"reset":function($event){$event.preventDefault();return reset($event)}}},[_c('div',{staticClass:"col-lg-4 offset-lg-4 text-left"},[_c('h2',{staticClass:"title-bold"},[_vm._v(" "+_vm._s(_vm.$t("customerProduct.editProduct"))+" ")]),_c('hr'),_c('div',{staticClass:"background-outline"},[_c('div',{staticClass:"background-modifier text-left"},[_c('label',{staticClass:"small"},[_vm._v(_vm._s(_vm.$t("customerProduct.productName")))]),_c('ValidationProvider',{attrs:{"rules":"min:1|max:50","name":"internalId"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"productNameInput","description":""}},[_c('b-form-input',{attrs:{"type":"text","state":errors[0] ? false : valid ? true : null,"placeholder":_vm.$t('customerProduct.productName')},model:{value:(_vm.product.productName),callback:function ($$v) {_vm.$set(_vm.product, "productName", $$v)},expression:"product.productName"}}),_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('label',{staticClass:"small"},[_vm._v(_vm._s(_vm.$t("customerProduct.productDescription")))]),_c('ValidationProvider',{attrs:{"rules":"min:1|max:250","name":"productDescription"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"productDescriptionInput","description":""}},[_c('b-form-input',{attrs:{"type":"text","state":errors[0] ? false : valid ? true : null,"placeholder":_vm.$t('customerProduct.productDescription')},model:{value:(_vm.product.productDescription),callback:function ($$v) {_vm.$set(_vm.product, "productDescription", $$v)},expression:"product.productDescription"}}),_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('label',{staticClass:"small"},[_vm._v(_vm._s(_vm.$t("customerProduct.productUnitPriceGross")))]),_c('ValidationProvider',{attrs:{"rules":"required|double|min_value:0.01","name":"productUnitPriceGross"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"productUnitPriceGrossInput","description":""}},[_c('b-form-input',{attrs:{"type":"text","state":errors[0] ? false : valid ? true : null,"placeholder":_vm.$t('customerProduct.productUnitPriceGross')},on:{"input":_vm.calculateTotalNet},model:{value:(_vm.product.productUnitPriceGross),callback:function ($$v) {_vm.$set(_vm.product, "productUnitPriceGross", $$v)},expression:"product.productUnitPriceGross"}}),_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('label',{staticClass:"small"},[_vm._v(_vm._s(_vm.$t("customerProduct.discountRate"))+" (%)")]),_c('ValidationProvider',{attrs:{"rules":"required|double|min_value:0.00","name":"discountRate"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"discountRateInput","description":""}},[_c('b-form-input',{attrs:{"type":"text","state":errors[0] ? false : valid ? true : null,"placeholder":_vm.$t('customerProduct.discountRate')},on:{"input":_vm.calculateTotalNet},model:{value:(_vm.product.discountRate),callback:function ($$v) {_vm.$set(_vm.product, "discountRate", $$v)},expression:"product.discountRate"}}),_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('label',{staticClass:"small"},[_vm._v(_vm._s(_vm.$t("customerProduct.productUnitPriceNet")))]),_c('p',[_vm._v(_vm._s(_vm.$n(_vm.totalNetResult, "decimal")))]),_c('br')],1),_c('hr'),_c('b-button',{staticClass:"btn btn-info text-light btn-block",attrs:{"type":"submit"},on:{"click":_vm.isloading}},[_vm._v(_vm._s(_vm.$t("customerProduct.submit"))),(_vm.isloading)?_c('b-spinner',{staticClass:"ml-auto",attrs:{"variant":_vm.light}}):_vm._e()],1),(_vm.msg && _vm.$bvToast.show('notification-toast'))?_c('div'):_vm._e(),_c('div',{staticClass:"action"},[_c('br'),_c('b-toast',{attrs:{"id":"notification-toast","variant":"secondary","static":"","no-auto-hide":""},scopedSlots:_vm._u([{key:"toast-title",fn:function(){return [_c('div',{staticClass:"d-flex flex-grow-1 align-items-baseline"},[_c('b-img',{staticClass:"logo mb-2",attrs:{"src":require('/public/images/logo.png'),"alt":"","width":"12","height":"12"}}),_c('strong',{staticClass:"mr-auto"},[_vm._v(_vm._s(_vm.$t("notification")))]),_c('small',{staticClass:"text-muted mr-2"})],1)]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(_vm.msg)+" ")])],1)],1)])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }