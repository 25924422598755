<template>
	<ValidationObserver ref="observer" v-slot="{ handleSubmit, reset }">
		<b-form
			@submit.prevent="handleSubmit(updateAccount)"
			@reset.prevent="reset"
		>
			<div class="col-lg-4 offset-lg-4">
				<h2 class="title-bold text-left">
					{{ $t("editAccount.editAccount") }}
				</h2>
				<hr />
				<div class="background-outline">
					<div class="background-modifier">
						<div class="text-left">
							<label class="small">{{ $t("editAccount.nickname") }}</label>
							<ValidationProvider
								rules="alpha_spaces|min:3|max:50"
								name="nickname"
								v-slot="{ valid, errors }"
							>
								<b-form-group label-for="nicknameInput" description="">
									<b-form-input
										type="text"
										v-model="account.nickname"
										:state="errors[0] ? false : valid ? true : null"
										:placeholder="$t('editAccount.nickname')"
									></b-form-input>
									<b-form-invalid-feedback id="inputLiveFeedback">{{
										errors[0]
									}}</b-form-invalid-feedback>
								</b-form-group>
							</ValidationProvider>
						</div>
					</div>
					<hr />
					<div class="">
						<b-button
							type="submit"
							@click="isloading"
							class="btn btn-info text-light btn-block"
						>
							{{ $t("editAccount.submit")
							}}<b-spinner
								v-if="isloading"
								class="ml-auto"
								:variant="light"
							></b-spinner
						></b-button>
					</div>
					<!--   <hr/>
     <b-button type="reset" class="btn btn-alert text-light btn-block" @click="resetForm()">{{ $t('reset') }}</b-button> -->

					<div v-if="msg && $bvToast.show('notification-toast')"></div>
					<div class="action">
						<br />
						<b-toast
							id="notification-toast"
							variant="secondary"
							static
							no-auto-hide
						>
							<template #toast-title>
								<div class="d-flex flex-grow-1 align-items-baseline">
									<b-img
										class="logo mb-2"
										:src="require('/public/images/logo.png')"
										alt=""
										width="12"
										height="12"
									></b-img>
									<strong class="mr-auto"> {{ $t("notification") }}</strong>
									<small class="text-muted mr-2"></small>
								</div>
							</template>
							{{ msg }}
						</b-toast>
					</div>
				</div>
			</div>
		</b-form>
	</ValidationObserver>
</template>

<script>
	import { ValidationObserver, ValidationProvider } from "vee-validate";
	import AuthService from "@/services/AuthService.js";

	export default {
		name: "NewAccountForm",
		components: {
			ValidationObserver,
			ValidationProvider,
		},
		data() {
			return {
				account: "",
				msg: "",
				isloading: false,
			};
		},
		async created() {
			if (!this.$store.getters.isLoggedIn) {
				this.$router.push("login");
			}
		},
		methods: {
			async getAccountById(id) {
				try {
					const id = this.$route.params.id;
					this.isloading = true;
					const response = await AuthService.getAccountById(id);
					this.account = response;
					this.isloading = false;
					console.log(response);
				} catch (error) {
					this.isloading = false;
					console.log(error);
				}
			},
			async updateAccount() {
				try {
					const id = this.$route.params.id;
					const details = {
						nickname: this.account.nickname,
						lang: this.$i18n.locale,
					};
					const response = await AuthService.updateAccount(id, details);
					this.msg = response.msg;
					this.getAccountById();
					console.log(response.msg);
				} catch (error) {
					console.log(error);
				}
			},
			resetForm() {
				this.nickname = "";
				this.msg = "";
				requestAnimationFrame(() => {
					this.$refs.observer.reset();
				});
			},
		},
		mounted() {
			this.getAccountById();
		},
	};
</script>
