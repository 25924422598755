<template>
	<div class="col-8 offset-md-2 text-left">
		<h2 class="title-bold">{{ $t("paymentDataUpload.fileUpload") }}</h2>
		<hr />
		<h5 class="title-bold text-left">
			{{ $t("paymentDataUpload.uploadExcelFileForInstructions") }}
		</h5>
		<hr />
		<div class="background-modifier">
			<p class="text-left">
				{{ $t("paymentDataUpload.theFileStructureMustFollowInstructions") }}
			</p>
			<label class="col-4 offset-md-4">
				<input class="" type="file" ref="file" @change="selectFile" />
			</label>
			<hr />
			<div v-if="currentFile" class="progress">
				<div
					class="progress-bar progress-bar-striped bg-info"
					role="progressbar"
					:animated="animate"
					:aria-valuenow="progress"
					aria-valuemin="0"
					aria-valuemax="100"
					:style="{ width: progress + '%' }"
				>
					{{ progress }}%
				</div>
			</div>
			<br />
			<b-button
				class="btn btn-primary text-white btn-block"
				:disabled="!selectedFiles"
				@click="upload"
			>
				{{ $t("paymentDataUpload.upload") }}
			</b-button>

			<div class="alert alert-light" role="alert">{{ msg }}</div>

			<!--   <div class="card">
          <div class="card-header">{{ $t('paymentDataUpload.listOfFiles') }}</div>
          <ul class="list-group list-group-flush">
            <li
              class="list-group-item"
              v-for="(file, index) in fileInfos"
              :key="index"
            >
              <a :href="file.url">{{ file.name }}</a>
            </li>
          </ul>
        </div>  -->

			<hr />
			<div class="">
				<h3 class="title-bold">{{ $t("paymentDataUpload.listOfFiles") }}</h3>

				<div v-if="isloading" class="text-center">
					<b-spinner
						style="width: 3rem; height: 3rem;"
						class="ml-auto text-center"
						:variant="secondary"
					></b-spinner>
				</div>
				<div v-else-if="paymentDocs.length > 0">
					<table class="table mt-2 ">
						<thead>
							<tr>
								<!--  <th class="text-left">{{ $t('filename') }}</th> -->
							</tr>
						</thead>
						<tbody>
							<tr v-for="paymentDoc in paymentDocs" :key="paymentDoc.id">
								<td class="text-left">
									<span>
										<a
											:href="
												'https://www.n3consortium.com/api/files/' +
													paymentDoc.filename
											"
											>{{ paymentDoc.filename }}</a
										></span
									>
								</td>

								<td class="text-center"></td>
							</tr>
						</tbody>
					</table>
				</div>

				<div v-else>{{ $t("paymentDataUpload.noDocuments") }}</div>
			</div>
		</div>
	</div>
</template>

<script>
	import AuthService from "@/services/AuthService.js";
	import UploadService from "../../services/ExcelFileUploadService";

	export default {
		name: "payment-file-upload",
		data() {
			return {
				selectedFiles: undefined,
				currentFile: undefined,
				progress: 0,
				msg: "",
				isloading: false,
				fileInfos: [],
				paymentDocs: [],
			};
		},
		async created() {
			if (!this.$store.getters.isLoggedIn) {
				this.$router.push("login");
			}
			if (
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 3 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 15 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 14 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 13 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 12
			) {
				this.$router.push("overview");
			}
			let lang = this.$i18n.locale;
			this.role = this.$store.getters.getUser.role;
			this.username = this.$store.getters.getUser.username;
			this.firstname = this.$store.getters.getUser.firstname;
			// this.getPaymentDocs();
		},
		methods: {
			selectFile() {
				this.selectedFiles = this.$refs.file.files;
			},
			upload() {
				this.progress = 0;

				const lang = this.$i18n.locale;
				const clientId = this.$store.getters.getUser.id;
				//NOTE: Here clientId = userId!!

				this.currentFile = this.selectedFiles.item(0);
				UploadService.upload(this.currentFile, lang, clientId, (event) => {
					this.progress = Math.round((100 * event.loaded) / event.total);
				})
					.then((response) => {
						this.msg = response.data.msg;
						this.getPaymentDocs();
						return UploadService.getFiles();
					})
					.then((files) => {
						this.fileInfos = files.data;
					})
					.catch(() => {
						this.progress = 0;
						this.msg = this.$t("couldNotUploadTheFile");
						this.currentFile = undefined;
					});

				this.selectedFiles = undefined;
			},
			async getPaymentDocs(id) {
				try {
					id = this.$store.getters.getUser.id;
					//  console.log(id);
					this.isloading = true;
					const response = await AuthService.getPaymentDocs(id);
					this.paymentDocs = response;
					this.isloading = false;
					console.log(response);
				} catch (error) {
					this.isloading = false;
					console.log(error);
				}
			},
		},
		mounted() {
			UploadService.getFiles().then((response) => {
				this.fileInfos = response.data;
			});
			this.getPaymentDocs();
		},
	};
</script>
