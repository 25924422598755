<template>
	<ValidationObserver ref="observer" v-slot="{ handleSubmit, reset }">
		<b-form @submit.prevent="handleSubmit(editUser)" @reset.prevent="reset">
			<div class="col-md-4 offset-md-4 text-left">
				<h2 class="title-bold">{{ $t("editUser.editUser") }} {{ user.id }}</h2>
				<hr />
				<div class="background-outline">
					<div class="background-modifier">
						<label class="text-left small">{{ $t("editUser.username") }}</label>
						<ValidationProvider
							rules="required|alpha_dash|min:3|max:50"
							name="username"
							v-slot="{ valid, errors }"
						>
							<b-form-group label-for="usernameInput" description="">
								<b-form-input
									type="text"
									v-model="user.username"
									:state="errors[0] ? false : valid ? true : null"
									:placeholder="$t('editUser.username')"
								></b-form-input>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>
						<label class="text-left small">{{ $t("editUser.email") }}</label>
						<ValidationProvider
							rules="required|email"
							name="email"
							v-slot="{ valid, errors }"
						>
							<b-form-group label-for="emailInput" description="">
								<b-form-input
									type="email"
									v-model="user.email"
									:state="errors[0] ? false : valid ? true : null"
									:placeholder="$t('editUser.email')"
								></b-form-input>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>
					</div>
					<br />

					<div class="background-modifier">
						<label class="text-left small">{{
							$t("editUser.firstname")
						}}</label>
						<ValidationProvider
							rules="required|alpha_spaces|min:2|max:50"
							name="firstname"
							v-slot="{ valid, errors }"
						>
							<b-form-group label-for="firstnameInput" description="">
								<b-form-input
									type="text"
									v-model="user.firstname"
									:state="errors[0] ? false : valid ? true : null"
									:placeholder="$t('editUser.firstname')"
								></b-form-input>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>

						<label class="text-left small">{{ $t("editUser.lastname") }}</label>
						<ValidationProvider
							rules="required|alpha_spaces|min:2|max:50"
							name="lastname"
							v-slot="{ valid, errors }"
						>
							<b-form-group label-for="lastnameInput" description="">
								<b-form-input
									type="text"
									v-model="user.lastname"
									:state="errors[0] ? false : valid ? true : null"
									:placeholder="$t('editUser.lastname')"
								></b-form-input>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>
					</div>
					<br />
					<div class="background-modifier">
						<label class="text-left small">{{
							$t("editUser.verificationStatus")
						}}</label>
						<b-form-group label="" v-slot="{ vStatus }">
							<b-form-radio
								v-model="user.verificationStatus"
								:aria-describedby="vStatus"
								name="radio_group_1"
								value="1"
								>{{ $t("editUser.pending") }}</b-form-radio
							>
							<b-form-radio
								v-model="user.verificationStatus"
								:aria-describedby="vStatus"
								name="radio_group_1"
								value="3"
								>{{ $t("editUser.verified") }}</b-form-radio
							>
						</b-form-group>
					</div>
					<br />
					<b-button
						type="submit"
						@click="isloading"
						class="btn btn-info text-light btn-block"
						>{{ $t("editUser.submit")
						}}<b-spinner
							v-if="isloading"
							class="ml-auto"
							:variant="light"
						></b-spinner
					></b-button>
					<!--   <hr/>
     <b-button type="reset" class="btn btn-alert text-light btn-block" @click="resetForm()">{{ $t('reset') }}</b-button> -->

					<div v-if="msg && $bvToast.show('notification-toast')"></div>
					<div class="action">
						<br />
						<b-toast
							id="notification-toast"
							variant="secondary"
							static
							no-auto-hide
						>
							<template #toast-title>
								<div class="d-flex flex-grow-1 align-items-baseline">
									<b-img
										class="logo mb-2"
										:src="require('/public/images/logo.png')"
										alt=""
										width="12"
										height="12"
									></b-img>
									<strong class="mr-auto">{{ $t("notification") }}</strong>
									<small class="text-muted mr-2"></small>
								</div>
							</template>
							{{ msg }}
						</b-toast>
					</div>
				</div>
			</div>
		</b-form>
	</ValidationObserver>
</template>

<script>
	import { ValidationObserver, ValidationProvider } from "vee-validate";
	import AuthService from "@/services/AuthService.js";

	export default {
		name: "EditUserForm",
		components: {
			ValidationObserver,
			ValidationProvider,
		},
		data() {
			return {
				user: "",
				msg: "",
				isloading: false,
			};
		},
		async created() {
			if (!this.$store.getters.isLoggedIn) {
				this.$router.push("login");
			}
			if (
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role !== 3
			) {
				this.$router.push("overview");
			}

			this.role = this.$store.getters.getUser.role;
		},
		methods: {
			async getUser(id) {
				try {
					const id = this.$route.params.id;
					const response = await AuthService.getUser(id);
					this.user = response;
					console.log(response);
				} catch (error) {
					console.log(error);
				}
			},
			async editUser() {
				try {
					const id = this.$route.params.id;
					const details = {
						lang: this.$i18n.locale,
						username: this.user.username,
						firstname: this.user.firstname,
						lastname: this.user.lastname,
						email: this.user.email,
						verificationStatus: this.user.verificationStatus,
					};
					this.isloading = true;
					const response = await AuthService.editUser(id, details);
					this.msg = response.msg;
					this.isloading = false;
					this.getUser();
					console.log(response.msg);
				} catch (error) {
					console.log(error);
					this.msg = error.response.data.msg;
					this.isloading = false;
				}
			},
			resetForm() {
				this.user.username = "";
				this.user.firstname = "";
				this.user.lastname = "";
				this.user.email = "";
				this.msg = "";
				requestAnimationFrame(() => {
					this.$refs.observer.reset();
				});
			},
		},
		mounted() {
			this.getUser();
		},
	};
</script>
