<template>
	<div class="col-12 offset-0">
		<div class="text-left">
			<div class="col-md-8 offset-md-2">
				<h1 class="title-bold">
					{{ $t("accountManagement.accountantView") }}
				</h1>
				<hr />
				<h5 class="title-bold">
					{{ $t("accountManagement.thisViewContains") }}
				</h5>
				<hr />
				<h3 class="title-bold">
					{{ $t("accountManagement.accounts") }} ({{ listedAccounts.length }})
				</h3>
				<hr />
				<!-- <p class="open-account text-right">
          <router-link
            class="btn btn-outline"
            :to="{ name: 'create-account' }"
            >{{ $t("accountManagement.openAccount") }}</router-link
          >
        </p> -->
				<!-- <br /> -->

				<div class="background-modifier">
					<div class="input-group mb-3">
						<input
							type="text"
							class="form-control"
							:placeholder="$t('accountManagement.nickname')"
							v-model="searchTitle"
						/>
						<div class="input-group-append">
							<button
								class="btn btn-outline"
								type="button"
								@click="
									page = 1;
									getClientsAccounts();
								"
							>
								{{ $t("search") }}
							</button>
						</div>
					</div>

					<span>
						<h3 class="title-bold">
							{{
								totalBalance() != null
									? $n(totalBalance(), "decimal")
									: $n(0.0, "decimal")
							}}
						</h3>
						<p class="small">
							{{ $t("accountManagement.totalBalance") }} ({{
								this.baseCurrency
							}})
						</p>
					</span>

					<div v-if="isloading" class="text-center">
						<b-spinner
							style="width: 3rem; height: 3rem;"
							class="ml-auto text-center"
							:variant="secondary"
						></b-spinner>
					</div>
					<div v-else-if="accounts.length > 0">
						<div class="table-wrap">
							<table class="table mt-2" id="accounts">
								<thead>
									<tr>
										<th class="text-left small">IBAN</th>
										<!--  <th class="text-left">{{ $t("accountManagement.accountHolder") }}</th> -->
										<th class="text-left small">
											{{ $t("accountManagement.nickname") }}
										</th>
										<!--   <th class="text-right">{{ $t("accountManagement.opened") }}</th> -->
										<th class="text-right small">
											{{ $t("accountManagement.currency") }}
										</th>
										<th class="text-right small">
											{{ $t("accountManagement.balance") }}
										</th>
										<th class="text-right small">
											{{ $t("accountManagement.balance") }} ({{
												this.baseCurrency
											}})
										</th>
										<th class="text-centered small">
											{{ $t("accountManagement.actions") }}
										</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="account in accounts" :key="account.id">
										<!--   <div v-if="account.accountType === 1"> -->
										<td class="text-left">
											{{
												account.iban.toString().replace(/\w{4}(?=.)/g, "$& ")
											}}
										</td>
										<!--  <td class="text-left">
                    {{ account.firstname }} {{ account.lastname }}
                  </td> -->
										<td class="text-left">{{ account.nickname }}</td>
										<!--    <td class="text-right">
                    {{ $d(Date.parse(account.created), "short") }}
                  </td> -->
										<td class="text-left">{{ account.currency }}</td>
										<td class="text-right">
											{{
												account.balance != null
													? $n(account.balance, "decimal")
													: $n(0.0, "decimal")
											}}
										</td>
										<td class="text-right small">
											{{
												account.baseBalance != null
													? $n(account.baseBalance, "decimal")
													: $n(0.0, "decimal")
											}}
										</td>
										<td class="text-right">
											<router-link
												:to="{
													name: 'account-details',
													params: { id: account.id },
												}"
												class="btn btn-outline mx-1 "
												>{{ $t("accountManagement.view") }}</router-link
											>
											<!--  <a
                  class="btn btn-danger mx-1 "
                  @click="deleteAccount(account.id)"
                  >{{ $t('accountManagement.delete') }}</a
                > -->
										</td>
										<!--   </div>
                  <div v-else></div> -->
									</tr>
								</tbody>
							</table>
						</div>
						<div v-if="listedAccounts.length >= 10">
							<div class="row">
								<div class="col-md-6">
									{{ $t("itemsPerPage") }}
									<select
										class="custom-select"
										style="width: 60px;"
										v-model="pageSize"
										@change="handlePageSizeChange($event)"
									>
										<option v-for="size in pageSizes" :key="size" :value="size">
											{{ size }}
										</option>
									</select>
								</div>
								<div class="col-md-6">
									<b-pagination
										v-model="page"
										:total-rows="count"
										:per-page="pageSize"
										:prev-text="$t('previous')"
										:next-text="$t('next')"
										align="right"
										@change="handlePageChange"
									></b-pagination>
								</div>
							</div>
						</div>
					</div>
					<div v-else>{{ $t("accountManagement.noAccounts") }}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import AuthService from "@/services/AuthService.js";

	export default {
		name: "accounts",
		data() {
			return {
				accounts: [],
				listedAccounts: [],
				currentAccount: null,
				currentIndex: -1,
				searchTitle: "",
				isloading: false,
				page: 1,
				count: 0,
				pageSize: 10,

				pageSizes: [10, 25, 50],
			};
		},
		async created() {
			if (!this.$store.getters.isLoggedIn) {
				this.$router.push("login");
			}
			if (
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 3 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 15 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 14 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 13 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 12 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 11
			) {
				this.$router.push("overview");
			}
			let lang = this.$i18n.locale;
			this.role = this.$store.getters.getUser.role;
			this.username = this.$store.getters.getUser.username;
			this.firstname = this.$store.getters.getUser.firstname;
			this.baseCurrency = this.$store.getters.getUser.currency;
			// this.getClientsAccounts();
		},
		methods: {
			getRequestParams(searchTitle, page, pageSize) {
				let params = {};

				if (searchTitle) {
					params["nickname"] = searchTitle;
				}

				if (page) {
					params["page"] = page - 1;
				}

				if (pageSize) {
					params["size"] = pageSize;
				}

				return params;
			},

			async getClientsAccountList() {
				const params = this.getRequestParams(
					this.searchTitle,
					this.page,
					this.pageSize
				);

				try {
					const details = {
						id: this.$store.getters.getUser.id,
					};
					this.isloading = true;
					const response = await AuthService.getClientsAccountList(
						params.page,
						params.size,
						params.nickname,
						details.id
					);
					const { items, totalItems } = response.data;
					this.accounts = items;
					this.count = totalItems;
					this.isloading = false;
					console.log(response.data);
				} catch (error) {
					this.isloading = false;
					console.log(error);
				}
			},

			async getClientsAccounts() {
				try {
					const id = this.$store.getters.getUser.id;
					const response = await AuthService.getClientsAccounts(id);
					this.listedAccounts = response;
					console.log(response);
				} catch (error) {
					console.log(error);
				}
			},
			totalBalance() {
				return this.listedAccounts.reduce(
					(acc, item) => acc + item.baseBalance,
					0
				);
			},
			handlePageChange(value) {
				this.page = value;
				this.getClientsAccountList();
			},

			handlePageSizeChange(event) {
				this.pageSize = event.target.value;
				this.page = 1;
				this.getClientsAccountList();
			},

			/* async deleteAccount(id) {
        try {
          const response = await AuthService.deleteAccount(id);
          console.log(response);
          this.getClientsAccountList();
        } catch (error) {
          console.log(error);
        }
      },*/
		},
		mounted() {
			this.getClientsAccounts();
			this.getClientsAccountList();
			this.totalBalance();
		},
	};
</script>
