<template>
	<ValidationObserver ref="observer" v-slot="{ handleSubmit, reset }">
		<b-form
			@submit.prevent="handleSubmit(newBeneficiary)"
			@reset.prevent="reset"
		>
			<div class="col-lg-4 offset-lg-4 text-left">
				<h2 class="title-bold">{{ $t("newBeneficiary.createBeneficiary") }}</h2>
				<hr />
				<div class="background-outline">
					<div class="background-modifier">
						<label class="text-left small">{{
							$t("newBeneficiary.beneficiaryAccount")
						}}</label>
						<ValidationProvider
							rules="integer|min:1|max:4"
							name="beneficiaryAccount"
							v-slot="{ valid, errors }"
						>
							<b-form-group label-for="beneficiaryAccountInput" description="">
								<b-form-input
									type="text"
									v-model="beneficiaryAccount"
									:state="errors[0] ? false : valid ? true : null"
									:placeholder="$t('newBeneficiary.beneficiaryAccount')"
								></b-form-input>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>
					</div>
					<br />
					<b-form-group label="" v-slot="{ ibanSlot }">
						<b-form-radio
							v-model="isIBAN"
							:aria-describedby="ibanSlot"
							name="radio_group_2"
							value="1"
							>{{ $t("newPayment.beneficiaryAccountIsIBAN") }}</b-form-radio
						>
						<b-form-radio
							v-model="isIBAN"
							:aria-describedby="ibanSlot"
							name="radio_group_2"
							value="0"
							>{{ $t("newPayment.beneficiaryAccountIsBBAN") }}</b-form-radio
						>
					</b-form-group>

					<div v-if="isIBAN == 1">
						<div class="background-modifier">
							<!-- <ValidationProvider
                :rules="{ iban: true }"
                :vid="name"
                v-bind="$attrs"
                v-slot="{ errors }"
                tag="div"
                class="w-full"
              >
                <b-form-input
                  type="text"
                  v-model="IBANfield"
                  :state="errors[0] ? false : valid ? true : null"
                  :placeholder="$t('newPayment.creditAccountId')"
                ></b-form-input>
                <b-form-invalid-feedback id="inputLiveFeedback">{{
                  errors[0]
                }}</b-form-invalid-feedback>
              </ValidationProvider> -->

							<div v-if="isIBAN == 1">
								<!-- creditAccountRequired ? 'required' : '' | -->
								<label class="text-left small">{{
									$t("newPayment.iban")
								}}</label>
								<ValidationProvider
									:rules="{ iban: true }"
									:vid="name"
									v-bind="$attrs"
									v-slot="{ errors }"
									tag="div"
									class="w-full"
								>
									<b-form-group label-for="creditAccountIdInput" description="">
										<b-form-input
											type="text"
											v-model="beneficiaryIBAN"
											:state="errors[0] ? false : valid ? true : null"
											:placeholder="$t('newPayment.iban')"
										></b-form-input>
										<b-form-invalid-feedback id="inputLiveFeedback">{{
											errors[0]
										}}</b-form-invalid-feedback>
									</b-form-group>
								</ValidationProvider>
							</div>

							<!-- creditAccountRequired ? 'required' : '' | -->
							<label class="text-left small">{{
								$t("newPayment.beneficiaryName")
							}}</label>
							<ValidationProvider
								rules="alpha_spaces|min:2|max:50"
								name="creditAccountHolder"
								v-slot="{ valid, errors }"
							>
								<b-form-group label-for="creditAccountHolder" description="">
									<b-form-input
										type="text"
										v-model="beneficiaryName"
										:state="errors[0] ? false : valid ? true : null"
										:placeholder="$t('newPayment.beneficiaryName')"
									></b-form-input>
									<b-form-invalid-feedback id="inputLiveFeedback">{{
										errors[0]
									}}</b-form-invalid-feedback>
								</b-form-group>
							</ValidationProvider>
						</div>
					</div>

					<div v-if="isIBAN == 0">
						<div class="background-modifier">
							<label class="text-left small">{{
								$t("newPayment.creditAccountId")
							}}</label>
							<ValidationProvider
								rules="alpha_dash|max:35"
								name="creditAccountId"
								v-slot="{ valid, errors }"
							>
								<b-form-group label-for="creditAccountIdInput" description="">
									<b-form-input
										type="text"
										v-model="beneficiaryBBAN"
										:state="errors[0] ? false : valid ? true : null"
										:placeholder="$t('newPayment.creditAccountId')"
									></b-form-input>
									<b-form-invalid-feedback id="inputLiveFeedback">{{
										errors[0]
									}}</b-form-invalid-feedback>
								</b-form-group>
							</ValidationProvider>
							<b-form-group label="" v-slot="{ bicSlot }">
								<b-form-radio
									v-model="isBIC"
									:aria-describedby="bicSlot"
									name="radio_group_3"
									value="1"
									>{{ $t("newPayment.beneficiaryBankUsesBIC") }}</b-form-radio
								>
								<b-form-radio
									v-model="isBIC"
									:aria-describedby="bicSlot"
									name="radio_group_3"
									value="0"
									>{{
										$t("newPayment.beneficiaryBankDoesNotUseBIC")
									}}</b-form-radio
								>
							</b-form-group>

							<div v-if="isBIC == 1">
								<label class="text-left small">{{
									$t("newPayment.bic")
								}}</label>
								<ValidationProvider
									:rules="{ bic: true }"
									:vid="name1"
									v-bind="$attrs"
									v-slot="{ errors }"
									tag="div"
									class="w-full"
								>
									<b-form-input
										type="text"
										v-model="BICfield"
										:state="errors[0] ? false : valid ? true : null"
										:placeholder="$t('newPayment.bic')"
									></b-form-input>
									<b-form-invalid-feedback id="inputLiveFeedback">{{
										errors[0]
									}}</b-form-invalid-feedback>
								</ValidationProvider>
								<br />
							</div>

							<div v-if="isBIC == 0">
								<label class="text-left small">{{
									$t("newPayment.beneficiaryBank")
								}}</label>
								<ValidationProvider
									rules="alpha_spaces|min:2|max:50"
									name="beneficiaryBank"
									v-slot="{ valid, errors }"
								>
									<b-form-group label-for="beneficiaryBank" description="">
										<b-form-input
											type="text"
											v-model="beneficiaryBank"
											:state="errors[0] ? false : valid ? true : null"
											:placeholder="$t('newPayment.beneficiaryBank')"
										></b-form-input>
										<b-form-invalid-feedback id="inputLiveFeedback">{{
											errors[0]
										}}</b-form-invalid-feedback>
									</b-form-group>
								</ValidationProvider>

								<label class="text-left small">{{
									$t("newPayment.beneficiaryBankAddressLine1")
								}}</label>
								<ValidationProvider
									rules="required|min:5|max:50"
									name="beneficiaryBankAddressLine1"
									v-slot="{ valid, errors }"
								>
									<b-form-group
										label-for="beneficiaryBankAddressLine1Input"
										description=""
									>
										<b-form-input
											type="text"
											v-model="beneficiaryBankAddressLine1"
											:state="errors[0] ? false : valid ? true : null"
											:placeholder="
												$t('newPayment.beneficiaryBankAddressLine1')
											"
										></b-form-input>
										<b-form-invalid-feedback id="inputLiveFeedback">{{
											errors[0]
										}}</b-form-invalid-feedback>
									</b-form-group>
								</ValidationProvider>
								<label class="text-left small">{{
									$t("newPayment.beneficiaryBankAddressLine2")
								}}</label>
								<ValidationProvider
									rules="min:5|max:50"
									name="beneficiaryBankAddressLine2"
									v-slot="{ valid, errors }"
								>
									<b-form-group
										label-for="beneficiaryBankAddressLine2Input"
										description=""
									>
										<b-form-input
											type="text"
											v-model="beneficiaryBankAddressLine2"
											:state="errors[0] ? false : valid ? true : null"
											:placeholder="
												$t('newPayment.beneficiaryBankAddressLine2')
											"
										></b-form-input>
										<b-form-invalid-feedback id="inputLiveFeedback">{{
											errors[0]
										}}</b-form-invalid-feedback>
									</b-form-group>
								</ValidationProvider>
								<label class="text-left small">{{
									$t("newPayment.beneficiaryBankCity")
								}}</label>
								<ValidationProvider
									rules="required|min:5|max:50"
									name="city"
									v-slot="{ valid, errors }"
								>
									<b-form-group
										label-for="beneficiaryBankCityInput"
										description=""
									>
										<b-form-input
											type="text"
											v-model="beneficiaryBankCity"
											:state="errors[0] ? false : valid ? true : null"
											:placeholder="$t('newPayment.beneficiaryBankCity')"
										></b-form-input>
										<b-form-invalid-feedback id="inputLiveFeedback">{{
											errors[0]
										}}</b-form-invalid-feedback>
									</b-form-group>
								</ValidationProvider>
								<label class="text-left small">{{
									$t("newPayment.beneficiaryBankZip")
								}}</label>
								<ValidationProvider
									rules="required|min:3|max:10"
									name="beneficiaryBankZip"
									v-slot="{ valid, errors }"
								>
									<b-form-group
										label-for="beneficiaryBankZipInput"
										description=""
									>
										<b-form-input
											type="text"
											v-model="beneficiaryBankZip"
											:state="errors[0] ? false : valid ? true : null"
											:placeholder="$t('newPayment.beneficiaryBankZip')"
										></b-form-input>
										<b-form-invalid-feedback id="inputLiveFeedback">{{
											errors[0]
										}}</b-form-invalid-feedback>
									</b-form-group>
								</ValidationProvider>
								<label class="text-left small">{{
									$t("newPayment.beneficiaryBankCountry")
								}}</label>
								<ValidationProvider
									rules="required"
									name="beneficiaryCountry"
									v-slot="{ valid, errors }"
								>
									<b-form-group
										id="beneficiaryBankCountryInputGroup"
										label-for="beneficiaryBankCountryInput"
									>
										<b-form-select
											@change="onChange"
											id="countryInput"
											:state="errors[0] ? false : valid ? true : null"
											v-model="beneficiaryBankCountry"
											><option
												v-for="country in countries"
												:value="country.countryCode"
												:key="country.countryCode"
												>{{
													countryNames.getName(country.countryCode, codeLang)
												}}
											</option>
											<template #first>
												<b-form-select-option value="" disabled>{{
													$t("newPayment.beneficiaryBankCountry")
												}}</b-form-select-option>
											</template></b-form-select
										>
										<b-form-invalid-feedback id="inputLiveFeedback">{{
											errors[0]
										}}</b-form-invalid-feedback>
									</b-form-group>
								</ValidationProvider>
								<br />
							</div>
							<label class="text-left small">{{
								$t("newPayment.beneficiaryName")
							}}</label>
							<ValidationProvider
								rules="alpha_spaces|min:2|max:50"
								name="creditAccountHolder"
								v-slot="{ valid, errors }"
							>
								<b-form-group label-for="creditAccountHolder" description="">
									<b-form-input
										type="text"
										v-model="creditAccountHolder"
										:state="errors[0] ? false : valid ? true : null"
										:placeholder="$t('newPayment.beneficiaryName')"
									></b-form-input>
									<b-form-invalid-feedback id="inputLiveFeedback">{{
										errors[0]
									}}</b-form-invalid-feedback>
								</b-form-group>
							</ValidationProvider>

							<label class="text-left small">{{
								$t("newPayment.beneficiaryAddressLine1")
							}}</label>
							<ValidationProvider
								rules="required|min:5|max:50"
								name="addressLine1"
								v-slot="{ valid, errors }"
							>
								<b-form-group label-for="addressLine1Input" description="">
									<b-form-input
										type="text"
										v-model="beneficiaryAddressLine1"
										:state="errors[0] ? false : valid ? true : null"
										:placeholder="$t('newPayment.beneficiaryAddressLine1')"
									></b-form-input>
									<b-form-invalid-feedback id="inputLiveFeedback">{{
										errors[0]
									}}</b-form-invalid-feedback>
								</b-form-group>
							</ValidationProvider>
							<label class="text-left small">{{
								$t("newPayment.beneficiaryAddressLine2")
							}}</label>
							<ValidationProvider
								rules="min:5|max:50"
								name="addressLine2"
								v-slot="{ valid, errors }"
							>
								<b-form-group label-for="addressLine2Input" description="">
									<b-form-input
										type="text"
										v-model="beneficiaryAddressLine2"
										:state="errors[0] ? false : valid ? true : null"
										:placeholder="$t('newPayment.beneficiaryAddressLine2')"
									></b-form-input>
									<b-form-invalid-feedback id="inputLiveFeedback">{{
										errors[0]
									}}</b-form-invalid-feedback>
								</b-form-group>
							</ValidationProvider>

							<label class="text-left small">{{
								$t("newPayment.beneficiaryCity")
							}}</label>
							<ValidationProvider
								rules="required|min:5|max:50"
								name="city"
								v-slot="{ valid, errors }"
							>
								<b-form-group label-for="cityInput" description="">
									<b-form-input
										type="text"
										v-model="beneficiaryCity"
										:state="errors[0] ? false : valid ? true : null"
										:placeholder="$t('newPayment.beneficiaryCity')"
									></b-form-input>
									<b-form-invalid-feedback id="inputLiveFeedback">{{
										errors[0]
									}}</b-form-invalid-feedback>
								</b-form-group>
							</ValidationProvider>
							<label class="text-left small">{{
								$t("newPayment.beneficiaryZip")
							}}</label>
							<ValidationProvider
								rules="required|min:3|max:10"
								name="zip"
								v-slot="{ valid, errors }"
							>
								<b-form-group label-for="zipInput" description="">
									<b-form-input
										type="text"
										v-model="beneficiaryZip"
										:state="errors[0] ? false : valid ? true : null"
										:placeholder="$t('newPayment.beneficiaryZip')"
									></b-form-input>
									<b-form-invalid-feedback id="inputLiveFeedback">{{
										errors[0]
									}}</b-form-invalid-feedback>
								</b-form-group>
							</ValidationProvider>
							<label class="text-left small">{{
								$t("newPayment.beneficiaryCountry")
							}}</label>
							<ValidationProvider
								rules="required"
								name="beneficiaryCountry"
								v-slot="{ valid, errors }"
							>
								<b-form-group id="countryInputGroup" label-for="countryInput">
									<b-form-select
										@change="onChange"
										id="countryInput"
										:state="errors[0] ? false : valid ? true : null"
										v-model="beneficiaryCountry"
										><option
											v-for="country in countries"
											:value="country.countryCode"
											:key="country.countryCode"
											>{{ countryNames.getName(country.countryCode, codeLang) }}
										</option>
										<template #first>
											<b-form-select-option value="" disabled>{{
												$t("newPayment.beneficiaryCountry")
											}}</b-form-select-option>
										</template></b-form-select
									>
									<b-form-invalid-feedback id="inputLiveFeedback">{{
										errors[0]
									}}</b-form-invalid-feedback>
								</b-form-group>
							</ValidationProvider>
						</div>
					</div>
					<br />
					<div class="background-modifier">
						<label class="text-left small">{{
							$t("newBeneficiary.alias")
						}}</label>
						<ValidationProvider
							rules="alpha_spaces|min:3|max:50"
							name="alias"
							v-slot="{ valid, errors }"
						>
							<b-form-group label-for="aliasInput" description="">
								<b-form-input
									type="text"
									v-model="alias"
									:state="errors[0] ? false : valid ? true : null"
									:placeholder="$t('newBeneficiary.alias')"
								></b-form-input>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>
					</div>
					<br />

					<b-button
						type="submit"
						@click="isloading"
						class="btn btn-info text-light btn-block"
						>{{ $t("newBeneficiary.submit")
						}}<b-spinner
							v-if="isloading"
							class="ml-auto"
							:variant="light"
						></b-spinner
					></b-button>
					<hr />
					<b-button
						type="reset"
						class="btn btn-alert text-light btn-block"
						@click="resetForm()"
						>{{ $t("reset") }}</b-button
					>

					<div v-if="msg && $bvToast.show('notification-toast')"></div>
					<div class="action">
						<br />
						<b-toast
							id="notification-toast"
							variant="secondary"
							static
							no-auto-hide
						>
							<template #toast-title>
								<div class="d-flex flex-grow-1 align-items-baseline">
									<b-img
										class="logo mb-2"
										:src="require('/public/images/logo.png')"
										alt=""
										width="12"
										height="12"
									></b-img>
									<strong class="mr-auto">{{ $t("notification") }}</strong>
									<small class="text-muted mr-2"></small>
								</div>
							</template>
							{{ msg }}
						</b-toast>
					</div>
				</div>
			</div>
		</b-form>
	</ValidationObserver>
</template>

<script>
	import { ValidationObserver, ValidationProvider } from "vee-validate";
	import AuthService from "@/services/AuthService.js";
	var countryNames = require("i18n-iso-countries");
	export default {
		name: "NewBeneficiaryForm",
		components: {
			ValidationObserver,
			ValidationProvider,
		},
		data() {
			return {
				users: [],
				currencies: [],
				currency: "EUR",
				nickname: "",
				firstname: "",
				lastname: "",
				email: "",
				userId: "",
				msg: "",
				beneficiaryAccount: "",
				bic: "",
				beneficiaryAddressLine1: "",
				beneficiaryAddressLine2: "",
				beneficiaryCity: "",
				beneficiaryZip: "",
				beneficiaryCountry: "",
				beneficiaryBank: "",
				beneficiaryBankAddressLine1: "",
				beneficiaryBankAddressLine2: "",
				beneficiaryBankCity: "",
				beneficiaryBankZip: "",
				beneficiaryBankCountry: "",
				beneficiaryIBAN: "",
				beneficiaryBBAN: "",
				sortCode: "",
				alias: "",
				beneficiaryName: "",
				currencies: ["EUR"],
				countries: [],
				countryNames: countryNames,
				isIBAN: 1,
				isBIC: 1,
				isloading: false,
			};
		},
		creditAccountRequired() {
			if (this.creditAccountId2 === "") {
				return true;
			} else {
				return false;
			}
		},
		creditAccount2Required() {
			if (creditAccountId === "") {
				return true;
			} else {
				return false;
			}
		},
		async created() {
			if (!this.$store.getters.isLoggedIn) {
				this.$router.push("login");
			}

			this.role = this.$store.getters.getUser.role;
			let lang = this.$i18n.locale;
			this.codeLang = this.$i18n.locale;
			this.countryNames.registerLocale(
				require(`i18n-iso-countries/langs/${this.codeLang}.json`)
			);
		},
		watch: {
			codeLang(newVal, oldVal) {
				this.countryNames.registerLocale(
					require(`i18n-iso-countries/langs/${newVal}.json`)
				);
			},
		},
		methods: {
			async newBeneficiary() {
				try {
					const details = {
						lang: this.$i18n.locale,
						userId: this.$store.getters.getUser.id,
						beneficiaryAccount: this.beneficiaryAccount,
						beneficiaryName: this.beneficiaryName,
						alias: this.alias ? this.alias : this.beneficiaryName,
						bic: this.BICfield,
						beneficiaryAddressLine1: this.beneficiaryAddressLine1,
						beneficiaryAddressLine2: this.beneficiaryAddressLine2,
						beneficiaryCity: this.beneficiaryCity,
						beneficiaryZip: this.beneficiaryZip,
						beneficiaryCountry: this.beneficiaryCountry,
						beneficiaryBank: this.beneficiaryBank,
						beneficiaryBankAddressLine1: this.beneficiaryBankAddressLine1,
						beneficiaryBankAddressLine2: this.beneficiaryBankAddressLine2,
						beneficiaryBankCity: this.beneficiaryBankCity,
						beneficiaryBankZip: this.beneficiaryBankZip,
						beneficiaryBankCountry: this.beneficiaryBankCountry,
						isIBAN: this.isIBAN,
						currency: this.currency,
						beneficiaryIBAN: this.beneficiaryIBAN,
						beneficiaryBBAN: this.beneficiaryBBAN,
						sortCode: this.sortCode,
						email: this.email,
						firstname: this.firstname,
						lastname: this.lastname,
					};
					this.isloading = true;
					const response = await AuthService.newBeneficiary(details);
					this.accounts = response;
					this.msg = response.msg;
					this.isloading = false;
					console.log(response);
				} catch (error) {
					this.isloading = false;
					console.log(error);
				}
			},
			async getCountries() {
				try {
					const response = await AuthService.getCountries();
					this.countries = response;
					console.log(response);
				} catch (error) {
					console.log(error);
				}
			},
			resetForm() {
				this.beneficiaryName = "";
				this.beneficiaryAccount = "";
				this.alias = "";
				this.msg = "";
				requestAnimationFrame(() => {
					this.$refs.observer.reset();
				});
			},
		},
		mounted() {
			this.getCountries();
		},
	};
</script>
