<template>
	<div class="col-12 offset-0">
		<div class="text-left">
			<div class="col-md-8 offset-md-2">
				<h1 class="title-bold">{{ $t("userList.users") }} ({{ count }})</h1>
				<hr />

				<!--   <form>
          <div class="input-group mb-3">
            <input type="text" class="form-control" :placeholder="$t('account.nickname')" v-model="account.nickname" />
            </div>
          <button type="submit" class="btn btn-info"
        @click="updateAccount"
      >{{ $t('newPayment.submit') }}
        </button>
        <p v-if="msg">{{ msg }}</p>
        </form> -->

				<p class="open-account text-right">
					<router-link class="btn btn-outline" :to="{ name: 'new-user' }">{{
						$t("userList.newUser")
					}}</router-link>
				</p>
				<br />

				<div v-if="msg && $bvToast.show('notification-toast')"></div>
				<div class="action">
					<b-toast
						id="notification-toast"
						variant="secondary"
						static
						no-auto-hide
					>
						<template #toast-title>
							<div class="d-flex flex-grow-1 align-items-baseline">
								<b-img
									class="logo mb-2"
									:src="require('/public/images/logo.png')"
									alt=""
									width="12"
									height="12"
								></b-img>
								<strong class="mr-auto">{{ $t("notification") }}</strong>
								<small class="text-muted mr-2"></small>
							</div>
						</template>
						{{ msg }}
					</b-toast>
				</div>

				<div class="background-modifier">
					<div class="input-group mb-3">
						<input
							type="text"
							class="form-control"
							:placeholder="$t('search')"
							v-model="searchTitle"
						/>
						<div class="input-group-append">
							<button
								class="btn btn-outline"
								type="button"
								@click="
									page = 1;
									getAllUsers();
								"
							>
								{{ $t("search") }}
							</button>
						</div>
					</div>

					<div v-if="isloading" class="text-center">
						<b-spinner
							style="width: 3rem; height: 3rem;"
							class="ml-auto text-center"
							:variant="secondary"
						></b-spinner>
					</div>
					<div v-else-if="users.length > 0">
						<div class="table-wrap">
							<table class="table mt-2 ">
								<thead>
									<tr>
										<th class="text-left small">{{ $t("userList.id") }}</th>
										<th class="text-left small">
											{{ $t("userList.username") }}
										</th>
										<th class="text-left small">
											{{ $t("userList.firstname") }}
										</th>
										<th class="text-left small">
											{{ $t("userList.lastname") }}
										</th>
										<th class="text-left small">{{ $t("userList.email") }}</th>
										<th class="text-right small">{{ $t("userList.role") }}</th>
										<th class="text-right small">
											{{ $t("userList.active") }}
										</th>
										<th class="text-right small">
											{{ $t("userList.registered") }}
										</th>
										<th class="text-right small">
											{{ $t("userList.lastLogin") }}
										</th>
										<th class="text-centered small">
											{{ $t("userList.actions") }}
										</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="user in users" :key="user.id">
										<td class="text-left">{{ user.id }}</td>
										<td class="text-left">{{ user.username }}</td>
										<td class="text-left">{{ user.firstname }}</td>
										<td class="text-left">{{ user.lastname }}</td>
										<td class="text-left">{{ user.email }}</td>
										<td class="text-right">
											{{ user.role == 3 ? $t("role.admin") : $t("role.user") }}
										</td>
										<td class="text-right">
											{{ user.active == 1 ? $t("yes") : $t("no") }}
										</td>
										<td class="text-right">
											{{ $d(Date.parse(user.registered), "long") }}
										</td>
										<td class="text-right">
											{{
												user.lastLogin != null
													? $d(Date.parse(user.lastLogin), "long")
													: $t("no")
											}}
										</td>
										<td class="text-right">
											<router-link
												:to="{ name: 'user-detail', params: { id: user.id } }"
												class="btn btn-outline mx-1 "
												>{{ $t("userList.view") }}</router-link
											>
											<a
												class="btn btn-danger mx-1 "
												@click="deleteUser(user.id)"
												>{{ $t("userList.delete") }}</a
											>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div v-if="users.length >= 1">
							<div class="row">
								<div class="col-md-6">
									{{ $t("itemsPerPage") }}
									<select
										class="custom-select"
										style="width: 60px;"
										v-model="pageSize"
										@change="handlePageSizeChange($event)"
									>
										<option v-for="size in pageSizes" :key="size" :value="size">
											{{ size }}
										</option>
									</select>
								</div>
								<div class="col-md-6">
									<b-pagination
										v-model="page"
										:total-rows="count"
										:per-page="pageSize"
										:prev-text="$t('previous')"
										:next-text="$t('next')"
										align="right"
										@change="handlePageChange"
									></b-pagination>
								</div>
							</div>
						</div>
					</div>
					<div v-else>{{ $t("userList.noUsers") }}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import AuthService from "@/services/AuthService.js";

	export default {
		name: "UserList",
		data() {
			return {
				users: [],
				msg: "",
				currentUser: null,
				currentIndex: -1,
				searchTitle: "",
				isloading: false,
				page: 1,
				count: 0,
				pageSize: 10,

				pageSizes: [10, 25, 50],
			};
		},
		async created() {
			if (!this.$store.getters.isLoggedIn) {
				this.$router.push("login");
			}
			if (
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role !== 3
			) {
				this.$router.push("overview");
			}

			this.role = this.$store.getters.getUser.role;
			this.getAllUsers();
		},
		methods: {
			getRequestParams(searchTitle, page, pageSize) {
				let params = {};

				if (searchTitle) {
					params["lastname"] = searchTitle;
				}

				if (page) {
					params["page"] = page - 1;
				}

				if (pageSize) {
					params["size"] = pageSize;
				}

				return params;
			},

			async getAllUsers() {
				const params = this.getRequestParams(
					this.searchTitle,
					this.page,
					this.pageSize
				);

				try {
					this.isloading = true;
					const response = await AuthService.getAllUsers(
						params.page,
						params.size,
						params.lastname
					);
					const { items, totalItems } = response.data;
					this.users = items;
					this.count = totalItems;
					this.isloading = false;
					console.log(response.data);
				} catch (error) {
					this.isloading = false;
					console.log(error);
				}
			},

			handlePageChange(value) {
				this.page = value;
				this.getAllUsers();
			},

			handlePageSizeChange(event) {
				this.pageSize = event.target.value;
				this.page = 1;
				this.getAllUsers();
			},

			async deleteUser(id) {
				try {
					const lang = this.$i18n.locale;
					const response = await AuthService.deleteUser(id, lang);
					console.log(response);
					this.getAllUsers();
					this.msg = response.msg;
					console.log(response.msg);
				} catch (error) {
					console.log(error);
					this.msg = error.response.data.msg;
				}
			},
		},
		mounted() {
			this.getAllUsers();
		},
	};
</script>
