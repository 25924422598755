var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.newTransaction)},"reset":function($event){$event.preventDefault();return reset($event)}}},[_c('div',{staticClass:"col-4 offset-md-4 text-left"},[_c('h2',{staticClass:"title-bold"},[_vm._v(_vm._s(_vm.$t("newTransaction.newTransaction")))]),_c('hr'),(_vm.isloading2)?_c('div',{staticClass:"text-center"},[_c('div',{staticClass:"background-outline"},[_c('b-spinner',{staticClass:"ml-auto text-center",staticStyle:{"width":"3rem","height":"3rem"},attrs:{"variant":_vm.secondary}})],1),_c('br')]):_c('div',{staticClass:"background-outline"},[_c('div',{staticClass:"background-modifier"},[_c('label',{staticClass:"small"},[_vm._v(_vm._s(_vm.$t("newTransaction.debitAccountId")))]),_c('ValidationProvider',{attrs:{"name":"debitAccountId","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"id":"debitAccountIdInputGroup","label-for":"debitAccountIdInput"}},[_c('b-form-select',{attrs:{"id":"debitAccountIdInput","state":errors[0] ? false : valid ? true : null},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":"","disabled":""}},[_vm._v(_vm._s(_vm.$t("newTransaction.debitAccountId")))])]},proxy:true}],null,true),model:{value:(_vm.debitAccountId),callback:function ($$v) {_vm.debitAccountId=$$v},expression:"debitAccountId"}},_vm._l((_vm.accounts),function(account){return _c('option',{key:account.id,domProps:{"value":account.id}},[_vm._v(_vm._s(account.id)+" "+_vm._s(account.firstname)+" "+_vm._s(account.lastname)+" "+_vm._s(_vm.$n(account.credits - account.debits, "decimal"))+" "+_vm._s(account.currency))])}),0),_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('label',{staticClass:"small"},[_vm._v(_vm._s(_vm.$t("newTransaction.creditAccountId")))]),_c('ValidationProvider',{attrs:{"name":"creditAccountId","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"id":"creditAccountIdInputGroup","label-for":"creditAccountIdInput"}},[_c('b-form-select',{attrs:{"id":"creditAccountIdInput","state":errors[0] ? false : valid ? true : null},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":"","disabled":""}},[_vm._v(_vm._s(_vm.$t("newTransaction.creditAccountId")))])]},proxy:true}],null,true),model:{value:(_vm.creditAccountId),callback:function ($$v) {_vm.creditAccountId=$$v},expression:"creditAccountId"}},_vm._l((_vm.accounts),function(account){return _c('option',{key:account.id,domProps:{"value":account.id}},[_vm._v(_vm._s(account.id)+" "+_vm._s(account.firstname)+" "+_vm._s(account.lastname)+" "+_vm._s(_vm.$n(account.credits - account.debits, "decimal"))+" "+_vm._s(account.currency))])}),0),_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('br'),_c('div',{staticClass:"background-modifier"},[_c('label',{staticClass:"small"},[_vm._v(_vm._s(_vm.$t("newTransaction.amount")))]),_c('ValidationProvider',{attrs:{"rules":"required|double|max_value:1000000|min_value:0.01","name":"amount"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"amountInput","description":""}},[_c('b-form-input',{attrs:{"type":"text","state":errors[0] ? false : valid ? true : null,"placeholder":_vm.$t('newTransaction.amount')},model:{value:(_vm.amount),callback:function ($$v) {_vm.amount=$$v},expression:"amount"}}),_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('br'),_c('div',{staticClass:"background-modifier"},[_c('label',{staticClass:"small"},[_vm._v(_vm._s(_vm.$t("newTransaction.message")))]),_c('ValidationProvider',{attrs:{"rules":"min:1|max:50","name":"message"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"messageInput","description":""}},[_c('b-form-input',{attrs:{"type":"text","state":errors[0] ? false : valid ? true : null,"placeholder":_vm.$t('newTransaction.message')},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}}),_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('br'),_c('b-button',{staticClass:"btn btn-info text-light btn-block",attrs:{"type":"submit"},on:{"click":_vm.isloading}},[_vm._v(_vm._s(_vm.$t("newTransaction.submit"))),(_vm.isloading)?_c('b-spinner',{staticClass:"ml-auto",attrs:{"variant":_vm.light}}):_vm._e()],1),_c('hr'),_c('b-button',{staticClass:"btn btn-alert text-light btn-block",attrs:{"type":"reset"},on:{"click":function($event){return _vm.resetForm()}}},[_vm._v(_vm._s(_vm.$t("reset")))]),(_vm.msg && _vm.$bvToast.show('notification-toast'))?_c('div'):_vm._e(),_c('div',{staticClass:"action"},[_c('br'),_c('b-toast',{attrs:{"id":"notification-toast","variant":"secondary","static":"","no-auto-hide":""},scopedSlots:_vm._u([{key:"toast-title",fn:function(){return [_c('div',{staticClass:"d-flex flex-grow-1 align-items-baseline"},[_c('b-img',{staticClass:"logo mb-2",attrs:{"src":require('/public/images/logo.png'),"alt":"","width":"12","height":"12"}}),_c('strong',{staticClass:"mr-auto"},[_vm._v(_vm._s(_vm.$t("notification")))]),_c('small',{staticClass:"text-muted mr-2"})],1)]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(_vm.msg)+" ")])],1)],1)])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }