<template>
	<div class="col-12 offset-0">
		<div class="">
			<div class="text-left">
				<div class="col-md-8 offset-md-2">
					<h1 class="title-bold">{{ $t("cards.cards") }}</h1>
					<hr />
				</div>

				<div v-if="!isloading" class="col-md-8 offset-md-2 ">
					<div class="">
						<h3 class="text-bold">
							{{ $t("cards.cards") }} ({{ listedCards.length }})
						</h3>
						<hr />
					</div>

					<!--   <p class="open-account text-right">
              <router-link
                class="btn btn-outline"
                :to="{ name: 'open-account' }"
                >{{ $t("accounts.openAccount") }}</router-link
              >
            </p>
            <br /> -->

					<div v-if="isloading" class="text-center">
						<b-spinner
							style="width: 3rem; height: 3rem;"
							class="ml-auto text-center"
							:variant="secondary"
						></b-spinner>
					</div>
					<div v-else-if="listedCards.length > 0">
						<b-row>
							<div
								v-for="card in listedCards"
								:key="card.id"
								class="col-6 card-grid"
							>
								<router-link
									:to="{ name: 'card', params: { id: card.id } }"
									class=""
									tag="text-grey"
								>
									<div class="background-modifier ">
										<div class="product-details">
											<br />
											<h3 class="title-bold">
												{{
													card.cardnumber
														.toString()
														.replace(/(\d{4}(?!\s))/g, "$1 ")
												}}
											</h3>
											<div class="">
												<div class="">
													<h3 class="text-bold">
														{{ card.cardholder.toUpperCase() }}
													</h3>
													<p class="text-right small">VALID THRU</p>
													<h4 class="text-right text-bold">
														{{ $d(Date.parse(card.validity), "card") }}
													</h4>
												</div>
											</div>
										</div>
									</div>
								</router-link>
							</div>
						</b-row>
					</div>
					<div v-else>{{ $t("cards.noCards") }}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import AuthService from "@/services/AuthService.js";

	export default {
		data() {
			return {
				card: "",
				username: "",
				listedCards: [],
				isloading: false,
			};
		},
		async created() {
			if (!this.$store.getters.isLoggedIn) {
				this.$router.push("login");
			}
			let lang = this.$i18n.locale;
			this.username = this.$store.getters.getUser.username;
		},
		methods: {
			async getUsersCardList() {
				try {
					const id = this.$store.getters.getUser.id;
					this.isloading = true;
					const response = await AuthService.getUsersCardList(id);
					this.listedCards = response;
					this.isloading = false;
					console.log(response);
				} catch (error) {
					this.isloading = false;
					console.log(error);
				}
			},
		},

		mounted() {
			this.getUsersCardList();
		},
	};
</script>
