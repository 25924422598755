<template>
	<div class="col-12 offset-0">
		<div class="">
			<div class="text-left">
				<div class="col-md-8 offset-md-2">
					<h1 class="title-bold">
						{{ $t("businessSignup.applicationOverview") }}
					</h1>
					<p class="text-right">{{ $d(new Date(), "short") }}</p>

					<div class="background-modifier">
						<p>{{ $t("hello") }} {{ firstname }}</p>

						<p>{{ $t("businessSignup.yourApplicationIsFor") }}:</p>
						<label class="small">{{ $t("businessSignup.fullname") }}</label>
						<p>
							{{ businessDetails.name }}
						</p>

						<div v-if="businessDetails.subType !== 0">
							<label class="small">{{ $t("businessSignup.businessId") }}</label>
							<p>
								{{ businessDetails.idCode ? businessDetails.idCode : "-" }}
							</p>
						</div>
						<div v-if="businessDetails.subType === 0">
							<p>{{ $t("businessSignup.companyInIncorporation") }}</p>
						</div>

						<!-- <div v-if="allBusinessDetails != 0"> -->
						<hr />
						<p class="text-left text-bold">
							{{ $t("businessSignup.general") }}
						</p>
						<br />
						<div v-if="businessDetails.subType !== 0">
							<label class="small">{{ $t("businessSignup.vatNumber") }}</label>
							<p>
								{{ allBusinessDetails.vatNumber }}
							</p>
						</div>

						<div v-if="businessDetails.subType !== 0">
							<label class="small">{{
								$t("businessSignup.dateOfIncorporation")
							}}</label>
							<p>
								{{ $d(Date.parse(allBusinessDetails.doi), "short") }}
							</p>
						</div>
						<div v-if="businessDetails.subType === 0">
							<label class="small">{{
								$t("businessSignup.estimatedIncorporationDate")
							}}</label>
							<p>
								{{ $d(Date.parse(allBusinessDetails.doi), "short") }}
							</p>
						</div>

						<label class="small">{{
							$t("businessSignup.placeOfIncorporation")
						}}</label>
						<p>
							{{ allBusinessDetails.placeOfIncorporation }}
						</p>
						<label class="small">{{ $t("businessSignup.domicile") }}</label>
						<p>
							{{ allBusinessDetails.domicile }}
						</p>
						<hr />
						<p class="text-left text-bold">
							{{ $t("businessSignup.contactDetails") }}
						</p>
						<br />
						<p class="text-left">
							{{ $t("businessSignup.registeredAddress") }}
						</p>
						<label class="small">{{ $t("businessSignup.address") }}</label>
						<p>
							{{ allBusinessDetails.addressLine1 }}
						</p>
						<label class="small">{{ $t("businessSignup.city") }}</label>
						<p>
							{{ allBusinessDetails.city }}
						</p>
						<label class="small">{{ $t("businessSignup.postCode") }}</label>
						<p>
							{{ allBusinessDetails.zip }}
						</p>
						<label class="small">{{ $t("businessSignup.country") }}</label>
						<p>
							{{ allBusinessDetails.country }}
						</p>
						<label class="small"></label>
						<p class="text-left">
							{{ $t("businessSignup.businessAddress") }}
						</p>
						<label class="small">{{ $t("businessSignup.address") }}</label>
						<p>
							{{ allBusinessDetails.contactAddressLine1 }}
						</p>
						<label class="small">{{ $t("businessSignup.city") }}</label>
						<p>
							{{ allBusinessDetails.contactCity }}
						</p>
						<label class="small">{{ $t("businessSignup.postCode") }}</label>
						<p>
							{{ allBusinessDetails.contactZip }}
						</p>
						<label class="small">{{ $t("businessSignup.country") }}</label>
						<p>
							{{ allBusinessDetails.contactCountry }}
						</p>
						<label class="small">{{ $t("businessSignup.phone") }}</label>
						<p>
							{{ allBusinessDetails.phoneNumber }}
						</p>
						<label class="small">{{ $t("businessSignup.email") }}</label>
						<p>
							{{ allBusinessDetails.email }}
						</p>
						<label class="small">{{ $t("businessSignup.website") }}</label>
						<p>
							{{ allBusinessDetails.website }}
						</p>
						<hr />
						<p class="text-left text-bold">
							{{ $t("businessSignup.registry") }}
						</p>
						<br />
						<label class="small">{{ $t("businessSignup.name") }}</label>
						<p>
							{{ allBusinessDetails.registry }}
						</p>
						<label class="small">{{ $t("businessSignup.website") }}</label>
						<p>
							{{ allBusinessDetails.registryWebsite }}
						</p>
						<hr />
						<p class="text-left text-bold">
							{{ $t("businessSignup.auditors") }}
						</p>
						<br />
						<label class="small">{{ $t("businessSignup.name") }}</label>
						<p>
							{{ allBusinessDetails.auditors }}
						</p>
						<hr />
						<p class="text-left text-bold">
							{{ $t("businessSignup.activitiesBusinessSources") }}
						</p>
						<br />
						<label class="small">{{ $t("businessSignup.turnover") }}</label>
						<p>
							{{
								allBusinessDetails.turnover != null
									? $n(allBusinessDetails.turnover, "decimal")
									: $n(0.0, "decimal")
							}}
						</p>
						<label class="small">{{ $t("businessSignup.employees") }}</label>
						<p>
							{{ allBusinessDetails.employees }}
						</p>
						<label class="small">{{ $t("businessSignup.totalAssets") }}</label>
						<p>
							{{
								allBusinessDetails.totalAssets != null
									? $n(allBusinessDetails.totalAssets, "decimal")
									: $n(0.0, "decimal")
							}}
						</p>
						<label class="small">{{
							$t("businessSignup.countryActivity")
						}}</label>
						<p>
							{{ allBusinessDetails.countryActivity }}
						</p>
						<hr />
						<div class="row">
							<div class="col-md-6 text-left">
								<router-link
									:to="{
										name: 'add-business-details',
										params: { id: businessDetails.clientId },
									}"
									class="btn btn-secondary mx-1 "
									>{{ $t("businessSignup.addBusinessDetails") }}</router-link
								>
								<p class="text-left">
									{{ $t("businessSignup.addRequiredBusinessDetails") }}
								</p>
							</div>
							<div class="col-md-6 text-right">
								<router-link
									:to="{
										name: 'edit-business-details',
										params: { id: allBusinessDetails.clientId },
									}"
									class="btn btn-outline mx-1 "
									>{{ $t("businessSignup.edit") }}</router-link
								>
							</div>
						</div>
						<hr />
					</div>
					<!-- </div> -->
					<hr />

					<div class="background-modifier">
						<h5 class="text-left text-bold">
							{{ $t("businessSignup.identityOfKeyPersons") }}
						</h5>
						<hr />
						<p class="text-left">
							{{ $t("businessSignup.addInfoAboutKeyPersons") }}
						</p>

						<div v-if="businessRoles.length > 0"></div>
						<div v-else>
							<p class="text-bold">
								{{ $t("businessSignup.noKeyPersons") }}
							</p>
						</div>

						<p class="text-left text-bold">
							{{ $t("businessSignup.shareholders") }}
						</p>

						<p class="text-left ">
							{{ $t("businessSignup.shareholderInfo1") }}
						</p>

						<p class="text-left">
							{{ $t("businessSignup.shareholderInfo2") }}
						</p>

						<b-row>
							<div
								v-for="businessRole in businessRoles"
								:key="businessRole.id"
								class="col-6 card-grid"
							>
								<router-link
									:to="{
										name: 'business-edit-role',
										params: {
											id: businessRole.id,
											reference: businessRole.role,
										},
									}"
									class=""
									tag="text-grey"
								>
									<div v-if="businessRole.role == '1'">
										<div class="background-modifier ">
											<h4 class="">
												{{ businessRole.firstname }} {{ businessRole.lastname }}
											</h4>
											<div>
												<div v-if="businessRole.role == '1'">
													<p class="">{{ $t("businessSignup.ubo") }}</p>
												</div>
												<div v-if="businessRole.role == '2'">
													<p class="">{{ $t("businessSignup.shareholder") }}</p>
												</div>
												<div v-if="businessRole.role == '3'">
													<p class="">{{ $t("businessSignup.director") }}</p>
												</div>
												<div v-if="businessRole.role == '4'">
													<p class="">
														{{ $t("businessSignup.decisionMaker") }}
													</p>
												</div>
												<div v-if="businessRole.role == '5'">
													<p class="">{{ $t("businessSignup.signatory") }}</p>
												</div>
											</div>
											<div v-if="businessRole.role == '1'">
												<p class="">
													{{
														businessRole.ownership != null
															? $n(businessRole.ownership, "decimal")
															: $n(0.0, "decimal")
													}}
													{{ $t("businessSignup.ownership") }} /
													{{
														businessRole.votes != null
															? $n(businessRole.votes, "decimal")
															: $n(0.0, "decimal")
													}}
													{{ $t("businessSignup.votes") }}
												</p>
											</div>
										</div>
									</div>
									<!-- <div v-else>
										<p class="text-bold">{{ $t("businessSignup.noUBOs") }}</p>
									</div> -->
								</router-link>
							</div>
						</b-row>

						<br />

						<div class="text-left">
							<router-link
								:to="{
									name: 'business-add-role',
									params: { id: allBusinessDetails.clientId, reference: 1 },
								}"
								class="btn btn-secondary mx-1 "
								>{{ $t("businessSignup.addUBOs") }}</router-link
							>
						</div>
						<br />
						<b-row>
							<div
								v-for="businessRole in businessRoles"
								:key="businessRole.id"
								class="col-6 card-grid"
							>
								<router-link
									:to="{
										name: 'business-edit-role',
										params: {
											id: businessRole.id,
											reference: businessRole.role,
										},
									}"
									class=""
									tag="text-grey"
								>
									<div v-if="businessRole.role == '2'">
										<div class="background-modifier ">
											<h4 class="">
												{{ businessRole.firstname }} {{ businessRole.lastname }}
											</h4>
											<div>
												<div v-if="businessRole.role == '1'">
													<p class="">{{ $t("businessSignup.ubo") }}</p>
												</div>
												<div v-if="businessRole.role == '2'">
													<p class="">{{ $t("businessSignup.shareholder") }}</p>
												</div>
												<div v-if="businessRole.role == '3'">
													<p class="">{{ $t("businessSignup.director") }}</p>
												</div>
												<div v-if="businessRole.role == '4'">
													<p class="">
														{{ $t("businessSignup.decisionMaker") }}
													</p>
												</div>
												<div v-if="businessRole.role == '5'">
													<p class="">{{ $t("businessSignup.signatory") }}</p>
												</div>
											</div>
											<div
												v-if="
													businessRole.role == '1' || businessRole.role == '2'
												"
											>
												<p class="">
													{{
														businessRole.ownership != null
															? $n(businessRole.ownership, "decimal")
															: $n(0.0, "decimal")
													}}
													{{ $t("businessSignup.ownership") }} /
													{{
														businessRole.votes != null
															? $n(businessRole.votes, "decimal")
															: $n(0.0, "decimal")
													}}
													{{ $t("businessSignup.votes") }}
												</p>
											</div>
										</div>
									</div>
									<!-- <div v-if="businessRole.role != '2'">
										<p class="text-bold">
											{{ $t("businessSignup.noShareholders") }}
										</p>
									</div> -->
								</router-link>
							</div>
						</b-row>
						<br />
						<div class="text-left">
							<router-link
								:to="{
									name: 'business-add-role',
									params: { id: allBusinessDetails.clientId, reference: 2 },
								}"
								class="btn btn-secondary mx-1 "
								>{{ $t("businessSignup.addShareholders") }}</router-link
							>
							<hr />
							<p class="text-left text-bold">
								{{ $t("businessSignup.directors") }}
							</p>

							<p class="text-left">
								{{ $t("businessSignup.directorInfo") }}
							</p>

							<b-row>
								<div
									v-for="businessRole in businessRoles"
									:key="businessRole.id"
									class="col-6 card-grid"
								>
									<router-link
										:to="{
											name: 'business-edit-role',
											params: {
												id: businessRole.id,
												reference: businessRole.role,
											},
										}"
										class=""
										tag="text-grey"
									>
										<div v-if="businessRole.role == '3'">
											<div class="background-modifier ">
												<h4 class="">
													{{ businessRole.firstname }}
													{{ businessRole.lastname }}
												</h4>
												<div>
													<div v-if="businessRole.role == '1'">
														<p class="">{{ $t("businessSignup.ubo") }}</p>
													</div>
													<div v-if="businessRole.role == '2'">
														<p class="">
															{{ $t("businessSignup.shareholder") }}
														</p>
													</div>
													<div v-if="businessRole.role == '3'">
														<p class="">{{ $t("businessSignup.director") }}</p>
													</div>
													<div v-if="businessRole.role == '4'">
														<p class="">
															{{ $t("businessSignup.decisionMaker") }}
														</p>
													</div>
													<div v-if="businessRole.role == '5'">
														<p class="">{{ $t("businessSignup.signatory") }}</p>
													</div>
												</div>
											</div>
										</div>
										<!-- <div v-else>
											<p class="text-bold">
												{{ $t("businessSignup.noDirectors") }}
											</p>
										</div> -->
									</router-link>
								</div>
							</b-row>

							<br />

							<div class="text-left">
								<router-link
									:to="{
										name: 'business-add-role',
										params: { id: allBusinessDetails.clientId, reference: 3 },
									}"
									class="btn btn-secondary mx-1 "
									>{{ $t("businessSignup.addDirectors") }}</router-link
								>
							</div>

							<hr />

							<p class="text-left text-bold">
								{{ $t("businessSignup.decisionMakers") }}
							</p>

							<p class="text-left">
								{{ $t("businessSignup.decisionMakerInfo") }}
							</p>

							<b-row>
								<div
									v-for="businessRole in businessRoles"
									:key="businessRole.id"
									class="col-6 card-grid"
								>
									<router-link
										:to="{
											name: 'business-edit-role',
											params: {
												id: businessRole.id,
												reference: businessRole.role,
											},
										}"
										class=""
										tag="text-grey"
									>
										<div v-if="businessRole.role == '4'">
											<div class="background-modifier ">
												<h4 class="">
													{{ businessRole.firstname }}
													{{ businessRole.lastname }}
												</h4>
												<div>
													<div v-if="businessRole.role == '1'">
														<p class="">{{ $t("businessSignup.ubo") }}</p>
													</div>
													<div v-if="businessRole.role == '2'">
														<p class="">
															{{ $t("businessSignup.shareholder") }}
														</p>
													</div>
													<div v-if="businessRole.role == '3'">
														<p class="">{{ $t("businessSignup.director") }}</p>
													</div>
													<div v-if="businessRole.role == '4'">
														<p class="">
															{{ $t("businessSignup.decisionMaker") }}
														</p>
													</div>
													<div v-if="businessRole.role == '5'">
														<p class="">{{ $t("businessSignup.signatory") }}</p>
													</div>
												</div>
											</div>
										</div>
										<!-- <div v-else>
											<p class="text-bold">
												{{ $t("businessSignup.noDecisionMakers") }}
											</p>
										</div> -->
									</router-link>
								</div>
							</b-row>

							<br />

							<div class="text-left">
								<router-link
									:to="{
										name: 'business-add-role',
										params: { id: allBusinessDetails.clientId, reference: 4 },
									}"
									class="btn btn-secondary mx-1 "
								>
									{{ $t("businessSignup.addDecisionMakers") }}</router-link
								>
							</div>

							<hr />

							<p class="text-left text-bold">
								{{ $t("businessSignup.signatories") }}
							</p>

							<p class="text-left">
								{{ $t("businessSignup.signatoryInfo") }}
							</p>

							<b-row>
								<div
									v-for="businessRole in businessRoles"
									:key="businessRole.id"
									class="col-6 card-grid"
								>
									<router-link
										:to="{
											name: 'business-edit-role',
											params: {
												id: businessRole.id,
												reference: businessRole.role,
											},
										}"
										class=""
										tag="text-grey"
									>
										<div v-if="businessRole.role == '5'">
											<div class="background-modifier ">
												<h4 class="">
													{{ businessRole.firstname }}
													{{ businessRole.lastname }}
												</h4>
												<div>
													<div v-if="businessRole.role == '1'">
														<p class="">{{ $t("businessSignup.ubo") }}</p>
													</div>
													<div v-if="businessRole.role == '2'">
														<p class="">
															{{ $t("businessSignup.shareholder") }}
														</p>
													</div>
													<div v-if="businessRole.role == '3'">
														<p class="">{{ $t("businessSignup.director") }}</p>
													</div>
													<div v-if="businessRole.role == '4'">
														<p class="">
															{{ $t("businessSignup.decisionMaker") }}
														</p>
													</div>
													<div v-if="businessRole.role == '5'">
														<p class="">{{ $t("businessSignup.signatory") }}</p>
													</div>
												</div>
											</div>
										</div>
										<!-- <div v-else>
											<p class="text-bold">
												{{ $t("businessSignup.noSignatories") }}
											</p>
										</div> -->
									</router-link>
								</div>
							</b-row>

							<br />

							<div class="text-left">
								<router-link
									:to="{
										name: 'business-add-role',
										params: { id: allBusinessDetails.clientId, reference: 5 },
									}"
									class="btn btn-secondary mx-1 "
									>{{ $t("businessSignup.addSignatories") }}</router-link
								>
							</div>
							<hr />
						</div>
					</div>

					<hr />

					<div class="background-modifier">
						<h5 class="title-bold">
							{{ $t("businessSignup.documentUpload") }}
						</h5>
						<hr />

						<!-- <div v-if="loaded && businessDocs.length">
              <h5 class="title-bold">{{ $t("fileUpload.listOfFiles") }}</h5>
              <table class="table mt-2 ">
                <thead>
                  <tr>
                    <th class="text-left">
                      {{ $t("documentUpload.filename") }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="businessDoc in businessDocs" :key="businessDoc.id">
                    <td class="text-left">
                      <span>
                        <a
                          :href="
                            'https://www.n3consortium.com/api/files/' +
                              businessDoc.filename
                          "
                          >{{ businessDoc.filename }}</a
                        ></span
                      >
                    </td>

                    <td class="text-center"></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-else>{{ $t("fileUpload.noDocuments") }}</div> -->

						<p class="">
							{{ $t("businessSignup.uploadInfo") }}
						</p>
						<router-link
							:to="{
								name: 'business-document-upload',
								params: { id: allBusinessDetails.clientId },
							}"
							class="btn btn-secondary mx-1 "
							>{{ $t("businessSignup.uploadDocuments") }}</router-link
						>
						<hr />
					</div>

					<hr />
					<p>{{ $t("businessSignup.submitFinalApplication") }}</p>
					<b-form>
						<b-button
							@click="updateApplicationStatus()"
							class="btn btn-info text-light btn-block"
							>{{ $t("businessSignup.submit") }}</b-button
						>
					</b-form>
					<hr />
					<div v-if="msg && $bvToast.show('notification-toast')"></div>
					<div class="action">
						<b-toast
							id="notification-toast"
							variant="secondary"
							static
							no-auto-hide
						>
							<template #toast-title>
								<div class="d-flex flex-grow-1 align-items-baseline">
									<b-img
										class="logo mb-2"
										:src="require('/public/images/logo.png')"
										alt=""
										width="12"
										height="12"
									></b-img>
									<strong class="mr-auto">{{ $t("notification") }}</strong>
									<small class="text-muted mr-2"></small>
								</div>
							</template>
							{{ msg }}
						</b-toast>
					</div>
					<br />

					<!-- <div class="col-md-12 offset-md-0 ">
            <div class="row">
              <div class="col-md-6 text-left">
                <span>
                  <h3 class="text-bold">
                    {{
                      totalBalance() != null
                        ? $n(totalBalance(), "decimal")
                        : $n(0.0, "decimal")
                    }}
                  </h3>
                  <p class="small">
                    {{ $t("accounts.totalBalance") }} ({{ this.baseCurrency }})
                  </p>
                </span>
              </div>

              <div class="col-md-6 text-right">
                <h3 class="text-bold">
                  {{ $t("accounts.accounts") }} ({{ listedAccounts.length }})
                </h3>
              </div>
            </div> -->

					<!--   <p class="open-account text-right">
            <router-link
              class="btn btn-outline"
              :to="{ name: 'open-account' }"
              >{{ $t("accounts.openAccount") }}</router-link
            >
          </p>
          <br /> -->

					<!--    <div class="input-group mb-3">
            <input
              type="text"
              class="form-control"
              :placeholder="$t('accounts.nickname')"
              v-model="searchTitle"
            />
            <div class="input-group-append">
              <button
                class="btn btn-outline-secondary"
                type="button"
                @click="
                  page = 1;
                  getUsersAccounts();
                "
              >
                {{ $t("search") }}
              </button>
            </div>
          </div> -->
					<!-- <div v-if="accounts.length > 0">
            <b-row>
              <div
                v-for="account in listedAccounts"
                :key="account.id"
                class="col-6 card-grid"
              >
                <router-link
                  :to="{ name: 'account', params: { id: account.id } }"
                  class=""
                  tag="text-grey"
                >
                  <div class="background-modifier ">
                    <h4 class="">
                      {{
                        account.iban.toString().replace(/\w{4}(?=.)/g, "$& ")
                      }}
                    </h4>
                    <p class="text-left">{{ account.nickname }}</p>
                    <div class="">
                      <div class="">
                        <h3 class="text-bold">
                          {{ account.currency }}
                          {{
                            account.balance != null
                              ? $n(account.balance, "decimal")
                              : $n(0.0, "decimal")
                          }}
                        </h3>
                      </div>
                      <div v-if="account.currency != baseCurrency" class="">
                        <small
                          >({{ baseCurrency }}
                          {{
                            account.baseBalance != null
                              ? $n(account.baseBalance, "decimal")
                              : $n(0.0, "decimal")
                          }})</small
                        >
                      </div>
                    </div>
                  </div>
                </router-link>
              </div>
            </b-row>
          </div>
          <div v-else>{{ $t("accounts.noAccounts") }}</div> -->

					<!-- <div class="background-modifier">
            <div v-if="listedAccounts.length > 10">
              <div class="">
                <div class="mb-3">
                  {{ $t("itemsPerPage") }}
                  <select 
										class="custom-select" 
										style="width: 60px;"
                    v-model="pageSize"
                    @change="handlePageSizeChange($event)"
                  >
                    <option v-for="size in pageSizes" :key="size" :value="size">
                      {{ size }}
                    </option>
                  </select>
                </div>

                <b-pagination
                  v-model="page"
                  :total-rows="count"
                  :per-page="pageSize"
                  :prev-text="$t('previous')"
                  :next-text="$t('next')"
                  @change="handlePageChange"
                ></b-pagination>
              </div>
            </div>

            <span>
              <h3 class="title-bold">
                {{
                  totalBalance() != null
                    ? $n(totalBalance(), "decimal")
                    : $n(0.0, "decimal")
                }}
              </h3>
              <p class="small">
                {{ $t("accounts.totalBalance") }} ({{ this.baseCurrency }})
              </p>
            </span>

            <div v-if="accounts.length > 0">
              <div class="table-wrap">
                <table class="table mt-2" id="accounts">
                  <thead>
                    <tr>
                      <th class="text-left">IBAN</th>
                    
                      <th class="text-left">{{ $t("accounts.nickname") }}</th>
                     
                      <th class="text-right">{{ $t("accounts.currency") }}</th>
                      <th class="text-right">{{ $t("accounts.balance") }}</th>
                      <th class="text-right small">
                        {{ $t("accounts.balance") }} ({{ this.baseCurrency }})
                      </th>
                      <th class="text-centered">
                        {{ $t("accounts.actions") }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="account in accounts" :key="account.id">
                    
                      <td class="text-left">
                        {{
                          account.iban.toString().replace(/\w{4}(?=.)/g, "$& ")
                        }}
                      </td>
                
                      <td class="text-left">{{ account.nickname }}</td>
                    
                      <td class="text-left">{{ account.currency }}</td>
                      <td class="text-right">
                        {{
                          account.balance != null
                            ? $n(account.balance, "decimal")
                            : $n(0.0, "decimal")
                        }}
                      </td>
                      <td class="text-right small">
                        {{
                          account.baseBalance != null
                            ? $n(account.baseBalance, "decimal")
                            : $n(0.0, "decimal")
                        }}
                      </td>
                      <td class="text-right">
                        <router-link
                          :to="{ name: 'account', params: { id: account.id } }"
                          class="btn btn-outline mx-1 "
                          >{{ $t("accounts.view") }}</router-link
                        >
                       
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div v-else>{{ $t("accounts.noAccounts") }}</div> -->
					<!-- </div> -->
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import AuthService from "@/services/AuthService.js";

	export default {
		data() {
			return {
				loaded: false,
				account: "",
				userMessage: "",
				firstname: "",
				lastname: "",
				username: "",
				msg: "",
				businessDetails: "",
				allBusinessDetails: "",
				businessRoles: [],
				businessRole: "",
				businessDocs: [],
				accounts: [],
				listedAccounts: [],
				currentAccount: null,
				currentIndex: -1,
				searchTitle: "",

				page: 1,
				count: 0,
				pageSize: 10,

				pageSizes: [10, 25, 50],
			};
		},
		async created() {
			if (!this.$store.getters.isLoggedIn) {
				this.$router.push("login");
			}
			if (
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 3 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 15 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 14
			) {
				this.$router.push("overview");
			}
			let lang = this.$i18n.locale;
			this.role = this.$store.getters.getUser.role;
			this.username = this.$store.getters.getUser.username;
			this.firstname = this.$store.getters.getUser.firstname;
			this.userMessage = await AuthService.getUserContent(lang);
			this.baseCurrency = this.$store.getters.getUser.currency;
			// this.getBusinessDetails();
		},
		methods: {
			getRequestParams(searchTitle, page, pageSize) {
				let params = {};

				if (searchTitle) {
					params["nickname"] = searchTitle;
				}

				if (page) {
					params["page"] = page - 1;
				}

				if (pageSize) {
					params["size"] = pageSize;
				}

				return params;
			},

			async getUsersAccounts() {
				const params = this.getRequestParams(
					this.searchTitle,
					this.page,
					this.pageSize
				);

				try {
					const details = {
						id: this.$store.getters.getUser.id,
					};
					const response = await AuthService.getUsersAccounts(
						details.id,
						params.page,
						params.size,
						params.nickname
					);
					const { items, totalItems } = response.data;
					this.accounts = items;
					this.count = totalItems;
					console.log(response.data);
				} catch (error) {
					console.log(error);
				}
			},

			async getUsersAccountList() {
				try {
					const id = this.$store.getters.getUser.id;
					const response = await AuthService.getUsersAccountList(id);
					this.listedAccounts = response;
					console.log(response);
				} catch (error) {
					console.log(error);
				}
			},
			totalBalance() {
				return this.listedAccounts.reduce(
					(acc, item) => acc + item.baseBalance,
					0
				);
			},

			handlePageChange(value) {
				this.page = value;
				this.getUsersAccounts();
			},

			handlePageSizeChange(event) {
				this.pageSize = event.target.value;
				this.page = 1;
				this.getUsersAccounts();
			},

			async getBusinessDetails() {
				try {
					const id = this.$store.getters.getUser.id;
					const response = await AuthService.getBusinessDetails(id);
					this.businessDetails = response;
					console.log(response);
				} catch (error) {
					console.log(error);
				}
			},

			async getAllBusinessDetails() {
				try {
					const id = this.$store.getters.getUser.id;
					const response = await AuthService.getAllBusinessDetails(id);
					this.allBusinessDetails = response;
					console.log(response);
				} catch (error) {
					console.log(error);
				}
			},

			async getBusinessRoles() {
				try {
					const id = this.$store.getters.getUser.id;
					const response = await AuthService.getBusinessRoles(id);
					this.businessRoles = response;
					console.log(response);
				} catch (error) {
					console.log(error);
				}
			},

			async getBusinessDocs(id) {
				try {
					const id = this.allBusinessDetails.clientId;
					//  console.log(id);
					const response = await AuthService.getBusinessDocs(id);
					this.businessDocs = response;
					this.loaded = true;
					console.log(response);
				} catch (error) {
					console.log(error);
				}
			},

			async updateApplicationStatus() {
				try {
					const id = this.allBusinessDetails.clientId;
					const details = {
						lang: this.$i18n.locale,
						status: 1,
					};
					const response = await AuthService.updateApplicationStatus(
						id,
						details
					);
					this.msg = response.msg;
					console.log(response.msg);
				} catch (error) {
					this.msg = error.response.data.msg;
					//console.log(error);
				}
			},

			/* async deleteAccount(id) {
        try {
          const response = await AuthService.deleteAccount(id);
          console.log(response);
          this.getUsersAccounts();
        } catch (error) {
          console.log(error);
        }
      },*/
		},

		mounted() {
			this.getBusinessDetails();
			this.getAllBusinessDetails();
			this.getBusinessRoles();
			// this.getBusinessDocs();
			this.getUsersAccounts();
			this.getUsersAccountList();
			this.totalBalance();

			// this.formatIBAN();
		},
	};
</script>
