<template>
	<div class="col-8 offset-md-2 text-left">
		<h2 class="title-bold">{{ $t("fileUpload.fileUpload") }}</h2>
		<hr />
		<div class="background-modifier">
			<label class="col-10 offset-md-1">
				<input class="" type="file" ref="file" @change="selectFile" />
			</label>
			<hr />
			<div v-if="currentFile" class="progress">
				<div
					class="progress-bar progress-bar-striped bg-info"
					role="progressbar"
					:aria-valuenow="progress"
					aria-valuemin="0"
					aria-valuemax="100"
					:style="{ width: progress + '%' }"
				>
					{{ progress }}%
				</div>
			</div>
			<br />
			<b-button
				class="btn btn-info text-light btn-block"
				:disabled="!selectedFiles"
				@click="upload"
			>
				{{ $t("fileUpload.upload") }}
			</b-button>

			<div class="alert alert-light" role="alert">{{ msg }}</div>

			<!--   <div class="card">
          <div class="card-header">{{ $t('fileUpload.listOfFiles') }}</div>
          <ul class="list-group list-group-flush">
            <li
              class="list-group-item"
              v-for="(file, index) in fileInfos"
              :key="index"
            >
              <a :href="file.url">{{ file.name }}</a>
            </li>
          </ul>
        </div>  -->

			<hr />
			<div class="">
				<h3 class="title-bold">{{ $t("fileUpload.listOfFiles") }}</h3>

				<div v-if="idDocs.length > 0">
					<table class="table mt-2 ">
						<thead>
							<tr>
								<!--  <th class="text-left">{{ $t('filename') }}</th> -->
							</tr>
						</thead>
						<tbody>
							<tr v-for="idDoc in idDocs" :key="idDoc.id">
								<td class="text-left">
									<span>
										<a
											:href="
												'https://www.n3consortium.com/api/files/' +
													idDoc.filename
											"
											>{{ idDoc.filename }}</a
										></span
									>
								</td>

								<td class="text-center"></td>
							</tr>
						</tbody>
					</table>
				</div>

				<div v-else>{{ $t("fileUpload.noDocuments") }}</div>
			</div>
		</div>
	</div>
</template>

<script>
	import AuthService from "@/services/AuthService.js";
	import UploadService from "../services/FileUploadService";

	export default {
		name: "file-upload",
		data() {
			return {
				selectedFiles: undefined,
				currentFile: undefined,
				progress: 0,
				msg: "",

				fileInfos: [],
				idDocs: [],
			};
		},
		async created() {
			if (!this.$store.getters.isLoggedIn) {
				this.$router.push("login");
			}
			/*    if (this.$store.getters.isLoggedIn && this.$store.getters.getUser.role !== 3) {
        this.$router.push('overview');
      }
      this.role = this.$store.getters.getUser.role;*/
			// this.getIdDocs();
		},
		methods: {
			selectFile() {
				this.selectedFiles = this.$refs.file.files;
			},
			upload() {
				this.progress = 0;

				const lang = this.$i18n.locale;
				const userId = this.$store.getters.getUser.id;

				this.currentFile = this.selectedFiles.item(0);
				UploadService.upload(this.currentFile, lang, userId, (event) => {
					this.progress = Math.round((100 * event.loaded) / event.total);
				})
					.then((response) => {
						this.msg = response.data.msg;
						this.getIdDocs();
						return UploadService.getFiles();
					})
					.then((files) => {
						this.fileInfos = files.data;
					})
					.catch(() => {
						this.progress = 0;
						this.msg = this.$t("couldNotUploadTheFile");
						this.currentFile = undefined;
					});

				this.selectedFiles = undefined;
			},
			async getIdDocs(id) {
				try {
					id = this.$store.getters.getUser.id;
					//  console.log(id);
					const response = await AuthService.getIdDocs(id);
					this.idDocs = response;
					console.log(response);
				} catch (error) {
					console.log(error);
				}
			},
		},
		mounted() {
			UploadService.getFiles().then((response) => {
				this.fileInfos = response.data;
			});
			this.getIdDocs();
		},
	};
</script>
