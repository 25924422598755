<template>
	<ValidationObserver ref="observer" v-slot="{ handleSubmit, reset }">
		<b-form @submit.prevent="handleSubmit(openAccount)" @reset.prevent="reset">
			<div class="col-lg-4 offset-lg-4">
				<h2 class="title-bold text-left">
					{{ $t("openAccount.openAccount") }}
				</h2>
				<hr />
				<div class="background-outline">
					<div class="background-modifier">
						<div class="text-left">
							<label class="small">{{ $t("openAccount.currency") }}</label>
							<ValidationProvider
								rules="required"
								name="currency"
								v-slot="{ valid, errors }"
							>
								<b-form-group
									id="accountCurrencyInputGroup"
									label-for="accountCurrencyInput"
								>
									<b-form-select
										id="accountCurrencyInput"
										:state="errors[0] ? false : valid ? true : null"
										v-model="currency"
										><option
											v-for="currency in currencies"
											:value="currency.code"
											:key="currency.code"
											>{{ currency.code }}</option
										>
										<template #first>
											<b-form-select-option value="" disabled>{{
												$t("openAccount.currency")
											}}</b-form-select-option>
										</template></b-form-select
									>
									<b-form-invalid-feedback id="inputLiveFeedback">{{
										errors[0]
									}}</b-form-invalid-feedback>
								</b-form-group>
							</ValidationProvider>

							<label class="small">{{ $t("openAccount.nickname") }}</label>
							<ValidationProvider
								rules="alpha_spaces|min:3|max:50"
								name="nickname"
								v-slot="{ valid, errors }"
							>
								<b-form-group
									label-for="lastnameInput"
									:description="$t('openAccount.nicknameVoluntary')"
								>
									<b-form-input
										type="text"
										v-model="nickname"
										:state="errors[0] ? false : valid ? true : null"
										:placeholder="$t('openAccount.nickname')"
									></b-form-input>
									<b-form-invalid-feedback id="inputLiveFeedback">{{
										errors[0]
									}}</b-form-invalid-feedback>
								</b-form-group>
							</ValidationProvider>
						</div>
					</div>
					<hr />

					<b-button
						type="submit"
						@click="isloading"
						class="btn btn-info text-light btn-block"
						>{{ $t("openAccount.submit")
						}}<b-spinner
							v-if="isloading"
							class="ml-auto"
							:variant="light"
						></b-spinner
					></b-button>
					<hr />
					<b-button
						type="reset"
						class="btn btn-alert text-light btn-block"
						@click="resetForm()"
						>{{ $t("reset") }}</b-button
					>

					<div v-if="msg && $bvToast.show('notification-toast')"></div>
					<div class="action">
						<br />
						<b-toast
							id="notification-toast"
							variant="secondary"
							static
							no-auto-hide
						>
							<template #toast-title>
								<div class="d-flex flex-grow-1 align-items-baseline">
									<b-img
										class="logo mb-2"
										:src="require('/public/images/logo.png')"
										alt=""
										width="12"
										height="12"
									></b-img>
									<strong class="mr-auto">{{ $t("notification") }}</strong>
									<small class="text-muted mr-2"></small>
								</div>
							</template>
							{{ msg }}
						</b-toast>
					</div>
				</div>
			</div>
		</b-form>
	</ValidationObserver>
</template>

<script>
	import { ValidationObserver, ValidationProvider } from "vee-validate";
	import AuthService from "@/services/AuthService.js";

	export default {
		name: "OpenAccountForm",
		components: {
			ValidationObserver,
			ValidationProvider,
		},
		data() {
			return {
				currencies: [],
				currency: "EUR",
				nickname: this.$t("openAccount.myAccount"),
				userId: "",
				msg: "",
				isloading: false,
			};
		},
		async created() {
			if (!this.$store.getters.isLoggedIn) {
				this.$router.push("login");
			}
		},
		methods: {
			async getAllAccountCurrencies() {
				try {
					const response = await AuthService.getAllAccountCurrencies();
					this.currencies = response;
					console.log(response);
				} catch (error) {
					console.log(error);
				}
			},
			async openAccount() {
				try {
					const details = {
						lang: this.$i18n.locale,
						currency: this.currency,
						nickname: this.nickname,
						userId: (this.userId = this.$store.getters.getUser.id),
					};
					this.isloading = true;
					const response = await AuthService.openAccount(details);
					this.resetForm();
					this.msg = response.msg;
					this.isloading = false;
				} catch (error) {
					this.msg = error.response.data.msg;
					this.isloading = false;
					/*this.$router.push('accounts');*/
				}
			},
			resetForm() {
				this.nickname = "";
				this.msg = "";
				requestAnimationFrame(() => {
					this.$refs.observer.reset();
				});
			},
		},
		mounted() {
			this.getAllAccountCurrencies();
		},
	};
</script>
