<template>
	<div class="col-lg-4 offset-lg-4">
		<h3 class="title-bold text-left">
			{{ $t("businessSignup.tellUsAboutYourBusiness") }}
		</h3>
		<h5 class="title-bold text-left">
			{{ $t("businessSignup.thisWillHelpToTailorYourExperience") }}
		</h5>
		<hr />

		<div class="background-outline">
			<router-link
				class="btn btn-outline btn-block text-left"
				:to="{ path: 'sign-up-business/0' }"
				>{{ $t("businessSignup.companyInIncorporation") }}</router-link
			>
			<p class="forgot-password text-left">
				{{ $t("businessSignup.companyIsInIncorporation") }}
			</p>
			<hr />
			<router-link
				class="btn btn-outline btn-block text-left"
				:to="{ path: 'sign-up-business/1' }"
				>{{ $t("businessSignup.youAreAFreelancer") }}</router-link
			>
			<br />
			<router-link
				class="btn btn-outline btn-block text-left"
				:to="{ path: 'sign-up-business/2' }"
				>{{ $t("businessSignup.youHaveUpTo20Employees") }}</router-link
			>
			<br />
			<router-link
				class="btn btn-outline btn-block text-left"
				:to="{ path: 'sign-up-business/3' }"
				>{{ $t("businessSignup.youHaveMoreThan20Employees") }}</router-link
			>
			<br />
			<router-link
				class="btn btn-outline btn-block text-left"
				:to="{ path: 'sign-up-business/4' }"
				>{{ $t("businessSignup.youManageAnAssociation") }}</router-link
			>
		</div>
		<!-- </div> -->
		<hr />
	</div>
</template>

<script>
	import AuthService from "@/services/AuthService.js";

	export default {
		name: "RegistrationType",

		/*props: {
    codeLang: {
      type: String,
      default: "en",
    },
  },*/
		//this.codeLang
		created() {
			this.codeLang = this.$i18n.locale;
		},
	};
</script>
