var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.editTransaction)},"reset":function($event){$event.preventDefault();return reset($event)}}},[_c('div',{staticClass:"col-md-4 offset-md-4 text-left"},[_c('h2',{staticClass:"title-bold"},[_vm._v(" "+_vm._s(_vm.$t("editTransaction.editTransaction"))+" "+_vm._s(_vm.transaction.id)+" ")]),_c('hr'),_c('div',{staticClass:"background-outline"},[_c('div',{staticClass:"background-modifier"},[_c('label',{staticClass:"text-left small"},[_vm._v(_vm._s(_vm.$t("editTransaction.debitAccountId")))]),_c('ValidationProvider',{attrs:{"rules":"required|integer","name":"debitAccounId"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"debitAccountIdInput","description":""}},[_c('b-form-input',{attrs:{"type":"text","state":errors[0] ? false : valid ? true : null,"placeholder":_vm.$t('editTransaction.debitAccountId')},model:{value:(_vm.transaction.debitAccountId),callback:function ($$v) {_vm.$set(_vm.transaction, "debitAccountId", $$v)},expression:"transaction.debitAccountId"}}),_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('label',{staticClass:"text-left small"},[_vm._v(_vm._s(_vm.$t("editTransaction.creditAccountId")))]),_c('ValidationProvider',{attrs:{"rules":"required|integer","name":"creditAccounId"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"creditAccountIdInput","description":""}},[_c('b-form-input',{attrs:{"type":"text","state":errors[0] ? false : valid ? true : null,"placeholder":_vm.$t('editTransaction.creditAccountId')},model:{value:(_vm.transaction.creditAccountId),callback:function ($$v) {_vm.$set(_vm.transaction, "creditAccountId", $$v)},expression:"transaction.creditAccountId"}}),_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('br'),_c('div',{staticClass:"background-modifier"},[_c('label',{staticClass:"text-left small"},[_vm._v(_vm._s(_vm.$t("editTransaction.amount")))]),_c('ValidationProvider',{attrs:{"rules":"required|double|max_value:1000000|min_value:0.01","name":"amount"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"amountInput","description":""}},[_c('b-form-input',{attrs:{"type":"text","state":errors[0] ? false : valid ? true : null,"placeholder":_vm.$t('editTransaction.amount')},model:{value:(_vm.transaction.amount),callback:function ($$v) {_vm.$set(_vm.transaction, "amount", $$v)},expression:"transaction.amount"}}),_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('br'),_c('div',{staticClass:"background-modifier"},[_c('label',{staticClass:"text-left small"},[_vm._v(_vm._s(_vm.$t("editTransaction.message")))]),_c('ValidationProvider',{attrs:{"rules":"min:1|max:50","name":"message"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"messageInput","description":""}},[_c('b-form-input',{attrs:{"type":"text","state":errors[0] ? false : valid ? true : null,"placeholder":_vm.$t('editTransaction.message')},model:{value:(_vm.transaction.message),callback:function ($$v) {_vm.$set(_vm.transaction, "message", $$v)},expression:"transaction.message"}}),_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('br'),_c('b-button',{staticClass:"btn btn-info text-light btn-block",attrs:{"type":"submit"},on:{"click":_vm.isloading}},[_vm._v(_vm._s(_vm.$t("editTransaction.submit"))),(_vm.isloading)?_c('b-spinner',{staticClass:"ml-auto",attrs:{"variant":_vm.light}}):_vm._e()],1),(_vm.msg && _vm.$bvToast.show('notification-toast'))?_c('div'):_vm._e(),_c('div',{staticClass:"action"},[_c('br'),_c('b-toast',{attrs:{"id":"notification-toast","variant":"secondary","static":"","no-auto-hide":""},scopedSlots:_vm._u([{key:"toast-title",fn:function(){return [_c('div',{staticClass:"d-flex flex-grow-1 align-items-baseline"},[_c('b-img',{staticClass:"logo mb-2",attrs:{"src":require('/public/images/logo.png'),"alt":"","width":"12","height":"12"}}),_c('strong',{staticClass:"mr-auto"},[_vm._v(_vm._s(_vm.$t("notification")))]),_c('small',{staticClass:"text-muted mr-2"})],1)]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(_vm.msg)+" ")])],1)],1)])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }