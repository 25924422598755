<template>
	<div v-if="getUserId() === beneficiary.userId">
		<div class="col-lg-4 offset-lg-4 text-left">
			<div class="">
				<h2 class="title-bold">
					{{ beneficiary.alias }}
				</h2>
				<hr />
				<div class="background-modifier">
					<div class="product-details">
						<label class="small">{{ $t("beneficiary.id") }}</label>
						<p class="text-left">
							{{ beneficiary.id }}
						</p>
						<label class="small">{{
							$t("beneficiary.beneficiaryAccount")
						}}</label>
						<p class="text-left">
							({{ beneficiary.beneficiaryAccount }})
							{{
								beneficiary.beneficiaryIBAN
									.toString()
									.replace(/\w{4}(?=.)/g, "$& ")
							}}
						</p>
						<label class="small">{{ $t("beneficiary.beneficiaryName") }}</label>
						<p class="text-left">
							{{ beneficiary.beneficiaryName }}
						</p>
						<label class="small">{{ $t("beneficiary.alias") }}</label>
						<p class="text-left">
							{{ beneficiary.alias }}
						</p>
						<label class="small">{{ $t("beneficiary.created") }}</label>
						<p class="text-left">
							{{ $d(Date.parse(beneficiary.created), "long") }}
						</p>
					</div>
					<div class="text-right">
						<router-link
							:to="{
								name: 'edit-beneficiary-details',
								params: { id: beneficiary.id },
							}"
							class="btn btn-outline mx-1 "
							>{{ $t("beneficiary.edit") }}</router-link
						>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div v-else>
		<h3>{{ $t("unauthorized") }}</h3>
	</div>
</template>

<script>
	import AuthService from "@/services/AuthService.js";

	export default {
		name: "Beneficiary",
		data() {
			return {
				beneficiary: "",
			};
		},
		async created() {
			if (!this.$store.getters.isLoggedIn) {
				this.$router.push("login");
			}
		},
		methods: {
			getUserId() {
				const userId = this.$store.getters.getUser.id;
				return userId;
			},
			async getBeneficiary(id) {
				try {
					const id = this.$route.params.id;
					const response = await AuthService.getBeneficiary(id);
					this.beneficiary = response;
					console.log(response);
				} catch (error) {
					console.log(error);
				}
			},
		},
		mounted() {
			this.getUserId();
			this.getBeneficiary();
		},
	};
</script>
