<template>
	<ValidationObserver ref="observer" v-slot="{ handleSubmit, reset }">
		<b-form
			@submit.prevent="handleSubmit(editCustomerDetails)"
			@reset.prevent="reset"
		>
			<div class="col-lg-4 offset-lg-4 text-left">
				<h3 class="title-bold" v-if="customer.customerType == 2">
					{{ customer.customerName }}
				</h3>
				<h3 class="title-bold" v-if="customer.customerType == 1">
					{{ customer.firstname }} {{ customer.lastname }}
				</h3>
				<hr />
				<p class="open-account text-right">
					<router-link
						class="btn btn-outline"
						:to="{ name: 'edit-customer-details', params: { id: customer.id } }"
						>{{ $t("clientsCustomer.editCustomer") }}</router-link
					>
				</p>
				<br />

				<div class="background-modifier text-left">
					<div class="product-details">
						<label class="small">{{
							$t("clientsCustomer.customerType")
						}}</label>
						<p class="" v-if="customer.customerType == 1">
							{{ $t("clientsCustomer.naturalPerson") }}
						</p>
						<p class="" v-if="customer.customerType == 2">
							{{ $t("clientsCustomer.legalPerson") }}
						</p>
						<label class="small">{{
							$t("clientsCustomer.customerName")
						}}</label>
						<p class="" v-if="customer.customerType == 2">
							{{ customer.customerName }}
						</p>

						<p class="" v-if="customer.customerType == 1">
							{{ customer.firstname }}
							{{ customer.lastname }}
						</p>
						<label class="small">{{
							$t("clientsCustomer.addressLine1")
						}}</label>
						<p class="">
							{{ customer.addressLine1 }}
						</p>
						<label class="small">{{
							$t("clientsCustomer.addressLine2")
						}}</label>
						<p class="">
							{{ customer.addressLine2 }}
						</p>
						<label class="small">{{ $t("clientsCustomer.city") }}</label>
						<p class="">{{ customer.city }}</p>
						<label class="small">{{ $t("clientsCustomer.zip") }}</label>
						<p class="">{{ customer.zip }}</p>
						<label class="small">{{ $t("clientsCustomer.country") }}</label>
						<p class="">
							{{ customer.country }}
						</p>
					</div>
				</div>
				<br />
				<div class="background-modifier">
					<div class="product-details">
						<label class="small">{{ $t("clientsCustomer.phoneNumber") }}</label>
						<p class="">
							{{ customer.phoneNumber }}
						</p>
						<label class="small">{{ $t("clientsCustomer.email") }}</label>
						<p class="">
							{{ customer.email }}
						</p>
						<label v-if="customer.customerType == 1" class="small">{{
							$t("clientsCustomer.dob")
						}}</label>
						<p class="" v-if="customer.customerType == 1">
							{{ $d(Date.parse(customer.dob), "short") }}
						</p>
						<label v-if="customer.customerType == 2" class="small">{{
							$t("clientsCustomer.dateOfIncorporation")
						}}</label>
						<p class="" v-if="customer.customerType == 2">
							{{ $d(Date.parse(customer.dob), "short") }}
						</p>
					</div>
				</div>

				<!-- <hr /> -->

				<!-- disabled-->
				<!-- <div v-if="status == '1'">
        <b-button type="submit" class="btn btn-info text-light btn-block">{{
          $t("clientsCustomer.submit")
        }}</b-button> -->
				<!-- <p class="small">{{ $t("clientsCustomer.notAvailable") }}</p> -->
				<!-- </div> -->
				<!-- <hr />
        <b-button
          type="reset"
          class="btn btn-alert text-light btn-block"
          @click="resetForm()"
          >{{ $t("reset") }}</b-button
        > -->

				<hr />
				<div v-if="msg && $bvToast.show('notification-toast')"></div>
				<div class="action">
					<b-toast
						id="notification-toast"
						variant="secondary"
						static
						no-auto-hide
					>
						<template #toast-title>
							<div class="d-flex flex-grow-1 align-items-baseline">
								<b-img
									class="logo mb-2"
									:src="require('/public/images/logo.png')"
									alt=""
									width="12"
									height="12"
								></b-img>
								<strong class="mr-auto">{{ $t("notification") }}</strong>
								<small class="text-muted mr-2"></small>
							</div>
						</template>
						{{ msg }}
					</b-toast>
				</div>
			</div>
		</b-form>
	</ValidationObserver>
</template>

<script>
	import { ValidationObserver, ValidationProvider } from "vee-validate";
	import AuthService from "@/services/AuthService.js";
	var countryNames = require("i18n-iso-countries");
	export default {
		name: "AddBusinessRole",
		components: {
			ValidationObserver,
			ValidationProvider,
		},
		/*props: {
      codeLang: {
        type: String,
        default: "en",
      },
    },*/
		//this.codeLang
		async created() {
			if (!this.$store.getters.isLoggedIn) {
				this.$router.push("login");
			}
			if (
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 3 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 15 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 14
			) {
				this.$router.push("overview");
			}
			this.role = this.$store.getters.getUser.role;
			// this.username = this.$store.getters.getUser.username;
			// this.firstname = this.$store.getters.getUser.firstname;
			// this.newRole = this.$route.params.reference;
			let lang = this.$i18n.locale;
			this.codeLang = this.$i18n.locale;
			this.countryNames.registerLocale(
				require(`i18n-iso-countries/langs/${this.codeLang}.json`)
			);
		},
		watch: {
			codeLang(newVal, oldVal) {
				this.countryNames.registerLocale(
					require(`i18n-iso-countries/langs/${newVal}.json`)
				);
			},
		},
		data() {
			const now = new Date();
			const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
			// 15th two months prior
			const minDate = new Date(today);
			minDate.setYear(minDate.getFullYear() - 300);
			const maxDate = new Date(today);
			maxDate.setYear(maxDate.getFullYear() - 0);
			// minDate.setMonth(minDate.getMonth() - 2);
			// minDate.setDate(15);
			// 15th in two months

			return {
				countries: [],
				countryNames: countryNames,
				customer: "",
				// role: "",
				// selected: 1,
				/*  username: "", */
				// email: "",
				// name: "",
				// firstname: "",
				// lastname: "",
				/*  password: "",
      password_repeat: "", */
				msg: "",
				/*  pin: "", */
				// dob: maxDate.toISOString().slice(0, 10),
				// addressLine1: "",
				// addressLine2: "",
				// city: "",
				// zip: "",
				// country: "",
				// prefix: "",
				// mobile: "",
				// value: "",
				min: minDate,
				max: maxDate,
				// status: 0,
				// internalReference: "",
			};
		},

		methods: {
			async editCustomerDetails() {
				const id = this.$route.params.id;
				try {
					const credentials = {
						userId: this.$store.getters.getUser.id,
						lang: this.$i18n.locale,
						email: this.customer.email,
						internalReference: this.customer.internalReference,
						customerName: this.customer.customerName,
						firstname: this.customer.firstname,
						lastname: this.customer.lastname,
						dob: this.customer.dob,
						addressLine1: this.customer.addressLine1,
						addressLine2: this.customer.addressLine2,
						city: this.customer.city,
						zip: this.customer.zip,
						country: this.customer.country,
						phoneNumber: this.customer.phoneNumber,
						customerStatus: this.customer.customerStatus,
						customerType: this.customer.customerType,
					};
					//let reference = this.$route.params.reference;
					const response = await AuthService.editCustomerDetails(
						id,
						credentials
					);
					this.resetForm();
					this.msg = response.msg;
				} catch (error) {
					this.msg = error.response.data.msg;
					/*this.$router.push('home');*/
				}
			},
			resetForm() {
				// this.role = ""; /*NEW*/
				// /* this.username = ""; */
				// this.name = "";
				// this.firstname = "";
				// (this.lastname = ""), (this.email = "");
				// /*  this.password = "";
				// this.password_repeat = ""; */
				// this.msg = "";
				// /* this.pin = ""; */
				// this.dob = "";
				// this.addressLine1 = "";
				// this.addressLine2 = "";
				// this.city = "";
				// this.zip = "";
				// this.country = "";
				// this.prefix = "";
				// this.mobile = "";
				// this.nationality = ""; /*NEW*/
				// this.ownership = ""; /*NEW*/
				// this.votes = ""; /*NEW*/
				// this.status = 0; /*NEW*/
				// this.selected = 1;
				requestAnimationFrame(() => {
					this.$refs.observer.reset();
				});
			},
			async getCountries() {
				try {
					const response = await AuthService.getCountries();
					this.countries = response;
					console.log(response);
				} catch (error) {
					console.log(error);
				}
			},
			async getCustomerDetails() {
				try {
					const id = this.$route.params.id;
					const response = await AuthService.getCustomerDetails(id);
					this.customer = response;
					console.log(response);
				} catch (error) {
					console.log(error);
				}
			},
		},
		mounted() {
			this.getCountries();
			this.getCustomerDetails();
		},
	};
</script>
