<template>
	<ValidationObserver ref="observer" v-slot="{ handleSubmit, reset }">
		<b-form
			@submit.prevent="handleSubmit(addNewProduct)"
			@reset.prevent="reset"
		>
			<div class="col-lg-4 offset-lg-4 text-left">
				<h2 class="title-bold">
					{{ $t("customerProduct.addProduct") }}
				</h2>
				<hr />
				<!-- {{ this.$route.params.id }} -->
				<!-- <h5 class="text-left text-bold">
          {{ $t("customerProduct.insertTheDetails") }}
        </h5>
        <hr /> -->
				<!-- <p class="open-account text-right">
          <router-link class="btn btn-outline" :to="{ name: 'add-customer' }">{{
            $t("customerProduct.customers")
          }}</router-link>
        </p> -->
				<div class="background-outline">
					<div class="background-modifier text-left">
						<label class="small">{{ $t("customerProduct.productName") }}</label>
						<ValidationProvider
							rules="min:1|max:50"
							name="internalId"
							v-slot="{ valid, errors }"
						>
							<b-form-group label-for="productNameInput" description="">
								<b-form-input
									type="text"
									v-model="productName"
									:state="errors[0] ? false : valid ? true : null"
									:placeholder="$t('customerProduct.productName')"
								></b-form-input>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>

						<label class="small">{{
							$t("customerProduct.productDescription")
						}}</label>
						<ValidationProvider
							rules="min:1|max:250"
							name="productDescription"
							v-slot="{ valid, errors }"
						>
							<b-form-group label-for="productDescriptionInput" description="">
								<b-form-input
									type="text"
									v-model="productDescription"
									:state="errors[0] ? false : valid ? true : null"
									:placeholder="$t('customerProduct.productDescription')"
								></b-form-input>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>

						<label class="small">{{
							$t("customerProduct.productUnitPriceGross")
						}}</label>
						<ValidationProvider
							rules="required|double|min_value:0.01"
							name="productUnitPriceGross"
							v-slot="{ valid, errors }"
						>
							<b-form-group
								label-for="productUnitPriceGrossInput"
								description=""
							>
								<b-form-input
									@input="calculateTotalNet"
									type="text"
									v-model="productUnitPriceGross"
									:state="errors[0] ? false : valid ? true : null"
									:placeholder="$t('customerProduct.productUnitPriceGross')"
								></b-form-input>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>

						<label class="small"
							>{{ $t("customerProduct.discountRate") }} (%)</label
						>
						<ValidationProvider
							rules="required|double|min_value:0.00"
							name="discountRate"
							v-slot="{ valid, errors }"
						>
							<b-form-group label-for="discountRateInput" description="">
								<b-form-input
									@input="calculateTotalNet"
									type="text"
									v-model="discountRate"
									:state="errors[0] ? false : valid ? true : null"
									:placeholder="$t('customerProduct.discountRate')"
								></b-form-input>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>

						<label class="small">{{
							$t("customerProduct.productUnitPriceNet")
						}}</label>

						<p>{{ $n(totalNetResult, "decimal") }}</p>

						<br />
					</div>

					<hr />

					<!-- disabled-->
					<!-- <div v-if="status == 'accepted'"> -->
					<b-button
						type="submit"
						@click="isloading"
						class="btn btn-info text-light btn-block"
						>{{ $t("customerProduct.submit")
						}}<b-spinner
							v-if="isloading"
							class="ml-auto"
							:variant="light"
						></b-spinner
					></b-button>
					<!--   <p class="small">{{ $t("businessSignup.notAvailable") }}</p> -->
					<!-- </div> -->
					<hr />
					<b-button
						type="reset"
						class="btn btn-alert text-light btn-block"
						@click="resetForm()"
						>{{ $t("reset") }}</b-button
					>

					<div v-if="msg && $bvToast.show('notification-toast')"></div>
					<div class="action">
						<br />
						<b-toast
							id="notification-toast"
							variant="secondary"
							static
							no-auto-hide
						>
							<template #toast-title>
								<div class="d-flex flex-grow-1 align-items-baseline">
									<b-img
										class="logo mb-2"
										:src="require('/public/images/logo.png')"
										alt=""
										width="12"
										height="12"
									></b-img>
									<strong class="mr-auto">{{ $t("notification") }}</strong>
									<small class="text-muted mr-2"></small>
								</div>
							</template>
							{{ msg }}
						</b-toast>
					</div>
				</div>
			</div>
		</b-form>
	</ValidationObserver>
</template>

<script>
	import { ValidationObserver, ValidationProvider } from "vee-validate";
	import AuthService from "@/services/AuthService.js";
	// var countryNames = require("i18n-iso-countries");
	export default {
		name: "AddNewProduct",
		components: {
			ValidationObserver,
			ValidationProvider,
		},
		/*props: {
        codeLang: {
          type: String,
          default: "en",
        },
      },*/
		//this.codeLang
		created() {
			this.codeLang = this.$i18n.locale;
			// this.countryNames.registerLocale(
			//   require(`i18n-iso-countries/langs/${this.codeLang}.json`)
			// );
		},
		watch: {
			// codeLang(newVal, oldVal) {
			//   this.countryNames.registerLocale(
			//     require(`i18n-iso-countries/langs/${newVal}.json`)
			//   );
			// },
		},
		data() {
			const now = new Date();
			const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
			// 15th two months prior
			const minDate = new Date(today);
			minDate.setYear(minDate.getFullYear() - 0);
			const maxDate = new Date(today);
			maxDate.setYear(maxDate.getFullYear() + 2);
			// minDate.setMonth(minDate.getMonth() - 2);
			// minDate.setDate(15);
			// 15th in two months

			return {
				product: "",
				msg: "",
				productName: "",
				productDescription: "",

				productUnitPriceGross: 0.0,
				discountRate: 0.0,
				productUnitPriceNet: 0.0,
				productStatus: 1,
				min: minDate,
				max: maxDate,
				isloading: false,
				//status: "not_accepted",
			};
		},
		async created() {
			if (!this.$store.getters.isLoggedIn) {
				this.$router.push("login");
			}
			if (
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 3 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 15 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 14 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 13 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 12
			) {
				this.$router.push("overview");
			}
			let lang = this.$i18n.locale;
			this.role = this.$store.getters.getUser.role;
			// this.username = this.$store.getters.getUser.username;
			// this.firstname = this.$store.getters.getUser.firstname;

			//this.getOfferById();
		},
		methods: {
			calculateTotalNet() {
				this.totalNetResult =
					this.productUnitPriceGross -
					(this.discountRate / 100) * this.productUnitPriceGross;
			},
			// calculateVATAmount() {
			//   this.vatAmountResult = (this.totalNetResult * this.vatRate) / 100;
			//   return this.vatAmountResult;
			// },
			// calculateTotalInclVAT() {
			//   this.totalIncVATResult = this.totalNetResult - this.vatAmountResult;
			//   return this.totalIncVATResult;
			// },

			async addNewProduct() {
				try {
					const details = {
						userId: this.$store.getters.getUser.id,
						lang: this.$i18n.locale,
						//id: this.$route.params.id,
						productName: this.productName,
						productDescription: this.productDescription,
						productUnitPriceGross: this.productUnitPriceGross,
						discountRate: this.discountRate,
						productUnitPriceNet: this.totalNetResult,
						productStatus: this.productStatus,
					};
					this.isloading = true;
					const response = await AuthService.addNewProduct(details);
					// this.resetForm();
					this.msg = response.msg;
					this.isloading = false;
					console.log(response.msg);
				} catch (error) {
					this.msg = error.response.data.msg;
					this.isloading = false;
					//console.log(error);
				}
			},

			resetForm() {
				this.email = "";
				this.productName = "";
				this.productDescription = "";
				this.productUnitPriceGross = 0.0;
				this.discountRate = 0.0;
				this.productUnitPriceNet = 0.0;
				this.productStatus = 1;
				this.msg = "";
				this.totalNetResult = 0.0;
				requestAnimationFrame(() => {
					this.$refs.observer.reset();
				});
			},
			// async getCountries() {
			//   try {
			//     const response = await AuthService.getCountries();
			//     this.countries = response;
			//     console.log(response);
			//   } catch (error) {
			//     console.log(error);
			//   }
			// },
			async getClientsCurrentAccounts() {
				try {
					const details = {
						id: this.$store.getters.getUser.id,
					};
					const response = await AuthService.getClientsCurrentAccounts(
						details.id
					);
					this.accounts = response;
					console.log(response);
				} catch (error) {
					console.log(error);
				}
			},
		},
		mounted() {
			// this.getCountries();
			this.getClientsCurrentAccounts();
			//  this.getUserId();
		},
	};
</script>
