<template>
	<ValidationObserver ref="observer" v-slot="{ handleSubmit, reset }">
		<b-form
			@submit.prevent="handleSubmit(editBusinessUser)"
			@reset.prevent="reset"
		>
			<div class="col-md-4 offset-md-4 text-left">
				<h2 class="title-bold">
					{{ $t("manageUser.editUser") }} {{ user.id }}
				</h2>
				<hr />
				<!-- <div class="background-modifier">
          <p class="text-left">{{ $t("manageUser.username") }}</p>
          <ValidationProvider
            rules="required|alpha_dash|min:3|max:50"
            name="username"
            v-slot="{ valid, errors }"
          >
            <b-form-group label-for="usernameInput" description="">
              <b-form-input
                type="text"
                v-model="user.username"
                :state="errors[0] ? false : valid ? true : null"
                :placeholder="$t('manageUser.username')"
              ></b-form-input>
              <b-form-invalid-feedback id="inputLiveFeedback">{{
                errors[0]
              }}</b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
          <p class="text-left">{{ $t("manageUser.email") }}</p>
          <ValidationProvider
            rules="required|email"
            name="email"
            v-slot="{ valid, errors }"
          >
            <b-form-group label-for="emailInput" description="">
              <b-form-input
                type="email"
                v-model="user.email"
                :state="errors[0] ? false : valid ? true : null"
                :placeholder="$t('manageUser.email')"
              ></b-form-input>
              <b-form-invalid-feedback id="inputLiveFeedback">{{
                errors[0]
              }}</b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </div>
        <br /> -->
				<div class="background-outline">
					<div class="background-modifier">
						<label class="text-left small">{{
							$t("manageUser.firstname")
						}}</label>
						<ValidationProvider
							rules="required|alpha_spaces|min:2|max:50"
							name="firstname"
							v-slot="{ valid, errors }"
						>
							<b-form-group label-for="firstnameInput" description="">
								<b-form-input
									type="text"
									v-model="user.firstname"
									:state="errors[0] ? false : valid ? true : null"
									:placeholder="$t('manageUser.firstname')"
								></b-form-input>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>

						<label class="text-left small">{{
							$t("manageUser.lastname")
						}}</label>
						<ValidationProvider
							rules="required|alpha_spaces|min:2|max:50"
							name="lastname"
							v-slot="{ valid, errors }"
						>
							<b-form-group label-for="lastnameInput" description="">
								<b-form-input
									type="text"
									v-model="user.lastname"
									:state="errors[0] ? false : valid ? true : null"
									:placeholder="$t('manageUser.lastname')"
								></b-form-input>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>
					</div>
					<br />

					<div class="background-modifier">
						<h4 class="title-bold">{{ $t("manageUser.authorizations") }}</h4>
						<hr />
						<b-form-group label="" v-slot="{ vAuth }">
							<b-form-radio
								v-model="user.role"
								:aria-describedby="vAuth"
								name="radio_group_0"
								value="11"
								>{{ $t("role.accountant") }}:
								{{ $t("manageUser.viewingRights") }}
							</b-form-radio>
							<br />
							<b-form-radio
								v-model="user.role"
								:aria-describedby="vAuth"
								name="radio_group_0"
								value="12"
								>{{ $t("role.employee") }}:
								{{ $t("manageUser.viewingAndPreparationRights") }}</b-form-radio
							>
							<br />
							<b-form-radio
								v-model="user.role"
								:aria-describedby="vAuth"
								name="radio_group_0"
								value="13"
								>{{ $t("role.manager") }}:
								{{
									$t("manageUser.viewingPreparationAndAuthorizationRights")
								}}</b-form-radio
							>
							<br />
							<b-form-radio
								v-model="user.role"
								:aria-describedby="vAuth"
								name="radio_group_0"
								value="14"
								>{{ $t("role.admin") }}:
								{{
									$t(
										"manageUser.viewingPreparationAuthorizationAndContractSigningRights"
									)
								}}</b-form-radio
							>
							<br />
							<b-form-radio
								v-model="user.role"
								:aria-describedby="vAuth"
								name="radio_group_0"
								value="15"
								>{{ $t("manageUser.masterUser") }}</b-form-radio
							>
						</b-form-group>
					</div>

					<br />
					<div class="background-modifier">
						<h4 class="title-bold">{{ $t("manageUser.userStatus") }}</h4>
						<hr />
						<b-form-group label="" v-slot="{ vActive }">
							<b-form-radio
								v-model="user.active"
								:aria-describedby="vActive"
								name="radio_group_1"
								value="1"
								>{{ $t("manageUser.active") }}</b-form-radio
							>
							<br />
							<b-form-radio
								v-model="user.active"
								:aria-describedby="vActive"
								name="radio_group_1"
								value="0"
								>{{ $t("manageUser.blocked") }}</b-form-radio
							>
						</b-form-group>
					</div>

					<!-- <div class="background-modifier">
          <p class="text-left">{{ $t("manageUser.verificationStatus") }}</p>
          <b-form-group label="" v-slot="{ vStatus }">
            <b-form-radio
              v-model="user.verificationStatus"
              :aria-describedby="vStatus"
              name="radio_group_1"
              value="1"
              >{{ $t("manageUser.pending") }}</b-form-radio
            >
            <b-form-radio
              v-model="user.verificationStatus"
              :aria-describedby="vStatus"
              name="radio_group_1"
              value="3"
              >{{ $t("manageUser.verified") }}</b-form-radio
            >
          </b-form-group>
        </div> -->
					<hr />
					<b-button
						type="submit"
						@click="isloading"
						class="btn btn-info text-light btn-block"
						>{{ $t("manageUser.submit")
						}}<b-spinner
							v-if="isloading"
							class="ml-auto"
							:variant="light"
						></b-spinner
					></b-button>
					<!--   <hr/>
     <b-button type="reset" class="btn btn-alert text-light btn-block" @click="resetForm()">{{ $t('reset') }}</b-button> -->

					<div v-if="msg && $bvToast.show('notification-toast')"></div>
					<div class="action">
						<br />
						<b-toast
							id="notification-toast"
							variant="secondary"
							static
							no-auto-hide
						>
							<template #toast-title>
								<div class="d-flex flex-grow-1 align-items-baseline">
									<b-img
										class="logo mb-2"
										:src="require('/public/images/logo.png')"
										alt=""
										width="12"
										height="12"
									></b-img>
									<strong class="mr-auto">{{ $t("notification") }}</strong>
									<small class="text-muted mr-2"></small>
								</div>
							</template>
							{{ msg }}
						</b-toast>
					</div>
				</div>
			</div>
		</b-form>
	</ValidationObserver>
</template>

<script>
	import { ValidationObserver, ValidationProvider } from "vee-validate";
	import AuthService from "@/services/AuthService.js";

	export default {
		name: "EditUserForm",
		components: {
			ValidationObserver,
			ValidationProvider,
		},
		data() {
			return {
				user: "",
				msg: "",
				isloading: false,
			};
		},
		async created() {
			if (!this.$store.getters.isLoggedIn) {
				this.$router.push("login");
			}
			if (
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 3 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 15 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 14
			) {
				this.$router.push("overview");
			}
			let lang = this.$i18n.locale;
			this.role = this.$store.getters.getUser.role;
			this.username = this.$store.getters.getUser.username;
			this.firstname = this.$store.getters.getUser.firstname;
		},
		methods: {
			async getBusinessUser(id) {
				try {
					const id = this.$route.params.id;
					this.isloading = true;
					const response = await AuthService.getBusinessUser(id);
					this.user = response;
					this.isloading = false;
					console.log(response);
				} catch (error) {
					this.isloading = false;
					console.log(error);
				}
			},
			async editBusinessUser() {
				try {
					const id = this.$route.params.id;
					const details = {
						lang: this.$i18n.locale,
						// username: this.user.username,
						// email: this.user.email,
						firstname: this.user.firstname,
						lastname: this.user.lastname,
						role: this.user.role,
						active: this.user.active,

						// verificationStatus: this.user.verificationStatus,
					};
					const response = await AuthService.editBusinessUser(id, details);
					this.msg = response.msg;
					this.getBusinessUser();
					console.log(response.msg);
				} catch (error) {
					console.log(error);
					this.msg = error.response.data.msg;
				}
			},
			resetForm() {
				this.user.username = "";
				this.user.firstname = "";
				this.user.lastname = "";
				this.user.email = "";
				this.msg = "";
				requestAnimationFrame(() => {
					this.$refs.observer.reset();
				});
			},
		},
		mounted() {
			this.getBusinessUser();
		},
	};
</script>
