<template>
	<div class="col-12 offset-0">
		<div class="text-left">
			<div class="col-md-8 offset-md-2">
				<h2 class="title-bold">
					{{ $t("clientsCustomer.customers") }}
				</h2>
				<hr />
				<h5 class="title-bold">
					{{ $t("clientsCustomer.manageCustomers") }}
				</h5>
				<hr />
				<div v-if="role == 3 || role == 15 || role == 14">
					<p class="open-account text-right">
						<router-link
							class="btn btn-outline"
							:to="{ name: 'add-customer' }"
							>{{ $t("clientsCustomer.addNewCustomer") }}</router-link
						>
					</p>
					<br />
				</div>

				<div v-if="msg && $bvToast.show('notification-toast')"></div>
				<div class="action">
					<b-toast
						id="notification-toast"
						variant="secondary"
						static
						no-auto-hide
					>
						<template #toast-title>
							<div class="d-flex flex-grow-1 align-items-baseline">
								<b-img
									class="logo mb-2"
									:src="require('/public/images/logo.png')"
									alt=""
									width="12"
									height="12"
								></b-img>
								<strong class="mr-auto">{{ $t("notification") }}</strong>
								<small class="text-muted mr-2"></small>
							</div>
						</template>
						{{ msg }}
						<br />
					</b-toast>
				</div>

				<div class="background-modifier">
					<div class="input-group mb-3">
						<input
							type="text"
							class="form-control"
							:placeholder="$t('clientsCustomer.customerName')"
							v-model="searchTitle"
						/>
						<div class="input-group-append">
							<button
								class="btn btn-outline"
								type="button"
								@click="
									page = 1;
									getClientsCustomers();
								"
							>
								{{ $t("search") }}
							</button>
						</div>
					</div>

					<div v-if="isloading" class="text-center">
						<b-spinner
							style="width: 3rem; height: 3rem;"
							class="ml-auto text-center"
							:variant="secondary"
						></b-spinner>
					</div>
					<div v-else-if="customers.length > 0">
						<div class="table-wrap">
							<table class="table mt-2" id="customers">
								<thead>
									<tr>
										<th class="text-left small">
											{{ $t("clientsCustomer.id") }}
										</th>
										<th class="text-left small">
											{{ $t("clientsCustomer.customerName") }}
										</th>
										<th class="text-right small">
											{{ $t("clientsCustomer.created") }}
										</th>
										<th class="text-centered small">
											{{ $t("clientsCustomer.actions") }}
										</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="customer in customers" :key="customer.id">
										<td class="text-left">
											{{ customer.id }}
										</td>

										<td v-if="customer.customerType == 2" class="text-left">
											{{ customer.customerName }}
										</td>

										<td v-if="customer.customerType == 1" class="text-left">
											{{ customer.firstname }} {{ customer.lastname }}
										</td>

										<td class="text-right">
											{{ $d(Date.parse(customer.created), "short") }}
										</td>
										<td class="text-right">
											<router-link
												:to="{
													name: 'customer-details',
													params: { id: customer.id },
												}"
												class="btn btn-outline mx-1 "
												>{{ $t("clientsCustomer.view") }}</router-link
											>
											<a
												class="btn btn-danger mx-1 "
												@click="deleteCustomer(customer.id)"
												>{{ $t("clientsCustomer.delete") }}</a
											>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div v-if="customers.length >= 10">
							<div class="row">
								<div class="col-md-6">
									{{ $t("itemsPerPage") }}
									<select
										class="custom-select"
										style="width: 60px;"
										v-model="pageSize"
										@change="handlePageSizeChange($event)"
									>
										<option v-for="size in pageSizes" :key="size" :value="size">
											{{ size }}
										</option>
									</select>
								</div>
								<div class="col-md-6">
									<b-pagination
										v-model="page"
										:total-rows="count"
										:per-page="pageSize"
										:prev-text="$t('previous')"
										:next-text="$t('next')"
										align="right"
										@change="handlePageChange"
									></b-pagination>
								</div>
							</div>
						</div>
					</div>

					<div v-else>
						{{ $t("clientsCustomer.noCustomers") }}
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import AuthService from "@/services/AuthService.js";

	export default {
		name: "customers",
		data() {
			return {
				customers: [],

				msg: "",
				currentCustomerInvoice: null,

				currentIndex: -1,
				searchTitle: "",
				isloading: false,
				page: 1,
				count: 0,
				pageSize: 10,

				pageSizes: [10, 25, 50],
			};
		},
		async created() {
			if (!this.$store.getters.isLoggedIn) {
				this.$router.push("login");
			}
			if (
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 3 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 15 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 14 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 13 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 12 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 11
			) {
				this.$router.push("overview");
			}
			let lang = this.$i18n.locale;
			this.role = this.$store.getters.getUser.role;
			this.username = this.$store.getters.getUser.username;
			this.firstname = this.$store.getters.getUser.firstname;
			this.baseCurrency = this.$store.getters.getUser.currency;
			// this.getUsersPaymentOrders();
		},
		methods: {
			getRequestParams(searchTitle, page, pageSize) {
				let params = {};

				if (searchTitle) {
					params["title"] = searchTitle;
				}

				if (page) {
					params["page"] = page - 1;
				}

				if (pageSize) {
					params["size"] = pageSize;
				}

				return params;
			},

			async getClientsCustomers() {
				const params = this.getRequestParams(
					this.searchTitle,
					this.page,
					this.pageSize
				);

				try {
					const details = {
						id: this.$store.getters.getUser.id,
					};
					this.isloading = true;
					const response = await AuthService.getClientsCustomers(
						params.page,
						params.size,
						params.title,
						details.id
					);
					const { items, totalItems } = response.data;
					this.customers = items;
					this.count = totalItems;
					this.isloading = false;
					console.log(response.data);
				} catch (error) {
					this.isloading = false;
					console.log(error);
				}
			},

			handlePageChange(value) {
				this.page = value;
				this.getClientsCustomers();
			},

			handlePageSizeChange(event) {
				this.pageSize = event.target.value;
				this.page = 1;
				this.getClientsCustomers();
			},

			async deleteCustomer(id) {
				try {
					const lang = this.$i18n.locale;
					const response = await AuthService.deleteCustomer(id, lang);
					console.log(response);
					this.getClientsCustomers();
					this.msg = response.msg;
					console.log(response.msg);
				} catch (error) {
					console.log(error);
					this.msg = error.response.data.msg;
				}
			},
		},

		mounted() {
			this.getClientsCustomers();
		},
	};
</script>
