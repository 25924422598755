var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.openNewBusinessAccount)},"reset":function($event){$event.preventDefault();return reset($event)}}},[_c('div',{staticClass:"col-lg-4 offset-lg-4"},[_c('h2',{staticClass:"title-bold text-left"},[_vm._v(" "+_vm._s(_vm.$t("createAccount.openAccount"))+" ")]),_c('hr'),_c('h5',{staticClass:"title-bold text-left"},[_vm._v(" "+_vm._s(_vm.$t("createAccount.openNewAccountFor"))+" ")]),_c('hr'),_c('div',{staticClass:"background-outline"},[_c('div',{staticClass:"background-modifier text-left"},[_c('label',{staticClass:"small"},[_vm._v(_vm._s(_vm.$t("createAccount.currency")))]),_c('ValidationProvider',{attrs:{"rules":"required","name":"currency"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"id":"accountCurrencyInputGroup","label-for":"accountCurrencyInput"}},[_c('b-form-select',{attrs:{"id":"accountCurrencyInput","state":errors[0] ? false : valid ? true : null},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":"","disabled":""}},[_vm._v(_vm._s(_vm.$t("createAccount.currency")))])]},proxy:true}],null,true),model:{value:(_vm.currency),callback:function ($$v) {_vm.currency=$$v},expression:"currency"}},_vm._l((_vm.currencies),function(currency){return _c('option',{key:currency.code,domProps:{"value":currency.code}},[_vm._v(_vm._s(currency.code))])}),0),_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('label',{staticClass:"small"},[_vm._v(_vm._s(_vm.$t("createAccount.nickname")))]),_c('ValidationProvider',{attrs:{"rules":"min:2|max:50","name":"nickname"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"lastnameInput","description":_vm.$t('createAccount.nicknameVoluntary')}},[_c('b-form-input',{attrs:{"type":"text","state":errors[0] ? false : valid ? true : null,"placeholder":_vm.$t('createAccount.nickname')},model:{value:(_vm.nickname),callback:function ($$v) {_vm.nickname=$$v},expression:"nickname"}}),_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('br'),_c('b-form-checkbox',{staticClass:"text-left",attrs:{"id":"checkbox-5","name":"checkbox-5","value":"accepted","unchecked-value":"not_accepted"},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}},[_vm._v(" "+_vm._s(_vm.$t("signup.acceptTerms"))+" ")]),(_vm.status == 'accepted')?_c('div',[_c('hr'),_c('b-button',{staticClass:"btn btn-info text-light btn-block",attrs:{"type":"submit"},on:{"click":_vm.isloading}},[_vm._v(_vm._s(_vm.$t("createAccount.submit"))),(_vm.isloading)?_c('b-spinner',{staticClass:"ml-auto",attrs:{"variant":_vm.light}}):_vm._e()],1)],1):_vm._e(),_c('hr'),_c('b-button',{staticClass:"btn btn-alert text-light btn-block",attrs:{"type":"reset"},on:{"click":function($event){return _vm.resetForm()}}},[_vm._v(_vm._s(_vm.$t("reset")))]),(_vm.msg && _vm.$bvToast.show('notification-toast'))?_c('div'):_vm._e(),_c('div',{staticClass:"action"},[_c('br'),_c('b-toast',{attrs:{"id":"notification-toast","variant":"secondary","static":"","no-auto-hide":""},scopedSlots:_vm._u([{key:"toast-title",fn:function(){return [_c('div',{staticClass:"d-flex flex-grow-1 align-items-baseline"},[_c('b-img',{staticClass:"logo mb-2",attrs:{"src":require('/public/images/logo.png'),"alt":"","width":"12","height":"12"}}),_c('strong',{staticClass:"mr-auto"},[_vm._v(_vm._s(_vm.$t("notification")))]),_c('small',{staticClass:"text-muted mr-2"})],1)]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(_vm.msg)+" ")])],1)],1)])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }