<template>
	<div id="app" v-cloak>
		<Nav />
		<router-view />
		<Footer />
		<Breadcrumb />
	</div>
</template>

<script>
	import Nav from "./components/Nav.vue";
	import Footer from "./components/Footer.vue";
	import Breadcrumb from "./components/Breadcrumb.vue";
	import { ValidationProvider, extend } from "vee-validate";
	import { required } from "vee-validate/dist/rules";

	export default {
		name: "App",
		components: {
			Nav,
			Footer,
			ValidationProvider,
			Breadcrumb,
		},
		data: () => ({
			value: "",
		}),
		// watch: {
		// 	$route(to, from) {
		// 		document.title = to.meta.title || "N1 Wealth";
		// 	},
		// },
	};
</script>

<style>
	#app {
		font-family: "DINPro-Regular", sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		/* color: #2c3e50; */
		color: var(--color-text);
		margin-bottom: -24px;
	}
</style>
