<template>
	<ValidationObserver ref="observer" v-slot="{ handleSubmit, reset }">
		<b-form @submit.prevent="handleSubmit(login)" @reset.prevent="reset">
			<div class="col-lg-4 offset-lg-4">
				<div class="text-left">
					<h2 class="title-bold">{{ $t("login.login") }}</h2>
					<hr />
					<div class="background-outline">
						<div class="background-modifier">
							<ValidationProvider
								rules="required|min:3|max:50"
								name="username"
								v-slot="{ valid, errors }"
							>
								<b-form-group label-for="usernameInput" description="">
									<b-form-input
										type="text"
										v-model="username"
										:placeholder="$t('login.username')"
									></b-form-input>
									<!-- :state="errors[0] ? false : valid ? true : null" -->
									<b-form-invalid-feedback id="inputLiveFeedback">{{
										errors[0]
									}}</b-form-invalid-feedback>
								</b-form-group>
							</ValidationProvider>

							<ValidationProvider
								rules="required|min:6|max:30"
								:name="$t('login.password')"
								vid="password"
								v-slot="{ valid, errors }"
							>
								<b-form-group description="">
									<b-form-input
										type="password"
										v-model="password"
										:placeholder="$t('login.password')"
									></b-form-input>
									<!-- :state="errors[0] ? false : valid ? true : null" -->
									<b-form-invalid-feedback id="inputLiveFeedback">{{
										errors[0]
									}}</b-form-invalid-feedback>
								</b-form-group>
							</ValidationProvider>
						</div>
						<hr />

						<b-button
							type="submit"
							@click="isloading"
							class="btn btn-info text-light btn-block"
							><span>{{ $t("login.login") }}</span
							><b-spinner
								v-if="isloading"
								class="ml-auto"
								:variant="light"
							></b-spinner
						></b-button>
						<hr />
						<!-- <b-button
						type="reset"
						class="btn btn-secondary text-light btn-block"
						@click="resetForm()"
						>{{ $t("reset") }}</b-button
					> -->
						<p class="forgot-password text-right">
							<router-link :to="{ name: 'forgot-password' }">{{
								$t("login.forgotPassword")
							}}</router-link>
						</p>

						<p class="forgot-password text-right">
							<router-link :to="{ path: 'business/application-login' }">{{
								$t("login.loginToApplications")
							}}</router-link>
						</p>

						<div v-if="msg && $bvToast.show('notification-toast')"></div>
						<div class="action">
							<br />
							<b-toast
								id="notification-toast"
								variant="secondary"
								static
								no-auto-hide
							>
								<template #toast-title>
									<div class="d-flex flex-grow-1 align-items-baseline">
										<b-img
											class="logo mb-2"
											:src="require('/public/images/logo.png')"
											alt=""
											width="12"
											height="12"
										></b-img>
										<strong class="mr-auto">{{ $t("notification") }}</strong>
										<small class="text-muted mr-2"></small>
									</div>
								</template>
								{{ msg }}
							</b-toast>
						</div>
					</div>
				</div>
			</div>
		</b-form>
	</ValidationObserver>
</template>

<script>
	import AuthService from "@/services/AuthService.js";
	import { ValidationObserver, ValidationProvider } from "vee-validate";

	export default {
		name: "LoginForm",
		components: {
			ValidationObserver,
			ValidationProvider,
		},
		data() {
			return {
				username: "",
				password: "",
				msg: "",
				isloading: false,
			};
		},
		methods: {
			async login() {
				try {
					const credentials = {
						lang: this.$i18n.locale,
						username: this.username,
						password: this.password,
					};
					this.isloading = true;
					const response = await AuthService.login(credentials);

					this.msg = response.msg;
					const token = response.token;
					const user = response.user;
					this.$store.dispatch("login", { token, user });
					//this.isloading = false;
					this.$router.push("overview");
				} catch (error) {
					this.isloading = false;
					this.msg = error.response.data.msg;
				}
			},
			resetForm() {
				this.username = "";
				this.password = "";
				this.msg = "";
				requestAnimationFrame(() => {
					this.$refs.observer.reset();
				});
			},
		},
	};
</script>
