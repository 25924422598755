<template>
	<ValidationObserver ref="observer" v-slot="{ handleSubmit, reset }">
		<b-form
			@submit.prevent="handleSubmit(createNewBusinessUser)"
			@reset.prevent="reset"
		>
			<div class="col-4 offset-md-4 text-left">
				<h2 class="title-bold">{{ $t("createUser.newUser") }}</h2>
				<hr />

				<div class="background-outline">
					<div class="background-modifier">
						<label class="small">{{ $t("createUser.username") }}</label>
						<ValidationProvider
							rules="required|alpha_dash|min:3|max:50"
							name="username"
							v-slot="{ valid, errors }"
						>
							<b-form-group label-for="usernameInput" description="">
								<b-form-input
									type="text"
									v-model="username"
									:state="errors[0] ? false : valid ? true : null"
									:placeholder="$t('createUser.username')"
								></b-form-input>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>

						<label class="small">{{ $t("createUser.email") }}</label>
						<ValidationProvider
							rules="required|email"
							name="email"
							v-slot="{ valid, errors }"
						>
							<b-form-group label-for="emailInput" description="">
								<b-form-input
									type="email"
									v-model="email"
									:state="errors[0] ? false : valid ? true : null"
									:placeholder="$t('createUser.email')"
								></b-form-input>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>
					</div>
					<br />

					<div class="background-modifier">
						<label class="small">{{ $t("createUser.firstname") }}</label>
						<ValidationProvider
							rules="required|alpha_spaces|min:2|max:50"
							name="firstname"
							v-slot="{ valid, errors }"
						>
							<b-form-group label-for="firstnameInput" description="">
								<b-form-input
									type="text"
									v-model="firstname"
									:state="errors[0] ? false : valid ? true : null"
									:placeholder="$t('createUser.firstname')"
								></b-form-input>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>

						<label class="small">{{ $t("createUser.lastname") }}</label>
						<ValidationProvider
							rules="required|alpha_spaces|min:2|max:50"
							name="lastname"
							v-slot="{ valid, errors }"
						>
							<b-form-group label-for="lastnameInput" description="">
								<b-form-input
									type="text"
									v-model="lastname"
									:state="errors[0] ? false : valid ? true : null"
									:placeholder="$t('createUser.lastname')"
								></b-form-input>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>
					</div>
					<br />

					<div class="background-modifier">
						<label class="small">{{ $t("createUser.password") }}</label>
						<ValidationProvider
							rules="required|min:6|max:30"
							name="password"
							vid="password"
							v-slot="{ valid, errors }"
						>
							<b-form-group description="">
								<b-form-input
									type="password"
									v-model="password"
									:state="errors[0] ? false : valid ? true : null"
									:placeholder="$t('createUser.password')"
								></b-form-input>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>

						<label class="small">{{ $t("createUser.repeatPassword") }}</label>
						<ValidationProvider
							rules="required|confirmed:password"
							name="Password confirmation"
							v-slot="{ valid, errors }"
						>
							<b-form-group label="" label-for="repeatPasswordInput1">
								<b-form-input
									type="password"
									v-model="password_repeat"
									:state="errors[0] ? false : valid ? true : null"
									:placeholder="$t('createUser.repeatPassword')"
								></b-form-input>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>
					</div>
					<br />

					<div class="background-modifier">
						<h4 class="title-bold">{{ $t("manageUser.authorizations") }}</h4>
						<hr />
						<b-form-group label="" v-slot="{ vAuth }">
							<b-form-radio
								v-model="role"
								:aria-describedby="vAuth"
								name="radio_group_0"
								value="11"
								>{{ $t("role.accountant") }}:
								{{ $t("manageUser.viewingRights") }}
							</b-form-radio>
							<br />
							<b-form-radio
								v-model="role"
								:aria-describedby="vAuth"
								name="radio_group_0"
								value="12"
								>{{ $t("role.employee") }}:
								{{ $t("manageUser.viewingAndPreparationRights") }}
							</b-form-radio>
							<br />
							<b-form-radio
								v-model="role"
								:aria-describedby="vAuth"
								name="radio_group_0"
								value="13"
								>{{ $t("role.manager") }}:
								{{ $t("manageUser.viewingPreparationAndAuthorizationRights") }}
							</b-form-radio>
							<br />
							<b-form-radio
								v-model="role"
								:aria-describedby="vAuth"
								name="radio_group_0"
								value="14"
								>{{ $t("role.admin") }}:
								{{
									$t(
										"manageUser.viewingPreparationAuthorizationAndContractSigningRights"
									)
								}}</b-form-radio
							>
							<br />
							<b-form-radio
								v-model="role"
								:aria-describedby="vAuth"
								name="radio_group_0"
								value="15"
								>{{ $t("manageUser.masterUser") }}</b-form-radio
							>
						</b-form-group>
					</div>
					<br />

					<b-button
						type="submit"
						@click="isloading"
						class="btn btn-info text-light btn-block"
						>{{ $t("createUser.submit")
						}}<b-spinner
							v-if="isloading"
							class="ml-auto"
							:variant="light"
						></b-spinner
					></b-button>
					<hr />
					<b-button
						type="reset"
						class="btn btn-alert text-light btn-block"
						@click="resetForm()"
						>{{ $t("reset") }}</b-button
					>

					<div v-if="msg && $bvToast.show('notification-toast')"></div>
					<div class="action">
						<br />
						<b-toast
							id="notification-toast"
							variant="secondary"
							static
							no-auto-hide
						>
							<template #toast-title>
								<div class="d-flex flex-grow-1 align-items-baseline">
									<b-img
										class="logo mb-2"
										:src="require('/public/images/logo.png')"
										alt=""
										width="12"
										height="12"
									></b-img>
									<strong class="mr-auto">{{ $t("notification") }}</strong>
									<small class="text-muted mr-2"></small>
								</div>
							</template>
							{{ msg }}
						</b-toast>
					</div>
				</div>
			</div>
		</b-form>
	</ValidationObserver>
</template>

<script>
	import { ValidationObserver, ValidationProvider } from "vee-validate";
	import AuthService from "@/services/AuthService.js";

	export default {
		name: "NewUserForm",
		components: {
			ValidationObserver,
			ValidationProvider,
		},
		data() {
			return {
				businessDetails: "",
				username: "",
				email: "",
				firstname: "",
				lastname: "",
				password: "",
				password_repeat: "",
				role: 11,
				msg: "",
				isloading: false,
			};
		},
		async created() {
			if (!this.$store.getters.isLoggedIn) {
				this.$router.push("login");
			}
			if (
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 3 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 15 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 14
			) {
				this.$router.push("overview");
			}
			let lang = this.$i18n.locale;
			this.role = this.$store.getters.getUser.role;
			// this.username = this.$store.getters.getUser.username;
			// this.firstname = this.$store.getters.getUser.firstname;
		},
		methods: {
			// getUserId() {
			//   const userId = this.$store.getters.getUser.id;
			//   return userId;
			// },
			// async getBusinessDetails() {
			//   try {
			//     const id = this.getUserId(); //this.$store.getters.getUser.id;
			//     const response = await AuthService.getBusinessDetails(id);
			//     this.businessDetails = response;
			//     console.log(response);
			//   } catch (error) {
			//     console.log(error);
			//   }
			// },

			async createNewBusinessUser() {
				try {
					const credentials = {
						lang: this.$i18n.locale,
						id: this.$store.getters.getUser.id,
						username: this.username,
						email: this.email,
						firstname: this.firstname,
						lastname: this.lastname,
						password: this.password,
						password_repeat: this.password_repeat,
						role: this.role,
						memberRank: 1, //this.memberRank,
					};
					this.isloading = true;
					const response = await AuthService.createNewBusinessUser(credentials);
					this.resetForm();
					this.msg = response.msg;
					this.isloading = false;
				} catch (error) {
					this.msg = error.response.data.msg;
					this.isloading = false;
					/*this.$router.push('home');*/
				}
			},
			resetForm() {
				this.username = "";
				this.firstname = "";
				(this.lastname = ""), (this.email = "");
				this.password = "";
				this.password_repeat = "";
				this.msg = "";
				this.role = 11;
				requestAnimationFrame(() => {
					this.$refs.observer.reset();
				});
			},
		},
		mounted() {
			// this.getUserId();
			// this.getBusinessDetails();
		},
	};
</script>
