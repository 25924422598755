import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/website/Home.vue";
import Products from "../views/website/Products.vue";
import Overview from "../views/Overview.vue";
import Accounts from "../views/Accounts.vue";
import Account from "../views/Account.vue";
import Cards from "../views/Cards.vue";
import Card from "../views/Card.vue";
import ModifyAccount from "../views/EditAccount.vue";
import OpenAccount from "../views/OpenAccount.vue";
import OpenFixedTermDeposit from "../views/OpenFixedTermDeposit.vue";
import FixedTermDeposits from "../views/FixedTermDeposits.vue";
import FixedTermDeposit from "../views/FixedTermDeposit.vue";
import Payment from "../views/Payment.vue";
import NewPayment from "../views/NewPayment.vue";
import Profile from "../views/Profile.vue";
import RegistrationType from "../views/RegistrationType.vue";
import SignUp from "../views/SignUp.vue";
import SignUpIndividual from "../views/SignUpIndividual.vue";
import VerifyEmail from "../views/VerifyEmail.vue";
import Login from "../views/Login.vue";
import ForgotPassword from "../views/ForgotPassword.vue";
import ResetPassword from "../views/ResetPassword.vue";
import Dashboard from "../views/admin/Dashboard.vue";
import RouterView from "../views/RouterView.vue";
import PaymentSearch from "../views/PaymentSearch.vue";

import Nip from "../views/Nip.vue";
import ClaimNip from "../views/ClaimNip.vue";
import GenerateQRCode from "../views/GenerateQRCode.vue";

import NewBeneficiary from "../views/NewBeneficiary.vue";
import Beneficiaries from "../views/Beneficiaries.vue";
import Beneficiary from "../views/Beneficiary.vue";
import EditBeneficiary from "../views/EditBeneficiary.vue";
import PaymentOrders from "../views/PaymentOrders.vue";
import PaymentOrder from "../views/PaymentOrder.vue";
import EditPaymentOrder from "../views/EditPaymentOrder.vue";
import StandingOrders from "../views/StandingOrders.vue";
import StandingOrder from "../views/StandingOrder.vue";
import EditStandingOrder from "../views/EditStandingOrder.vue";

import FileUpload from "../components/FileUpload.vue";

import ClientList from "../views/admin/ClientList.vue";

import Applications from "../views/admin/Applications.vue";

import UserList from "../views/admin/UserList.vue";
import UserDetail from "../views/admin/UserDetail.vue";
import NewUser from "../views/admin/NewUser.vue";
import EditUser from "../views/admin/EditUser.vue";
import EditIndividualClient from "../views/admin/EditIndividualClient.vue";
import AccountList from "../views/admin/AccountList.vue";
import AccountDetail from "../views/admin/AccountDetail.vue";
import NewAccount from "../views/admin/NewAccount.vue";
import EditAccount from "../views/admin/EditAccount.vue";
import TransactionList from "../views/admin/TransactionList.vue";
import TransactionDetail from "../views/admin/TransactionDetail.vue";
import NewTransaction from "../views/admin/NewTransaction.vue";
import EditTransaction from "../views/admin/EditTransaction.vue";
import DocumentUpload from "../views/admin/DocumentUpload.vue";
import BusinessClientDetails from "../views/admin/BusinessClientDetails.vue";
import IndividualClientDetails from "../views/admin/IndividualClientDetails.vue";

import BusinessTypeSelection from "../views/business/BusinessTypeSelection.vue";
import SignUpBusiness from "../views/business/SignUpBusiness.vue";
import ApplicationLogin from "../views/business/ApplicationLogin.vue";
import ApplicationOverview from "../views/business/ApplicationOverview.vue";
import AddBusinessDetails from "../views/business/AddBusinessDetails.vue";
import EditBusinessDetails from "../views/business/EditBusinessDetails.vue";
import AddBusinessRole from "../views/business/AddBusinessRole.vue";
import EditBusinessRole from "../views/business/EditBusinessRole.vue";
import BusinessDocumentUpload from "../views/business/BusinessDocumentUpload.vue";

import BusinessDashboard from "../views/business/BusinessDashboard.vue";
import ExtendedPayment from "../views/business/ExtendedPayment.vue";
import InvoicePayment from "../views/business/InvoicePayment.vue";
import PaymentManagement from "../views/business/PaymentManagement.vue";
import PayablesManagement from "../views/business/PayablesManagement.vue";
import ReceivablesManagement from "../views/business/ReceivablesManagement.vue";
import CreateInvoice from "../views/business/CreateInvoice.vue";
import InvoiceDetails from "../views/business/InvoiceDetails.vue";
import EditInvoiceDetails from "../views/business/EditInvoiceDetails.vue";
import InvoiceView from "../views/business/InvoiceView.vue";
import AddInvoiceItem from "../views/business/AddInvoiceItem.vue";
import EditInvoiceItem from "../views/business/EditInvoiceItem.vue";
import InvoiceItemDetails from "../views/business/InvoiceItemDetails.vue";
import ClientsCustomers from "../views/business/ClientsCustomers.vue";
import AddCustomer from "../views/business/AddCustomer.vue";
import EditCustomerDetails from "../views/business/EditCustomerDetails.vue";
import CustomerDetails from "../views/business/CustomerDetails.vue";
import ClientsProducts from "../views/business/ClientsProducts.vue";
import AddProduct from "../views/business/AddProduct.vue";
import ProductDetails from "../views/business/ProductDetails.vue";
import EditProductDetails from "../views/business/EditProductDetails.vue";
import BusinessNip from "../views/business/BusinessNip.vue";
import ClaimBusinessNip from "../views/business/ClaimBusinessNip.vue";
import ManagePendingPaymentOrder from "../views/business/ManagePendingPaymentOrder.vue";
import ManagePendingStandingOrder from "../views/business/ManagePendingStandingOrder.vue";
import PaymentOrderDetails from "../views/business/PaymentOrderDetails.vue";
import StandingOrderDetails from "../views/business/StandingOrderDetails.vue";
import ManagePaymentOrder from "../views/business/ManagePaymentOrder.vue";
import ManageStandingOrder from "../views/business/ManageStandingOrder.vue";
import CreateNewBeneficiary from "../views/business/CreateNewBeneficiary.vue";
import BeneficiaryManagement from "../views/business/BeneficiaryManagement.vue";
import BeneficiaryDetails from "../views/business/BeneficiaryDetails.vue";
import EditBeneficiaryDetails from "../views/business/EditBeneficiaryDetails.vue";
import AccountManagement from "../views/business/AccountManagement.vue";
import AccountantView from "../views/business/AccountantView.vue";
import SavingsAccounts from "../views/business/SavingsAccounts.vue";
import CreateSavingsAccount from "../views/business/CreateSavingsAccount.vue";
import TermAccounts from "../views/business/TermAccounts.vue";
import CreateTermAccount from "../views/business/CreateTermAccount.vue";
import AccountDetails from "../views/business/AccountDetails.vue";
import TransactionDetails from "../views/business/TransactionDetails.vue";
import CreateAccount from "../views/business/CreateAccount.vue";
import ManageAccount from "../views/business/ManageAccount.vue";
import ManageAccountUserRights from "../views/business/ManageAccountUserRights.vue";
import CreateAccountUser from "../views/business/CreateAccountUser.vue";
import UserManagement from "../views/business/UserManagement.vue";
import UserDetails from "../views/business/UserDetails.vue";
import CreateUser from "../views/business/CreateUser.vue";
import ManageUser from "../views/business/ManageUser.vue";
import BusinessUserFileUpload from "../views/business/BusinessUserFileUpload.vue";
import CardManagement from "../views/business/CardManagement.vue";
import ManageCardApplications from "../views/business/ManageCardApplications.vue";
import ManageCardApplication from "../views/business/ManageCardApplication.vue";
import CardDetails from "../views/business/CardDetails.vue";
import CreateCard from "../views/business/CreateCard.vue";
import ManageCard from "../views/business/ManageCard.vue";
import OfferManagement from "../views/business/OfferManagement.vue";
import OfferDetails from "../views/business/OfferDetails.vue";
import CreateOffer from "../views/business/CreateOffer.vue";
import ManageOffer from "../views/business/ManageOffer.vue";
import EInvoiceManagement from "../views/business/EInvoiceManagement.vue";
import DirectDebitManagement from "../views/business/DirectDebitManagement.vue";
import PayrollDataUpload from "../views/business/PayrollDataUpload.vue";
import PaymentDataUpload from "../views/business/PaymentDataUpload.vue";

import { i18n } from "../main.js";

Vue.use(VueRouter);

export default new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes: [
		{
			path: "/:lang",
			component: RouterView,
			beforeEnter(to, from, next) {
				// document.title = to.meta.title ? to.meta.title : "N3";
				const lang = to.params.lang;
				if (!["fr", "fi", "en"].includes(lang)) return next("en");
				if (i18n.locale !== lang) {
					i18n.locale = lang;
				}
				return next();
			},
			children: [
				{
					path: "home",
					name: "home",
					component: Home,
					// meta: {
					// 	breadcrumb: "Home",
					// 	// title: $t("home"),
					// },
				},
				{
					path: "overview",
					name: "overview",
					component: Overview,
					// meta: {
					// 	breadcrumb: "Overview",
					// },
				},
				{
					path: "products",
					name: "products",
					component: Products,
					// meta: {
					// 	breadcrumb: "Products",
					// },
				},
				{
					path: "accounts",
					name: "accounts",
					component: Accounts,
				},
				{
					path: "account/:id",
					name: "account",
					component: Account,
				},
				{
					path: "cards",
					name: "cards",
					component: Cards,
				},
				{
					path: "card/:id",
					name: "card",
					component: Card,
				},
				{
					path: "payment-search/:id",
					name: "payment-search",
					component: PaymentSearch,
				},
				{
					path: "open-account",
					name: "open-account",
					component: OpenAccount,
				},
				{
					path: "edit-account/:id",
					name: "edit-account",
					component: ModifyAccount,
				},
				{
					path: "open-fixed-term-deposit",
					name: "open-fixed-term-deposit",
					component: OpenFixedTermDeposit,
				},
				{
					path: "fixed-term-deposits",
					name: "fixed-term-deposits",
					component: FixedTermDeposits,
				},
				{
					path: "fixed-term-deposit/:id",
					name: "fixed-term-deposit",
					component: FixedTermDeposit,
				},
				{
					path: "beneficiaries",
					name: "beneficiaries",
					component: Beneficiaries,
				},
				{
					path: "business/beneficiary-management",
					name: "beneficiary-management",
					component: BeneficiaryManagement,
				},
				{
					path: "business/beneficiary-details/:id",
					name: "beneficiary-details",
					component: BeneficiaryDetails,
				},
				{
					path: "business/create-new-beneficiary",
					name: "create-new-beneficiary",
					component: CreateNewBeneficiary,
				},
				{
					path: "business/edit-beneficiary-details/:id",
					name: "edit-beneficiary-details",
					component: EditBeneficiaryDetails,
				},
				{
					path: "beneficiary/:id",
					name: "beneficiary",
					component: Beneficiary,
				},
				{
					path: "new-beneficiary",
					name: "new-beneficiary",
					component: NewBeneficiary,
				},
				{
					path: "edit-beneficiary/:id",
					name: "edit-beneficiary",
					component: EditBeneficiary,
				},
				{
					path: "paymentorders",
					name: "paymentorders",
					component: PaymentOrders,
				},
				{
					path: "paymentorder/:id",
					name: "paymentorder",
					component: PaymentOrder,
				},
				{
					path: "edit-paymentorder/:id",
					name: "edit-paymentorder",
					component: EditPaymentOrder,
				},
				{
					path: "standingorders",
					name: "standingorders",
					component: StandingOrders,
				},
				{
					path: "standingorder/:id",
					name: "standingorder",
					component: StandingOrder,
				},
				{
					path: "edit-standingorder/:id",
					name: "edit-standingorder",
					component: EditStandingOrder,
				},

				{
					path: "payment/:id",
					name: "payment",
					component: Payment,
				},
				{
					path: "new-payment",
					name: "new-payment",
					component: NewPayment,
				},
				{
					path: "nip",
					name: "nip",
					component: Nip,
				},
				{
					path: "business/nip",
					name: "business-nip",
					component: BusinessNip,
				},
				{
					path: "business/claim-nip",
					name: "claim-business-nip",
					component: ClaimBusinessNip,
				},
				{
					path: "generate-qr-code",
					name: "generate-qr-code",
					component: GenerateQRCode,
				},
				{
					path: "business/qr-code",
					name: "qr-code",
					component: GenerateQRCode,
				},
				{
					path: "claim-nip",
					name: "claim-nip",
					component: ClaimNip,
				},
				{
					path: "profile",
					name: "profile",
					component: Profile,
				},
				{
					path: "login",
					name: "login",
					component: Login,
				},
				{
					path: "registration-type",
					name: "registration-type",
					component: RegistrationType,
				},
				{
					path: "business/business-type-selection",
					name: "business-type-selection",
					component: BusinessTypeSelection,
				},
				{
					path: "sign-up/:reference",
					name: "sign-up",
					component: SignUp,
				},
				{
					path: "sign-up-individual/:reference",
					name: "sign-up-individual",
					component: SignUpIndividual,
				},
				{
					path: "verify/:userID/:token",
					name: "verify-email",
					component: VerifyEmail,
				},
				{
					path: "forgot-password",
					name: "forgot-password",
					component: ForgotPassword,
				},
				{
					path: "reset-password/:token",
					name: "reset-password",
					component: ResetPassword,
				},
				{
					path: "about",
					name: "about",
					// meta: {
					// 	breadcrumb: "About",
					// },
					component: () => import("../views/website/About.vue"),
				},
				{
					path: "enterprises",
					name: "enterprises",
					component: () => import("../views/website/Enterprises.vue"),
				},
				{
					path: "revolution",
					name: "revolution",
					component: () => import("../views/website/Revolution.vue"),
				},
				{
					path: "introduction",
					name: "introduction",
					component: () => import("../views/website/Introduction.vue"),
				},
				{
					path: "consortium",
					name: "consortium",
					component: () => import("../views/website/Consortium.vue"),
				},
				{
					path: "consumer-products",
					name: "consumer-products",
					component: () => import("../views/website/ConsumerProducts.vue"),
				},
				{
					path: "our-story",
					name: "our-story",
					component: () => import("../views/website/OurStory.vue"),
				},
				{
					path: "blog",
					name: "blog",
					component: () => import("../views/website/Blog.vue"),
				},
				{
					path: "careers",
					name: "careers",
					component: () => import("../views/website/Careers.vue"),
				},
				{
					path: "accounts-page",
					name: "accounts-page",
					component: () => import("../views/website/AccountsPage.vue"),
				},
				{
					path: "payments-page",
					name: "payments-page",
					component: () => import("../views/website/PaymentsPage.vue"),
				},
				{
					path: "cards-page",
					name: "cards-page",
					component: () => import("../views/website/CardsPage.vue"),
				},
				{
					path: "savings-page",
					name: "savings-page",
					component: () => import("../views/website/SavingsPage.vue"),
				},
				{
					path: "loans-page",
					name: "loans-page",
					component: () => import("../views/website/LoansPage.vue"),
				},
				{
					path: "points-page",
					name: "points-page",
					component: () => import("../views/website/PointsPage.vue"),
				},
				{
					path: "inbrief",
					name: "inbrief",
					component: () => import("../views/website/InBrief.vue"),
				},
				{
					path: "business/business-account",
					name: "business-account",
					component: () =>
						import("../views/website/business/BusinessAccount.vue"),
				},
				{
					path: "business/business-home",
					name: "business-home",
					component: () => import("../views/website/business/BusinessHome.vue"),
				},
				{
					path: "business/pricing",
					name: "pricing",
					component: () => import("../views/website/business/Pricing.vue"),
				},
				{
					path: "business/payment-methods",
					name: "payment-methods",
					component: () =>
						import("../views/website/business/PaymentMethods.vue"),
				},
				{
					path: "business/payment-methods/business-cards",
					name: "business-cards",
					component: () =>
						import("../views/website/business/BusinessCards.vue"),
				},
				{
					path: "business/payment-methods/apple-pay",
					name: "apple-pay",
					component: () => import("../views/website/business/ApplePay.vue"),
				},
				{
					path: "business/payment-methods/google-pay",
					name: "google-pay",
					component: () => import("../views/website/business/GooglePay.vue"),
				},
				{
					path: "business/team",
					name: "team",
					component: () => import("../views/website/business/Team.vue"),
				},
				{
					path: "business/accounting",
					name: "accounting",
					component: () => import("../views/website/business/Accounting.vue"),
				},
				{
					path: "business/multi-account",
					name: "multi-account",
					component: () => import("../views/website/business/MultiAccount.vue"),
				},
				{
					path: "business/capital-deposit",
					name: "capital-deposit",
					component: () =>
						import("../views/website/business/CapitalDeposit.vue"),
				},
				{
					path: "business/company-creation",
					name: "company-creation",
					component: () =>
						import("../views/website/business/CompanyCreation.vue"),
				},
				{
					path: "business/freelancers",
					name: "freelancers",
					component: () => import("../views/website/business/Freelancers.vue"),
				},
				{
					path: "business/small-business",
					name: "small-business",
					component: () =>
						import("../views/website/business/SmallBusiness.vue"),
				},
				{
					path: "business/medium-business",
					name: "medium-business",
					component: () =>
						import("../views/website/business/MediumBusiness.vue"),
				},
				{
					path: "business/associations",
					name: "associations",
					component: () => import("../views/website/business/Associations.vue"),
				},
				{
					path: "business/security",
					name: "security",
					component: () => import("../views/website/business/Security.vue"),
				},
				{
					path: "business/team/role-access",
					name: "role-access",
					component: () => import("../views/website/business/RoleAccess.vue"),
				},
				{
					path: "business/invoicing",
					name: "invoicing",
					component: () => import("../views/website/business/Invoicing.vue"),
				},
				{
					path: "business/connect",
					name: "connect",
					component: () => import("../views/website/business/Connect.vue"),
				},
				{
					path: "business/comparators",
					name: "comparators",
					component: () => import("../views/website/business/Comparators.vue"),
				},
				{
					path: "business/account-switch",
					name: "account-switch",
					component: () =>
						import("../views/website/business/AccountSwitch.vue"),
				},

				{
					path: "business/business-transaction-details/:id",
					name: "business-transaction-details",
					component: TransactionDetails,
				},
				/*  {
          path: "app",
          name: "app",
          component: () => import("../views/website/App.vue"),
        },
        {
          path: "send",
          name: "send",
          component: () => import("../views/website/Send.vue"),
        },
        {
          path: "spend",
          name: "spend",
          component: () => import("../views/website/Spend.vue"),
        },
        {
          path: "bank",
          name: "bank",
          component: () => import("../views/website/Bank.vue"),
        },
        {
          path: "crypto",
          name: "crypto",
          component: () => import("../views/website/Crypto.vue"),
        },
        {
          path: "stocks",
          name: "stocks",
          component: () => import("../views/website/Stocks.vue"),
        },
        {
          path: "protect",
          name: "protect",
          component: () => import("../views/website/Protect.vue"),
        }, */
				{
					path: "business/sign-up-business/:reference",
					name: "sign-up-business",
					component: SignUpBusiness,
				},
				{
					path: "business/application-login",
					name: "application-login",
					component: ApplicationLogin,
				},
				{
					path: "business/application-overview",
					name: "application-overview",
					component: ApplicationOverview,
				},
				{
					path: "business/add-business-details/:id",
					name: "add-business-details",
					component: AddBusinessDetails,
				},
				{
					path: "business/edit-business-details/:id",
					name: "edit-business-details",
					component: EditBusinessDetails,
				},
				{
					path: "admin/edit-business-details/:id",
					name: "admin-edit-business-details",
					component: EditBusinessDetails,
				},
				{
					path: "business/add-role/:id/:reference",
					name: "business-add-role",
					component: AddBusinessRole,
				},
				{
					path: "business/edit-role/:id/:reference",
					name: "business-edit-role",
					component: EditBusinessRole,
				},
				{
					path: "business/upload/:id",
					name: "business-document-upload",
					component: BusinessDocumentUpload,
				},
				{
					path: "business/dashboard",
					name: "business-dashboard",
					component: BusinessDashboard,
				},
				{
					path: "business/account-management",
					name: "account-management",
					component: AccountManagement,
				},
				{
					path: "business/accountant-view",
					name: "accountant-view",
					component: AccountantView,
				},
				{
					path: "business/savings-accounts",
					name: "business-savings-accounts",
					component: SavingsAccounts,
				},
				{
					path: "business/create-savings-account",
					name: "business-create-savings-account",
					component: CreateSavingsAccount,
				},
				{
					path: "business/term-accounts",
					name: "business-term-accounts",
					component: TermAccounts,
				},
				{
					path: "business/create-term-account",
					name: "business-create-term-account",
					component: CreateTermAccount,
				},
				{
					path: "business/account-details/:id",
					name: "account-details",
					component: AccountDetails,
				},
				{
					path: "business/create-account",
					name: "create-account",
					component: CreateAccount,
				},
				{
					path: "business/manage-account/:id",
					name: "manage-account",
					component: ManageAccount,
				},
				{
					path: "business/manage-account-user-rights/:id",
					name: "manage-account-user-rights",
					component: ManageAccountUserRights,
				},
				{
					path: "business/create-account-user/:id",
					name: "create-account-user",
					component: CreateAccountUser,
				},
				{
					path: "business/user-management",
					name: "user-management",
					component: UserManagement,
				},
				{
					path: "business/user-details/:id",
					name: "user-details",
					component: UserDetails,
				},
				{
					path: "business/create-user",
					name: "create-user",
					component: CreateUser,
				},
				{
					path: "business/manage-user/:id",
					name: "manage-user",
					component: ManageUser,
				},
				{
					path: "business/business-user-file-upload/:id",
					name: "business-user-file-upload",
					component: BusinessUserFileUpload,
				},
				{
					path: "business/card-management",
					name: "card-management",
					component: CardManagement,
				},
				{
					path: "business/manage-card-applications",
					name: "manage-card-applications",
					component: ManageCardApplications,
				},
				{
					path: "business/card-details",
					name: "card-details",
					component: CardDetails,
				},
				{
					path: "business/create-card/:id",
					name: "create-card",
					component: CreateCard,
				},
				{
					path: "business/manage-card/:id",
					name: "manage-card",
					component: ManageCard,
				},
				{
					path: "business/manage-card-application/:id",
					name: "manage-card-application",
					component: ManageCardApplication,
				},
				{
					path: "business/offer-management",
					name: "offer-management",
					component: OfferManagement,
				},
				{
					path: "business/offer-details/:id",
					name: "offer-details",
					component: OfferDetails,
				},
				{
					path: "business/create-offer/:id",
					name: "create-offer",
					component: CreateOffer,
				},
				{
					path: "business/manage-offer/:id",
					name: "manage-offer",
					component: ManageOffer,
				},
				{
					path: "business/receivables-management",
					name: "receivables-management",
					component: ReceivablesManagement,
				},
				{
					path: "business/create-invoice",
					name: "create-invoice",
					component: CreateInvoice,
				},
				{
					path: "business/add-customer",
					name: "add-customer",
					component: AddCustomer,
				},
				{
					path: "business/customer-details/:id",
					name: "customer-details",
					component: CustomerDetails,
				},
				{
					path: "business/edit-customer-details/:id",
					name: "edit-customer-details",
					component: EditCustomerDetails,
				},
				{
					path: "business/clients-customers",
					name: "clients-customers",
					component: ClientsCustomers,
				},
				{
					path: "business/clients-products",
					name: "clients-products",
					component: ClientsProducts,
				},
				{
					path: "business/add-product",
					name: "add-product",
					component: AddProduct,
				},
				{
					path: "business/product-details/:id",
					name: "product-details",
					component: ProductDetails,
				},
				{
					path: "business/edit-product-details/:id",
					name: "edit-product-details",
					component: EditProductDetails,
				},
				{
					path: "business/add-invoice-item",
					name: "add-invoice-item",
					component: AddInvoiceItem,
				},
				{
					path: "business/invoice-item-details/:id",
					name: "invoice-item-details",
					component: InvoiceItemDetails,
				},
				{
					path: "business/invoice-view/:id",
					name: "invoice-view",
					component: InvoiceView,
				},
				{
					path: "business/edit-invoice-item/:id",
					name: "edit-invoice-item",
					component: EditInvoiceItem,
				},
				{
					path: "business/invoice-details/:id",
					name: "invoice-details",
					component: InvoiceDetails,
				},
				{
					path: "business/edit-invoice-details/:id",
					name: "edit-invoice-details",
					component: EditInvoiceDetails,
				},
				{
					path: "business/payables-management",
					name: "payables-management",
					component: PayablesManagement,
				},
				{
					path: "business/payment-management",
					name: "payment-management",
					component: PaymentManagement,
				},
				{
					path: "business/manage-pending-payment-order/:id",
					name: "manage-pending-payment-order",
					component: ManagePendingPaymentOrder,
				},
				{
					path: "business/manage-pending-standing-order/:id",
					name: "manage-pending-standing-order",
					component: ManagePendingStandingOrder,
				},
				{
					path: "business/manage-payment-order/:id",
					name: "manage-payment-order",
					component: ManagePaymentOrder,
				},
				{
					path: "business/standing-order-details/:id",
					name: "standing-order-details",
					component: StandingOrderDetails,
				},
				{
					path: "business/payment-order-details/:id",
					name: "payment-order-details",
					component: PaymentOrderDetails,
				},
				{
					path: "business/manage-standing-order/:id",
					name: "manage-standing-order",
					component: ManageStandingOrder,
				},
				{
					path: "business/extended-payment",
					name: "extended-payment",
					component: ExtendedPayment,
				},
				{
					path: "business/invoice-payment/:id",
					name: "invoice-payment",
					component: InvoicePayment,
				},
				{
					path: "business/einvoice-management",
					name: "einvoice-management",
					component: EInvoiceManagement,
				},
				{
					path: "business/direct-debit-management",
					name: "direct-debit-management",
					component: DirectDebitManagement,
				},
				{
					path: "business/payment-data-upload",
					name: "payment-data-upload",
					component: PaymentDataUpload,
				},
				{
					path: "business/payroll-data-upload",
					name: "payroll-data-upload",
					component: PayrollDataUpload,
				},
				{
					path: "admin/dashboard",
					name: "dashboard",
					component: Dashboard,
				},
				{
					path: "admin/applications",
					name: "applications",
					component: Applications,
				},
				{
					path: "admin/business-client-details/:id",
					name: "business-client-details",
					component: BusinessClientDetails,
				},
				{
					path: "admin/individual-client-details/:id",
					name: "individual-client-details",
					component: IndividualClientDetails,
				},
				{
					path: "admin/add-role/:id/:reference",
					name: "admin-business-add-role",
					component: AddBusinessRole,
				},
				{
					path: "admin/edit-role/:id/:reference",
					name: "admin-business-edit-role",
					component: EditBusinessRole,
				},
				{
					path: "admin/upload/:id",
					name: "admin-business-document-upload",
					component: BusinessDocumentUpload,
				},
				{
					path: "admin/client-list",
					name: "client-list",
					component: ClientList,
				},
				{
					path: "admin/user-list",
					name: "user-list",
					component: UserList,
				},
				{
					path: "admin/user-detail/:id",
					name: "user-detail",
					component: UserDetail,
				},
				{
					path: "admin/new-user",
					name: "new-user",
					component: NewUser,
				},
				{
					path: "admin/edit-user/:id",
					name: "edit-user",
					component: EditUser,
				},
				{
					path: "admin/edit-individual-client/:id",
					name: "edit-individual-client",
					component: EditIndividualClient,
				},
				{
					path: "admin/account-list",
					name: "account-list",
					component: AccountList,
				},
				{
					path: "admin/account-detail/:id",
					name: "account-detail",
					component: AccountDetail,
				},
				{
					path: "admin/new-account",
					name: "new-account",
					component: NewAccount,
				},
				{
					path: "admin/edit-account/:id",
					name: "admin-edit-account",
					component: EditAccount,
				},
				{
					path: "admin/transaction-list",
					name: "transaction-list",
					component: TransactionList,
				},
				{
					path: "admin/transaction-detail/:id",
					name: "transaction-detail",
					component: TransactionDetail,
				},
				{
					path: "admin/new-transaction",
					name: "new-transaction",
					component: NewTransaction,
				},
				{
					path: "admin/edit-transaction/:id",
					name: "edit-transaction",
					component: EditTransaction,
				},
				{
					path: "admin/document-upload",
					name: "document-upload",
					component: DocumentUpload,
				},
				{
					path: "file-upload",
					name: "file-upload",
					component: FileUpload,
				},
			],
		},
		{
			path: "*",
			redirect: "en/home",
		},
	],
});
