<template>
	<ValidationObserver ref="observer" v-slot="{ handleSubmit, reset }">
		<b-form @submit.prevent="handleSubmit(newNip)" @reset.prevent="reset">
			<div class="col-lg-4 offset-lg-4 text-left">
				<h2 class="title-bold">{{ $t("nip.newNip") }}</h2>
				<p class="open-account text-right">
					<router-link
						class="btn btn-outline"
						:to="{ name: 'claim-business-nip' }"
						>{{ $t("nip.claimNip") }}</router-link
					>
				</p>
				<hr />
				<div v-if="isloading2" class="text-center">
					<div class="background-outline">
						<b-spinner
							style="width: 3rem; height: 3rem;"
							class="ml-auto text-center"
							:variant="secondary"
						></b-spinner>
					</div>
					<br />
				</div>
				<div v-else class="background-outline">
					<div class="background-modifier">
						<label class="small">{{ $t("nip.debitAccountId") }}</label>
						<ValidationProvider
							rules="required"
							name="debitAccountId"
							v-slot="{ valid, errors }"
						>
							<b-form-group
								id="debitAccountIdInputGroup"
								label-for="debitAccountIdInput"
							>
								<b-form-select
									@change="onChange"
									id="debitAccountIdInput"
									:state="errors[0] ? false : valid ? true : null"
									v-model="debitAccountId"
									><option
										v-for="account in accounts"
										:value="account.id"
										:key="account.id"
										>({{ account.id }})
										{{ account.iban.toString().replace(/\w{4}(?=.)/g, "$& ") }}
										{{ $n(account.credits - account.debits, "decimal") }}
										{{ account.currency }}</option
									>
									<template #first>
										<b-form-select-option value="" disabled>{{
											$t("nip.debitAccountId")
										}}</b-form-select-option>
									</template></b-form-select
								>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>

						<div v-if="debitAccountId > 0">
							<!--     <div v-if="creditAccountId == '' && creditAccountId2 == ''">
            <p>{{ $t('editStandingOrder.selectStoredBeneficiaryOrInsertANewOne') }}</p>
          </div>
  
        <div v-if="creditAccountId == ''">   creditAccount2Required ? 'required' : '' 
          <div v-if="beneficiaries.length > 0">
          <ValidationProvider rules="" name="creditAccountId2" v-slot="{ valid, errors }">
            <b-form-group id="creditAccountIdInputGroup2" label-for="creditAccountIdInput2">
              <b-form-select @input="getBeneficiaryName()"
              id="creditAccountIdInput2"
              :state="errors[0] ? false : (valid ? true : null)"
              v-model="creditAccountId2"
             ><option v-for="beneficiary in beneficiaries" :value="beneficiary.beneficiaryAccount" :key="beneficiary.beneficiaryAccount">{{beneficiary.beneficiaryAccount}} {{beneficiary.alias}}</option>
             <template #first>
               <b-form-select-option value="" disabled>{{ $t('editStandingOrder.creditAccountId') }}</b-form-select-option>
             </template></b-form-select>
              <b-form-invalid-feedback id="inputLiveFeedback">{{ errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </div>
  
        <p>{{ beneficiaryDetails ? $t('editStandingOrder.beneficiaryName') : '' }} {{ beneficiaryDetails ? beneficiaryDetails.beneficiaryName : '' }}</p>
      </div> 
  
     
  
        <div v-if="creditAccountId2 == ''">   creditAccountRequired ? 'required' : '' | 
      <ValidationProvider rules="integer|max_value:1000000" name="creditAccountId" v-slot="{ valid, errors }">
        <b-form-group
          
          label-for="creditAccountIdInput"
          description=""          >
          <b-form-input
            type="text"
            v-model="creditAccountId"
            :state="errors[0] ? false : (valid ? true : null)"
            :placeholder="$t('editStandingOrder.creditAccountId')"
          ></b-form-input>
          <b-form-invalid-feedback id="inputLiveFeedback">{{ errors[0] }}</b-form-invalid-feedback>
        </b-form-group>
      </ValidationProvider> -->

							<!-- creditAccountRequired ? 'required' : '' | -->
							<label class="small">{{ $t("nip.beneficiaryName") }}</label>
							<ValidationProvider
								rules="alpha_spaces|min:2|max:50"
								name="creditAccountHolder"
								v-slot="{ valid, errors }"
							>
								<b-form-group label-for="creditAccountHolder" description="">
									<b-form-input
										type="text"
										v-model="creditAccountHolder"
										:state="errors[0] ? false : valid ? true : null"
										:placeholder="$t('editStandingOrder.beneficiaryName')"
									></b-form-input>
									<b-form-invalid-feedback id="inputLiveFeedback">{{
										errors[0]
									}}</b-form-invalid-feedback>
								</b-form-group>
							</ValidationProvider>

							<label class="small">{{ $t("nip.email") }}</label>
							<ValidationProvider
								rules="required|email"
								name="email"
								v-slot="{ valid, errors }"
							>
								<b-form-group label-for="emailInput" description="">
									<b-form-input
										type="email"
										v-model="email"
										:state="errors[0] ? false : valid ? true : null"
										:placeholder="$t('nip.email')"
									></b-form-input>
									<b-form-invalid-feedback id="inputLiveFeedback">{{
										errors[0]
									}}</b-form-invalid-feedback>
								</b-form-group>
							</ValidationProvider>
						</div>
					</div>
					<br />

					<!--   </div>   -->
					<div v-if="isloading3" class="text-center">
						<div class="background-modifier">
							<b-spinner
								style="width: 3rem; height: 3rem;"
								class="ml-auto text-center"
								:variant="secondary"
							></b-spinner>
						</div>
						<br />
					</div>
					<div v-else class="background-modifier">
						<div class="row">
							<div class="col-md-4">
								<label class="small">{{ $t("account.currency") }}</label>
								<ValidationProvider
									rules="required"
									name="selected"
									v-slot="{ valid, errors }"
								>
									<b-form-group
										id="sourceCurrencyInputGroup"
										:label-for="sourceCurrencyInput"
									>
										<b-form-select
											@change="convert"
											id="sourceCurrencyInput"
											:state="errors[0] ? false : valid ? true : null"
											v-model="selected[0]"
											><option
												v-for="currency in currencies"
												:value="currency.CharCode"
												:key="currency.CharCode"
												>{{ currency.CharCode }}</option
											>
											<template #first>
												<b-form-select-option value="" disabled>{{
													$t("editStandingOrder.paymentCurrency")
												}}</b-form-select-option>
											</template></b-form-select
										>
										<b-form-invalid-feedback id="inputLiveFeedback">{{
											errors[0]
										}}</b-form-invalid-feedback>
									</b-form-group>
								</ValidationProvider>
							</div>

							<!--  <div class="col-md-4">
      <ValidationProvider rules="" name="selected" v-slot="{ valid, errors }">
        <b-form-group id="targetCurrencyInputGroup" label-for="targetCurrencyInput">
          <b-form-select @change="convert"
          id="targetCurrencyInput"
          :state="errors[0] ? false : (valid ? true : null)"
          v-model="selected[1]"
         ><option v-for="currency in currencies" :value="currency.CharCode" :key="currency.CharCode">{{ currency.CharCode }}</option>
         <template #first>
           <b-form-select-option value="" disabled>{{ $t('to') }}</b-form-select-option>
         </template></b-form-select>
          <b-form-invalid-feedback id="inputLiveFeedback">{{ errors[0] }}</b-form-invalid-feedback>
        </b-form-group>
      </ValidationProvider>
    </div> -->

							<div class="col-md-8">
								<label class="small">{{ $t("nip.amount") }}</label>
								<ValidationProvider
									rules="required|double|min_value:0.01"
									name="amount"
									v-slot="{ valid, errors }"
								>
									<b-form-group label-for="amountInput" description="">
										<b-form-input
											class="custom-amount"
											@input="convert"
											type="text"
											v-model="amount"
											:state="errors[0] ? false : valid ? true : null"
											:placeholder="$t('editStandingOrder.amount')"
										></b-form-input>
										<b-form-invalid-feedback id="inputLiveFeedback">{{
											errors[0]
										}}</b-form-invalid-feedback>
									</b-form-group>
								</ValidationProvider>
							</div>
						</div>

						<div v-if="selected[0] !== accountCurrency.currency">
							<div class="col-md-12 text-right">
								<p v-model="result" class="small" type="number" readonly>
									<!-- (inside p tag)-->
									{{ result ? accountCurrency.currency : "" }}
									{{ result ? $n(result.toFixed(2), "decimal") : "" }}
								</p>
							</div>
						</div>
					</div>
					<br />

					<!--  <ValidationProvider rules="required|double|min_value:0.01" name="amount" v-slot="{ valid, errors }">
        <b-form-group
          
          label-for="amountInput"
          description=""          >
          <b-form-input
            type="text"
            v-model="amount"
            :state="errors[0] ? false : (valid ? true : null)"
            :placeholder="$t('editStandingOrder.amount')"
          ></b-form-input>
          <b-form-invalid-feedback id="inputLiveFeedback">{{ errors[0] }}</b-form-invalid-feedback>
  
          <div v-bind:style="(balance-amount)>0 ? color=green : color=red">
            <p v-if="amount.length > 0" class="small">{{ $t('editStandingOrder.remainingBalance') }} {{ $n(balance - amount, 'decimal') }}</p>
          </div>
  
        </b-form-group>
      </ValidationProvider> -->

					<div class="background-modifier">
						<label class="small">{{ $t("nip.message") }}</label>
						<ValidationProvider
							rules="min:1|max:50"
							name="message"
							v-slot="{ valid, errors }"
						>
							<b-form-group label-for="messageInput" description="">
								<b-form-input
									type="text"
									v-model="message"
									:state="errors[0] ? false : valid ? true : null"
									:placeholder="$t('nip.message')"
								></b-form-input>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>
					</div>

					<!--    <div class="row">
        <div class="col-md-12 offset-md-0">
            <label for="">{{ $t('editStandingOrder.dueDate') }}</label>
                <b-form-datepicker v-model="dueDate" :min="min" :locale="$i18n.locale" :label-no-date-selected="$t('editStandingOrder.noDateSelected')" :label-help="$t('editStandingOrder.useCursorKeysToNavigateCalendarDates')"></b-form-datepicker>
        </div>
    </div> 
  
      <hr/> 
     
    <b-form-group label="" v-slot="{ oneOrMany }">
     
      <b-form-radio v-model="radio" :aria-describedby="oneOrMany" name="radio_group_1" value="once">{{ $t('editStandingOrder.payOnce') }}</b-form-radio>
      <b-form-radio v-model="radio" :aria-describedby="oneOrMany" name="radio_group_1" value="monthly">{{ $t('editStandingOrder.monthly') }}</b-form-radio>
    </b-form-group> -->

					<!--    <div v-show="radio === 'monthly'"> 
      <div class="row">
  
        <div class="col-md-12 offset-md-0"> required: endDateRequired 
        <ValidationProvider rules="" name="endDate" v-slot="{ valid, errors }">
           
          <b-form-group 
          label-for="endDateInput"
          description="" >
          
            <label for="">{{ $t('editStandingOrder.endDate') }}</label>
            <b-form-datepicker v-model="endDate" :min="minEnd" :state="errors[0] ? false : (valid ? true : null)" :locale="$i18n.locale" :label-no-date-selected="$t('editStandingOrder.noDateSelected')" :label-help="$t('editStandingOrder.useCursorKeysToNavigateCalendarDates')"></b-form-datepicker>
       
            <b-form-invalid-feedback id="inputLiveFeedback">{{ errors[0] }}</b-form-invalid-feedback>
          </b-form-group>
        
      </ValidationProvider>
     </div>  
  
    </div> 
    </div> -->

					<!--    <div v-if="creditAccountId2 == ''">
      <hr/>
      <div class="row">
        <div class="col-md-6 offset-md-0">
          <b-form-checkbox
          id="checkbox-1"
          v-model="status"
          name="checkbox-1"
          value="accepted"
          unchecked-value="not_accepted"
        >
        {{ $t('nip.storeBeneficiary') }}
        </b-form-checkbox>
      </div>
      <div class="col-md-6 offset-md-0">
        <ValidationProvider rules="min:1|max:50" name="alias" v-slot="{ valid, errors }">
        <b-form-group
        label-for="aliasInput"
        description=""          >
        <b-form-input
          type="text"
          v-model="alias"
          :state="errors[0] ? false : (valid ? true : null)"
          :placeholder="$t('niå.alias')"
        ></b-form-input>
        <b-form-invalid-feedback id="inputLiveFeedback">{{ errors[0] }}</b-form-invalid-feedback>
      </b-form-group>
    </ValidationProvider>
      </div>
    </div>
  </div> -->

					<div v-if="debitAccountId > 0">
						<br />
						<!--   <div v-if="(debitAccountId != creditAccountId && debitAccountId != creditAccountId2)"> -->
						<div v-if="creditAmount.credits - debitAmount.debits - result >= 0">
							<b-button
								type="submit"
								@click="isloading"
								class="btn btn-info text-light btn-block"
								>{{ $t("editStandingOrder.submit")
								}}<b-spinner
									v-if="isloading"
									class="ml-auto"
									:variant="light"
								></b-spinner
							></b-button>
						</div>

						<div
							v-else-if="
								result - (creditAmount.credits - debitAmount.debits) > 0
							"
							class="red"
						>
							{{ $t("newPayment.insufficientBalance") }}
						</div>
						<!--  </div> -->
						<div v-else class="red">
							{{ $t("editStandingOrder.accountsMustBeDifferent") }}
						</div>

						<hr />
						<b-button
							type="reset"
							class="btn btn-alert text-light btn-block"
							@click="resetForm()"
							>{{ $t("reset") }}</b-button
						>
					</div>

					<div v-if="msg && $bvToast.show('notification-toast')"></div>
					<div class="action">
						<br />
						<b-toast
							id="notification-toast"
							variant="secondary"
							static
							no-auto-hide
						>
							<template #toast-title>
								<div class="d-flex flex-grow-1 align-items-baseline">
									<b-img
										class="logo mb-2"
										:src="require('/public/images/logo.png')"
										alt=""
										width="12"
										height="12"
									></b-img>
									<strong class="mr-auto">{{ $t("notification") }}</strong>
									<small class="text-muted mr-2"></small>
								</div>
							</template>
							{{ msg }}
						</b-toast>
					</div>
				</div>
			</div>
		</b-form>
	</ValidationObserver>
</template>

<script>
	import { ValidationObserver, ValidationProvider } from "vee-validate";
	import AuthService from "@/services/AuthService.js";
	var IBAN = require("@/components/iban.js");
	const currencyAPI = require("axios").default;

	export default {
		name: "NewNipForm",
		components: {
			ValidationObserver,
			ValidationProvider,
		},
		data() {
			//   const now = new Date()
			//   const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
			// 15th two months prior

			//    const minDate = new Date(today)
			//   const minDate = new Date(openingDate.getFullYear(), openingDate.getMonth(), openingDate.getDate())
			//    minDate.setYear(minDate.getDate() + 1)

			//   const minEndDate = new Date(today)
			//    minEndDate.setMonth(minEndDate.getMonth() + 1)

			return {
				accounts: [],
				debitAccountId: "",
				//    creditAccountId: '',
				//   creditAccountId2: '',
				creditAccountHolder: "",
				email: "",
				balance: "",
				accountCurrency: "", //this.accountCurrency.currency ? this.accountCurrency.currency : '',
				amount: "",
				message: "",
				//   radio: 'once',
				//   dueDate: new Date().toISOString().slice(0, 10),
				//   endDate: new Date().toISOString().slice(0, 10),
				//   min: minDate,
				//   minEnd: minEndDate,
				msg: "",

				//   status: 'not_accepted',
				isloading: false,
				isloading2: false,
				isloading3: false,
				currencies: {},
				selected: ["EUR", "USD"],
				//  inputted: "",
				result: null,
				countries: [],
				rules: {
					onlyNumbers: (value) => {
						const pattern = /^\d+$/.test(value);
						if (!pattern) this.inputed = this.inputed.replace(/\D/g, "");
						return true;
					},
				},
			};
		},
		/* computed: {
          isCreditAccountRequired() {
              if(this.creditAccountId2 === '')
                  return true; // creditAccountId is required
              return false;
          },
          isCreditAccount2Required() {
              // check if creditAccountId is empty
              if(this.creditAccountId === '')
                  return true; // creditAccount2 is required
              return false;
          }
      },*/

		/*   endDateRequired(){
        if (radio==='monthly'){
          return true
        } else {return false}
    },
    creditAccountRequired(){
        if (this.creditAccountId2===''){
          return true
        } else {return false}
    },
    creditAccount2Required(){
        if (creditAccountId===''){
          return true
        } else {return false}
    }, */
		async created() {
			if (!this.$store.getters.isLoggedIn) {
				this.$router.push("login");
			}
		},
		methods: {
			formatIBAN() {
				var ibanCode = IBAN.printFormat(this.account.iban, " ");
				return ibanCode;
			},
			async onChange(event) {
				try {
					const id = this.debitAccountId;
					const response1 = await AuthService.getCreditAmount(id);
					this.creditAmount = response1;

					//  console.log(response1);
					const response2 = await AuthService.getDebitAmount(id);
					this.debitAmount = response2;

					const response3 = await AuthService.getAccountCurrency(id);
					this.accountCurrency = response3;
					console.log(accountCurrency.currency);

					//   console.log(response2);
					const balance1 = this.creditAmount.credits - this.debitAmount.debits;
					this.balance = balance1;
					console.log(balance);
				} catch (error) {
					console.log(error);
				}
			},
			convert() {
				// Default currency values / EUR
				let defaultCurrency = {
					Value: 1,
					Nominal: 1,
				};
				// First selected currency details
				let firstCurrency =
						this.currencies[this.selected[0]] ?? defaultCurrency,
					firstCurrencyValue = firstCurrency.Value * Number(this.amount),
					firstCurrencyNominal = firstCurrency.Nominal;
				// Second selected currency details
				let secondCurrency =
						this.currencies[this.accountCurrency.currency /*selected[1]*/] ??
						defaultCurrency,
					secondCurrencyValue = secondCurrency.Value,
					secondCurrencyNominal = secondCurrency.Nominal;
				// Result calculating
				let result =
					firstCurrencyValue /
					firstCurrencyNominal /
					(secondCurrencyValue / secondCurrencyNominal);
				// Rounding to ten thousandths
				this.result = result ? Math.floor(result * 10000) / 10000 : null;
			},
			async getCurrencyData() {
				try {
					this.isloading3 = true;
					const response = await AuthService.getCurrencyData();
					this.currencies = response.data.Valute;
					console.log(response.data.Valute);
					this.isloading3 = false;
					// Adding all charCodes to array
					for (let code in response) {
						this.countries.push(code);
					}
				} catch (error) {
					this.isloading3 = false;
					console.log(error);
				}
			},
			async newNip() {
				try {
					const details = {
						lang: this.$i18n.locale,
						debitAccountId: this.debitAccountId,
						email: this.email,
						//    creditAccountId: this.creditAccountId2 ? this.creditAccountId2 : this.creditAccountId,
						creditAccountHolder: this.creditAccountHolder
							? this.creditAccountHolder
							: this.beneficiaryDetails.beneficiaryName,
						baseCurrency: this.accountCurrency.currency,
						amount: this.result,
						paymentCurrency: this.selected[0],
						currencyAmount: this.amount,
						message: this.message,
						dueDate: this.dueDate,
						//  dueDate: this.dueDate ? this.dueDate : '',
						//  endDate: this.endDate ? this.endDate : '',
						//  frequency: this.frequency ? this.frequency : 1
					}; //&& this.radio == 'once'

					console.log(details);
					//   const todaysDate = new Date().toISOString().slice(0, 10)
					//   console.log(this.dueDate,todaysDate);
					/*    if (this.radio == 'once' && this.dueDate > todaysDate) {
          this.newPaymentOrder();
            if (this.status == 'accepted') {
              this.newBeneficiary();
            }
        } else if(this.radio == 'monthly' || (this.radio == 'monthly' && this.dueDate > todaysDate)) {
          this.newStandingOrder(); 
            if (this.status == 'accepted') {
              this.newBeneficiary();
            }
        } else if (this.radio == 'once' && this.dueDate == todaysDate ) { */
					this.isloading = true;
					const response = await AuthService.newNip(details);
					this.resetForm();
					this.isloading = false;
					this.msg = response.msg;

					/*    if (this.status == 'accepted') {
              this.newBeneficiary();
            } */
					/*     }  else if (this.radio == 'monthly' && this.dueDate == todaysDate) {
        //   const response = await AuthService.newNip(details);
         //  this.msg = response.msg;
           this.newStandingOrder();
            if (this.status == 'accepted') {
              this.newBeneficiary();
            }
        } */

					// this.resetForm();
				} catch (error) {
					this.isloading = false;
					this.msg = error.response.data.msg;
					/*this.$router.push('accounts');*/
				}
			},

			/*async getAccountCurrency() {
          try {
            const details = {
              debitAccountId: this.debitAccountId,
            };
            const response3 = await AuthService.getAccountCurrency(details.debitAccountId);
            this.accountCurrency = response3;
            console.log(accountCurrency);
          } catch (error) {
            console.log(error);
          }
        },*/

			/*    async newPaymentOrder() {
          try {
            const details = {
              lang: this.$i18n.locale,
              userId: this.$store.getters.getUser.id,
              debitAccountId: this.debitAccountId,
              creditAccountId: this.creditAccountId2 ? this.creditAccountId2 : this.creditAccountId,
              creditAccountHolder: this.creditAccountHolder ? this.creditAccountHolder : this.beneficiaryDetails.beneficiaryName,
              baseCurrency: this.accountCurrency.currency,
              amount: this.result,
              paymentCurrency: this.selected[0],
              currencyAmount: this.amount,
              message: this.message,
              dueDate: this.dueDate
       
            };
            const response = await AuthService.newPaymentOrder(details);
            this.msg = response.msg;
            console.log(response);
          } catch (error) {
            console.log(error);
          }
        },
  
        async newStandingOrder() {
          const todaysDate = new Date().toISOString().slice(0, 10);
          const dateX = new Date(todaysDate);
          const newStartDate = dateX.getFullYear()+'-' + (dateX.getMonth()+2) + '-'+(dateX.getDate()+2);
          try {
            const details = {
              lang: this.$i18n.locale,
              userId: this.$store.getters.getUser.id,
              debitAccountId: this.debitAccountId,
              creditAccountId: this.creditAccountId2 ? this.creditAccountId2 : this.creditAccountId,
              creditAccountHolder: this.creditAccountHolder ? this.creditAccountHolder : this.beneficiaryDetails.beneficiaryName,
              baseCurrency: this.accountCurrency.currency,
              amount: this.result,
              paymentCurrency: this.selected[0],
              currencyAmount: this.amount,
              message: this.message,
              startDate: this.dueDate ==  todaysDate ? newStartDate : this.dueDate,
              endDate: this.endDate,
              frequency: this.frequency ? this.frequency : 1
       
            };
            console.log(this.dueDate,this.endDate);
            const response = await AuthService.newStandingOrder(details);
            this.msg = response.msg;
            console.log(response);
          } catch (error) {
            console.log(error);
          }
        }, */

			/*  async newBeneficiary() {
          try {
            const details = {
              lang: this.$i18n.locale,
              userId: this.$store.getters.getUser.id,
              beneficiaryAccount: this.creditAccountId2 ? this.creditAccountId2 : this.creditAccountId,
              beneficiaryName: this.creditAccountHolder,
              alias: this.alias ? this.alias : this.creditAccountHolder
            };
            const response = await AuthService.newBeneficiary(details);
            this.accounts = response;
            console.log(response);
          } catch (error) {
            console.log(error);
          }
        }, */

			async getClientsCurrentAccounts() {
				try {
					const details = {
						id: this.$store.getters.getUser.id,
					};
					this.isloading2 = true;
					const response = await AuthService.getClientsCurrentAccounts(
						details.id
					);
					this.accounts = response;
					this.isloading2 = false;
					console.log(response);
				} catch (error) {
					this.isloading2 = false;
					console.log(error);
				}
			},

			/*     async getUsersBeneficiaryList() {
        try {
          const details = {
            id: this.$store.getters.getUser.id,
          };
          const response = await AuthService.getUsersBeneficiaryList(details.id);
          this.beneficiaries = response;
          console.log(response);
        } catch (error) {
          console.log(error);
        }
      },*/

			/*    async getBeneficiaryName() {
        try {
          const details = {
            userid: this.$store.getters.getUser.id,
            accountid: this.creditAccountId2
          };
          const response = await AuthService.getBeneficiaryName(details.accountid,details.userid);
          this.beneficiaryDetails = response;
          console.log(response);
        } catch (error) {
          console.log(error);
        }
      }, */

			resetForm() {
				this.debitAccountId = "";
				//    this.creditAccountId = '';
				//    this.creditAccountId2 = '';
				this.creditAccountHolder = "";
				// this.beneficiaryDetails.beneficiaryName = '';
				this.email = "";
				this.amount = "";
				this.selected[0] = "";
				this.message = "";
				//    this.alias = '';
				//    this.dueDate = '';
				//    this.endDate = '';
				//  this.msg = '';
				this.result = null;
				requestAnimationFrame(() => {
					this.$refs.observer.reset();
				});
			},
		},

		mounted() {
			this.getClientsCurrentAccounts();
			//    this.getUsersBeneficiaryList();
			this.getCurrencyData();
			this.getUserId();
			this.balance;
			/*if (this.debitAccountId > 0) {
          this.getAccountCurrency();
        }*/
		},
	};
</script>
