<template>
	<div class="col-4 offset-md-4 text-left">
		<h2 class="title-bold">
			{{ $t("transactionDetail.transaction") }} {{ this.$route.params.id }}
		</h2>
		<hr />
		<div class="background-modifier">
			<div class="product-details">
				<label class="small">{{ $t("transactionDetail.id") }}</label>
				<p class="text-left">
					{{ transaction.id }}
				</p>
				<label class="small">{{
					$t("transactionDetail.debitAccountId")
				}}</label>
				<p class="text-left">
					{{ transaction.debitAccountId }}
				</p>
				<label class="small">{{ $t("transactionDetail.remitter") }}</label>
				<p class="text-left">
					{{ transaction.debitAccountHolder }}
				</p>
				<label class="small">{{
					$t("transactionDetail.creditAccountId")
				}}</label>
				<p class="text-left">
					{{ transaction.creditAccountId }}
				</p>
				<label class="small">{{ $t("transactionDetail.beneficiary") }}</label>
				<p class="text-left">
					{{ transaction.creditAccountHolder }}
				</p>
				<label class="small">{{
					$t("transactionDetail.accountCurrency")
				}}</label>
				<p class="text-left">
					{{ transaction.baseCurrency }}
				</p>
				<label class="small">{{ $t("transactionDetail.amount") }}</label>
				<p class="text-left">
					{{ $n(transaction.amount, "decimal") }}
				</p>
				<label class="small">
					{{ $t("transactionDetail.paymentCurrency") }}</label
				>
				<p class="text-left">
					{{ transaction.paymentCurrency }}
				</p>
				<label class="small">{{
					$t("transactionDetail.currencyAmount")
				}}</label>
				<p class="text-left">
					{{ $n(transaction.currencyAmount, "decimal") }}
				</p>
				<label class="small">{{ $t("transactionDetail.message") }}:</label>
				<p class="text-left">
					{{ transaction.message }}
				</p>
				<label class="small">{{ $t("transactionDetail.date") }}</label>
				<p class="text-left">
					{{ $d(Date.parse(transaction.created), "long") }}
				</p>
			</div>
			<div class="text-right">
				<router-link
					:to="{ name: 'edit-transaction', params: { id: transaction.id } }"
					class="btn btn-outline mx-1 "
					>{{ $t("transactionDetail.edit") }}</router-link
				>
			</div>
		</div>
	</div>
</template>

<script>
	import AuthService from "@/services/AuthService.js";

	export default {
		name: "TransactionDetail",
		data() {
			return {
				transaction: "",
			};
		},
		async created() {
			if (!this.$store.getters.isLoggedIn) {
				this.$router.push("login");
			}
			if (
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role !== 3
			) {
				this.$router.push("overview");
			}

			this.role = this.$store.getters.getUser.role;
			this.getTransaction();
		},
		methods: {
			async getTransaction(id) {
				try {
					const id = this.$route.params.id;
					const response = await AuthService.getTransaction(id);
					this.transaction = response;
					console.log(response);
				} catch (error) {
					console.log(error);
				}
			},
		},
		mounted() {
			this.getTransaction();
		},
	};
</script>
