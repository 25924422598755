<template>
	<ValidationObserver ref="observer" v-slot="{ handleSubmit, reset }">
		<b-form
			@submit.prevent="handleSubmit(openBusinessFixedTermDeposit)"
			@reset.prevent="reset"
		>
			<div class="col-lg-4 offset-lg-4 text-left">
				<h2 class="title-bold">
					{{ $t("openFixedTermDeposit.openFixedTermDeposit") }}
				</h2>
				<hr />
				<div v-if="isloading2" class="text-center">
					<div class="background-outline">
						<b-spinner
							style="width: 3rem; height: 3rem;"
							class="ml-auto text-center"
							:variant="secondary"
						></b-spinner>
					</div>
					<br />
				</div>
				<div v-else class="background-outline">
					<div class="background-modifier">
						<label class="text-left small">{{
							$t("openFixedTermDeposit.sourceAccount")
						}}</label>
						<ValidationProvider
							rules="required"
							name="debitAccountId"
							v-slot="{ valid, errors }"
						>
							<b-form-group
								id="debitAccountIdInputGroup"
								label-for="debitAccountIdInput"
							>
								<b-form-select
									@change="onChange"
									id="debitAccountIdInput"
									:state="errors[0] ? false : valid ? true : null"
									v-model="debitAccountId"
									><option
										v-for="account in accounts"
										:value="account.id"
										:key="account.id"
										>{{ account.id }}
										{{ $n(account.credits - account.debits, "decimal") }}
										{{ account.currency }}</option
									>
									<template #first>
										<b-form-select-option value="" disabled>{{
											$t("openFixedTermDeposit.sourceAccount")
										}}</b-form-select-option>
									</template></b-form-select
								>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>
					</div>
					<br />
					<!--    <ValidationProvider rules="required" name="currency" v-slot="{ valid, errors }">
		<b-form-group id="accountCurrencyInputGroup" label-for="accountCurrencyInput">
		  <b-form-select 
		  id="accountCurrencyInput"
		  :state="errors[0] ? false : (valid ? true : null)"
		  v-model="currency"
		 ><option v-for="currency in currencies" :value="currency.code" :key="currency.code">{{currency.code}}</option>
		 <template #first>
		   <b-form-select-option value="" disabled>{{ $t('openFixedTermDeposit.currency') }}</b-form-select-option>
		 </template></b-form-select>
		  <b-form-invalid-feedback id="inputLiveFeedback">{{ errors[0] }}</b-form-invalid-feedback>
		</b-form-group>
	  </ValidationProvider> -->
					<div class="background-modifier">
						<label class="text-left small">{{
							$t("openFixedTermDeposit.depositAmount")
						}}</label>
						<ValidationProvider
							rules="required|double|min_value:0.01"
							name="amount"
							v-slot="{ valid, errors }"
						>
							<b-form-group label-for="amountInput" description="">
								<b-form-input
									class="custom-amount"
									@input="calculateInterestAmount"
									type="text"
									v-model="amount"
									:state="errors[0] ? false : valid ? true : null"
									:placeholder="$t('openFixedTermDeposit.depositAmount')"
								></b-form-input>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>
					</div>
					<br />

					<div class="background-modifier">
						<div class="product-details">
							<label class="small"
								>{{ $t("openFixedTermDeposit.term") }}
							</label>
							<p class="text-left">{{ $tc("month", term) }}</p>
							<vue-slider
								@input="calculateInterestAmount"
								v-model="term"
								:data="[1, 3, 6, 12, 24, 36, 48, 60]"
							/>
							<br />
							<!--   <p>
		{{ $t('openFixedTermDeposit.interestRate') }} 
		<span v-if="term==1">{{  $n(rates.one, 'decimal') }}</span>
		<span v-if="term==3">{{  $n(rates.three, 'decimal') }}</span>
		<span v-if="term==6">{{  $n(rates.six, 'decimal') }}</span>
		<span v-if="term==12">{{  $n(rates.twelve, 'decimal') }}</span>
		<span v-if="term==24">{{  $n(rates.twentyfour, 'decimal') }}</span>
		<span v-if="term==36">{{  $n(rates.thirtysix, 'decimal') }}</span>
		<span v-if="term==48">{{  $n(rates.fortyeight, 'decimal') }}</span>
		<span v-if="term==60">{{  $n(rates.sixty, 'decimal') }}</span>
		
		% {{ $t('openFixedTermDeposit.pa') }}
	  </p> -->
							<div class="row">
								<div class="col-md-6">
									<div v-if="rates">
										<label class="text-left small">{{
											$t("openFixedTermDeposit.interestRate")
										}}</label>
										<p>
											{{ $n(getInterestRate(), "decimal") }}%
											{{ $t("openFixedTermDeposit.pa") }}
										</p>
									</div>
								</div>
								<div class="col-md-6 text-right">
									<label v-if="amount > 0" class="small">{{
										$t("openFixedTermDeposit.interestAmount")
									}}</label>
									<p v-if="amount > 0">
										{{ $n(calculateInterestAmount(), "decimal") }}
									</p>
								</div>
							</div>

							<div class="row">
								<div class="col-md-6">
									<label class="text-left small">{{
										$t("openFixedTermDeposit.startDate")
									}}</label>
									<p class="text-left">
										{{ $d(getStartDate(), "short") }}
									</p>
								</div>
								<div class="col-md-6 text-right">
									<label class="text-right small">{{
										$t("openFixedTermDeposit.endDate")
									}}</label>
									<p class="text-right">
										{{ $d(getEndDate(), "short") }}
									</p>
								</div>
							</div>
						</div>
					</div>
					<br />

					<div class="background-modifier">
						<label class="text-left small">{{
							$t("openFixedTermDeposit.nickname")
						}}</label>
						<ValidationProvider
							rules="alpha_spaces|min:3|max:50"
							name="nickname"
							v-slot="{ valid, errors }"
						>
							<b-form-group
								label-for="nicknameInput"
								:description="$t('openFixedTermDeposit.nicknameVoluntary')"
							>
								<b-form-input
									type="text"
									v-model="nickname"
									:state="errors[0] ? false : valid ? true : null"
									:placeholder="$t('openFixedTermDeposit.nickname')"
								></b-form-input>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>
					</div>
					<hr />

					<div class="">
						<b-form-checkbox
							class="text-left"
							id="checkbox-1"
							v-model="status"
							name="checkbox-1"
							value="accepted"
							unchecked-value="not_accepted"
						>
							{{ $t("openFixedTermDeposit.acceptTerms") }}
						</b-form-checkbox>
					</div>

					<div v-if="debitAccountId > 0">
						<br />
						<div v-if="amount > creditAmount.credits - debitAmount.debits">
							<b-alert variant="danger" show>{{
								$t("openFixedTermDeposit.insufficientBalance")
							}}</b-alert>
						</div>
					</div>

					<div
						v-if="
							status === 'accepted' &&
								creditAmount.credits - debitAmount.debits - amount >= 0
						"
					>
						<b-button
							type="submit"
							@click="isloading"
							class="btn btn-info text-light btn-block"
							>{{ $t("openFixedTermDeposit.submit")
							}}<b-spinner
								v-if="isloading"
								class="ml-auto"
								:variant="light"
							></b-spinner
						></b-button>
					</div>
					<hr />
					<b-button
						type="reset"
						class="btn btn-alert text-light btn-block"
						@click="resetForm()"
						>{{ $t("reset") }}</b-button
					>

					<div v-if="msg && $bvToast.show('notification-toast')"></div>
					<div class="action">
						<br />
						<b-toast
							id="notification-toast"
							variant="secondary"
							static
							no-auto-hide
						>
							<template #toast-title>
								<div class="d-flex flex-grow-1 align-items-baseline">
									<b-img
										class="logo mb-2"
										:src="require('/public/images/logo.png')"
										alt=""
										width="12"
										height="12"
									></b-img>
									<strong class="mr-auto">{{ $t("notification") }}</strong>
									<small class="text-muted mr-2"></small>
								</div>
							</template>
							{{ msg }}
						</b-toast>
					</div>
				</div>
			</div>
		</b-form>
	</ValidationObserver>
</template>

<script>
	import AuthService from "@/services/AuthService.js";
	import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
	import { required } from "vee-validate/dist/rules";
	import VueSlider from "vue-slider-component";
	import "vue-slider-component/theme/default.css";
	extend("required", required);

	export default {
		name: "OpenAccountForm",
		components: {
			ValidationObserver,
			ValidationProvider,
			VueSlider,
		},
		data() {
			const now = new Date();
			const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
			const startDate = new Date(today);

			return {
				//  currencies: [],
				oneMonth: "",
				accounts: [],
				currency: "EUR",
				nickname: this.$t("openFixedTermDeposit.myFTD"),
				userId: "",
				msg: "",
				acceptTerms: false,
				status: "not_accepted",
				interestAmount: "",
				isloading: false,
				isloading2: false,
				//  error: null,
				//  success: null,
				term: 12,
				//acceptTerms: "not_accepted",
			};
		},
		async created() {
			if (!this.$store.getters.isLoggedIn) {
				this.$router.push("login");
			}
			if (
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 3 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 15 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 14 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 13
			) {
				this.$router.push("overview");
			}
			let lang = this.$i18n.locale;
			this.role = this.$store.getters.getUser.role;
			this.username = this.$store.getters.getUser.username;
			this.firstname = this.$store.getters.getUser.firstname;
		},
		methods: {
			async onChange(event) {
				try {
					const id = this.debitAccountId;
					const response1 = await AuthService.getCreditAmount(id);
					this.creditAmount = response1;

					//  console.log(response1);
					const response2 = await AuthService.getDebitAmount(id);
					this.debitAmount = response2;

					//   console.log(response2);
					const balance1 = this.creditAmount.credits - this.debitAmount.debits;
					this.balance = balance1;
					console.log(balance);
				} catch (error) {
					console.log(error);
				}
			},

			getInterestRate() {
				try {
					if (this.term === 1) {
						const interestRate = this.rates.one;
						return interestRate;
					} else if (this.term == 3) {
						const interestRate = this.rates.three;
						return interestRate;
					} else if (this.term == 6) {
						const interestRate = this.rates.six;
						return interestRate;
					} else if (this.term == 12) {
						const interestRate = this.rates.twelve;
						return interestRate;
					} else if (this.term == 24) {
						const interestRate = this.rates.twentyfour;
						return interestRate;
					} else if (this.term == 36) {
						const interestRate = this.rates.thirtysix;
						return interestRate;
					} else if (this.term == 48) {
						const interestRate = this.rates.fortyeight;
						return interestRate;
					} else if (this.term == 60) {
						const interestRate = this.rates.sixty;
						return interestRate;
					}
					//   return interestAmount;
				} catch (error) {
					console.log(error);
				}
			},
			calculateInterestAmount() {
				try {
					if (this.term === 1) {
						const interestAmount =
							(((this.amount * this.term) / 12) * this.rates.one) / 100;
						return interestAmount;
					} else if (this.term == 3) {
						const interestAmount =
							(((this.amount * this.term) / 12) * this.rates.three) / 100;
						return interestAmount;
					} else if (this.term == 6) {
						const interestAmount =
							(((this.amount * this.term) / 12) * this.rates.six) / 100;
						return interestAmount;
					} else if (this.term == 12) {
						const interestAmount =
							(((this.amount * this.term) / 12) * this.rates.twelve) / 100;
						return interestAmount;
					} else if (this.term == 24) {
						const interestAmount =
							(((this.amount * this.term) / 12) * this.rates.twentyfour) / 100;
						return interestAmount;
					} else if (this.term == 36) {
						const interestAmount =
							(((this.amount * this.term) / 12) * this.rates.thirtysix) / 100;
						return interestAmount;
					} else if (this.term == 48) {
						const interestAmount =
							(((this.amount * this.term) / 12) * this.rates.fortyeight) / 100;
						return interestAmount;
					} else if (this.term == 60) {
						const interestAmount =
							(((this.amount * this.term) / 12) * this.rates.sixty) / 100;
						return interestAmount;
					}
					//   return interestAmount;
				} catch (error) {
					console.log(error);
				}
			},
			getStartDate() {
				const now = new Date();
				const today = new Date(
					now.getFullYear(),
					now.getMonth(),
					now.getDate()
				);
				const startDate = new Date(today);

				return startDate;
			},
			getEndDate() {
				const now = new Date();
				const today = new Date(
					now.getFullYear(),
					now.getMonth(),
					now.getDate()
				);
				const startDate = new Date(today);

				const endDate = new Date(today); // add arguments as needed
				endDate.setMonth(endDate.getMonth() + this.term);

				return endDate;
			},

			/*    async getAllAccountCurrencies() {
          try {
            const response = await AuthService.getAllAccountCurrencies();
            this.currencies = response;
            console.log(response);
          } catch (error) {
            console.log(error);
          }
        }, */
			// async getUsersCurrencyAccountList() {
			//   try {
			//     const details = {
			//       id: this.$store.getters.getUser.id,
			//       currency: "EUR",
			//     };
			//     const response = await AuthService.getUsersCurrencyAccountList(
			//       details.id,
			//       details.currency
			//     );
			//     this.accounts = response;
			//     console.log(response);
			//   } catch (error) {
			//     console.log(error);
			//   }
			// },
			async getClientsEURCurrentAccounts() {
				try {
					const id = this.$store.getters.getUser.id;
					this.isloading2 = true;
					const response = await AuthService.getClientsEURCurrentAccounts(id);
					this.accounts = response;
					this.isloading2 = false;
					console.log(response);
				} catch (error) {
					this.isloading2 = false;
					console.log(error);
				}
			},
			async getTermDepositRates() {
				try {
					const response = await AuthService.getTermDepositRates();
					this.rates = response;
					console.log(response);
				} catch (error) {
					console.log(error);
				}
			},
			async openBusinessFixedTermDeposit() {
				try {
					const details = {
						lang: this.$i18n.locale,
						currency: this.currency,
						nickname: this.nickname,
						userId: this.$store.getters.getUser.id,
						startDate: this.getStartDate(),
						endDate: this.getEndDate(),
						interestRate: this.getInterestRate(),
						debitAccountId: this.debitAccountId,
						amount: this.amount,
						message: this.$t("openFixedTermDeposit.fixedTermDeposit"),
					};
					console.log(details);
					this.isloading = true;
					const response = await AuthService.openBusinessFixedTermDeposit(
						details
					);
					this.resetForm();
					this.msg = response.msg;
					this.isloading = false;
				} catch (error) {
					this.msg = error.response.data.msg;
					this.isloading = false;
					/*this.$router.push('accounts');*/
				}
			},
			resetForm() {
				this.nickname = "";
				this.debitAccountId = "";
				this.amount = "";
				this.msg = "";
				requestAnimationFrame(() => {
					this.$refs.observer.reset();
				});
			},
		},
		mounted() {
			//   this.getAllAccountCurrencies();
			// this.getUsersCurrencyAccountList();
			this.getTermDepositRates();
			this.getClientsEURCurrentAccounts();
		},
	};
</script>
