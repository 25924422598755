var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.newBeneficiary)},"reset":function($event){$event.preventDefault();return reset($event)}}},[_c('div',{staticClass:"col-lg-4 offset-lg-4 text-left"},[_c('h2',{staticClass:"title-bold"},[_vm._v(_vm._s(_vm.$t("newBeneficiary.createBeneficiary")))]),_c('hr'),_c('div',{staticClass:"background-outline"},[_c('div',{staticClass:"background-modifier"},[_c('label',{staticClass:"text-left small"},[_vm._v(_vm._s(_vm.$t("newBeneficiary.beneficiaryAccount")))]),_c('ValidationProvider',{attrs:{"rules":"integer|min:1|max:4","name":"beneficiaryAccount"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"beneficiaryAccountInput","description":""}},[_c('b-form-input',{attrs:{"type":"text","state":errors[0] ? false : valid ? true : null,"placeholder":_vm.$t('newBeneficiary.beneficiaryAccount')},model:{value:(_vm.beneficiaryAccount),callback:function ($$v) {_vm.beneficiaryAccount=$$v},expression:"beneficiaryAccount"}}),_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('br'),_c('div',{staticClass:"background-modifier"},[_c('label',{staticClass:"text-left small"},[_vm._v(_vm._s(_vm.$t("newBeneficiary.beneficiaryName")))]),_c('ValidationProvider',{attrs:{"rules":"alpha_spaces|min:3|max:50","name":"beneficiaryName"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"beneficiaryNameInput","description":""}},[_c('b-form-input',{attrs:{"type":"text","state":errors[0] ? false : valid ? true : null,"placeholder":_vm.$t('newBeneficiary.beneficiaryName')},model:{value:(_vm.beneficiaryName),callback:function ($$v) {_vm.beneficiaryName=$$v},expression:"beneficiaryName"}}),_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('br'),_c('div',{staticClass:"background-modifier"},[_c('label',{staticClass:"text-left small"},[_vm._v(_vm._s(_vm.$t("newBeneficiary.alias")))]),_c('ValidationProvider',{attrs:{"rules":"alpha_spaces|min:3|max:50","name":"alias"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"aliasInput","description":""}},[_c('b-form-input',{attrs:{"type":"text","state":errors[0] ? false : valid ? true : null,"placeholder":_vm.$t('newBeneficiary.alias')},model:{value:(_vm.alias),callback:function ($$v) {_vm.alias=$$v},expression:"alias"}}),_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('br'),_c('b-button',{staticClass:"btn btn-info text-light btn-block",attrs:{"type":"submit"},on:{"click":_vm.isloading}},[_vm._v(_vm._s(_vm.$t("newBeneficiary.submit"))),(_vm.isloading)?_c('b-spinner',{staticClass:"ml-auto",attrs:{"variant":_vm.light}}):_vm._e()],1),_c('hr'),_c('b-button',{staticClass:"btn btn-alert text-light btn-block",attrs:{"type":"reset"},on:{"click":function($event){return _vm.resetForm()}}},[_vm._v(_vm._s(_vm.$t("reset")))]),(_vm.msg && _vm.$bvToast.show('notification-toast'))?_c('div'):_vm._e(),_c('div',{staticClass:"action"},[_c('br'),_c('b-toast',{attrs:{"id":"notification-toast","variant":"secondary","static":"","no-auto-hide":""},scopedSlots:_vm._u([{key:"toast-title",fn:function(){return [_c('div',{staticClass:"d-flex flex-grow-1 align-items-baseline"},[_c('b-img',{staticClass:"logo mb-2",attrs:{"src":require('/public/images/logo.png'),"alt":"","width":"12","height":"12"}}),_c('strong',{staticClass:"mr-auto"},[_vm._v(_vm._s(_vm.$t("notification")))]),_c('small',{staticClass:"text-muted mr-2"})],1)]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(_vm.msg)+" ")])],1)],1)])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }