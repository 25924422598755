import axios from "axios";

const url = /*"https://localhost/api/";*/ "https://www.n3consortium.com/api/";

export default {
	login(credentials) {
		return axios
			.post(url + "login/", credentials)
			.then((response) => response.data);
	},
	signUp(reference, credentials) {
		return axios
			.post(url + "sign-up/" + reference, credentials)
			.then((response) => response.data);
	},
	signUpIndividual(reference, credentials) {
		return axios
			.post(url + "sign-up-individual/" + reference, credentials)
			.then((response) => response.data);
	},
	signUpBusiness(reference, credentials) {
		return axios
			.post(url + "sign-up-business/" + reference, credentials)
			.then((response) => response.data);
	},
	addBusinessDetails(credentials) {
		return axios
			.post(url + "business/add-business-details/", credentials)
			.then((response) => response.data);
	},
	getBusinessDetails(id) {
		return axios
			.get(url + "business/business-details/" + id)
			.then((response) => response.data);
	},
	getAllBusinessDetails(id) {
		return axios
			.get(url + "business/all-business-details/" + id)
			.then((response) => response.data);
	},
	editBusinessDetails(id, details) {
		return axios
			.put(url + "business/edit-business-details/" + id, details)
			.then((response) => response.data);
	},
	addBusinessRole(credentials) {
		return axios
			.post(url + "business/add-business-role/", credentials)
			.then((response) => response.data);
	},
	getBusinessRoles(id) {
		return axios
			.get(url + "business/all-business-roles/" + id)
			.then((response) => response.data);
	},
	getBusinessRoleDetails(id) {
		return axios
			.get(url + "business/business-role-details/" + id)
			.then((response) => response.data);
	},
	editBusinessRoleDetails(id, details) {
		return axios
			.put(url + "business/edit-business-role-details/" + id, details)
			.then((response) => response.data);
	},
	getBusinessDocs(id) {
		return axios
			.get(url + "business-docs/" + id)
			.then((response) => response.data);
	},
	getPaymentDocs(id) {
		return axios
			.get(url + "payment-docs/" + id)
			.then((response) => response.data);
	},
	getCSVDocs(id) {
		return axios
			.get(url + "csv/download/" + id)
			.then((response) => response.data);
	},
	getExcelDocs(id) {
		return axios
			.get(url + "excel/download/" + id)
			.then((response) => response.data);
	},
	getNipRequestDocs(id) {
		return axios
			.get(url + "nip-request-docs/" + id)
			.then((response) => response.data);
	},
	getDirectDebitDocs(id) {
		return axios
			.get(url + "direct-debit-docs/" + id)
			.then((response) => response.data);
	},
	updateApplicationStatus(id, details) {
		return axios
			.put(url + "business/update-application-status/" + id, details)
			.then((response) => response.data);
	},
	createOffer(details) {
		return axios
			.post(url + "business/create-offer", details)
			.then((response) => response.data);
	},
	getClientsOfferList(id) {
		return axios
			.get(url + "business/clients-offer-list/" + id)
			.then((response) => response.data);
	},
	getOfferById(id) {
		return axios
			.get(url + "business/offers/" + id)
			.then((response) => response.data);
	},
	editOfferDetails(id, details) {
		return axios
			.put(url + "business/edit-offer-details/" + id, details)
			.then((response) => response.data);
	},
	getClientsCardList(id) {
		return axios
			.get(url + "business/clients-card-list/" + id)
			.then((response) => response.data);
	},
	getClientsCardApplications(id) {
		return axios
			.get(url + "business/clients-card-applications/" + id)
			.then((response) => response.data);
	},
	deleteCardApplication(id) {
		return axios
			.delete(url + "business/delete-card-application/" + id)
			.then((response) => response.data);
	},
	createBusinessCard(details) {
		return axios
			.post(url + "business/cards/", details)
			.then((response) => response.data);
	},
	manageBusinessCardDetails(id, details) {
		return axios
			.put(url + "business/edit-card-details/" + id, details)
			.then((response) => response.data);
	},
	getClientsUsers(id) {
		return axios
			.get(url + "business/users/" + id)
			.then((response) => response.data);
	},
	getClientsUserList(page, size, lastname, id) {
		return axios
			.get(
				url + "business/users/" + page + "/" + size + "/" + lastname + "/" + id
			)
			.then((response) => response);
	},
	createNewBusinessUser(details) {
		return axios
			.post(url + "business/users/", details)
			.then((response) => response.data);
	},
	getBusinessUser(id) {
		return axios
			.get(url + "business/get-business-user/" + id)
			.then((response) => response.data);
	},
	editBusinessUser(id, details) {
		return axios
			.put(url + "business/users/" + id, details)
			.then((response) => response.data);
	},
	getClientsAccounts(id) {
		return axios
			.get(url + "business/accounts/" + id)
			.then((response) => response.data);
	},
	getClientsCurrentAccounts(id) {
		return axios
			.get(url + "business/current-accounts/" + id)
			.then((response) => response.data);
	},
	getClientsSavingsAccounts(id) {
		return axios
			.get(url + "business/savings-accounts/" + id)
			.then((response) => response.data);
	},
	getClientsTermAccounts(id) {
		return axios
			.get(url + "business/term-accounts/" + id)
			.then((response) => response.data);
	},
	getClientsEURCurrentAccounts(id) {
		return axios
			.get(url + "business/eur-current-accounts/" + id)
			.then((response) => response.data);
	},
	getClientsAccountList(page, size, nickname, id) {
		return axios
			.get(
				url +
					"business/accounts/" +
					page +
					"/" +
					size +
					"/" +
					nickname +
					"/" +
					id
			)
			.then((response) => response);
	},
	openNewBusinessAccount(details) {
		return axios
			.post(url + "business/open-account/", details)
			.then((response) => response.data);
	},
	openNewBusinessSavingsAccount(details) {
		return axios
			.post(url + "business/savings-account/", details)
			.then((response) => response.data);
	},
	getBusinessAccountDetails(id) {
		return axios
			.get(url + "business/account-details/" + id)
			.then((response) => response.data);
	},
	editBusinessAccount(id, details) {
		return axios
			.put(url + "business/edit-account/" + id, details)
			.then((response) => response.data);
	},
	editAccountUserRights(id, details) {
		return axios
			.put(url + "business/edit-account-user/" + id, details)
			.then((response) => response.data);
	},
	deleteBusinessAccountUser(id) {
		return axios
			.delete(url + "business/delete-account-user/" + id)
			.then((response) => response.data);
	},
	getBusinessAccountUsers(id) {
		return axios
			.get(url + "business/account-users/" + id)
			.then((response) => response.data);
	},
	getBusinessAccountUserDetails(id) {
		return axios
			.get(url + "business/account-user/" + id)
			.then((response) => response.data);
	},
	createAccountUser(details) {
		return axios
			.post(url + "business/create-account-user/", details)
			.then((response) => response.data);
	},
	getTransactionDetails(id) {
		return axios
			.get(url + "business/transactions/" + id)
			.then((response) => response.data);
	},
	getCreditAccountIBAN(id) {
		return axios
			.get(url + "business/get-credit-account-iban/" + id)
			.then((response) => response.data);
	},
	getDebitAccountIBAN(id) {
		return axios
			.get(url + "business/get-debit-account-iban/" + id)
			.then((response) => response.data);
	},

	getPaymentOrderCreditAccountIBAN(id) {
		return axios
			.get(url + "business/get-payment-order-credit-account-iban/" + id)
			.then((response) => response.data);
	},
	getPaymentOrderDebitAccountIBAN(id) {
		return axios
			.get(url + "business/get-payment-order-debit-account-iban/" + id)
			.then((response) => response.data);
	},
	getStandingOrderCreditAccountIBAN(id) {
		return axios
			.get(url + "business/get-standing-order-credit-account-iban/" + id)
			.then((response) => response.data);
	},
	getStandingOrderDebitAccountIBAN(id) {
		return axios
			.get(url + "business/get-standing-order-debit-account-iban/" + id)
			.then((response) => response.data);
	},

	getExpenseReceipts(id) {
		return axios
			.get(url + "expense-receipts/" + id)
			.then((response) => response.data);
	},

	verifyEmail(lang, userID, token) {
		return axios
			.get(url + lang + "/verify/" + userID + "/" + token)
			.then((response) => response.data);
	},
	forgotPassword(credentials) {
		return axios
			.post(url + "forgot-password/", credentials)
			.then((response) => response.data);
	},
	resetPassword(credentials) {
		return axios
			.post(url + "reset-password/", credentials)
			.then((response) => response.data);
	},
	getUserContent(lang) {
		return axios
			.get(url + lang + "/user-route/")
			.then((response) => response.data);
	},
	getAdminContent(lang) {
		return axios
			.get(url + lang + "/admin-route/")
			.then((response) => response.data);
	},
	openAccount(details) {
		return axios
			.post(url + "open-account/", details)
			.then((response) => response.data);
	},

	getUsersAccountList(id) {
		return axios
			.get(url + "users-account-list/" + id)
			.then((response) => response.data);
	},
	getUsersAccounts(id, page, size, nickname) {
		return axios
			.get(
				url + "users-accounts/" + id + "/" + page + "/" + size + "/" + nickname
			)
			.then((response) => response);
	},
	getAccountById(id) {
		return axios.get(url + "accounts/" + id).then((response) => response.data);
	},

	updateAccount(id, details) {
		return axios
			.put(url + "accounts/" + id, details)
			.then((response) => response.data);
	},
	/*deleteAccount(id) {
    return axios
      .delete(url + 'accounts/' + id)
      .then(response => response.data);
  },*/
	getUsersCardList(id) {
		return axios
			.get(url + "users-card-list/" + id)
			.then((response) => response.data);
	},
	getCardById(id) {
		return axios.get(url + "cards/" + id).then((response) => response.data);
	},

	newPayment(details) {
		return axios
			.post(url + "new-payment/", details)
			.then((response) => response.data);
	},
	newExtendedPayment(details) {
		return axios
			.post(url + "business/new-extended-payment/", details)
			.then((response) => response.data);
	},
	getAccountPayments(id, page, size, title) {
		return axios
			.get(url + "payments/" + id + "/" + page + "/" + size + "/" + title)
			.then((response) => response);
	},
	getDatedAccountPayments(id, startDate, endDate, page, size, title) {
		return axios
			.get(
				url +
					"dated-payments/" +
					id +
					"/" +
					startDate +
					"/" +
					endDate +
					"/" +
					page +
					"/" +
					size +
					"/" +
					title
			)
			.then((response) => response);
	},
	getDatedAccountTx(id, startDate, endDate) {
		return axios
			.get(
				url +
					"business/dated-account-tx/" +
					id +
					"/" +
					startDate +
					"/" +
					endDate
			)
			.then((response) => response.data);
	},
	getPaymentById(id) {
		return axios.get(url + "payments/" + id).then((response) => response.data);
	},
	getTxCreditAccount(id) {
		return axios
			.get(url + "payments/credit-account/" + id)
			.then((response) => response.data);
	},
	getTxDebitAccount(id) {
		return axios
			.get(url + "payments/debit-account/" + id)
			.then((response) => response.data);
	},
	getCreditAmount(id) {
		return axios
			.get(url + "payments/credit-amount/" + id)
			.then((response) => response.data);
	},
	getDebitAmount(id) {
		return axios
			.get(url + "payments/debit-amount/" + id)
			.then((response) => response.data);
	},
	getDatedCreditAmount(id, startDate, endDate) {
		return axios
			.get(
				url +
					"dated-payments/credit-amount/" +
					id +
					"/" +
					startDate +
					"/" +
					endDate
			)
			.then((response) => response.data);
	},
	getDatedDebitAmount(id, startDate, endDate) {
		return axios
			.get(
				url +
					"dated-payments/debit-amount/" +
					id +
					"/" +
					startDate +
					"/" +
					endDate
			)
			.then((response) => response.data);
	},
	getAccountCurrency(id) {
		return axios
			.get(url + "account-currency/" + id)
			.then((response) => response.data);
	},
	/*deletePayment(id) {
    return axios
      .delete(url + 'payments/' + id)
      .then(response => response.data);
  },*/
	getTotalUsers() {
		return axios
			.get(url + "admin/total-users/")
			.then((response) => response.data);
	},
	getTotalAccounts() {
		return axios
			.get(url + "admin/total-accounts/")
			.then((response) => response.data);
	},
	getTotalTransactions() {
		return axios
			.get(url + "admin/total-transactions/")
			.then((response) => response.data);
	},

	getAllIndividualClients(page, size, lastname) {
		return axios
			.get(
				url + "admin/individual-clients/" + page + "/" + size + "/" + lastname
			)
			.then((response) => response);
	},
	getAllBusinessClients(page, size, name) {
		return axios
			.get(url + "admin/business-clients/" + page + "/" + size + "/" + name)
			.then((response) => response);
	},
	getTotalClients() {
		return axios
			.get(url + "admin/total-clients/")
			.then((response) => response.data);
	},
	getTotalIndividualClients() {
		return axios
			.get(url + "admin/total-individual-clients/")
			.then((response) => response.data);
	},
	getTotalBusinessClients() {
		return axios
			.get(url + "admin/total-business-clients/")
			.then((response) => response.data);
	},
	getAllIndividualClientApplications(page, size, lastname) {
		return axios
			.get(
				url +
					"admin/individual-client-applications/" +
					page +
					"/" +
					size +
					"/" +
					lastname
			)
			.then((response) => response);
	},
	getAllBusinessClientApplications(page, size, name) {
		return axios
			.get(
				url +
					"admin/business-client-applications/" +
					page +
					"/" +
					size +
					"/" +
					name
			)
			.then((response) => response);
	},
	getTotalClientApplications() {
		return axios
			.get(url + "admin/total-client-applications/")
			.then((response) => response.data);
	},
	getTotalIndividualClientApplications() {
		return axios
			.get(url + "admin/total-individual-client-applications/")
			.then((response) => response.data);
	},
	getTotalBusinessClientApplications() {
		return axios
			.get(url + "admin/total-business-client-applications/")
			.then((response) => response.data);
	},
	manageApplicationStatus(id, details) {
		return axios
			.put(url + "admin/manage-application-status/" + id, details)
			.then((response) => response.data);
	},
	editIndividualClient(id, details) {
		return axios
			.put(url + "admin/edit-individual-client/" + id, details)
			.then((response) => response.data);
	},
	getClient(id) {
		return axios
			.get(url + "admin/clients/" + id)
			.then((response) => response.data);
	},
	getAdminBusinessRoles(id) {
		return axios
			.get(url + "admin/all-business-roles/" + id)
			.then((response) => response.data);
	},
	getAdminBusinessDetails(id) {
		return axios
			.get(url + "admin/business-details/" + id)
			.then((response) => response.data);
	},
	getAdminAllBusinessDetails(id) {
		return axios
			.get(url + "admin/all-business-details/" + id)
			.then((response) => response.data);
	},

	getAllUsers(page, size, lastname) {
		return axios
			.get(url + "admin/users/" + page + "/" + size + "/" + lastname)
			.then((response) => response);
	},
	getUser(id) {
		return axios
			.get(url + "admin/users/" + id)
			.then((response) => response.data);
	},
	newUser(details) {
		return axios
			.post(url + "admin/users/", details)
			.then((response) => response.data);
	},
	editUser(id, details) {
		return axios
			.put(url + "admin/users/" + id, details)
			.then((response) => response.data);
	},
	deleteUser(id, lang) {
		return axios
			.delete(url + lang + "/admin/users/" + id)
			.then((response) => response.data);
	},
	getUserAccounts(id, page, size, nickname) {
		return axios
			.get(
				url +
					"admin/user-accounts/" +
					id +
					"/" +
					page +
					"/" +
					size +
					"/" +
					nickname
			)
			.then((response) => response);
	},

	getAccountList() {
		return axios
			.get(url + "admin/account-list/")
			.then((response) => response.data);
	},
	getAllAccounts(page, size, nickname) {
		return axios
			.get(url + "admin/accounts/" + page + "/" + size + "/" + nickname)
			.then((response) => response);
	},
	getAccount(id) {
		return axios
			.get(url + "admin/accounts/" + id)
			.then((response) => response.data);
	},
	getClientAccounts(id) {
		return axios
			.get(url + "admin/client-accounts/" + id)
			.then((response) => response.data);
	},
	getClientAccountList(page, size, nickname, id) {
		return axios
			.get(
				url +
					"admin/client-account-list/" +
					page +
					"/" +
					size +
					"/" +
					nickname +
					"/" +
					id
			)
			.then((response) => response);
	},
	getAccountTransactions(id, page, size, title) {
		return axios
			.get(
				url +
					"admin/account-transactions/" +
					id +
					"/" +
					page +
					"/" +
					size +
					"/" +
					title
			)
			.then((response) => response);
	},
	getAccountTx(id) {
		return axios
			.get(url + "business/account-tx/" + id)
			.then((response) => response.data);
	},
	getBusinessAccountTransactions(id, page, size, title) {
		return axios
			.get(
				url +
					"business/account-transactions/" +
					id +
					"/" +
					page +
					"/" +
					size +
					"/" +
					title
			)
			.then((response) => response);
	},
	newAccount(details) {
		return axios
			.post(url + "admin/accounts/", details)
			.then((response) => response.data);
	},
	editAccount(id, details) {
		return axios
			.put(url + "admin/accounts/" + id, details)
			.then((response) => response.data);
	},
	deleteAccount(id, lang) {
		return axios
			.delete(url + lang + "/admin/accounts/" + id)
			.then((response) => response.data);
	},

	getAllTransactions(page, size, title) {
		return axios
			.get(url + "admin/transactions/" + page + "/" + size + "/" + title)
			.then((response) => response);
	},
	getTransaction(id) {
		return axios
			.get(url + "admin/transactions/" + id)
			.then((response) => response.data);
	},
	newTransaction(details) {
		return axios
			.post(url + "admin/transactions/", details)
			.then((response) => response.data);
	},
	editTransaction(id, details) {
		return axios
			.put(url + "admin/transactions/" + id, details)
			.then((response) => response.data);
	},
	deleteTransaction(id, lang) {
		return axios
			.delete(url + lang + "/admin/transactions/" + id)
			.then((response) => response.data);
	},

	getCurrencyData() {
		return axios.get(url + "currencydata/").then((response) => response);
	},

	getAllAccountCurrencies() {
		return axios.get(url + "currencies/").then((response) => response.data);
	},

	getIdDocs(id) {
		return axios.get(url + "id-docs/" + id).then((response) => response.data);
	},
	getAllIdDocs(page, size, title) {
		return axios
			.get(url + "admin/id-docs/" + page + "/" + size + "/" + title)
			.then((response) => response);
	},

	getBalanceData(id) {
		return axios
			.get(url + "balance-data/" + id)
			.then((response) => response.data);
	},
	getClientsTotalRunningBalance(id) {
		return axios
			.get(url + "business/total-running-balance-data/" + id)
			.then((response) => response.data);
	},
	getClientsMonthlyNetCashFlow(id) {
		return axios
			.get(url + "business/monthly-net-cash-flow/" + id)
			.then((response) => response.data);
	},
	getClientsMonthlyCredits(id) {
		return axios
			.get(url + "business/monthly-credits/" + id)
			.then((response) => response.data);
	},
	getClientsMonthlyDebits(id) {
		return axios
			.get(url + "business/monthly-debits/" + id)
			.then((response) => response.data);
	},

	getBeneficiary(id) {
		return axios
			.get(url + "beneficiaries/" + id)
			.then((response) => response.data);
	},
	newBeneficiary(details) {
		return axios
			.post(url + "beneficiaries/", details)
			.then((response) => response.data);
	},
	editBeneficiary(id, details) {
		return axios
			.put(url + "/beneficiaries/" + id, details)
			.then((response) => response.data);
	},
	deleteBeneficiary(id, lang) {
		return axios
			.delete(url + lang + "/beneficiaries/" + id)
			.then((response) => response.data);
	},
	getUsersBeneficiaries(id, page, size, title) {
		return axios
			.get(url + "beneficiaries/" + id + "/" + page + "/" + size + "/" + title)
			.then((response) => response);
	},
	getUsersBeneficiaryList(id) {
		return axios
			.get(url + "users-beneficiaries/" + id)
			.then((response) => response.data);
	},
	getBeneficiaryName(accountid, userid) {
		return axios
			.get(url + "beneficiary-name/" + accountid + "/" + userid)
			.then((response) => response.data);
	},
	createNewInvoice(details) {
		return axios
			.post(url + "business/clients-customer-invoices/", details)
			.then((response) => response.data);
	},
	getInvoiceDetails(id) {
		return axios
			.get(url + "business/clients-customer-invoices/" + id)
			.then((response) => response.data);
	},
	getInvoiceDetailsByReference(id) {
		return axios
			.get(url + "business/view-customer-invoices/" + id)
			.then((response) => response.data);
	},
	getInvoiceItemsByReference(page, size, title, id) {
		return axios
			.get(
				url +
					"business/view-customer-invoice-items/" +
					page +
					"/" +
					size +
					"/" +
					title +
					"/" +
					id
			)
			.then((response) => response);
	},
	getLatestInvoice(id) {
		return axios
			.get(url + "business/clients-latest-invoice/" + id)
			.then((response) => response.data);
	},
	editInvoiceDetails(id, details) {
		return axios
			.put(url + "business/clients-customer-invoices/" + id, details)
			.then((response) => response.data);
	},

	addNewCustomer(details) {
		return axios
			.post(url + "business/clients-customers/", details)
			.then((response) => response.data);
	},
	getCustomerDetails(id) {
		return axios
			.get(url + "business/clients-customer-details/" + id)
			.then((response) => response.data);
	},
	editCustomerDetails(id, credentials) {
		return axios
			.put(url + "business/clients-customers/" + id, credentials)
			.then((response) => response.data);
	},
	getClientsCustomers(page, size, nickname, id) {
		return axios
			.get(
				url +
					"business/clients-customers/" +
					page +
					"/" +
					size +
					"/" +
					nickname +
					"/" +
					id
			)
			.then((response) => response);
	},
	getClientsCustomerList(id) {
		return axios
			.get(url + "business/clients-customers/" + id)
			.then((response) => response.data);
	},
	deleteCustomer(id, lang) {
		return axios
			.delete(url + lang + "/business/clients-customers/" + id)
			.then((response) => response.data);
	},
	addNewProduct(details) {
		return axios
			.post(url + "business/clients-products/", details)
			.then((response) => response.data);
	},
	getProductDetails(id) {
		return axios
			.get(url + "business/clients-product-details/" + id)
			.then((response) => response.data);
	},
	editProductDetails(id, credentials) {
		return axios
			.put(url + "business/clients-products/" + id, credentials)
			.then((response) => response.data);
	},
	getClientsProducts(page, size, title, id) {
		return axios
			.get(
				url +
					"business/clients-products/" +
					page +
					"/" +
					size +
					"/" +
					title +
					"/" +
					id
			)
			.then((response) => response);
	},
	getClientsProductList(id) {
		return axios
			.get(url + "business/clients-products/" + id)
			.then((response) => response.data);
	},
	deleteProduct(id, lang) {
		return axios
			.delete(url + lang + "/business/clients-products/" + id)
			.then((response) => response.data);
	},
	addNewInvoiceItem(details) {
		return axios
			.post(url + "business/clients-customer-invoice-items/", details)
			.then((response) => response.data);
	},
	getInvoiceItems(page, size, title, id) {
		return axios
			.get(
				url +
					"business/clients-customer-invoice-items/" +
					page +
					"/" +
					size +
					"/" +
					title +
					"/" +
					id
			)
			.then((response) => response);
	},
	getInvoiceItemDetails(id) {
		return axios
			.get(url + "business/clients-customer-invoice-item-details/" + id)
			.then((response) => response.data);
	},
	editInvoiceItem(id, details) {
		return axios
			.put(url + "/business/clients-customer-invoice-items/" + id, details)
			.then((response) => response.data);
	},
	deleteInvoiceItem(id, lang) {
		return axios
			.delete(url + lang + "/business/clients-customer-invoice-items/" + id)
			.then((response) => response.data);
	},

	getPaymentOrder(id) {
		return axios
			.get(url + "paymentorders/" + id)
			.then((response) => response.data);
	},
	newPaymentOrder(details) {
		return axios
			.post(url + "paymentorders/", details)
			.then((response) => response.data);
	},
	editPaymentOrder(id, details) {
		return axios
			.put(url + "/paymentorders/" + id, details)
			.then((response) => response.data);
	},
	deletePaymentOrder(id, lang) {
		return axios
			.delete(url + lang + "/paymentorders/" + id)
			.then((response) => response.data);
	},
	getUsersPaymentOrders(id, page, size, title) {
		return axios
			.get(url + "paymentorders/" + id + "/" + page + "/" + size + "/" + title)
			.then((response) => response);
	},
	getUsersPaymentOrderList(id) {
		return axios
			.get(url + "users-paymentorders/" + id)
			.then((response) => response.data);
	},
	getClientsPendingPaymentOrders(id, page, size, title) {
		return axios
			.get(
				url +
					"business/pending-paymentorders/" +
					id +
					"/" +
					page +
					"/" +
					size +
					"/" +
					title
			)
			.then((response) => response);
	},
	getClientsPendingStandingOrderList(id) {
		return axios
			.get(url + "business/clients-pending-standingorders/" + id)
			.then((response) => response.data);
	},
	getClientsPendingStandingOrders(id, page, size, title) {
		return axios
			.get(
				url +
					"business/pending-standingorders/" +
					id +
					"/" +
					page +
					"/" +
					size +
					"/" +
					title
			)
			.then((response) => response);
	},
	getClientsPendingPaymentOrderList(id) {
		return axios
			.get(url + "business/clients-pending-paymentorders/" + id)
			.then((response) => response.data);
	},

	getClientsPaymentOrders(id, page, size, title) {
		return axios
			.get(
				url +
					"business/paymentorders/" +
					id +
					"/" +
					page +
					"/" +
					size +
					"/" +
					title
			)
			.then((response) => response);
	},
	getClientsPaymentOrderList(id) {
		return axios
			.get(url + "business/clients-paymentorders/" + id)
			.then((response) => response.data);
	},
	getClientsStandingOrders(id, page, size, title) {
		return axios
			.get(
				url +
					"business/standingorders/" +
					id +
					"/" +
					page +
					"/" +
					size +
					"/" +
					title
			)
			.then((response) => response);
	},
	getClientsStandingOrderList(id) {
		return axios
			.get(url + "business/clients-standingorders/" + id)
			.then((response) => response.data);
	},

	getStandingOrder(id) {
		return axios
			.get(url + "standingorders/" + id)
			.then((response) => response.data);
	},
	newStandingOrder(details) {
		return axios
			.post(url + "standingorders/", details)
			.then((response) => response.data);
	},
	editStandingOrder(id, details) {
		return axios
			.put(url + "/standingorders/" + id, details)
			.then((response) => response.data);
	},
	deleteStandingOrder(id, lang) {
		return axios
			.delete(url + lang + "/standingorders/" + id)
			.then((response) => response.data);
	},
	getUsersStandingOrders(id, page, size, title) {
		return axios
			.get(url + "standingorders/" + id + "/" + page + "/" + size + "/" + title)
			.then((response) => response);
	},
	getUsersStandingOrderList(id) {
		return axios
			.get(url + "users-standingorders/" + id)
			.then((response) => response.data);
	},

	getClientsCustomerInvoices(id, page, size, title) {
		return axios
			.get(
				url +
					"business/clients-customer-invoices/" +
					id +
					"/" +
					page +
					"/" +
					size +
					"/" +
					title
			)
			.then((response) => response);
	},
	getClientsDraftInvoices(id, page, size, title) {
		return axios
			.get(
				url +
					"business/clients-draft-invoices/" +
					id +
					"/" +
					page +
					"/" +
					size +
					"/" +
					title
			)
			.then((response) => response);
	},
	deleteCustomerInvoice(id, lang) {
		return axios
			.delete(url + lang + "/business/clients-customer-invoices/" + id)
			.then((response) => response.data);
	},

	newNip(details) {
		return axios.post(url + "nip/", details).then((response) => response.data);
	},
	claimNip(details) {
		return axios
			.post(url + "claim-nip/", details)
			.then((response) => response.data);
	},
	getUsersPendingNips(email, page, size, title) {
		return axios
			.get(
				url + "pending-nips/" + email + "/" + page + "/" + size + "/" + title
			)
			.then((response) => response);
	},
	getClientsPendingNips(email, page, size, title) {
		return axios
			.get(
				url +
					"business/pending-nips/" +
					email +
					"/" +
					page +
					"/" +
					size +
					"/" +
					title
			)
			.then((response) => response);
	},

	openFixedTermDeposit(details) {
		return axios
			.post(url + "fixed-term-deposit/", details)
			.then((response) => response.data);
	},
	openBusinessFixedTermDeposit(details) {
		return axios
			.post(url + "business/fixed-term-deposit/", details)
			.then((response) => response.data);
	},
	getUsersCurrencyAccountList(id, currency) {
		return axios
			.get(url + "users-currency-account-list/" + id + "/" + currency)
			.then((response) => response.data);
	},
	getTermDepositRates() {
		return axios
			.get(url + "term-deposit-rates/")
			.then((response) => response.data);
	},
	getUsersFixedTermDeposits(id, page, size, title) {
		return axios
			.get(
				url +
					"users-fixed-term-deposits/" +
					id +
					"/" +
					page +
					"/" +
					size +
					"/" +
					title
			)
			.then((response) => response);
	},
	getFixedTermDepositById(id) {
		return axios
			.get(url + "fixed-term-deposits/" + id)
			.then((response) => response.data);
	},
	getCountries() {
		return axios.get(url + "countries/").then((response) => response.data);
	},
	getUserList() {
		return axios
			.get(url + "admin/user-list/")
			.then((response) => response.data);
	},
	getLatestUsersWithin1h() {
		return axios
			.get(url + "admin/latest-users-within-1h/")
			.then((response) => response.data);
	},
	getLatestUsersBefore1h() {
		return axios
			.get(url + "admin/latest-users-before-1h/")
			.then((response) => response.data);
	},
	getLatestUsersWithin1d() {
		return axios
			.get(url + "admin/latest-users-within-1d/")
			.then((response) => response.data);
	},
	getLatestUsersBefore1d() {
		return axios
			.get(url + "admin/latest-users-before-1d/")
			.then((response) => response.data);
	},
	getLatestUsersWithin1w() {
		return axios
			.get(url + "admin/latest-users-within-1w/")
			.then((response) => response.data);
	},
	getLatestUsersBefore1w() {
		return axios
			.get(url + "admin/latest-users-before-1w/")
			.then((response) => response.data);
	},
	getLatestUsersWithin1m() {
		return axios
			.get(url + "admin/latest-users-within-1m/")
			.then((response) => response.data);
	},
	getLatestUsersBefore1m() {
		return axios
			.get(url + "admin/latest-users-before-1m/")
			.then((response) => response.data);
	},
	getTotalDeposits() {
		return axios
			.get(url + "admin/total-deposits/")
			.then((response) => response.data);
	},
	getTotalLoans() {
		return axios
			.get(url + "admin/total-loans/")
			.then((response) => response.data);
	},
	// getAverageDepositInterest() {
	//   return axios
	//     .get(url + "admin/average-deposit-interest/")
	//     .then((response) => response.data);
	// },
	// getAverageLoanInterest() {
	//   return axios
	//     .get(url + "admin/average-loan-interest/")
	//     .then((response) => response.data);
	// },
	getTotalInterestIncome() {
		return axios
			.get(url + "admin/total-interest-income/")
			.then((response) => response.data);
	},
	getTotalInterestExpenses() {
		return axios
			.get(url + "admin/total-interest-expenses/")
			.then((response) => response.data);
	},
	getTotalFeeIncome() {
		return axios
			.get(url + "admin/total-fee-income/")
			.then((response) => response.data);
	},
	getNewUserData() {
		return axios
			.get(url + "admin/new-user-data/")
			.then((response) => response.data);
	},
	getLoginData() {
		return axios
			.get(url + "admin/login-data/")
			.then((response) => response.data);
	},
	getCountryData() {
		return axios
			.get(url + "admin/country-data/")
			.then((response) => response.data);
	},
};
