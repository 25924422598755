var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.editIndividualClient)},"reset":function($event){$event.preventDefault();return reset($event)}}},[_c('div',{staticClass:"col-md-4 offset-md-4 text-left"},[_c('h2',{staticClass:"title-bold"},[_vm._v(" "+_vm._s(_vm.$t("editIndividualClient.editClient"))+" "+_vm._s(_vm.client.id)+" ")]),_c('hr'),_c('div',{staticClass:"background-outline"},[_c('div',{staticClass:"background-modifier"},[_c('label',{staticClass:"text-left small"},[_vm._v(_vm._s(_vm.$t("editIndividualClient.firstname")))]),_c('ValidationProvider',{attrs:{"rules":"required|alpha_spaces|min:2|max:50","name":"firstname"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"firstnameInput","description":""}},[_c('b-form-input',{attrs:{"type":"text","state":errors[0] ? false : valid ? true : null,"placeholder":_vm.$t('editIndividualClient.firstname')},model:{value:(_vm.client.firstname),callback:function ($$v) {_vm.$set(_vm.client, "firstname", $$v)},expression:"client.firstname"}}),_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('label',{staticClass:"text-left small"},[_vm._v(_vm._s(_vm.$t("editIndividualClient.lastname")))]),_c('ValidationProvider',{attrs:{"rules":"required|alpha_spaces|min:2|max:50","name":"lastname"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"lastnameInput","description":""}},[_c('b-form-input',{attrs:{"type":"text","state":errors[0] ? false : valid ? true : null,"placeholder":_vm.$t('editIndividualClient.lastname')},model:{value:(_vm.client.lastname),callback:function ($$v) {_vm.$set(_vm.client, "lastname", $$v)},expression:"client.lastname"}}),_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('label',{staticClass:"small",attrs:{"for":"datepicker-placeholder"}},[_vm._v(_vm._s(_vm.$t("editIndividualClient.dob")))]),_c('b-form-datepicker',{attrs:{"id":"datepicker-placeholder","placeholder":_vm.$t('editIndividualClient.dob'),"min":_vm.min,"max":_vm.max,"locale":_vm.$i18n.locale,"label-no-date-selected":_vm.$t('editIndividualClient.noDateSelected'),"label-help":_vm.$t('editIndividualClient.useCursorKeysToNavigateCalendarDates')},model:{value:(_vm.client.dob),callback:function ($$v) {_vm.$set(_vm.client, "dob", $$v)},expression:"client.dob"}})],1),_c('br'),_c('div',{staticClass:"background-modifier"},[_c('br'),_c('h5',{staticClass:"text-left text-bold"},[_vm._v(" "+_vm._s(_vm.$t("editIndividualClient.contactDetails"))+" ")]),_c('hr'),_c('label',{staticClass:"small"},[_vm._v(_vm._s(_vm.$t("editIndividualClient.phoneNumber")))]),_c('ValidationProvider',{attrs:{"rules":"required|min:5|max:15","name":"mobile"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"mobileInput","description":""}},[_c('b-form-input',{attrs:{"type":"text","state":errors[0] ? false : valid ? true : null,"placeholder":_vm.$t('businessSignup.phone')},model:{value:(_vm.client.phoneNumber),callback:function ($$v) {_vm.$set(_vm.client, "phoneNumber", $$v)},expression:"client.phoneNumber"}}),_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('label',{staticClass:"text-left small"},[_vm._v(_vm._s(_vm.$t("editIndividualClient.email")))]),_c('ValidationProvider',{attrs:{"rules":"required|email","name":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"emailInput","description":""}},[_c('b-form-input',{attrs:{"type":"email","state":errors[0] ? false : valid ? true : null,"placeholder":_vm.$t('editIndividualClient.email')},model:{value:(_vm.client.email),callback:function ($$v) {_vm.$set(_vm.client, "email", $$v)},expression:"client.email"}}),_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('label',{staticClass:"text-left small"},[_vm._v(_vm._s(_vm.$t("editIndividualClient.address")))]),_c('ValidationProvider',{attrs:{"rules":"required|min:5|max:50","name":"addressLine1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"addressLine1Input","description":""}},[_c('b-form-input',{attrs:{"type":"text","state":errors[0] ? false : valid ? true : null,"placeholder":_vm.$t('editIndividualClient.address')},model:{value:(_vm.client.addressLine1),callback:function ($$v) {_vm.$set(_vm.client, "addressLine1", $$v)},expression:"client.addressLine1"}}),_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required|min:5|max:50","name":"city"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"cityInput","description":""}},[_c('b-form-input',{attrs:{"type":"text","state":errors[0] ? false : valid ? true : null,"placeholder":_vm.$t('editIndividualClient.city')},model:{value:(_vm.client.city),callback:function ($$v) {_vm.$set(_vm.client, "city", $$v)},expression:"client.city"}}),_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required|min:3|max:10","name":"zip"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"zipInput","description":""}},[_c('b-form-input',{attrs:{"type":"text","state":errors[0] ? false : valid ? true : null,"placeholder":_vm.$t('editIndividualClient.postCode')},model:{value:(_vm.client.zip),callback:function ($$v) {_vm.$set(_vm.client, "zip", $$v)},expression:"client.zip"}}),_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required","name":"country"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"id":"countryInputGroup","label-for":"countryInput"}},[_c('b-form-select',{attrs:{"id":"countryInput","state":errors[0] ? false : valid ? true : null},on:{"change":_vm.onChange},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":"","disabled":""}},[_vm._v(_vm._s(_vm.$t("editIndividualClient.country")))])]},proxy:true}],null,true),model:{value:(_vm.client.country),callback:function ($$v) {_vm.$set(_vm.client, "country", $$v)},expression:"client.country"}},_vm._l((_vm.countries),function(country){return _c('option',{key:country.countryCode,domProps:{"value":country.countryCode}},[_vm._v(_vm._s(_vm.countryNames.getName(country.countryCode, _vm.codeLang))+" ")])}),0),_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('br'),_c('div',{staticClass:"background-modifier"},[_c('h5',{staticClass:"title-bold text-left"},[_vm._v(" "+_vm._s(_vm.$t("editIndividualClient.verificationStatus"))+" ")]),_c('hr'),_c('b-form-group',{attrs:{"label":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var vStatus = ref.vStatus;
return [_c('b-form-radio',{attrs:{"aria-describedby":vStatus,"name":"radio_group_1","value":"1"},model:{value:(_vm.client.verificationStatus),callback:function ($$v) {_vm.$set(_vm.client, "verificationStatus", $$v)},expression:"client.verificationStatus"}},[_vm._v(_vm._s(_vm.$t("editIndividualClient.pending")))]),_c('br'),_c('b-form-radio',{attrs:{"aria-describedby":vStatus,"name":"radio_group_1","value":"2"},model:{value:(_vm.client.verificationStatus),callback:function ($$v) {_vm.$set(_vm.client, "verificationStatus", $$v)},expression:"client.verificationStatus"}},[_vm._v(_vm._s(_vm.$t("editIndividualClient.moreInformationRequired")))]),_c('br'),_c('b-form-radio',{attrs:{"aria-describedby":vStatus,"name":"radio_group_1","value":"3"},model:{value:(_vm.client.verificationStatus),callback:function ($$v) {_vm.$set(_vm.client, "verificationStatus", $$v)},expression:"client.verificationStatus"}},[_vm._v(_vm._s(_vm.$t("editIndividualClient.verified")))])]}}],null,true)})],1),_c('hr'),_c('b-button',{staticClass:"btn btn-info text-light btn-block",attrs:{"type":"submit"},on:{"click":_vm.isloading}},[_vm._v(_vm._s(_vm.$t("editIndividualClient.submit"))),(_vm.isloading)?_c('b-spinner',{staticClass:"ml-auto",attrs:{"variant":_vm.light}}):_vm._e()],1),(_vm.msg && _vm.$bvToast.show('notification-toast'))?_c('div'):_vm._e(),_c('div',{staticClass:"action"},[_c('br'),_c('b-toast',{attrs:{"id":"notification-toast","variant":"secondary","static":"","no-auto-hide":""},scopedSlots:_vm._u([{key:"toast-title",fn:function(){return [_c('div',{staticClass:"d-flex flex-grow-1 align-items-baseline"},[_c('b-img',{staticClass:"logo mb-2",attrs:{"src":require('/public/images/logo.png'),"alt":"","width":"12","height":"12"}}),_c('strong',{staticClass:"mr-auto"},[_vm._v(_vm._s(_vm.$t("notification")))]),_c('small',{staticClass:"text-muted mr-2"})],1)]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(_vm.msg)+" ")])],1)],1)])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }