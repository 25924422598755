<template>
	<div class="col-12 offset-0">
		<div class="text-left">
			<div class="col-md-8 offset-md-2">
				<h1 class="title-bold">
					{{ $t("paymentManagement.paymentAuthorization") }}
				</h1>
				<hr />
				<h5 class="title-bold">
					{{ $t("paymentManagement.viewApproveDeletePayments") }}
				</h5>
				<hr />
				<div v-if="role == 3 || role == 15 || role == 14">
					<p class="open-account text-right">
						<router-link
							class="btn btn-outline"
							:to="{ name: 'extended-payment' }"
							>{{ $t("paymentManagement.newPayment") }}</router-link
						>
					</p>
					<br />
				</div>

				<div v-if="msg && $bvToast.show('notification-toast')"></div>
				<div class="action">
					<b-toast
						id="notification-toast"
						variant="secondary"
						static
						no-auto-hide
					>
						<template #toast-title>
							<div class="d-flex flex-grow-1 align-items-baseline">
								<b-img
									class="logo mb-2"
									:src="require('/public/images/logo.png')"
									alt=""
									width="12"
									height="12"
								></b-img>
								<strong class="mr-auto">{{ $t("notification") }}</strong>
								<small class="text-muted mr-2"></small>
							</div>
						</template>
						{{ msg }}
						<br />
					</b-toast>
				</div>

				<b-tabs content-class="mt-3">
					<b-tab :title="$t('paymentManagement.paymentOrders')" active>
						<div class="background-modifier">
							<div class="input-group mb-3">
								<input
									type="text"
									class="form-control"
									:placeholder="$t('search')"
									v-model="searchTitle"
								/>
								<div class="input-group-append">
									<button
										class="btn btn-outline"
										type="button"
										@click="
											page = 1;
											getClientsPendingPaymentOrders();
										"
									>
										{{ $t("search") }}
									</button>
								</div>
							</div>

							<div v-if="isloading" class="text-center">
								<b-spinner
									style="width: 3rem; height: 3rem;"
									class="ml-auto text-center"
									:variant="secondary"
								></b-spinner>
							</div>
							<div v-else-if="paymentOrders.length > 0">
								<div class="table-wrap">
									<table class="table mt-2" id="paymentOrders">
										<thead>
											<tr>
												<th class="text-left small">
													{{ $t("paymentManagement.id") }}
												</th>
												<th class="text-left small">
													{{ $t("paymentManagement.beneficiaryName") }}
												</th>
												<th class="text-right small">
													{{ $t("paymentManagement.amount") }}
												</th>
												<th class="text-left small">
													{{ $t("paymentManagement.message") }}
												</th>
												<th class="text-right small">
													{{ $t("paymentManagement.dueDate") }}
												</th>
												<th class="text-right small">
													{{ $t("paymentManagement.created") }}
												</th>
												<th class="text-centered small">
													{{ $t("paymentManagement.actions") }}
												</th>
											</tr>
										</thead>
										<tbody>
											<tr
												v-for="paymentOrder in paymentOrders"
												:key="paymentOrder.id"
											>
												<td class="text-left">
													{{ paymentOrder.id }}
												</td>
												<td class="text-left">
													{{ paymentOrder.creditAccountHolder }}
												</td>
												<td class="text-right">
													{{ $n(paymentOrder.amount, "decimal") }}
												</td>
												<td class="text-left">{{ paymentOrder.message }}</td>
												<td class="text-right">
													{{ $d(Date.parse(paymentOrder.dueDate), "short") }}
												</td>
												<td class="text-right">
													{{ $d(Date.parse(paymentOrder.created), "short") }}
												</td>
												<td class="text-right">
													<router-link
														:to="{
															name: 'manage-pending-payment-order',
															params: { id: paymentOrder.id },
														}"
														class="btn btn-outline mx-1 "
														>{{ $t("paymentManagement.view") }}</router-link
													>
													<a
														class="btn btn-danger mx-1 "
														@click="deletePaymentOrder(paymentOrder.id)"
														>{{ $t("paymentManagement.delete") }}</a
													>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
								<div v-if="paymentOrders.length >= 1">
									<div class="row">
										<div class="col-md-6">
											{{ $t("itemsPerPage") }}
											<select
												class="custom-select"
												style="width: 60px;"
												v-model="pageSize"
												@change="handlePageSizeChange($event)"
											>
												<option
													v-for="size in pageSizes"
													:key="size"
													:value="size"
												>
													{{ size }}
												</option>
											</select>
										</div>
										<div class="col-md-6">
											<b-pagination
												v-model="page"
												:total-rows="count"
												:per-page="pageSize"
												:prev-text="$t('previous')"
												:next-text="$t('next')"
												align="right"
												@change="handlePageChange"
											></b-pagination>
										</div>
									</div>
								</div>
							</div>

							<div v-else>
								{{ $t("paymentManagement.noPendingPaymentOrders") }}
							</div>
						</div>
					</b-tab>
					<b-tab :title="$t('paymentManagement.standingOrders')">
						<div class="background-modifier">
							<div class="input-group mb-3">
								<input
									type="text"
									class="form-control"
									:placeholder="$t('search')"
									v-model="searchTitle"
								/>
								<div class="input-group-append">
									<button
										class="btn btn-outline"
										type="button"
										@click="
											page = 1;
											getClientsPendingStandingOrders();
										"
									>
										{{ $t("search") }}
									</button>
								</div>
							</div>

							<div v-if="isloading2" class="text-center">
								<b-spinner
									style="width: 3rem; height: 3rem;"
									class="ml-auto text-center"
									:variant="secondary"
								></b-spinner>
							</div>
							<div v-else-if="standingOrders.length > 0">
								<div class="table-wrap">
									<table class="table mt-2" id="standingOrders">
										<thead>
											<tr>
												<th class="text-left small">
													{{ $t("paymentManagement.id") }}
												</th>
												<th class="text-left small">
													{{ $t("paymentManagement.beneficiaryName") }}
												</th>
												<th class="text-right small">
													{{ $t("paymentManagement.amount") }}
												</th>
												<th class="text-left small">
													{{ $t("paymentManagement.message") }}
												</th>
												<th class="text-right small">
													{{ $t("paymentManagement.frequency") }}
												</th>
												<th class="text-right small">
													{{ $t("paymentManagement.startDate") }}
												</th>
												<th class="text-right small">
													{{ $t("paymentManagement.endDate") }}
												</th>
												<th class="text-right small">
													{{ $t("paymentManagement.created") }}
												</th>
												<th class="text-centered small">
													{{ $t("paymentManagement.actions") }}
												</th>
											</tr>
										</thead>
										<tbody>
											<tr
												v-for="standingOrder in standingOrders"
												:key="standingOrder.id"
											>
												<td class="text-left">{{ standingOrder.id }}</td>
												<td class="text-left">
													{{ standingOrder.creditAccountHolder }}
												</td>
												<td class="text-right">
													{{ $n(standingOrder.amount, "decimal") }}
												</td>
												<td class="text-left">{{ standingOrder.message }}</td>
												<td class="text-left">{{ standingOrder.frequency }}</td>
												<td class="text-right">
													{{ $d(Date.parse(standingOrder.startDate), "short") }}
												</td>
												<td class="text-right">
													{{ $d(Date.parse(standingOrder.endDate), "short") }}
												</td>
												<td class="text-right">
													{{ $d(Date.parse(standingOrder.created), "short") }}
												</td>
												<td class="text-right">
													<router-link
														:to="{
															name: 'manage-pending-standing-order',
															params: { id: standingOrder.id },
														}"
														class="btn btn-outline mx-1 "
														>{{ $t("paymentManagement.view") }}</router-link
													>
													<a
														class="btn btn-danger mx-1 "
														@click="deleteStandingOrder(standingOrder.id)"
														>{{ $t("paymentManagement.delete") }}</a
													>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
								<div v-if="standingOrders.length >= 1">
									<div class="row">
										<div class="col-md-6">
											{{ $t("itemsPerPage") }}
											<select
												class="custom-select"
												style="width: 60px;"
												v-model="pageSize"
												@change="handlePageSizeChange($event)"
											>
												<option
													v-for="size in pageSizes"
													:key="size"
													:value="size"
												>
													{{ size }}
												</option>
											</select>
										</div>
										<div class="col-md-6">
											<b-pagination
												v-model="page"
												:total-rows="count"
												:per-page="pageSize"
												:prev-text="$t('previous')"
												:next-text="$t('next')"
												align="right"
												@change="handlePageChange"
											></b-pagination>
										</div>
									</div>
								</div>
							</div>
							<div v-else>
								{{ $t("paymentManagement.noPendingStandingOrders") }}
							</div>
						</div>
					</b-tab>
				</b-tabs>
			</div>
		</div>
	</div>
</template>

<script>
	import AuthService from "@/services/AuthService.js";

	export default {
		name: "paymentOrders",
		data() {
			return {
				paymentOrders: [],
				standingOrders: [],
				msg: "",
				currentPaymentOrder: null,
				currentStandingOrder: null,
				currentIndex: -1,
				searchTitle: "",
				isloading: false,
				isloading2: false,
				page: 1,
				count: 0,
				pageSize: 10,

				pageSizes: [10, 25, 50],
			};
		},
		async created() {
			if (!this.$store.getters.isLoggedIn) {
				this.$router.push("login");
			}
			if (
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 3 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 15 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 14 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 13 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 12 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 11
			) {
				this.$router.push("overview");
			}
			let lang = this.$i18n.locale;
			this.role = this.$store.getters.getUser.role;
			this.username = this.$store.getters.getUser.username;
			this.firstname = this.$store.getters.getUser.firstname;
			this.baseCurrency = this.$store.getters.getUser.currency;
			// this.getUsersPaymentOrders();
		},
		methods: {
			getRequestParams(searchTitle, page, pageSize) {
				let params = {};

				if (searchTitle) {
					params["title"] = searchTitle;
				}

				if (page) {
					params["page"] = page - 1;
				}

				if (pageSize) {
					params["size"] = pageSize;
				}

				return params;
			},

			getRequestParams2(searchTitle, page, pageSize) {
				let params = {};

				if (searchTitle) {
					params["title"] = searchTitle;
				}

				if (page) {
					params["page"] = page - 1;
				}

				if (pageSize) {
					params["size"] = pageSize;
				}

				return params;
			},

			async getClientsPendingPaymentOrders() {
				const params = this.getRequestParams(
					this.searchTitle,
					this.page,
					this.pageSize
				);

				try {
					const details = {
						id: this.$store.getters.getUser.id,
					};
					this.isloading = true;
					const response = await AuthService.getClientsPendingPaymentOrders(
						details.id,
						params.page,
						params.size,
						params.title
					);
					const { items, totalItems } = response.data;
					this.paymentOrders = items;
					this.count = totalItems;
					this.isloading = false;
					console.log(response.data);
				} catch (error) {
					this.isloading = false;
					console.log(error);
				}
			},

			async getClientsPendingStandingOrders() {
				const params = this.getRequestParams2(
					this.searchTitle,
					this.page,
					this.pageSize
				);

				try {
					const details = {
						id: this.$store.getters.getUser.id,
					};
					this.isloading2 = true;
					const response = await AuthService.getClientsPendingStandingOrders(
						details.id,
						params.page,
						params.size,
						params.title
					);
					const { items, totalItems } = response.data;
					this.standingOrders = items;
					this.count = totalItems;
					this.isloading2 = false;
					console.log(response.data);
				} catch (error) {
					this.isloading2 = false;
					console.log(error);
				}
			},

			// async getUsersPaymentOrders() {
			//   const params = this.getRequestParams(
			//     this.searchTitle,
			//     this.page,
			//     this.pageSize
			//   );

			//   try {
			//     const details = {
			//       id: this.$store.getters.getUser.id,
			//     };
			//     const response = await AuthService.getUsersPaymentOrders(
			//       details.id,
			//       params.page,
			//       params.size,
			//       params.title
			//     );
			//     const { items, totalItems } = response.data;
			//     this.paymentOrders = items;
			//     this.count = totalItems;
			//     console.log(response.data);
			//   } catch (error) {
			//     console.log(error);
			//   }
			// },

			handlePageChange(value) {
				this.page = value;
				this.getClientsPendingPaymentOrders();
				this.getClientsPendingStandingOrders();
			},

			handlePageSizeChange(event) {
				this.pageSize = event.target.value;
				this.page = 1;
				this.getClientsPendingPaymentOrders();
				this.getClientsPendingStandingOrders();
			},

			async deletePaymentOrder(id) {
				try {
					const lang = this.$i18n.locale;
					const response = await AuthService.deletePaymentOrder(id, lang);
					console.log(response);
					this.getClientsPendingPaymentOrders();
					this.msg = response.msg;
					console.log(response.msg);
				} catch (error) {
					console.log(error);
					this.msg = error.response.data.msg;
				}
			},
			async deleteStandingOrder(id) {
				try {
					const lang = this.$i18n.locale;
					const response = await AuthService.deleteStandingOrder(id, lang);
					console.log(response);
					this.getClientsPendingStandingOrders();
					this.msg = response.msg;
					console.log(response.msg);
				} catch (error) {
					console.log(error);
					this.msg = error.response.data.msg;
				}
			},
		},

		mounted() {
			this.getClientsPendingPaymentOrders();
			this.getClientsPendingStandingOrders();
		},
	};
</script>
