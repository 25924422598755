<template>
	<ValidationObserver ref="observer" v-slot="{ handleSubmit, reset }">
		<b-form @submit.prevent="handleSubmit(createOffer)" @reset.prevent="reset">
			<div class="col-lg-4 offset-lg-4 text-left">
				<h2 class="title-bold">
					{{ $t("createOffer.createOffer") }}
				</h2>
				<hr />
				<!-- {{ this.$route.params.id }} -->
				<!-- <h5 class="text-left text-bold">
          {{ $t("createOffer.insertTheDetails") }}
        </h5>
        <hr /> -->
				<div class="background-outline">
					<div class="background-modifier text-left">
						<label class="small">{{ $t("createOffer.offerName") }}</label>
						<ValidationProvider
							rules="required|min:2|max:100"
							name="offerName"
							v-slot="{ valid, errors }"
						>
							<b-form-group label-for="offerNameInput" description="">
								<b-form-input
									type="text"
									v-model="offerName"
									:state="errors[0] ? false : valid ? true : null"
									:placeholder="$t('createOffer.offerName')"
								></b-form-input>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>

						<label class="small">{{
							$t("createOffer.offerDescription")
						}}</label>
						<ValidationProvider
							rules="required|min:2|max:250"
							name="offerDescription"
							v-slot="{ valid, errors }"
						>
							<b-form-group label-for="offerDescriptionInput" description="">
								<b-form-input
									type="text"
									v-model="offerDescription"
									:state="errors[0] ? false : valid ? true : null"
									:placeholder="$t('createOffer.offerDescription')"
								></b-form-input>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>

						<ValidationProvider
							rules="required|min:3|max:3"
							name="currency"
							v-slot="{ valid, errors }"
						>
							<label class="small" for="currency">{{
								$t("createOffer.currency")
							}}</label>
							<b-form-group label-for="currencyInput" description="">
								<b-form-input
									type="text"
									v-model="currency"
									:state="errors[0] ? false : valid ? true : null"
									:placeholder="$t('createOffer.currency')"
								></b-form-input>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>

						<ValidationProvider
							rules="required|numeric"
							name="discountRate"
							v-slot="{ valid, errors }"
						>
							<label class="small" for="discountRate">{{
								$t("createOffer.discountRate")
							}}</label>
							<b-form-group label-for="discountRateInput" description="">
								<b-form-input
									type="text"
									v-model="discountRate"
									:state="errors[0] ? false : valid ? true : null"
									:placeholder="$t('createOffer.discountRate')"
								></b-form-input>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>

						<label class="small" for="datepicker-placeholder">{{
							$t("createOffer.startDate")
						}}</label>
						<b-form-datepicker
							id="datepicker-placeholder"
							:placeholder="$t('createOffer.startDate')"
							v-model="startDate"
							:min="min"
							:max="max"
							:locale="$i18n.locale"
							:label-no-date-selected="$t('createOffer.noDateSelected')"
							:label-help="
								$t('createOffer.useCursorKeysToNavigateCalendarDates')
							"
						></b-form-datepicker>

						<label class="small" for="datepicker-placeholder2">{{
							$t("createOffer.endDate")
						}}</label>
						<b-form-datepicker
							id="datepicker-placeholder2"
							:placeholder="$t('createOffer.endDate')"
							v-model="endDate"
							:min="min"
							:max="max"
							:locale="$i18n.locale"
							:label-no-date-selected="$t('createOffer.noDateSelected')"
							:label-help="
								$t('createOffer.useCursorKeysToNavigateCalendarDates')
							"
						></b-form-datepicker>
						<br />
					</div>

					<hr />

					<!-- disabled-->
					<!-- <div v-if="status == 'accepted'"> -->
					<b-button
						type="submit"
						@click="isloading"
						class="btn btn-info text-light btn-block"
						>{{ $t("createOffer.submit")
						}}<b-spinner
							v-if="isloading"
							class="ml-auto"
							:variant="light"
						></b-spinner
					></b-button>
					<!--   <p class="small">{{ $t("businessSignup.notAvailable") }}</p> -->
					<!-- </div> -->

					<!-- <b-button
          type="reset"
          class="btn btn-alert text-light btn-block"
          @click="resetForm()"
          >{{ $t("reset") }}</b-button
        >
        <hr /> -->
					<div v-if="msg && $bvToast.show('notification-toast')"></div>
					<div class="action">
						<br />
						<b-toast
							id="notification-toast"
							variant="secondary"
							static
							no-auto-hide
						>
							<template #toast-title>
								<div class="d-flex flex-grow-1 align-items-baseline">
									<b-img
										class="logo mb-2"
										:src="require('/public/images/logo.png')"
										alt=""
										width="12"
										height="12"
									></b-img>
									<strong class="mr-auto">{{ $t("notification") }}</strong>
									<small class="text-muted mr-2"></small>
								</div>
							</template>
							{{ msg }}
						</b-toast>
					</div>
				</div>
			</div>
		</b-form>
	</ValidationObserver>
</template>

<script>
	import { ValidationObserver, ValidationProvider } from "vee-validate";
	import AuthService from "@/services/AuthService.js";
	//var countryNames = require("i18n-iso-countries");
	export default {
		name: "ManageOffer",
		components: {
			ValidationObserver,
			ValidationProvider,
		},
		/*props: {
        codeLang: {
          type: String,
          default: "en",
        },
      },*/
		//this.codeLang
		created() {
			this.codeLang = this.$i18n.locale;
			// this.countryNames.registerLocale(
			//   require(`i18n-iso-countries/langs/${this.codeLang}.json`)
			// );
		},
		watch: {
			// codeLang(newVal, oldVal) {
			//   this.countryNames.registerLocale(
			//     require(`i18n-iso-countries/langs/${newVal}.json`)
			//   );
			// },
		},
		data() {
			const now = new Date();
			const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
			// 15th two months prior
			const minDate = new Date(today);
			minDate.setYear(minDate.getFullYear() - 0);
			const maxDate = new Date(today);
			maxDate.setYear(maxDate.getFullYear() + 2);
			// minDate.setMonth(minDate.getMonth() - 2);
			// minDate.setDate(15);
			// 15th in two months

			return {
				offer: "",
				msg: "",
				isloading: false,
				//countries: [],
				//countryNames: countryNames,
				/*username: "",
      name: "",
      businessId: "",
      vatNumber: "",
      email: "",
      /*  firstname: "",
      lastname: "",
      password: "",
      password_repeat: "",

      pin: "",
      doi: maxDate.toISOString().slice(0, 10),
      placeOfIncorporation: "",
      domicile: "",
      addressLine1: "",
      city: "",
      zip: "",
      country: "",
      contactAddressLine1: "",
      contactCity: "",
      contactZip: "",
      contactCountry: "",
      prefix: "",
      mobile: "",
      website: "",
      registry: "",
      registryWebsite: "",
      auditors: "",
      turnover: "",
      employees: "",
      totalAssets: "",
      countryActivity: "",
      value: "", */
				min: minDate,
				max: maxDate,
				//status: "not_accepted",
			};
		},
		async created() {
			if (!this.$store.getters.isLoggedIn) {
				this.$router.push("login");
			}
			if (
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 3 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 15 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 14 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 13 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 12
			) {
				this.$router.push("overview");
			}
			let lang = this.$i18n.locale;
			this.role = this.$store.getters.getUser.role;
			this.username = this.$store.getters.getUser.username;
			this.firstname = this.$store.getters.getUser.firstname;

			//this.getOfferById();
		},
		methods: {
			async createOffer() {
				try {
					const details = {
						merchantId: this.$route.params.id,
						lang: this.$i18n.locale,
						//id: this.$route.params.id,
						offerName: this.offerName,
						offerDescription: this.offerDescription,
						discountRate: this.discountRate,
						currency: this.currency,
						startDate: this.startDate,
						endDate: this.endDate,
					};
					this.isloading = true;
					const response = await AuthService.createOffer(details);
					// this.resetForm();
					this.msg = response.msg;
					this.isloading = false;
					console.log(response.msg);
				} catch (error) {
					this.msg = error.response.data.msg;
					this.isloading = false;
					//console.log(error);
				}
			},

			resetForm() {
				this.offerName = "";
				this.offerDescription = "";
				this.discountRate = "";
				this.currency = "";
				this.startDate = "";
				this.endDate = "";
				this.msg = "";
				requestAnimationFrame(() => {
					this.$refs.observer.reset();
				});
			},
			// async getCountries() {
			//   try {
			//     const response = await AuthService.getCountries();
			//     this.countries = response;
			//     console.log(response);
			//   } catch (error) {
			//     console.log(error);
			//   }
			// },
		},
		mounted() {
			//  this.getUserId();
		},
	};
</script>
