var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.editBusinessUser)},"reset":function($event){$event.preventDefault();return reset($event)}}},[_c('div',{staticClass:"col-md-4 offset-md-4 text-left"},[_c('h2',{staticClass:"title-bold"},[_vm._v(" "+_vm._s(_vm.$t("manageUser.editUser"))+" "+_vm._s(_vm.user.id)+" ")]),_c('hr'),_c('div',{staticClass:"background-outline"},[_c('div',{staticClass:"background-modifier"},[_c('label',{staticClass:"text-left small"},[_vm._v(_vm._s(_vm.$t("manageUser.firstname")))]),_c('ValidationProvider',{attrs:{"rules":"required|alpha_spaces|min:2|max:50","name":"firstname"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"firstnameInput","description":""}},[_c('b-form-input',{attrs:{"type":"text","state":errors[0] ? false : valid ? true : null,"placeholder":_vm.$t('manageUser.firstname')},model:{value:(_vm.user.firstname),callback:function ($$v) {_vm.$set(_vm.user, "firstname", $$v)},expression:"user.firstname"}}),_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('label',{staticClass:"text-left small"},[_vm._v(_vm._s(_vm.$t("manageUser.lastname")))]),_c('ValidationProvider',{attrs:{"rules":"required|alpha_spaces|min:2|max:50","name":"lastname"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"lastnameInput","description":""}},[_c('b-form-input',{attrs:{"type":"text","state":errors[0] ? false : valid ? true : null,"placeholder":_vm.$t('manageUser.lastname')},model:{value:(_vm.user.lastname),callback:function ($$v) {_vm.$set(_vm.user, "lastname", $$v)},expression:"user.lastname"}}),_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('br'),_c('div',{staticClass:"background-modifier"},[_c('h4',{staticClass:"title-bold"},[_vm._v(_vm._s(_vm.$t("manageUser.authorizations")))]),_c('hr'),_c('b-form-group',{attrs:{"label":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var vAuth = ref.vAuth;
return [_c('b-form-radio',{attrs:{"aria-describedby":vAuth,"name":"radio_group_0","value":"11"},model:{value:(_vm.user.role),callback:function ($$v) {_vm.$set(_vm.user, "role", $$v)},expression:"user.role"}},[_vm._v(_vm._s(_vm.$t("role.accountant"))+": "+_vm._s(_vm.$t("manageUser.viewingRights"))+" ")]),_c('br'),_c('b-form-radio',{attrs:{"aria-describedby":vAuth,"name":"radio_group_0","value":"12"},model:{value:(_vm.user.role),callback:function ($$v) {_vm.$set(_vm.user, "role", $$v)},expression:"user.role"}},[_vm._v(_vm._s(_vm.$t("role.employee"))+": "+_vm._s(_vm.$t("manageUser.viewingAndPreparationRights")))]),_c('br'),_c('b-form-radio',{attrs:{"aria-describedby":vAuth,"name":"radio_group_0","value":"13"},model:{value:(_vm.user.role),callback:function ($$v) {_vm.$set(_vm.user, "role", $$v)},expression:"user.role"}},[_vm._v(_vm._s(_vm.$t("role.manager"))+": "+_vm._s(_vm.$t("manageUser.viewingPreparationAndAuthorizationRights")))]),_c('br'),_c('b-form-radio',{attrs:{"aria-describedby":vAuth,"name":"radio_group_0","value":"14"},model:{value:(_vm.user.role),callback:function ($$v) {_vm.$set(_vm.user, "role", $$v)},expression:"user.role"}},[_vm._v(_vm._s(_vm.$t("role.admin"))+": "+_vm._s(_vm.$t( "manageUser.viewingPreparationAuthorizationAndContractSigningRights" )))]),_c('br'),_c('b-form-radio',{attrs:{"aria-describedby":vAuth,"name":"radio_group_0","value":"15"},model:{value:(_vm.user.role),callback:function ($$v) {_vm.$set(_vm.user, "role", $$v)},expression:"user.role"}},[_vm._v(_vm._s(_vm.$t("manageUser.masterUser")))])]}}],null,true)})],1),_c('br'),_c('div',{staticClass:"background-modifier"},[_c('h4',{staticClass:"title-bold"},[_vm._v(_vm._s(_vm.$t("manageUser.userStatus")))]),_c('hr'),_c('b-form-group',{attrs:{"label":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var vActive = ref.vActive;
return [_c('b-form-radio',{attrs:{"aria-describedby":vActive,"name":"radio_group_1","value":"1"},model:{value:(_vm.user.active),callback:function ($$v) {_vm.$set(_vm.user, "active", $$v)},expression:"user.active"}},[_vm._v(_vm._s(_vm.$t("manageUser.active")))]),_c('br'),_c('b-form-radio',{attrs:{"aria-describedby":vActive,"name":"radio_group_1","value":"0"},model:{value:(_vm.user.active),callback:function ($$v) {_vm.$set(_vm.user, "active", $$v)},expression:"user.active"}},[_vm._v(_vm._s(_vm.$t("manageUser.blocked")))])]}}],null,true)})],1),_c('hr'),_c('b-button',{staticClass:"btn btn-info text-light btn-block",attrs:{"type":"submit"},on:{"click":_vm.isloading}},[_vm._v(_vm._s(_vm.$t("manageUser.submit"))),(_vm.isloading)?_c('b-spinner',{staticClass:"ml-auto",attrs:{"variant":_vm.light}}):_vm._e()],1),(_vm.msg && _vm.$bvToast.show('notification-toast'))?_c('div'):_vm._e(),_c('div',{staticClass:"action"},[_c('br'),_c('b-toast',{attrs:{"id":"notification-toast","variant":"secondary","static":"","no-auto-hide":""},scopedSlots:_vm._u([{key:"toast-title",fn:function(){return [_c('div',{staticClass:"d-flex flex-grow-1 align-items-baseline"},[_c('b-img',{staticClass:"logo mb-2",attrs:{"src":require('/public/images/logo.png'),"alt":"","width":"12","height":"12"}}),_c('strong',{staticClass:"mr-auto"},[_vm._v(_vm._s(_vm.$t("notification")))]),_c('small',{staticClass:"text-muted mr-2"})],1)]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(_vm.msg)+" ")])],1)],1)])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }