<template>
	<div class="col-12 offset-0">
		<div class="">
			<div class="text-left">
				<div class="col-md-8 offset-md-2">
					<h1 class="title-bold">{{ $t("cardManagement.cardManagement") }}</h1>
					<hr />
					<div class="">
						<h3 class="text-bold">
							{{ $t("cardManagement.cards") }} ({{ listedCards.length }})
						</h3>
						<hr />
					</div>
				</div>

				<div class="col-md-8 offset-md-2 ">
					<div class="row">
						<div class="col-md-6 text-left">
							<div v-if="role == 3 || role == 15 || role == 14 || role == 13">
								<p class="open-account text-left">
									<router-link
										class="btn btn-outline"
										:to="{ name: 'manage-card-applications' }"
										>{{ $t("cardManagement.manageApplications") }}</router-link
									>
								</p>
							</div>
						</div>
						<div class="col-md-6 text-right">
							<div
								v-if="
									role == 3 ||
										role == 15 ||
										role == 14 ||
										role == 13 ||
										role == 12
								"
							>
								<p class="open-account text-right">
									<router-link
										class="btn btn-outline"
										:to="{ name: 'create-card', params: { id: this.clientId } }"
										>{{ $t("cardManagement.newCard") }}</router-link
									>
								</p>
							</div>
						</div>
					</div>

					<!--   <p class="open-account text-right">
              <router-link
                class="btn btn-outline"
                :to="{ name: 'open-account' }"
                >{{ $t("accounts.openAccount") }}</router-link
              >
            </p>
            <br /> -->

					<br />
					<div v-if="isloading" class="text-center">
						<b-spinner
							style="width: 3rem; height: 3rem;"
							class="ml-auto text-center"
							:variant="secondary"
						></b-spinner>
					</div>
					<div v-else-if="listedCards.length > 0">
						<b-row>
							<div
								v-for="card in listedCards"
								:key="card.id"
								class="col-6 card-grid"
							>
								<router-link
									:to="{ name: 'card-details', params: { id: card.id } }"
									class=""
									tag="text-grey"
								>
									<div class="background-modifier ">
										<div class="product-details">
											<br />
											<h3 class="title-bold">
												{{
													card.cardnumber
														.toString()
														.replace(/(\d{4}(?!\s))/g, "$1 ")
												}}
											</h3>
											<div class="">
												<div class="">
													<h3 class="text-bold">
														{{ card.cardholder.toUpperCase() }}
													</h3>
													<p class="text-right small">VALID THRU</p>
													<h4 class="text-right text-bold">
														{{ $d(Date.parse(card.validity), "card") }}
													</h4>
												</div>
											</div>
										</div>
									</div>
								</router-link>
							</div>
						</b-row>
					</div>
					<div v-else>{{ $t("cardManagement.noCards") }}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import AuthService from "@/services/AuthService.js";

	export default {
		data() {
			return {
				card: "",
				username: "",
				listedCards: [],
				isloading: false,
			};
		},
		async created() {
			if (!this.$store.getters.isLoggedIn) {
				this.$router.push("login");
			}

			if (
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 3 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 15 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 14 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 13 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 12 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 11
			) {
				this.$router.push("overview");
			}
			let lang = this.$i18n.locale;
			this.role = this.$store.getters.getUser.role;
			this.username = this.$store.getters.getUser.username;
			this.firstname = this.$store.getters.getUser.firstname;
			this.clientId = this.$store.getters.getUser.clientId;
		},
		methods: {
			async getClientsCardList() {
				try {
					const id = this.$store.getters.getUser.id;
					this.isloading = true;
					const response = await AuthService.getClientsCardList(id);
					this.listedCards = response;
					this.isloading = false;
					console.log(response);
				} catch (error) {
					this.isloading = false;
					console.log(error);
				}
			},
		},

		mounted() {
			this.getClientsCardList();
		},
	};
</script>
