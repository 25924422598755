var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.newNip)},"reset":function($event){$event.preventDefault();return reset($event)}}},[_c('div',{staticClass:"col-lg-4 offset-lg-4 text-left"},[_c('h2',{staticClass:"title-bold"},[_vm._v(_vm._s(_vm.$t("generateQRCode.generateQRCode")))]),_c('h5',{staticClass:"title-bold"},[_vm._v(" "+_vm._s(_vm.$t("generateQRCode.generateQRCodeToReceiveNip"))+" ")]),_c('hr'),_c('div',{staticClass:"background-outline"},[_c('div',{staticClass:"background-modifier text-center"},[_c('qrcode-vue',{attrs:{"value":_vm.creditAccountHolder +
									'\n' +
									_vm.email +
									'\n' +
									_vm.message +
									'\n' +
									_vm.selected[0] +
									'\n' +
									_vm.amount,"size":_vm.size,"level":"H"}})],1),_c('hr'),_c('div',{staticClass:"background-modifier"},[_c('label',{staticClass:"small"},[_vm._v(_vm._s(_vm.$t("generateQRCode.beneficiaryName")))]),_c('ValidationProvider',{attrs:{"rules":"alpha_spaces|min:2|max:50","name":"creditAccountHolder"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
									var valid = ref.valid;
									var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"creditAccountHolder","description":""}},[_c('b-form-input',{attrs:{"type":"text","state":errors[0] ? false : valid ? true : null,"placeholder":_vm.$t('generateQRCode.beneficiaryName')},model:{value:(_vm.creditAccountHolder),callback:function ($$v) {_vm.creditAccountHolder=$$v},expression:"creditAccountHolder"}}),_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('label',{staticClass:"small"},[_vm._v(_vm._s(_vm.$t("generateQRCode.email")))]),_c('ValidationProvider',{attrs:{"rules":"required|email","name":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
									var valid = ref.valid;
									var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"emailInput","description":""}},[_c('b-form-input',{attrs:{"type":"email","state":errors[0] ? false : valid ? true : null,"placeholder":_vm.$t('generateQRCode.email')},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('br'),_c('div',{staticClass:"background-modifier"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-5"},[_c('label',{staticClass:"small"},[_vm._v(_vm._s(_vm.$t("generateQRCode.selectCurrency")))]),_c('ValidationProvider',{attrs:{"rules":"required","name":"selected"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
									var valid = ref.valid;
									var errors = ref.errors;
return [_c('b-form-group',{attrs:{"id":"sourceCurrencyInputGroup","label-for":_vm.sourceCurrencyInput}},[_c('b-form-select',{attrs:{"id":"sourceCurrencyInput","state":errors[0] ? false : valid ? true : null},on:{"change":_vm.convert},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":"","disabled":""}},[_vm._v(_vm._s(_vm.$t("generateQRCode.selectCurrency")))])]},proxy:true}],null,true),model:{value:(_vm.selected[0]),callback:function ($$v) {_vm.$set(_vm.selected, 0, $$v)},expression:"selected[0]"}},_vm._l((_vm.currencies),function(currency){return _c('option',{key:currency.CharCode,domProps:{"value":currency.CharCode}},[_vm._v(_vm._s(currency.CharCode))])}),0),_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('div',{staticClass:"col-md-7"},[_c('label',{staticClass:"small"},[_vm._v(_vm._s(_vm.$t("generateQRCode.amount")))]),_c('ValidationProvider',{attrs:{"rules":"required|double|min_value:0.01","name":"amount"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
									var valid = ref.valid;
									var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"amountInput","description":""}},[_c('b-form-input',{attrs:{"type":"text","state":errors[0] ? false : valid ? true : null,"placeholder":_vm.$t('generateQRCode.amount')},on:{"input":_vm.convert},model:{value:(_vm.amount),callback:function ($$v) {_vm.amount=$$v},expression:"amount"}}),_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)])]),_c('br'),_c('div',{staticClass:"background-modifier"},[_c('label',{staticClass:"small"},[_vm._v(_vm._s(_vm.$t("generateQRCode.message")))]),_c('ValidationProvider',{attrs:{"rules":"min:1|max:50","name":"message"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
									var valid = ref.valid;
									var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"messageInput","description":""}},[_c('b-form-input',{attrs:{"type":"text","state":errors[0] ? false : valid ? true : null,"placeholder":_vm.$t('generateQRCode.message')},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}}),_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('hr'),_c('b-button',{staticClass:"btn btn-alert text-light btn-block",attrs:{"type":"reset"},on:{"click":function($event){return _vm.resetForm()}}},[_vm._v(_vm._s(_vm.$t("reset")))])],1)])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }