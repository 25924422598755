<template>
	<div class="breadcrumb-container">
		<div class="">
			<nav class="breadcrumb ">
				<ul>
					<li class="logo"></li>
					<li class="nav-item logo">
						<RouterLink to="/">
							<img
								class="logo"
								src="/images/triangular_logo_grey.svg"
								width="18"
							/>
						</RouterLink>
					</li>
					<li v-for="crumb in breadcrumbs" :key="index">
						<RouterLink class="small" :to="crumb.to">
							{{ crumb.label }}
						</RouterLink>
					</li>
				</ul>
			</nav>
		</div>
	</div>
</template>

<script>
	//import { useI18n } from 'vue-i18n'
	//const { t } = useI18n()
	//import i18n from '@/i18n'
	import { i18n } from "../main.js";
	export default {
		computed: {
			breadcrumbs() {
				const lang = this.$i18n.locale;

				const route = this.$route;
				const matchedRoutes = route.matched;

				return matchedRoutes.map((routeItem) => ({
					label: routeItem.meta.breadcrumb || routeItem.name,
					to: this.getRoutePath(route, routeItem),
				}));
			},
		},
		methods: {
			getRoutePath(route, routeItem) {
				const matchedSegments = route.matched.slice(
					1,
					route.matched.indexOf(routeItem) + 1
				);
				const lang = this.$i18n.locale;
				return matchedSegments
					.map((segment) => segment.path.replace(/^.+?[/]/, "/" + lang + "/"))
					.join("");
			},
		},
	};
</script>

<style scoped>
	.breadcrumb-container {
		margin-top: -25px;
		background-color: var(--color-background);
	}
	.breadcrumb {
		background-color: var(--color-background);
		color: var(--color-text);
		margin-left: auto;
		margin-right: auto;
		max-width: 1140px;
		padding-left: 30px;
		padding-right: 30px;
		font-size: 1rem;
	}
	.logo {
		padding: 0px 0px 0px 0px;
		margin-bottom: 6px;
		margin-right: -2px;
	}
	ul {
		list-style-type: none;
		margin: 0;
		padding: 0;
		overflow: hidden;
	}
	li {
		float: left;
		color: var(--text-color);
	}
	li a {
		display: block;
		text-align: center;
		padding: 3px;
		text-decoration: none;
		color: var(--text-color);
	}
	/* li a:hover {
  } */
</style>
