<template>
	<div class="col-lg-4 offset-lg-4 text-left">
		<h2 class="title-bold">
			{{ $t("registrationType.chooseRegistrationType") }}
		</h2>
		<hr />

		<div class="background-modifier">
			<br />
			<router-link
				class="btn btn-info text-light btn-block"
				:to="{ path: 'sign-up-individual/0' }"
				>{{ $t("registrationType.registerAsIndividual") }}</router-link
			>
			<hr />
			<router-link
				class="btn btn-alert text-light btn-block"
				:to="{ path: 'business/business-type-selection' }"
				>{{ $t("registrationType.registerAsBusiness") }}</router-link
			>
			<p class="forgot-password text-left">
				{{ $t("registrationType.registeringLegalEntity") }}
			</p>
			<hr />
			<router-link
				class="btn btn-alert text-light btn-block"
				:to="{ path: 'business/application-login' }"
				>{{ $t("registrationType.loginToApplications") }}</router-link
			>
		</div>
		<!-- </div> -->
		<hr />

		<p class="forgot-password text-right">
			{{ $t("registrationType.alreadyRegistered") }}
			<router-link :to="{ name: 'login' }">{{
				$t("registrationType.login")
			}}</router-link>
		</p>
		<hr />
	</div>
</template>

<script>
	import AuthService from "@/services/AuthService.js";

	export default {
		name: "RegistrationType",

		/*props: {
    codeLang: {
      type: String,
      default: "en",
    },
  },*/
		//this.codeLang
		created() {
			this.codeLang = this.$i18n.locale;
		},
	};
</script>
