<template>
	<ValidationObserver ref="observer" v-slot="{ handleSubmit, reset }">
		<b-form
			@submit.prevent="handleSubmit(newExtendedPayment)"
			@reset.prevent="reset"
		>
			<div class="col-lg-4 offset-lg-4 text-left">
				<h2 class="title-bold">{{ $t("newPayment.invoiceSettlement") }}</h2>
				<hr />
				<h5 class="title-bold">
					{{ $t("newPayment.useThisFormToSettle") }}
				</h5>
				<hr />
				<div class="text-right">
					<b-button
						class="text-right"
						variant="outline"
						v-b-tooltip.hover
						:title="$t('newPayment.executeImmediateOrLater')"
					>
						&#9432;
					</b-button>
				</div>
				<hr />
				<div class="background-outline">
					<div v-if="isloading2" class="text-center">
						<b-spinner
							style="width: 3rem; height: 3rem;"
							class="ml-auto text-center"
							:variant="secondary"
						></b-spinner>
					</div>
					<div v-else-if class="background-modifier">
						<label class="small">
							{{ $t("newPayment.startByChoosingDebitAccount") }}
						</label>
						<ValidationProvider
							rules="required"
							name="debitAccountId"
							v-slot="{ valid, errors }"
						>
							<b-form-group
								id="debitAccountIdInputGroup"
								label-for="debitAccountIdInput"
							>
								<b-form-select
									@change="onChange"
									id="debitAccountIdInput"
									:state="errors[0] ? false : valid ? true : null"
									v-model="debitAccountId"
									><option
										v-for="account in accounts"
										:value="account.id"
										:key="account.id"
										>({{ account.id }})
										{{ account.iban.toString().replace(/\w{4}(?=.)/g, "$& ") }}
										{{ $n(account.credits - account.debits, "decimal") }}
										{{ account.currency }}</option
									>
									<template #first>
										<b-form-select-option value="" disabled>{{
											$t("newPayment.debitAccountId")
										}}</b-form-select-option>
									</template></b-form-select
								>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>
					</div>
					<br />

					<!-- <b-form-group label="" v-slot="{ ibanSlot }">
          <b-form-radio
            v-model="isIBAN"
            :aria-describedby="ibanSlot"
            name="radio_group_2"
            value="1"
            >{{ $t("newPayment.beneficiaryAccountIsIBAN") }}</b-form-radio
          >
          <b-form-radio
            v-model="isIBAN"
            :aria-describedby="ibanSlot"
            name="radio_group_2"
            value="0"
            >{{ $t("newPayment.beneficiaryAccountIsBBAN") }}</b-form-radio
          >
        </b-form-group> -->

					<!-- <div v-if="isIBAN == 1"> -->
					<div class="background-modifier">
						<!-- <div v-if="debitAccountId > 0">
              <div v-if="creditAccountId == '' && creditAccountId2 == ''">
                <p>
                  {{ $t("newPayment.selectStoredBeneficiaryOrInsertANewOne") }}
                </p>
              </div>

              <div v-if="creditAccountId == ''"> -->
						<!--creditAccount2Required ? 'required' : '' -->
						<!-- <div v-if="beneficiaries.length > 0">
                  <ValidationProvider
                    rules=""
                    name="creditAccountId2"
                    v-slot="{ valid, errors }"
                  >
                    <b-form-group
                      id="creditAccountIdInputGroup2"
                      label-for="creditAccountIdInput2"
                    >
                      <b-form-select
                        @input="getBeneficiaryName()"
                        id="creditAccountIdInput2"
                        :state="errors[0] ? false : valid ? true : null"
                        v-model="creditAccountId2"
                        ><option
                          v-for="beneficiary in beneficiaries"
                          :value="beneficiary.beneficiaryAccount"
                          :key="beneficiary.beneficiaryAccount"
                          >({{ beneficiary.beneficiaryAccount }})
                          {{ beneficiary.alias }}
                          {{ beneficiary.beneficiaryIBAN }}
                        </option>
                        <template #first>
                          <b-form-select-option value="" disabled>{{
                            $t("newPayment.creditAccountId")
                          }}</b-form-select-option>
                        </template></b-form-select
                      >
                      <b-form-invalid-feedback id="inputLiveFeedback">{{
                        errors[0]
                      }}</b-form-invalid-feedback>
                    </b-form-group>
                  </ValidationProvider>
                </div> -->

						<!-- <p>
                  {{
                    beneficiaryDetails ? $t("newPayment.beneficiaryName") : ""
                  }}
                  {{
                    beneficiaryDetails ? beneficiaryDetails.beneficiaryName : ""
                  }}
                </p> -->
						<!-- </div>
            </div> -->

						<!-- <ValidationProvider
              :rules="{ iban: true }"
              :vid="name"
              v-bind="$attrs"
              v-slot="{ errors }"
              tag="div"
              class="w-full"
            >
              <b-form-input
                type="text"
                v-model="IBANfield"
                :state="errors[0] ? false : valid ? true : null"
                :placeholder="$t('newPayment.creditAccountId')"
              ></b-form-input>
              <b-form-invalid-feedback id="inputLiveFeedback">{{
                errors[0]
              }}</b-form-invalid-feedback>
            </ValidationProvider> -->

						<!-- <div v-if="creditAccountId2 == ''"> -->
						<!-- <div v-if="isIBAN == 1"> -->
						<!-- creditAccountRequired ? 'required' : '' | -->
						<label class="small">{{ $t("newPayment.creditAccountId") }}</label>
						<ValidationProvider
							:rules="{ iban: true }"
							:vid="name"
							v-bind="$attrs"
							v-slot="{ errors }"
							tag="div"
							class="w-full"
						>
							<b-form-group label-for="creditAccountIdInput" description="">
								<b-form-input
									type="text"
									v-model="invoice.creditAccountIBAN"
									:state="errors[0] ? false : valid ? true : null"
									:placeholder="$t('newPayment.creditAccountId')"
								></b-form-input>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>
						<!-- </div> -->

						<!-- creditAccountRequired ? 'required' : '' | -->
						<label class="small">{{ $t("newPayment.beneficiaryName") }}</label>
						<ValidationProvider
							rules="alpha_spaces|min:2|max:50"
							name="creditAccountHolder"
							v-slot="{ valid, errors }"
						>
							<b-form-group label-for="creditAccountHolder" description="">
								<b-form-input
									type="text"
									v-model="invoice.creditAccountHolder"
									:state="errors[0] ? false : valid ? true : null"
									:placeholder="$t('newPayment.beneficiaryName')"
								></b-form-input>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>
					</div>
					<br />
					<!-- </div> -->
					<!-- </div> -->
					<!-- <br />
        </div> -->

					<!-- <div v-if="isIBAN == 0">
          <div class="background-modifier">
            <ValidationProvider
              rules="alpha_dash|max:35"
              name="creditAccountId"
              v-slot="{ valid, errors }"
            >
              <b-form-group label-for="creditAccountIdInput" description="">
                <b-form-input
                  type="text"
                  v-model="creditAccountId"
                  :state="errors[0] ? false : valid ? true : null"
                  :placeholder="$t('newPayment.creditAccountId')"
                ></b-form-input>
                <b-form-invalid-feedback id="inputLiveFeedback">{{
                  errors[0]
                }}</b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>
            <b-form-group label="" v-slot="{ bicSlot }">
              <b-form-radio
                v-model="isBIC"
                :aria-describedby="bicSlot"
                name="radio_group_3"
                value="1"
                >{{ $t("newPayment.beneficiaryBankUsesBIC") }}</b-form-radio
              >
              <b-form-radio
                v-model="isBIC"
                :aria-describedby="bicSlot"
                name="radio_group_3"
                value="0"
                >{{
                  $t("newPayment.beneficiaryBankDoesNotUseBIC")
                }}</b-form-radio
              >
            </b-form-group>

            <div v-if="isBIC == 1">
              <ValidationProvider
                :rules="{ bic: true }"
                :vid="name1"
                v-bind="$attrs"
                v-slot="{ errors }"
                tag="div"
                class="w-full"
              >
                <b-form-input
                  type="text"
                  v-model="BICfield"
                  :state="errors[0] ? false : valid ? true : null"
                  :placeholder="$t('newPayment.bic')"
                ></b-form-input>
                <b-form-invalid-feedback id="inputLiveFeedback">{{
                  errors[0]
                }}</b-form-invalid-feedback>
              </ValidationProvider>
              <br />
            </div>

            <div v-if="isBIC == 0">
              <ValidationProvider
                rules="alpha_spaces|min:2|max:50"
                name="beneficiaryBank"
                v-slot="{ valid, errors }"
              >
                <b-form-group label-for="beneficiaryBank" description="">
                  <b-form-input
                    type="text"
                    v-model="beneficiaryBank"
                    :state="errors[0] ? false : valid ? true : null"
                    :placeholder="$t('newPayment.beneficiaryBank')"
                  ></b-form-input>
                  <b-form-invalid-feedback id="inputLiveFeedback">{{
                    errors[0]
                  }}</b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>

              <ValidationProvider
                rules="required|min:5|max:50"
                name="beneficiaryBankAddressLine1"
                v-slot="{ valid, errors }"
              >
                <b-form-group
                  label-for="beneficiaryBankAddressLine1Input"
                  description=""
                >
                  <b-form-input
                    type="text"
                    v-model="beneficiaryBankAddressLine1"
                    :state="errors[0] ? false : valid ? true : null"
                    :placeholder="$t('newPayment.beneficiaryBankAddressLine1')"
                  ></b-form-input>
                  <b-form-invalid-feedback id="inputLiveFeedback">{{
                    errors[0]
                  }}</b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
              <ValidationProvider
                rules="min:5|max:50"
                name="beneficiaryBankAddressLine2"
                v-slot="{ valid, errors }"
              >
                <b-form-group
                  label-for="beneficiaryBankAddressLine2Input"
                  description=""
                >
                  <b-form-input
                    type="text"
                    v-model="beneficiaryBankAddressLine2"
                    :state="errors[0] ? false : valid ? true : null"
                    :placeholder="$t('newPayment.beneficiaryBankAddressLine2')"
                  ></b-form-input>
                  <b-form-invalid-feedback id="inputLiveFeedback">{{
                    errors[0]
                  }}</b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
              <ValidationProvider
                rules="required|min:5|max:50"
                name="city"
                v-slot="{ valid, errors }"
              >
                <b-form-group
                  label-for="beneficiaryBankCityInput"
                  description=""
                >
                  <b-form-input
                    type="text"
                    v-model="beneficiaryBankCity"
                    :state="errors[0] ? false : valid ? true : null"
                    :placeholder="$t('newPayment.beneficiaryBankCity')"
                  ></b-form-input>
                  <b-form-invalid-feedback id="inputLiveFeedback">{{
                    errors[0]
                  }}</b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
              <ValidationProvider
                rules="required|min:3|max:10"
                name="beneficiaryBankZip"
                v-slot="{ valid, errors }"
              >
                <b-form-group
                  label-for="beneficiaryBankZipInput"
                  description=""
                >
                  <b-form-input
                    type="text"
                    v-model="beneficiaryBankZip"
                    :state="errors[0] ? false : valid ? true : null"
                    :placeholder="$t('newPayment.beneficiaryBankZip')"
                  ></b-form-input>
                  <b-form-invalid-feedback id="inputLiveFeedback">{{
                    errors[0]
                  }}</b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
              <ValidationProvider
                rules="required"
                name="beneficiaryCountry"
                v-slot="{ valid, errors }"
              >
                <b-form-group
                  id="beneficiaryBankCountryInputGroup"
                  label-for="beneficiaryBankCountryInput"
                >
                  <b-form-select
                    @change="onChange"
                    id="countryInput"
                    :state="errors[0] ? false : valid ? true : null"
                    v-model="beneficiaryBankCountry"
                    ><option
                      v-for="country in countries"
                      :value="country.countryCode"
                      :key="country.countryCode"
                      >{{ countryNames.getName(country.countryCode, codeLang) }}
                    </option>
                    <template #first>
                      <b-form-select-option value="" disabled>{{
                        $t("newPayment.beneficiaryBankCountry")
                      }}</b-form-select-option>
                    </template></b-form-select
                  >
                  <b-form-invalid-feedback id="inputLiveFeedback">{{
                    errors[0]
                  }}</b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
              <br />
            </div>

            <ValidationProvider
              rules="alpha_spaces|min:2|max:50"
              name="creditAccountHolder"
              v-slot="{ valid, errors }"
            >
              <b-form-group label-for="creditAccountHolder" description="">
                <b-form-input
                  type="text"
                  v-model="creditAccountHolder"
                  :state="errors[0] ? false : valid ? true : null"
                  :placeholder="$t('newPayment.beneficiaryName')"
                ></b-form-input>
                <b-form-invalid-feedback id="inputLiveFeedback">{{
                  errors[0]
                }}</b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>

            <ValidationProvider
              rules="required|min:5|max:50"
              name="addressLine1"
              v-slot="{ valid, errors }"
            >
              <b-form-group label-for="addressLine1Input" description="">
                <b-form-input
                  type="text"
                  v-model="beneficiaryAddressLine1"
                  :state="errors[0] ? false : valid ? true : null"
                  :placeholder="$t('newPayment.beneficiaryAddressLine1')"
                ></b-form-input>
                <b-form-invalid-feedback id="inputLiveFeedback">{{
                  errors[0]
                }}</b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>
            <ValidationProvider
              rules="min:5|max:50"
              name="addressLine2"
              v-slot="{ valid, errors }"
            >
              <b-form-group label-for="addressLine2Input" description="">
                <b-form-input
                  type="text"
                  v-model="beneficiaryAddressLine2"
                  :state="errors[0] ? false : valid ? true : null"
                  :placeholder="$t('newPayment.beneficiaryAddressLine2')"
                ></b-form-input>
                <b-form-invalid-feedback id="inputLiveFeedback">{{
                  errors[0]
                }}</b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>
            <ValidationProvider
              rules="required|min:5|max:50"
              name="city"
              v-slot="{ valid, errors }"
            >
              <b-form-group label-for="cityInput" description="">
                <b-form-input
                  type="text"
                  v-model="beneficiaryCity"
                  :state="errors[0] ? false : valid ? true : null"
                  :placeholder="$t('newPayment.beneficiaryCity')"
                ></b-form-input>
                <b-form-invalid-feedback id="inputLiveFeedback">{{
                  errors[0]
                }}</b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>
            <ValidationProvider
              rules="required|min:3|max:10"
              name="zip"
              v-slot="{ valid, errors }"
            >
              <b-form-group label-for="zipInput" description="">
                <b-form-input
                  type="text"
                  v-model="beneficiaryZip"
                  :state="errors[0] ? false : valid ? true : null"
                  :placeholder="$t('newPayment.beneficiaryZip')"
                ></b-form-input>
                <b-form-invalid-feedback id="inputLiveFeedback">{{
                  errors[0]
                }}</b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>
            <ValidationProvider
              rules="required"
              name="beneficiaryCountry"
              v-slot="{ valid, errors }"
            >
              <b-form-group id="countryInputGroup" label-for="countryInput">
                <b-form-select
                  @change="onChange"
                  id="countryInput"
                  :state="errors[0] ? false : valid ? true : null"
                  v-model="beneficiaryCountry"
                  ><option
                    v-for="country in countries"
                    :value="country.countryCode"
                    :key="country.countryCode"
                    >{{ countryNames.getName(country.countryCode, codeLang) }}
                  </option>
                  <template #first>
                    <b-form-select-option value="" disabled>{{
                      $t("newPayment.beneficiaryCountry")
                    }}</b-form-select-option>
                  </template></b-form-select
                >
                <b-form-invalid-feedback id="inputLiveFeedback">{{
                  errors[0]
                }}</b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>
          </div>

          <br />
        </div> -->

					<div class="background-modifier">
						<div class="row">
							<div class="col-md-4">
								<label class="small">{{ $t("account.currency") }}</label>
								<ValidationProvider
									rules="required"
									name="selected"
									v-slot="{ valid, errors }"
								>
									<b-form-group
										id="sourceCurrencyInputGroup"
										:label-for="sourceCurrencyInput"
									>
										<b-form-select
											@change="convert"
											id="sourceCurrencyInput"
											:state="errors[0] ? false : valid ? true : null"
											v-model="invoice.currency"
											><option
												v-for="currency in currencies"
												:value="currency.CharCode"
												:key="currency.CharCode"
												>{{ currency.CharCode }}</option
											>
											<template #first>
												<b-form-select-option value="" disabled>{{
													$t("newPayment.paymentCurrency")
												}}</b-form-select-option>
											</template></b-form-select
										>
										<b-form-invalid-feedback id="inputLiveFeedback">{{
											errors[0]
										}}</b-form-invalid-feedback>
									</b-form-group>
								</ValidationProvider>
							</div>

							<!--  <div class="col-md-4">
    <ValidationProvider rules="" name="selected" v-slot="{ valid, errors }">
      <b-form-group id="targetCurrencyInputGroup" label-for="targetCurrencyInput">
        <b-form-select @change="convert"
        id="targetCurrencyInput"
        :state="errors[0] ? false : (valid ? true : null)"
        v-model="selected[1]"
       ><option v-for="currency in currencies" :value="currency.CharCode" :key="currency.CharCode">{{ currency.CharCode }}</option>
       <template #first>
         <b-form-select-option value="" disabled>{{ $t('to') }}</b-form-select-option>
       </template></b-form-select>
        <b-form-invalid-feedback id="inputLiveFeedback">{{ errors[0] }}</b-form-invalid-feedback>
      </b-form-group>
    </ValidationProvider>
  </div> -->

							<div class="col-md-8">
								<label class="small">{{ $t("newPayment.amount") }}</label>
								<ValidationProvider
									rules="required|double|min_value:0.01"
									name="amount"
									v-slot="{ valid, errors }"
								>
									<b-form-group label-for="amountInput" description="">
										<b-form-input
											class="custom-amount"
											@input="convert"
											type="text"
											v-model="invoice.totalVat"
											:state="errors[0] ? false : valid ? true : null"
											:placeholder="$t('newPayment.amount')"
										></b-form-input>
										<b-form-invalid-feedback id="inputLiveFeedback">{{
											errors[0]
										}}</b-form-invalid-feedback>
									</b-form-group>
								</ValidationProvider>
							</div>
						</div>

						<div v-if="selected[0] !== accountCurrency.currency">
							<div class="col-md-12 text-right">
								<p v-model="result" class="small" type="number" readonly>
									<!-- inside p tag-->
									{{ result !== "undefined" ? accountCurrency.currency : "" }}
									{{ result ? $n(result.toFixed(2), "decimal") : "" }}
								</p>
							</div>
						</div>
					</div>
					<br />

					<!--  <ValidationProvider rules="required|double|min_value:0.01" name="amount" v-slot="{ valid, errors }">
      <b-form-group
        
        label-for="amountInput"
        description=""          >
        <b-form-input
          type="text"
          v-model="amount"
          :state="errors[0] ? false : (valid ? true : null)"
          :placeholder="$t('newPayment.amount')"
        ></b-form-input>
        <b-form-invalid-feedback id="inputLiveFeedback">{{ errors[0] }}</b-form-invalid-feedback>

        <div v-bind:style="(balance-amount)>0 ? color=green : color=red">
          <p v-if="amount.length > 0" class="small">{{ $t('newPayment.remainingBalance') }} {{ $n(balance - amount, 'decimal') }}</p>
        </div>

      </b-form-group>
    </ValidationProvider> -->
					<div class="background-modifier">
						<label class="small">{{ $t("newPayment.message") }}</label>
						<ValidationProvider
							rules="min:1|max:50"
							name="message"
							v-slot="{ valid, errors }"
						>
							<b-form-group label-for="messageInput" description="">
								<b-form-input
									type="text"
									v-model="invoice.paymentReference"
									:state="errors[0] ? false : valid ? true : null"
									:placeholder="$t('newPayment.message')"
								></b-form-input>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>
					</div>
					<br />
					<!-- <div v-if="isIBAN == '1'"> -->
					<div class="background-modifier">
						<label class="small">{{ $t("newPayment.dueDate") }}</label>
						<div class="row">
							<div class="col-md-12 offset-md-0">
								<label for="">{{ $t("newPayment.dueDate") }}</label>
								<b-form-datepicker
									v-model="invoice.dueDate"
									:min="min"
									:locale="$i18n.locale"
									:label-no-date-selected="$t('newPayment.noDateSelected')"
									:label-help="
										$t('newPayment.useCursorKeysToNavigateCalendarDates')
									"
								></b-form-datepicker>
							</div>
						</div>
						<br />

						<!-- <b-form-group label="" v-slot="{ oneOrMany }">
              <b-form-radio
                v-model="radio"
                :aria-describedby="oneOrMany"
                name="radio_group_1"
                value="once"
                >{{ $t("newPayment.payOnce") }}</b-form-radio
              >
              <b-form-radio
                v-model="radio"
                :aria-describedby="oneOrMany"
                name="radio_group_1"
                value="monthly"
                >{{ $t("newPayment.monthly") }}</b-form-radio
              >
            </b-form-group> -->

						<!-- <div v-show="radio === 'monthly'">
              <div class="row">
                <!--    <div class="col-md-6 offset-md-0">
          <label for="">{{ $t('newPayment.startDate') }}</label>
          <b-form-datepicker vee-validate="{radio=='monthly' ? 'required':''}" :initial-date="startDate" v-model="startDate" :min="min" :locale="$i18n.locale" :label-no-date-selected="$t('newPayment.noDateSelected')" :label-help="$t('newPayment.useCursorKeysToNavigateCalendarDates')"></b-form-datepicker> 
      </div>  -->

						<!-- <div class="col-md-12 offset-md-0">
                   required: endDateRequired -->
						<!-- <ValidationProvider
                    rules=""
                    name="endDate"
                    v-slot="{ valid, errors }"
                  >
                    <b-form-group label-for="endDateInput" description="">
                      <label for="">{{ $t("newPayment.endDate") }}</label>
                      <b-form-datepicker
                        v-model="endDate"
                        :min="minEnd"
                        :state="errors[0] ? false : valid ? true : null"
                        :locale="$i18n.locale"
                        :label-no-date-selected="
                          $t('newPayment.noDateSelected')
                        "
                        :label-help="
                          $t('newPayment.useCursorKeysToNavigateCalendarDates')
                        "
                      ></b-form-datepicker>

                      <b-form-invalid-feedback id="inputLiveFeedback">{{
                        errors[0]
                      }}</b-form-invalid-feedback>
                    </b-form-group>
                  </ValidationProvider>
                </div>
              </div>
            </div>  -->
						<!-- </div> -->
					</div>
					<br />
					<!-- <div v-if="isIBAN == '1'">
          <div v-if="creditAccountId2 == ''">
            <div class="background-modifier">
              <div class="row">
                <div class="col-md-6 offset-md-0">
                  <b-form-checkbox
                    id="checkbox-1"
                    v-model="status"
                    name="checkbox-1"
                    value="accepted"
                    unchecked-value="not_accepted"
                  >
                    {{ $t("newPayment.storeBeneficiary") }}
                  </b-form-checkbox>
                </div>
                <div class="col-md-6 offset-md-0">
                  <ValidationProvider
                    rules="min:1|max:50"
                    name="alias"
                    v-slot="{ valid, errors }"
                  >
                    <b-form-group label-for="aliasInput" description="">
                      <b-form-input
                        type="text"
                        v-model="alias"
                        :state="errors[0] ? false : valid ? true : null"
                        :placeholder="$t('newPayment.alias')"
                      ></b-form-input>
                      <b-form-invalid-feedback id="inputLiveFeedback">{{
                        errors[0]
                      }}</b-form-invalid-feedback>
                    </b-form-group>
                  </ValidationProvider>
                </div>
              </div>
            </div>
          </div>
        </div> -->
					<hr />

					<!-- CreditAccount1:{{ creditAccountId }} CreditAccount2:{{
          creditAccountId2
        }} -->

					<div v-if="debitAccountId > 0">
						<div
							v-if="
								debitAccountId != creditAccountId &&
									debitAccountId != creditAccountId2
							"
						>
							<div
								v-if="creditAmount.credits - debitAmount.debits - result >= 0"
							>
								<div v-if="role == 3">
									<b-button
										type="submit"
										@click="isloading"
										class="btn btn-info text-light btn-block"
										>{{ $t("newPayment.submit")
										}}<b-spinner
											v-if="isloading"
											class="ml-auto"
											:variant="light"
										></b-spinner
									></b-button>
								</div>
								<div v-if="role == 12">
									<b-button
										type="submit"
										@click="isloading"
										class="btn btn-info text-light btn-block"
										>{{ $t("newPayment.submitForApproval")
										}}<b-spinner
											v-if="isloading"
											class="ml-auto"
											:variant="light"
										></b-spinner
									></b-button>
								</div>
							</div>

							<div
								v-else-if="
									result - (creditAmount.credits - debitAmount.debits) > 0
								"
								class=""
							>
								<b-alert variant="danger" show>{{
									$t("newPayment.insufficientBalance")
								}}</b-alert>
							</div>
						</div>
						<div v-else class="">
							<b-alert variant="danger" show>{{
								$t("newPayment.accountsMustBeDifferent")
							}}</b-alert>
						</div>

						<hr />
						<b-button
							type="reset"
							class="btn btn-alert text-light btn-block"
							@click="resetForm()"
							>{{ $t("reset") }}</b-button
						>
					</div>

					<div v-if="msg && $bvToast.show('notification-toast')"></div>
					<div class="action">
						<br />
						<b-toast
							id="notification-toast"
							variant="secondary"
							static
							no-auto-hide
						>
							<template #toast-title>
								<div class="d-flex flex-grow-1 align-items-baseline">
									<b-img
										class="logo mb-2"
										:src="require('/public/images/logo.png')"
										alt=""
										width="12"
										height="12"
									></b-img>
									<strong class="mr-auto">{{ $t("notification") }}</strong>
									<small class="text-muted mr-2"></small>
								</div>
							</template>
							{{ msg }}
						</b-toast>
					</div>
				</div>
			</div>
		</b-form>
	</ValidationObserver>
</template>

<script>
	import { ValidationObserver, ValidationProvider } from "vee-validate";
	import AuthService from "@/services/AuthService.js";
	var countryNames = require("i18n-iso-countries");
	const currencyAPI = require("axios").default;

	export default {
		name: "NewPaymentForm",
		components: {
			ValidationObserver,
			ValidationProvider,
		},
		async created() {
			if (!this.$store.getters.isLoggedIn) {
				this.$router.push("login");
			}
			this.newRole = this.$route.params.reference;
			let lang = this.$i18n.locale;
			this.codeLang = this.$i18n.locale;
			this.countryNames.registerLocale(
				require(`i18n-iso-countries/langs/${this.codeLang}.json`)
			);
		},
		watch: {
			codeLang(newVal, oldVal) {
				this.countryNames.registerLocale(
					require(`i18n-iso-countries/langs/${newVal}.json`)
				);
			},
		},
		data() {
			const now = new Date();
			const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
			// 15th two months prior

			const minDate = new Date(today);
			//   const minDate = new Date(openingDate.getFullYear(), openingDate.getMonth(), openingDate.getDate())
			minDate.setYear(minDate.getDate() + 1);

			const minEndDate = new Date(today);
			minEndDate.setMonth(minEndDate.getMonth() + 1);

			return {
				invoice: "",
				accounts: [],
				debitAccountId: "",
				creditAccountId: "",
				creditAccountId2: "",
				creditAccountHolder: "",
				beneficiaryIBAN: "",
				beneficiaryBBAN: "",
				sortCode: "",
				bic: "",
				beneficiaryAddressLine1: "",
				beneficiaryAddressLine2: "",
				beneficiaryCity: "",
				beneficiaryZip: "",
				beneficiaryCountry: "",
				beneficiaryBank: "",
				beneficiaryBankAddressLine1: "",
				beneficiaryBankAddressLine2: "",
				beneficiaryBankCity: "",
				beneficiaryBankZip: "",
				beneficiaryBankCountry: "",
				balance: "",
				accountCurrency: "", //this.accountCurrency.currency ? this.accountCurrency.currency : '',
				amount: "",
				message: "",
				radio: "once",
				dueDate: new Date().toISOString().slice(0, 10),
				endDate: new Date().toISOString().slice(0, 10),
				min: minDate,
				minEnd: minEndDate,
				msg: "",
				isIBAN: 1,
				isBIC: 1,
				status: "not_accepted",
				paymentStatus: 1,
				currencies: {},
				selected: ["EUR", "USD"],
				//  inputted: "",
				result: null,
				isloading: false,
				isloading2: false,
				// currencies: ["EUR"],
				countries: [],
				countryNames: countryNames,
				rules: {
					onlyNumbers: (value) => {
						const pattern = /^\d+$/.test(value);
						if (!pattern) this.inputed = this.inputed.replace(/\D/g, "");
						return true;
					},
				},
			};
		},
		/* computed: {
        isCreditAccountRequired() {
            if(this.creditAccountId2 === '')
                return true; // creditAccountId is required
            return false;
        },
        isCreditAccount2Required() {
            // check if creditAccountId is empty
            if(this.creditAccountId === '')
                return true; // creditAccount2 is required
            return false;
        }
    },*/

		endDateRequired() {
			if (radio === "monthly") {
				return true;
			} else {
				return false;
			}
		},
		creditAccountRequired() {
			if (this.creditAccountId2 === "") {
				return true;
			} else {
				return false;
			}
		},
		creditAccount2Required() {
			if (creditAccountId === "") {
				return true;
			} else {
				return false;
			}
		},
		async created() {
			if (!this.$store.getters.isLoggedIn) {
				this.$router.push("login");
			}
			if (
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 3 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 15 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 14 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 13 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 12
			) {
				this.$router.push("overview");
			}
			let lang = this.$i18n.locale;
			this.role = this.$store.getters.getUser.role;
			this.username = this.$store.getters.getUser.username;
			this.firstname = this.$store.getters.getUser.firstname;
		},
		methods: {
			getUserRole() {
				const role = this.$store.getters.getUser.role;
				return role;
			},
			async onChange(event) {
				try {
					const id = this.debitAccountId;
					const response1 = await AuthService.getCreditAmount(id);
					this.creditAmount = response1;

					//  console.log(response1);
					const response2 = await AuthService.getDebitAmount(id);
					this.debitAmount = response2;

					const response3 = await AuthService.getAccountCurrency(id);
					this.accountCurrency = response3;
					console.log(accountCurrency.currency);

					//   console.log(response2);
					const balance1 = this.creditAmount.credits - this.debitAmount.debits;
					this.balance = balance1;
					console.log(balance);
				} catch (error) {
					console.log(error);
				}
			},
			convert() {
				// Default currency values / EUR
				let defaultCurrency = {
					Value: 1,
					Nominal: 1,
				};
				// First selected currency details

				//NOTE CHANGES
				// let firstCurrency = this.currencies[this.selected[0]] ?? defaultCurrency,
				//   firstCurrencyValue = firstCurrency.Value * Number(this.amount),
				//   firstCurrencyNominal = firstCurrency.Nominal;

				let firstCurrency =
						this.currencies[this.invoice.totalVat] ?? defaultCurrency,
					firstCurrencyValue =
						firstCurrency.Value * Number(this.invoice.totalVat),
					firstCurrencyNominal = firstCurrency.Nominal;
				// Second selected currency details
				let secondCurrency =
						this.currencies[this.accountCurrency.currency /*selected[1]*/] ??
						defaultCurrency,
					secondCurrencyValue = secondCurrency.Value,
					secondCurrencyNominal = secondCurrency.Nominal;
				// Result calculating
				let result =
					firstCurrencyValue /
					firstCurrencyNominal /
					(secondCurrencyValue / secondCurrencyNominal);
				// Rounding to ten thousandths
				this.result = result ? Math.floor(result * 10000) / 10000 : null;
			},
			async getCurrencyData() {
				try {
					const response = await AuthService.getCurrencyData();
					this.currencies = response.data.Valute;
					console.log(response.data.Valute);
					// Adding all charCodes to array
					for (let code in response) {
						this.countries.push(code);
					}
				} catch (error) {
					console.log(error);
				}
			},
			async newExtendedPayment() {
				try {
					const details = {
						paymentStatus: this.role == 12 ? 0 : this.paymentStatus,
						lang: this.$i18n.locale,
						debitAccountId: this.debitAccountId,
						creditAccountId: this.invoice.creditAccountId,
						// creditAccountId: this.creditAccountId2
						//   ? this.creditAccountId2
						//   : this.creditAccountId,
						creditAccountHolder: this.invoice.creditAccountHolder,
						// creditAccountHolder: this.creditAccountHolder
						//   ? this.creditAccountHolder
						//   : this.beneficiaryDetails.beneficiaryName,
						bic: this.bic,
						beneficiaryAddressLine1: this.beneficiaryAddressLine1,
						beneficiaryAddressLine2: this.beneficiaryAddressLine2,
						beneficiaryCity: this.beneficiaryCity,
						beneficiaryZip: this.beneficiaryZip,
						beneficiaryCountry: this.beneficiaryCountry,
						beneficiaryBank: this.beneficiaryBank,
						beneficiaryBankAddressLine1: this.beneficiaryBankAddressLine1,
						beneficiaryBankAddressLine2: this.beneficiaryBankAddressLine2,
						beneficiaryBankCity: this.beneficiaryBankCity,
						beneficiaryBankZip: this.beneficiaryBankZip,
						beneficiaryBankCountry: this.beneficiaryBankCountry,
						baseCurrency: this.accountCurrency.currency,
						amount: this.invoice.totalVat,
						paymentCurrency: this.invoice.currency,
						// amount: this.result,
						// paymentCurrency: this.selected[0],
						//currencyAmount: this.amount,
						currencyAmount: this.invoice.totalVat,
						// message: this.message,
						message: this.invoice.paymentReference,
						// dueDate: this.dueDate,
						dueDate: this.invoice.dueDate,
						isIBAN: this.isIBAN,

						paymentType: 3,
						dueDate: this.dueDate ? this.dueDate : "",
						//  endDate: this.endDate ? this.endDate : '',
						//  frequency: this.frequency ? this.frequency : 1
					}; //&& this.radio == 'once'

					console.log(details);
					const todaysDate = new Date().toISOString().slice(0, 10);
					console.log(this.dueDate, todaysDate);
					if (this.role != 12) {
						if (this.radio == "once" && this.dueDate > todaysDate) {
							this.newPaymentOrder();
							if (this.status == "accepted") {
								this.newBeneficiary();
							}
						} else if (
							this.radio == "monthly" ||
							(this.radio == "monthly" && this.dueDate > todaysDate)
						) {
							this.newStandingOrder();
							if (this.status == "accepted") {
								this.newBeneficiary();
							}
						} else if (this.radio == "once" && this.dueDate == todaysDate) {
							this.isloading = true;
							const response = await AuthService.newExtendedPayment(details);
							this.msg = response.msg;
							this.isloading = false;
							if (this.status == "accepted") {
								this.newBeneficiary();
							}
						} else if (this.radio == "monthly" && this.dueDate == todaysDate) {
							//   const response = await AuthService.newPayment(details);
							//  this.msg = response.msg;
							this.newStandingOrder();
							if (this.status == "accepted") {
								this.newBeneficiary();
							}
						}
					} else {
						if (this.radio == "once" && this.dueDate > todaysDate) {
							this.newPaymentOrder();
							if (this.status == "accepted") {
								this.newBeneficiary();
							}
						} else if (
							this.radio == "monthly" ||
							(this.radio == "monthly" && this.dueDate > todaysDate)
						) {
							this.newStandingOrder();
							if (this.status == "accepted") {
								this.newBeneficiary();
							}
						} else if (this.radio == "once" && this.dueDate == todaysDate) {
							this.isloading = true;
							const response = await AuthService.newPaymentOrder(details);
							this.msg = response.msg;
							this.isloading = false;
							if (this.status == "accepted") {
								this.newBeneficiary();
							}
						} else if (this.radio == "monthly" && this.dueDate == todaysDate) {
							//   const response = await AuthService.newPayment(details);
							//  this.msg = response.msg;
							this.newStandingOrder();
							if (this.status == "accepted") {
								this.newBeneficiary();
							}
						}
					}

					// this.resetForm();
				} catch (error) {
					this.msg = error.response.data.msg;
					/*this.$router.push('accounts');*/
				}
			},

			/*async getAccountCurrency() {
        try {
          const details = {
            debitAccountId: this.debitAccountId,
          };
          const response3 = await AuthService.getAccountCurrency(details.debitAccountId);
          this.accountCurrency = response3;
          console.log(accountCurrency);
        } catch (error) {
          console.log(error);
        }
      },*/

			async newPaymentOrder() {
				try {
					const details = {
						lang: this.$i18n.locale,
						userId: this.$store.getters.getUser.id,
						debitAccountId: this.debitAccountId,
						creditAccountId: this.creditAccountId2
							? this.creditAccountId2
							: this.creditAccountId,
						creditAccountHolder: this.creditAccountHolder
							? this.creditAccountHolder
							: this.beneficiaryDetails.beneficiaryName,
						bic: this.bic,
						beneficiaryAddressLine1: this.beneficiaryAddressLine1,
						beneficiaryAddressLine2: this.beneficiaryAddressLine2,
						beneficiaryCity: this.beneficiaryCity,
						beneficiaryZip: this.beneficiaryZip,
						beneficiaryCountry: this.beneficiaryCountry,
						beneficiaryBank: this.beneficiaryBank,
						beneficiaryBankAddressLine1: this.beneficiaryBankAddressLine1,
						beneficiaryBankAddressLine2: this.beneficiaryBankAddressLine2,
						beneficiaryBankCity: this.beneficiaryBankCity,
						beneficiaryBankZip: this.beneficiaryBankZip,
						beneficiaryBankCountry: this.beneficiaryBankCountry,
						baseCurrency: this.accountCurrency.currency,
						amount: this.result,
						paymentCurrency: this.selected[0],
						currencyAmount: this.amount,
						message: this.message,
						dueDate: this.dueDate,
						isIBAN: this.isIBAN,
						paymentStatus: this.role == 12 ? 0 : this.paymentStatus,
						paymentType: 3,
					};
					this.isloading = true;
					const response = await AuthService.newPaymentOrder(details);
					this.msg = response.msg;
					this.isloading = false;
					console.log(response);
				} catch (error) {
					this.isloading = false;
					console.log(error);
				}
			},

			async newStandingOrder() {
				const todaysDate = new Date().toISOString().slice(0, 10);
				const dateX = new Date(todaysDate);
				const newStartDate =
					dateX.getFullYear() +
					"-" +
					(dateX.getMonth() + 2) +
					"-" +
					(dateX.getDate() + 2);
				try {
					const details = {
						lang: this.$i18n.locale,
						userId: this.$store.getters.getUser.id,
						debitAccountId: this.debitAccountId,
						creditAccountId: this.creditAccountId2
							? this.creditAccountId2
							: this.creditAccountId,
						creditAccountHolder: this.creditAccountHolder
							? this.creditAccountHolder
							: this.beneficiaryDetails.beneficiaryName,
						bic: this.bic,
						beneficiaryAddressLine1: this.beneficiaryAddressLine1,
						beneficiaryAddressLine2: this.beneficiaryAddressLine2,
						beneficiaryCity: this.beneficiaryCity,
						beneficiaryZip: this.beneficiaryZip,
						beneficiaryCountry: this.beneficiaryCountry,
						beneficiaryBank: this.beneficiaryBank,
						beneficiaryBankAddressLine1: this.beneficiaryBankAddressLine1,
						beneficiaryBankAddressLine2: this.beneficiaryBankAddressLine2,
						beneficiaryBankCity: this.beneficiaryBankCity,
						beneficiaryBankZip: this.beneficiaryBankZip,
						beneficiaryBankCountry: this.beneficiaryBankCountry,
						baseCurrency: this.accountCurrency.currency,
						amount: this.result,
						paymentCurrency: this.selected[0],
						currencyAmount: this.amount,
						message: this.message,
						startDate: this.dueDate == todaysDate ? newStartDate : this.dueDate,
						endDate: this.endDate,
						frequency: this.frequency ? this.frequency : 1,
						isIBAN: this.isIBAN,
						paymentStatus: this.role == 12 ? 0 : this.paymentStatus,
						paymentType: 3,
					};
					console.log(this.dueDate, this.endDate);
					this.isloading = true;
					const response = await AuthService.newStandingOrder(details);
					this.msg = response.msg;
					this.isloading = false;
					console.log(response);
				} catch (error) {
					this.isloading = false;
					console.log(error);
				}
			},

			async newBeneficiary() {
				try {
					const details = {
						lang: this.$i18n.locale,
						userId: this.$store.getters.getUser.id,
						beneficiaryAccount: this.creditAccountId2
							? this.creditAccountId2
							: this.creditAccountId,
						beneficiaryName: this.creditAccountHolder,
						bic: this.bic,
						beneficiaryAddressLine1: this.beneficiaryAddressLine1,
						beneficiaryAddressLine2: this.beneficiaryAddressLine2,
						beneficiaryCity: this.beneficiaryCity,
						beneficiaryZip: this.beneficiaryZip,
						beneficiaryCountry: this.beneficiaryCountry,
						beneficiaryBank: this.beneficiaryBank,
						beneficiaryBankAddressLine1: this.beneficiaryBankAddressLine1,
						beneficiaryBankAddressLine2: this.beneficiaryBankAddressLine2,
						beneficiaryBankCity: this.beneficiaryBankCity,
						beneficiaryBankZip: this.beneficiaryBankZip,
						beneficiaryBankCountry: this.beneficiaryBankCountry,
						alias: this.alias ? this.alias : this.creditAccountHolder,
						isIBAN: this.isIBAN,
						currency: this.selected[0],
						beneficiaryIBAN: "",
						beneficiaryBBAN: "",
						sortCode: this.sortCode,
						email: "",
						firstname: "",
						lastname: "",
					};
					const response = await AuthService.newBeneficiary(details);
					this.accounts = response;
					console.log(response);
				} catch (error) {
					console.log(error);
				}
			},

			// async getUsersAccountList() {
			//   try {
			//     const details = {
			//       id: this.$store.getters.getUser.id,
			//     };
			//     const response = await AuthService.getUsersAccountList(details.id);
			//     this.accounts = response;
			//     console.log(response);
			//   } catch (error) {
			//     console.log(error);
			//   }
			// },

			async getClientsCurrentAccounts() {
				try {
					const details = {
						id: this.$store.getters.getUser.id,
					};
					this.isloading2 = true;
					const response = await AuthService.getClientsCurrentAccounts(
						details.id
					);
					this.accounts = response;
					this.isloading2 = false;
					console.log(response);
				} catch (error) {
					this.isloading2 = false;
					console.log(error);
				}
			},

			async getUsersBeneficiaryList() {
				try {
					const details = {
						id: this.$store.getters.getUser.id,
					};
					const response = await AuthService.getUsersBeneficiaryList(
						details.id
					);
					this.beneficiaries = response;
					console.log(response);
				} catch (error) {
					console.log(error);
				}
			},

			async getBeneficiaryName() {
				try {
					const details = {
						userid: this.$store.getters.getUser.id,
						accountid: this.creditAccountId2,
					};
					const response = await AuthService.getBeneficiaryName(
						details.accountid,
						details.userid
					);
					this.beneficiaryDetails = response;
					console.log(response);
				} catch (error) {
					console.log(error);
				}
			},

			resetForm() {
				this.debitAccountId = "";
				this.creditAccountId = "";
				this.creditAccountId2 = "";
				this.creditAccountHolder = "";
				this.bic = "";
				this.beneficiaryAddressLine1 = "";
				this.beneficiaryAddressLine2 = "";
				this.beneficiaryCity = "";
				this.beneficiaryZip = "";
				this.beneficiaryCountry = "";
				this.beneficiaryBank = "";
				this.beneficiaryBankAddressLine1 = "";
				this.beneficiaryBankAddressLine2 = "";
				this.beneficiaryBankCity = "";
				this.beneficiaryBankZip = "";
				this.beneficiaryBankCountry = "";
				// this.beneficiaryDetails.beneficiaryName = '';
				this.amount = "";
				this.selected[0] = "";
				this.message = "";
				this.alias = "";
				this.dueDate = "";
				this.endDate = "";
				//  this.msg = '';
				requestAnimationFrame(() => {
					this.$refs.observer.reset();
				});
			},
			async getCountries() {
				try {
					const response = await AuthService.getCountries();
					this.countries = response;
					console.log(response);
				} catch (error) {
					console.log(error);
				}
			},
			async getInvoiceDetailsByReference() {
				try {
					const id = this.$route.params.id;
					const response = await AuthService.getInvoiceDetailsByReference(id);
					this.invoice = response;
					console.log(response);
				} catch (error) {
					console.log(error);
				}
			},
		},

		mounted() {
			// this.getUsersAccountList();
			this.getClientsCurrentAccounts();
			this.getUsersBeneficiaryList();
			this.getCurrencyData();
			// this.getUserId();
			this.getCountries();
			this.getUserRole();
			this.balance;
			this.getInvoiceDetailsByReference();
			/*if (this.debitAccountId > 0) {
        this.getAccountCurrency();
      }*/
		},
	};
</script>
