<template>
	<ValidationObserver ref="observer" v-slot="{ handleSubmit, reset }">
		<b-form
			@submit.prevent="handleSubmit(editInvoiceItem)"
			@reset.prevent="reset"
		>
			<div class="col-lg-4 offset-lg-4 text-left">
				<h2 class="title-bold">
					{{ $t("customerInvoiceItem.id") }} {{ item.id }}
				</h2>
				<hr />

				<div class="background-modifier text-left">
					<div class="product-details">
						<label class="small">{{
							$t("customerInvoiceItem.productName")
						}}</label>
						<p>
							{{ item.productName }}
						</p>
						<label class="small">{{
							$t("customerInvoiceItem.productUnitPriceNet")
						}}</label>
						<p>
							{{ $n(item.productUnitPriceNet, "decimal") }}
						</p>
						<label class="small"
							>{{ $t("customerInvoiceItem.vatRate") }} (%)</label
						>
						<p>
							{{ $n(item.vatRate, "decimal") }}
						</p>
						<label class="small">{{
							$t("customerInvoiceItem.vatAmount")
						}}</label>
						<p>
							{{ $n(item.vatAmount, "decimal") }}
						</p>
						<label class="small">{{
							$t("customerInvoiceItem.productUnitPriceGross")
						}}</label>
						<p>
							{{ $n(item.productUnitPriceGross, "decimal") }}
						</p>
						<label class="small">{{
							$t("customerInvoiceItem.numberOfItems")
						}}</label>
						<p>
							{{ item.numberOfItems }}
						</p>
						<label class="small">{{
							$t("customerInvoiceItem.subTotal")
						}}</label>
						<p>
							{{ $n(item.subTotal, "decimal") }}
						</p>
					</div>

					<div class="text-right">
						<router-link
							class="btn btn-outline"
							:to="{
								name: 'edit-invoice-item',
								params: { id: item.id },
							}"
							>{{ $t("customerInvoiceItem.editInvoiceItem") }}</router-link
						>
					</div>
				</div>
				<br />

				<!-- disabled-->
				<!-- <div v-if="status == 'accepted'"> -->
				<!-- <b-button type="submit" class="btn btn-info text-light btn-block">{{
          $t("customerInvoiceItem.submit")
        }}</b-button> -->
				<!--   <p class="small">{{ $t("businessSignup.notAvailable") }}</p> -->
				<!-- </div> -->
				<!-- <hr /> -->
				<!-- <b-button
          type="reset"
          class="btn btn-alert text-light btn-block"
          @click="resetForm()"
          >{{ $t("reset") }}</b-button
        >
        <hr /> -->
				<div v-if="msg && $bvToast.show('notification-toast')"></div>
				<div class="action">
					<b-toast
						id="notification-toast"
						variant="secondary"
						static
						no-auto-hide
					>
						<template #toast-title>
							<div class="d-flex flex-grow-1 align-items-baseline">
								<b-img
									class="logo mb-2"
									:src="require('/public/images/logo.png')"
									alt=""
									width="12"
									height="12"
								></b-img>
								<strong class="mr-auto">{{ $t("notification") }}</strong>
								<small class="text-muted mr-2"></small>
							</div>
						</template>
						{{ msg }}
					</b-toast>
				</div>
			</div>
		</b-form>
	</ValidationObserver>
</template>

<script>
	import { ValidationObserver, ValidationProvider } from "vee-validate";
	import AuthService from "@/services/AuthService.js";
	// var countryNames = require("i18n-iso-countries");
	export default {
		name: "AddNewProduct",
		components: {
			ValidationObserver,
			ValidationProvider,
		},
		/*props: {
        codeLang: {
          type: String,
          default: "en",
        },
      },*/
		//this.codeLang
		created() {
			this.codeLang = this.$i18n.locale;
			// this.countryNames.registerLocale(
			//   require(`i18n-iso-countries/langs/${this.codeLang}.json`)
			// );
		},
		watch: {
			// codeLang(newVal, oldVal) {
			//   this.countryNames.registerLocale(
			//     require(`i18n-iso-countries/langs/${newVal}.json`)
			//   );
			// },
		},
		data() {
			// const now = new Date();
			// const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
			// // 15th two months prior
			// const minDate = new Date(today);
			// minDate.setYear(minDate.getFullYear() - 0);
			// const maxDate = new Date(today);
			// maxDate.setYear(maxDate.getFullYear() + 2);
			// minDate.setMonth(minDate.getMonth() - 2);
			// minDate.setDate(15);
			// 15th in two months

			return {
				msg: "",
				item: "",
				// productName: "",
				// productUnitPriceNet: 0.0,
				// //productUnitPriceGross: 0.0,
				// vatRate: 21.0,
				// numberOfItems: 1,
				// //subTotal: 0.0,
				// invoiceId: 0,
				// productId: 0,
				// min: minDate,
				// max: maxDate,
				//status: "not_accepted",
			};
		},
		async created() {
			if (!this.$store.getters.isLoggedIn) {
				this.$router.push("login");
			}
			if (
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 3 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 15 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 14 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 13 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 12
			) {
				this.$router.push("overview");
			}
			let lang = this.$i18n.locale;
			this.role = this.$store.getters.getUser.role;
			// this.username = this.$store.getters.getUser.username;
			// this.firstname = this.$store.getters.getUser.firstname;

			//this.getOfferById();
		},
		methods: {
			// calculateVATAmount() {
			//   this.vatAmountResult =
			//     (this.item.productUnitPriceNet * this.item.vatRate) / 100;
			//   return this.vatAmountResult;
			// },
			// calculateProductUnitPriceGross() {
			//   this.productUnitPriceGrossResult =
			//     1 * this.item.productUnitPriceNet + this.vatAmountResult;
			//   return this.productUnitPriceGrossResult;
			// },
			// calculateSubtotal() {
			//   this.subtotalResult =
			//     this.productUnitPriceGrossResult * this.item.numberOfItems;
			//   return this.subtotalResult;
			// },
			// calculateVATAmount() {
			//   this.vatAmountResult = (this.totalNetResult * this.vatRate) / 100;
			//   return this.vatAmountResult;
			// },
			// calculateTotalInclVAT() {
			//   this.totalIncVATResult = this.totalNetResult - this.vatAmountResult;
			//   return this.totalIncVATResult;
			// },

			// async editInvoiceItem() {
			//   try {
			//     const id = this.$route.params.id;
			//     const details = {
			//       userId: this.$store.getters.getUser.id,
			//       lang: this.$i18n.locale,
			//       invoiceId: this.item.invoiceId,
			//       productId: this.item.productId,
			//       //id: this.$route.params.id,
			//       productName: this.item.productName,
			//       // productDescription: this.productDescription,
			//       productUnitPriceNet: this.item.productUnitPriceNet,
			//       vatRate: this.item.vatRate,
			//       vatAmount: this.vatAmountResult,
			//       productUnitPriceGross: this.calculateProductUnitPriceGross(),
			//       numberOfItems: this.item.numberOfItems,
			//       subTotal: this.calculateSubtotal(),
			//     };
			//     const response = await AuthService.editInvoiceItem(id, details);
			//     // this.resetForm();
			//     this.msg = response.msg;

			//     console.log(response.msg);
			//   } catch (error) {
			//     this.msg = error.response.data.msg;
			//     //console.log(error);
			//   }
			// },

			resetForm() {
				// this.email = "";
				// this.productName = "";
				// // this.productDescription = "";
				// this.productUnitPriceGross = 0.0;
				// this.vatRate = 0.0;
				// this.productUnitPriceNet = 0.0;
				// this.numberOfItems = 1;
				// this.subTotal = 0;
				// this.msg = "";

				requestAnimationFrame(() => {
					this.$refs.observer.reset();
				});
			},
			async getInvoiceItemDetails() {
				try {
					const id = this.$route.params.id;
					const response = await AuthService.getInvoiceItemDetails(id);
					this.item = response;
					console.log(response);
				} catch (error) {
					console.log(error);
				}
			},
		},
		mounted() {
			// this.getCountries();
			// this.getClientsCurrentAccounts();
			//  this.getUserId();
			this.getInvoiceItemDetails();
		},
	};
</script>
