<template>
	<ValidationObserver ref="observer" v-slot="{ handleSubmit, reset }">
		<b-form
			@submit.prevent="handleSubmit(addBusinessDetails)"
			@reset.prevent="reset"
		>
			<div class="col-lg-4 offset-lg-4 text-left">
				<h2 class="title-bold text-left">
					{{ $t("businessSignup.addBusinessDetails") }}
				</h2>
				<hr />

				<h5 class="text-left text-bold">
					{{ $t("businessSignup.insertTheDetails") }}
				</h5>

				<hr />
				<div class="background-outline">
					<div v-if="businessDetails.subType !== 0">
						<div class="background-modifier text-left">
							<div v-if="businessDetails.subType === 0">
								<p>{{ $t("businessSignup.companyInIncorporation") }}</p>
							</div>

							<label class="small">{{ $t("businessSignup.vatNumber") }}</label>
							<ValidationProvider
								rules="required|alpha_dash|min:3|max:50"
								name="vatNumber"
								v-slot="{ valid, errors }"
							>
								<b-form-group label-for="vatNumberInput" description="">
									<b-form-input
										type="text"
										v-model="vatNumber"
										:state="errors[0] ? false : valid ? true : null"
										:placeholder="$t('businessSignup.vatNumber')"
									></b-form-input>
									<b-form-invalid-feedback id="inputLiveFeedback">{{
										errors[0]
									}}</b-form-invalid-feedback>
								</b-form-group>
							</ValidationProvider>
						</div>
						<br />
					</div>

					<div class="background-modifier text-left">
						<div>
							<div v-if="businessDetails.subType !== 0">
								<label class="small" for="datepicker-placeholder">{{
									$t("businessSignup.dateOfIncorporation")
								}}</label>
								<b-form-datepicker
									id="datepicker-placeholder"
									:placeholder="$t('businessSignup.doi')"
									v-model="doi"
									:min="min"
									:max="max"
									:locale="$i18n.locale"
									:label-no-date-selected="$t('businessSignup.noDateSelected')"
									:label-help="
										$t('businessSignup.useCursorKeysToNavigateCalendarDates')
									"
								></b-form-datepicker>
							</div>
							<div v-if="businessDetails.subType === 0">
								<label class="small" for="datepicker-placeholder">{{
									$t("businessSignup.estimatedIncorporationDate")
								}}</label>
								<b-form-datepicker
									id="datepicker-placeholder"
									:placeholder="$t('businessSignup.doi')"
									v-model="doi"
									:min="max"
									:max="max2"
									:locale="$i18n.locale"
									:label-no-date-selected="$t('businessSignup.noDateSelected')"
									:label-help="
										$t('businessSignup.useCursorKeysToNavigateCalendarDates')
									"
								></b-form-datepicker>
							</div>
							<label class="small" for="placeOfIncorporation">{{
								$t("businessSignup.placeOfIncorporation")
							}}</label>
							<ValidationProvider
								rules="required|min:3|max:50"
								name="placeOfIncorporation"
								v-slot="{ valid, errors }"
							>
								<b-form-group label-for="placeOfIncorporation" description="">
									<b-form-input
										type="text"
										v-model="placeOfIncorporation"
										:state="errors[0] ? false : valid ? true : null"
										:placeholder="$t('businessSignup.placeOfIncorporation')"
									></b-form-input>
									<b-form-invalid-feedback id="inputLiveFeedback">{{
										errors[0]
									}}</b-form-invalid-feedback>
								</b-form-group>
							</ValidationProvider>
						</div>
					</div>
					<br />

					<div class="background-modifier text-left">
						<label class="small" for="domicile">{{
							$t("businessSignup.domicile")
						}}</label>
						<ValidationProvider
							rules="required|min:2|max:50"
							name="domicile"
							v-slot="{ valid, errors }"
						>
							<b-form-group label-for="domicile" description="">
								<b-form-input
									type="text"
									v-model="domicile"
									:state="errors[0] ? false : valid ? true : null"
									:placeholder="$t('businessSignup.domicile')"
								></b-form-input>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>

						<!-- <ValidationProvider
            rules="required|alpha_spaces|min:2|max:50"
            name="lastname"
            v-slot="{ valid, errors }"
          >
            <b-form-group label-for="lastnameInput" description="">
              <b-form-input
                type="text"
                v-model="lastname"
                :state="errors[0] ? false : valid ? true : null"
                :placeholder="$t('businessSignup.lastname')"
              ></b-form-input>
              <b-form-invalid-feedback id="inputLiveFeedback">{{
                errors[0]
              }}</b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>  -->
					</div>
					<br />

					<!--    <div class="background-modifier">
          <ValidationProvider
            rules="required|min:6|max:30"
            name="password"
            vid="password"
            v-slot="{ valid, errors }"
          >
            <b-form-group description="">
              <b-form-input
                type="password"
                v-model="password"
                :state="errors[0] ? false : valid ? true : null"
                :placeholder="$t('businessSignup.password')"
              ></b-form-input>
              <b-form-invalid-feedback id="inputLiveFeedback">{{
                errors[0]
              }}</b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider
            rules="required|confirmed:password"
            name="Password confirmation"
            v-slot="{ valid, errors }"
          >
            <b-form-group label="" label-for="repeatPasswordInput1">
              <b-form-input
                type="password"
                v-model="password_repeat"
                :state="errors[0] ? false : valid ? true : null"
                :placeholder="$t('businessSignup.repeatPassword')"
              ></b-form-input>
              <b-form-invalid-feedback id="inputLiveFeedback">{{
                errors[0]
              }}</b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </div>
        <br /> -->

					<p class="text-left text-bold">
						{{ $t("businessSignup.contactDetails") }}
					</p>
					<p class="text-left">{{ $t("businessSignup.registeredAddress") }}</p>

					<div class="background-modifier">
						<label class="small">{{ $t("businessSignup.address") }}</label>
						<ValidationProvider
							rules="required|min:5|max:50"
							name="addressLine1"
							v-slot="{ valid, errors }"
						>
							<b-form-group label-for="addressLine1Input" description="">
								<b-form-input
									type="text"
									v-model="addressLine1"
									:state="errors[0] ? false : valid ? true : null"
									:placeholder="$t('businessSignup.address')"
								></b-form-input>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>
						<label class="small">{{ $t("businessSignup.city") }}</label>
						<ValidationProvider
							rules="required|min:5|max:50"
							name="city"
							v-slot="{ valid, errors }"
						>
							<b-form-group label-for="cityInput" description="">
								<b-form-input
									type="text"
									v-model="city"
									:state="errors[0] ? false : valid ? true : null"
									:placeholder="$t('businessSignup.city')"
								></b-form-input>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>
						<label class="small">{{ $t("businessSignup.postCode") }}</label>
						<ValidationProvider
							rules="required|min:3|max:10"
							name="zip"
							v-slot="{ valid, errors }"
						>
							<b-form-group label-for="zipInput" description="">
								<b-form-input
									type="text"
									v-model="zip"
									:state="errors[0] ? false : valid ? true : null"
									:placeholder="$t('businessSignup.postCode')"
								></b-form-input>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>
						<label class="small">{{ $t("businessSignup.country") }}</label>
						<ValidationProvider
							rules="required"
							name="country"
							v-slot="{ valid, errors }"
						>
							<b-form-group id="countryInputGroup" label-for="countryInput">
								<b-form-select
									@change="onChange"
									id="countryInput"
									:state="errors[0] ? false : valid ? true : null"
									v-model="country"
									><option
										v-for="country in countries"
										:value="country.countryCode"
										:key="country.countryCode"
										>{{ countryNames.getName(country.countryCode, codeLang) }}
									</option>
									<template #first>
										<b-form-select-option value="" disabled>{{
											$t("businessSignup.country")
										}}</b-form-select-option>
									</template></b-form-select
								>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>
					</div>

					<br />

					<p class="text-left">{{ $t("businessSignup.businessAddress") }}</p>

					<div class="background-modifier">
						<label class="small">{{ $t("businessSignup.address") }}</label>
						<ValidationProvider
							rules="required|min:5|max:50"
							name="contactAddressLine1"
							v-slot="{ valid, errors }"
						>
							<b-form-group label-for="contactAddressLine1Input" description="">
								<b-form-input
									type="text"
									v-model="contactAddressLine1"
									:state="errors[0] ? false : valid ? true : null"
									:placeholder="$t('businessSignup.address')"
								></b-form-input>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>
						<label class="small">{{ $t("businessSignup.city") }}</label>
						<ValidationProvider
							rules="required|min:5|max:50"
							name="contactCity"
							v-slot="{ valid, errors }"
						>
							<b-form-group label-for="cityInput" description="">
								<b-form-input
									type="text"
									v-model="contactCity"
									:state="errors[0] ? false : valid ? true : null"
									:placeholder="$t('businessSignup.city')"
								></b-form-input>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>
						<label class="small">{{ $t("businessSignup.postCode") }}</label>
						<ValidationProvider
							rules="required|min:3|max:10"
							name="contactZip"
							v-slot="{ valid, errors }"
						>
							<b-form-group label-for="contactZipInput" description="">
								<b-form-input
									type="text"
									v-model="contactZip"
									:state="errors[0] ? false : valid ? true : null"
									:placeholder="$t('businessSignup.postCode')"
								></b-form-input>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>
						<label class="small">{{ $t("businessSignup.country") }}</label>
						<ValidationProvider
							rules="required"
							name="contactCountry"
							v-slot="{ valid, errors }"
						>
							<b-form-group
								id="countryInputGroup"
								label-for="contactCountryInput"
							>
								<b-form-select
									@change="onChange"
									id="contactCountryInput"
									:state="errors[0] ? false : valid ? true : null"
									v-model="contactCountry"
									><option
										v-for="country in countries"
										:value="country.countryCode"
										:key="country.countryCode"
										>{{ countryNames.getName(country.countryCode, codeLang) }}
									</option>
									<template #first>
										<b-form-select-option value="" disabled>{{
											$t("businessSignup.country")
										}}</b-form-select-option>
									</template></b-form-select
								>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>
					</div>
					<br />
					<div class="background-modifier">
						<div class="row">
							<div class="col-md-6">
								<label class="small">{{ $t("businessSignup.country") }}</label>
								<ValidationProvider
									rules="required"
									name="prefix"
									v-slot="{ valid, errors }"
								>
									<b-form-group id="prefixInputGroup" label-for="prefixInput">
										<b-form-select
											@change="onChange"
											id="prefixInput"
											:state="errors[0] ? false : valid ? true : null"
											v-model="prefix"
											><option
												v-for="country in countries"
												:value="country.prefix"
												:key="country.prefix"
												>+ {{ country.prefix }}
												{{
													countryNames.getName(country.countryCode, codeLang)
												}}
											</option>
											<template #first>
												<b-form-select-option value="" disabled>{{
													$t("businessSignup.country")
												}}</b-form-select-option>
											</template></b-form-select
										>
										<b-form-invalid-feedback id="inputLiveFeedback">{{
											errors[0]
										}}</b-form-invalid-feedback>
									</b-form-group>
								</ValidationProvider>
							</div>
							<div class="col-md-6">
								<label class="small">{{ $t("businessSignup.phone") }}</label>
								<ValidationProvider
									rules="required|numeric|min:5|max:12"
									name="mobile"
									v-slot="{ valid, errors }"
								>
									<b-form-group label-for="mobileInput" description="">
										<b-form-input
											type="text"
											v-model="mobile"
											:state="errors[0] ? false : valid ? true : null"
											:placeholder="$t('businessSignup.phone')"
										></b-form-input>
										<b-form-invalid-feedback id="inputLiveFeedback">{{
											errors[0]
										}}</b-form-invalid-feedback>
									</b-form-group>
								</ValidationProvider>
							</div>
						</div>
						<label class="small">{{ $t("businessSignup.email") }}</label>
						<ValidationProvider
							rules="required|email"
							name="email"
							v-slot="{ valid, errors }"
						>
							<b-form-group label-for="emailInput" description="">
								<b-form-input
									type="email"
									v-model="email"
									:state="errors[0] ? false : valid ? true : null"
									:placeholder="$t('businessSignup.email')"
								></b-form-input>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>

						<label class="small">{{ $t("businessSignup.website") }}</label>
						<ValidationProvider
							rules="required|min:6|max:50"
							name="website"
							v-slot="{ valid, errors }"
						>
							<b-form-group label-for="websiteInput" description="">
								<b-form-input
									type="text"
									v-model="website"
									:state="errors[0] ? false : valid ? true : null"
									:placeholder="$t('businessSignup.website')"
								></b-form-input>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>
					</div>

					<!-- disabled-->
					<!-- <div v-if="status == 'accepted'"> -->

					<br />

					<p class="text-left text-bold">{{ $t("businessSignup.registry") }}</p>
					<br />

					<div class="background-modifier text-left">
						<label class="small">{{ $t("businessSignup.name") }}</label>
						<ValidationProvider
							rules="required|min:3|max:50"
							name="registry"
							v-slot="{ valid, errors }"
						>
							<b-form-group label-for="registryInput" description="">
								<b-form-input
									type="text"
									v-model="registry"
									:state="errors[0] ? false : valid ? true : null"
									:placeholder="$t('businessSignup.name')"
								></b-form-input>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>

						<label class="small">{{ $t("businessSignup.website") }}</label>
						<ValidationProvider
							rules="required|min:3|max:50"
							name="registryWebsite"
							v-slot="{ valid, errors }"
						>
							<b-form-group label-for="websiteInput" description="">
								<b-form-input
									type="text"
									v-model="registryWebsite"
									:state="errors[0] ? false : valid ? true : null"
									:placeholder="$t('businessSignup.website')"
								></b-form-input>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>

						<!--    <b-form-checkbox
            class="text-left"
            id="checkbox-2"
            v-model="status2"
            name="checkbox-2"
            value="accepted"
            unchecked-value="not_accepted"
          >
            Possibility to confirm online with the registry the particulars of
            the applicant company
          </b-form-checkbox>  -->
					</div>
					<br />
					<p class="text-left text-bold">{{ $t("businessSignup.auditors") }}</p>

					<br />

					<div class="background-modifier text-left">
						<label class="small">{{ $t("businessSignup.name") }}</label>
						<ValidationProvider
							rules="required|min:3|max:50"
							name="auditors"
							v-slot="{ valid, errors }"
						>
							<b-form-group label-for="auditorInput" description="">
								<b-form-input
									type="text"
									v-model="auditors"
									:state="errors[0] ? false : valid ? true : null"
									:placeholder="$t('businessSignup.name')"
								></b-form-input>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>
					</div>

					<br />

					<p class="text-left text-bold">
						{{ $t("businessSignup.businessOfApplicant") }}
					</p>

					<p class="text-left">
						{{ $t("businessSignup.activitiesBusinessSources") }}
					</p>

					<div class="background-modifier text-left">
						<label class="small">{{ $t("businessSignup.turnover") }}</label>
						<ValidationProvider
							rules="required|numeric"
							name="turnover"
							v-slot="{ valid, errors }"
						>
							<b-form-group label-for="turnoverInput" description="">
								<b-form-input
									type="text"
									v-model="turnover"
									:state="errors[0] ? false : valid ? true : null"
									:placeholder="$t('businessSignup.turnover')"
								></b-form-input>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>

						<label class="small">{{ $t("businessSignup.employees") }}</label>
						<ValidationProvider
							rules="required|numeric"
							name="employees"
							v-slot="{ valid, errors }"
						>
							<b-form-group label-for="employeeInput" description="">
								<b-form-input
									type="text"
									v-model="employees"
									:state="errors[0] ? false : valid ? true : null"
									:placeholder="$t('businessSignup.employees')"
								></b-form-input>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>

						<label class="small">{{ $t("businessSignup.totalAssets") }}</label>
						<ValidationProvider
							rules="required|numeric"
							name="totalAssets"
							v-slot="{ valid, errors }"
						>
							<b-form-group label-for="totalAssetInput" description="">
								<b-form-input
									type="text"
									v-model="totalAssets"
									:state="errors[0] ? false : valid ? true : null"
									:placeholder="$t('businessSignup.totalAssets')"
								></b-form-input>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>

						<label class="small">{{
							$t("businessSignup.countryActivity")
						}}</label>

						<ValidationProvider
							rules="required"
							name="countryActivity"
							v-slot="{ valid, errors }"
						>
							<b-form-group
								id="countryActivityInputGroup"
								label-for="countryActivityInput"
							>
								<b-form-select
									@change="onChange"
									id="countryActivityInput"
									:state="errors[0] ? false : valid ? true : null"
									v-model="countryActivity"
									><option
										v-for="country in countries"
										:value="country.countryCode"
										:key="country.countryCode"
										>{{ countryNames.getName(country.countryCode, codeLang) }}
									</option>
									<template #first>
										<b-form-select-option value="" disabled>{{
											$t("businessSignup.country")
										}}</b-form-select-option>
									</template></b-form-select
								>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>
					</div>
					<br />

					<!-- disabled-->
					<!-- <div v-if="status == 'accepted'"> -->
					<b-button
						type="submit"
						@click="isloading"
						class="btn btn-info text-light btn-block"
						>{{ $t("businessSignup.submit")
						}}<b-spinner
							v-if="isloading"
							class="ml-auto"
							:variant="light"
						></b-spinner
					></b-button>
					<!--   <p class="small">{{ $t("businessSignup.notAvailable") }}</p> -->
					<!-- </div> -->
					<hr />
					<b-button
						type="reset"
						class="btn btn-alert text-light btn-block"
						@click="resetForm()"
						>{{ $t("reset") }}</b-button
					>

					<div v-if="msg && $bvToast.show('notification-toast')"></div>
					<div class="action">
						<br />
						<b-toast
							id="notification-toast"
							variant="secondary"
							static
							no-auto-hide
						>
							<template #toast-title>
								<div class="d-flex flex-grow-1 align-items-baseline">
									<b-img
										class="logo mb-2"
										:src="require('/public/images/logo.png')"
										alt=""
										width="12"
										height="12"
									></b-img>
									<strong class="mr-auto">{{ $t("notification") }}</strong>
									<small class="text-muted mr-2"></small>
								</div>
							</template>
							{{ msg }}
						</b-toast>
					</div>
				</div>
			</div>
		</b-form>
	</ValidationObserver>
</template>

<script>
	import { ValidationObserver, ValidationProvider } from "vee-validate";
	import AuthService from "@/services/AuthService.js";
	var countryNames = require("i18n-iso-countries");
	export default {
		name: "AddBusinessDetails",
		components: {
			ValidationObserver,
			ValidationProvider,
		},
		/*props: {
        codeLang: {
          type: String,
          default: "en",
        },
      },*/
		//this.codeLang
		created() {
			if (!this.$store.getters.isLoggedIn) {
				this.$router.push("login");
			}
			this.getBusinessDetails();

			if (
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 3 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 15 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 14
			) {
				this.$router.push("overview");
			}
			let lang = this.$i18n.locale;
			this.role = this.$store.getters.getUser.role;
			this.username = this.$store.getters.getUser.username;
			this.firstname = this.$store.getters.getUser.firstname;
			this.codeLang = this.$i18n.locale;
			this.countryNames.registerLocale(
				require(`i18n-iso-countries/langs/${this.codeLang}.json`)
			);
		},
		watch: {
			codeLang(newVal, oldVal) {
				this.countryNames.registerLocale(
					require(`i18n-iso-countries/langs/${newVal}.json`)
				);
			},
		},
		data() {
			const now = new Date();
			const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
			// 15th two months prior
			const minDate = new Date(today);
			minDate.setYear(minDate.getFullYear() - 300);
			const maxDate = new Date(today);
			maxDate.setYear(maxDate.getFullYear() - 0);
			const maxDate2 = new Date(today);
			maxDate2.setYear(maxDate2.getMonth() + 3);
			// minDate.setMonth(minDate.getMonth() - 2);
			// minDate.setDate(15);
			// 15th in two months

			return {
				businessDetails: "",
				countries: [],
				countryNames: countryNames,
				/*username: "",*/
				vatNumber: "",
				email: "",
				/*  firstname: "",
      lastname: "", 
      password: "",
      password_repeat: "", */
				msg: "",
				/* pin: "", */
				doi: maxDate.toISOString().slice(0, 10),
				placeOfIncorporation: "",
				domicile: "",
				addressLine1: "",
				city: "",
				zip: "",
				country: "",
				contactAddressLine1: "",
				contactCity: "",
				contactZip: "",
				contactCountry: "",
				prefix: "",
				mobile: "",
				website: "",
				registry: "",
				registryWebsite: "",
				auditors: "",
				turnover: "",
				employees: "",
				totalAssets: "",
				countryActivity: "",
				value: "",
				min: minDate,
				max: maxDate,
				max2: maxDate2,
				status: "not_accepted",
				isloading: false,
			};
		},

		methods: {
			async getBusinessDetails() {
				try {
					const id = this.$store.getters.getUser.id;
					const response = await AuthService.getBusinessDetails(id);
					this.businessDetails = response;
					console.log(response);
				} catch (error) {
					console.log(error);
				}
			},
			async addBusinessDetails() {
				try {
					const credentials = {
						lang: this.$i18n.locale,
						id: this.$route.params.id,
						/*username: this.username,*/
						vatNumber: this.vatNumber,
						email: this.email,
						/*  firstname: this.firstname,
          lastname: this.lastname, 
          password: this.password,
          password_repeat: this.password_repeat, 
          pin: this.pin, */
						doi: this.doi,
						placeOfIncorporation: this.placeOfIncorporation,
						domicile: this.domicile,
						addressLine1: this.addressLine1,
						city: this.city,
						zip: this.zip,
						country: this.country,
						contactAddressLine1: this.contactAddressLine1,
						contactCity: this.contactCity,
						contactZip: this.contactZip,
						contactCountry: this.contactCountry,
						phoneNumber: "+" + this.prefix + this.mobile,
						website: this.website,
						registry: this.registry,
						registryWebsite: this.registryWebsite,
						auditors: this.auditors,
						turnover: this.turnover,
						employees: this.employees,
						totalAssets: this.totalAssets,
						countryActivity: this.countryActivity,
					};
					// let reference = this.$route.params.reference;
					this.isloading = true;
					const response = await AuthService.addBusinessDetails(
						/*reference, */ credentials
					);
					this.resetForm();
					this.msg = response.msg;
					this.isloading = false;
				} catch (error) {
					this.msg = error.response.data.msg;
					this.isloading = false;
					/*this.$router.push('home');*/
				}
			},
			resetForm() {
				/* this.username = "";*/
				this.vatNumber = "";
				/*this.firstname = "";
      this.lastname = ""; */
				this.email = "";
				/* this.password = "";
      this.password_repeat = ""; */
				this.msg = "";
				/* this.pin = ""; */
				this.doi = "";
				this.placeOfIncorporation = "";
				this.domicile = "";
				this.addressLine1 = "";
				this.city = "";
				this.zip = "";
				this.country = "";
				this.contactAddressLine1 = "";
				this.contactCity = "";
				this.contactZip = "";
				this.contactCountry = "";
				this.prefix = "";
				this.mobile = "";
				this.website = "";
				this.registry = "";
				this.registryWebsite = "";
				this.auditors = "";
				this.turnover = "";
				this.employees = "";
				this.totalAssets = "";
				this.countryActivity = "";
				requestAnimationFrame(() => {
					this.$refs.observer.reset();
				});
			},
			async getCountries() {
				try {
					const response = await AuthService.getCountries();
					this.countries = response;
					console.log(response);
				} catch (error) {
					console.log(error);
				}
			},
		},
		mounted() {
			this.getCountries();
			this.getBusinessDetails();
		},
	};
</script>
