<template>
  <div class="col-12 offset-0">
    <div class="text-left">
      <div class="col-md-8 offset-md-2">
        <h2 class="title-bold">
          {{ $t("businessClientDetails.clientOverview") }}
        </h2>
        <hr />

        <div class="background-modifier">
          <div class="product-details">
          <div class="row">
            <div class="col-md-10">
              <h4 class="title-bold">{{ businessDetails.name }}</h4>
            </div>
            <div class="col-md-2 text-right">
              <b-avatar variant="secondary" size="3rem">
                {{ businessDetails.name.charAt() }}
              </b-avatar>
            </div>
          </div>
          <hr>
          <!-- <p>{{ $t("hello") }} {{ firstname }}</p>
            <p>{{ $t("businessClientDetails.yourApplicationIsFor") }}:</p> -->
          <p class="text-left text-bold">
            {{ $t("businessClientDetails.general") }}
          </p>
          <br />
          <label class="small">{{ $t("businessClientDetails.fullname") }}</label>
          <p>
            {{ businessDetails.name }}
          </p>
          <label class="small"> {{ $t("businessClientDetails.businessId") }}</label>
          <p>
            {{ businessDetails.idCode }}
          </p>

          <!-- <div v-if="allBusinessDetails != 0"> -->
          <label class="small">{{ $t("businessClientDetails.vatNumber") }}</label>
          <p>
            {{ allBusinessDetails.vatNumber }}
          </p>
          <label class="small">{{ $t("businessClientDetails.dateOfIncorporation") }}</label>
          <p>
            {{ $d(Date.parse(allBusinessDetails.doi), "short") }}
          </p>
          <label class="small">{{ $t("businessClientDetails.placeOfIncorporation") }}</label>
          <p>
            {{ allBusinessDetails.placeOfIncorporation }}
          </p>
          <label class="small">{{ $t("businessClientDetails.domicile") }}</label>
          <p>
            {{ allBusinessDetails.domicile }}
          </p>
          <hr />
          <p class="text-left text-bold">
            {{ $t("businessClientDetails.contactDetails") }}
          </p>
       
          <label class="small"></label>
          <p class="text-left">
            {{ $t("businessClientDetails.registeredAddress") }}
          </p>
          <label class="small">{{ $t("businessClientDetails.address") }}</label>
          <p>
            {{ allBusinessDetails.addressLine1 }}
          </p>
          <label class="small">{{ $t("businessClientDetails.city") }}</label>
          <p>
            {{ allBusinessDetails.city }}
          </p>
          <label class="small">{{ $t("businessClientDetails.postCode") }}</label>
          <p>
            {{ allBusinessDetails.zip }}
          </p>
          <label class="small">{{ $t("businessClientDetails.country") }}</label>
          <p>
            {{ allBusinessDetails.country }}
          </p>
          <p class="text-left">
            {{ $t("businessClientDetails.businessAddress") }}
          </p>
          <label class="small"> {{ $t("businessClientDetails.address") }}</label>
          <p>
            {{ allBusinessDetails.contactAddressLine1 }}
          </p>
          <label class="small">{{ $t("businessClientDetails.city") }}</label>
          <p>
            {{ allBusinessDetails.contactCity }}
          </p>
          <label class="small">{{ $t("businessClientDetails.postCode") }}</label>
          <p>
            {{ allBusinessDetails.contactZip }}
          </p>
          <label class="small">{{ $t("businessClientDetails.country") }}</label>
          <p>
            {{ allBusinessDetails.contactCountry }}
          </p>
          <label class="small">{{ $t("businessClientDetails.phone") }}</label>
          <p>
            {{ allBusinessDetails.phoneNumber }}
          </p>
          <label class="small">{{ $t("businessClientDetails.email") }}</label>
          <p>
            {{ allBusinessDetails.email }}
          </p>
          <label class="small">{{ $t("businessClientDetails.website") }}</label>
          <p>
            {{ allBusinessDetails.website }}
          </p>
          <hr />
          <p class="text-left text-bold">
            {{ $t("businessClientDetails.registry") }}
          </p>
          <br />
          <label class="small">{{ $t("businessClientDetails.name") }}</label>
          <p>
            {{ allBusinessDetails.registry }}
          </p>
          <label class="small">{{ $t("businessClientDetails.website") }}</label>
          <p>
            {{ allBusinessDetails.registryWebsite }}
          </p>
          <hr />
          <p class="text-left text-bold">
            {{ $t("businessClientDetails.auditors") }}
          </p>
          <br />
          <label class="small">{{ $t("businessClientDetails.name") }}</label>
          <p>
            {{ allBusinessDetails.auditors }}
          </p>
          <hr />
          <p class="text-left text-bold">
            {{ $t("businessClientDetails.activitiesBusinessSources") }}
          </p>
          <br />
          <label class="small">{{ $t("businessClientDetails.turnover") }}</label>
          <p>
            {{
              allBusinessDetails.turnover != null
                ? $n(allBusinessDetails.turnover, "decimal")
                : $n(0.0, "decimal")
            }}
          </p>
          <label class="small">{{ $t("businessClientDetails.employees") }}</label>
          <p>
            {{ allBusinessDetails.employees }}
          </p>
          <label class="small">{{ $t("businessClientDetails.totalAssets") }}</label>
          <p>
            {{
              allBusinessDetails.totalAssets != null
                ? $n(allBusinessDetails.totalAssets, "decimal")
                : $n(0.0, "decimal")
            }}
          </p>
          <label class="small">{{ $t("businessClientDetails.countryActivity") }}</label>
          <p>
            {{ allBusinessDetails.countryActivity }}
          </p>
          <hr />
          <div class="row">
            <!-- <div class="col-md-6 text-left">
                <router-link
                  :to="{
                    name: 'add-business-details',
                    params: { id: businessDetails.clientId },
                  }"
                  class="btn btn-secondary mx-1 "
                  >{{ $t("businessClientDetails.addBusinessDetails") }}</router-link
                >
                <p class="text-left">
                  {{ $t("businessClientDetails.addRequiredBusinessDetails") }}
                </p>
              </div> -->
            <div class="col-md-12 text-right">
              <router-link
                :to="{
                  name: 'admin-edit-business-details',
                  params: { id: allBusinessDetails.clientId },
                }"
                class="btn btn-outline mx-1 "
                >{{ $t("businessClientDetails.edit") }}</router-link
              >
            </div>
          </div>
          <hr />
        </div>
        </div>
        <!-- </div> -->
        <hr />

        <div class="background-modifier">
          <h5 class="text-left text-bold">
            {{ $t("businessClientDetails.identityOfKeyPersons") }}
          </h5>
          <hr />
          <p class="text-left">
            {{ $t("businessClientDetails.addInfoAboutKeyPersons") }}
          </p>

          <div v-if="businessRoles.length > 0"></div>
          <div v-else>
            <p class="text-bold">
              {{ $t("businessClientDetails.noKeyPersons") }}
            </p>
          </div>
          <br>

          <p class="text-left text-bold">
            {{ $t("businessClientDetails.shareholders") }}
          </p>

          <p class="text-left ">
            {{ $t("businessClientDetails.shareholderInfo1") }}
          </p>

          <p class="text-left">
            {{ $t("businessClientDetails.shareholderInfo2") }}
          </p>

          <b-row>
            <div
              v-for="businessRole in businessRoles"
              :key="businessRole.id"
              class="col-6 card-grid"
            >
              <router-link
                :to="{
                  name: 'admin-business-edit-role',
                  params: {
                    id: businessRole.id,
                    reference: businessRole.role,
                  },
                }"
                class=""
                tag="text-grey"
              >
                <div v-if="businessRole.role == '1'">
                  <div class="background-modifier ">
                    <h4 class="">
                      {{ businessRole.firstname }} {{ businessRole.lastname }}
                    </h4>
                    <div>
                      <div v-if="businessRole.role == '1'">
                        <p class="">{{ $t("businessClientDetails.ubo") }}</p>
                      </div>
                      <div v-if="businessRole.role == '2'">
                        <p class="">
                          {{ $t("businessClientDetails.shareholder") }}
                        </p>
                      </div>
                      <div v-if="businessRole.role == '3'">
                        <p class="">
                          {{ $t("businessClientDetails.director") }}
                        </p>
                      </div>
                      <div v-if="businessRole.role == '4'">
                        <p class="">
                          {{ $t("businessClientDetails.decisionMaker") }}
                        </p>
                      </div>
                      <div v-if="businessRole.role == '5'">
                        <p class="">
                          {{ $t("businessClientDetails.signatory") }}
                        </p>
                      </div>
                    </div>
                    <div
                      v-if="
                        businessRole.role == '1' || businessRole.role == '2'
                      "
                    >
                      <p class="">
                        {{
                          businessRole.ownership != null
                            ? $n(businessRole.ownership, "decimal")
                            : $n(0.0, "decimal")
                        }}
                        {{ $t("businessClientDetails.ownership") }} /
                        {{
                          businessRole.votes != null
                            ? $n(businessRole.votes, "decimal")
                            : $n(0.0, "decimal")
                        }}
                        {{ $t("businessClientDetails.votes") }}
                      </p>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <p class="text-bold">
                    {{ $t("businessClientDetails.noUBOs") }}
                  </p>
                </div>
              </router-link>
            </div>
          </b-row>

          <br />

          <div class="text-left">
            <router-link
              :to="{
                name: 'admin-business-add-role',
                params: { id: allBusinessDetails.clientId, reference: 1 },
              }"
              class="btn btn-secondary mx-1 "
              >{{ $t("businessClientDetails.addUBOs") }}</router-link
            >
          </div>
          <br />
          <b-row>
            <div
              v-for="businessRole in businessRoles"
              :key="businessRole.id"
              class="col-6 card-grid"
            >
              <router-link
                :to="{
                  name: 'admin-business-edit-role',
                  params: {
                    id: businessRole.id,
                    reference: businessRole.role,
                  },
                }"
                class=""
                tag="text-grey"
              >
                <div v-if="businessRole.role == '2'">
                  <div class="background-modifier ">
                    <h4 class="">
                      {{ businessRole.firstname }} {{ businessRole.lastname }}
                    </h4>
                    <div>
                      <div v-if="businessRole.role == '1'">
                        <p class="">{{ $t("businessClientDetails.ubo") }}</p>
                      </div>
                      <div v-if="businessRole.role == '2'">
                        <p class="">
                          {{ $t("businessClientDetails.shareholder") }}
                        </p>
                      </div>
                      <div v-if="businessRole.role == '3'">
                        <p class="">
                          {{ $t("businessClientDetails.director") }}
                        </p>
                      </div>
                      <div v-if="businessRole.role == '4'">
                        <p class="">
                          {{ $t("businessClientDetails.decisionMaker") }}
                        </p>
                      </div>
                      <div v-if="businessRole.role == '5'">
                        <p class="">
                          {{ $t("businessClientDetails.signatory") }}
                        </p>
                      </div>
                    </div>
                    <div
                      v-if="
                        businessRole.role == '1' || businessRole.role == '2'
                      "
                    >
                      <p class="">
                        {{
                          businessRole.ownership != null
                            ? $n(businessRole.ownership, "decimal")
                            : $n(0.0, "decimal")
                        }}
                        {{ $t("businessClientDetails.ownership") }} /
                        {{
                          businessRole.votes != null
                            ? $n(businessRole.votes, "decimal")
                            : $n(0.0, "decimal")
                        }}
                        {{ $t("businessClientDetails.votes") }}
                      </p>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <p class="text-bold">
                    {{ $t("businessClientDetails.noShareholders") }}
                  </p>
                </div>
              </router-link>
            </div>
          </b-row>
          <br />
          <div class="text-left">
            <router-link
              :to="{
                name: 'admin-business-add-role',
                params: { id: allBusinessDetails.clientId, reference: 2 },
              }"
              class="btn btn-secondary mx-1 "
              >{{ $t("businessClientDetails.addShareholders") }}</router-link
            >
            <hr />
            <p class="text-left text-bold">
              {{ $t("businessClientDetails.directors") }}
            </p>

            <p class="text-left">
              {{ $t("businessClientDetails.directorInfo") }}
            </p>

            <b-row>
              <div
                v-for="businessRole in businessRoles"
                :key="businessRole.id"
                class="col-6 card-grid"
              >
                <router-link
                  :to="{
                    name: 'admin-business-edit-role',
                    params: {
                      id: businessRole.id,
                      reference: businessRole.role,
                    },
                  }"
                  class=""
                  tag="text-grey"
                >
                  <div v-if="businessRole.role == '3'">
                    <div class="background-modifier ">
                      <h4 class="">
                        {{ businessRole.firstname }}
                        {{ businessRole.lastname }}
                      </h4>
                      <div>
                        <div v-if="businessRole.role == '1'">
                          <p class="">
                            {{ $t("businessClientDetails.ubo") }}
                          </p>
                        </div>
                        <div v-if="businessRole.role == '2'">
                          <p class="">
                            {{ $t("businessClientDetails.shareholder") }}
                          </p>
                        </div>
                        <div v-if="businessRole.role == '3'">
                          <p class="">
                            {{ $t("businessClientDetails.director") }}
                          </p>
                        </div>
                        <div v-if="businessRole.role == '4'">
                          <p class="">
                            {{ $t("businessClientDetails.decisionMaker") }}
                          </p>
                        </div>
                        <div v-if="businessRole.role == '5'">
                          <p class="">
                            {{ $t("businessClientDetails.signatory") }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <p class="text-bold">
                      {{ $t("businessClientDetails.noDirectors") }}
                    </p>
                  </div>
                </router-link>
              </div>
            </b-row>

            <br />

            <div class="text-left">
              <router-link
                :to="{
                  name: 'admin-business-add-role',
                  params: { id: allBusinessDetails.clientId, reference: 3 },
                }"
                class="btn btn-secondary mx-1 "
                >{{ $t("businessClientDetails.addDirectors") }}</router-link
              >
            </div>

            <hr />

            <p class="text-left text-bold">
              {{ $t("businessClientDetails.decisionMakers") }}
            </p>

            <p class="text-left">
              {{ $t("businessClientDetails.decisionMakerInfo") }}
            </p>

            <b-row>
              <div
                v-for="businessRole in businessRoles"
                :key="businessRole.id"
                class="col-6 card-grid"
              >
                <router-link
                  :to="{
                    name: 'admin-business-edit-role',
                    params: {
                      id: businessRole.id,
                      reference: businessRole.role,
                    },
                  }"
                  class=""
                  tag="text-grey"
                >
                  <div v-if="businessRole.role == '4'">
                    <div class="background-modifier ">
                      <h4 class="">
                        {{ businessRole.firstname }}
                        {{ businessRole.lastname }}
                      </h4>
                      <div>
                        <div v-if="businessRole.role == '1'">
                          <p class="">
                            {{ $t("businessClientDetails.ubo") }}
                          </p>
                        </div>
                        <div v-if="businessRole.role == '2'">
                          <p class="">
                            {{ $t("businessClientDetails.shareholder") }}
                          </p>
                        </div>
                        <div v-if="businessRole.role == '3'">
                          <p class="">
                            {{ $t("businessClientDetails.director") }}
                          </p>
                        </div>
                        <div v-if="businessRole.role == '4'">
                          <p class="">
                            {{ $t("businessClientDetails.decisionMaker") }}
                          </p>
                        </div>
                        <div v-if="businessRole.role == '5'">
                          <p class="">
                            {{ $t("businessClientDetails.signatory") }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <p class="text-bold">
                      {{ $t("businessClientDetails.noDecisionMakers") }}
                    </p>
                  </div>
                </router-link>
              </div>
            </b-row>

            <br />

            <div class="text-left">
              <router-link
                :to="{
                  name: 'admin-business-add-role',
                  params: { id: allBusinessDetails.clientId, reference: 4 },
                }"
                class="btn btn-secondary mx-1 "
              >
                {{ $t("businessClientDetails.addDecisionMakers") }}</router-link
              >
            </div>

            <hr />

            <p class="text-left text-bold">
              {{ $t("businessClientDetails.signatories") }}
            </p>

            <p class="text-left">
              {{ $t("businessClientDetails.signatoryInfo") }}
            </p>

            <b-row>
              <div
                v-for="businessRole in businessRoles"
                :key="businessRole.id"
                class="col-6 card-grid"
              >
                <router-link
                  :to="{
                    name: 'admin-business-edit-role',
                    params: {
                      id: businessRole.id,
                      reference: businessRole.role,
                    },
                  }"
                  class=""
                  tag="text-grey"
                >
                  <div v-if="businessRole.role == '5'">
                    <div class="background-modifier ">
                      <h4 class="">
                        {{ businessRole.firstname }}
                        {{ businessRole.lastname }}
                      </h4>
                      <div>
                        <div v-if="businessRole.role == '1'">
                          <p class="">
                            {{ $t("businessClientDetails.ubo") }}
                          </p>
                        </div>
                        <div v-if="businessRole.role == '2'">
                          <p class="">
                            {{ $t("businessClientDetails.shareholder") }}
                          </p>
                        </div>
                        <div v-if="businessRole.role == '3'">
                          <p class="">
                            {{ $t("businessClientDetails.director") }}
                          </p>
                        </div>
                        <div v-if="businessRole.role == '4'">
                          <p class="">
                            {{ $t("businessClientDetails.decisionMaker") }}
                          </p>
                        </div>
                        <div v-if="businessRole.role == '5'">
                          <p class="">
                            {{ $t("businessClientDetails.signatory") }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <p class="text-bold">
                      {{ $t("businessClientDetails.noSignatories") }}
                    </p>
                  </div>
                </router-link>
              </div>
            </b-row>

            <br />

            <div class="text-left">
              <router-link
                :to="{
                  name: 'admin-business-add-role',
                  params: { id: allBusinessDetails.clientId, reference: 5 },
                }"
                class="btn btn-secondary mx-1 "
                >{{ $t("businessClientDetails.addSignatories") }}</router-link
              >
            </div>
            <hr />
          </div>
        </div>

        <hr />

        <div class="background-modifier">
          <h5 class="title-bold">
            {{ $t("businessClientDetails.documentUpload") }}
          </h5>
          <hr />

          <!-- <div v-if="loaded && businessDocs.length">
              <h5 class="title-bold">{{ $t("fileUpload.listOfFiles") }}</h5>
              <table class="table mt-2 ">
                <thead>
                  <tr>
                    <th class="text-left">
                      {{ $t("documentUpload.filename") }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="businessDoc in businessDocs" :key="businessDoc.id">
                    <td class="text-left">
                      <span>
                        <a
                          :href="
                            'https://www.n3consortium.com/api/files/' +
                              businessDoc.filename
                          "
                          >{{ businessDoc.filename }}</a
                        ></span
                      >
                    </td>

                    <td class="text-center"></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-else>{{ $t("fileUpload.noDocuments") }}</div> -->

          <p class="">
            {{ $t("businessClientDetails.uploadInfo") }}
          </p>
          <router-link
            :to="{
              name: 'admin-business-document-upload',
              params: { id: allBusinessDetails.clientId },
            }"
            class="btn btn-secondary mx-1 "
            >{{ $t("businessClientDetails.uploadDocuments") }}</router-link
          >
          <hr />
        </div>
    
      <hr />
      <div class="background-modifier">
        <div class="col-md-12 offset-md-0 ">
          <div class="row">
            <div class="col-md-6 text-left">
              <span>
                <h3 class="text-bold">
                  {{
                    totalBalance() != null
                      ? $n(totalBalance(), "decimal")
                      : $n(0.0, "decimal")
                  }}
                </h3>
                <p class="small">
                  {{ $t("accounts.totalBalance") }} ({{ this.baseCurrency }})
                </p>
              </span>
            </div>

            <div class="col-md-6 text-right">
              <h3 class="text-bold">
                {{ $t("accounts.accounts") }} ({{ accounts.length }})
              </h3>
            </div>
          </div>

          <!--   <p class="open-account text-right">
            <router-link
              class="btn btn-outline"
              :to="{ name: 'open-account' }"
              >{{ $t("accounts.openAccount") }}</router-link
            >
          </p>
          <br /> -->

             <!-- <div class="input-group mb-3">
            <input
              type="text"
              class="form-control"
              :placeholder="$t('accounts.nickname')"
              v-model="searchTitle"
            />
            <div class="input-group-append">
              <button
                class="btn btn-outline-secondary"
                type="button"
                @click="
                  page = 1;
                  getClientAccountList();
                "
              >
                {{ $t("search") }}
              </button>
            </div>
          </div>  -->
          <div v-if="isloading" class="text-center">
						<b-spinner
						style="width: 3rem; height: 3rem;"
						class="ml-auto text-center"
						:variant="secondary"
					></b-spinner
				></div>
					<div v-else-if="accounts.length > 0">
            <b-row>
              <div
                v-for="account in accounts"
                :key="account.id"
                class="col-6 card-grid"
              >
                <router-link
                  :to="{ name: 'account-detail', params: { id: account.id } }"
                  class=""
                  tag="text-grey"
                >
                  <div class="background-modifier ">
                    <h4 class="">
                      {{
                        account.iban.toString().replace(/\w{4}(?=.)/g, "$& ")
                      }}
                    </h4>
                    <p class="text-left">{{ account.nickname }}</p>
                    <div class="">
                      <div class="">
                        <h3 class="text-bold">
                          {{ account.currency }}
                          {{
                            account.balance != null
                              ? $n(account.balance, "decimal")
                              : $n(0.0, "decimal")
                          }}
                        </h3>
                      </div>
                      <div v-if="account.currency != baseCurrency" class="">
                        <small
                          >({{ baseCurrency }}
                          {{
                            account.baseBalance != null
                              ? $n(account.baseBalance, "decimal")
                              : $n(0.0, "decimal")
                          }})</small
                        >
                      </div>
                    </div>
                  </div>
                </router-link>
              </div>
            </b-row>
          </div>
          <div v-else>{{ $t("accounts.noAccounts") }}</div> 

         <!--   <div class="background-modifier">
            

            <span>
              <h3 class="title-bold">
                {{
                  totalBalance() != null
                    ? $n(totalBalance(), "decimal")
                    : $n(0.0, "decimal")
                }}
              </h3>
              <p class="small">
                {{ $t("accounts.totalBalance") }} ({{ this.baseCurrency }})
              </p>
            </span>

            <div v-if="accounts.length > 0">
              <div class="table-wrap">
                <table class="table mt-2" id="accounts">
                  <thead>
                    <tr>
                      <th class="text-left">IBAN</th>
                    
                      <th class="text-left">{{ $t("accounts.nickname") }}</th>
                     
                      <th class="text-right">{{ $t("accounts.currency") }}</th>
                      <th class="text-right">{{ $t("accounts.balance") }}</th>
                      <th class="text-right small">
                        {{ $t("accounts.balance") }} ({{ this.baseCurrency }})
                      </th>
                      <th class="text-centered">
                        {{ $t("accounts.actions") }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="account in accounts" :key="account.id">
                    
                      <td class="text-left">
                        {{
                          account.iban.toString().replace(/\w{4}(?=.)/g, "$& ")
                        }}
                      </td>
                
                      <td class="text-left">{{ account.nickname }}</td>
                    
                      <td class="text-left">{{ account.currency }}</td>
                      <td class="text-right">
                        {{
                          account.balance != null
                            ? $n(account.balance, "decimal")
                            : $n(0.0, "decimal")
                        }}
                      </td>
                      <td class="text-right small">
                        {{
                          account.baseBalance != null
                            ? $n(account.baseBalance, "decimal")
                            : $n(0.0, "decimal")
                        }}
                      </td>
                      <td class="text-right">
                        <router-link
                          :to="{ name: 'account-detail', params: { id: account.id } }"
                          class="btn btn-outline mx-1 "
                          >{{ $t("accounts.view") }}</router-link
                        >
                       
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div v-if="accounts.length >= 1">
							<div class="row">
								<div class="col-md-6">
									{{ $t("itemsPerPage") }}
									<select 
										class="custom-select" 
										style="width: 60px;"
										v-model="pageSize"
										@change="handlePageSizeChange($event)"
									>
										<option v-for="size in pageSizes" :key="size" :value="size">
											{{ size }}
										</option>
									</select>
								</div>
								<div class="col-md-6">
									<b-pagination
										v-model="page"
										:total-rows="count"
										:per-page="pageSize"
										:prev-text="$t('previous')"
										:next-text="$t('next')"
										align="right"
										@change="handlePageChange"
									></b-pagination>
								</div>
							</div>
						</div>
            </div>
            <div v-else>{{ $t("accounts.noAccounts") }}</div> -->
          </div>
        </div>

          <hr />
          <div class="background-modifier">
            <h5 class="title-bold text-left">
              {{ $t("businessClientDetails.verificationStatus") }}
            </h5>
            <hr />
            <b-form-group label="" v-slot="{ vStatus }">
              <b-form-radio
                v-model="client.verificationStatus"
                :aria-describedby="vStatus"
                name="radio_group_1"
                value="1"
                >{{ $t("businessClientDetails.pending") }}</b-form-radio
              >
              <br />
              <b-form-radio
                v-model="client.verificationStatus"
                :aria-describedby="vStatus"
                name="radio_group_1"
                value="2"
                >{{
                  $t("businessClientDetails.moreInformationRequired")
                }}</b-form-radio
              >
              <br />
              <b-form-radio
                v-model="client.verificationStatus"
                :aria-describedby="vStatus"
                name="radio_group_1"
                value="3"
                >{{ $t("businessClientDetails.verified") }}</b-form-radio
              >
            </b-form-group>

            <div>
              <br />
              <b-form>
                <b-button
                  @click="manageApplicationStatus()"
                  class="btn btn-info text-light btn-block"
                  >{{ $t("businessClientDetails.submit") }}</b-button
                >
              </b-form>
            </div>
          </div>
          <hr />
          <div v-if="msg && $bvToast.show('notification-toast')"></div>
          <div class="action">
            <b-toast
              id="notification-toast"
              variant="secondary"
              static
              no-auto-hide
            >
              <template #toast-title>
                <div class="d-flex flex-grow-1 align-items-baseline">
                  <b-img
                    class="logo mb-2"
                    :src="require('/public/images/logo.png')"
                    alt=""
                    width="12"
                    height="12"
                  ></b-img>
                  <strong class="mr-auto">{{ $t("notification") }}</strong>
                  <small class="text-muted mr-2"></small>
                </div>
              </template>
              {{ msg }}
            </b-toast>
          </div>
          <br />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AuthService from "@/services/AuthService.js";

export default {
  data() {
    return {
      loaded: false,
      account: "",
      userMessage: "",
      firstname: "",
      lastname: "",
      username: "",
      msg: "",
      client: "",
      businessDetails: "",
      allBusinessDetails: "",
      businessRoles: [],
      businessRole: "",
      businessDocs: [],
      accounts: [],
      listedAccounts: [],
      currentAccount: null,
      currentIndex: -1,
      searchTitle: "",
      isloading: false,
      page: 1,
      count: 0,
      pageSize: 10,

      pageSizes: [10, 25, 50],
    };
  },
  async created() {
    if (!this.$store.getters.isLoggedIn) {
      this.$router.push("login");
    }
    let lang = this.$i18n.locale;
    this.firstname = this.$store.getters.getUser.firstname;
    this.lastname = this.$store.getters.getUser.lastname;
    this.username = this.$store.getters.getUser.username;
    this.userMessage = await AuthService.getUserContent(lang);
    this.baseCurrency = this.$store.getters.getUser.currency;
    // this.getAdminBusinessDetails();
  },
  methods: {
    getRequestParams(searchTitle, page, pageSize) {
      let params = {};

      if (searchTitle) {
        params["nickname"] = searchTitle;
      }

      if (page) {
        params["page"] = page - 1;
      }

      if (pageSize) {
        params["size"] = pageSize;
      }

      return params;
    },

    

    async getClientAccounts() {
      try {
        const id = this.$route.params.id;
        this.isloading = true;
        const response = await AuthService.getClientAccounts(id);
        this.accounts = response;
        this.isloading = false;
        console.log(response);
      } catch (error) {
        this.isloading = false;
        console.log(error);
      }
    },

    async getClientAccountList() {
      const params = this.getRequestParams(
        this.searchTitle,
        this.page,
        this.pageSize
      );

      try {
        const details = {
          id: this.$route.params.id,
        };
        const response = await AuthService.getClientAccountList(
          params.page,
          params.size,
          params.nickname,
          details.id,
        );
        const { items, totalItems } = response.data;
        this.listedAccounts = items;
        this.count = totalItems;
        console.log(response.data);
      } catch (error) {
        console.log(error);
      }
    },

    totalBalance() {
      return this.accounts.reduce(
        (acc, item) => acc + item.baseBalance,
        0
      );
    },

    handlePageChange(value) {
      this.page = value;
      this.getClientAccountList();
    },

    handlePageSizeChange(event) {
      this.pageSize = event.target.value;
      this.page = 1;
      this.getClientAccountList();
    },

    async getAdminBusinessDetails() {
      try {
        const id = this.$route.params.id;
        const response = await AuthService.getAdminBusinessDetails(id);
        this.businessDetails = response;
        console.log(response);
      } catch (error) {
        console.log(error);
      }
    },

    async getAdminAllBusinessDetails() {
      try {
        const id = this.$route.params.id;
        const response = await AuthService.getAdminAllBusinessDetails(id);
        this.allBusinessDetails = response;
        console.log(response);
      } catch (error) {
        console.log(error);
      }
    },

    async getAdminBusinessRoles() {
      try {
        const id = this.$route.params.id;
        const response = await AuthService.getAdminBusinessRoles(id);
        this.businessRoles = response;
        console.log(response);
      } catch (error) {
        console.log(error);
      }
    },

    async getBusinessDocs(id) {
      try {
        const id = this.allBusinessDetails.clientId;
        //  console.log(id);
        const response = await AuthService.getBusinessDocs(id);
        this.businessDocs = response;
        this.loaded = true;
        console.log(response);
      } catch (error) {
        console.log(error);
      }
    },

    async getClient(id) {
      try {
        const id = this.$route.params.id;
        const response = await AuthService.getClient(id);
        this.client = response;
        console.log(response);
      } catch (error) {
        console.log(error);
      }
    },
    async manageApplicationStatus() {
      try {
        const id = this.$route.params.id;
        const details = {
          lang: this.$i18n.locale,
          verificationStatus: this.client.verificationStatus,
        };
        const response = await AuthService.manageApplicationStatus(id, details);
        this.msg = response.msg;
        console.log(response.msg);
      } catch (error) {
        this.msg = error.response.data.msg;
        //console.log(error);
      }
    },

    /* async deleteAccount(id) {
        try {
          const response = await AuthService.deleteAccount(id);
          console.log(response);
          this.getClientAccountList();
        } catch (error) {
          console.log(error);
        }
      },*/
  },

  mounted() {
    this.getClient();
   
    this.getAdminBusinessDetails();
    this.getAdminAllBusinessDetails();
    this.getAdminBusinessRoles();
    // this.getBusinessDocs();
    this.getClientAccountList();
    this.getClientAccounts();
    this.totalBalance();

    // this.formatIBAN();
  },
};
</script>
