<template>
	<ValidationObserver ref="observer" v-slot="{ handleSubmit, reset }">
		<b-form
			@submit.prevent="handleSubmit(editPaymentOrder)"
			@reset.prevent="reset"
		>
			<div class="col-lg-4 offset-lg-4 text-left">
				<h2 class="title-bold">
					{{ $t("paymentManagement.managePayment") }}
				</h2>
				<hr />
				<h5 class="title-bold">
					{{ $t("paymentManagement.viewApproveDeletePayments") }}
				</h5>
				<hr />
				<!-- <div class="background-modifier">
          <p class="text-left">{{ $t("editPaymentOrder.debitAccountId") }}</p>
          <ValidationProvider
            rules="required|integer"
            name="debitAccounId"
            v-slot="{ valid, errors }"
          >
            <b-form-group label-for="debitAccountIdInput" description="">
              <b-form-input
                type="text"
                v-model="paymentOrder.debitAccountId"
                :state="errors[0] ? false : valid ? true : null"
                :placeholder="$t('editPaymentOrder.debitAccountId')"
              ></b-form-input>
              <b-form-invalid-feedback id="inputLiveFeedback">{{
                errors[0]
              }}</b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
          <p class="text-left">{{ $t("editPaymentOrder.creditAccountId") }}</p>
          <ValidationProvider
            rules="required|integer"
            name="creditAccounId"
            v-slot="{ valid, errors }"
          >
            <b-form-group label-for="creditAccountIdInput" description="">
              <b-form-input
                type="text"
                v-model="paymentOrder.creditAccountId"
                :state="errors[0] ? false : valid ? true : null"
                :placeholder="$t('editPaymentOrder.creditAccountId')"
              ></b-form-input>
              <b-form-invalid-feedback id="inputLiveFeedback">{{
                errors[0]
              }}</b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </div>
        <br /> -->

				<!-- <div class="background-modifier">
          <p class="text-left">{{ $t("editPaymentOrder.amount") }}</p>
          <ValidationProvider
            rules="required|double|max_value:1000000|min_value:0.01"
            name="amount"
            v-slot="{ valid, errors }"
          >
            <b-form-group label-for="amountInput" description="">
              <b-form-input
                type="text"
                v-model="paymentOrder.amount"
                :state="errors[0] ? false : valid ? true : null"
                :placeholder="$t('editPaymentOrder.amount')"
              ></b-form-input>
              <b-form-invalid-feedback id="inputLiveFeedback">{{
                errors[0]
              }}</b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </div>
        <br />

        <div class="background-modifier">
          <p class="text-left">{{ $t("editPaymentOrder.message") }}</p>
          <ValidationProvider
            rules="min:1|max:50"
            name="message"
            v-slot="{ valid, errors }"
          >
            <b-form-group label-for="messageInput" description="">
              <b-form-input
                type="text"
                v-model="paymentOrder.message"
                :state="errors[0] ? false : valid ? true : null"
                :placeholder="$t('editPaymentOrder.message')"
              ></b-form-input>
              <b-form-invalid-feedback id="inputLiveFeedback">{{
                errors[0]
              }}</b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </div>
        <br />

        <div class="background-modifier">
          <div class="row">
            <div class="col-md-12 offset-md-0">
              <label for="">{{ $t("editPaymentOrder.dueDate") }}</label>
              <b-form-datepicker
                v-model="paymentOrder.dueDate"
                :min="min"
                :locale="$i18n.locale"
                :label-no-date-selected="$t('editPaymentOrder.noDateSelected')"
                :label-help="
                  $t('editPaymentOrder.useCursorKeysToNavigateCalendarDates')
                "
              ></b-form-datepicker>
            </div>
          </div>
        </div> -->
				<div class="background-outline">
					<div class="background-modifier">
						<div class="product-details">
							<label class="small">{{ $t("paymentManagement.id") }}</label>
							<p class="text-left">
								{{ paymentOrder.id }}
							</p>
							<label class="small">{{
								$t("paymentManagement.debitAccountId")
							}}</label>
							<p class="text-left">
								({{ paymentOrder.debitAccountId }})
								{{
									debitAccountIBAN.iban.toString().replace(/\w{4}(?=.)/g, "$& ")
								}}
							</p>
							<label class="small">{{
								$t("paymentManagement.creditAccountId")
							}}</label>
							<p class="text-left">
								({{ paymentOrder.creditAccountId }})
								{{
									creditAccountIBAN.iban
										.toString()
										.replace(/\w{4}(?=.)/g, "$& ")
								}}
							</p>
							<label class="small">{{
								$t("paymentManagement.beneficiaryName")
							}}</label>
							<p class="text-left">
								{{ paymentOrder.creditAccountHolder }}
							</p>
							<label class="small">{{
								$t("paymentManagement.accountCurrency")
							}}</label>
							<p class="text-left">
								{{ paymentOrder.baseCurrency }}
							</p>
							<label class="small">{{ $t("paymentManagement.amount") }}</label>
							<p class="text-left">
								{{ $n(paymentOrder.amount, "decimal") }}
							</p>
							<label class="small">{{
								$t("paymentManagement.paymentCurrency")
							}}</label>
							<p class="text-left">
								{{ paymentOrder.paymentCurrency }}
							</p>
							<label class="small">{{
								$t("paymentManagement.currencyAmount")
							}}</label>
							<p class="text-left">
								{{ $n(paymentOrder.currencyAmount, "decimal") }}
							</p>
							<label class="small">{{ $t("paymentManagement.message") }}</label>
							<p class="text-left">
								{{ paymentOrder.message }}
							</p>
							<label class="small">{{ $t("paymentManagement.dueDate") }}</label>
							<p class="text-left">
								{{ $d(Date.parse(paymentOrder.dueDate), "long") }}
							</p>
							<label class="small">{{ $t("paymentManagement.created") }}</label>
							<p class="text-left">
								{{ $d(Date.parse(paymentOrder.created), "long") }}
							</p>
						</div>
					</div>
					<hr />
					<b-button
						type="submit"
						@click="isloading"
						class="btn btn-info text-light btn-block"
						>{{ $t("paymentManagement.approve")
						}}<b-spinner
							v-if="isloading"
							class="ml-auto"
							:variant="light"
						></b-spinner
					></b-button>
					<hr />
					<a
						class="btn btn-alert text-light btn-block"
						@click="deletePaymentOrder(paymentOrder.id)"
						>{{ $t("paymentManagement.delete") }}</a
					>
					<!--   <hr/>
       <b-button type="reset" class="btn btn-alert text-light btn-block" @click="resetForm()">{{ $t('reset') }}</b-button> -->

					<div v-if="msg && $bvToast.show('notification-toast')"></div>

					<div class="action">
						<br />
						<b-toast
							id="notification-toast"
							variant="secondary"
							static
							no-auto-hide
						>
							<template #toast-title>
								<div class="d-flex flex-grow-1 align-items-baseline">
									<b-img
										class="logo mb-2"
										:src="require('/public/images/logo.png')"
										alt=""
										width="12"
										height="12"
									></b-img>
									<strong class="mr-auto">{{ $t("notification") }}</strong>
									<small class="text-muted mr-2"></small>
								</div>
							</template>
							{{ msg }}
						</b-toast>
					</div>
				</div>
			</div>
		</b-form>
	</ValidationObserver>
</template>

<script>
	import { ValidationObserver, ValidationProvider } from "vee-validate";
	import AuthService from "@/services/AuthService.js";

	export default {
		name: "EditPaymentOrder",
		components: {
			ValidationObserver,
			ValidationProvider,
		},
		data() {
			const now = new Date();
			const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
			// 15th two months prior

			const minDate = new Date(today);
			//   const minDate = new Date(openingDate.getFullYear(), openingDate.getMonth(), openingDate.getDate())
			minDate.setYear(minDate.getDate() + 1);

			return {
				paymentOrder: "",
				creditAccountIBAN: "",
				debitAccountIBAN: "",
				dueDate: "",
				min: minDate,
				msg: "",
				isloading: false,
			};
		},
		async created() {
			if (!this.$store.getters.isLoggedIn) {
				this.$router.push("login");
			}
		},
		methods: {
			async getPaymentOrder(id) {
				try {
					const id = this.$route.params.id;
					const response = await AuthService.getPaymentOrder(id);
					this.paymentOrder = response;
					console.log(response);
				} catch (error) {
					console.log(error);
				}
			},
			async editPaymentOrder() {
				try {
					const id = this.$route.params.id;
					const details = {
						lang: this.$i18n.locale,
						debitAccountId: this.paymentOrder.debitAccountId,
						creditAccountId: this.paymentOrder.creditAccountId,
						creditAccountHolder: this.paymentOrder.creditAccountHolder,
						amount: this.paymentOrder.amount,
						message: this.paymentOrder.message,
						dueDate: this.paymentOrder.dueDate,
						status: 1,
					};
					this.isloading = true;
					const response = await AuthService.editPaymentOrder(id, details);
					this.msg = response.msg;
					this.isloading = false;
					this.getPaymentOrder();
					console.log(response.msg);
				} catch (error) {
					this.isloading = false;
					console.log(error);
				}
			},
			async deletePaymentOrder(id) {
				try {
					const lang = this.$i18n.locale;
					const response = await AuthService.deletePaymentOrder(id, lang);
					console.log(response);
					this.getClientsPaymentOrders();
					this.msg = response.msg;
					console.log(response.msg);
				} catch (error) {
					console.log(error);
					this.msg = error.response.data.msg;
				}
			},
			async getPaymentOrderCreditAccountIBAN(id) {
				try {
					const id = this.$route.params.id;
					const response = await AuthService.getPaymentOrderCreditAccountIBAN(
						id
					);
					this.creditAccountIBAN = response;
					console.log(response);
				} catch (error) {
					console.log(error);
				}
			},
			async getPaymentOrderDebitAccountIBAN(id) {
				try {
					const id = this.$route.params.id;
					const response = await AuthService.getPaymentOrderDebitAccountIBAN(
						id
					);
					this.debitAccountIBAN = response;
					console.log(response);
				} catch (error) {
					console.log(error);
				}
			},
			/*     resetForm() {

        this.msg = '';
        requestAnimationFrame(() => {
          this.$refs.observer.reset();
        });
      }*/
		},
		mounted() {
			this.getPaymentOrder();
			this.getPaymentOrderCreditAccountIBAN();
			this.getPaymentOrderDebitAccountIBAN();
		},
	};
</script>
