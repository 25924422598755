import http from "../http-common";

class NipRequestUploadService {
  upload(file, lang, clientId, onUploadProgress) {
    let formData = new FormData();
    // let details = details;
    formData.append("file", file);

    return http.post(
      lang + "/nip-request-upload/" + clientId + "/{}",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          //         or limit header to excel:
          // "Content-disposition" : "attachment; filename=[yourFileName]"
          // "Content-Type" : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        },
        onUploadProgress,
      }
    );
  }

  getFiles() {
    return http.get("/files");
  }
}

export default new NipRequestUploadService();
