<template>
	<div class="col-12 offset-0">
		<div class="text-left">
			<div class="col-md-8 offset-md-2">
				<h1 class="title-bold">{{ $t("fixedTermDeposits.accounts") }}</h1>
				<hr />
				<p class="open-account text-right">
					<router-link
						class="btn btn-outline"
						:to="{ name: 'open-fixed-term-deposit' }"
						>{{ $t("fixedTermDeposits.openAccount") }}</router-link
					>
				</p>
				<br />

				<div class="background-modifier">
					<div class="input-group mb-3">
						<input
							type="text"
							class="form-control"
							:placeholder="$t('search')"
							v-model="searchTitle"
						/>
						<div class="input-group-append">
							<button
								class="btn btn-outline"
								type="button"
								@click="
									page = 1;
									getUsersFixedTermDeposits();
								"
							>
								{{ $t("search") }}
							</button>
						</div>
					</div>

					<div v-if="isloading" class="text-center">
						<b-spinner
							style="width: 3rem; height: 3rem;"
							class="ml-auto text-center"
							:variant="secondary"
						></b-spinner>
					</div>
					<div v-else-if="accounts.length > 0">
						<div class="table-wrap">
							<table class="table mt-2" id="accounts">
								<thead>
									<tr>
										<th class="text-left small">
											{{ $t("fixedTermDeposits.id") }}
										</th>
										<th class="text-left small">
											{{ $t("fixedTermDeposits.accountHolder") }}
										</th>
										<th class="text-left small">
											{{ $t("fixedTermDeposits.nickname") }}
										</th>
										<th class="text-right small">
											{{ $t("fixedTermDeposits.opened") }}
										</th>
										<th class="text-right small">
											{{ $t("fixedTermDeposits.currency") }}
										</th>
										<th class="text-right small">
											{{ $t("fixedTermDeposits.balance") }}
										</th>
										<th class="text-centered small">
											{{ $t("fixedTermDeposits.actions") }}
										</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="account in accounts" :key="account.id">
										<td class="text-left">{{ account.id }}</td>
										<td class="text-left">
											{{ account.firstname }} {{ account.lastname }}
										</td>
										<td class="text-left">{{ account.nickname }}</td>
										<td class="text-right">
											{{ $d(Date.parse(account.created), "short") }}
										</td>
										<td class="text-left">{{ account.currency }}</td>
										<td class="text-right">
											{{
												account.credits - account.debits != null
													? $n(account.credits - account.debits, "decimal")
													: 0.0
											}}
										</td>
										<td class="text-right">
											<router-link
												:to="{
													name: 'fixed-term-deposit',
													params: { id: account.id },
												}"
												class="btn btn-outline mx-1 "
												>{{ $t("fixedTermDeposits.view") }}</router-link
											>
											<!--  <a
                  class="btn btn-danger mx-1 "
                  @click="deleteAccount(account.id)"
                  >{{ $t('fixedTermDeposits.delete') }}</a
                > -->
										</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div v-if="accounts.length >= 1">
							<div class="row">
								<div class="col-md-6">
									{{ $t("itemsPerPage") }}
									<select
										class="custom-select"
										style="width: 60px;"
										v-model="pageSize"
										@change="handlePageSizeChange($event)"
									>
										<option v-for="size in pageSizes" :key="size" :value="size">
											{{ size }}
										</option>
									</select>
								</div>
								<div class="col-md-6">
									<b-pagination
										v-model="page"
										:total-rows="count"
										:per-page="pageSize"
										:prev-text="$t('previous')"
										:next-text="$t('next')"
										align="right"
										@change="handlePageChange"
									></b-pagination>
								</div>
							</div>
						</div>
					</div>
					<div v-else>{{ $t("fixedTermDeposits.noAccounts") }}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import AuthService from "@/services/AuthService.js";

	export default {
		name: "accounts",
		data() {
			return {
				accounts: [],
				currentAccount: null,
				currentIndex: -1,
				searchTitle: "",
				isloading: false,
				page: 1,
				count: 0,
				pageSize: 10,

				pageSizes: [10, 25, 50],
			};
		},
		async created() {
			if (!this.$store.getters.isLoggedIn) {
				this.$router.push("login");
			}
			// this.getUsersFixedTermDeposits();
		},
		methods: {
			getRequestParams(searchTitle, page, pageSize) {
				let params = {};

				if (searchTitle) {
					params["nickname"] = searchTitle;
				}

				if (page) {
					params["page"] = page - 1;
				}

				if (pageSize) {
					params["size"] = pageSize;
				}

				return params;
			},

			async getUsersFixedTermDeposits() {
				const params = this.getRequestParams(
					this.searchTitle,
					this.page,
					this.pageSize
				);

				try {
					const details = {
						id: this.$store.getters.getUser.id,
					};
					this.isloading = true;
					const response = await AuthService.getUsersFixedTermDeposits(
						details.id,
						params.page,
						params.size,
						params.nickname
					);
					const { items, totalItems } = response.data;
					this.accounts = items;
					this.count = totalItems;
					this.isloading = false;
					console.log(response.data);
				} catch (error) {
					this.isloading = false;
					console.log(error);
				}
			},

			handlePageChange(value) {
				this.page = value;
				this.getUsersFixedTermDeposits();
			},

			handlePageSizeChange(event) {
				this.pageSize = event.target.value;
				this.page = 1;
				this.getUsersFixedTermDeposits();
			},
			/* async deleteAccount(id) {
        try {
          const response = await AuthService.deleteAccount(id);
          console.log(response);
          this.getUsersFixedTermDeposits();
        } catch (error) {
          console.log(error);
        }
      },*/
		},

		mounted() {
			this.getUsersFixedTermDeposits();
		},
	};
</script>
