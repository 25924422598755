<template>
	<div class="col-12 offset-0">
		<div class="text-left">
			<div class="col-md-8 offset-md-2">
				<h1 class="title-bold">{{ $t("paymentOrders.paymentOrders") }}</h1>
				<hr />
				<div v-if="msg && $bvToast.show('notification-toast')"></div>
				<div class="action">
					<b-toast
						id="notification-toast"
						variant="secondary"
						static
						no-auto-hide
					>
						<template #toast-title>
							<div class="d-flex flex-grow-1 align-items-baseline">
								<b-img
									class="logo mb-2"
									:src="require('/public/images/logo.png')"
									alt=""
									width="12"
									height="12"
								></b-img>
								<strong class="mr-auto">{{ $t("notification") }}</strong>
								<small class="text-muted mr-2"></small>
							</div>
						</template>
						{{ msg }}
					</b-toast>
				</div>

				<div class="background-modifier">
					<div class="input-group mb-3">
						<input
							type="text"
							class="form-control"
							:placeholder="$t('search')"
							v-model="searchTitle"
						/>
						<div class="input-group-append">
							<button
								class="btn btn-outline"
								type="button"
								@click="
									page = 1;
									getUsersPaymentOrders();
								"
							>
								{{ $t("search") }}
							</button>
						</div>
					</div>

					<div v-if="isloading" class="text-center">
						<b-spinner
							style="width: 3rem; height: 3rem;"
							class="ml-auto text-center"
							:variant="secondary"
						></b-spinner>
					</div>
					<div v-else-if="paymentOrders.length > 0">
						<div class="table-wrap">
							<table class="table mt-2" id="paymentOrders">
								<thead>
									<tr>
										<th class="text-left small">
											{{ $t("paymentOrders.id") }}
										</th>
										<th class="text-left small">
											{{ $t("paymentOrders.beneficiaryName") }}
										</th>
										<th class="text-right small">
											{{ $t("paymentOrders.amount") }}
										</th>
										<th class="text-left small">
											{{ $t("paymentOrders.message") }}
										</th>
										<th class="text-right small">
											{{ $t("paymentOrders.dueDate") }}
										</th>
										<th class="text-right small">
											{{ $t("paymentOrders.created") }}
										</th>
										<th class="text-centered small">
											{{ $t("paymentOrders.actions") }}
										</th>
									</tr>
								</thead>
								<tbody>
									<tr
										v-for="paymentOrder in paymentOrders"
										:key="paymentOrder.id"
									>
										<td class="text-left">{{ paymentOrder.id }}</td>
										<td class="text-left">
											{{ paymentOrder.creditAccountHolder }}
										</td>
										<td class="text-right">
											{{ $n(paymentOrder.amount, "decimal") }}
										</td>
										<td class="text-left">{{ paymentOrder.message }}</td>
										<td class="text-right">
											{{ $d(Date.parse(paymentOrder.dueDate), "short") }}
										</td>
										<td class="text-right">
											{{ $d(Date.parse(paymentOrder.created), "short") }}
										</td>
										<td class="text-right">
											<router-link
												:to="{
													name: 'paymentorder',
													params: { id: paymentOrder.id },
												}"
												class="btn btn-outline mx-1 "
												>{{ $t("paymentOrders.view") }}</router-link
											>
											<a
												class="btn btn-danger mx-1 "
												@click="deletePaymentOrder(paymentOrder.id)"
												>{{ $t("paymentOrders.delete") }}</a
											>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div v-if="paymentOrders.length >= 1">
							<div class="row">
								<div class="col-md-6">
									{{ $t("itemsPerPage") }}
									<select
										class="custom-select"
										style="width: 60px;"
										v-model="pageSize"
										@change="handlePageSizeChange($event)"
									>
										<option v-for="size in pageSizes" :key="size" :value="size">
											{{ size }}
										</option>
									</select>
								</div>
								<div class="col-md-6">
									<b-pagination
										v-model="page"
										:total-rows="count"
										:per-page="pageSize"
										:prev-text="$t('previous')"
										:next-text="$t('next')"
										align="right"
										@change="handlePageChange"
									></b-pagination>
								</div>
							</div>
						</div>
					</div>

					<div v-else>{{ $t("paymentOrders.noPaymentOrders") }}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import AuthService from "@/services/AuthService.js";

	export default {
		name: "paymentOrders",
		data() {
			return {
				paymentOrders: [],
				msg: "",
				currentPaymentOrder: null,
				currentIndex: -1,
				searchTitle: "",
				isloading: false,
				page: 1,
				count: 0,
				pageSize: 10,

				pageSizes: [10, 25, 50],
			};
		},
		async created() {
			if (!this.$store.getters.isLoggedIn) {
				this.$router.push("login");
			}
			// this.getUsersPaymentOrders();
		},
		methods: {
			getRequestParams(searchTitle, page, pageSize) {
				let params = {};

				if (searchTitle) {
					params["title"] = searchTitle;
				}

				if (page) {
					params["page"] = page - 1;
				}

				if (pageSize) {
					params["size"] = pageSize;
				}

				return params;
			},

			async getUsersPaymentOrders() {
				const params = this.getRequestParams(
					this.searchTitle,
					this.page,
					this.pageSize
				);

				try {
					const details = {
						id: this.$store.getters.getUser.id,
					};
					this.isloading = true;
					const response = await AuthService.getUsersPaymentOrders(
						details.id,
						params.page,
						params.size,
						params.title
					);
					const { items, totalItems } = response.data;
					this.paymentOrders = items;
					this.count = totalItems;
					this.isloading = false;
					console.log(response.data);
				} catch (error) {
					this.isloading = false;
					console.log(error);
				}
			},

			handlePageChange(value) {
				this.page = value;
				this.getUsersPaymentOrders();
			},

			handlePageSizeChange(event) {
				this.pageSize = event.target.value;
				this.page = 1;
				this.getUsersPaymentOrders();
			},

			async deletePaymentOrder(id) {
				try {
					const lang = this.$i18n.locale;
					const response = await AuthService.deletePaymentOrder(id, lang);
					console.log(response);
					this.getUsersPaymentOrders();
					this.msg = response.msg;
					console.log(response.msg);
				} catch (error) {
					console.log(error);
					this.msg = error.response.data.msg;
				}
			},
		},

		mounted() {
			this.getUsersPaymentOrders();
		},
	};
</script>
