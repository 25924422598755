<template>
	<ValidationObserver ref="observer" v-slot="{ handleSubmit, reset }">
		<b-form
			@submit.prevent="handleSubmit(editBeneficiary)"
			@reset.prevent="reset"
		>
			<div class="col-lg-4 offset-lg-4 text-left">
				<h2 class="title-bold">{{ $t("editBeneficiary.editBeneficiary") }}</h2>
				<hr />
				<div class="background-outline">
					<div class="background-modifier">
						<label class="small">
							{{ $t("editBeneficiary.beneficiaryAccount") }}
						</label>
						<ValidationProvider
							rules="integer|min:1|max:4"
							name="beneficiaryAccount"
							v-slot="{ valid, errors }"
						>
							<b-form-group label-for="beneficiaryAccountInput" description="">
								<b-form-input
									type="text"
									v-model="beneficiary.beneficiaryAccount"
									:state="errors[0] ? false : valid ? true : null"
									:placeholder="$t('editBeneficiary.beneficiaryAccount')"
								></b-form-input>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>

						<label class="small">{{
							$t("editBeneficiary.beneficiaryName")
						}}</label>
						<ValidationProvider
							rules="alpha_spaces|min:3|max:50"
							name="beneficiaryName"
							v-slot="{ valid, errors }"
						>
							<b-form-group label-for="beneficiaryNameInput" description="">
								<b-form-input
									type="text"
									v-model="beneficiary.beneficiaryName"
									:state="errors[0] ? false : valid ? true : null"
									:placeholder="$t('editBeneficiary.beneficiaryName')"
								></b-form-input>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>

						<label class="small">{{ $t("editBeneficiary.alias") }}</label>
						<ValidationProvider
							rules="alpha_spaces|min:3|max:50"
							name="alias"
							v-slot="{ valid, errors }"
						>
							<b-form-group label-for="aliasInput" description="">
								<b-form-input
									type="text"
									v-model="beneficiary.alias"
									:state="errors[0] ? false : valid ? true : null"
									:placeholder="$t('editBeneficiary.alias')"
								></b-form-input>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>
					</div>
					<hr />

					<b-button
						type="submit"
						@click="isloading"
						class="btn btn-info text-light btn-block"
						>{{ $t("editBeneficiary.submit")
						}}<b-spinner
							v-if="isloading"
							class="ml-auto"
							:variant="light"
						></b-spinner
					></b-button>
					<!--   <hr/>
       <b-button type="reset" class="btn btn-alert text-light btn-block" @click="resetForm()">{{ $t('reset') }}</b-button> -->

					<div v-if="msg && $bvToast.show('notification-toast')"></div>
					<div class="action">
						<br />
						<b-toast
							id="notification-toast"
							variant="secondary"
							static
							no-auto-hide
						>
							<template #toast-title>
								<div class="d-flex flex-grow-1 align-items-baseline">
									<b-img
										class="logo mb-2"
										:src="require('/public/images/logo.png')"
										alt=""
										width="12"
										height="12"
									></b-img>
									<strong class="mr-auto">{{ $t("notification") }}</strong>
									<small class="text-muted mr-2"></small>
								</div>
							</template>
							{{ msg }}
						</b-toast>
					</div>
				</div>
			</div>
		</b-form>
	</ValidationObserver>
</template>

<script>
	import { ValidationObserver, ValidationProvider } from "vee-validate";
	import AuthService from "@/services/AuthService.js";

	export default {
		name: "NewBeneficiaryForm",
		components: {
			ValidationObserver,
			ValidationProvider,
		},
		data() {
			return {
				beneficiary: "",
				msg: "",
				isloading: false,
			};
		},
		async created() {
			if (!this.$store.getters.isLoggedIn) {
				this.$router.push("login");
			}
		},
		methods: {
			async getBeneficiaryById(id) {
				try {
					const id = this.$route.params.id;
					const response = await AuthService.getBeneficiary(id);
					this.beneficiary = response;
					console.log(response);
				} catch (error) {
					console.log(error);
				}
			},
			async editBeneficiary() {
				try {
					const id = this.$route.params.id;
					const details = {
						beneficiaryAccount: this.beneficiary.beneficiaryAccount,
						beneficiaryName: this.beneficiary.beneficiaryName,
						alias: this.beneficiary.alias,
						lang: this.$i18n.locale,
					};
					this.isloading = true;
					const response = await AuthService.editBeneficiary(id, details);
					this.msg = response.msg;
					this.isloading = false;
					this.getBeneficiaryById();
					console.log(response.msg);
				} catch (error) {
					this.isloading = false;
					console.log(error);
				}
			},
			resetForm() {
				this.nickname = "";
				this.msg = "";
				requestAnimationFrame(() => {
					this.$refs.observer.reset();
				});
			},
		},
		mounted() {
			this.getBeneficiaryById();
		},
	};
</script>
