<template>
	<div v-if="getUserId() === paymentOrder.userId">
		<div class="col-lg-4 offset-lg-4 text-left">
			<div class="">
				<h2 class="title-bold">
					{{ $t("paymentOrder.paymentOrder") }} {{ paymentOrder.alias }}
				</h2>
				<hr />
				<div class="background-modifier">
					<div class="product-details">
						<label class="small">{{ $t("paymentOrder.id") }}</label>
						<p class="text-left">
							{{ paymentOrder.id }}
						</p>
						<label class="small">{{ $t("paymentOrder.debitAccountId") }}</label>
						<p class="text-left">
							{{ paymentOrder.debitAccountId }}
						</p>
						<label class="small">{{
							$t("paymentOrder.creditAccountId")
						}}</label>
						<p class="text-left">
							{{ paymentOrder.creditAccountId }}
						</p>
						<label class="small">{{
							$t("paymentOrder.beneficiaryName")
						}}</label>
						<p class="text-left">
							{{ paymentOrder.creditAccountHolder }}
						</p>
						<label class="small"
							>{{ $t("paymentOrder.accountCurrency") }}></label
						>
						<p class="text-left">
							{{ paymentOrder.baseCurrency }}
						</p>
						<label class="small">{{ $t("paymentOrder.amount") }}</label>
						<p class="text-left">
							{{ $n(paymentOrder.amount, "decimal") }}
						</p>
						<label class="small">{{
							$t("paymentOrder.paymentCurrency")
						}}</label>
						<p class="text-left">
							{{ paymentOrder.paymentCurrency }}
						</p>
						<label class="small">{{ $t("paymentOrder.currencyAmount") }}</label>
						<p class="text-left">
							{{ $n(paymentOrder.currencyAmount, "decimal") }}
						</p>
						<label class="small">{{ $t("paymentOrder.message") }}</label>
						<p class="text-left">
							{{ paymentOrder.message }}
						</p>
						<label class="small">{{ $t("paymentOrder.dueDate") }}</label>
						<p class="text-left">
							{{ $d(Date.parse(paymentOrder.dueDate), "long") }}
						</p>
						<label class="small">{{ $t("paymentOrder.created") }}</label>
						<p class="text-left">
							{{ $d(Date.parse(paymentOrder.created), "long") }}
						</p>
					</div>

					<div class="text-right">
						<router-link
							:to="{
								name: 'edit-paymentorder',
								params: { id: paymentOrder.id },
							}"
							class="btn btn-outline mx-1 "
							>{{ $t("paymentOrder.edit") }}</router-link
						>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div v-else>
		<h3>{{ $t("unauthorized") }}</h3>
	</div>
</template>

<script>
	import AuthService from "@/services/AuthService.js";

	export default {
		name: "PaymentOrder",
		data() {
			return {
				paymentOrder: "",
			};
		},
		async created() {
			if (!this.$store.getters.isLoggedIn) {
				this.$router.push("login");
			}
		},
		methods: {
			getUserId() {
				const userId = this.$store.getters.getUser.id;
				return userId;
			},
			async getPaymentOrder(id) {
				try {
					const id = this.$route.params.id;
					const response = await AuthService.getPaymentOrder(id);
					this.paymentOrder = response;
					console.log(response);
				} catch (error) {
					console.log(error);
				}
			},
		},
		mounted() {
			this.getUserId();
			this.getPaymentOrder();
		},
	};
</script>
