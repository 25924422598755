<template>
	<ValidationObserver ref="observer" v-slot="{ handleSubmit, reset }">
		<b-form
			@submit.prevent="handleSubmit(editInvoiceDetails)"
			@reset.prevent="reset"
		>
			<div class="col-lg-8 offset-lg-2 text-left">
				<h2 class="title-bold">
					{{ $t("customerInvoice.id") }} {{ invoice.id }}
				</h2>
				<hr />
				<div class="background-modifier text-left">
					<div class="product-details">
						<label class="small">{{ $t("customerInvoice.internalId") }}</label>
						<p class="">
							{{ invoice.internalId }}
						</p>
						<label class="small">{{
							$t("customerInvoice.customerReference")
						}}</label>
						<p class="">
							{{ invoice.customerReference }}
						</p>
						<label class="small">{{
							$t("customerInvoice.creditAccountId")
						}}</label>
						<p class="">
							{{ invoice.creditAccountId }}
						</p>
						<label class="small">{{
							$t("customerInvoice.customerName")
						}}</label>
						<p class="">
							{{ invoice.debitAccountHolder }}
						</p>
						<label class="small">{{
							$t("customerInvoice.debitAccountId")
						}}</label>
						<p class="">
							{{ invoice.debitAccountId }}
						</p>
						<label class="small">{{
							$t("customerInvoice.debitAccountIBAN")
						}}</label>
						<p class="">
							{{ invoice.debitAccountIBAN }}
						</p>
						<label class="small">{{ $t("customerInvoice.currency") }}</label>
						<p class="">
							{{ invoice.currency }}
						</p>
						<label class="small">{{ $t("customerInvoice.totalGross") }}</label>
						<p class="">
							{{ $n(invoice.totalGross, "decimal") }}
						</p>
						<label class="small"
							>{{ $t("customerInvoice.discountRate") }} (%)</label
						>
						<p class="">
							{{ $n(invoice.discountRate, "decimal") }}
						</p>
						<label class="small">{{ $t("customerInvoice.totalNet") }}</label>
						<p>
							{{ $n(invoice.totalNet, "decimal") }}
						</p>
						<label class="small">{{ $t("customerInvoice.vatRate") }} (%)</label>
						<p class="">
							{{ $n(invoice.vatRate, "decimal") }}
						</p>
						<label class="small">{{ $t("customerInvoice.vatAmount") }}</label>
						<p>
							{{ $n(invoice.vatAmount, "decimal") }}
						</p>
						<label class="small">{{ $t("customerInvoice.totalVAT") }}</label>
						<p>
							{{ $n(invoice.totalVat, "decimal") }}
						</p>
						<label class="small">{{
							$t("customerInvoice.paymentReference")
						}}</label>
						<p class="">
							{{ invoice.paymentReference }}
						</p>
						<label class="small">{{ $t("customerInvoice.dueDate") }}</label>
						<p class="">
							{{ $d(Date.parse(invoice.dueDate), "short") }}
						</p>
					</div>
					<hr />
					<div class="row">
						<div class="col-md-6">
							<p class="open-account text-left">
								<router-link
									class="btn btn-info text-white"
									:to="{
										name: 'invoice-payment',
										params: { id: invoice.paymentReference },
									}"
									>{{ $t("customerInvoice.goToPay") }}</router-link
								>
							</p>
						</div>
						<div class="col-md-6"></div>
					</div>
				</div>
				<br />

				<div v-if="msg && $bvToast.show('notification-toast')"></div>
				<div class="action">
					<b-toast
						id="notification-toast"
						variant="secondary"
						static
						no-auto-hide
					>
						<template #toast-title>
							<div class="d-flex flex-grow-1 align-items-baseline">
								<b-img
									class="logo mb-2"
									:src="require('/public/images/logo.png')"
									alt=""
									width="12"
									height="12"
								></b-img>
								<strong class="mr-auto">{{ $t("notification") }}</strong>
								<small class="text-muted mr-2"></small>
							</div>
						</template>
						{{ msg }}
						<br />
					</b-toast>
				</div>

				<h4 class="title-bold">
					{{ $t("customerInvoiceItem.listOfItems") }} ({{ items.length }})
				</h4>
				<hr />

				<div class="background-modifier">
					<div class="input-group mb-3">
						<input
							type="text"
							class="form-control"
							:placeholder="$t('customerInvoiceItem.productName')"
							v-model="searchTitle"
						/>
						<div class="input-group-append">
							<button
								class="btn btn-outline"
								type="button"
								@click="
									page = 1;
									getInvoiceItems();
								"
							>
								{{ $t("search") }}
							</button>
						</div>
					</div>
					<div class="">
						<div class="mb-3">
							{{ $t("itemsPerPage") }}
							<select
								class="custom-select"
								style="width: 60px;"
								v-model="pageSize"
								@change="handlePageSizeChange($event)"
							>
								<option v-for="size in pageSizes" :key="size" :value="size">
									{{ size }}
								</option>
							</select>
						</div>

						<b-pagination
							v-model="page"
							:total-rows="count"
							:per-page="pageSize"
							:prev-text="$t('previous')"
							:next-text="$t('next')"
							@change="handlePageChange"
						></b-pagination>
					</div>

					<div v-if="isloading" class="text-center">
						<b-spinner
							style="width: 3rem; height: 3rem;"
							class="ml-auto text-center"
							:variant="secondary"
						></b-spinner>
					</div>
					<div v-else-if="items.length > 0">
						<div class="table-wrap">
							<table class="table mt-2" id="items">
								<thead>
									<tr>
										<th class="text-left">
											{{ $t("customerInvoiceItem.id") }}
										</th>
										<th class="text-left">
											{{ $t("customerInvoiceItem.productName") }}
										</th>
										<th class="text-right">
											{{ $t("customerInvoiceItem.productUnitPriceGross") }}
										</th>
										<th class="text-right">
											{{ $t("customerInvoiceItem.numberOfItems") }}
										</th>
										<th class="text-centered">
											{{ $t("customerInvoiceItem.subTotal") }}
										</th>
										<!-- <th class="text-centered">
                      {{ $t("customerInvoiceItem.actions") }}
                    </th> -->
									</tr>
								</thead>
								<tbody>
									<tr v-for="item in items" :key="item.id">
										<td class="text-left">
											{{ item.id }}
										</td>
										<td class="text-left">
											{{ item.productName }}
										</td>
										<td class="text-right">
											{{ $n(item.productUnitPriceGross, "decimal") }}
										</td>
										<td class="text-right">
											{{ item.numberOfItems }}
										</td>
										<td class="text-right">
											{{ $n(item.subTotal, "decimal") }}
										</td>

										<!-- <td class="text-right">
                      <router-link
                        :to="{
                          name: 'invoice-item-details',
                          params: { id: item.id },
                        }"
                        class="btn btn-outline mx-1 "
                        >{{ $t("customerInvoiceItem.view") }}</router-link
                      >
                      <a
                        class="btn btn-danger mx-1 "
                        @click="deleteInvoiceItem(item.id)"
                        >{{ $t("customerInvoiceItem.delete") }}</a
                      >
                    </td> -->
									</tr>
								</tbody>
							</table>
						</div>
						<div v-if="items.length >= 1">
							<div class="row">
								<div class="col-md-6">
									{{ $t("itemsPerPage") }}
									<select
										class="custom-select"
										style="width: 60px;"
										v-model="pageSize"
										@change="handlePageSizeChange($event)"
									>
										<option v-for="size in pageSizes" :key="size" :value="size">
											{{ size }}
										</option>
									</select>
								</div>
								<div class="col-md-6">
									<b-pagination
										v-model="page"
										:total-rows="count"
										:per-page="pageSize"
										:prev-text="$t('previous')"
										:next-text="$t('next')"
										align="right"
										@change="handlePageChange"
									></b-pagination>
								</div>
							</div>
						</div>

						<div v-else>
							{{ $t("customerInvoiceItem.noItems") }}
						</div>
					</div>

					<!-- disabled-->
					<!-- <div v-if="status == 'accepted'"> -->
					<!-- <b-button type="submit" class="btn btn-info text-light btn-block">{{
          $t("customerInvoice.submit")
        }}</b-button> -->
					<!--   <p class="small">{{ $t("businessSignup.notAvailable") }}</p> -->
					<!-- </div> -->

					<!-- <b-button
          type="reset"
          class="btn btn-alert text-light btn-block"
          @click="resetForm()"
          >{{ $t("reset") }}</b-button
        >
        <hr /> -->
					<div v-if="msg && $bvToast.show('notification-toast')"></div>
					<div class="action">
						<br />
						<b-toast
							id="notification-toast"
							variant="secondary"
							static
							no-auto-hide
						>
							<template #toast-title>
								<div class="d-flex flex-grow-1 align-items-baseline">
									<b-img
										class="logo mb-2"
										:src="require('/public/images/logo.png')"
										alt=""
										width="12"
										height="12"
									></b-img>
									<strong class="mr-auto">{{ $t("notification") }}</strong>
									<small class="text-muted mr-2"></small>
								</div>
							</template>
							{{ msg }}
						</b-toast>
					</div>
				</div>
			</div>
		</b-form>
	</ValidationObserver>
</template>

<script>
	import { ValidationObserver, ValidationProvider } from "vee-validate";
	import AuthService from "@/services/AuthService.js";
	// var countryNames = require("i18n-iso-countries");
	export default {
		name: "InvoiceDetails",
		components: {
			ValidationObserver,
			ValidationProvider,
		},
		/*props: {
        codeLang: {
          type: String,
          default: "en",
        },
      },*/
		//this.codeLang
		created() {
			this.codeLang = this.$i18n.locale;
			// this.countryNames.registerLocale(
			//   require(`i18n-iso-countries/langs/${this.codeLang}.json`)
			// );
		},
		watch: {
			// codeLang(newVal, oldVal) {
			//   this.countryNames.registerLocale(
			//     require(`i18n-iso-countries/langs/${newVal}.json`)
			//   );
			// },
		},
		data() {
			// const now = new Date();
			// const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
			// // 15th two months prior
			// const minDate = new Date(today);
			// minDate.setYear(minDate.getFullYear() - 0);
			// const maxDate = new Date(today);
			// maxDate.setYear(maxDate.getFullYear() + 2);
			// minDate.setMonth(minDate.getMonth() - 2);
			// minDate.setDate(15);
			// 15th in two months

			return {
				invoice: "",
				items: [],
				msg: "",

				currentItem: null,

				currentIndex: -1,
				searchTitle: "",

				page: 1,
				count: 0,
				pageSize: 10,

				pageSizes: [10, 25, 50],
				isloading: false,
				// accounts: [],
				// countries: [],
				// countryNames: countryNames,

				// businessId: "",
				// vatNumber: "",
				// email: "",

				// internalId: "",
				// customerReference: "",
				// creditAccountId: 0,
				// creditAccountIBAN: "",
				// creditAccountHolder: "",
				// debitAccountId: 0,
				// debitAccountIBAN: "",
				// debitAccountHolder: "",
				// currency: "EUR",
				// totalGross: 0.0,
				// discountRate: 0.0,
				// totalNet: 0.0,
				// vatRate: 21.0,
				// vatAmount: 0.0,
				// totalVAT: 0.0,
				// paymentReference: "",
				// dueDate: minDate,
				// invoiceStatus: 0,

				/*  firstname: "",
      lastname: "",

      doi: maxDate.toISOString().slice(0, 10),

      addressLine1: "",
      city: "",
      zip: "",
      country: "",
      contactAddressLine1: "",
      contactCity: "",
      contactZip: "",
      contactCountry: "",
      prefix: "",
      mobile: "",*/
				// min: minDate,
				// max: maxDate,
				//status: "not_accepted",
			};
		},
		async created() {
			// if (!this.$store.getters.isLoggedIn) {
			//   this.$router.push("login");
			// }
			// if (
			//   this.$store.getters.isLoggedIn &&
			//   this.$store.getters.getUser.role != 3 &&
			//   this.$store.getters.isLoggedIn &&
			//   this.$store.getters.getUser.role != 15 &&
			//   this.$store.getters.isLoggedIn &&
			//   this.$store.getters.getUser.role != 14 &&
			//   this.$store.getters.isLoggedIn &&
			//   this.$store.getters.getUser.role != 13 &&
			//   this.$store.getters.isLoggedIn &&
			//   this.$store.getters.getUser.role != 12
			// ) {
			//   this.$router.push("overview");
			// }
			// let lang = this.$i18n.locale;
			// this.role = this.$store.getters.getUser.role;
			// this.username = this.$store.getters.getUser.username;
			// this.firstname = this.$store.getters.getUser.firstname;
			//this.getOfferById();
		},
		methods: {
			calculateTotalNet() {
				this.totalNetResult =
					this.invoice.totalGross -
					(this.invoice.discountRate / 100) * this.invoice.totalGross;
			},
			calculateVATAmount() {
				this.vatAmountResult =
					(this.totalNetResult * this.invoice.vatRate) / 100;
				return this.vatAmountResult;
			},
			calculateTotalInclVAT() {
				this.totalIncVATResult = this.totalNetResult - this.vatAmountResult;
				return this.totalIncVATResult;
			},

			// async editInvoiceDetails() {
			//   try {
			//     const id = this.$route.params.id;
			//     const details = {
			//       userId: this.$store.getters.getUser.id,
			//       lang: this.$i18n.locale,
			//       //id: this.$route.params.id,
			//       clientId: this.invoice.clientId,
			//       internalId: this.invoice.internalId,
			//       customerReference: this.invoice.customerReference,
			//       creditAccountId: this.invoice.creditAccountId,
			//       creditAccountIBAN: this.invoice.creditAccountIBAN,
			//       creditAccountHolder: this.invoice.creditAccountHolder,
			//       debitAccountId: this.invoice.debitAccountId,
			//       debitAccountIBAN: this.invoice.debitAccountIBAN,
			//       debitAccountHolder: this.invoice.debitAccountHolder,
			//       currency: this.invoice.currency,
			//       totalGross: this.invoice.totalGross,
			//       discountRate: this.invoice.discountRate,
			//       totalNet: this.invoice.totalNetResult,
			//       vatRate: this.invoice.vatRate,
			//       vatAmount: this.calculateVATAmount(),
			//       totalVat: this.calculateTotalInclVAT(),
			//       paymentReference: this.invoice.paymentReference,
			//       dueDate: this.invoice.dueDate,
			//       invoiceStatus: this.invoice.invoiceStatus,
			//     };
			//     const response = await AuthService.editInvoiceDetails(id, details);
			//     // this.resetForm();
			//     this.msg = response.msg;

			//     console.log(response.msg);
			//   } catch (error) {
			//     this.msg = error.response.data.msg;
			//     //console.log(error);
			//   }
			// },

			resetForm() {
				// this.email = "";

				// this.internalId = "";
				// this.customerReference = "";
				// this.creditAccountId = "";
				// this.creditAccountIBAN = "";
				// this.creditAccountHolder = "";
				// this.debitAccountId = "";
				// this.debitAccountIBAN = "";
				// this.debitAccountHolder = "";
				// this.currency = "EUR";
				// this.totalGross = 0.0;
				// this.discountRate = 0.0;
				// this.totalNet = 0.0;
				// this.vatRate = 21.0;
				// this.vatAmount = 0.0;
				// this.totalVat = 0.0;
				// this.paymentReference = "";
				// this.dueDate = minDate;
				// this.invoiceStatus = 0;
				// this.msg = "";
				// this.totalNetResult = 0.0;
				// this.calculateVATAmount;
				// this.calculateTotalInclVAT;
				requestAnimationFrame(() => {
					this.$refs.observer.reset();
				});
			},
			// async getCountries() {
			//   try {
			//     const response = await AuthService.getCountries();
			//     this.countries = response;
			//     console.log(response);
			//   } catch (error) {
			//     console.log(error);
			//   }
			// },
			// async getClientsCurrentAccounts() {
			//   try {
			//     const details = {
			//       id: this.$store.getters.getUser.id,
			//     };
			//     const response = await AuthService.getClientsCurrentAccounts(
			//       details.id
			//     );
			//     this.accounts = response;
			//     console.log(response);
			//   } catch (error) {
			//     console.log(error);
			//   }
			// },
			// async getInvoiceDetails() {
			//   try {
			//     const id = this.$route.params.id;
			//     const response = await AuthService.getInvoiceDetails(id);
			//     this.invoice = response;
			//     console.log(response);
			//   } catch (error) {
			//     console.log(error);
			//   }
			// },
			async getInvoiceDetailsByReference() {
				try {
					const id = this.$route.params.id;
					const response = await AuthService.getInvoiceDetailsByReference(id);
					this.invoice = response;
					console.log(response);
				} catch (error) {
					console.log(error);
				}
			},
			getRequestParams(searchTitle, page, pageSize) {
				let params = {};

				if (searchTitle) {
					params["title"] = searchTitle;
				}

				if (page) {
					params["page"] = page - 1;
				}

				if (pageSize) {
					params["size"] = pageSize;
				}

				return params;
			},
			async getInvoiceItemsByReference() {
				const params = this.getRequestParams(
					this.searchTitle,
					this.page,
					this.pageSize
				);

				try {
					const details = {
						id: this.$route.params.id,
					};
					this.isloading = true;
					const response = await AuthService.getInvoiceItemsByReference(
						params.page,
						params.size,
						params.title,
						details.id
					);
					const { items, totalItems } = response.data;
					this.items = items;
					this.count = totalItems;
					this.isloading = false;
					console.log(response.data);
				} catch (error) {
					this.isloading = false;
					console.log(error);
				}
			},

			handlePageChange(value) {
				this.page = value;
				this.getInvoiceItemsByReference();
			},

			handlePageSizeChange(event) {
				this.pageSize = event.target.value;
				this.page = 1;
				this.getInvoiceItemsByReference();
			},

			async deleteInvoiceItem(id) {
				try {
					const lang = this.$i18n.locale;
					const response = await AuthService.deleteInvoiceItem(id, lang);
					console.log(response);
					this.getInvoiceItemsByReference();
					this.msg = response.msg;
					console.log(response.msg);
				} catch (error) {
					console.log(error);
					this.msg = error.response.data.msg;
				}
			},
		},
		mounted() {
			// this.getCountries();
			// this.getClientsCurrentAccounts();
			this.getInvoiceDetailsByReference();
			this.getInvoiceItemsByReference();
			//  this.getUserId();
		},
	};
</script>
