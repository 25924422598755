import http from "../http-common";

class ExpenseReceiptUploadService {
  upload(file, lang, txId, onUploadProgress) {
    let formData = new FormData();
    // let details = details;
    formData.append("file", file);

    return http.post(lang + "/expense-upload/" + txId + "/{}", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
    });
  }

  getFiles() {
    return http.get("/files");
  }
}

export default new ExpenseReceiptUploadService();
