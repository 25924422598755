<template>
	<!-- <div v-if="getUserId() === offer.userId"> -->
	<div class="col-12 offset-0">
		<div class="text-left">
			<div class="col-md-8 offset-md-2 ">
				<h2 class="title-bold">
					{{ $t("offerDetails.offer") }} {{ offer.id }}
				</h2>
				<hr />

				<div class="background-modifier">
					<div class="product-details">
						<label class="small">{{ $t("offerDetails.id") }}</label>
						<p class="text-left">
							{{ offer.id }}
						</p>
						<label class="small">{{ $t("offerDetails.merchantId") }}</label>
						<p class="text-left">
							{{ offer.merchantId }}
						</p>
						<label class="small">{{ $t("offerDetails.merchantName") }}</label>
						<p class="text-left">
							{{ offer.merchantName }}
						</p>
						<label class="small">{{ $t("offerDetails.offerName") }}</label>
						<p class="text-left">
							{{ offer.offerName }}
						</p>
						<label class="small">{{
							$t("offerDetails.offerDescription")
						}}</label>
						<p class="text-left">
							{{ offer.offerDescription }}
						</p>
						<!-- <p class="text-left">
            {{ $t("offerDetails.currency") }}: {{ offer.currency }}
          </p> -->
						<label class="small"> {{ $t("offerDetails.discountRate") }}</label>
						<p class="text-left">
							{{
								offer.discountRate != null
									? $n(offer.discountRate, "decimal")
									: $n(0.0, "decimal")
							}}%
						</p>
						<label class="small"> {{ $t("offerDetails.startDate") }}</label>
						<p class="text-left">
							{{ $d(Date.parse(offer.startDate), "short") }}
						</p>
						<label class="small">{{ $t("offerDetails.endDate") }}</label>
						<p class="text-left">
							{{ $d(Date.parse(offer.endDate), "short") }}
						</p>
						<label class="small">{{ $t("offerDetails.created") }}</label>
						<p class="text-left">
							{{ $d(Date.parse(offer.created), "short") }}
						</p>
					</div>
					<hr />
					<div class="text-right">
						<router-link
							:to="{ name: 'manage-offer', params: { id: offer.id } }"
							class="btn btn-outline mx-1 "
							>{{ $t("offerDetails.manage") }}</router-link
						>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- </div>
  <div v-else>
    <h3>{{ $t("unauthorized") }}</h3>
  </div> -->
</template>

<script>
	import AuthService from "@/services/AuthService.js";
	import Vue from "vue";

	export default {
		name: "offer-details",

		data() {
			return {
				offer: "",
				//  nickname: '',

				msg: "",
			};
		},
		async created() {
			if (!this.$store.getters.isLoggedIn) {
				this.$router.push("login");
			}

			if (
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 3 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 15 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 14 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 13 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 12 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 11
			) {
				this.$router.push("overview");
			}
			let lang = this.$i18n.locale;
			this.role = this.$store.getters.getUser.role;
			this.username = this.$store.getters.getUser.username;
			this.firstname = this.$store.getters.getUser.firstname;
			this.getOfferById();

			//  this.getAcctId();
			//   this.updateAccount();
			//  this.getAccountPayments();
			//  this.getCreditAmount();
			//  this.getDebitAmount();
		},
		methods: {
			getOfferId() {
				const offerId = this.$route.params.id;
				return offerId;
			},
			getUserId() {
				const userId = this.$store.getters.getUser.id;
				return userId;
			},

			async getOfferById(id) {
				try {
					const id = this.$route.params.id;
					const response = await AuthService.getOfferById(id);
					this.offer = response;
					console.log(response);
				} catch (error) {
					console.log(error);
				}
			},

			/*  async updateAccount(id) {
        try {
          const id = this.$route.params.id;
         
          const details = {
            nickname: this.card.nickname
          };
          const response = await AuthService.updateAccount(id, details);
          this.msg = response.msg;
          console.log(response.msg);
        } catch (error) {
          console.log(error);
        }
   }, */
			/*   async deletePayment(id) {
          try {
            const response = await AuthService.deletePayment(id);
            console.log(response);
            this.getAccountTransactions();
          } catch (error) {
            console.log(error);
          }
        }, */
		},
		mounted() {
			this.getOfferById();
			this.getUserId();
		},
	};
</script>
