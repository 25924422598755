var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.newExtendedPayment)},"reset":function($event){$event.preventDefault();return reset($event)}}},[_c('div',{staticClass:"col-lg-4 offset-lg-4 text-left"},[_c('h2',{staticClass:"title-bold"},[_vm._v(_vm._s(_vm.$t("newPayment.invoiceSettlement")))]),_c('hr'),_c('h5',{staticClass:"title-bold"},[_vm._v(" "+_vm._s(_vm.$t("newPayment.useThisFormToSettle"))+" ")]),_c('hr'),_c('div',{staticClass:"text-right"},[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"text-right",attrs:{"variant":"outline","title":_vm.$t('newPayment.executeImmediateOrLater')}},[_vm._v(" ⓘ ")])],1),_c('hr'),_c('div',{staticClass:"background-outline"},[(_vm.isloading2)?_c('div',{staticClass:"text-center"},[_c('b-spinner',{staticClass:"ml-auto text-center",staticStyle:{"width":"3rem","height":"3rem"},attrs:{"variant":_vm.secondary}})],1):_vm._e(),_c('div',{staticClass:"background-modifier"},[_c('label',{staticClass:"small"},[_vm._v(" "+_vm._s(_vm.$t("newPayment.startByChoosingDebitAccount"))+" ")]),_c('ValidationProvider',{attrs:{"rules":"required","name":"debitAccountId"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"id":"debitAccountIdInputGroup","label-for":"debitAccountIdInput"}},[_c('b-form-select',{attrs:{"id":"debitAccountIdInput","state":errors[0] ? false : valid ? true : null},on:{"change":_vm.onChange},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":"","disabled":""}},[_vm._v(_vm._s(_vm.$t("newPayment.debitAccountId")))])]},proxy:true}],null,true),model:{value:(_vm.debitAccountId),callback:function ($$v) {_vm.debitAccountId=$$v},expression:"debitAccountId"}},_vm._l((_vm.accounts),function(account){return _c('option',{key:account.id,domProps:{"value":account.id}},[_vm._v("("+_vm._s(account.id)+") "+_vm._s(account.iban.toString().replace(/\w{4}(?=.)/g, "$& "))+" "+_vm._s(_vm.$n(account.credits - account.debits, "decimal"))+" "+_vm._s(account.currency))])}),0),_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('br'),_c('div',{staticClass:"background-modifier"},[_c('label',{staticClass:"small"},[_vm._v(_vm._s(_vm.$t("newPayment.creditAccountId")))]),_c('ValidationProvider',_vm._b({staticClass:"w-full",attrs:{"rules":{ iban: true },"vid":_vm.name,"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"creditAccountIdInput","description":""}},[_c('b-form-input',{attrs:{"type":"text","state":errors[0] ? false : _vm.valid ? true : null,"placeholder":_vm.$t('newPayment.creditAccountId')},model:{value:(_vm.invoice.creditAccountIBAN),callback:function ($$v) {_vm.$set(_vm.invoice, "creditAccountIBAN", $$v)},expression:"invoice.creditAccountIBAN"}}),_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)},'ValidationProvider',_vm.$attrs,false)),_c('label',{staticClass:"small"},[_vm._v(_vm._s(_vm.$t("newPayment.beneficiaryName")))]),_c('ValidationProvider',{attrs:{"rules":"alpha_spaces|min:2|max:50","name":"creditAccountHolder"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"creditAccountHolder","description":""}},[_c('b-form-input',{attrs:{"type":"text","state":errors[0] ? false : valid ? true : null,"placeholder":_vm.$t('newPayment.beneficiaryName')},model:{value:(_vm.invoice.creditAccountHolder),callback:function ($$v) {_vm.$set(_vm.invoice, "creditAccountHolder", $$v)},expression:"invoice.creditAccountHolder"}}),_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('br'),_c('div',{staticClass:"background-modifier"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('label',{staticClass:"small"},[_vm._v(_vm._s(_vm.$t("account.currency")))]),_c('ValidationProvider',{attrs:{"rules":"required","name":"selected"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"id":"sourceCurrencyInputGroup","label-for":_vm.sourceCurrencyInput}},[_c('b-form-select',{attrs:{"id":"sourceCurrencyInput","state":errors[0] ? false : valid ? true : null},on:{"change":_vm.convert},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":"","disabled":""}},[_vm._v(_vm._s(_vm.$t("newPayment.paymentCurrency")))])]},proxy:true}],null,true),model:{value:(_vm.invoice.currency),callback:function ($$v) {_vm.$set(_vm.invoice, "currency", $$v)},expression:"invoice.currency"}},_vm._l((_vm.currencies),function(currency){return _c('option',{key:currency.CharCode,domProps:{"value":currency.CharCode}},[_vm._v(_vm._s(currency.CharCode))])}),0),_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('div',{staticClass:"col-md-8"},[_c('label',{staticClass:"small"},[_vm._v(_vm._s(_vm.$t("newPayment.amount")))]),_c('ValidationProvider',{attrs:{"rules":"required|double|min_value:0.01","name":"amount"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"amountInput","description":""}},[_c('b-form-input',{staticClass:"custom-amount",attrs:{"type":"text","state":errors[0] ? false : valid ? true : null,"placeholder":_vm.$t('newPayment.amount')},on:{"input":_vm.convert},model:{value:(_vm.invoice.totalVat),callback:function ($$v) {_vm.$set(_vm.invoice, "totalVat", $$v)},expression:"invoice.totalVat"}}),_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)]),(_vm.selected[0] !== _vm.accountCurrency.currency)?_c('div',[_c('div',{staticClass:"col-md-12 text-right"},[_c('p',{staticClass:"small",attrs:{"type":"number","readonly":""},model:{value:(_vm.result),callback:function ($$v) {_vm.result=$$v},expression:"result"}},[_vm._v(" "+_vm._s(_vm.result !== "undefined" ? _vm.accountCurrency.currency : "")+" "+_vm._s(_vm.result ? _vm.$n(_vm.result.toFixed(2), "decimal") : "")+" ")])])]):_vm._e()]),_c('br'),_c('div',{staticClass:"background-modifier"},[_c('label',{staticClass:"small"},[_vm._v(_vm._s(_vm.$t("newPayment.message")))]),_c('ValidationProvider',{attrs:{"rules":"min:1|max:50","name":"message"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"messageInput","description":""}},[_c('b-form-input',{attrs:{"type":"text","state":errors[0] ? false : valid ? true : null,"placeholder":_vm.$t('newPayment.message')},model:{value:(_vm.invoice.paymentReference),callback:function ($$v) {_vm.$set(_vm.invoice, "paymentReference", $$v)},expression:"invoice.paymentReference"}}),_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('br'),_c('div',{staticClass:"background-modifier"},[_c('label',{staticClass:"small"},[_vm._v(_vm._s(_vm.$t("newPayment.dueDate")))]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 offset-md-0"},[_c('label',{attrs:{"for":""}},[_vm._v(_vm._s(_vm.$t("newPayment.dueDate")))]),_c('b-form-datepicker',{attrs:{"min":_vm.min,"locale":_vm.$i18n.locale,"label-no-date-selected":_vm.$t('newPayment.noDateSelected'),"label-help":_vm.$t('newPayment.useCursorKeysToNavigateCalendarDates')},model:{value:(_vm.invoice.dueDate),callback:function ($$v) {_vm.$set(_vm.invoice, "dueDate", $$v)},expression:"invoice.dueDate"}})],1)]),_c('br')]),_c('br'),_c('hr'),(_vm.debitAccountId > 0)?_c('div',[(
								_vm.debitAccountId != _vm.creditAccountId &&
									_vm.debitAccountId != _vm.creditAccountId2
							)?_c('div',[(_vm.creditAmount.credits - _vm.debitAmount.debits - _vm.result >= 0)?_c('div',[(_vm.role == 3)?_c('div',[_c('b-button',{staticClass:"btn btn-info text-light btn-block",attrs:{"type":"submit"},on:{"click":_vm.isloading}},[_vm._v(_vm._s(_vm.$t("newPayment.submit"))),(_vm.isloading)?_c('b-spinner',{staticClass:"ml-auto",attrs:{"variant":_vm.light}}):_vm._e()],1)],1):_vm._e(),(_vm.role == 12)?_c('div',[_c('b-button',{staticClass:"btn btn-info text-light btn-block",attrs:{"type":"submit"},on:{"click":_vm.isloading}},[_vm._v(_vm._s(_vm.$t("newPayment.submitForApproval"))),(_vm.isloading)?_c('b-spinner',{staticClass:"ml-auto",attrs:{"variant":_vm.light}}):_vm._e()],1)],1):_vm._e()]):(
									_vm.result - (_vm.creditAmount.credits - _vm.debitAmount.debits) > 0
								)?_c('div',{},[_c('b-alert',{attrs:{"variant":"danger","show":""}},[_vm._v(_vm._s(_vm.$t("newPayment.insufficientBalance")))])],1):_vm._e()]):_c('div',{},[_c('b-alert',{attrs:{"variant":"danger","show":""}},[_vm._v(_vm._s(_vm.$t("newPayment.accountsMustBeDifferent")))])],1),_c('hr'),_c('b-button',{staticClass:"btn btn-alert text-light btn-block",attrs:{"type":"reset"},on:{"click":function($event){return _vm.resetForm()}}},[_vm._v(_vm._s(_vm.$t("reset")))])],1):_vm._e(),(_vm.msg && _vm.$bvToast.show('notification-toast'))?_c('div'):_vm._e(),_c('div',{staticClass:"action"},[_c('br'),_c('b-toast',{attrs:{"id":"notification-toast","variant":"secondary","static":"","no-auto-hide":""},scopedSlots:_vm._u([{key:"toast-title",fn:function(){return [_c('div',{staticClass:"d-flex flex-grow-1 align-items-baseline"},[_c('b-img',{staticClass:"logo mb-2",attrs:{"src":require('/public/images/logo.png'),"alt":"","width":"12","height":"12"}}),_c('strong',{staticClass:"mr-auto"},[_vm._v(_vm._s(_vm.$t("notification")))]),_c('small',{staticClass:"text-muted mr-2"})],1)]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(_vm.msg)+" ")])],1)])])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }