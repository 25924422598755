<template>
	<div v-if="getUserId() === standingOrder.userId">
		<div class="col-lg-4 offset-lg-4 text-left">
			<div class="">
				<h2 class="title-bold">
					{{ $t("standingOrder.standingOrder") }} {{ standingOrder.alias }}
				</h2>
				<hr />
				<div class="background-modifier">
					<div class="product-details">
						<label class="small">{{ $t("standingOrder.id") }}</label>
						<p class="text-left">
							{{ standingOrder.id }}
						</p>
						<label class="small">{{
							$t("standingOrder.debitAccountId")
						}}</label>
						<p class="text-left">
							{{ standingOrder.debitAccountId }}
						</p>
						<label class="small">{{
							$t("standingOrder.creditAccountId")
						}}</label>
						<p class="text-left">
							{{ standingOrder.creditAccountId }}
						</p>
						<label class="small">{{
							$t("standingOrder.beneficiaryName")
						}}</label>
						<p class="text-left">
							{{ standingOrder.creditAccountHolder }}
						</p>
						<label class="small">{{
							$t("standingOrder.accountCurrency")
						}}</label>
						<p class="text-left">
							{{ standingOrder.baseCurrency }}
						</p>
						<label class="small">{{ $t("standingOrder.amount") }}</label>
						<p class="text-left">
							{{ $n(standingOrder.amount, "decimal") }}
						</p>
						<label class="small">{{
							$t("standingOrder.paymentCurrency")
						}}</label>
						<p class="text-left">
							{{ standingOrder.paymentCurrency }}
						</p>
						<label class="small">{{
							$t("standingOrder.currencyAmount")
						}}</label>
						<p class="text-left">
							{{ $n(standingOrder.currencyAmount, "decimal") }}
						</p>
						<label class="small">{{ $t("standingOrder.message") }}</label>
						<p class="text-left">
							{{ standingOrder.message }}
						</p>
						<label class="small">{{ $t("standingOrder.frequency") }}</label>
						<p class="text-left">
							{{ standingOrder.frequency }}
						</p>
						<label class="small">{{ $t("standingOrder.startDate") }}</label>
						<p class="text-left">
							{{ $d(Date.parse(standingOrder.startDate), "long") }}
						</p>
						<label class="small">{{ $t("standingOrder.endDate") }}</label>
						<p class="text-left">
							{{ $d(Date.parse(standingOrder.endDate), "long") }}
						</p>
						<label class="small">{{ $t("standingOrder.created") }}</label>
						<p class="text-left">
							{{ $d(Date.parse(standingOrder.created), "long") }}
						</p>
						<div class="text-right">
							<router-link
								:to="{
									name: 'edit-standingorder',
									params: { id: standingOrder.id },
								}"
								class="btn btn-outline mx-1 "
								>{{ $t("standingOrder.edit") }}</router-link
							>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div v-else>
		<h3>{{ $t("unauthorized") }}</h3>
	</div>
</template>

<script>
	import AuthService from "@/services/AuthService.js";

	export default {
		name: "StandingOrder",
		data() {
			return {
				standingOrder: "",
			};
		},
		async created() {
			if (!this.$store.getters.isLoggedIn) {
				this.$router.push("login");
			}
		},
		methods: {
			getUserId() {
				const userId = this.$store.getters.getUser.id;
				return userId;
			},
			async getStandingOrder(id) {
				try {
					const id = this.$route.params.id;
					const response = await AuthService.getStandingOrder(id);
					this.standingOrder = response;
					console.log(response);
				} catch (error) {
					console.log(error);
				}
			},
		},
		mounted() {
			this.getUserId();
			this.getStandingOrder();
		},
	};
</script>
