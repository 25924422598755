<template>
	<div>
		<b-navbar toggleable="md" type="dark" variant="dark" fixed="top">
			<b-navbar-brand
				class="logoname"
				:to="{ path: '/' + $i18n.locale + '/home' }"
			>
				<img src="/images/triangular_logo_light.png" width="25" />
				<!-- <img src="/images/logo.png" width="25" /> -->
				<!-- {{ $t("brandName") }} -->
			</b-navbar-brand>

			<b-navbar-toggle target="nav-collapse">
				<template #default="{ expanded }">
					<div v-if="expanded" class="main-item menu close">
						<span class="line line01 "></span>
						<span class="line line02 "></span>
						<span class="line line03 "></span>
					</div>
					<div class="main-item menu" v-else>
						<span class="line line01"></span>
						<span class="line line02"></span>
						<span class="line line03"></span>
					</div>
				</template>
			</b-navbar-toggle>
			<!--   <b-navbar-toggle target="nav-collapse">
        <template #default="{ expanded }">
          <b-icon v-if="expanded" icon="chevron-bar-up"></b-icon>
          <b-icon v-else icon="chevron-bar-down"></b-icon>
        </template>
      </b-navbar-toggle> -->

			<b-collapse id="nav-collapse" is-nav>
				<b-navbar-nav>
					<b-nav-item
						:to="{ path: '/' + $i18n.locale + '/about' }"
						data-cy="nav-bar-about"
						>{{ $t("nav.about") }}</b-nav-item
					>
					<b-nav-item
						:to="{ path: '/' + $i18n.locale + '/enterprises' }"
						data-cy="nav-bar-about"
						>{{ $t("nav.merchantBanking") }}</b-nav-item
					>
					<b-nav-item-dropdown
						v-if="this.$store.getters.isLoggedIn"
						data-cy="nav-bar-products"
						:text="$t('nav.products')"
					>
						<b-dropdown-item
							v-if="this.$store.getters.isLoggedIn"
							:to="{ path: '/' + $i18n.locale + '/products' }"
							data-cy="nav-bar-products"
							>{{ $t("nav.products") }}</b-dropdown-item
						>
						<b-dropdown-item
							v-if="this.$store.getters.isLoggedIn"
							:to="{ path: '/' + $i18n.locale + '/accounts-page' }"
							data-cy="nav-bar-accounts-page"
							>{{ $t("nav.accounts") }}</b-dropdown-item
						>
						<b-dropdown-item
							v-if="this.$store.getters.isLoggedIn"
							:to="{ path: '/' + $i18n.locale + '/payments-page' }"
							data-cy="nav-bar-payments-page"
							>{{ $t("nav.payments") }}</b-dropdown-item
						>
						<b-dropdown-item
							v-if="this.$store.getters.isLoggedIn"
							:to="{ path: '/' + $i18n.locale + '/cards-page' }"
							data-cy="nav-bar-cards-page"
							>{{ $t("nav.cards") }}</b-dropdown-item
						>
						<b-dropdown-item
							v-if="this.$store.getters.isLoggedIn"
							:to="{ path: '/' + $i18n.locale + '/savings-page' }"
							data-cy="nav-bar-savings-page"
							>{{ $t("nav.savings") }}</b-dropdown-item
						>
						<b-dropdown-item
							v-if="this.$store.getters.isLoggedIn"
							:to="{ path: '/' + $i18n.locale + '/loans-page' }"
							data-cy="nav-bar-loans-page"
							>{{ $t("nav.loans") }}</b-dropdown-item
						>
						<b-dropdown-item
							v-if="this.$store.getters.isLoggedIn"
							:to="{ path: '/' + $i18n.locale + '/points-page' }"
							data-cy="nav-bar-points-page"
							>{{ $t("nav.points") }}</b-dropdown-item
						>
						<b-dropdown-item
							v-if="this.$store.getters.isLoggedIn"
							:to="{ path: '/' + $i18n.locale + '/business/business-home' }"
							data-cy="nav-bar-points-page"
							>{{ $t("nav.business") }}</b-dropdown-item
						>
					</b-nav-item-dropdown>
					<b-nav-item
						v-if="this.$store.getters.isLoggedIn"
						:to="{ path: '/' + $i18n.locale + '/blog' }"
						data-cy="nav-bar-blog"
						>{{ $t("nav.blog") }}</b-nav-item
					>
					<b-nav-item
						v-if="this.$store.getters.isLoggedIn"
						:to="{ path: '/' + $i18n.locale + '/careers' }"
						data-cy="nav-bar-careers"
						>{{ $t("nav.careers") }}</b-nav-item
					>
					<b-nav-item
						v-if="this.$store.getters.isLoggedIn"
						:to="{ path: '/' + $i18n.locale + '/overview' }"
						data-cy="nav-bar-overview"
						>{{ $t("nav.overview") }}</b-nav-item
					>
					<b-nav-item
						v-if="this.$store.getters.isLoggedIn"
						:to="{ path: '/' + $i18n.locale + '/accounts' }"
						data-cy="nav-bar-user-accounts"
						>{{ $t("nav.accounts") }}</b-nav-item
					>
					<b-nav-item
						v-if="this.$store.getters.isLoggedIn"
						:to="{ path: '/' + $i18n.locale + '/cards' }"
						data-cy="nav-bar-user-cards"
						>{{ $t("nav.cards") }}</b-nav-item
					>
					<b-nav-item-dropdown
						v-if="this.$store.getters.isLoggedIn"
						data-cy="nav-bar-payments"
						:text="$t('nav.payments')"
					>
						<b-dropdown-item
							v-if="this.$store.getters.isLoggedIn"
							:to="{ path: '/' + $i18n.locale + '/nip' }"
							data-cy="nav-bar-nip"
							>{{ $t("nav.nip") }}</b-dropdown-item
						>
						<b-dropdown-item
							v-if="this.$store.getters.isLoggedIn"
							:to="{ path: '/' + $i18n.locale + '/generate-qr-code' }"
							data-cy="nav-bar-generate-qr-code"
							>{{ $t("nav.generateQRCode") }}</b-dropdown-item
						>
						<b-dropdown-item
							v-if="this.$store.getters.isLoggedIn"
							:to="{ path: '/' + $i18n.locale + '/new-payment' }"
							data-cy="nav-bar-new-payment"
							>{{ $t("nav.newPayment") }}</b-dropdown-item
						>
						<b-dropdown-item
							v-if="this.$store.getters.isLoggedIn"
							:to="{ path: '/' + $i18n.locale + '/paymentorders' }"
							data-cy="nav-bar-payment-order-list"
							>{{ $t("nav.paymentOrders") }}</b-dropdown-item
						>
						<b-dropdown-item
							v-if="this.$store.getters.isLoggedIn"
							:to="{ path: '/' + $i18n.locale + '/standingorders' }"
							data-cy="nav-bar-standing-order-list"
							>{{ $t("nav.standingOrders") }}</b-dropdown-item
						>
						<b-dropdown-item
							v-if="this.$store.getters.isLoggedIn"
							:to="{ path: '/' + $i18n.locale + '/beneficiaries' }"
							data-cy="nav-bar-beneficiary-list"
							>{{ $t("nav.beneficiaries") }}</b-dropdown-item
						>
						<b-dropdown-item
							v-if="this.$store.getters.isLoggedIn"
							:to="{ path: '/' + $i18n.locale + '/claim-nip' }"
							data-cy="nav-bar-claim-nip"
							>{{ $t("nav.claimNip") }}</b-dropdown-item
						>
					</b-nav-item-dropdown>
					<b-nav-item-dropdown
						v-if="this.$store.getters.isLoggedIn"
						data-cy="nav-bar-savings"
						:text="$t('nav.savings')"
					>
						<b-dropdown-item
							v-if="this.$store.getters.isLoggedIn"
							:to="{ path: '/' + $i18n.locale + '/open-fixed-term-deposit' }"
							data-cy="nav-bar-new-ftd"
							>{{ $t("nav.newFixedTermDeposit") }}</b-dropdown-item
						>
						<b-dropdown-item
							v-if="this.$store.getters.isLoggedIn"
							:to="{ path: '/' + $i18n.locale + '/fixed-term-deposits' }"
							data-cy="nav-bar-ftd"
							>{{ $t("nav.fixedTermDeposits") }}</b-dropdown-item
						>
					</b-nav-item-dropdown>
				</b-navbar-nav>

				<!-- Right aligned nav items -->

				<b-navbar-nav class="ml-auto" v-if="!this.$store.getters.isLoggedIn">
					<b-nav-item
						:to="{ path: '/' + $i18n.locale + '/login' }"
						data-cy="nav-bar-login"
						link-classes="btn mx-1 text-light"
						>{{ $t("nav.login") }}</b-nav-item
					>
					<!-- <b-nav-item
						:to="{ path: '/' + $i18n.locale + '/registration-type' }"
						data-cy="nav-bar-register"
						link-classes="btn text-light mx-1 "
						>{{ $t("nav.signup") }}</b-nav-item
					> -->
					<!-- :to="{ path: '/' + $i18n.locale + '/sign-up/0' }" -->
				</b-navbar-nav>

				<b-navbar-nav class="ml-auto" v-if="this.$store.getters.isLoggedIn">
					<div class="">
						<b-nav-item-dropdown
							v-if="
								this.$store.getters.getUser.role === 3 ||
									this.$store.getters.getUser.role === 15 ||
									this.$store.getters.getUser.role === 14 ||
									this.$store.getters.getUser.role === 13 ||
									this.$store.getters.getUser.role === 12 ||
									this.$store.getters.getUser.role === 11
							"
							data-cy="nav-bar-business"
							:text="$t('nav.business')"
							class="nav-dropdown-right"
							link-classes="nav-dropdown-right"
							right
						>
							<b-dropdown-item
								v-if="
									this.$store.getters.getUser.role === 3 ||
										this.$store.getters.getUser.role === 15 ||
										this.$store.getters.getUser.role === 14 ||
										this.$store.getters.getUser.role === 13
								"
								:to="{ path: '/' + $i18n.locale + '/business/dashboard' }"
								data-cy="nav-bar-dashboard"
								>{{ $t("nav.dashboard") }}</b-dropdown-item
							>
							<b-dropdown-item
								v-if="
									this.$store.getters.getUser.role === 3 ||
										this.$store.getters.getUser.role === 15 ||
										this.$store.getters.getUser.role === 14
								"
								:to="{ path: '/' + $i18n.locale + '/business/user-management' }"
								data-cy="nav-bar-user-management"
								>{{ $t("nav.userManagement") }}</b-dropdown-item
							>
							<b-dropdown-item
								v-if="
									this.$store.getters.getUser.role === 3 ||
										this.$store.getters.getUser.role === 15 ||
										this.$store.getters.getUser.role === 14 ||
										this.$store.getters.getUser.role === 13 ||
										this.$store.getters.getUser.role === 12 ||
										this.$store.getters.getUser.role === 11
								"
								:to="{
									path: '/' + $i18n.locale + '/business/account-management',
								}"
								data-cy="nav-bar-account-management"
								>{{ $t("nav.accountManagement") }}</b-dropdown-item
							>
							<b-dropdown-item
								v-if="
									this.$store.getters.getUser.role === 3 ||
										this.$store.getters.getUser.role === 15 ||
										this.$store.getters.getUser.role === 14 ||
										this.$store.getters.getUser.role === 13 ||
										this.$store.getters.getUser.role === 12
								"
								:to="{
									path: '/' + $i18n.locale + '/business/payment-management',
								}"
								data-cy="nav-bar-payment-authorization"
								>{{ $t("nav.paymentAuthorization") }}</b-dropdown-item
							>
							<b-dropdown-item
								v-if="
									this.$store.getters.getUser.role === 3 ||
										this.$store.getters.getUser.role === 15 ||
										this.$store.getters.getUser.role === 14 ||
										this.$store.getters.getUser.role === 13 ||
										this.$store.getters.getUser.role === 12 ||
										this.$store.getters.getUser.role === 11
								"
								:to="{
									path: '/' + $i18n.locale + '/business/payables-management',
								}"
								data-cy="nav-bar-payables-management"
								>{{ $t("nav.payablesManagement") }}</b-dropdown-item
							>
							<b-dropdown-item
								v-if="
									this.$store.getters.getUser.role === 3 ||
										this.$store.getters.getUser.role === 15 ||
										this.$store.getters.getUser.role === 14 ||
										this.$store.getters.getUser.role === 13 ||
										this.$store.getters.getUser.role === 12 ||
										this.$store.getters.getUser.role === 11
								"
								:to="{
									path: '/' + $i18n.locale + '/business/receivables-management',
								}"
								data-cy="nav-bar-receivables-management"
								>{{ $t("nav.receivablesManagement") }}</b-dropdown-item
							>
							<b-dropdown-item
								v-if="
									this.$store.getters.getUser.role === 3 ||
										this.$store.getters.getUser.role === 15 ||
										this.$store.getters.getUser.role === 14 ||
										this.$store.getters.getUser.role === 13 ||
										this.$store.getters.getUser.role === 12 ||
										this.$store.getters.getUser.role === 11
								"
								:to="{ path: '/' + $i18n.locale + '/business/card-management' }"
								data-cy="nav-bar-card-management"
								>{{ $t("nav.cardManagement") }}</b-dropdown-item
							>
							<b-dropdown-item
								v-if="
									this.$store.getters.getUser.role === 3 ||
										this.$store.getters.getUser.role === 15 ||
										this.$store.getters.getUser.role === 14 ||
										this.$store.getters.getUser.role === 13 ||
										this.$store.getters.getUser.role === 12 ||
										this.$store.getters.getUser.role === 11
								"
								:to="{
									path: '/' + $i18n.locale + '/business/offer-management',
								}"
								data-cy="nav-bar-offer-management"
								>{{ $t("nav.offerManagement") }}</b-dropdown-item
							>
							<b-dropdown-item
								v-if="
									this.$store.getters.getUser.role === 3 ||
										this.$store.getters.getUser.role === 15 ||
										this.$store.getters.getUser.role === 14 ||
										this.$store.getters.getUser.role === 13 ||
										this.$store.getters.getUser.role === 12
								"
								:to="{
									path: '/' + $i18n.locale + '/business/einvoice-management',
								}"
								data-cy="nav-bar-einvoice-management"
								>{{ $t("nav.eInvoiceManagement") }}</b-dropdown-item
							>
							<b-dropdown-item
								v-if="
									this.$store.getters.getUser.role === 3 ||
										this.$store.getters.getUser.role === 15 ||
										this.$store.getters.getUser.role === 14 ||
										this.$store.getters.getUser.role === 13 ||
										this.$store.getters.getUser.role === 12
								"
								:to="{
									path:
										'/' + $i18n.locale + '/business/direct-debit-management',
								}"
								data-cy="nav-bar-direct-debit-management"
								>{{ $t("nav.directDebitManagement") }}</b-dropdown-item
							>
							<b-dropdown-item
								v-if="
									this.$store.getters.getUser.role === 3 ||
										this.$store.getters.getUser.role === 15 ||
										this.$store.getters.getUser.role === 14 ||
										this.$store.getters.getUser.role === 13 ||
										this.$store.getters.getUser.role === 12
								"
								:to="{
									path: '/' + $i18n.locale + '/business/payment-data-upload',
								}"
								data-cy="nav-bar-payment-data-upload"
								>{{ $t("nav.paymentDataUpload") }}</b-dropdown-item
							>
							<b-dropdown-item
								v-if="
									this.$store.getters.getUser.role === 3 ||
										this.$store.getters.getUser.role === 15 ||
										this.$store.getters.getUser.role === 14 ||
										this.$store.getters.getUser.role === 13 ||
										this.$store.getters.getUser.role === 12
								"
								:to="{
									path: '/' + $i18n.locale + '/business/payroll-data-upload',
								}"
								data-cy="nav-bar-payroll-data-upload"
								>{{ $t("nav.payrollDataUpload") }}</b-dropdown-item
							>
							<b-dropdown-item
								v-if="
									this.$store.getters.getUser.role === 3 ||
										this.$store.getters.getUser.role === 15 ||
										this.$store.getters.getUser.role === 14 ||
										this.$store.getters.getUser.role === 13 ||
										this.$store.getters.getUser.role === 12 ||
										this.$store.getters.getUser.role === 11
								"
								:to="{
									path: '/' + $i18n.locale + '/business/accountant-view',
								}"
								data-cy="nav-bar-accountant-view"
								>{{ $t("nav.accountantView") }}</b-dropdown-item
							>
						</b-nav-item-dropdown>
					</div>
					<div class="">
						<b-nav-item-dropdown
							v-if="this.$store.getters.getUser.role === 3"
							data-cy="nav-bar-admin"
							:text="$t('system')"
							class="nav-dropdown-right"
							link-classes=""
							right
						>
							<b-dropdown-item
								:to="{ path: '/' + $i18n.locale + '/admin/dashboard' }"
								data-cy="nav-bar-dashboard"
								>{{ $t("nav.dashboard") }}</b-dropdown-item
							>
							<b-dropdown-item
								:to="{ path: '/' + $i18n.locale + '/admin/applications' }"
								data-cy="nav-bar-applications"
								>{{ $t("nav.applications") }}</b-dropdown-item
							>
							<b-dropdown-item
								:to="{ path: '/' + $i18n.locale + '/admin/client-list' }"
								data-cy="nav-bar-clients"
								>{{ $t("nav.clients") }}</b-dropdown-item
							>
							<b-dropdown-item
								:to="{ path: '/' + $i18n.locale + '/admin/user-list' }"
								data-cy="nav-bar-users"
								>{{ $t("nav.users") }}</b-dropdown-item
							>
							<b-dropdown-item
								:to="{ path: '/' + $i18n.locale + '/admin/account-list' }"
								data-cy="nav-bar-accounts"
								>{{ $t("nav.accounts") }}</b-dropdown-item
							>
							<b-dropdown-item
								:to="{ path: '/' + $i18n.locale + '/admin/transaction-list' }"
								data-cy="nav-bar-transactions"
								>{{ $t("nav.transactions") }}</b-dropdown-item
							>
							<b-dropdown-item
								:to="{ path: '/' + $i18n.locale + '/admin/document-upload' }"
								data-cy="nav-bar-document-upload"
								>{{ $t("nav.fileUpload") }}</b-dropdown-item
							>
							<b-dropdown-item
								:to="{ path: '/' + $i18n.locale + '/introduction' }"
								data-cy="nav-bar-introduction"
								>{{ $t("introduction.title") }}</b-dropdown-item
							>

							<b-dropdown-item
								:to="{ path: '/' + $i18n.locale + '/revolution' }"
								data-cy="nav-bar-revolution"
								>{{ $t("revolution.title") }}</b-dropdown-item
							>
							<b-dropdown-item
								:to="{ path: '/' + $i18n.locale + '/consortium' }"
								data-cy="nav-bar-consortium"
								>{{ $t("consortium.title") }}</b-dropdown-item
							>
							<b-dropdown-item
								:to="{ path: '/' + $i18n.locale + '/consumer-products' }"
								data-cy="nav-bar-consumer-products"
								>{{ $t("consumerProducts.products") }}</b-dropdown-item
							>
						</b-nav-item-dropdown>
					</div>

					<b-nav-item
						data-cy="nav-bar-profile"
						:to="{ path: '/' + $i18n.locale + '/profile' }"
						link-classes="nav-right circle-small"
						>{{ this.$store.getters.getUser.firstname.charAt()
						}}{{ this.$store.getters.getUser.lastname.charAt() }}
					</b-nav-item>
					<b-nav-item
						@click="logout"
						data-cy="nav-bar-logout"
						link-classes="btn mx-1"
						>{{ $t("nav.logout") }}</b-nav-item
					>
				</b-navbar-nav>
			</b-collapse>
		</b-navbar>
	</div>
</template>

<script>
	import AuthService from "@/services/AuthService.js";

	export default {
		name: "Nav",
		methods: {
			logout() {
				this.$store.dispatch("logout");
				this.$router.push({ name: "login" });
			},
		},
		data() {
			return {
				username: "",
				role: "",
			};
		},
		async created() {
			this.username = this.$store.getters.getUser.username;
			this.firstname = this.$store.getters.getUser.firstname;
			this.role = this.$store.getters.getUser.role;
		},
	};
</script>
