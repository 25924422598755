<template>
	<div class="col-12 offset-0">
		<div class="text-left">
			<div class="col-md-8 offset-md-2">
				<h1 class="title-bold">{{ $t("businessDashboard.dashboard") }}</h1>
				<hr />
				<div class="background-outline">
					<div class="background-modifier">
						<div class="background-pink">
							<div class="row">
								<div class="col-md-6 text-left">
									<div class="text-left">
										<label class="small">{{
											$t("businessDashboard.client")
										}}</label>
										<h5 class="">
											{{ businessDetails.name }} ({{ businessDetails.id }})
										</h5>
									</div>
								</div>
								<div class="col-md-6 text-right">
									<b-avatar variant="secondary" size="3rem">
										{{ businessDetails.name.charAt() }}
									</b-avatar>
									<div class="text-right"></div>
								</div>
							</div>
							<hr />
							<div class="">
								<h5>{{ $t("hello") }} {{ firstname }}</h5>
								<h5>
									{{ $t("businessDashboard.itIs") }}
									{{ $d(new Date(), "long") }}
								</h5>
							</div>
						</div>
					</div>
				</div>

				<h3 class="title-bold">
					{{ $t("businessDashboard.cashFlowAnalysis") }}
				</h3>

				<hr />
				<div v-if="isloading2" class="text-center">
					<div class="background-modifier">
						<b-spinner
							style="width: 3rem; height: 3rem;"
							class="ml-auto text-center"
							:variant="secondary"
						></b-spinner>
					</div>
				</div>
				<div v-if="!isloading2" class="background-outline">
					<div class="background-modifier">
						<span>
							<h2 class="text-bold">
								{{
									totalBalance() != null
										? $n(totalBalance(), "decimal")
										: $n(0.0, "decimal")
								}}
							</h2>
							<p class="small">
								{{ $t("accounts.totalBalance") }} ({{ this.baseCurrency }})
							</p>
						</span>
					</div>
					<hr />
					<p class="small text-right">
						{{ $t("businessDashboard.past30Days") }}
					</p>
					<div class="row">
						<div class="col-md-6 text-left ">
							<div class="background-modifier">
								<h4 class="title-bold text-left">
									{{ $t("businessDashboard.runningBalance") }}
									{{ $n(chartData.slice(-1)[0], "decimal") }}
								</h4>
								<p class="small text-left">
									{{ $t("businessDashboard.yourTotalRunningBalance") }}
								</p>
								<div class="container">
									<trend
										v-if="loaded"
										:data="chartData"
										:gradientDirection="left"
										:gradient="['#808080', '#d6d6d6', '#2c3e50']"
										:padding="7.8"
										:radius="9.2"
										:stroke-width="2.0"
										:stroke-linecap="round"
										auto-draw
										smooth
									>
									</trend>
								</div>
							</div>
						</div>
						<div class="col-md-6 text-right">
							<div class="background-modifier">
								<h4 class="title-bold text-left">
									{{ $t("businessDashboard.netCashFlow") }}
									{{ $n(chartDataCF.slice(-1)[0], "decimal") }}
								</h4>
								<p class="small text-left">
									{{ $t("businessDashboard.yourNetCashFlow") }}
								</p>

								<div class="container">
									<trend
										v-if="loadedCF"
										:data="chartDataCF"
										:gradientDirection="left"
										:gradient="['#33a0c0', '#d6d6d6', '#69BED7']"
										:padding="7.8"
										:radius="9.2"
										:stroke-width="2.0"
										:stroke-linecap="round"
										auto-draw
										smooth
									>
									</trend>
								</div>
							</div>
						</div>
					</div>
					<br />
					<div class="row">
						<div class="col-md-6 text-left">
							<div class="background-modifier">
								<h4 class="title-bold text-left">
									{{ $t("businessDashboard.credits") }}
									{{ $n(chartDataCredits.slice(-1)[0], "decimal") }}
								</h4>
								<p class="small text-left">
									{{ $t("businessDashboard.yourCredits") }}
								</p>
								<div class="container">
									<trend
										v-if="loadedCredits"
										:data="chartDataCredits"
										:gradientDirection="left"
										:gradient="['#66cc00', '#d6d6d6', '#00cc00']"
										:padding="7.8"
										:radius="9.2"
										:stroke-width="2.0"
										:stroke-linecap="round"
										auto-draw
										smooth
									>
									</trend>
								</div>
							</div>
						</div>
						<div class="col-md-6 text-right ">
							<div class="background-modifier">
								<h4 class="title-bold text-left">
									{{ $t("businessDashboard.debits") }}
									{{ $n(chartDataDebits.slice(-1)[0], "decimal") }}
								</h4>
								<p class="small text-left">
									{{ $t("businessDashboard.yourDebits") }}
								</p>
								<div class="container">
									<trend
										v-if="loadedDebits"
										:data="chartDataDebits"
										:gradientDirection="left"
										:gradient="['#dd0000', '#d6d6d6', '#ff0000']"
										:padding="7.8"
										:radius="9.2"
										:stroke-width="2.0"
										:stroke-linecap="round"
										auto-draw
										smooth
									>
									</trend>
								</div>
							</div>
						</div>
					</div>
				</div>

				<h3 class="title-bold ">{{ $t("businessDashboard.yourApps") }}</h3>
				<hr />
				<div class="background-outline">
					<div class="box">
						<div class="row text-center">
							<router-link
								:to="{ name: 'user-management' }"
								class=""
								tag="text-grey"
							>
								<div class="item background-modifier icon">
									<!-- <font-awesome-icon class="icon" icon="user-circle" size="3x" /> -->
									<img src="/images/triangular_logo_grey.png" width="35" />
									<h5 class="title-bold text-center">
										{{ $t("nav.users") }}
									</h5>
								</div>
							</router-link>
							<router-link
								:to="{ name: 'account-management' }"
								class=""
								tag="text-grey"
							>
								<div class="item background-modifier icon">
									<!-- <font-awesome-icon class="icon" icon="briefcase" size="3x" /> -->
									<img src="/images/triangular_logo_grey.png" width="35" />
									<h5 class="title-bold text-center">
										{{ $t("nav.accounts") }}
									</h5>
								</div>
							</router-link>
							<router-link
								:to="{ name: 'business-savings-accounts' }"
								class=""
								tag="text-grey"
							>
								<div class="item background-modifier icon">
									<!-- <font-awesome-icon class="icon" icon="piggy-bank" size="3x" /> -->
									<img src="/images/triangular_logo_grey.png" width="35" />
									<h5 class="title-bold text-center">
										{{ $t("nav.savingsAccounts") }}
									</h5>
								</div>
							</router-link>
							<router-link
								:to="{ name: 'business-term-accounts' }"
								class=""
								tag="text-grey"
							>
								<div class="item background-modifier icon">
									<!-- <font-awesome-icon class="icon" icon="percentage" size="3x" /> -->
									<img src="/images/triangular_logo_grey.png" width="35" />
									<h5 class="title-bold text-center">
										{{ $t("nav.termAccounts") }}
									</h5>
								</div>
							</router-link>

							<router-link
								:to="{ name: 'extended-payment' }"
								class=""
								tag="text-grey"
							>
								<div class="item background-modifier icon">
									<!-- <font-awesome-icon class="icon" icon="paper-plane" size="3x" /> -->
									<img src="/images/triangular_logo_grey.png" width="35" />
									<h5 class="title-bold text-center">
										{{ $t("nav.payments") }}
									</h5>
								</div>
							</router-link>

							<router-link
								:to="{ name: 'business-nip' }"
								class=""
								tag="text-grey"
							>
								<div class="item background-modifier icon">
									<!-- <font-awesome-icon class="icon" icon="hand-sparkles" size="3x" /> -->
									<img src="/images/triangular_logo_grey.png" width="35" />
									<h5 class="title-bold text-center">{{ $t("nav.nip") }}</h5>
								</div>
							</router-link>

							<router-link :to="{ name: 'qr-code' }" class="" tag="text-grey">
								<div class="item background-modifier icon">
									<!-- <font-awesome-icon class="icon" icon="qrcode" size="3x" /> -->
									<img src="/images/triangular_logo_grey.png" width="35" />
									<h5 class="title-bold text-center">
										{{ $t("nav.qrCode") }}
									</h5>
								</div>
							</router-link>

							<router-link
								:to="{ name: 'card-management' }"
								class=""
								tag="text-grey"
							>
								<div class="item background-modifier icon">
									<!-- <font-awesome-icon class="icon" icon="credit-card" size="3x" /> -->
									<img src="/images/triangular_logo_grey.png" width="35" />
									<h5 class="title-bold text-center">
										{{ $t("nav.cards") }}
									</h5>
								</div>
							</router-link>

							<router-link
								:to="{ name: 'offer-management' }"
								class=""
								tag="text-grey"
							>
								<div class="item background-modifier icon">
									<!-- <font-awesome-icon class="icon" icon="shopping-basket" size="3x" /> -->
									<img src="/images/triangular_logo_grey.png" width="35" />
									<h5 class="title-bold text-center">
										{{ $t("nav.offers") }}
									</h5>
								</div>
							</router-link>

							<router-link
								:to="{ name: 'einvoice-management' }"
								class=""
								tag="text-grey"
							>
								<div class="item background-modifier icon">
									<!-- <font-awesome-icon class="icon" icon="file-invoice" size="3x" /> -->
									<img src="/images/triangular_logo_grey.png" width="35" />
									<h5 class="title-bold text-center">
										{{ $t("nav.eInvoices") }}
									</h5>
								</div>
							</router-link>

							<router-link
								:to="{ name: 'direct-debit-management' }"
								class=""
								tag="text-grey"
							>
								<div class="item background-modifier icon">
									<!-- <font-awesome-icon class="icon" icon="money-check" size="3x" /> -->
									<img src="/images/triangular_logo_grey.png" width="35" />
									<h5 class="title-bold text-center">
										{{ $t("nav.directDebits") }}
									</h5>
								</div>
							</router-link>

							<router-link
								:to="{ name: 'payroll-data-upload' }"
								class=""
								tag="text-grey"
							>
								<div class="item background-modifier icon">
									<!-- <font-awesome-icon class="icon" icon="people-arrows" size="3x" /> -->
									<img src="/images/triangular_logo_grey.png" width="35" />
									<h5 class="title-bold text-center">
										{{ $t("nav.payroll") }}
									</h5>
								</div>
							</router-link>

							<router-link
								:to="{ name: 'payment-data-upload' }"
								class=""
								tag="text-grey"
							>
								<div class="item background-modifier icon">
									<!-- <font-awesome-icon class="icon" icon="file-download" size="3x" /> -->
									<img src="/images/triangular_logo_grey.png" width="35" />
									<h5 class="title-bold text-center">
										{{ $t("nav.batchPayments") }}
									</h5>
								</div>
							</router-link>

							<router-link
								:to="{ name: 'beneficiary-management' }"
								class=""
								tag="text-grey"
							>
								<div class="item background-modifier icon">
									<!-- <font-awesome-icon class="icon" icon="person-booth" size="3x" /> -->
									<img src="/images/triangular_logo_grey.png" width="35" />
									<h5 class="title-bold text-center">
										{{ $t("nav.beneficiaries") }}
									</h5>
								</div>
							</router-link>

							<router-link
								:to="{ name: 'receivables-management' }"
								class=""
								tag="text-grey"
							>
								<div class="item background-modifier icon">
									<!-- <font-awesome-icon class="icon" icon="euro-sign" size="3x" /> -->
									<img src="/images/triangular_logo_grey.png" width="35" />
									<h5 class="title-bold text-center">
										{{ $t("nav.invoicing") }}
									</h5>
								</div>
							</router-link>

							<router-link
								:to="{ name: 'payables-management' }"
								class=""
								tag="text-grey"
							>
								<div class="item background-modifier icon">
									<!-- <font-awesome-icon class="icon" icon="file-invoice-dollar" size="3x" /> -->
									<img src="/images/triangular_logo_grey.png" width="35" />
									<h5 class="title-bold text-center">
										{{ $t("nav.payables") }}
									</h5>
								</div>
							</router-link>
						</div>
					</div>
				</div>

				<br />
				<div v-if="!isloading">
					<h3 class="text-bold">
						{{ $t("accounts.accounts") }} ({{ listedAccounts.length }})
					</h3>
					<hr />
					<div class="background-outline">
						<!--   <p class="open-account text-right">
              <router-link
                class="btn btn-outline"
                :to="{ name: 'open-account' }"
                >{{ $t("accounts.openAccount") }}</router-link
              >
            </p>
            <br /> -->

						<!--    <div class="input-group mb-3">
              <input
                type="text"
                class="form-control"
                :placeholder="$t('accounts.nickname')"
                v-model="searchTitle"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary"
                  type="button"
                  @click="
                    page = 1;
                    getClientsAccountList();
                  "
                >
                  {{ $t("search") }}
                </button>
              </div>
            </div> -->
						<div v-if="isloading" class="text-center">
							<b-spinner
								style="width: 3rem; height: 3rem;"
								class="ml-auto text-center"
								:variant="secondary"
							></b-spinner>
						</div>
						<div v-else-if="accounts.length > 0">
							<b-row>
								<div
									v-for="account in listedAccounts"
									:key="account.id"
									class="col-6 card-grid"
								>
									<router-link
										:to="{
											name: 'account-details',
											params: { id: account.id },
										}"
										class=""
										tag="text-grey"
									>
										<div class="background-modifier ">
											<h4 class="">
												{{
													account.iban.toString().replace(/\w{4}(?=.)/g, "$& ")
												}}
											</h4>
											<p class="text-left">{{ account.nickname }}</p>
											<br />
											<hr />
											<div class="row">
												<div class="col-md-8 text-left">
													<h3 class="text-bold">
														{{ account.currency }}
														{{
															account.balance != null
																? $n(account.balance, "decimal")
																: $n(0.0, "decimal")
														}}
													</h3>
												</div>
												<div class="col-md-4 text-right">
													<div v-if="account.currency != baseCurrency" class="">
														<small
															>({{ baseCurrency }}
															{{
																account.baseBalance != null
																	? $n(account.baseBalance, "decimal")
																	: $n(0.0, "decimal")
															}})</small
														>
													</div>
												</div>
											</div>
										</div>
									</router-link>
								</div>
							</b-row>
						</div>
						<div v-else>{{ $t("accounts.noAccounts") }}</div>

						<!-- <div class="background-modifier">
              <div v-if="listedAccounts.length > 10">
                <div class="">
                  <div class="mb-3">
                    {{ $t("itemsPerPage") }}
                    <select 
										class="custom-select" 
										style="width: 60px;"
                      v-model="pageSize"
                      @change="handlePageSizeChange($event)"
                    >
                      <option v-for="size in pageSizes" :key="size" :value="size">
                        {{ size }}
                      </option>
                    </select>
                  </div>
  
                  <b-pagination
                    v-model="page"
                    :total-rows="count"
                    :per-page="pageSize"
                    :prev-text="$t('previous')"
                    :next-text="$t('next')"
                    @change="handlePageChange"
                  ></b-pagination>
                </div>
              </div>
  
              <span>
                <h3 class="title-bold">
                  {{
                    totalBalance() != null
                      ? $n(totalBalance(), "decimal")
                      : $n(0.0, "decimal")
                  }}
                </h3>
                <p class="small">
                  {{ $t("accounts.totalBalance") }} ({{ this.baseCurrency }})
                </p>
              </span>
  
              <div v-if="accounts.length > 0">
                <div class="table-wrap">
                  <table class="table mt-2" id="accounts">
                    <thead>
                      <tr>
                        <th class="text-left">IBAN</th>
                      
                        <th class="text-left">{{ $t("accounts.nickname") }}</th>
                       
                        <th class="text-right">{{ $t("accounts.currency") }}</th>
                        <th class="text-right">{{ $t("accounts.balance") }}</th>
                        <th class="text-right small">
                          {{ $t("accounts.balance") }} ({{ this.baseCurrency }})
                        </th>
                        <th class="text-centered">
                          {{ $t("accounts.actions") }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="account in accounts" :key="account.id">
                      
                        <td class="text-left">
                          {{
                            account.iban.toString().replace(/\w{4}(?=.)/g, "$& ")
                          }}
                        </td>
                  
                        <td class="text-left">{{ account.nickname }}</td>
                      
                        <td class="text-left">{{ account.currency }}</td>
                        <td class="text-right">
                          {{
                            account.balance != null
                              ? $n(account.balance, "decimal")
                              : $n(0.0, "decimal")
                          }}
                        </td>
                        <td class="text-right small">
                          {{
                            account.baseBalance != null
                              ? $n(account.baseBalance, "decimal")
                              : $n(0.0, "decimal")
                          }}
                        </td>
                        <td class="text-right">
                          <router-link
                            :to="{ name: 'account', params: { id: account.id } }"
                            class="btn btn-outline mx-1 "
                            >{{ $t("accounts.view") }}</router-link
                          >
                         
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div v-else>{{ $t("accounts.noAccounts") }}</div> -->
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import AuthService from "@/services/AuthService.js";
	import Trend from "vuetrend";
	// Vue.use(Trend);

	export default {
		name: "business-dashboard",

		data() {
			return {
				account: "",
				//userMessage: "",
				username: "",
				firstname: "",

				loaded: false,
				loadedCF: false,
				loadedCredits: false,
				loadedDebits: false,
				chartData: null,
				chartDataCF: null,
				chartDataCredits: null,
				chartDataDebits: null,
				//sumCF: 0.00,
				sumCredits: 0.0,
				subDebits: 0.0,
				isloading: false,
				isloading2: false,
				accounts: [],
				listedAccounts: [],
				currentAccount: null,
				currentIndex: -1,
				searchTitle: "",
				businessDetails: "",
				page: 1,
				count: 0,
				pageSize: 10,
				pageSizes: [10, 25, 50],
			};
		},
		async created() {
			if (!this.$store.getters.isLoggedIn) {
				this.$router.push("login");
			}

			if (
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 3 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 15 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 14 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 13 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 12 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 11
			) {
				this.$router.push("overview");
			}

			let lang = this.$i18n.locale;
			this.role = this.$store.getters.getUser.role;
			this.username = this.$store.getters.getUser.username;
			this.firstname = this.$store.getters.getUser.firstname;
			// this.userMessage = await AuthService.getUserContent(lang);
			this.baseCurrency = this.$store.getters.getUser.currency;
		},
		methods: {
			getUserId() {
				const userId = this.$store.getters.getUser.id;
				return userId;
			},
			getUserRole() {
				const role = this.$store.getters.getUser.role;
				return role;
			},
			async getClientsTotalRunningBalance(id) {
				this.loaded = false;
				try {
					const id = this.$store.getters.getUser.id;
					this.isloading2 = true;
					const response = await AuthService.getClientsTotalRunningBalance(id);
					this.chartData = response;
					this.loaded = true;
					this.isloading2 = false;
					console.log(response);
				} catch (error) {
					this.isloading2 = false;
					console.log(error);
				}
			},

			async getClientsMonthlyNetCashFlow(id) {
				this.loadedCF = false;
				try {
					const id = this.$store.getters.getUser.id;
					const response = await AuthService.getClientsMonthlyNetCashFlow(id);
					this.chartDataCF = response;
					// const sumCF = this.chartDataCF.reduce((a, b) => a + b, 0);
					this.loadedCF = true;

					console.log(response);
				} catch (error) {
					console.log(error);
				}
			},

			async getClientsMonthlyCredits(id) {
				this.loadedCredits = false;
				try {
					const id = this.$store.getters.getUser.id;
					const response = await AuthService.getClientsMonthlyCredits(id);
					this.chartDataCredits = response;
					this.loadedCredits = true;
					console.log(response);
				} catch (error) {
					console.log(error);
				}
			},

			async getClientsMonthlyDebits(id) {
				this.loadedDebits = false;
				try {
					const id = this.$store.getters.getUser.id;
					const response = await AuthService.getClientsMonthlyDebits(id);
					this.chartDataDebits = response;
					this.loadedDebits = true;
					console.log(response);
				} catch (error) {
					console.log(error);
				}
			},

			getRequestParams(searchTitle, page, pageSize) {
				let params = {};

				if (searchTitle) {
					params["nickname"] = searchTitle;
				}

				if (page) {
					params["page"] = page - 1;
				}

				if (pageSize) {
					params["size"] = pageSize;
				}

				return params;
			},

			async getClientsAccountList() {
				const params = this.getRequestParams(
					this.searchTitle,
					this.page,
					this.pageSize
				);

				try {
					const details = {
						id: this.$store.getters.getUser.id,
					};
					//this.isloading = true;
					const response = await AuthService.getClientsAccountList(
						params.page,
						params.size,
						params.nickname,
						details.id
					);
					const { items, totalItems } = response.data;
					this.accounts = items;
					this.count = totalItems;
					//	this.isloading = false;
					console.log(response.data);
				} catch (error) {
					//	this.isloading = false;
					console.log(error);
				}
			},

			async getClientsAccounts() {
				try {
					const id = this.$store.getters.getUser.id;
					this.isloading = true;
					const response = await AuthService.getClientsAccounts(id);
					this.listedAccounts = response;
					this.isloading = false;
					console.log(response);
				} catch (error) {
					this.isloading = false;
					console.log(error);
				}
			},
			totalBalance() {
				return this.listedAccounts.reduce(
					(acc, item) => acc + item.baseBalance,
					0
				);
			},

			handlePageChange(value) {
				this.page = value;
				this.getClientsAccountList();
			},

			handlePageSizeChange(event) {
				this.pageSize = event.target.value;
				this.page = 1;
				this.getClientsAccountList();
			},

			async getBusinessDetails() {
				try {
					const id = this.$store.getters.getUser.id;
					const response = await AuthService.getBusinessDetails(id);
					this.businessDetails = response;
					console.log(response);
				} catch (error) {
					console.log(error);
				}
			},

			/* async deleteAccount(id) {
          try {
            const response = await AuthService.deleteAccount(id);
            console.log(response);
            this.getClientsAccounts();
          } catch (error) {
            console.log(error);
          }
        },*/
		},

		mounted() {
			this.totalBalance();
			this.getClientsMonthlyCredits();
			this.getClientsMonthlyDebits();
			this.getClientsTotalRunningBalance();
			this.getClientsMonthlyNetCashFlow();

			this.getClientsAccounts();
			this.getClientsAccountList();

			this.getUserId();
			this.getUserRole();
			this.getBusinessDetails();
			// this.formatIBAN();
		},
	};
</script>
