<template>
	<ValidationObserver ref="observer" v-slot="{ handleSubmit, reset }">
		<b-form
			@submit.prevent="handleSubmit(editInvoiceDetails)"
			@reset.prevent="reset"
		>
			<div class="col-lg-4 offset-lg-4 text-left">
				<h2 class="title-bold">
					{{ $t("customerInvoice.editInvoice") }}
				</h2>
				<hr />
				<!-- {{ this.$route.params.id }} -->
				<!-- <h5 class="text-left text-bold">
          {{ $t("customerInvoice.insertTheDetails") }}
        </h5>
        <hr /> -->
				<!-- <div class="row">
          <div class="col-md-6">
            <p class="open-account text-left">
              <router-link
                class="btn btn-outline"
                :to="{ name: 'add-customer' }"
                >{{ $t("customerInvoice.customers") }}</router-link
              >
            </p>
          </div>
          <div class="col-md-6">
            <p class="open-account text-right">
              <router-link
                class="btn btn-outline"
                :to="{ name: 'add-product' }"
                >{{ $t("customerInvoice.products") }}</router-link
              >
            </p>
          </div>
        </div>
        <br /> -->
				<div class="background-outline">
					<div class="background-modifier text-left">
						<!--    <label>{{ $t("customerInvoice.internalId") }}</label>
          <ValidationProvider
            rules="min:1|max:10"
            name="internalId"
            v-slot="{ valid, errors }"
          >
            <b-form-group label-for="internalIdInput" description="">
              <b-form-input
                type="text"
                v-model="invoice.internalId"
                :state="errors[0] ? false : valid ? true : null"
                :placeholder="$t('customerInvoice.internalId')"
              ></b-form-input>
              <b-form-invalid-feedback id="inputLiveFeedback">{{
                errors[0]
              }}</b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider> -->

						<label class="small">{{
							$t("customerInvoice.customerReference")
						}}</label>
						<ValidationProvider
							rules="min:1|max:50"
							name="customerReference"
							v-slot="{ valid, errors }"
						>
							<b-form-group label-for="customerReferenceInput" description="">
								<b-form-input
									type="text"
									v-model="invoice.customerReference"
									:state="errors[0] ? false : valid ? true : null"
									:placeholder="$t('customerInvoice.customerReference')"
								></b-form-input>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>

						<label class="small">{{
							$t("customerInvoice.creditAccountId")
						}}</label>
						<ValidationProvider
							rules="required"
							name="creditAccountId"
							v-slot="{ valid, errors }"
						>
							<b-form-group
								id="creditAccountIdInputGroup"
								label-for="creditAccountIdInput"
							>
								<b-form-select
									@change=""
									id="creditAccountIdInput"
									:state="errors[0] ? false : valid ? true : null"
									v-model="invoice.creditAccountId"
									><option
										v-for="account in accounts"
										:value="account.id"
										:key="account.id"
										>({{ account.id }})
										{{ account.iban.toString().replace(/\w{4}(?=.)/g, "$& ") }}
										{{ $n(account.credits - account.debits, "decimal") }}
										{{ account.currency }}</option
									>
									<template #first>
										<b-form-select-option value="" disabled>{{
											$t("customerInvoice.creditAccountId")
										}}</b-form-select-option>
									</template></b-form-select
								>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>

						<label class="small">{{
							$t("customerInvoice.customerName")
						}}</label>
						<ValidationProvider
							rules="min:1|max:50"
							name="debitAccountHolder"
							v-slot="{ valid, errors }"
						>
							<b-form-group label-for="debitAccountHolderInput" description="">
								<b-form-input
									type="text"
									v-model="invoice.debitAccountHolder"
									:state="errors[0] ? false : valid ? true : null"
									:placeholder="$t('customerInvoice.customerName')"
								></b-form-input>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>

						<label class="small">{{
							$t("customerInvoice.debitAccountId")
						}}</label>
						<ValidationProvider
							rules="integer|min:1|max:4"
							name="debitAccountId"
							v-slot="{ valid, errors }"
						>
							<b-form-group label-for="debitAccountIdInput" description="">
								<b-form-input
									type="text"
									v-model="invoice.debitAccountId"
									:state="errors[0] ? false : valid ? true : null"
									:placeholder="$t('customerInvoice.debitAccountId')"
								></b-form-input>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>

						<label class="small">{{
							$t("customerInvoice.debitAccountIBAN")
						}}</label>
						<ValidationProvider
							:rules="{ iban: true }"
							:vid="name"
							v-bind="$attrs"
							v-slot="{ errors }"
							tag="div"
							class="w-full"
						>
							<b-form-group label-for="debitAccountIdInput" description="">
								<b-form-input
									type="text"
									v-model="invoice.debitAccountIBAN"
									:state="errors[0] ? false : valid ? true : null"
									:placeholder="$t('customerInvoice.debitAccountIBAN')"
								></b-form-input>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>

						<ValidationProvider
							rules="required|min:3|max:3"
							name="currency"
							v-slot="{ valid, errors }"
						>
							<label class="small">{{ $t("customerInvoice.currency") }}</label>
							<b-form-group label-for="currencyInput" description="">
								<b-form-input
									type="text"
									v-model="invoice.currency"
									:state="errors[0] ? false : valid ? true : null"
									:placeholder="$t('customerInvoice.currency')"
								></b-form-input>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>

						<label class="small">{{ $t("customerInvoice.totalGross") }}</label>
						<p>{{ $n(invoice.totalGross, "decimal") }}</p>
						<!-- <label>{{ $t("customerInvoice.totalGross") }}</label>
          <ValidationProvider
            rules="required|double|min_value:0.01"
            name="totalGross"
            v-slot="{ valid, errors }"
          >
            <b-form-group label-for="totalGrossInput" description="">
              <b-form-input
                @input="calculateTotalNet"
                type="text"
                v-model="invoice.totalGross"
                :state="errors[0] ? false : valid ? true : null"
                :placeholder="$t('customerInvoice.totalGross')"
              ></b-form-input>
              <b-form-invalid-feedback id="inputLiveFeedback">{{
                errors[0]
              }}</b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider> -->

						<label class="small"
							>{{ $t("customerInvoice.discountRate") }} (%)</label
						>
						<ValidationProvider
							rules="required|double|min_value:0.00"
							name="discountRate"
							v-slot="{ valid, errors }"
						>
							<b-form-group label-for="discountRateInput" description="">
								<b-form-input
									@input="calculateTotalNet"
									type="text"
									v-model="invoice.discountRate"
									:state="errors[0] ? false : valid ? true : null"
									:placeholder="$t('customerInvoice.discountRate')"
								></b-form-input>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>

						<label class="small">{{ $t("customerInvoice.totalNet") }}</label>
						<p>{{ $n(totalNetResult, "decimal") }}</p>

						<!-- <ValidationProvider
            rules="required|double|min_value:0.01"
            name="totalNet"
            v-slot="{ valid, errors }"
          >
            <b-form-group label-for="totalNetInput" description="">
              <b-form-input
                @change=""
                type="text"
                v-model="totalNetResult"
                :state="errors[0] ? false : valid ? true : null"
                :placeholder="$t('customerInvoice.totalNet')"
              ></b-form-input>
              <b-form-invalid-feedback id="inputLiveFeedback">{{
                errors[0]
              }}</b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider> -->

						<label class="small">{{ $t("customerInvoice.vatRate") }} (%)</label>
						<ValidationProvider
							rules="required|double|min_value:0.00"
							name="vatRate"
							v-slot="{ valid, errors }"
						>
							<b-form-group label-for="vatRateInput" description="">
								<b-form-input
									@input=""
									type="text"
									v-model="invoice.vatRate"
									:state="errors[0] ? false : valid ? true : null"
									:placeholder="$t('customerInvoice.vatRate')"
								></b-form-input>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>

						<label class="small">{{ $t("customerInvoice.vatAmount") }}</label>
						<p>{{ $n(calculateVATAmount(), "decimal") }}</p>

						<!-- <ValidationProvider
            rules="required|double|min_value:0.00"
            name="vatAmount"
            v-slot="{ valid, errors }"
          >
            <b-form-group label-for="vatAmountInput" description="">
              <b-form-input
                @change=""
                type="text"
                v-model="vatAmountResult"
                :state="errors[0] ? false : valid ? true : null"
                :placeholder="$t('customerInvoice.vatAmount')"
              ></b-form-input>
              <b-form-invalid-feedback id="inputLiveFeedback">{{
                errors[0]
              }}</b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider> -->
						<label class="small">{{ $t("customerInvoice.totalVAT") }}</label>
						<p>{{ $n(calculateTotalInclVAT(), "decimal") }}</p>

						<!-- <ValidationProvider
            rules="required|double|min_value:0.00"
            name="totalVAT"
            v-slot="{ valid, errors }"
          >
            <b-form-group label-for="totalVatInput" description="">
              <b-form-input
                @input=""
                type="text"
                v-model="invoice.totalIncVATResult"
                :state="errors[0] ? false : valid ? true : null"
                :placeholder="$t('customerInvoice.totalVAT')"
              ></b-form-input>
              <b-form-invalid-feedback id="inputLiveFeedback">{{
                errors[0]
              }}</b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider> -->

						<label class="small">{{
							$t("customerInvoice.paymentReference")
						}}</label>
						<ValidationProvider
							rules="min:1|max:50"
							name="paymentReference"
							v-slot="{ valid, errors }"
						>
							<b-form-group label-for="paymentReferenceInput" description="">
								<b-form-input
									type="text"
									v-model="invoice.paymentReference"
									:state="errors[0] ? false : valid ? true : null"
									:placeholder="$t('customerInvoice.paymentReference')"
								></b-form-input>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>

						<label class="small" for="datepicker-placeholder">{{
							$t("customerInvoice.dueDate")
						}}</label>
						<b-form-datepicker
							id="datepicker-placeholder"
							:placeholder="$t('customerInvoice.dueDate')"
							v-model="invoice.dueDate"
							:min="min"
							:max="max"
							:locale="$i18n.locale"
							:label-no-date-selected="$t('customerInvoice.noDateSelected')"
							:label-help="
								$t('customerInvoice.useCursorKeysToNavigateCalendarDates')
							"
						></b-form-datepicker>
						<br />
					</div>

					<hr />
					<div class="row">
						<div class="col-md-6">
							<p class="open-account text-left">
								<router-link
									class="btn btn-outline"
									:to="{ name: 'add-invoice-item' }"
									>{{ $t("customerInvoice.addInvoiceItem") }}</router-link
								>
							</p>
						</div>
						<div class="col-md-6"></div>
					</div>
					<br />

					<!-- disabled-->
					<!-- <div v-if="status == 'accepted'"> -->
					<b-button
						type="submit"
						@click="isloading"
						class="btn btn-info text-light btn-block"
						>{{ $t("customerInvoice.finishInvoice")
						}}<b-spinner
							v-if="isloading"
							class="ml-auto"
							:variant="light"
						></b-spinner
					></b-button>
					<!--   <p class="small">{{ $t("businessSignup.notAvailable") }}</p> -->
					<!-- </div> -->

					<!-- <b-button
          type="reset"
          class="btn btn-alert text-light btn-block"
          @click="resetForm()"
          >{{ $t("reset") }}</b-button
        >
        <hr /> -->
					<div v-if="msg && $bvToast.show('notification-toast')"></div>
					<div class="action">
						<br />
						<b-toast
							id="notification-toast"
							variant="secondary"
							static
							no-auto-hide
						>
							<template #toast-title>
								<div class="d-flex flex-grow-1 align-items-baseline">
									<b-img
										class="logo mb-2"
										:src="require('/public/images/logo.png')"
										alt=""
										width="12"
										height="12"
									></b-img>
									<strong class="mr-auto">{{ $t("notification") }}</strong>
									<small class="text-muted mr-2"></small>
								</div>
							</template>
							{{ msg }}
						</b-toast>
					</div>
				</div>
			</div>
		</b-form>
	</ValidationObserver>
</template>

<script>
	import { ValidationObserver, ValidationProvider } from "vee-validate";
	import AuthService from "@/services/AuthService.js";
	// var countryNames = require("i18n-iso-countries");
	export default {
		name: "CreateNewInvoice",
		components: {
			ValidationObserver,
			ValidationProvider,
		},
		/*props: {
        codeLang: {
          type: String,
          default: "en",
        },
      },*/
		//this.codeLang
		created() {
			this.codeLang = this.$i18n.locale;
			// this.countryNames.registerLocale(
			//   require(`i18n-iso-countries/langs/${this.codeLang}.json`)
			// );
		},
		watch: {
			// codeLang(newVal, oldVal) {
			//   this.countryNames.registerLocale(
			//     require(`i18n-iso-countries/langs/${newVal}.json`)
			//   );
			// },
		},
		data() {
			const now = new Date();
			const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
			// 15th two months prior
			const minDate = new Date(today);
			minDate.setYear(minDate.getFullYear() - 0);
			const maxDate = new Date(today);
			maxDate.setYear(maxDate.getFullYear() + 2);
			// minDate.setMonth(minDate.getMonth() - 2);
			// minDate.setDate(15);
			// 15th in two months

			return {
				invoice: "",
				msg: "",
				accounts: [],
				isloading: false,
				// countries: [],
				// countryNames: countryNames,

				// businessId: "",
				// vatNumber: "",
				// email: "",

				// internalId: "",
				// customerReference: "",
				// creditAccountId: 0,
				// creditAccountIBAN: "",
				// creditAccountHolder: "",
				// debitAccountId: 0,
				// debitAccountIBAN: "",
				// debitAccountHolder: "",
				// currency: "EUR",
				// totalGross: 0.0,
				// discountRate: 0.0,
				// totalNet: 0.0,
				// vatRate: 21.0,
				// vatAmount: 0.0,
				// totalVAT: 0.0,
				// paymentReference: "",
				// dueDate: minDate,
				// invoiceStatus: 0,

				/*  firstname: "",
      lastname: "",

      doi: maxDate.toISOString().slice(0, 10),

      addressLine1: "",
      city: "",
      zip: "",
      country: "",
      contactAddressLine1: "",
      contactCity: "",
      contactZip: "",
      contactCountry: "",
      prefix: "",
      mobile: "",*/
				min: minDate,
				max: maxDate,
				//status: "not_accepted",
			};
		},
		async created() {
			if (!this.$store.getters.isLoggedIn) {
				this.$router.push("login");
			}
			if (
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 3 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 15 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 14 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 13 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 12
			) {
				this.$router.push("overview");
			}
			let lang = this.$i18n.locale;
			this.role = this.$store.getters.getUser.role;
			this.username = this.$store.getters.getUser.username;
			this.firstname = this.$store.getters.getUser.firstname;

			//this.getOfferById();
		},
		methods: {
			calculateTotalNet() {
				this.totalNetResult =
					this.invoice.totalGross -
					(this.invoice.discountRate / 100) * this.invoice.totalGross;
			},
			calculateVATAmount() {
				this.vatAmountResult =
					(this.totalNetResult * this.invoice.vatRate) / 100;
				return this.vatAmountResult;
			},
			calculateTotalInclVAT() {
				this.totalIncVATResult = this.totalNetResult + this.vatAmountResult;
				return this.totalIncVATResult;
			},

			async editInvoiceDetails() {
				try {
					const id = this.$route.params.id;
					const details = {
						userId: this.$store.getters.getUser.id,
						lang: this.$i18n.locale,
						//id: this.$route.params.id,
						clientId: this.invoice.clientId,
						internalId: this.invoice.internalId,
						customerReference: this.invoice.customerReference,
						creditAccountId: this.invoice.creditAccountId,
						creditAccountIBAN: this.invoice.creditAccountIBAN,
						creditAccountHolder: this.invoice.creditAccountHolder,
						debitAccountId: this.invoice.debitAccountId,
						debitAccountIBAN: this.invoice.debitAccountIBAN,
						debitAccountHolder: this.invoice.debitAccountHolder,
						currency: this.invoice.currency,
						totalGross: this.invoice.totalGross,
						discountRate: this.invoice.discountRate,
						totalNet: this.totalNetResult,
						vatRate: this.invoice.vatRate,
						vatAmount: this.calculateVATAmount(),
						totalVat: this.calculateTotalInclVAT(),
						paymentReference: this.invoice.paymentReference,
						dueDate: this.invoice.dueDate,
						invoiceStatus: 1, //this.invoice.invoiceStatus,
					};
					this.isloading = true;
					const response = await AuthService.editInvoiceDetails(id, details);
					// this.resetForm();
					this.msg = response.msg;
					this.isloading = false;
					console.log(response.msg);
				} catch (error) {
					this.msg = error.response.data.msg;
					this.isloading = false;
					//console.log(error);
				}
			},

			resetForm() {
				// this.email = "";

				// this.internalId = "";
				// this.customerReference = "";
				// this.creditAccountId = "";
				// this.creditAccountIBAN = "";
				// this.creditAccountHolder = "";
				// this.debitAccountId = "";
				// this.debitAccountIBAN = "";
				// this.debitAccountHolder = "";
				// this.currency = "EUR";
				// this.totalGross = 0.0;
				// this.discountRate = 0.0;
				// this.totalNet = 0.0;
				// this.vatRate = 21.0;
				// this.vatAmount = 0.0;
				// this.totalVat = 0.0;
				// this.paymentReference = "";
				// this.dueDate = minDate;
				// this.invoiceStatus = 0;
				// this.msg = "";
				// this.totalNetResult = 0.0;
				// this.calculateVATAmount;
				// this.calculateTotalInclVAT;
				requestAnimationFrame(() => {
					this.$refs.observer.reset();
				});
			},
			// async getCountries() {
			//   try {
			//     const response = await AuthService.getCountries();
			//     this.countries = response;
			//     console.log(response);
			//   } catch (error) {
			//     console.log(error);
			//   }
			// },
			async getClientsCurrentAccounts() {
				try {
					const details = {
						id: this.$store.getters.getUser.id,
					};
					const response = await AuthService.getClientsCurrentAccounts(
						details.id
					);
					this.accounts = response;
					console.log(response);
				} catch (error) {
					console.log(error);
				}
			},
			async getInvoiceDetails() {
				try {
					const id = this.$route.params.id;
					const response = await AuthService.getInvoiceDetails(id);
					this.invoice = response;
					console.log(response);
				} catch (error) {
					console.log(error);
				}
			},
		},
		mounted() {
			// this.getCountries();
			this.getClientsCurrentAccounts();
			this.getInvoiceDetails();
			this.calculateTotalNet();
			this.calculateTotalInclVAT();
			this.calculateVATAmount();
			//  this.getUserId();
		},
	};
</script>
