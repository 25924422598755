<template>
  <div class="col-8 offset-md-2 text-left">
    <h2 class="title-bold">
      {{ $t("clientDetail.client") }} {{ this.$route.params.id }}
    </h2>
    <hr/>
    <div class="background-modifier">
      <div class="product-details">
      <div class="row">
        <div class="col-md-10">
          <h4 class="title-bold">{{ client.firstname }} {{ client.lastname }}</h4>
        </div>
        <div class="col-md-2 text-right">
          <b-avatar variant="secondary" size="3rem">
            {{ client.firstname.charAt() }}{{ client.lastname.charAt() }}
					</b-avatar>
        </div>
      </div>
      <hr>
      <label class="small">{{ $t("clientDetail.id") }}</label>
      <p class="text-left">{{ client.id }}</p>
      <label class="small">{{ $t("clientDetail.firstname") }}</label>
      <p class="text-left">
        {{ client.firstname }}
      </p>
      <label class="small">{{ $t("clientDetail.lastname") }}</label>
      <p class="text-left">
        {{ client.lastname }}
      </p>
      <label class="small">{{ $t("clientDetail.email") }}</label>
      <p class="text-left">
        {{ client.email }}
      </p>
      <label class="small">{{ $t("clientDetail.address") }}</label>
      <p class="text-left">
        {{ client.addressLine1 }}
      </p>
      <label class="small">{{ $t("clientDetail.city") }}</label>
      <p class="text-left">{{ client.city }}</p>
      <label class="small">{{ $t("clientDetail.postCode") }}</label>
      <p class="text-left">
        {{ client.zip }}
      </p>
      <label class="small">{{ $t("clientDetail.country") }}</label>
      <p class="text-left">
        {{ client.country }}
      </p>
      <label class="small">{{ $t("clientDetail.phoneNumber") }}</label>
      <p class="text-left">
        {{ client.phoneNumber }}
      </p>
      <label class="small">{{ $t("clientDetail.dob") }}</label>
      <p class="text-left">
        {{ $d(Date.parse(client.dob), "long") }}
      </p>
      <label class="small">{{ $t("clientDetail.verificationStatus") }}</label>
      <p class="text-left">
        {{
          client.verificationStatus == 3
            ? $t("clientDetail.verified")
            : $t("clientDetail.pending")
        }}
      </p>
      <label class="small">{{ $t("clientDetail.registered") }}</label>
      <p class="text-left">
        {{ $d(Date.parse(client.registered), "long") }}
      </p>
      <hr/>
      <div class="text-right">
        <router-link
          :to="{ name: 'edit-individual-client', params: { id: client.id } }"
          class="btn btn-outline mx-1 "
          >{{ $t("clientDetail.edit") }}</router-link
        >
      </div>
    </div>
  </div>
    <hr />

    <div class="background-modifier">
      <h5 class="title-bold">{{ $t("fileUpload.listOfFiles") }}</h5>
      <hr/>
      <div v-if="isloading2" class="text-center">
        <b-spinner
        style="width: 3rem; height: 3rem;"
        class="ml-auto text-center"
        :variant="secondary"
      ></b-spinner
    ></div>
      <div v-else-if="idDocs.length > 0">
        <div class="background-modifier">
          <table class="table mt-2 ">
            <thead>
              <tr>
                <!--  <th class="text-left">{{ $t('filename') }}</th> -->
              </tr>
            </thead>
            <tbody>
              <tr v-for="idDoc in idDocs" :key="idDoc.id">
                <td class="text-left">
                  <span>
                    <a
                      :href="
                        'https://www.n3consortium.com/api/files/' +
                          idDoc.filename
                      "
                      >{{ idDoc.filename }}</a
                    ></span
                  >
                </td>

                <td class="text-center"></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
   

    <br />
    <div class="row justify-content-end">
      <div class="col-md-12">
        <router-link
          :to="{ name: 'document-upload' }"
          class="btn btn-secondary mx-1 "
          >{{ $t("dashboard.fileUpload") }}</router-link
        >
      </div>
    </div>
  </div>
    <hr />

    <div class="background-modifier">
      <h5 class="title-bold">{{ $t("accountList.accounts") }}</h5>
      <hr/>
      <div class="">
        <div class="input-group mb-3">
          <input
            type="text"
            class="form-control"
            :placeholder="$t('accountList.nickname')"
            v-model="searchTitle"
          />
          <div class="input-group-append">
            <button
              class="btn btn-outline"
              type="button"
              @click="
                page = 1;
                getUserAccounts();
              "
            >
              {{ $t("search") }}
            </button>
          </div>
        </div>

        <div v-if="isloading" class="text-center">
          <b-spinner
          style="width: 3rem; height: 3rem;"
          class="ml-auto text-center"
          :variant="secondary"
        ></b-spinner
      ></div>
        <div v-else-if="accounts.length > 0">
          <p class="text-right">
            <router-link
              class="btn btn-secondary"
              :to="{ name: 'new-account' }"
              >{{ $t("accountList.openAccount") }}</router-link
            >
          </p>
          <br />
          <div class="table-wrap">
            <table class="table mt-2 " id="account-list">
              <thead>
                <tr>
                  <th class="text-left small">IBAN</th>
                  <th class="text-left small">
                    {{ $t("accountList.accountHolder") }}
                  </th>
                  <th class="text-left small">{{ $t("accountList.nickname") }}</th>
                  <th class="text-right small">{{ $t("accountList.opened") }}</th>
                  <th class="text-right small">{{ $t("accountList.balance") }}</th>
                  <th class="text-centered small">{{ $t("accountList.actions") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="account in accounts" :key="account.id">
                  <td class="text-left">
                    {{ account.iban.toString().replace(/\w{4}(?=.)/g, "$& ") }}
                  </td>
                  <td class="text-left">
                    {{ account.userId }} {{ account.firstname }}
                    {{ account.lastname }}
                  </td>
                  <td class="text-left">{{ account.nickname }}</td>
                  <td class="text-right">
                    {{ $d(Date.parse(account.created), "short") }}
                  </td>
                  <td class="text-right">
                    {{
                      account.credits - account.debits != null
                        ? $n(account.credits - account.debits, "decimal")
                        : 0.0
                    }}
                  </td>
                  <td class="text-right">
                    <router-link
                      :to="{
                        name: 'account-detail',
                        params: { id: account.id },
                      }"
                      class="btn btn-outline mx-1 "
                      >{{ $t("accountList.view") }}</router-link
                    >
                    <!-- <a
                      class="btn btn-danger mx-1 "
                      @click="deleteAccount(account.id)"
                      >{{ $t("accountList.delete") }}</a
                    > -->
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-if="listedAccounts.length >= 1">
            <div class="row">
              <div class="col-md-6">
                {{ $t("itemsPerPage") }}
                <select 
										class="custom-select" 
										style="width: 60px;"
                    v-model="pageSize"
                    @change="handlePageSizeChange($event)"
                >
                  <option v-for="size in pageSizes" :key="size" :value="size">
                    {{ size }}
                  </option>
                </select>
              </div>
              <div class="col-md-6">
                <b-pagination
                  v-model="page"
                  :total-rows="count"
                  :per-page="pageSize"
                  :prev-text="$t('previous')"
                  :next-text="$t('next')"
                  align="right"
                  @change="handlePageChange"
                ></b-pagination>
              </div>
            </div>
          </div>
        </div>
        <div v-else>{{ $t("clientDetail.noAccounts") }}</div>
      </div>
    </div>
    
      <hr />
      <div class="background-modifier">
        <h5 class="title-bold text-left">
          {{ $t("businessClientDetails.verificationStatus") }}
        </h5>
        <hr />
            <b-form-group label="" v-slot="{ vStatus }">
              <b-form-radio
                v-model="client.verificationStatus"
                :aria-describedby="vStatus"
                name="radio_group_1"
                value="1"
                >{{ $t("businessClientDetails.pending") }}</b-form-radio
              >
              <br />
              <b-form-radio
                v-model="client.verificationStatus"
                :aria-describedby="vStatus"
                name="radio_group_1"
                value="2"
                >{{
                  $t("businessClientDetails.moreInformationRequired")
                }}</b-form-radio
              >
              <br />
              <b-form-radio
                v-model="client.verificationStatus"
                :aria-describedby="vStatus"
                name="radio_group_1"
                value="3"
                >{{ $t("businessClientDetails.verified") }}</b-form-radio
              >
            </b-form-group>

            <div>
              <br />
              <b-form>
                <b-button
                  @click="manageApplicationStatus()"
                  class="btn btn-info text-light btn-block"
                  >{{ $t("businessClientDetails.submit") }}</b-button
                >
              </b-form>
            </div>
          </div>
          <hr />
          <div v-if="msg && $bvToast.show('notification-toast')"></div>
          <div class="action">
            <b-toast
              id="notification-toast"
              variant="secondary"
              static
              no-auto-hide
            >
              <template #toast-title>
                <div class="d-flex flex-grow-1 align-items-baseline">
                  <b-img
                    class="logo mb-2"
                    :src="require('/public/images/logo.png')"
                    alt=""
                    width="12"
                    height="12"
                  ></b-img>
                  <strong class="mr-auto">{{ $t("notification") }}</strong>
                  <small class="text-muted mr-2"></small>
                </div>
              </template>
              {{ msg }}
            </b-toast>
          </div>
          <br />
   
  </div>
  </div>
</template>

<script>
import AuthService from "@/services/AuthService.js";

export default {
  name: "IndividualClientDetails",
  data() {
    return {
      client: "",
      account: "",
      accounts: [],
      currentAccount: null,
      currentIndex: -1,
      searchTitle: "",
      msg: "",
      page: 1,
      count: 0,
      pageSize: 10,
      isloading: false,
      isloading2: false,
      pageSizes: [10, 25, 50],

      idDocs: [],
    };
  },
  async created() {
    if (!this.$store.getters.isLoggedIn) {
      this.$router.push("login");
    }
    if (
      this.$store.getters.isLoggedIn &&
      this.$store.getters.getUser.role !== 3
    ) {
      this.$router.push("overview");
    }

    this.role = this.$store.getters.getUser.role;
    this.getClient();
  },
  methods: {
    async getClient(id) {
      try {
        const id = this.$route.params.id;
        const response = await AuthService.getClient(id);
        this.client = response;
        console.log(response);
      } catch (error) {
        console.log(error);
      }
    },

    getRequestParams(searchTitle, page, pageSize) {
      let params = {};

      if (searchTitle) {
        params["nickname"] = searchTitle;
      }

      if (page) {
        params["page"] = page - 1;
      }

      if (pageSize) {
        params["size"] = pageSize;
      }

      return params;
    },

    async getClientAccountList() {
      const params = this.getRequestParams(
        this.searchTitle,
        this.page,
        this.pageSize
      );

      try {
        const details = {
          id: this.$route.params.id,
        };
        this.isloading = true;
        const response = await AuthService.getClientAccountList(
          params.page,
          params.size,
          params.nickname,
          details.id
        );
        const { items, totalItems } = response.data;
        this.accounts = items;
        this.count = totalItems;
        this.isloading = false;
        console.log(response.data);
      } catch (error) {
        this.isloading = false;
        console.log(error);
      }
    },

    totalBalance() {
      return this.accounts.reduce((acc, item) => acc + item.baseBalance, 0);
    },

    handlePageChange(value) {
      this.page = value;
      this.getClientAccountList();
    },

    handlePageSizeChange(event) {
      this.pageSize = event.target.value;
      this.page = 1;
      this.getClientAccountList();
    },

    async getIdDocs(id) {
      try {
        id = this.$route.params.id;
        //  console.log(id);
        this.isloading2 = true;
        const response = await AuthService.getIdDocs(id);
        this.idDocs = response;
        this.isloading2 = false;
        console.log(response);
      } catch (error) {
        this.isloading2 = false;
        console.log(error);
      }
    },

    async manageApplicationStatus() {
      try {
        const id = this.$route.params.id;
        const details = {
          lang: this.$i18n.locale,
          verificationStatus: this.client.verificationStatus,
        };
        const response = await AuthService.manageApplicationStatus(id, details);
        this.msg = response.msg;
        console.log(response.msg);
      } catch (error) {
        this.msg = error.response.data.msg;
        //console.log(error);
      }
    },
  },
  mounted() {
    this.getClient();
    this.getClientAccountList();
    this.totalBalance();
    this.getIdDocs();
  },
};
</script>
