<template>
	<ValidationObserver ref="observer" v-slot="{ handleSubmit, reset }">
		<b-form
			@submit.prevent="handleSubmit(editCustomerDetails)"
			@reset.prevent="reset"
		>
			<div class="col-lg-4 offset-lg-4 text-left">
				<h2 class="title-bold">
					{{ $t("clientsCustomer.editCustomer") }}
				</h2>
				<hr />
				<div class="background-outline">
					<div class="background-modifier text-left">
						<label class="small"
							>{{ $t("clientsCustomer.customerType") }}
						</label>

						<div class="text-left">
							<b-form-group label="" v-slot="{ ariaDescribedby }">
								<b-form-radio
									v-model="customer.customerType"
									:aria-describedby="ariaDescribedby"
									name="radio-inline"
									value="1"
									>{{ $t("clientsCustomer.naturalPerson") }}</b-form-radio
								>
								<b-form-radio
									v-model="customer.customerType"
									:aria-describedby="ariaDescribedby"
									name="radio-inline"
									value="2"
									>{{ $t("clientsCustomer.legalPerson") }}</b-form-radio
								>
							</b-form-group>
						</div>
					</div>
					<br />

					<div v-if="customer.customerType == 1">
						<div class="background-modifier">
							<label class="small"
								>{{ $t("clientsCustomer.firstname") }}
							</label>
							<ValidationProvider
								rules="required|alpha_spaces|min:2|max:50"
								name="firstname"
								v-slot="{ valid, errors }"
							>
								<b-form-group label-for="firstnameInput" description="">
									<b-form-input
										type="text"
										v-model="customer.firstname"
										:state="errors[0] ? false : valid ? true : null"
										:placeholder="$t('clientsCustomer.firstname')"
									></b-form-input>
									<b-form-invalid-feedback id="inputLiveFeedback">{{
										errors[0]
									}}</b-form-invalid-feedback>
								</b-form-group>
							</ValidationProvider>

							<label class="small">{{ $t("clientsCustomer.lastname") }} </label>
							<ValidationProvider
								rules="required|alpha_spaces|min:2|max:50"
								name="lastname"
								v-slot="{ valid, errors }"
							>
								<b-form-group label-for="lastnameInput" description="">
									<b-form-input
										type="text"
										v-model="customer.lastname"
										:state="errors[0] ? false : valid ? true : null"
										:placeholder="$t('clientsCustomer.lastname')"
									></b-form-input>
									<b-form-invalid-feedback id="inputLiveFeedback">{{
										errors[0]
									}}</b-form-invalid-feedback>
								</b-form-group>
							</ValidationProvider>
						</div>
						<br />
					</div>

					<div v-if="customer.customerType == 2">
						<div class="background-modifier">
							<label class="small"
								>{{ $t("clientsCustomer.customerName") }}
							</label>
							<ValidationProvider
								rules="alpha_spaces|min:2|max:50"
								name="name"
								v-slot="{ valid, errors }"
							>
								<b-form-group label-for="nameInput" description="">
									<b-form-input
										type="text"
										v-model="customer.customerName"
										:state="errors[0] ? false : valid ? true : null"
										:placeholder="$t('clientsCustomer.customerName')"
									></b-form-input>
									<b-form-invalid-feedback id="inputLiveFeedback">{{
										errors[0]
									}}</b-form-invalid-feedback>
								</b-form-group>
							</ValidationProvider>
						</div>
						<br />
					</div>

					<!--  <div class="background-modifier">
          <ValidationProvider
            rules="required|min:6|max:30"
            name="password"
            vid="password"
            v-slot="{ valid, errors }"
          >
            <b-form-group description="">
              <b-form-input
                type="password"
                v-model="customer.password"
                :state="errors[0] ? false : valid ? true : null"
                :placeholder="$t('clientsCustomer.password')"
              ></b-form-input>
              <b-form-invalid-feedback id="inputLiveFeedback">{{
                errors[0]
              }}</b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider
            rules="required|confirmed:password"
            name="Password confirmation"
            v-slot="{ valid, errors }"
          >
            <b-form-group label="" label-for="repeatPasswordInput1">
              <b-form-input
                type="password"
                v-model="customer.password_repeat"
                :state="errors[0] ? false : valid ? true : null"
                :placeholder="$t('clientsCustomer.repeatPassword')"
              ></b-form-input>
              <b-form-invalid-feedback id="inputLiveFeedback">{{
                errors[0]
              }}</b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </div>
        <br /> -->
					<div class="background-modifier">
						<label class="small"
							>{{ $t("clientsCustomer.addressLine1") }}
						</label>
						<ValidationProvider
							rules="required|min:5|max:50"
							name="addressLine1"
							v-slot="{ valid, errors }"
						>
							<b-form-group label-for="addressLine1Input" description="">
								<b-form-input
									type="text"
									v-model="customer.addressLine1"
									:state="errors[0] ? false : valid ? true : null"
									:placeholder="$t('clientsCustomer.addressLine1')"
								></b-form-input>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>

						<label class="small"
							>{{ $t("clientsCustomer.addressLine2") }}
						</label>
						<ValidationProvider
							rules="min:2|max:50"
							name="addressLine2"
							v-slot="{ valid, errors }"
						>
							<b-form-group label-for="addressLine2Input" description="">
								<b-form-input
									type="text"
									v-model="customer.addressLine2"
									:state="errors[0] ? false : valid ? true : null"
									:placeholder="$t('clientsCustomer.addressLine2')"
								></b-form-input>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>

						<label class="small">{{ $t("clientsCustomer.city") }} </label>
						<ValidationProvider
							rules="required|min:5|max:50"
							name="city"
							v-slot="{ valid, errors }"
						>
							<b-form-group label-for="cityInput" description="">
								<b-form-input
									type="text"
									v-model="customer.city"
									:state="errors[0] ? false : valid ? true : null"
									:placeholder="$t('clientsCustomer.city')"
								></b-form-input>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>
						<label class="small">{{ $t("clientsCustomer.zip") }} </label>
						<ValidationProvider
							rules="required|min:3|max:10"
							name="zip"
							v-slot="{ valid, errors }"
						>
							<b-form-group label-for="zipInput" description="">
								<b-form-input
									type="text"
									v-model="customer.zip"
									:state="errors[0] ? false : valid ? true : null"
									:placeholder="$t('clientsCustomer.zip')"
								></b-form-input>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>
						<label class="small">{{ $t("clientsCustomer.country") }} </label>
						<ValidationProvider
							rules="required"
							name="country"
							v-slot="{ valid, errors }"
						>
							<b-form-group id="countryInputGroup" label-for="countryInput">
								<b-form-select
									@change="onChange"
									id="countryInput"
									:state="errors[0] ? false : valid ? true : null"
									v-model="customer.country"
									><option
										v-for="country in countries"
										:value="country.countryCode"
										:key="country.countryCode"
										>{{ countryNames.getName(country.countryCode, codeLang) }}
									</option>
									<template #first>
										<b-form-select-option value="" disabled>{{
											$t("clientsCustomer.country")
										}}</b-form-select-option>
									</template></b-form-select
								>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>
					</div>
					<br />
					<div class="background-modifier">
						<div class="row">
							<!-- <div class="col-md-6">
              <ValidationProvider
                rules="required"
                name="prefix"
                v-slot="{ valid, errors }"
              >
                <b-form-group id="prefixInputGroup" label-for="prefixInput">
                  <b-form-select
                    @change="onChange"
                    id="prefixInput"
                    :state="errors[0] ? false : valid ? true : null"
                    v-model="prefix"
                    ><option
                      v-for="country in countries"
                      :value="country.prefix"
                      :key="country.prefix"
                      >+ {{ country.prefix }}
                      {{ countryNames.getName(country.countryCode, codeLang) }}
                    </option>
                    <template #first>
                      <b-form-select-option value="" disabled>{{
                        $t("clientsCustomer.country")
                      }}</b-form-select-option>
                    </template></b-form-select
                  >
                  <b-form-invalid-feedback id="inputLiveFeedback">{{
                    errors[0]
                  }}</b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
            </div> -->
							<div class="col-md-12">
								<label class="small">{{
									$t("clientsCustomer.phoneNumber")
								}}</label>
								<ValidationProvider
									rules="required|numeric|min:5|max:12"
									name="mobile"
									v-slot="{ valid, errors }"
								>
									<b-form-group label-for="mobileInput" description="">
										<b-form-input
											type="text"
											v-model="customer.phoneNumber"
											:state="errors[0] ? false : valid ? true : null"
											:placeholder="$t('clientsCustomer.phoneNumber')"
										></b-form-input>
										<b-form-invalid-feedback id="inputLiveFeedback">{{
											errors[0]
										}}</b-form-invalid-feedback>
									</b-form-group>
								</ValidationProvider>
							</div>
						</div>
					</div>
					<br />

					<div class="background-modifier">
						<label class="small">{{ $t("clientsCustomer.email") }} </label>
						<ValidationProvider
							rules="required|email"
							name="email"
							v-slot="{ valid, errors }"
						>
							<b-form-group label-for="emailInput" description="">
								<b-form-input
									type="email"
									v-model="customer.email"
									:state="errors[0] ? false : valid ? true : null"
									:placeholder="$t('clientsCustomer.email')"
								></b-form-input>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>
					</div>

					<br />
					<div class="background-modifier text-left">
						<div>
							<div v-if="customer.customerType == 1">
								<label class="small" for="datepicker-placeholder">{{
									$t("clientsCustomer.dob")
								}}</label>
							</div>
							<div v-if="customer.customerType == 2">
								<label class="small" for="datepicker-placeholder">{{
									$t("clientsCustomer.dateOfIncorporation")
								}}</label>
							</div>
							<b-form-datepicker
								id="datepicker-placeholder"
								:placeholder="$t('clientsCustomer.dob')"
								v-model="customer.dob"
								:min="min"
								:max="max"
								:locale="$i18n.locale"
								:label-no-date-selected="$t('clientsCustomer.noDateSelected')"
								:label-help="
									$t('clientsCustomer.useCursorKeysToNavigateCalendarDates')
								"
							></b-form-datepicker>
						</div>
					</div>
					<!-- <br /> -->
					<!--  <div class="background-modifier">
            <ValidationProvider
              rules="required|numeric|min:6|max:6"
              name="pin"
              v-slot="{ valid, errors }"
            >
              <b-form-group label-for="pinInput" description="">
                <b-form-input
                  type="text"
                  v-model="customer.pin"
                  :state="errors[0] ? false : valid ? true : null"
                  :placeholder="$t('clientsCustomer.pin')"
                ></b-form-input>
                <b-form-invalid-feedback id="inputLiveFeedback">{{
                  errors[0]
                }}</b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>
          </div> -->
					<!-- <div class="background-modifier text-left">
          <ValidationProvider
            rules="required"
            name="nationality"
            v-slot="{ valid, errors }"
          >
            <label>{{ $t("clientsCustomer.nationality") }}</label>
            <b-form-group id="countryInputGroup" label-for="nationalityInput">
              <b-form-select
                @change="onChange"
                id="nationalityInput"
                :state="errors[0] ? false : valid ? true : null"
                v-model="customer.nationality"
                ><option
                  v-for="country in countries"
                  :value="country.countryCode"
                  :key="country.countryCode"
                  >{{ countryNames.getName(country.countryCode, codeLang) }}
                </option>
                <template #first>
                  <b-form-select-option value="" disabled>{{
                    Nationality
                  }}</b-form-select-option>
                </template></b-form-select
              >
              <b-form-invalid-feedback id="inputLiveFeedback">{{
                errors[0]
              }}</b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider> -->

					<hr />

					<!-- disabled-->
					<!-- <div v-if="status == '1'"> -->
					<b-button
						type="submit"
						@click="isloading"
						class="btn btn-info text-light btn-block"
						>{{ $t("clientsCustomer.submit")
						}}<b-spinner
							v-if="isloading"
							class="ml-auto"
							:variant="light"
						></b-spinner
					></b-button>
					<!-- <p class="small">{{ $t("clientsCustomer.notAvailable") }}</p> -->
					<!-- </div> -->
					<!-- <hr />
        <b-button
          type="reset"
          class="btn btn-alert text-light btn-block"
          @click="resetForm()"
          >{{ $t("reset") }}</b-button
        > -->

					<div v-if="msg && $bvToast.show('notification-toast')"></div>
					<div class="action">
						<br />
						<b-toast
							id="notification-toast"
							variant="secondary"
							static
							no-auto-hide
						>
							<template #toast-title>
								<div class="d-flex flex-grow-1 align-items-baseline">
									<b-img
										class="logo mb-2"
										:src="require('/public/images/logo.png')"
										alt=""
										width="12"
										height="12"
									></b-img>
									<strong class="mr-auto">{{ $t("notification") }}</strong>
									<small class="text-muted mr-2"></small>
								</div>
							</template>
							{{ msg }}
						</b-toast>
					</div>
				</div>
			</div>
		</b-form>
	</ValidationObserver>
</template>

<script>
	import { ValidationObserver, ValidationProvider } from "vee-validate";
	import AuthService from "@/services/AuthService.js";
	var countryNames = require("i18n-iso-countries");
	export default {
		name: "AddBusinessRole",
		components: {
			ValidationObserver,
			ValidationProvider,
		},
		/*props: {
      codeLang: {
        type: String,
        default: "en",
      },
    },*/
		//this.codeLang
		async created() {
			if (!this.$store.getters.isLoggedIn) {
				this.$router.push("login");
			}
			if (
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 3 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 15 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 14
			) {
				this.$router.push("overview");
			}
			this.role = this.$store.getters.getUser.role;
			// this.username = this.$store.getters.getUser.username;
			// this.firstname = this.$store.getters.getUser.firstname;
			// this.newRole = this.$route.params.reference;
			let lang = this.$i18n.locale;
			this.codeLang = this.$i18n.locale;
			this.countryNames.registerLocale(
				require(`i18n-iso-countries/langs/${this.codeLang}.json`)
			);
		},
		watch: {
			codeLang(newVal, oldVal) {
				this.countryNames.registerLocale(
					require(`i18n-iso-countries/langs/${newVal}.json`)
				);
			},
		},
		data() {
			const now = new Date();
			const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
			// 15th two months prior
			const minDate = new Date(today);
			minDate.setYear(minDate.getFullYear() - 300);
			const maxDate = new Date(today);
			maxDate.setYear(maxDate.getFullYear() - 0);
			// minDate.setMonth(minDate.getMonth() - 2);
			// minDate.setDate(15);
			// 15th in two months

			return {
				countries: [],
				countryNames: countryNames,
				customer: "",
				isloading: false,
				// role: "",
				// selected: 1,
				/*  username: "", */
				// email: "",
				// name: "",
				// firstname: "",
				// lastname: "",
				/*  password: "",
      password_repeat: "", */
				msg: "",
				/*  pin: "", */
				// dob: maxDate.toISOString().slice(0, 10),
				// addressLine1: "",
				// addressLine2: "",
				// city: "",
				// zip: "",
				// country: "",
				// prefix: "",
				// mobile: "",
				// value: "",
				min: minDate,
				max: maxDate,
				// status: 0,
				// internalReference: "",
			};
		},

		methods: {
			async editCustomerDetails() {
				const id = this.$route.params.id;
				try {
					const credentials = {
						userId: this.$store.getters.getUser.id,
						lang: this.$i18n.locale,
						email: this.customer.email,
						internalReference: this.customer.internalReference,
						customerName: this.customer.customerName,
						firstname: this.customer.firstname,
						lastname: this.customer.lastname,
						dob: this.customer.dob,
						addressLine1: this.customer.addressLine1,
						addressLine2: this.customer.addressLine2,
						city: this.customer.city,
						zip: this.customer.zip,
						country: this.customer.country,
						phoneNumber: this.customer.phoneNumber,
						customerStatus: this.customer.customerStatus,
						customerType: this.customer.customerType,
					};
					//let reference = this.$route.params.reference;
					this.isloading = true;
					const response = await AuthService.editCustomerDetails(
						id,
						credentials
					);
					this.resetForm();
					this.msg = response.msg;
					this.isloading = false;
				} catch (error) {
					this.msg = error.response.data.msg;
					this.isloading = false;
					/*this.$router.push('home');*/
				}
			},
			resetForm() {
				// this.role = ""; /*NEW*/
				// /* this.username = ""; */
				// this.name = "";
				// this.firstname = "";
				// (this.lastname = ""), (this.email = "");
				// /*  this.password = "";
				// this.password_repeat = ""; */
				// this.msg = "";
				// /* this.pin = ""; */
				// this.dob = "";
				// this.addressLine1 = "";
				// this.addressLine2 = "";
				// this.city = "";
				// this.zip = "";
				// this.country = "";
				// this.prefix = "";
				// this.mobile = "";
				// this.nationality = ""; /*NEW*/
				// this.ownership = ""; /*NEW*/
				// this.votes = ""; /*NEW*/
				// this.status = 0; /*NEW*/
				// this.selected = 1;
				requestAnimationFrame(() => {
					this.$refs.observer.reset();
				});
			},
			async getCountries() {
				try {
					const response = await AuthService.getCountries();
					this.countries = response;
					console.log(response);
				} catch (error) {
					console.log(error);
				}
			},
			async getCustomerDetails() {
				try {
					const id = this.$route.params.id;
					const response = await AuthService.getCustomerDetails(id);
					this.customer = response;
					console.log(response);
				} catch (error) {
					console.log(error);
				}
			},
		},
		mounted() {
			this.getCountries();
			this.getCustomerDetails();
		},
	};
</script>
