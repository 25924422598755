<template>
	<ValidationObserver ref="observer" v-slot="{ handleSubmit, reset }">
		<b-form
			@submit.prevent="handleSubmit(editIndividualClient)"
			@reset.prevent="reset"
		>
			<div class="col-md-4 offset-md-4 text-left">
				<h2 class="title-bold">
					{{ $t("editIndividualClient.editClient") }} {{ client.id }}
				</h2>

				<hr />
				<div class="background-outline">
					<div class="background-modifier">
						<label class="text-left small">{{
							$t("editIndividualClient.firstname")
						}}</label>
						<ValidationProvider
							rules="required|alpha_spaces|min:2|max:50"
							name="firstname"
							v-slot="{ valid, errors }"
						>
							<b-form-group label-for="firstnameInput" description="">
								<b-form-input
									type="text"
									v-model="client.firstname"
									:state="errors[0] ? false : valid ? true : null"
									:placeholder="$t('editIndividualClient.firstname')"
								></b-form-input>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>

						<label class="text-left small">{{
							$t("editIndividualClient.lastname")
						}}</label>
						<ValidationProvider
							rules="required|alpha_spaces|min:2|max:50"
							name="lastname"
							v-slot="{ valid, errors }"
						>
							<b-form-group label-for="lastnameInput" description="">
								<b-form-input
									type="text"
									v-model="client.lastname"
									:state="errors[0] ? false : valid ? true : null"
									:placeholder="$t('editIndividualClient.lastname')"
								></b-form-input>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>

						<label for="datepicker-placeholder" class="small">{{
							$t("editIndividualClient.dob")
						}}</label>
						<b-form-datepicker
							id="datepicker-placeholder"
							:placeholder="$t('editIndividualClient.dob')"
							v-model="client.dob"
							:min="min"
							:max="max"
							:locale="$i18n.locale"
							:label-no-date-selected="
								$t('editIndividualClient.noDateSelected')
							"
							:label-help="
								$t('editIndividualClient.useCursorKeysToNavigateCalendarDates')
							"
						></b-form-datepicker>
					</div>
					<br />
					<div class="background-modifier">
						<br />
						<h5 class="text-left text-bold">
							{{ $t("editIndividualClient.contactDetails") }}
						</h5>
						<hr />

						<label class="small">{{
							$t("editIndividualClient.phoneNumber")
						}}</label>
						<ValidationProvider
							rules="required|min:5|max:15"
							name="mobile"
							v-slot="{ valid, errors }"
						>
							<b-form-group label-for="mobileInput" description="">
								<b-form-input
									type="text"
									v-model="client.phoneNumber"
									:state="errors[0] ? false : valid ? true : null"
									:placeholder="$t('businessSignup.phone')"
								></b-form-input>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>

						<label class="text-left small">{{
							$t("editIndividualClient.email")
						}}</label>
						<ValidationProvider
							rules="required|email"
							name="email"
							v-slot="{ valid, errors }"
						>
							<b-form-group label-for="emailInput" description="">
								<b-form-input
									type="email"
									v-model="client.email"
									:state="errors[0] ? false : valid ? true : null"
									:placeholder="$t('editIndividualClient.email')"
								></b-form-input>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>
						<label class="text-left small">{{
							$t("editIndividualClient.address")
						}}</label>
						<ValidationProvider
							rules="required|min:5|max:50"
							name="addressLine1"
							v-slot="{ valid, errors }"
						>
							<b-form-group label-for="addressLine1Input" description="">
								<b-form-input
									type="text"
									v-model="client.addressLine1"
									:state="errors[0] ? false : valid ? true : null"
									:placeholder="$t('editIndividualClient.address')"
								></b-form-input>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>
						<ValidationProvider
							rules="required|min:5|max:50"
							name="city"
							v-slot="{ valid, errors }"
						>
							<b-form-group label-for="cityInput" description="">
								<b-form-input
									type="text"
									v-model="client.city"
									:state="errors[0] ? false : valid ? true : null"
									:placeholder="$t('editIndividualClient.city')"
								></b-form-input>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>
						<ValidationProvider
							rules="required|min:3|max:10"
							name="zip"
							v-slot="{ valid, errors }"
						>
							<b-form-group label-for="zipInput" description="">
								<b-form-input
									type="text"
									v-model="client.zip"
									:state="errors[0] ? false : valid ? true : null"
									:placeholder="$t('editIndividualClient.postCode')"
								></b-form-input>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>
						<ValidationProvider
							rules="required"
							name="country"
							v-slot="{ valid, errors }"
						>
							<b-form-group id="countryInputGroup" label-for="countryInput">
								<b-form-select
									@change="onChange"
									id="countryInput"
									:state="errors[0] ? false : valid ? true : null"
									v-model="client.country"
									><option
										v-for="country in countries"
										:value="country.countryCode"
										:key="country.countryCode"
										>{{ countryNames.getName(country.countryCode, codeLang) }}
									</option>
									<template #first>
										<b-form-select-option value="" disabled>{{
											$t("editIndividualClient.country")
										}}</b-form-select-option>
									</template></b-form-select
								>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>
					</div>

					<br />
					<div class="background-modifier">
						<h5 class="title-bold text-left">
							{{ $t("editIndividualClient.verificationStatus") }}
						</h5>
						<hr />
						<b-form-group label="" v-slot="{ vStatus }">
							<b-form-radio
								v-model="client.verificationStatus"
								:aria-describedby="vStatus"
								name="radio_group_1"
								value="1"
								>{{ $t("editIndividualClient.pending") }}</b-form-radio
							>
							<br />
							<b-form-radio
								v-model="client.verificationStatus"
								:aria-describedby="vStatus"
								name="radio_group_1"
								value="2"
								>{{
									$t("editIndividualClient.moreInformationRequired")
								}}</b-form-radio
							>
							<br />
							<b-form-radio
								v-model="client.verificationStatus"
								:aria-describedby="vStatus"
								name="radio_group_1"
								value="3"
								>{{ $t("editIndividualClient.verified") }}</b-form-radio
							>
						</b-form-group>
					</div>
					<hr />
					<b-button
						type="submit"
						@click="isloading"
						class="btn btn-info text-light btn-block"
						>{{ $t("editIndividualClient.submit")
						}}<b-spinner
							v-if="isloading"
							class="ml-auto"
							:variant="light"
						></b-spinner
					></b-button>
					<!--   <hr/>
     <b-button type="reset" class="btn btn-alert text-light btn-block" @click="resetForm()">{{ $t('reset') }}</b-button> -->

					<div v-if="msg && $bvToast.show('notification-toast')"></div>
					<div class="action">
						<br />
						<b-toast
							id="notification-toast"
							variant="secondary"
							static
							no-auto-hide
						>
							<template #toast-title>
								<div class="d-flex flex-grow-1 align-items-baseline">
									<b-img
										class="logo mb-2"
										:src="require('/public/images/logo.png')"
										alt=""
										width="12"
										height="12"
									></b-img>
									<strong class="mr-auto">{{ $t("notification") }}</strong>
									<small class="text-muted mr-2"></small>
								</div>
							</template>
							{{ msg }}
						</b-toast>
					</div>
				</div>
			</div>
		</b-form>
	</ValidationObserver>
</template>

<script>
	import { ValidationObserver, ValidationProvider } from "vee-validate";
	import AuthService from "@/services/AuthService.js";
	var countryNames = require("i18n-iso-countries");
	export default {
		name: "EditIndividualClientForm",
		components: {
			ValidationObserver,
			ValidationProvider,
		},
		watch: {
			codeLang(newVal, oldVal) {
				this.countryNames.registerLocale(
					require(`i18n-iso-countries/langs/${newVal}.json`)
				);
			},
		},
		data() {
			const now = new Date();
			const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
			// 15th two months prior
			const minDate = new Date(today);
			minDate.setYear(minDate.getFullYear() - 300);
			const maxDate = new Date(today);
			maxDate.setYear(maxDate.getFullYear() - 0);
			return {
				client: "",
				msg: "",
				countries: [],
				countryNames: countryNames,
				min: minDate,
				max: maxDate,
				isloading: false,
			};
		},
		async created() {
			if (!this.$store.getters.isLoggedIn) {
				this.$router.push("login");
			}
			if (
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role !== 3
			) {
				this.$router.push("overview");
			}

			this.role = this.$store.getters.getUser.role;
			this.countryNames.registerLocale(
				require(`i18n-iso-countries/langs/${this.codeLang}.json`)
			);
		},
		methods: {
			async getClient(id) {
				try {
					const id = this.$route.params.id;
					const response = await AuthService.getClient(id);
					this.client = response;
					console.log(response);
				} catch (error) {
					console.log(error);
				}
			},
			async editIndividualClient() {
				try {
					const id = this.$route.params.id;
					const details = {
						lang: this.$i18n.locale,
						firstname: this.client.firstname,
						lastname: this.client.lastname,
						email: this.client.email,
						phoneNumber: this.client.phoneNumber,
						addressLine1: this.client.addressLine1,
						city: this.client.city,
						zip: this.client.zip,
						country: this.client.country,
						dob: this.client.dob,
						verificationStatus: this.client.verificationStatus,
					};
					this.isloading = true;
					const response = await AuthService.editIndividualClient(id, details);
					this.msg = response.msg;
					this.isloading = false;
					// this.getClient();
					console.log(response.msg);
				} catch (error) {
					console.log(error);
					this.msg = error.response.data.msg;
					this.isloading = false;
				}
			},
			async getCountries() {
				try {
					const response = await AuthService.getCountries();
					this.countries = response;
					console.log(response);
				} catch (error) {
					console.log(error);
				}
			},
			resetForm() {
				this.msg = "";
				requestAnimationFrame(() => {
					this.$refs.observer.reset();
				});
			},
		},
		mounted() {
			this.getCountries();
			this.getClient();
		},
	};
</script>
