<template>
	<div class="col-12 offset-0">
		<div class="text-left">
			<div class="col-md-8 offset-md-2">
				<h1 class="title-bold">{{ $t("transactionList.transactions") }}</h1>
				<hr />
				<!--   <form>
          <div class="input-group mb-3">
            <input type="text" class="form-control" :placeholder="$t('account.nickname')" v-model="account.nickname" />
            </div>
          <button type="submit" class="btn btn-info"
        @click="updateAccount"
      >{{ $t('newPayment.submit') }}
        </button>
        <p v-if="msg">{{ msg }}</p>
        </form> -->

				<p class="open-account text-right">
					<router-link
						class="btn btn-outline"
						:to="{ name: 'new-transaction' }"
						>{{ $t("transactionList.newTransaction") }}</router-link
					>
				</p>
				<br />

				<div v-if="msg && $bvToast.show('notification-toast')"></div>
				<div class="action">
					<b-toast
						id="notification-toast"
						variant="secondary"
						static
						no-auto-hide
					>
						<template #toast-title>
							<div class="d-flex flex-grow-1 align-items-baseline">
								<b-img
									class="logo mb-2"
									:src="require('/public/images/logo.png')"
									alt=""
									width="12"
									height="12"
								></b-img>
								<strong class="mr-auto">{{ $t("notification") }}</strong>
								<small class="text-muted mr-2"></small>
							</div>
						</template>
						{{ msg }}
					</b-toast>
				</div>

				<div class="background-modifier">
					<div class="input-group mb-3">
						<input
							type="text"
							class="form-control"
							:placeholder="$t('transactionList.id')"
							v-model="searchTitle"
						/>
						<div class="input-group-append">
							<button
								class="btn btn-outline"
								type="button"
								@click="
									page = 1;
									getAllTransactions();
								"
							>
								{{ $t("search") }}
							</button>
						</div>
					</div>

					<div v-if="isloading" class="text-center">
						<b-spinner
							style="width: 3rem; height: 3rem;"
							class="ml-auto text-center"
							:variant="secondary"
						></b-spinner>
					</div>
					<div v-else-if="transactions.length > 0">
						<div class="table-wrap">
							<table class="table mt-2 " id="transaction-list">
								<thead>
									<tr>
										<th class="text-left small">
											{{ $t("transactionList.id") }}
										</th>
										<th class="text-left small">
											{{ $t("transactionList.debitAccountId") }}
										</th>
										<th class="text-left small">
											{{ $t("transactionList.remitter") }}
										</th>
										<th class="text-left small">
											{{ $t("transactionList.creditAccountId") }}
										</th>
										<th class="text-left small">
											{{ $t("transactionList.beneficiary") }}
										</th>
										<th class="text-right small">
											{{ $t("transactionList.accountCurrency") }}
										</th>
										<th class="text-right small">
											{{ $t("transactionList.amount") }}
										</th>
										<th class="text-right small">
											{{ $t("transactionList.message") }}
										</th>
										<th class="text-right small">
											{{ $t("transactionList.date") }}
										</th>
										<th class="text-centered small">
											{{ $t("transactionList.actions") }}
										</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="transaction in transactions" :key="transaction.id">
										<td class="text-left">{{ transaction.id }}</td>
										<td class="text-left">{{ transaction.debitAccountId }}</td>
										<td class="text-left">
											{{ transaction.debitAccountHolder }}
										</td>
										<td class="text-left">{{ transaction.creditAccountId }}</td>
										<td class="text-left">
											{{ transaction.creditAccountHolder }}
										</td>
										<td class="text-left">{{ transaction.baseCurrency }}</td>
										<td class="text-right">
											{{ $n(transaction.amount, "decimal") }}
										</td>
										<td class="text-right">{{ transaction.message }}</td>
										<td class="text-right">
											{{ $d(Date.parse(transaction.created), "short") }}
										</td>
										<td class="text-right">
											<router-link
												:to="{
													name: 'transaction-detail',
													params: { id: transaction.id },
												}"
												class="btn btn-outline mx-1 "
												>{{ $t("transactionList.view") }}</router-link
											>
											<a
												class="btn btn-danger mx-1 "
												@click="deleteTransaction(transaction.id)"
												>{{ $t("transactionList.delete") }}</a
											>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div v-if="transactions.length >= 1">
							<div class="row">
								<div class="col-md-6">
									{{ $t("itemsPerPage") }}
									<select
										class="custom-select"
										style="width: 60px;"
										v-model="pageSize"
										@change="handlePageSizeChange($event)"
									>
										<option v-for="size in pageSizes" :key="size" :value="size">
											{{ size }}
										</option>
									</select>
								</div>
								<div class="col-md-6">
									<b-pagination
										v-model="page"
										:total-rows="count"
										:per-page="pageSize"
										:prev-text="$t('previous')"
										:next-text="$t('next')"
										align="right"
										@change="handlePageChange"
									></b-pagination>
								</div>
							</div>
						</div>
					</div>
					<div v-else>{{ $t("transactionList.noTransactions") }}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import AuthService from "@/services/AuthService.js";

	export default {
		name: "transaction-list",
		data() {
			return {
				transactions: [],
				msg: "",
				currentTransaction: null,
				currentIndex: -1,
				searchTitle: "",
				isloading: false,
				page: 1,
				count: 0,
				pageSize: 10,

				pageSizes: [10, 25, 50],
			};
		},
		async created() {
			if (!this.$store.getters.isLoggedIn) {
				this.$router.push("login");
			}
			if (
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role !== 3
			) {
				this.$router.push("overview");
			}

			this.role = this.$store.getters.getUser.role;
			this.getAllTransactions();
		},
		methods: {
			getRequestParams(searchTitle, page, pageSize) {
				let params = {};

				if (searchTitle) {
					params["title"] = searchTitle;
				}

				if (page) {
					params["page"] = page - 1;
				}

				if (pageSize) {
					params["size"] = pageSize;
				}

				return params;
			},

			async getAllTransactions() {
				const params = this.getRequestParams(
					this.searchTitle,
					this.page,
					this.pageSize
				);
				try {
					this.isloading = true;
					const response = await AuthService.getAllTransactions(
						params.page,
						params.size,
						params.title
					);
					const { items, totalItems } = response.data;
					this.transactions = items;
					this.count = totalItems;
					this.isloading = false;
					console.log(response.data);
				} catch (error) {
					this.isloading = false;
					console.log(error);
				}
			},

			handlePageChange(value) {
				this.page = value;
				this.getAllTransactions();
			},

			handlePageSizeChange(event) {
				this.pageSize = event.target.value;
				this.page = 1;
				this.getAllTransactions();
			},

			async deleteTransaction(id) {
				try {
					const lang = this.$i18n.locale;
					const response = await AuthService.deleteTransaction(id, lang);
					console.log(response);
					this.getAllTransactions();
					this.msg = response.msg;
				} catch (error) {
					console.log(error);
					this.msg = error.response.data.msg;
				}
			},
		},
		mounted() {
			this.getAllTransactions();
		},
	};
</script>
