<template>
	<div class="col-12 offset-0">
		<div class="text-left">
			<div class="col-md-8 offset-md-2">
				<h1 class="title-bold">
					{{ $t("receivablesManagement.receivablesManagement") }}
				</h1>
				<hr />
				<h5 class="title-bold">
					{{ $t("receivablesManagement.manageReceivables") }}
				</h5>
				<hr />
				<div v-if="role == 3 || role == 15 || role == 14">
					<p class="open-account text-right">
						<router-link
							class="btn btn-outline"
							:to="{ name: 'create-invoice' }"
							>{{ $t("receivablesManagement.newInvoice") }}</router-link
						>
					</p>
					<br />
				</div>

				<div v-if="msg && $bvToast.show('notification-toast')"></div>
				<div class="action">
					<b-toast
						id="notification-toast"
						variant="secondary"
						static
						no-auto-hide
					>
						<template #toast-title>
							<div class="d-flex flex-grow-1 align-items-baseline">
								<b-img
									class="logo mb-2"
									:src="require('/public/images/logo.png')"
									alt=""
									width="12"
									height="12"
								></b-img>
								<strong class="mr-auto">{{ $t("notification") }}</strong>
								<small class="text-muted mr-2"></small>
							</div>
						</template>
						{{ msg }}
						<br />
					</b-toast>
				</div>

				<b-tabs content-class="mt-3">
					<b-tab :title="$t('receivablesManagement.draft')">
						<div class="background-modifier">
							<div class="input-group mb-3">
								<input
									type="text"
									class="form-control"
									:placeholder="$t('receivablesManagement.customerName')"
									v-model="searchTitle"
								/>
								<div class="input-group-append">
									<button
										class="btn btn-outline"
										type="button"
										@click="
											page = 1;
											getClientsDraftInvoices();
										"
									>
										{{ $t("search") }}
									</button>
								</div>
							</div>

							<div v-if="isloading2" class="text-center">
								<b-spinner
									style="width: 3rem; height: 3rem;"
									class="ml-auto text-center"
									:variant="secondary"
								></b-spinner>
							</div>
							<div v-else-if="draftInvoices.length > 0">
								<div class="table-wrap">
									<table class="table mt-2" id="draftInvoices">
										<thead>
											<tr>
												<th class="text-left small">
													{{ $t("receivablesManagement.id") }}
												</th>
												<th class="text-left small">
													{{ $t("receivablesManagement.customerName") }}
												</th>
												<th class="text-right small">
													{{ $t("receivablesManagement.amount") }}
												</th>
												<th class="text-left small">
													{{ $t("receivablesManagement.message") }}
												</th>
												<th class="text-right small">
													{{ $t("receivablesManagement.dueDate") }}
												</th>
												<th class="text-right small">
													{{ $t("receivablesManagement.created") }}
												</th>
												<th class="text-centered small">
													{{ $t("receivablesManagement.actions") }}
												</th>
											</tr>
										</thead>
										<tbody>
											<tr
												v-for="draftInvoice in draftInvoices"
												:key="draftInvoice.id"
											>
												<td class="text-left">
													{{ draftInvoice.id }}
												</td>
												<td class="text-left">
													{{ draftInvoice.debitAccountHolder }}
												</td>
												<td class="text-right">
													{{ $n(draftInvoice.totalVat, "decimal") }}
												</td>
												<td class="text-left">
													{{ draftInvoice.paymentReference }}
												</td>
												<td class="text-right">
													{{ $d(Date.parse(draftInvoice.dueDate), "short") }}
												</td>
												<td class="text-right">
													{{ $d(Date.parse(draftInvoice.created), "short") }}
												</td>
												<td class="text-right">
													<router-link
														:to="{
															name: 'invoice-details',
															params: { id: draftInvoice.id },
														}"
														class="btn btn-outline mx-1 "
														>{{ $t("receivablesManagement.view") }}</router-link
													>
													<a
														class="btn btn-danger mx-1 "
														@click="deleteCustomerInvoice(draftInvoice.id)"
														>{{ $t("receivablesManagement.delete") }}</a
													>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
								<div v-if="draftInvoices.length >= 1">
									<div class="row">
										<div class="col-md-6">
											{{ $t("itemsPerPage") }}
											<select
												class="custom-select"
												style="width: 60px;"
												v-model="pageSize"
												@change="handlePageSizeChange($event)"
											>
												<option
													v-for="size in pageSizes"
													:key="size"
													:value="size"
												>
													{{ size }}
												</option>
											</select>
										</div>
										<div class="col-md-6">
											<b-pagination
												v-model="page"
												:total-rows="count"
												:per-page="pageSize"
												:prev-text="$t('previous')"
												:next-text="$t('next')"
												align="right"
												@change="handlePageChange"
											></b-pagination>
										</div>
									</div>
								</div>
							</div>

							<div v-else>
								{{ $t("receivablesManagement.noDraftCustomerInvoices") }}
							</div>
						</div>
					</b-tab>
					<b-tab :title="$t('receivablesManagement.pending')" active>
						<div class="background-modifier">
							<div class="input-group mb-3">
								<input
									type="text"
									class="form-control"
									:placeholder="$t('receivablesManagement.customerName')"
									v-model="searchTitle"
								/>
								<div class="input-group-append">
									<button
										class="btn btn-outline"
										type="button"
										@click="
											page = 1;
											getClientsCustomerInvoices();
										"
									>
										{{ $t("search") }}
									</button>
								</div>
							</div>

							<div v-if="isloading" class="text-center">
								<b-spinner
									style="width: 3rem; height: 3rem;"
									class="ml-auto text-center"
									:variant="secondary"
								></b-spinner>
							</div>
							<div v-else-if="customerInvoices.length > 0">
								<div class="table-wrap">
									<table class="table mt-2" id="customerInvoices">
										<thead>
											<tr>
												<th class="text-left small">
													{{ $t("receivablesManagement.id") }}
												</th>
												<th class="text-left small">
													{{ $t("receivablesManagement.customerName") }}
												</th>
												<th class="text-right small">
													{{ $t("receivablesManagement.amount") }}
												</th>
												<th class="text-left small">
													{{ $t("receivablesManagement.message") }}
												</th>
												<th class="text-right small">
													{{ $t("receivablesManagement.dueDate") }}
												</th>
												<th class="text-right small">
													{{ $t("receivablesManagement.created") }}
												</th>
												<th class="text-centered small">
													{{ $t("receivablesManagement.actions") }}
												</th>
											</tr>
										</thead>
										<tbody>
											<tr
												v-for="customerInvoice in customerInvoices"
												:key="customerInvoice.id"
											>
												<td class="text-left">
													{{ customerInvoice.id }}
												</td>
												<td class="text-left">
													{{ customerInvoice.debitAccountHolder }}
												</td>
												<td class="text-right">
													{{ $n(customerInvoice.totalVat, "decimal") }}
												</td>
												<td class="text-left">
													{{ customerInvoice.paymentReference }}
												</td>
												<td class="text-right">
													{{ $d(Date.parse(customerInvoice.dueDate), "short") }}
												</td>
												<td class="text-right">
													{{ $d(Date.parse(customerInvoice.created), "short") }}
												</td>
												<td class="text-right">
													<router-link
														:to="{
															name: 'invoice-details',
															params: { id: customerInvoice.id },
														}"
														class="btn btn-outline mx-1 "
														>{{ $t("receivablesManagement.view") }}</router-link
													>
													<a
														class="btn btn-danger mx-1 "
														@click="deleteCustomerInvoice(customerInvoice.id)"
														>{{ $t("receivablesManagement.delete") }}</a
													>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
								<div v-if="customerInvoices.length >= 1">
									<div class="row">
										<div class="col-md-6">
											{{ $t("itemsPerPage") }}
											<select
												class="custom-select"
												style="width: 60px;"
												v-model="pageSize"
												@change="handlePageSizeChange($event)"
											>
												<option
													v-for="size in pageSizes"
													:key="size"
													:value="size"
												>
													{{ size }}
												</option>
											</select>
										</div>
										<div class="col-md-6">
											<b-pagination
												v-model="page"
												:total-rows="count"
												:per-page="pageSize"
												:prev-text="$t('previous')"
												:next-text="$t('next')"
												align="right"
												@change="handlePageChange"
											></b-pagination>
										</div>
									</div>
								</div>
							</div>

							<div v-else>
								{{ $t("receivablesManagement.noPendingCustomerInvoices") }}
							</div>
						</div>
					</b-tab>
				</b-tabs>
			</div>
		</div>
	</div>
</template>

<script>
	import AuthService from "@/services/AuthService.js";

	export default {
		name: "customerInvoices",
		data() {
			return {
				customerInvoices: [],
				draftInvoices: [],

				msg: "",
				currentCustomerInvoice: null,

				currentIndex: -1,
				searchTitle: "",
				isloading: false,
				isloading2: false,
				page: 1,
				count: 0,
				pageSize: 10,

				pageSizes: [10, 25, 50],
			};
		},
		async created() {
			if (!this.$store.getters.isLoggedIn) {
				this.$router.push("login");
			}
			if (
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 3 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 15 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 14 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 13 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 12 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 11
			) {
				this.$router.push("overview");
			}
			let lang = this.$i18n.locale;
			this.role = this.$store.getters.getUser.role;
			this.username = this.$store.getters.getUser.username;
			this.firstname = this.$store.getters.getUser.firstname;
			this.baseCurrency = this.$store.getters.getUser.currency;
			// this.getUsersPaymentOrders();
		},
		methods: {
			getRequestParams(searchTitle, page, pageSize) {
				let params = {};

				if (searchTitle) {
					params["title"] = searchTitle;
				}

				if (page) {
					params["page"] = page - 1;
				}

				if (pageSize) {
					params["size"] = pageSize;
				}

				return params;
			},

			getRequestParams2(searchTitle, page, pageSize) {
				let params = {};

				if (searchTitle) {
					params["title"] = searchTitle;
				}

				if (page) {
					params["page"] = page - 1;
				}

				if (pageSize) {
					params["size"] = pageSize;
				}

				return params;
			},

			async getClientsCustomerInvoices() {
				const params = this.getRequestParams(
					this.searchTitle,
					this.page,
					this.pageSize
				);

				try {
					const details = {
						id: this.$store.getters.getUser.id,
					};
					this.isloading = true;
					const response = await AuthService.getClientsCustomerInvoices(
						details.id,
						params.page,
						params.size,
						params.title
					);
					const { items, totalItems } = response.data;
					this.customerInvoices = items;
					this.count = totalItems;
					this.isloading = false;
					console.log(response.data);
				} catch (error) {
					this.isloading = false;
					console.log(error);
				}
			},
			// async getClientsCustomerInvoices() {
			// 	const params = this.getRequestParams(
			// 		this.searchTitle,
			// 		this.page,
			// 		this.pageSize
			// 	);

			// 	try {
			// 		const details = {
			// 			id: this.$store.getters.getUser.id,
			// 		};
			// 		const response = await AuthService.getClientsCustomerInvoices(
			// 			details.id,
			// 			params.page,
			// 			params.size,
			// 			params.title
			// 		);
			// 		const { items, totalItems } = response.data;
			// 		this.customerInvoices = items;
			// 		this.count = totalItems;
			// 		console.log(response.data);
			// 	} catch (error) {
			// 		console.log(error);
			// 	}
			// },

			async getClientsDraftInvoices() {
				const params = this.getRequestParams2(
					this.searchTitle,
					this.page,
					this.pageSize
				);

				try {
					const details = {
						id: this.$store.getters.getUser.id,
					};
					this.isloading2 = true;
					const response = await AuthService.getClientsDraftInvoices(
						details.id,
						params.page,
						params.size,
						params.title
					);
					const { items, totalItems } = response.data;
					this.draftInvoices = items;
					this.count = totalItems;
					this.isloading2 = false;
					console.log(response.data);
				} catch (error) {
					this.isloading2 = false;
					console.log(error);
				}
			},

			handlePageChange(value) {
				this.page = value;
				this.getClientsCustomerInvoices();
				this.getClientsDraftInvoices();
			},

			handlePageSizeChange(event) {
				this.pageSize = event.target.value;
				this.page = 1;
				this.getClientsCustomerInvoices();
				this.getClientsDraftInvoices();
			},

			async deleteCustomerInvoice(id) {
				try {
					const lang = this.$i18n.locale;
					const response = await AuthService.deleteCustomerInvoice(id, lang);
					console.log(response);
					this.getClientsCustomerInvoices();
					this.msg = response.msg;
					console.log(response.msg);
				} catch (error) {
					console.log(error);
					this.msg = error.response.data.msg;
				}
			},
		},

		mounted() {
			this.getClientsCustomerInvoices();
			this.getClientsDraftInvoices();
		},
	};
</script>
