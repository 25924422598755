<template>
	<div class="col-lg-4 offset-lg-4 text-left">
		<div
			v-if="creditAccount.id === getUserId() || debitAccount.id === getUserId()"
		>
			<div class="">
				<h2 class="title-bold">
					{{ $n(payment.currencyAmount, "decimal") }}
				</h2>
				<hr />
				<div class="background-modifier">
					<div class="product-details">
						<label class="small">{{ $t("payment.id") }}</label>
						<p class="text-left">{{ payment.id }}</p>
						<label class="small">{{ $t("payment.debitAccountId") }}</label>
						<p class="text-left">
							{{ payment.debitAccountId }}
						</p>
						<label class="small">{{ $t("payment.remitter") }}</label>
						<p class="text-left">
							{{ payment.debitAccountHolder }}
						</p>
						<label class="small">{{ $t("payment.creditAccountId") }}</label>
						<p class="text-left">
							{{ payment.creditAccountId }}
						</p>
						<label class="small">{{ $t("payment.beneficiary") }}</label>
						<p class="text-left">
							{{ payment.creditAccountHolder }}
						</p>
						<label class="small">{{ $t("payment.accountCurrency") }}</label>
						<p class="text-left">
							{{ payment.baseCurrency }}
						</p>
						<label class="small">{{ $t("payment.amount") }}</label>
						<p class="text-left">
							{{ $n(payment.amount, "decimal") }}
						</p>
						<label class="small">{{ $t("payment.paymentCurrency") }}</label>
						<p class="text-left">
							{{ payment.paymentCurrency }}
						</p>
						<label class="small">{{ $t("payment.currencyAmount") }}</label>
						<p class="text-left">
							{{ $n(payment.currencyAmount, "decimal") }}
						</p>
						<label class="small">{{ $t("payment.message") }}</label>
						<p class="text-left">
							{{ payment.message }}
						</p>
						<label class="small">{{ $t("payment.date") }}</label>
						<p class="text-left">
							{{ $d(Date.parse(payment.created), "long") }}
						</p>
					</div>
				</div>
			</div>
		</div>
		<div v-else>
			<h3>{{ $t("unauthorized") }}</h3>
		</div>
	</div>
</template>

<script>
	import AuthService from "@/services/AuthService.js";

	export default {
		name: "TransactionDetail",
		data() {
			return {
				payment: "",
				creditAccount: "",
				debitAccount: "",
			};
		},
		async created() {
			if (!this.$store.getters.isLoggedIn) {
				this.$router.push("login");
			}
			this.getPaymentById();
		},
		methods: {
			getUserId() {
				const userId = this.$store.getters.getUser.id;
				return userId;
			},
			async getTxCreditAccount(id) {
				try {
					const id = this.$route.params.id;
					const response = await AuthService.getTxCreditAccount(id);
					this.creditAccount = response;

					console.log(response);
				} catch (error) {
					console.log(error);
				}
			},
			async getTxDebitAccount(id) {
				try {
					const id = this.$route.params.id;
					const response = await AuthService.getTxDebitAccount(id);
					this.debitAccount = response;

					console.log(response);
				} catch (error) {
					console.log(error);
				}
			},
			async getPaymentById(id) {
				try {
					const id = this.$route.params.id;
					const response = await AuthService.getPaymentById(id);
					this.payment = response;
					console.log(response);
				} catch (error) {
					console.log(error);
				}
			},
		},
		mounted() {
			this.getPaymentById();
			this.getUserId();
			this.getTxCreditAccount();
			this.getTxDebitAccount();
		},
	};
</script>
