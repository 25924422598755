<template>
	<ValidationObserver ref="observer" v-slot="{ handleSubmit, reset }">
		<b-form
			@submit.prevent="handleSubmit(addBusinessRole)"
			@reset.prevent="reset"
		>
			<div class="col-lg-4 offset-lg-4 text-left">
				<h2 class="title-bold">
					{{ $t("businessSignup.addANewBusinessRole") }}
				</h2>
				<hr />
				<div v-if="newRole == 1">
					<h4 class="title-bold">{{ $t("businessSignup.ubo") }}</h4>
				</div>
				<div v-if="newRole == 2">
					<h4 class="title-bold">{{ $t("businessSignup.shareholder") }}</h4>
				</div>
				<div v-if="newRole == 3">
					<h4 class="title-bold">{{ $t("businessSignup.director") }}</h4>
				</div>
				<div v-if="newRole == 4">
					<h4 class="title-bold">{{ $t("businessSignup.decisionMaker") }}</h4>
				</div>
				<div v-if="newRole == 5">
					<h4 class="title-bold">{{ $t("businessSignup.signatory") }}</h4>
				</div>
				<hr />
				<div class="background-outline">
					<div class="background-modifier text-left">
						<label class="small">{{ $t("businessSignup.role") }} </label>
						<div v-if="newRole == 1">
							<p class="">{{ $t("businessSignup.ubo") }}</p>
						</div>
						<div v-if="newRole == 2">
							<p class="">{{ $t("businessSignup.shareholder") }}</p>
						</div>
						<div v-if="newRole == 3">
							<p class="">{{ $t("businessSignup.director") }}</p>
						</div>
						<div v-if="newRole == 4">
							<p class="">{{ $t("businessSignup.decisionMaker") }}</p>
						</div>
						<div v-if="newRole == 5">
							<p class="">{{ $t("businessSignup.signatory") }}</p>
						</div>
						<div class="text-left">
							<b-form-group label="" v-slot="{ ariaDescribedby }">
								<b-form-radio
									v-model="selected"
									:aria-describedby="ariaDescribedby"
									name="radio-inline"
									value="1"
									>{{ $t("businessSignup.naturalPerson") }}</b-form-radio
								>
								<b-form-radio
									v-model="selected"
									:aria-describedby="ariaDescribedby"
									name="radio-inline"
									value="2"
									>{{ $t("businessSignup.legalPerson") }}</b-form-radio
								>
							</b-form-group>
						</div>
					</div>
					<br />

					<div v-if="selected == 1">
						<div class="background-modifier">
							<label class="small">{{ $t("businessSignup.firstname") }} </label>
							<ValidationProvider
								rules="required|alpha_spaces|min:2|max:50"
								name="firstname"
								v-slot="{ valid, errors }"
							>
								<b-form-group label-for="firstnameInput" description="">
									<b-form-input
										type="text"
										v-model="firstname"
										:state="errors[0] ? false : valid ? true : null"
										:placeholder="$t('businessSignup.firstname')"
									></b-form-input>
									<b-form-invalid-feedback id="inputLiveFeedback">{{
										errors[0]
									}}</b-form-invalid-feedback>
								</b-form-group>
							</ValidationProvider>

							<label class="small">{{ $t("businessSignup.lastname") }} </label>
							<ValidationProvider
								rules="required|alpha_spaces|min:2|max:50"
								name="lastname"
								v-slot="{ valid, errors }"
							>
								<b-form-group label-for="lastnameInput" description="">
									<b-form-input
										type="text"
										v-model="lastname"
										:state="errors[0] ? false : valid ? true : null"
										:placeholder="$t('businessSignup.lastname')"
									></b-form-input>
									<b-form-invalid-feedback id="inputLiveFeedback">{{
										errors[0]
									}}</b-form-invalid-feedback>
								</b-form-group>
							</ValidationProvider>
						</div>
						<br />
					</div>

					<div v-if="selected == 2">
						<div class="background-modifier">
							<label class="small">{{ $t("businessSignup.name") }} </label>
							<ValidationProvider
								rules="required|alpha_spaces|min:2|max:50"
								name="name"
								v-slot="{ valid, errors }"
							>
								<b-form-group label-for="nameInput" description="">
									<b-form-input
										type="text"
										v-model="name"
										:state="errors[0] ? false : valid ? true : null"
										:placeholder="$t('businessSignup.name')"
									></b-form-input>
									<b-form-invalid-feedback id="inputLiveFeedback">{{
										errors[0]
									}}</b-form-invalid-feedback>
								</b-form-group>
							</ValidationProvider>
						</div>
						<br />
					</div>

					<!--  <div class="background-modifier">
          <ValidationProvider
            rules="required|min:6|max:30"
            name="password"
            vid="password"
            v-slot="{ valid, errors }"
          >
            <b-form-group description="">
              <b-form-input
                type="password"
                v-model="password"
                :state="errors[0] ? false : valid ? true : null"
                :placeholder="$t('businessSignup.password')"
              ></b-form-input>
              <b-form-invalid-feedback id="inputLiveFeedback">{{
                errors[0]
              }}</b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider
            rules="required|confirmed:password"
            name="Password confirmation"
            v-slot="{ valid, errors }"
          >
            <b-form-group label="" label-for="repeatPasswordInput1">
              <b-form-input
                type="password"
                v-model="password_repeat"
                :state="errors[0] ? false : valid ? true : null"
                :placeholder="$t('businessSignup.repeatPassword')"
              ></b-form-input>
              <b-form-invalid-feedback id="inputLiveFeedback">{{
                errors[0]
              }}</b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </div>
        <br /> -->
					<div class="background-modifier">
						<label class="small">{{ $t("businessSignup.address") }} </label>
						<ValidationProvider
							rules="required|min:5|max:50"
							name="addressLine1"
							v-slot="{ valid, errors }"
						>
							<b-form-group label-for="addressLine1Input" description="">
								<b-form-input
									type="text"
									v-model="addressLine1"
									:state="errors[0] ? false : valid ? true : null"
									:placeholder="$t('businessSignup.address')"
								></b-form-input>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>
						<label class="small">{{ $t("businessSignup.city") }} </label>
						<ValidationProvider
							rules="required|min:5|max:50"
							name="city"
							v-slot="{ valid, errors }"
						>
							<b-form-group label-for="cityInput" description="">
								<b-form-input
									type="text"
									v-model="city"
									:state="errors[0] ? false : valid ? true : null"
									:placeholder="$t('businessSignup.city')"
								></b-form-input>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>
						<label class="small">{{ $t("businessSignup.postCode") }} </label>
						<ValidationProvider
							rules="required|min:3|max:10"
							name="zip"
							v-slot="{ valid, errors }"
						>
							<b-form-group label-for="zipInput" description="">
								<b-form-input
									type="text"
									v-model="zip"
									:state="errors[0] ? false : valid ? true : null"
									:placeholder="$t('businessSignup.postCode')"
								></b-form-input>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>
						<label class="small">{{ $t("businessSignup.country") }} </label>
						<ValidationProvider
							rules="required"
							name="country"
							v-slot="{ valid, errors }"
						>
							<b-form-group id="countryInputGroup" label-for="countryInput">
								<b-form-select
									@change="onChange"
									id="countryInput"
									:state="errors[0] ? false : valid ? true : null"
									v-model="country"
									><option
										v-for="country in countries"
										:value="country.countryCode"
										:key="country.countryCode"
										>{{ countryNames.getName(country.countryCode, codeLang) }}
									</option>
									<template #first>
										<b-form-select-option value="" disabled>{{
											$t("businessSignup.country")
										}}</b-form-select-option>
									</template></b-form-select
								>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>
					</div>
					<br />
					<div class="background-modifier">
						<div class="row">
							<div class="col-md-6">
								<label class="small">{{ $t("businessSignup.country") }} </label>
								<ValidationProvider
									rules="required"
									name="prefix"
									v-slot="{ valid, errors }"
								>
									<b-form-group id="prefixInputGroup" label-for="prefixInput">
										<b-form-select
											@change="onChange"
											id="prefixInput"
											:state="errors[0] ? false : valid ? true : null"
											v-model="prefix"
											><option
												v-for="country in countries"
												:value="country.prefix"
												:key="country.prefix"
												>+ {{ country.prefix }}
												{{
													countryNames.getName(country.countryCode, codeLang)
												}}
											</option>
											<template #first>
												<b-form-select-option value="" disabled>{{
													$t("businessSignup.country")
												}}</b-form-select-option>
											</template></b-form-select
										>
										<b-form-invalid-feedback id="inputLiveFeedback">{{
											errors[0]
										}}</b-form-invalid-feedback>
									</b-form-group>
								</ValidationProvider>
							</div>
							<div class="col-md-6">
								<label class="small"
									>{{ $t("businessSignup.phoneNumber") }}
								</label>
								<ValidationProvider
									rules="required|numeric|min:5|max:12"
									name="mobile"
									v-slot="{ valid, errors }"
								>
									<b-form-group label-for="mobileInput" description="">
										<b-form-input
											type="text"
											v-model="mobile"
											:state="errors[0] ? false : valid ? true : null"
											:placeholder="$t('businessSignup.phoneNumber')"
										></b-form-input>
										<b-form-invalid-feedback id="inputLiveFeedback">{{
											errors[0]
										}}</b-form-invalid-feedback>
									</b-form-group>
								</ValidationProvider>
							</div>
						</div>
					</div>
					<br />

					<div class="background-modifier">
						<label class="small">{{ $t("businessSignup.email") }} </label>
						<ValidationProvider
							rules="required|email"
							name="email"
							v-slot="{ valid, errors }"
						>
							<b-form-group label-for="emailInput" description="">
								<b-form-input
									type="email"
									v-model="email"
									:state="errors[0] ? false : valid ? true : null"
									:placeholder="$t('businessSignup.email')"
								></b-form-input>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>
					</div>

					<br />
					<div class="background-modifier text-left">
						<div>
							<div v-if="selected == 1">
								<label class="small" for="datepicker-placeholder">{{
									$t("businessSignup.dob")
								}}</label>
							</div>
							<div v-if="selected == 2">
								<label for="datepicker-placeholder">{{
									$t("businessSignup.dateOfIncorporation")
								}}</label>
							</div>
							<b-form-datepicker
								id="datepicker-placeholder"
								:placeholder="$t('businessSignup.dob')"
								v-model="dob"
								:min="min"
								:max="max"
								:locale="$i18n.locale"
								:label-no-date-selected="$t('businessSignup.noDateSelected')"
								:label-help="
									$t('businessSignup.useCursorKeysToNavigateCalendarDates')
								"
							></b-form-datepicker>
						</div>
					</div>
					<br />
					<!--  <div class="background-modifier">
            <ValidationProvider
              rules="required|numeric|min:6|max:6"
              name="pin"
              v-slot="{ valid, errors }"
            >
              <b-form-group label-for="pinInput" description="">
                <b-form-input
                  type="text"
                  v-model="pin"
                  :state="errors[0] ? false : valid ? true : null"
                  :placeholder="$t('businessSignup.pin')"
                ></b-form-input>
                <b-form-invalid-feedback id="inputLiveFeedback">{{
                  errors[0]
                }}</b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>
          </div> -->
					<div class="background-modifier text-left">
						<label class="small">{{ $t("businessSignup.nationality") }}</label>
						<ValidationProvider
							rules="required"
							name="nationality"
							v-slot="{ valid, errors }"
						>
							<b-form-group id="countryInputGroup" label-for="nationalityInput">
								<b-form-select
									@change="onChange"
									id="nationalityInput"
									:state="errors[0] ? false : valid ? true : null"
									v-model="nationality"
									><option
										v-for="country in countries"
										:value="country.countryCode"
										:key="country.countryCode"
										>{{ countryNames.getName(country.countryCode, codeLang) }}
									</option>
									<template #first>
										<b-form-select-option value="" disabled>{{
											$t("businessSignup.nationality")
										}}</b-form-select-option>
									</template></b-form-select
								>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>
					</div>
					<hr />

					<div v-if="newRole == 1 || newRole == 2">
						<div class="background-modifier text-left">
							<label class="small">{{ $t("businessSignup.ownership") }} </label>
							<ValidationProvider
								rules="required|min:1|max:5"
								name="ownership"
								v-slot="{ valid, errors }"
							>
								<b-form-group label-for="ownershipInput" description="">
									<b-form-input
										type="text"
										v-model="ownership"
										:state="errors[0] ? false : valid ? true : null"
										:placeholder="$t('businessSignup.ownership')"
									></b-form-input>
									<b-form-invalid-feedback id="inputLiveFeedback">{{
										errors[0]
									}}</b-form-invalid-feedback>
								</b-form-group>
							</ValidationProvider>

							<label class="small">{{ $t("businessSignup.votes") }} </label>
							<ValidationProvider
								rules="required|min:1|max:5"
								name="votes"
								v-slot="{ valid, errors }"
							>
								<b-form-group label-for="votesInput" description="">
									<b-form-input
										type="text"
										v-model="votes"
										:state="errors[0] ? false : valid ? true : null"
										:placeholder="$t('businessSignup.votes')"
									></b-form-input>
									<b-form-invalid-feedback id="inputLiveFeedback">{{
										errors[0]
									}}</b-form-invalid-feedback>
								</b-form-group>
							</ValidationProvider>
						</div>
						<hr />
					</div>

					<div v-if="selected == 1">
						<b-form-checkbox
							class="text-left"
							id="checkbox-1"
							v-model="status"
							name="checkbox-1"
							value="1"
							unchecked-value="0"
						>
							{{ $t("businessSignup.thePersonIsPEP") }}
						</b-form-checkbox>
						<br />

						<p class="text-left text-small">
							[{{ $t("businessSignup.definitionOfPEP") }}]
						</p>
						<hr />
					</div>
					<!-- disabled-->
					<!-- <div v-if="status == '1'"> -->
					<b-button
						type="submit"
						@click="isloading"
						class="btn btn-info text-light btn-block"
						>{{ $t("businessSignup.submit")
						}}<b-spinner
							v-if="isloading"
							class="ml-auto"
							:variant="light"
						></b-spinner
					></b-button>
					<!-- <p class="small">{{ $t("businessSignup.notAvailable") }}</p> -->
					<!-- </div> -->
					<hr />
					<b-button
						type="reset"
						class="btn btn-alert text-light btn-block"
						@click="resetForm()"
						>{{ $t("reset") }}</b-button
					>

					<div v-if="msg && $bvToast.show('notification-toast')"></div>
					<div class="action">
						<br />
						<b-toast
							id="notification-toast"
							variant="secondary"
							static
							no-auto-hide
						>
							<template #toast-title>
								<div class="d-flex flex-grow-1 align-items-baseline">
									<b-img
										class="logo mb-2"
										:src="require('/public/images/logo.png')"
										alt=""
										width="12"
										height="12"
									></b-img>
									<strong class="mr-auto">{{ $t("notification") }}</strong>
									<small class="text-muted mr-2"></small>
								</div>
							</template>
							{{ msg }}
						</b-toast>
					</div>
				</div>
			</div>
		</b-form>
	</ValidationObserver>
</template>

<script>
	import { ValidationObserver, ValidationProvider } from "vee-validate";
	import AuthService from "@/services/AuthService.js";
	var countryNames = require("i18n-iso-countries");
	export default {
		name: "AddBusinessRole",
		components: {
			ValidationObserver,
			ValidationProvider,
		},
		/*props: {
	     codeLang: {
	       type: String,
	       default: "en",
	     },
	   },*/
		//this.codeLang
		async created() {
			if (!this.$store.getters.isLoggedIn) {
				this.$router.push("login");
			}
			if (
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 3 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 15 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 14
			) {
				this.$router.push("overview");
			}
			this.role = this.$store.getters.getUser.role;
			this.username = this.$store.getters.getUser.username;
			this.firstname = this.$store.getters.getUser.firstname;
			this.newRole = this.$route.params.reference;
			let lang = this.$i18n.locale;
			this.codeLang = this.$i18n.locale;
			this.countryNames.registerLocale(
				require(`i18n-iso-countries/langs/${this.codeLang}.json`)
			);
		},
		watch: {
			codeLang(newVal, oldVal) {
				this.countryNames.registerLocale(
					require(`i18n-iso-countries/langs/${newVal}.json`)
				);
			},
		},
		data() {
			const now = new Date();
			const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
			// 15th two months prior
			const minDate = new Date(today);
			minDate.setYear(minDate.getFullYear() - 300);
			const maxDate = new Date(today);
			maxDate.setYear(maxDate.getFullYear() - 0);
			// minDate.setMonth(minDate.getMonth() - 2);
			// minDate.setDate(15);
			// 15th in two months

			return {
				countries: [],
				countryNames: countryNames,
				role: "",
				selected: 1,
				/*  username: "", */
				email: "",
				name: "",
				firstname: "",
				lastname: "",
				/*  password: "",
	     password_repeat: "", */
				msg: "",
				/*  pin: "", */
				dob: maxDate.toISOString().slice(0, 10),
				addressLine1: "",
				city: "",
				zip: "",
				country: "",
				prefix: "",
				mobile: "",
				value: "",
				min: minDate,
				max: maxDate,
				status: 0,
				nationality: "",
				ownership: "",
				votes: "",
				newRole: "",
				isloading: false,
			};
		},

		methods: {
			async addBusinessRole() {
				try {
					const credentials = {
						lang: this.$i18n.locale,
						clientId: this.$route.params.id,
						role: this.$route.params.reference /*NEW*/,
						/* username: this.username, */
						email: this.email,
						name: this.name,
						firstname: this.firstname,
						lastname: this.lastname,
						/*  password: this.password,
	         password_repeat: this.password_repeat, */
						/*   pin: this.pin, */
						dob: this.dob,
						addressLine1: this.addressLine1,
						city: this.city,
						zip: this.zip,
						country: this.country,
						phoneNumber: "+" + this.prefix + this.mobile,
						nationality: this.nationality /*NEW*/,
						ownership: this.ownership /*NEW*/,
						votes: this.votes /*NEW*/,
						pep: this.status /*NEW*/,
						entityType: this.selected,
					};
					//let reference = this.$route.params.reference;
					this.isloading = true;
					const response = await AuthService.addBusinessRole(credentials);
					this.resetForm();
					this.msg = response.msg;
					this.isloading = false;
				} catch (error) {
					this.msg = error.response.data.msg;
					this.isloading = false;
					/*this.$router.push('home');*/
				}
			},
			resetForm() {
				this.role = ""; /*NEW*/
				/* this.username = ""; */
				this.name = "";
				this.firstname = "";
				(this.lastname = ""), (this.email = "");
				/*  this.password = "";
	     this.password_repeat = ""; */
				this.msg = "";
				/* this.pin = ""; */
				this.dob = "";
				this.addressLine1 = "";
				this.city = "";
				this.zip = "";
				this.country = "";
				this.prefix = "";
				this.mobile = "";
				this.nationality = ""; /*NEW*/
				this.ownership = ""; /*NEW*/
				this.votes = ""; /*NEW*/
				this.status = 0; /*NEW*/
				this.selected = 1;
				requestAnimationFrame(() => {
					this.$refs.observer.reset();
				});
			},
			async getCountries() {
				try {
					const response = await AuthService.getCountries();
					this.countries = response;
					console.log(response);
				} catch (error) {
					console.log(error);
				}
			},
		},
		mounted() {
			this.getCountries();
		},
	};
</script>
