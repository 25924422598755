<template>
	<ValidationObserver ref="observer" v-slot="{ handleSubmit, reset }">
		<b-form
			@submit.prevent="handleSubmit(manageBusinessCardDetails)"
			@reset.prevent="reset"
		>
			<div class="col-lg-4 offset-lg-4">
				<h2 class="title-bold text-left">
					{{ $t("manageCard.manageCard") }}
				</h2>
				<hr />
				<!-- <div class="background-modifier text-left">
          <p class="">
            {{ $t("manageCard.chooseUser") }}
          </p>
          <ValidationProvider
            name="userId"
            rules="required"
            v-slot="{ valid, errors }"
          >
            <b-form-group id="userIdInputGroup" label-for="userIdInput">
              <b-form-select
                id="userIdInput"
                :state="errors[0] ? false : valid ? true : null"
                v-model="card.userId"
                ><option v-for="user in users" :value="user.id" :key="user.id"
                  >{{ user.id }} {{ user.firstname }}
                  {{ user.lastname }}</option
                >
                <template #first>
                  <b-form-select-option value="" disabled>{{
                    $t("manageCard.userId")
                  }}</b-form-select-option>
                </template></b-form-select
              >
              <b-form-invalid-feedback id="inputLiveFeedback">{{
                errors[0]
              }}</b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>

          <p class="">
            {{ $t("manageCard.chooseLinkedAccount") }}
          </p>
          <ValidationProvider
            rules="required"
            name="debitAccountId"
            v-slot="{ valid, errors }"
          >
            <b-form-group
              id="debitAccountIdInputGroup"
              label-for="debitAccountIdInput"
            >
              <b-form-select
                @change="onChange"
                id="debitAccountIdInput"
                :state="errors[0] ? false : valid ? true : null"
                v-model="card.debitAccountId"
                ><option
                  v-for="account in accounts"
                  :value="account.id"
                  :key="account.id"
                  >{{ account.iban }}
                  {{ $n(account.credits - account.debits, "decimal") }}
                  {{ account.currency }}</option
                >
                <template #first>
                  <b-form-select-option value="" disabled>{{
                    $t("manageCard.debitAccountId")
                  }}</b-form-select-option>
                </template></b-form-select
              >
              <b-form-invalid-feedback id="inputLiveFeedback">{{
                errors[0]
              }}</b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider> -->

				<!-- <ValidationProvider
            rules="required"
            name="currency"
            v-slot="{ valid, errors }"
          >
            <b-form-group
              id="accountCurrencyInputGroup"
              label-for="accountCurrencyInput"
            >
              <b-form-select
                id="accountCurrencyInput"
                :state="errors[0] ? false : valid ? true : null"
                v-model="card.currency"
                ><option
                  v-for="currency in currencies"
                  :value="currency.code"
                  :key="currency.code"
                  >{{ currency.code }}</option
                >
                <template #first>
                  <b-form-select-option value="" disabled>{{
                    $t("manageCard.currency")
                  }}</b-form-select-option>
                </template></b-form-select
              >
              <b-form-invalid-feedback id="inputLiveFeedback">{{
                errors[0]
              }}</b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider> -->

				<!-- <ValidationProvider
            rules="alpha_spaces|min:3|max:50"
            name="nickname"
            v-slot="{ valid, errors }"
          >
            <b-form-group
              class="text-left"
              label-for="lastnameInput"
              :description="$t('manageCard.nicknameVoluntary')"
            >
              <b-form-input
                type="text"
                v-model="card.nickname"
                :state="errors[0] ? false : valid ? true : null"
                :placeholder="$t('manageCard.nickname')"
              ></b-form-input>
              <b-form-invalid-feedback id="inputLiveFeedback">{{
                errors[0]
              }}</b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider> -->
				<!-- </div>
        <br /> -->
				<!-- <div class="background-modifier text-left">
          <h4 class="title-bold">{{ $t("manageCard.specifications") }}</h4>
          <hr />
          <p class="">
            {{ $t("manageCard.chooseCardType") }}
          </p>
          <b-form-group label="" v-slot="{ chooseCardType }">
            <b-form-radio
              v-model="card.cardType"
              :aria-describedby="chooseCardType"
              name="chooseCardType"
              value="0"
              >{{ $t("manageCard.standard") }}</b-form-radio
            >
            <br />
            <b-form-radio
              v-model="card.cardType"
              :aria-describedby="chooseCardType"
              name="chooseCardType"
              value="1"
              >{{ $t("manageCard.premium") }}</b-form-radio
            >
          </b-form-group>
          <hr />
          <p class="">
            {{ $t("manageCard.chooseCardColor") }}
          </p>
          <b-form-group label="" v-slot="{ chooseCardColor }">
            <b-form-radio
              v-model="card.cardColor"
              :aria-describedby="chooseCardColor"
              name="chooseCardColor"
              value="1"
              >{{ $t("manageCard.silver") }}</b-form-radio
            >
            <br />
            <b-form-radio
              v-model="card.cardColor"
              :aria-describedby="chooseCardColor"
              name="chooseCardColor"
              value="2"
              >{{ $t("manageCard.gold") }}</b-form-radio
            >
            <br />
            <div v-if="cardType == '1'">
              <b-form-radio
                v-model="card.cardColor"
                :aria-describedby="chooseCardColor"
                name="chooseCardColor"
                value="0"
                >{{ $t("manageCard.black") }}</b-form-radio
              >
              <br />
              <b-form-radio
                v-model="card.cardColor"
                :aria-describedby="chooseCardColor"
                name="chooseCardColor"
                value="3"
                >{{ $t("manageCard.rose") }}</b-form-radio
              >
            </div>
          </b-form-group>
        </div>
        <br /> -->
				<div class="background-outline">
					<div class="background-modifier text-left">
						<h4 class="title-bold">{{ $t("manageCard.pin") }}</h4>
						<hr />
						<label class="small">{{ $t("manageCard.choosePinCode") }}</label>

						<div class="">
							<ValidationProvider
								rules="alpha_dash|min:4|max:6"
								name="cardPin"
								v-slot="{ valid, errors }"
							>
								<b-form-group label-for="pinInput" description="">
									<b-form-input
										@input=""
										type="text"
										v-model="card.cardPin"
										:state="errors[0] ? false : valid ? true : null"
										:placeholder="$t('manageCard.pin')"
									></b-form-input>
									<b-form-invalid-feedback id="inputLiveFeedback">{{
										errors[0]
									}}</b-form-invalid-feedback>
								</b-form-group>
							</ValidationProvider>
						</div>
						<br />
					</div>
					<br />
					<div class="background-modifier text-left">
						<h4 class="title-bold">{{ $t("manageCard.authorizations") }}</h4>
						<hr />
						<label class="small">{{ $t("manageCard.paymentLimit") }}</label>

						<ValidationProvider
							rules="required|numeric|min_value:100.00|max_value:10000.00"
							name="paymentLimit"
							v-slot="{ valid, errors }"
						>
							<b-form-group label-for="paymentLimitInput" description="">
								<b-form-input
									@input=""
									type="text"
									v-model="card.paymentLimit"
									:state="errors[0] ? false : valid ? true : null"
									:placeholder="$t('manageCard.paymentLimit')"
								></b-form-input>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>
						<br />
						<b-form-checkbox
							class="text-left"
							id="checkbox-1"
							v-model="card.paymentsAbroad"
							name="checkbox-1"
							value="1"
							unchecked-value="0"
							switch
						>
							{{ $t("manageCard.paymentsAbroad") }}
							<!-- <b>({{ paymentsAbroad }})</b> -->
						</b-form-checkbox>
						<br />
						<b-form-checkbox
							class="text-left"
							id="checkbox-2"
							v-model="card.onlinePayments"
							name="checkbox-2"
							value="1"
							unchecked-value="0"
							switch
						>
							{{ $t("manageCard.onlinePayments") }}
							<!-- <b>({{ onlinePayments }})</b> -->
						</b-form-checkbox>
						<br />
						<b-form-checkbox
							class="text-left"
							id="checkbox-3"
							v-model="card.atmWithdrawals"
							name="checkbox-3"
							value="1"
							unchecked-value="0"
							switch
						>
							{{ $t("manageCard.atmWithdrawals") }}
							<!-- <b>({{ atmWithdrawals }})</b> -->
						</b-form-checkbox>
						<br />

						<b-form-checkbox
							class="text-left"
							id="checkbox-4"
							v-model="card.locked"
							name="checkbox-4"
							value="1"
							unchecked-value="0"
							switch
						>
							{{ $t("manageCard.blockCard") }}
							<!-- <b>({{ locked }})</b> -->
						</b-form-checkbox>
						<br />
					</div>

					<!-- <b-form-checkbox
          class="text-left"
          id="checkbox-5"
          v-model="status"
          name="checkbox-5"
          value="accepted"
          unchecked-value="not_accepted"
        >
          {{ $t("signup.acceptTerms") }}
        </b-form-checkbox>
        <br />

        <div v-if="status == 'accepted'"> -->
					<hr />
					<b-button
						type="submit"
						@click="isloading"
						class="btn btn-info text-light btn-block"
						>{{ $t("manageCard.submit")
						}}<b-spinner
							v-if="isloading"
							class="ml-auto"
							:variant="light"
						></b-spinner
					></b-button>
					<!-- </div> -->
					<hr />
					<b-button
						type="reset"
						class="btn btn-alert text-light btn-block"
						@click="resetForm()"
						>{{ $t("reset") }}</b-button
					>

					<div v-if="msg && $bvToast.show('notification-toast')"></div>
					<div class="action">
						<br />
						<b-toast
							id="notification-toast"
							variant="secondary"
							static
							no-auto-hide
						>
							<template #toast-title>
								<div class="d-flex flex-grow-1 align-items-baseline">
									<b-img
										class="logo mb-2"
										:src="require('/public/images/logo.png')"
										alt=""
										width="12"
										height="12"
									></b-img>
									<strong class="mr-auto">{{ $t("notification") }}</strong>
									<small class="text-muted mr-2"></small>
								</div>
							</template>
							{{ msg }}
						</b-toast>
					</div>
				</div>
			</div>
		</b-form>
	</ValidationObserver>
</template>

<script>
	import { ValidationObserver, ValidationProvider } from "vee-validate";
	import AuthService from "@/services/AuthService.js";

	export default {
		name: "ManageBusinessCardForm",
		components: {
			ValidationObserver,
			ValidationProvider,
		},
		data() {
			return {
				card: "",
				// currencies: [],
				// users: [],
				// accounts: [],
				//currency: "EUR",
				// nickname: this.$t("manageCard.myCard"),
				// userId: "",
				// debitAccountId: "",
				// cardType: 0,
				// cardColor: 1,
				// cardPin: "",
				// cardStatus: 1,
				// paymentsAbroad: 1,
				// onlinePayments: 1,
				// atmWithdrawals: 1,
				// paymentLimit: 2000.0,
				// locked: 0,
				msg: "",
				status: "not_accepted",
				isloading: false,
			};
		},
		async created() {
			if (!this.$store.getters.isLoggedIn) {
				this.$router.push("login");
			}

			if (
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 3 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 15 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 14 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 13
			) {
				this.$router.push("overview");
			}
			let lang = this.$i18n.locale;
			this.role = this.$store.getters.getUser.role;
			this.username = this.$store.getters.getUser.username;
			this.firstname = this.$store.getters.getUser.firstname;
		},
		methods: {
			// async getAllAccountCurrencies() {
			//   try {
			//     const response = await AuthService.getAllAccountCurrencies();
			//     this.currencies = response;
			//     console.log(response);
			//   } catch (error) {
			//     console.log(error);
			//   }
			// },
			// async getClientsAccounts() {
			//   try {
			//     const id = this.$store.getters.getUser.id;
			//     const response = await AuthService.getClientsAccounts(id);
			//     this.accounts = response;
			//     console.log(response);
			//   } catch (error) {
			//     console.log(error);
			//   }
			// },
			// async getClientsUsers() {
			//   try {
			//     const id = this.$store.getters.getUser.id;
			//     const response = await AuthService.getClientsUsers(id);
			//     this.users = response;
			//     console.log(response);
			//   } catch (error) {
			//     console.log(error);
			//   }
			// },
			getCardId() {
				const cardId = this.$route.params.id;
				return cardId;
			},
			getUserId() {
				const userId = this.$store.getters.getUser.id;
				return userId;
			},

			async getCardById(id) {
				try {
					const id = this.$route.params.id;
					const response = await AuthService.getCardById(id);
					this.card = response;
					console.log(response);
				} catch (error) {
					console.log(error);
				}
			},
			async manageBusinessCardDetails(id, details) {
				try {
					const id = this.getCardId();
					const details = {
						lang: this.$i18n.locale,
						// clientId: this.$route.params.id,
						// userId: this.card.userId,
						// accountId: this.card.debitAccountId,
						// cardColor: this.card.cardColor,
						// cardType: this.card.cardType,
						// cardStatus: this.card.cardStatus,
						cardPin: this.card.cardPin,
						locked: this.card.locked,
						paymentsAbroad: this.card.paymentsAbroad,
						onlinePayments: this.card.onlinePayments,
						atmWithdrawals: this.card.atmWithdrawals,
						paymentLimit: this.card.paymentLimit,
						// nickname: this.nickname,
					};
					this.isloading = true;
					const response = await AuthService.manageBusinessCardDetails(
						id,
						details
					);
					// this.resetForm();
					this.msg = response.msg;
					this.isloading = false;
				} catch (error) {
					this.msg = error.response.data.msg;
					this.isloading = false;
					/*this.$router.push('accounts');*/
				}
			},
			resetForm() {
				// this.nickname = "";
				this.msg = "";
				requestAnimationFrame(() => {
					this.$refs.observer.reset();
				});
			},
		},
		mounted() {
			this.getCardById();
			this.getUserId();
			// this.getAllAccountCurrencies();
			// this.getClientsAccounts();
			// this.getClientsUsers();
		},
	};
</script>
