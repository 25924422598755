<template>
	<ValidationObserver ref="observer" v-slot="{ handleSubmit, reset }">
		<b-form
			@submit.prevent="handleSubmit(editTransaction)"
			@reset.prevent="reset"
		>
			<div class="col-md-4 offset-md-4 text-left">
				<h2 class="title-bold">
					{{ $t("editTransaction.editTransaction") }} {{ transaction.id }}
				</h2>
				<hr />
				<div class="background-outline">
					<div class="background-modifier">
						<label class="text-left small">{{
							$t("editTransaction.debitAccountId")
						}}</label>
						<ValidationProvider
							rules="required|integer"
							name="debitAccounId"
							v-slot="{ valid, errors }"
						>
							<b-form-group label-for="debitAccountIdInput" description="">
								<b-form-input
									type="text"
									v-model="transaction.debitAccountId"
									:state="errors[0] ? false : valid ? true : null"
									:placeholder="$t('editTransaction.debitAccountId')"
								></b-form-input>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>
						<label class="text-left small">{{
							$t("editTransaction.creditAccountId")
						}}</label>
						<ValidationProvider
							rules="required|integer"
							name="creditAccounId"
							v-slot="{ valid, errors }"
						>
							<b-form-group label-for="creditAccountIdInput" description="">
								<b-form-input
									type="text"
									v-model="transaction.creditAccountId"
									:state="errors[0] ? false : valid ? true : null"
									:placeholder="$t('editTransaction.creditAccountId')"
								></b-form-input>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>
					</div>
					<br />
					<div class="background-modifier">
						<label class="text-left small">{{
							$t("editTransaction.amount")
						}}</label>
						<ValidationProvider
							rules="required|double|max_value:1000000|min_value:0.01"
							name="amount"
							v-slot="{ valid, errors }"
						>
							<b-form-group label-for="amountInput" description="">
								<b-form-input
									type="text"
									v-model="transaction.amount"
									:state="errors[0] ? false : valid ? true : null"
									:placeholder="$t('editTransaction.amount')"
								></b-form-input>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>
					</div>
					<br />
					<div class="background-modifier">
						<label class="text-left small">{{
							$t("editTransaction.message")
						}}</label>
						<ValidationProvider
							rules="min:1|max:50"
							name="message"
							v-slot="{ valid, errors }"
						>
							<b-form-group label-for="messageInput" description="">
								<b-form-input
									type="text"
									v-model="transaction.message"
									:state="errors[0] ? false : valid ? true : null"
									:placeholder="$t('editTransaction.message')"
								></b-form-input>
								<b-form-invalid-feedback id="inputLiveFeedback">{{
									errors[0]
								}}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>
					</div>
					<br />
					<b-button
						type="submit"
						@click="isloading"
						class="btn btn-info text-light btn-block"
						>{{ $t("editTransaction.submit")
						}}<b-spinner
							v-if="isloading"
							class="ml-auto"
							:variant="light"
						></b-spinner
					></b-button>
					<!--   <hr/>
              <b-button type="reset" class="btn btn-alert text-light btn-block" @click="resetForm()">{{ $t('reset') }}</b-button> -->

					<div v-if="msg && $bvToast.show('notification-toast')"></div>
					<div class="action">
						<br />
						<b-toast
							id="notification-toast"
							variant="secondary"
							static
							no-auto-hide
						>
							<template #toast-title>
								<div class="d-flex flex-grow-1 align-items-baseline">
									<b-img
										class="logo mb-2"
										:src="require('/public/images/logo.png')"
										alt=""
										width="12"
										height="12"
									></b-img>
									<strong class="mr-auto">{{ $t("notification") }}</strong>
									<small class="text-muted mr-2"></small>
								</div>
							</template>
							{{ msg }}
						</b-toast>
					</div>
				</div>
			</div>
		</b-form>
	</ValidationObserver>
</template>

<script>
	import { ValidationObserver, ValidationProvider } from "vee-validate";
	import AuthService from "@/services/AuthService.js";

	export default {
		name: "EditTransactionForm",
		components: {
			ValidationObserver,
			ValidationProvider,
		},
		data() {
			return {
				transaction: "",
				msg: "",
				isloading: false,
			};
		},
		async created() {
			if (!this.$store.getters.isLoggedIn) {
				this.$router.push("login");
			}
			if (
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role !== 3
			) {
				this.$router.push("overview");
			}

			this.role = this.$store.getters.getUser.role;
		},
		methods: {
			async getTransaction(id) {
				try {
					const id = this.$route.params.id;
					this.isloading = true;
					const response = await AuthService.getTransaction(id);
					this.transaction = response;
					this.isloading = false;
					console.log(response);
				} catch (error) {
					console.log(error);
					this.msg = error.response.data.msg;
					this.isloading = false;
				}
			},
			async editTransaction() {
				try {
					const id = this.$route.params.id;

					const details = {
						lang: this.$i18n.locale,
						debitAccountId: this.transaction.debitAccountId,
						creditAccountId: this.transaction.creditAccountId,
						amount: this.transaction.amount,
						message: this.transaction.message,
					};
					const response = await AuthService.editTransaction(id, details);
					this.msg = response.msg;
					console.log(response.msg);
				} catch (error) {
					console.log(error);
				}
			},
			resetForm() {
				this.transaction.debitAccountId = "";
				this.transaction.creditAccountId = "";
				this.transaction.amount = "";
				this.transaction.message = "";
				this.msg = "";
				requestAnimationFrame(() => {
					this.$refs.observer.reset();
				});
			},
		},
		mounted() {
			this.getTransaction();
		},
	};
</script>
