<template>
	<div class="col-lg-4 offset-lg-4">
		<h2 class="title-bold">{{ $t("verifyEmail.activateAccount") }}</h2>
		<hr />
		<div class="background-outline">
			<!--   <div v-if="msg">{{ msg }}</div> -->
			<div v-if="msg && $bvToast.show('notification-toast')"></div>
			<div class="action">
				<b-toast
					id="notification-toast"
					variant="secondary"
					static
					no-auto-hide
				>
					<template #toast-title>
						<div class="d-flex flex-grow-1 align-items-baseline">
							<b-img
								class="logo mb-2"
								:src="require('/public/images/logo.png')"
								alt=""
								width="12"
								height="12"
							></b-img>
							<strong class="mr-auto">{{ $t("notification") }}</strong>
							<small class="text-muted mr-2"></small>
						</div>
					</template>
					{{ msg }}
				</b-toast>
			</div>
		</div>
	</div>
</template>

<script>
	import AuthService from "@/services/AuthService.js";

	export default {
		data() {
			return {
				msg: "",
			};
		},
		created() {
			this.verifyEmail();
		},
		methods: {
			async verifyEmail() {
				try {
					let lang = this.$route.params.lang;
					let userID = this.$route.params.userID;
					let token = this.$route.params.token;
					const response = await AuthService.verifyEmail(lang, userID, token);

					this.msg = response.msg;
				} catch (error) {
					this.msg = error.response.data.msg;
					/*this.$router.push('home');*/
				}
			},
		},
		mounted() {
			//    this.verifyEmail();
		},
	};
</script>
