<template>
	<footer class="footer my-md-4 bg">
		<div class="">
			<!-- <div v-if="!this.$store.getters.isLoggedIn">
        <h3 class="title-bold text-gray">Choose your plan</h3>
        <br />
        <div class="flex-container" id="">
          <div class="flex-item text-left " id="">
            <div class="" id="">
              <h3 class="title-bold">Basic</h3>
              <h4 class="title-bold">Free</h4>
              </h2>
              <p class="">
                Our free core pack.
              </p>
              <a class="btn btn-outline" href="accounts-page" target=""
                > -->
			<!-- <font-awesome-icon icon="arrow-right"/> -->
			<!--   </a>
            </div>
          </div>
          <div class="flex-item text-left " id="">
            <div class="" id="">
              <h3 class="title-bold">Basic +</h3>
              <h4 class="title-bold">3/month</h4>
              </h2>
              <p class="">
                Our Basic+ pack.
              </p>
              <a class="btn btn-outline" href="accounts-page" target=""
                > -->
			<!-- <font-awesome-icon icon="arrow-right"/> -->
			<!-- </a>
            </div>
          </div>
          <div class="flex-item text-left " id="">
            <div class="" id="">
              <h3 class="title-bold">Premium</h3>
              <h4 class="title-bold">10/month</h4>
              </h2>
              <p class="">
                Our Premium pack.
              </p>
              <a class="btn btn-outline" href="accounts-page" target=""
                > -->
			<!-- <font-awesome-icon icon="arrow-right"/> -->
			<!-- </a>
            </div>
          </div>
          <div class="flex-item text-left " id="">
            <div class="" id="">
              <h3 class="title-bold">Premium+</h3>
              <h4 class="title-bold">15/month</h4>
              </h2>
              <p class="">
                Our Premium+ pack.
              </p>
              <a class="btn btn-outline" href="accounts-page" target=""
               > -->
			<!-- <font-awesome-icon icon="arrow-right"/> -->
			<!-- </a>
            </div>
          </div>
          
        </div>
        <div class="flextron">
          <h4 class="text-gray title-bold">No more hassles</h4>
          <h1 class="title-bold text-white">
            Get the global financial hyper app now
          </h1>
         
          <a class="btn btn-outline" href="sign-up/0" target="">Let's go</a>
        </div>
      </div> -->
			<hr />
			<div class="container">
				<div class="normal-footer">
					<ul class="list-unstyled text-small">
						<li>
							<h5>
								{{ $t("footer.slogan1") }}
							</h5>
						</li>
						<li>
							<h5>
								{{ $t("footer.slogan2") }}
							</h5>
						</li>
						<li>
							<h5>
								{{ $t("footer.slogan3") }}
							</h5>
						</li>
					</ul>
					<div class="row footer-links-wrapper">
						<!--   <div class="col-6 col-md">
          <h5 v-if="!this.$store.getters.isLoggedIn">
            {{ $t("footer.products") }}
          </h5>
          <ul class="list-unstyled text-small">
            <li>
              <b-nav-item
                v-if="!this.$store.getters.isLoggedIn"
                class="text-muted"
                :to="{ path: '/' + $i18n.locale + '/products' }"
                data-cy="nav-bar-products"
                >{{ $t("nav.products") }}</b-nav-item
              >
            </li>
            <li>
              <b-nav-item
                v-if="!this.$store.getters.isLoggedIn"
                class="text-muted"
                :to="{ path: '/' + $i18n.locale + '/accounts-page' }"
                data-cy="nav-bar-accounts-page"
                >{{ $t("nav.accounts") }}</b-nav-item
              >
            </li>
            <li>
              <b-nav-item
                v-if="!this.$store.getters.isLoggedIn"
                class="text-muted"
                :to="{ path: '/' + $i18n.locale + '/payments-page' }"
                data-cy="nav-bar-payments-page"
                >{{ $t("nav.payments") }}</b-nav-item
              >
            </li>
            <li>
              <b-nav-item
                v-if="!this.$store.getters.isLoggedIn"
                class="text-muted"
                :to="{ path: '/' + $i18n.locale + '/cards-page' }"
                data-cy="nav-bar-cards-page"
                >{{ $t("nav.cards") }}</b-nav-item
              >
            </li>
            <li>
              <b-nav-item
                v-if="!this.$store.getters.isLoggedIn"
                class="text-muted"
                :to="{ path: '/' + $i18n.locale + '/savings-page' }"
                data-cy="nav-bar-savings-page"
                >{{ $t("nav.savings") }}</b-nav-item
              >
            </li>
            <li>
              <b-nav-item
                v-if="!this.$store.getters.isLoggedIn"
                class="text-muted"
                :to="{ path: '/' + $i18n.locale + '/loans-page' }"
                data-cy="nav-bar-loans-page"
                >{{ $t("nav.loans") }}</b-nav-item
              >
            </li>
            <li>
              <b-nav-item
                v-if="!this.$store.getters.isLoggedIn"
                class="text-muted"
                :to="{ path: '/' + $i18n.locale + '/points-page' }"
                data-cy="nav-bar-points-page"
                >{{ $t("nav.points") }}</b-nav-item
              >
            </li>
          </ul>
        </div> -->

						<div class="col-6 col-md">
							<h5 class="text-left">{{ $t("footer.about") }}</h5>
							<ul class="list-unstyled text-small">
								<li>
									<b-nav-item
										class="text-muted"
										:to="{ path: '/' + $i18n.locale + '/home' }"
										data-cy="nav-bar-home"
										>{{ $t("footer.home") }}</b-nav-item
									>
								</li>
								<li>
									<b-nav-item
										class="text-muted"
										:to="{ path: '/' + $i18n.locale + '/about' }"
										data-cy="nav-bar-about"
										>{{ $t("footer.about") }}</b-nav-item
									>
								</li>
								<li>
									<b-nav-item
										class="text-muted"
										:to="{ path: '/' + $i18n.locale + '/enterprises' }"
										data-cy="nav-bar-about"
										>{{ $t("footer.merchantBanking") }}</b-nav-item
									>
								</li>
								<!--    <li>
              <b-nav-item
                class="text-muted"
                :to="{ path: '/' + $i18n.locale + '/blog' }"
                data-cy="nav-bar-blog"
                >{{ $t("footer.blog") }}</b-nav-item
              >
            </li> -->
								<!-- <li>
									<b-nav-item
										class="text-muted"
										:to="{ path: '/' + $i18n.locale + '/careers' }"
										data-cy="nav-bar-careers"
										>{{ $t("footer.careers") }}</b-nav-item
									>
								</li> -->
							</ul>
						</div>
					</div>
				</div>
			</div>
			<hr />
			<div class="container">
				<div class="footer-bottom text-left">
					<div class="row">
						<div class="col-6 col-md">
							<div class="row">
								<small class="d-block mb-3 text-muted">
									<!-- src="/images/logo.png" -->
									<img
										class="logo mb-2"
										src="/images/triangular_logo_grey.svg"
										alt=""
										width="16"
										height="16"
									/>
									&copy; {{ getCurrentYear() }}
									{{ $t("footer.allRightsReserved") }}
									{{ $t("footer.notAffiliated") }}</small
								>
							</div>
						</div>
            <div class="col-6 col-md">
              <div class="">
                <div class="text-right text-muted">
                  <LanguageSwitcher />
                </div>
              </div>
                <div class="text-right">
                <div class="footer-theme-button">
                  <ThemeButton />
                </div>
              </div>
            </div>
					</div>

					<div class="row">
						
						</div>
					</div>
				</div>
			</div>
		</div>
	</footer>
</template>

<script>
	import LanguageSwitcher from "../components/LanguageSwitcher.vue";
	import ThemeButton from "@/components/ThemeButton.vue";
	export default {
		name: "Footer",
		components: {
			LanguageSwitcher,
			ThemeButton,
		},
		methods: {
			getCurrentYear() {
				const currentYear = new Date().getFullYear();
				return currentYear;
			},
		},
		mounted() {
			this.getCurrentYear();
		},
	};
</script>

<!--<style scoped>
.bg {
  background-size: 400% 400%;

  animation-name: colors;
  animation-duration: 30s;
  animation-timing-function: ease;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
}
@keyframes colors {
  0% {
    background: #f5f5f7;
  } /*//#f6efab*/
  /* 6% {
    background: #e8ecbc; }
  12% {
    background: #d0e1c0; }*/
  18% {
    background: #d0e4d9;
  }
  24% {
    background: #c4e1ef;
  }
  30% {
    background: #cbe5f0;
  }
  36% {
    background: #c9dfe0;
  }
  42% {
    background: #d9e2d9;
  }
  48% {
    background: #e8e4e2;
  }
  54% {
    background: #f7dfdf;
  }
  60% {
    background: #f8e3de;
  }
  66% {
    background: #fae8db;
  }
  72% {
    background: #ecebe1;
  }
  78% {
    background: #f0eeea;
  }
  84% {
    background: #f1eae0;
  }
  90% {
    background: #f2e8cc;
  }
  96% {
    background: #fcecba;
  }
  100% {
    background: #f5f5f7;
  }
} /*#f6efab*/

@-webkit-keyframes colorchange {
  /* Safari and Chrome - necessary duplicate */
  0% {
    background: #f5f5f7;
  } /*//#f6efab*/
  /* 6% {
    background: #e8ecbc; }
  12% {
    background: #d0e1c0; }*/
  18% {
    background: #d0e4d9;
  }
  24% {
    background: #c4e1ef;
  }
  30% {
    background: #cbe5f0;
  }
  36% {
    background: #c9dfe0;
  }
  42% {
    background: #d9e2d9;
  }
  48% {
    background: #e8e4e2;
  }
  54% {
    background: #f7dfdf;
  }
  60% {
    background: #f8e3de;
  }
  66% {
    background: #fae8db;
  }
  72% {
    background: #ecebe1;
  }
  78% {
    background: #f0eeea;
  }
  84% {
    background: #f1eae0;
  }
  90% {
    background: #f2e8cc;
  }
  96% {
    background: #fcecba;
  }
  100% {
    background: #f5f5f7;
  }
} /*#f6efab*/
</style>-->
