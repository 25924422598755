<template>
	<ValidationObserver ref="observer" v-slot="{ handleSubmit, reset }">
		<b-form
			@submit.prevent="handleSubmit(editProductDetails)"
			@reset.prevent="reset"
		>
			<div class="col-lg-4 offset-lg-4 text-left">
				<h2 class="title-bold">
					{{ product.productName }}
				</h2>
				<hr />
				<p class="open-account text-right">
					<router-link
						class="btn btn-outline"
						:to="{ name: 'edit-product-details', params: { id: product.id } }"
						>{{ $t("customerProduct.editProduct") }}</router-link
					>
				</p>
				<br />

				<div class="background-modifier text-left">
					<div class="product-details"></div>
					<label class="small">{{ $t("customerProduct.productName") }}</label>
					<p>
						{{ product.productName }}
					</p>
					<label class="small">{{ $t("customerProduct.productDescription") }}</label>

					<p>
						{{ product.productDescription }}
					</p>
					<label class="small">{{ $t("customerProduct.productUnitPriceGross") }}</label>
					<p>
						
						{{ $n(product.productUnitPriceGross, "decimal") }}
					</p>
					<label class="small">{{ $t("customerProduct.discountRate") }} (%)</label>
					<p>
						
						{{ $n(product.discountRate, "decimal") }}
					</p>
					<label class="small">{{ $t("customerProduct.productUnitPriceNet") }}</label>
					<p>
						
						{{ $n(product.totalNet, "decimal") }}
					</p>
					</div>
				</div>

				<!-- disabled-->
				<!-- <div v-if="status == 'accepted'"> -->
				<!-- <b-button type="submit" class="btn btn-info text-light btn-block">{{
          $t("customerProduct.submit")
        }}</b-button> -->
				<!--   <p class="small">{{ $t("businessSignup.notAvailable") }}</p> -->
				<!-- </div> -->
				<!-- <hr />
        <b-button
          type="reset"
          class="btn btn-alert text-light btn-block"
          @click="resetForm()"
          >{{ $t("reset") }}</b-button
        > -->
				<br />
				<div v-if="msg && $bvToast.show('notification-toast')"></div>
				<div class="action">
					<b-toast
						id="notification-toast"
						variant="secondary"
						static
						no-auto-hide
					>
						<template #toast-title>
							<div class="d-flex flex-grow-1 align-items-baseline">
								<b-img
									class="logo mb-2"
									:src="require('/public/images/logo.png')"
									alt=""
									width="12"
									height="12"
								></b-img>
								<strong class="mr-auto">{{ $t("notification") }}</strong>
								<small class="text-muted mr-2"></small>
							</div>
						</template>
						{{ msg }}
					</b-toast>
				</div>
			</div>
		</b-form>
	</ValidationObserver>
</template>

<script>
	import { ValidationObserver, ValidationProvider } from "vee-validate";
	import AuthService from "@/services/AuthService.js";
	// var countryNames = require("i18n-iso-countries");
	export default {
		name: "AddNewProduct",
		components: {
			ValidationObserver,
			ValidationProvider, 
		},
		/*props: {
        codeLang: {
          type: String,
          default: "en",
        },
      },*/
		//this.codeLang
		created() {
			this.codeLang = this.$i18n.locale;
			// this.countryNames.registerLocale(
			//   require(`i18n-iso-countries/langs/${this.codeLang}.json`)
			// );
		},
		watch: {
			// codeLang(newVal, oldVal) {
			//   this.countryNames.registerLocale(
			//     require(`i18n-iso-countries/langs/${newVal}.json`)
			//   );
			// },
		},
		data() {
			const now = new Date();
			const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
			// 15th two months prior
			const minDate = new Date(today);
			minDate.setYear(minDate.getFullYear() - 0);
			const maxDate = new Date(today);
			maxDate.setYear(maxDate.getFullYear() + 2);
			// minDate.setMonth(minDate.getMonth() - 2);
			// minDate.setDate(15);
			// 15th in two months

			return {
				product: "",
				msg: "",
				productName: "",
				productDescription: "",

				productUnitPriceGross: 0.0,
				discountRate: 0.0,
				productUnitPriceNet: 0.0,
				productStatus: 1,
				min: minDate,
				max: maxDate,
				//status: "not_accepted",
			};
		},
		async created() {
			if (!this.$store.getters.isLoggedIn) {
				this.$router.push("login");
			}
			if (
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 3 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 15 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 14 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 13 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 12
			) {
				this.$router.push("overview");
			}
			let lang = this.$i18n.locale;
			this.role = this.$store.getters.getUser.role;
			// this.username = this.$store.getters.getUser.username;
			// this.firstname = this.$store.getters.getUser.firstname;

			//this.getOfferById();
		},
		methods: {
			calculateTotalNet() {
				this.totalNetResult =
					this.product.productUnitPriceGross -
					(this.product.discountRate / 100) *
						this.product.productUnitPriceGross;
			},
			// calculateVATAmount() {
			//   this.vatAmountResult = (this.totalNetResult * this.vatRate) / 100;
			//   return this.vatAmountResult;
			// },
			// calculateTotalInclVAT() {
			//   this.totalIncVATResult = this.totalNetResult - this.vatAmountResult;
			//   return this.totalIncVATResult;
			// },

			async editProductDetails() {
				try {
					const id = this.$route.params.id;
					const details = {
						userId: this.$store.getters.getUser.id,
						lang: this.$i18n.locale,
						//id: this.$route.params.id,
						productName: this.product.productName,
						productDescription: this.product.productDescription,
						productUnitPriceGross: this.product.productUnitPriceGross,
						discountRate: this.product.discountRate,
						productUnitPriceNet: this.totalNetResult,
						productStatus: this.product.productStatus,
					};
					const response = await AuthService.editProductDetails(id, details);
					// this.resetForm();
					this.msg = response.msg;

					console.log(response.msg);
				} catch (error) {
					this.msg = error.response.data.msg;
					//console.log(error);
				}
			},

			resetForm() {
				// this.email = "";
				// this.productName = "";
				// this.productDescription = "";
				// this.productUnitPriceGross = 0.0;
				// this.discountRate = 0.0;
				// this.productUnitPriceNet = 0.0;
				// this.productStatus = 1;
				this.msg = "";
				// this.totalNetResult = 0.0;
				requestAnimationFrame(() => {
					this.$refs.observer.reset();
				});
			},
			// async getCountries() {
			//   try {
			//     const response = await AuthService.getCountries();
			//     this.countries = response;
			//     console.log(response);
			//   } catch (error) {
			//     console.log(error);
			//   }
			// },
			// async getClientsCurrentAccounts() {
			//   try {
			//     const details = {
			//       id: this.$store.getters.getUser.id,
			//     };
			//     const response = await AuthService.getClientsCurrentAccounts(
			//       details.id
			//     );
			//     this.accounts = response;
			//     console.log(response);
			//   } catch (error) {
			//     console.log(error);
			//   }
			// },
			async getProductDetails() {
				try {
					const id = this.$route.params.id;
					const response = await AuthService.getProductDetails(id);
					this.product = response;
					console.log(response);
				} catch (error) {
					console.log(error);
				}
			},
		},
		mounted() {
			// this.getCountries();
			// this.getClientsCurrentAccounts();
			this.getProductDetails();
			//  this.getUserId();
		},
	};
</script>
