<template>
	<div class="col-12 offset-0">
		<div class="">
			<div class="text-left">
				<div class="col-md-8 offset-md-2">
					<h1 class="title-bold">
						{{ $t("offerManagement.offerManagement") }}
					</h1>
					<hr />
					<div class="">
						<h3 class="text-bold">
							{{ $t("offerManagement.offers") }} ({{ listedOffers.length }})
						</h3>
					</div>
					<hr />
				</div>

				<div class="col-md-8 offset-md-2 ">
					<div
						v-if="
							role == 3 || role == 15 || role == 14 || role == 13 || role == 12
						"
					>
						<p class="open-account text-right">
							<router-link
								class="btn btn-outline"
								:to="{
									name: 'create-offer',
									params: { id: this.businessDetails.id },
								}"
								>{{ $t("offerManagement.createOffer") }}</router-link
							>
						</p>
					</div>

					<!--   <p class="open-account text-right">
              <router-link
                class="btn btn-outline"
                :to="{ name: 'open-account' }"
                >{{ $t("accounts.openAccount") }}</router-link
              >
            </p>
            <br /> -->

					<br />
					<div v-if="isloading" class="text-center">
						<b-spinner
							style="width: 3rem; height: 3rem;"
							class="ml-auto text-center"
							:variant="secondary"
						></b-spinner>
					</div>
					<div v-else-if="listedOffers.length > 0">
						<b-row>
							<div
								v-for="offer in listedOffers"
								:key="offer.id"
								class="col-6 card-grid"
							>
								<router-link
									:to="{ name: 'offer-details', params: { id: offer.id } }"
									class=""
									tag="text-grey"
								>
									<div class="background-modifier ">
										<br />
										<p class="title-bold">
											({{ offer.id }}) {{ offer.merchantName }}
										</p>

										<div class="">
											<div class="">
												<h3 class="text-bold">
													{{ offer.offerName }}
												</h3>
												<p class="small">
													{{ offer.offerDescription }} ({{
														offer.discountRate != null
															? $n(offer.discountRate, "decimal")
															: $n(0.0, "decimal")
													}}%)
												</p>
												<p class="text-right small"></p>
												<h4 class="text-right text-bold">
													{{ $d(Date.parse(offer.startDate)) }} -
													{{ $d(Date.parse(offer.endDate)) }}
												</h4>
											</div>
										</div>
									</div>
								</router-link>
							</div>
						</b-row>
					</div>
					<div v-else>{{ $t("offerManagement.noOffers") }}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import AuthService from "@/services/AuthService.js";

	export default {
		data() {
			return {
				offer: "",
				username: "",
				listedOffers: [],
				businessDetails: "",
				isloading: false,
			};
		},
		async created() {
			if (!this.$store.getters.isLoggedIn) {
				this.$router.push("login");
			}
			if (
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 3 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 15 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 14 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 13 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 12 &&
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role != 11
			) {
				this.$router.push("overview");
			}
			let lang = this.$i18n.locale;
			this.role = this.$store.getters.getUser.role;
			this.username = this.$store.getters.getUser.username;
			this.firstname = this.$store.getters.getUser.firstname;
		},
		methods: {
			async getClientsOfferList() {
				try {
					const id = this.$store.getters.getUser.id;
					this.isloading = true;
					const response = await AuthService.getClientsOfferList(id);
					this.listedOffers = response;
					this.isloading = false;
					console.log(response);
				} catch (error) {
					this.isloading = false;
					console.log(error);
				}
			},
			async getBusinessDetails() {
				try {
					const id = this.$store.getters.getUser.id;
					const response = await AuthService.getBusinessDetails(id);
					this.businessDetails = response;
					console.log(response);
				} catch (error) {
					console.log(error);
				}
			},
		},

		mounted() {
			this.getClientsOfferList();
			this.getBusinessDetails();
		},
	};
</script>
