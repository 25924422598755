<template>
	<ValidationObserver ref="observer" v-slot="{ handleSubmit, reset }">
		<b-form
			@submit.prevent="handleSubmit(editPaymentOrder)"
			@reset.prevent="reset"
		>
			<div class="col-lg-4 offset-lg-4 text-left">
				<h2 class="title-bold">
					{{ $t("editPaymentOrder.editPaymentOrder") }}
				</h2>
				<hr />
				<div class="background-outline">
				<div class="background-modifier">
					<label class="small">{{
						$t("editPaymentOrder.debitAccountId")
					}}</label>
					<ValidationProvider
						rules="required|integer"
						name="debitAccounId"
						v-slot="{ valid, errors }"
					>
						<b-form-group label-for="debitAccountIdInput" description="">
							<b-form-input
								type="text"
								v-model="paymentOrder.debitAccountId"
								:state="errors[0] ? false : valid ? true : null"
								:placeholder="$t('editPaymentOrder.debitAccountId')"
							></b-form-input>
							<b-form-invalid-feedback id="inputLiveFeedback">{{
								errors[0]
							}}</b-form-invalid-feedback>
						</b-form-group>
					</ValidationProvider>
					<label class="small">{{
						$t("editPaymentOrder.creditAccountId")
					}}</label>
					<ValidationProvider
						rules="required|integer"
						name="creditAccounId"
						v-slot="{ valid, errors }"
					>
						<b-form-group label-for="creditAccountIdInput" description="">
							<b-form-input
								type="text"
								v-model="paymentOrder.creditAccountId"
								:state="errors[0] ? false : valid ? true : null"
								:placeholder="$t('editPaymentOrder.creditAccountId')"
							></b-form-input>
							<b-form-invalid-feedback id="inputLiveFeedback">{{
								errors[0]
							}}</b-form-invalid-feedback>
						</b-form-group>
					</ValidationProvider>
				</div>
				<br />

				<div class="background-modifier">
					<label class="small">{{ $t("editPaymentOrder.amount") }}</label>
					<ValidationProvider
						rules="required|double|max_value:1000000|min_value:0.01"
						name="amount"
						v-slot="{ valid, errors }"
					>
						<b-form-group label-for="amountInput" description="">
							<b-form-input
								type="text"
								v-model="paymentOrder.amount"
								:state="errors[0] ? false : valid ? true : null"
								:placeholder="$t('editPaymentOrder.amount')"
							></b-form-input>
							<b-form-invalid-feedback id="inputLiveFeedback">{{
								errors[0]
							}}</b-form-invalid-feedback>
						</b-form-group>
					</ValidationProvider>
				</div>
				<br />

				<div class="background-modifier">
					<label class="small">{{ $t("editPaymentOrder.message") }}</label>
					<ValidationProvider
						rules="min:1|max:50"
						name="message"
						v-slot="{ valid, errors }"
					>
						<b-form-group label-for="messageInput" description="">
							<b-form-input
								type="text"
								v-model="paymentOrder.message"
								:state="errors[0] ? false : valid ? true : null"
								:placeholder="$t('editPaymentOrder.message')"
							></b-form-input>
							<b-form-invalid-feedback id="inputLiveFeedback">{{
								errors[0]
							}}</b-form-invalid-feedback>
						</b-form-group>
					</ValidationProvider>
				</div>
				<br />

				<div class="background-modifier">
					<div class="row">
						<div class="col-md-12 offset-md-0">
							<label for="" class="small">{{
								$t("editPaymentOrder.dueDate")
							}}</label>
							<b-form-datepicker
								v-model="paymentOrder.dueDate"
								:min="min"
								:locale="$i18n.locale"
								:label-no-date-selected="$t('editPaymentOrder.noDateSelected')"
								:label-help="
									$t('editPaymentOrder.useCursorKeysToNavigateCalendarDates')
								"
							></b-form-datepicker>
						</div>
					</div>
				</div>
				<hr />
				<b-button type="submit" @click="isloading" class="btn btn-info text-light btn-block">{{
					$t("editPaymentOrder.submit")
				}}<b-spinner
				v-if="isloading"
				class="ml-auto"
				:variant="light"
			></b-spinner
		></b-button></b-button>
				<!--   <hr/>
       <b-button type="reset" class="btn btn-alert text-light btn-block" @click="resetForm()">{{ $t('reset') }}</b-button> -->
				
				<div v-if="msg && $bvToast.show('notification-toast')"></div>
				<div class="action">
					<br />
					<b-toast
						id="notification-toast"
						variant="secondary"
						static
						no-auto-hide
					>
						<template #toast-title>
							<div class="d-flex flex-grow-1 align-items-baseline">
								<b-img
									class="logo mb-2"
									:src="require('/public/images/logo.png')"
									alt=""
									width="12"
									height="12"
								></b-img>
								<strong class="mr-auto">{{ $t("notification") }}</strong>
								<small class="text-muted mr-2"></small>
							</div>
						</template>
						{{ msg }}
					</b-toast>
				</div>
			</div>
		</div>
		</b-form>
	</ValidationObserver>
</template>

<script>
	import { ValidationObserver, ValidationProvider } from "vee-validate";
	import AuthService from "@/services/AuthService.js";

	export default {
		name: "EditPaymentOrder",
		components: {
			ValidationObserver,
			ValidationProvider,
		},
		data() {
			const now = new Date();
			const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
			// 15th two months prior

			const minDate = new Date(today);
			//   const minDate = new Date(openingDate.getFullYear(), openingDate.getMonth(), openingDate.getDate())
			minDate.setYear(minDate.getDate() + 1);

			return {
				paymentOrder: "",
				dueDate: "",
				min: minDate,
				isloading: false,
				msg: "",
			};
		},
		async created() {
			if (!this.$store.getters.isLoggedIn) {
				this.$router.push("login");
			}
		},
		methods: {
			async getPaymentOrder(id) {
				try {
					const id = this.$route.params.id;
					const response = await AuthService.getPaymentOrder(id);
					this.paymentOrder = response;
					console.log(response);
				} catch (error) {
					console.log(error);
				}
			},
			async editPaymentOrder() {
				try {
					const id = this.$route.params.id;
					const details = {
						lang: this.$i18n.locale,
						debitAccountId: this.paymentOrder.debitAccountId,
						creditAccountId: this.paymentOrder.creditAccountId,
						creditAccountHolder: this.paymentOrder.creditAccountHolder,
						amount: this.paymentOrder.amount,
						message: this.paymentOrder.message,
						dueDate: this.paymentOrder.dueDate,
					};
					this.isloading = true;
					const response = await AuthService.editPaymentOrder(id, details);
					this.msg = response.msg;
					this.isloading = false;
					this.getPaymentOrder();
					console.log(response.msg);
				} catch (error) {
					this.isloading = false;
					console.log(error);
				}
			},
			/*     resetForm() {

        this.msg = '';
        requestAnimationFrame(() => {
          this.$refs.observer.reset();
        });
      }*/
		},
		mounted() {
			this.getPaymentOrder();
		},
	};
</script>
