<template>
	<div class="col-12 offset-0">
		<div class="text-left">
			<div class="col-md-8 offset-md-2">
				<h1 class="title-bold">
					{{ $t("clientList.clients") }} ({{ totalClients.count }})
				</h1>

				<!-- ({{ count }}) -->
				<!--   <form>
          <div class="input-group mb-3">
            <input type="text" class="form-control" :placeholder="$t('account.nickname')" v-model="account.nickname" />
            </div>
          <button type="submit" class="btn btn-info"
        @click="updateAccount"
      >{{ $t('newPayment.submit') }}
        </button>
        <p v-if="msg">{{ msg }}</p>
        </form> -->

				<!-- <p class="open-account text-right">
          <router-link class="btn btn-outline" :to="{ name: 'new-client' }">{{
            $t("clientList.newClient")
          }}</router-link>
        </p> -->
				<br />

				<div v-if="msg && $bvToast.show('notification-toast')"></div>
				<div class="action">
					<b-toast
						id="notification-toast"
						variant="secondary"
						static
						no-auto-hide
					>
						<template #toast-title>
							<div class="d-flex flex-grow-1 align-items-baseline">
								<b-img
									class="logo mb-2"
									:src="require('/public/images/logo.png')"
									alt=""
									width="12"
									height="12"
								></b-img>
								<strong class="mr-auto">{{ $t("notification") }}</strong>
								<small class="text-muted mr-2"></small>
							</div>
						</template>
						{{ msg }}
					</b-toast>
				</div>

				<b-tabs content-class="mt-3">
					<b-tab :title="$t('clientList.individual')" active>
						<div class="background-modifier">
							<div v-if="individualClients.length > 0">
								<div class="row">
									<div class="col-md-6">
										<h4 class="title-bold text-left">
											{{ $t("clientList.individual") }}
										</h4>
									</div>
									<div class="col-md-6">
										<h4 class="title-bold text-right">
											({{ totalIndividualClients.count }})
										</h4>
									</div>
								</div>
								<hr />
							</div>
							<div class="input-group mb-3">
								<input
									type="text"
									class="form-control"
									:placeholder="$t('search')"
									v-model="searchTitle"
								/>
								<div class="input-group-append">
									<button
										class="btn btn-outline"
										type="button"
										@click="
											page = 1;
											getAllIndividualClients();
										"
									>
										{{ $t("search") }}
									</button>
								</div>
							</div>

							<div v-if="isloading" class="text-center">
								<b-spinner
									style="width: 3rem; height: 3rem;"
									class="ml-auto text-center"
									:variant="secondary"
								></b-spinner>
							</div>
							<div v-else-if="individualClients.length > 0">
								<div class="table-wrap">
									<table class="table mt-2 ">
										<thead>
											<tr>
												<th class="text-left small">
													{{ $t("clientList.id") }}
												</th>
												<!-- <th class="text-left">{{ $t("clientList.name") }}</th> -->
												<th class="text-left small">
													{{ $t("clientList.firstname") }}
												</th>
												<th class="text-left small">
													{{ $t("clientList.lastname") }}
												</th>
												<!-- <th class="text-left">{{ $t("clientList.email") }}</th> -->
												<!-- <th class="text-right">{{ $t("clientList.role") }}</th> -->
												<!-- <th class="text-right">
                          {{ $t("clientList.status") }}
                        </th> -->
												<th class="text-right small">
													{{ $t("clientList.registered") }}
												</th>
												<!-- <th class="text-right">{{ $t("clientList.lastLogin") }}</th> -->
												<th class="text-centered small">
													{{ $t("clientList.actions") }}
												</th>
											</tr>
										</thead>
										<tbody>
											<tr
												v-for="individualClient in individualClients"
												:key="individualClient.id"
											>
												<td class="text-left">{{ individualClient.id }}</td>
												<!-- <td class="text-left">{{ individualClient.name }}</td> -->
												<td class="text-left">
													{{ individualClient.firstname }}
												</td>
												<td class="text-left">
													{{ individualClient.lastname }}
												</td>
												<!-- <td class="text-left">{{ client.email }}</td> -->
												<!-- <td class="text-right">
                      {{ client.role == 3 ? $t("role.admin") : $t("role.client") }}
                    </td> -->
												<!-- <td class="text-right">
                          {{
                            individualClient.status == 1 ? $t("yes") : $t("no")
                          }}
                        </td> -->
												<td class="text-right">
													{{
														$d(Date.parse(individualClient.registered), "long")
													}}
												</td>
												<!-- <td class="text-right">
                      {{
                        user.lastLogin != null
                          ? $d(Date.parse(user.lastLogin), "long")
                          : $t("no")
                      }}
                    </td> -->

												<td class="text-right">
													<router-link
														:to="{
															name: 'individual-client-details',
															params: { id: individualClient.id },
														}"
														class="btn btn-outline mx-1 "
														>{{ $t("applications.view") }}</router-link
													>
													<!-- <a
                            class="btn btn-danger mx-1 "
                            @click="deleteIndividualClient(individualClient.id)"
                            >{{ $t("clientList.delete") }}</a
                          > -->
												</td>
											</tr>
										</tbody>
									</table>
								</div>
								<div v-if="individualClients.length >= 1">
									<div class="row">
										<div class="col-md-6">
											{{ $t("itemsPerPage") }}
											<select
												class="custom-select"
												style="width: 60px;"
												v-model="pageSize"
												@change="handlePageSizeChange($event)"
											>
												<option
													v-for="size in pageSizes"
													:key="size"
													:value="size"
												>
													{{ size }}
												</option>
											</select>
										</div>
										<div class="col-md-6">
											<b-pagination
												v-model="page"
												:total-rows="count"
												:per-page="pageSize"
												:prev-text="$t('previous')"
												:next-text="$t('next')"
												align="right"
												@change="handlePageChange"
											></b-pagination>
										</div>
									</div>
								</div>
							</div>
							<div v-else>{{ $t("clientList.noClients") }}</div>
						</div>
					</b-tab>
					<b-tab :title="$t('clientList.business')">
						<div class="background-modifier">
							<div v-if="businessClients.length > 0">
								<div class="row">
									<div class="col-md-6">
										<h4 class="title-bold text-left">
											{{ $t("clientList.business") }}
										</h4>
									</div>
									<div class="col-md-6">
										<h4 class="title-bold text-right">
											({{ totalBusinessClients.count }})
										</h4>
									</div>
								</div>
								<hr />
							</div>
							<div class="input-group mb-3">
								<input
									type="text"
									class="form-control"
									:placeholder="$t('search')"
									v-model="searchTitle"
								/>
								<div class="input-group-append">
									<button
										class="btn btn-outline"
										type="button"
										@click="
											page = 1;
											getAllBusinessClients();
										"
									>
										{{ $t("search") }}
									</button>
								</div>
							</div>

							<div v-if="isloading2" class="text-center">
								<b-spinner
									style="width: 3rem; height: 3rem;"
									class="ml-auto text-center"
									:variant="secondary"
								></b-spinner>
							</div>
							<div v-else-if="businessClients.length > 0">
								<div class="table-wrap">
									<table class="table mt-2 ">
										<thead>
											<tr>
												<th class="text-left small">
													{{ $t("clientList.id") }}
												</th>
												<th class="text-left small">
													{{ $t("clientList.name") }}
												</th>
												<!-- <th class="text-left">
                          {{ $t("clientList.firstname") }}
                        </th>
                        <th class="text-left">
                          {{ $t("clientList.lastname") }}
                        </th> -->
												<!-- <th class="text-left">{{ $t("clientList.email") }}</th> -->
												<!-- <th class="text-right">{{ $t("clientList.role") }}</th> -->
												<!-- <th class="text-right">
                          {{ $t("clientList.status") }}
                        </th> -->
												<th class="text-right small">
													{{ $t("clientList.registered") }}
												</th>
												<!-- <th class="text-right">{{ $t("clientList.lastLogin") }}</th> -->
												<th class="text-centered small">
													{{ $t("clientList.actions") }}
												</th>
											</tr>
										</thead>
										<tbody>
											<tr
												v-for="businessClient in businessClients"
												:key="businessClient.id"
											>
												<td class="text-left">{{ businessClient.id }}</td>
												<td class="text-left">{{ businessClient.name }}</td>
												<!-- <td class="text-left">
                          {{ businessClient.firstname }}
                        </td>
                        <td class="text-left">{{ businessClient.lastname }}</td> -->
												<!-- <td class="text-left">{{ client.email }}</td> -->
												<!-- <td class="text-right">
                        {{ client.role == 3 ? $t("role.admin") : $t("role.client") }}
                      </td> -->
												<!-- <td class="text-right">
                          {{
                            businessClient.status == 1 ? $t("yes") : $t("no")
                          }}
                        </td> -->
												<td class="text-right">
													{{
														$d(Date.parse(businessClient.registered), "long")
													}}
												</td>
												<!-- <td class="text-right">
                        {{
                          user.lastLogin != null
                            ? $d(Date.parse(user.lastLogin), "long")
                            : $t("no")
                        }}
                      </td> -->
												<td class="text-right">
													<router-link
														:to="{
															name: 'business-client-details',
															params: { id: businessClient.id },
														}"
														class="btn btn-outline mx-1 "
														>{{ $t("applications.view") }}</router-link
													>
													<a
														class="btn btn-danger mx-1 "
														@click="deleteBusinessClient(businessClient.id)"
														>{{ $t("clientList.delete") }}</a
													>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
								<div v-if="businessClients.length >= 1">
									<div class="row">
										<div class="col-md-6">
											{{ $t("itemsPerPage") }}
											<select
												class="custom-select"
												style="width: 60px;"
												v-model="pageSize"
												@change="handlePageSizeChange($event)"
											>
												<option
													v-for="size in pageSizes"
													:key="size"
													:value="size"
												>
													{{ size }}
												</option>
											</select>
										</div>
										<div class="col-md-6">
											<b-pagination
												v-model="page"
												:total-rows="count"
												:per-page="pageSize"
												:prev-text="$t('previous')"
												:next-text="$t('next')"
												align="right"
												@change="handlePageChange"
											></b-pagination>
										</div>
									</div>
								</div>
							</div>
							<div v-else>{{ $t("clientList.noClients") }}</div>
						</div>
					</b-tab>
				</b-tabs>
			</div>
		</div>
	</div>
</template>

<script>
	import AuthService from "@/services/AuthService.js";

	export default {
		name: "ClientList",
		data() {
			return {
				individualClients: [],
				businessClients: [],

				totalClients: "",
				totalIndividualClients: "",
				totalBusinessClients: "",
				totalClientApplications: "",
				totalIndividualClientApplications: "",
				totalBusinessClientsApplications: "",
				isloading: false,
				isloading2: false,
				msg: "",
				currentIndividualClient: null,
				currentBusinessClient: null,
				currentIndex: -1,
				searchTitle: "",

				page: 1,
				count: 0,
				pageSize: 10,

				pageSizes: [10, 25, 50],
			};
		},
		async created() {
			if (!this.$store.getters.isLoggedIn) {
				this.$router.push("login");
			}
			if (
				this.$store.getters.isLoggedIn &&
				this.$store.getters.getUser.role !== 3
			) {
				this.$router.push("overview");
			}

			this.role = this.$store.getters.getUser.role;
			this.getAllIndividualClients();
			this.getAllBusinessClients();
		},
		methods: {
			getRequestParams(searchTitle, page, pageSize) {
				let params = {};

				if (searchTitle) {
					params["lastname"] = searchTitle;
				}

				if (page) {
					params["page"] = page - 1;
				}

				if (pageSize) {
					params["size"] = pageSize;
				}

				return params;
			},

			getRequestParams2(searchTitle, page, pageSize) {
				let params = {};

				if (searchTitle) {
					params["name"] = searchTitle;
				}

				if (page) {
					params["page"] = page - 1;
				}

				if (pageSize) {
					params["size"] = pageSize;
				}

				return params;
			},

			async getAllIndividualClients() {
				const params = this.getRequestParams(
					this.searchTitle,
					this.page,
					this.pageSize
				);

				try {
					this.isloading = true;
					const response = await AuthService.getAllIndividualClients(
						params.page,
						params.size,
						params.lastname
					);
					const { items, totalItems } = response.data;
					this.individualClients = items;
					this.count = totalItems;
					this.isloading = false;
					console.log(response.data);
				} catch (error) {
					this.isloading = false;
					console.log(error);
				}
			},
			async getAllBusinessClients() {
				const params = this.getRequestParams2(
					this.searchTitle,
					this.page,
					this.pageSize
				);

				try {
					this.isloading2 = true;
					const response = await AuthService.getAllBusinessClients(
						params.page,
						params.size,
						params.name
					);
					const { items, totalItems } = response.data;
					this.businessClients = items;
					this.count = totalItems;
					this.isloading2 = false;
					console.log(response.data);
				} catch (error) {
					this.isloading2 = false;
					console.log(error);
				}
			},

			handlePageChange(value) {
				this.page = value;
				this.getAllIndividualClients();
				this.getAllBusinessClients();
			},

			handlePageSizeChange(event) {
				this.pageSize = event.target.value;
				this.page = 1;
				this.getAllIndividualClients();
				this.getAllBusinessClients();
			},

			// async deleteIndividualClient(id) {
			//   try {
			//     const lang = this.$i18n.locale;
			//     const response = await AuthService.deleteClient(id, lang);
			//     console.log(response);
			//     this.getAllIndividualClients();
			//     this.msg = response.msg;
			//     console.log(response.msg);
			//   } catch (error) {
			//     console.log(error);
			//     this.msg = error.response.data.msg;
			//   }
			// },
			// async deleteBusinessClient(id) {
			//   try {
			//     const lang = this.$i18n.locale;
			//     const response = await AuthService.deleteClient(id, lang);
			//     console.log(response);
			//     this.getAllBusinessClients();
			//     this.msg = response.msg;
			//     console.log(response.msg);
			//   } catch (error) {
			//     console.log(error);
			//     this.msg = error.response.data.msg;
			//   }
			// },
			async getTotalClients() {
				try {
					const response = await AuthService.getTotalClients();
					this.totalClients = response;
					console.log(response);
				} catch (error) {
					console.log(error);
				}
			},
			async getTotalIndividualClients() {
				try {
					const response = await AuthService.getTotalIndividualClients();
					this.totalIndividualClients = response;
					console.log(response);
				} catch (error) {
					console.log(error);
				}
			},
			async getTotalBusinessClients() {
				try {
					const response = await AuthService.getTotalBusinessClients();
					this.totalBusinessClients = response;
					console.log(response);
				} catch (error) {
					console.log(error);
				}
			},
			async getTotalClientApplications() {
				try {
					const response = await AuthService.getTotalClientApplications();
					this.totalClientApplications = response;
					console.log(response);
				} catch (error) {
					console.log(error);
				}
			},
			async getTotalIndividualClientApplications() {
				try {
					const response = await AuthService.getTotalIndividualClientApplications();
					this.totalIndividualClientApplications = response;
					console.log(response);
				} catch (error) {
					console.log(error);
				}
			},
			async getTotalBusinessClientApplications() {
				try {
					const response = await AuthService.getTotalBusinessClientApplications();
					this.totalBusinessClientApplications = response;
					console.log(response);
				} catch (error) {
					console.log(error);
				}
			},
		},
		mounted() {
			this.getAllIndividualClients();
			this.getAllBusinessClients();
			this.getTotalClients();
			this.getTotalIndividualClients();
			this.getTotalBusinessClients();
			this.getTotalClientApplications();
			this.getTotalIndividualClientApplications();
			this.getTotalBusinessClientApplications();
		},
	};
</script>
